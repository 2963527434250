import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { uuidPropType } from 'prop_types';
import { useRaiseOnFailedRequest } from 'utils/react_utils';
import { useAlerts } from 'alert';
import { LoadingSpinner } from 'controls/loading_spinner';
import { EmployeesRouteHelpers } from "pages/workforce/route_helpers";
import { employeeActions, employeeProfileActions } from '../../employees_actions';
import { WithEmployeeProfileHeader } from './profile/employee_profile_header';
import { Routes } from './routes';
import { useIntl } from 'react-intl';

/**
 * Layout for showing a single employee
 *
 * @param {Object} props
 *   @param {Uuid} props.organization_id - The organization ID.
 *   @param {Uuid} props.id - Id of the employee
 */
export const Layout = (props) => {
  const intl = useIntl();
  const [employeeProfileState] = employeeProfileActions.useOnMount('get', props.id, {
    organization_id: props.organization.id
  });
  const alerts = useAlerts();

  const [employeeDeleteState] = employeeActions.useAction('delete', props.id);

  useEffect(() => {
    if (employeeDeleteState.status.succeeded) {
      alerts.add({
        type: 'notice',
        text: intl.formatMessage({
          id: 'workforce.employees.show.layout.successfully_removed',
          defaultMessage: 'Employee successfully removed.'
        })
      });
    }
  }, [employeeDeleteState.status]);

  useRaiseOnFailedRequest(employeeProfileState.status, `Employee (${props.id})`);

  const employee = employeeProfileState.resources;
  const status = employeeProfileState.status;
  const deleteStatus = employeeDeleteState.status;

  if (status.pending || (status.idle && !employee)) {
    return <LoadingSpinner/>;
  } else if (deleteStatus.succeeded) {
    const afterDeletePath = EmployeesRouteHelpers.root.parameterize(
      { organization_id: props.organization_id }
    );
    return (
      <Redirect to={afterDeletePath}/>
    );
  } else {
    return (
      <WithEmployeeProfileHeader
        baseUrl={props.baseUrl}
        organization={props.organization}
        employee={employee}
        location={props.location}
        id={props.id}
      >
        <Routes
          baseUrl={props.baseUrl}
          employee={employee}
          organization={props.organization}
          location={props.location}
        />
      </WithEmployeeProfileHeader>
    );
  }
};

Layout.propTypes = {
  baseUrl: PropTypes.string,
  organization_id: uuidPropType,
  organization: PropTypes.object,
  location: PropTypes.object,
  id: uuidPropType
};
