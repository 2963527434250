import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { uniqueId } from 'utils';
import { useToggle } from 'utils/react_utils';
import { useWidthBreakpoints } from 'utils/use_width_breakpoints';
import CollapsibleSection from 'controls/collapsible_section';
import CredlyLogo from 'images/credly-logo-white.svg';
import { CurrentOrganization } from './current_organization';
import { isSmallScreen } from './helpers';
import { WorkforceNav } from './workforce_nav';

import './workforce_sidebar.sass';

/**
 * Nav "sidebar" for the Workforce section.
 *
 * @param {object} props
 *   @param {string} props.baseUrl - base URL where this nav is applicable (e.g. /workforce/organizations/:org_id)
 *   @param {object} props.organization - organization data (as from organizationActions)
 * @returns {JSX.Element}
 * @constructor
 */
export const WorkforceSidebar = (props) => {
  const [menuOpen, toggleMenuOpen] = useToggle();
  const toggleMenuOpenNoArgs = () => toggleMenuOpen();
  const view = useWidthBreakpoints();
  const intl = useIntl();
  const navContainerId = useRef();
  const hideSubsc = props.organization.hide_subscription;

  if (!navContainerId.current) {
    navContainerId.current = uniqueId();
  }

  return (
    <div className="cr-workforce-sidebar">
      {
        isSmallScreen(view)
          ? (
            <div className="cr-workforce-sidebar__current-org">
              <CurrentOrganization organization={props.organization}/>
              <button
                className="cr-workforce-sidebar__toggle-menu"
                onClick={toggleMenuOpenNoArgs}
                aria-expanded={menuOpen}
                aria-controls={`wf_nav_${navContainerId.current}`}
                aria-label={intl.formatMessage(
                  {
                    id: 'workforce.sidebar.nav_toggle',
                    defaultMessage: 'Navigation'
                  }
                )}
              >
                <FontAwesomeIcon icon={menuOpen ? faChevronUp : faChevronDown}/>
              </button>
            </div>
          )
          : (
            <img
              className="cr-workforce-sidebar__logo"
              src={CredlyLogo}
              alt={intl.formatMessage({
                id: 'workforce.sidebar.credly.alt',
                defaultMessage: 'Credly'
              })}
            />
          )
      }
      <CollapsibleSection
        collapsed={!menuOpen}
        alwaysExpandedAboveBreakpoint="lg"
        otherProps={{ id: `wf_nav_${navContainerId.current}` }}
      >
        <WorkforceNav
          baseUrl={props.baseUrl}
          className="cr-workforce-sidebar__menu"
          onClick={toggleMenuOpenNoArgs}
          hideSubscription={hideSubsc}
        />
      </CollapsibleSection>
    </div>
  );
};

WorkforceSidebar.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired
};
