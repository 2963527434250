import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { uuidPropType } from 'prop_types';
import { useAlerts } from 'alert';
import { useFormProps } from 'form';
import { GenericSectionHeader, WithSectionHeader } from 'controls/section_header.jsx';
import { EmployeesRouteHelpers } from 'pages/workforce/route_helpers';
import { employeeProfileActions } from '../../employees_actions';
import { useIntl } from 'react-intl';
import { EmployeeForm } from '../add/employee_form';

import './edit_employee.sass';

/**
 * Page for editing an employee. Displays an edit form as well as buttons
 * to resend a notification and delete the employee.
 *
 * @param {Object} props
 *   @param {Object} props.organization - Organization to retrieve employees for.
 *   @param {Object} props.employee - Redux resource object for one employee
 */
export const EditEmployee = (props) => {
  const intl = useIntl();
  const initialValues = {
    first_name: props.employee.first_name,
    last_name: props.employee.last_name,
    email: props.employee.email,
    external_id: props.employee.external_id,
    position_title: props.employee.position_title,
    state_or_province: props.employee.state_or_province,
    country: props.employee.country,
    department: props.employee.department,
    manager_first_name: props.employee.manager_first_name,
    manager_last_name: props.employee.manager_last_name,
    manager_external_id: props.employee.manager_external_id,
    organization_level_1: props.employee.organization_level_1,
    organization_level_2: props.employee.organization_level_2,
    organization_level_3: props.employee.organization_level_3,
    level: props.employee.level,
    job_family: props.employee.job_family
  };
  const [editEmployeeFormProps] = useFormProps(
    employeeProfileActions.get('update', props.employee.id),
    {
      initialValues: initialValues,
      staticParams: { organization_id: props.organization.id, id: props.employee.id }
    }
  );

  const alerts = useAlerts();
  const history = useHistory();
  const onSubmitSuccess = () => {
    alerts.add({
      type: 'notice',
      text: 'Employee successfully updated.'
    });
    history.push(EmployeesRouteHelpers.root.parameterize({
      organization_id: props.organization.id
    }));
  };

  const sectionHeader = useCallback(
    () => {
      const headerText = intl.formatMessage({
        id: 'workforce.employees.show.edit.section_header.heading',
        defaultMessage: 'Edit'
      });

      return <GenericSectionHeader heading={headerText} breadcrumbs={false} />;
    },
    []
  );

  return (
    <WithSectionHeader sectionHeader={sectionHeader}>
      <div className="c-edit-employee">
        <EmployeeForm
          formProps={editEmployeeFormProps}
          onSubmitSuccess={onSubmitSuccess}
          organization={props.organization}
          employee={props.employee}
        />
      </div>
    </WithSectionHeader>
  );
};

EditEmployee.propTypes = {
  organization: PropTypes.shape({
    id: uuidPropType,
    integrated_with_faethm: PropTypes.bool
  }),
  employee: PropTypes.object,
  onDeleteSuccess: PropTypes.func
};
