import React from 'react';
import PropTypes from 'prop-types';
import { occupationUrl } from 'utils/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/pro-regular-svg-icons/faAddressCard';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons/faLocationDot';
import { faNetworkWired } from '@fortawesome/pro-regular-svg-icons/faNetworkWired';
import { faUserTie } from '@fortawesome/pro-regular-svg-icons/faUserTie';
import { useIntl } from 'react-intl';
import { Heading } from 'controls/heading';
import { SmartLink } from 'controls/smart_link';
import { EmployeeRouteHelpers } from 'pages/workforce/route_helpers';

import './employee_profile_details.sass';

/**
 * Shows basic information about an employee.
 *
 * @param {object} props
 *   @param {String} props.baseUrl - the URL base where this component is presented
 *   @param {String} props.email - employee email address
 *   @param {String} [props.country] - full name of employee country (e.g. "United States")
 *   @param {String} [props.location] - location (state/provice, country) of employee
 *   @param {String} [props.department] - department employee is in
 *   @param {String} [props.external_id] - employee external ID (i.e. "Employee ID" in UI)
 *   @param {String} [props.manager_id] - ID of Employment record of employee's manager
 *   @param {String} [props.manager_name] - full name of employee's manager
 *   @param {{id:string,name:string}} [props.occupation] - employee's occupation (per Faethm)
 *   @param {String} [props.state_or_province] - name of employee state/province (e.g. "Minnesota")
 * @returns {JSX.Element}
 * @constructor
 */
export const EmployeeProfileDetails = (props) => {
  const intl = useIntl();

  const routeHelpers = EmployeeRouteHelpers.forUrl(props.baseUrl);
  const location = props.location;

  return (
    <div className="cr-workforce-employee-profile-details">
      <Heading appearance="workforce-subhead">
        {intl.formatMessage({
          defaultMessage: 'Employee information',
          id: 'workforce.employee_profile.employee_information'
        })}
      </Heading>
      <div className="cr-workforce-employee-profile-details__container">
        <DetailsKeyValuePair
          label={intl.formatMessage({
            defaultMessage: 'Email', id: 'workforce.employee_profile.email_label'
          })}
          value={props.email}
          link={`mailto:${props.email}`}
          icon={faEnvelope}
        />
        {
          props.external_id && (
            <DetailsKeyValuePair
              label={intl.formatMessage({
                defaultMessage: 'ID', id: 'workforce.employee_profile.id_label'
              })}
              value={props.external_id}
              icon={faAddressCard}
            />
          )
        }
        {
          props.occupation?.id && (
            <DetailsKeyValuePair
              label={intl.formatMessage({
                defaultMessage: 'Occupation', id: 'workforce.employee_profile.occupation_label'
              })}
              value={props.occupation.name}
              icon={faUserTie}
              link={occupationUrl(props.occupation.id)}
            />
          )
        }
        {
          props.manager_name && (
            <DetailsKeyValuePair
              label={intl.formatMessage({
                defaultMessage: 'Managed by', id: 'workforce.employee_profile.manager_label'
              })}
              value={props.manager_name}
              icon={faNetworkWired}
              link={
                props.manager_id && routeHelpers.showEmployee.parameterize({ id: props.manager_id })
              }
            />
          )
        }
        {
          props.department && (
            <DetailsKeyValuePair
              label={intl.formatMessage({
                defaultMessage: 'Department', id: 'workforce.employee_profile.department_label'
              })}
              value={props.department}
              icon={faBuilding}
            />
          )
        }
        {
          location && (
            <DetailsKeyValuePair
              label={intl.formatMessage({
                defaultMessage: 'Location', id: 'workforce.employee_profile.location_label'
              })}
              value={location}
              icon={faLocationDot}
            />
          )
        }
      </div>
    </div>
  );
};

EmployeeProfileDetails.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  external_id: PropTypes.string,
  manager_id: PropTypes.string,
  manager_name: PropTypes.string,
  occupation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  /* eslint-disable react/no-unused-prop-types */
  // used indirectly by helper functions
  country: PropTypes.string,
  location: PropTypes.string,
  department: PropTypes.string,
  state_or_province: PropTypes.string
  /* eslint-enable react/no-unused-prop-types */
};

/**
 * Internal component used by EmployeeProfileDetails to render a single label/value pair, such as
 * "Email: employee@example.com" A link prop can be supplied to make the value link
 * out to another page; {@link SmartLink} is used, so any types of URLs supported by that component
 * can be used.
 *
 * @param {object} props
 *   @param {object} props.icon - FontAwesome icon to render next to the label
 *   @param {string} props.label - the label to display, e.g. "Email"
 *   @param {string} props.value - the value of the attribute, e.g. "employee@example.com"
 *   @param {string} [props.link] - an optional link to direct the user to if they click on the
 *     attribute value
 * @returns {JSX.Element}
 * @constructor
 */
const DetailsKeyValuePair = (props) => {
  const valueElProps = { className: 'cr-workforce-employee-profile-details__value' };
  let valueEl;
  if (props.link) {
    valueEl = <SmartLink {...valueElProps} action={props.link}>{props.value}</SmartLink>;
  } else {
    valueEl = <div {...valueElProps}>{props.value}</div>;
  }

  return (
    <div className="cr-workforce-employee-profile-details__group">
      <div className="cr-workforce-employee-profile-details__label-group">
        <FontAwesomeIcon icon={props.icon} className="cr-workforce-employee-profile-details__icon"/>
        <div className="cr-workforce-employee-profile-details__label">{props.label}</div>
      </div>
      {valueEl}
    </div>
  );
};

DetailsKeyValuePair.propTypes = {
  icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  value: PropTypes.string.isRequired
};
