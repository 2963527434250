import { actionTypes } from 'redux-resource';
import { ActionManager } from 'utils/action_manager';
import { Action } from 'utils/action';
import { modelSynchronizer } from '../model_synchronizer';
import * as stringUtils from 'utils/string';
import { waitFor, ajax, generateUID } from 'utils';

const PROCESS_TIMEOUT = 180000;

export const organizationActions = (new ActionManager('managementOrganizationsOrganizations'))
  .add({
    url: '/api/v1/organizations/[id]',
    requestKey: 'getOrganization',
    effect: 'read',
    method: 'GET'
  }, true).add({
    url: '/api/v1/organizations/[id]',
    requestKey: 'put',
    effect: 'update',
    method: 'PUT'
  }, true);

// Returns the summary list view of a user's organizations
export const managementShortOrganizationActions = (new ActionManager('managementShortOrganizations'))
  .add({
    url: '/api/v1/organizations',
    requestKey: 'getOrganizations',
    effect: 'read',
    method: 'GET'
  }).add({
    url: '/api/v1/organizations/selector_search',
    requestKey: 'selectorSearchOrganizations',
    effect: 'read',
    method: 'GET',
    list: 'selectorSearch',
    alwaysRefresh: true
  });

export const webhookHeadersActions = (new ActionManager('managementOrganizationsWebhookHeaders'))
  .add({
    effect: 'read',
    method: 'GET',
    url: '/api/v1/organizations/[organization_id]/webhook_headers',
    requestKey: 'list',
    list: 'webhookHeaders',
    alwaysRefresh: true,
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    })
  });

export const reportingTagsActions = (new ActionManager('managementOrganizationsReportingTags'))
  .add({
    effect: 'read',
    method: 'GET',
    url: '/api/v1/organizations/[organization_id]/reporting_tags',
    requestKey: 'get',
    alwaysRefresh: true,
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    })
  });

export const pdfTemplateActions = (new ActionManager('managementOrganizationsPdfTemplates'))
  .add({
    requestKey: 'get',
    effect: 'read',
    method: 'GET',
    url: '/api/v1/organizations/[organization_id]/pdf_templates',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    })
  });

export const pdfDesignExportActions = (new ActionManager('managementOrganizationsPdfDesignExports'))
  .add({
    requestKey: 'get',
    list: 'list',
    effect: 'read',
    method: 'GET',
    url: '/api/v1/organizations/[organization_id]/pdf_design_exports/[id]',
    urlSubs: new stringUtils.BracketSubs({
      id: { param: 'id', remove: true },
      organization_id: { param: 'organization_id', remove: true }
    }),
    alwaysRefresh: true
  })
  .add({
    requestKey: 'create',
    effect: 'create',
    method: 'POST',
    list: 'created',
    url: '/api/v1/organizations/[organization_id]/pdf_design_exports',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    }),
    alwaysRefresh: true
  });

export const certificatesActions = (new ActionManager('managementOrganizationsCertificates'))
  .add({
    requestKey: 'list',
    list: 'list',
    effect: 'read',
    method: 'GET',
    url: '/api/v1/organizations/[organization_id]/pdf_definitions',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    }),
    alwaysRefresh: true
  })
  .add({
    requestKey: 'create',
    effect: 'create',
    method: 'POST',
    list: 'created',
    url: '/api/v1/organizations/[organization_id]/pdf_definitions',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    }),
    alwaysRefresh: true
  })
  .add({
    requestKey: 'delete',
    url: '/api/v1/organizations/[organization_id]/pdf_definitions/[id]',
    urlSubs: new stringUtils.BracketSubs({
      id: { param: 'id' },
      organization_id: { param: 'organization_id' }
    }),
    effect: 'delete',
    method: 'DELETE'
  })
  .add({
    requestKey: 'deleteLegacy',
    url: '/api/v1/organizations/[organization_id]/pdf_templates/[id]',
    urlSubs: new stringUtils.BracketSubs({
      id: { param: 'id' },
      organization_id: { param: 'organization_id', remove: true }
    }),
    effect: 'delete',
    method: 'DELETE'
  })
  .add({
    requestKey: 'duplicate',
    list: 'duplicated',
    url: '/api/v1/organizations/[organization_id]/pdf_definitions/[id]/duplicate',
    urlSubs: new stringUtils.BracketSubs({
      id: { param: 'id' },
      organization_id: { param: 'organization_id' }
    }),
    effect: 'read',
    method: 'POST'
  });

export const certificatePdfsActions = (new ActionManager('managementOrganizationsCertificatePdfs'))
  .add({
    requestKey: 'generate_preview',
    list: 'created',
    url: '/api/v1/organizations/[organization_id]/pdf_definitions/[id]/generate_preview',
    urlSubs: new stringUtils.BracketSubs({
      id: { param: 'id' },
      organization_id: { param: 'organization_id' }
    }),
    effect: 'create',
    method: 'POST',
    alwaysRefresh: true
  });

export const badgeTemplatePdfActions = (new ActionManager('managementOrganizationsBadgeTemplatePdfs'))
  .add({
    requestKey: 'update',
    effect: 'update',
    method: 'PUT',
    url: '/api/v1/organizations/[organization_id]/badge_template_pdfs/[id]',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    })
  }, true)
  .add({
    requestKey: 'get',
    effect: 'read',
    method: 'GET',
    url: '/api/v1/organizations/[organization_id]/badge_template_pdfs/[id]',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    })
  }, true)
  .add({
    requestKey: 'delete',
    effect: 'delete',
    method: 'DELETE',
    url: '/api/v1/organizations/[organization_id]/badge_template_pdfs/[id]',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    })
  }, true);

const organizationShowUrlRegexp = /(\/api)?\/v1\/organizations\/[a-f0-9-]+$/;

modelSynchronizer.registerSynchronizedModel(
  organizationShowUrlRegexp,
  'managementOrganizationsOrganizations'
);

modelSynchronizer.registerSynchronizedModel(
  organizationShowUrlRegexp,
  'managementShortOrganizations'
);

modelSynchronizer.registerSynchronizedModel(
  organizationShowUrlRegexp,
  (id, response, action, _url) => {
    return (dispatch) => {
      if (action === 'update') {
        const o = response.data;
        dispatch({
          type: actionTypes.UPDATE_RESOURCES,
          resources: {
            managementRecentlyViewedOrganizations: [
              {
                id: o.id,
                organization: {
                  id: o.id, name: o.name, photo_url: o.photo_url, vanity_slug: o.vanity_slug
                }
              }
            ]
          }
        });
      };
    };
  }
);

modelSynchronizer.registerSynchronizedModel(
  /(\/api)?\/v1\/organizations\/[a-f0-9-]+(\/subscription_type)?$/,
  'managementOrganizationsOrganizations'
);

export const sessionLive = new Action({
  resourceType: 'temp',
  url: '/session_live_check',
  requestKey: 'checkSessionAlive',
  effect: 'read',
  method: 'GET',
  id: 'checkSessionAlive'
});

export const analyticsFilterOptionsActions =
  (new ActionManager('managementOrganizationsAnalyticsFilterOptions'))
    .add({
      url: '/api/v1/organizations/[id]/badges/analytics_filters',
      requestKey: 'get',
      effect: 'read',
      method: 'GET',
      alwaysRefresh: true
    }, true);

export const badgeTemplateAdditionalDetailActions =
  (new ActionManager('managementOrganizationsBadgeTemplateAdditionalDetails'))
    .add({
      url: '/api/v1/organizations/[organization_id]/badge_templates/[id]/additional',
      requestKey: 'get',
      effect: 'read',
      method: 'GET',
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      alwaysRefresh: true
    }, true);

export const badgeTemplateActions =
  (new ActionManager('managementOrganizationsBadgeTemplates'))
    .add({
      requestKey: 'get',
      alwaysRefresh: true,
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      effect: 'read',
      method: 'GET',
      url: '/api/v1/organizations/[organization_id]/badge_templates/[id]'
    }, true)
    .add({
      requestKey: 'unarchive',
      effect: 'create',
      method: 'PUT',
      list: 'list',
      alwaysRefresh: true,
      urlSubs: new stringUtils.BracketSubs({
        organization_id: {
          param: 'organization_id'
        },
        badge_template_id: {
          param: 'badge_template_id'
        }
      }),
      url: '/api/v1/organizations/[organization_id]/badge_templates/[badge_template_id]/unarchive'
    })
    .add({
      requestKey: 'duplicate',
      effect: 'create',
      method: 'PUT',
      list: 'list',
      alwaysRefresh: true,
      urlSubs: new stringUtils.BracketSubs({
        organization_id: {
          param: 'organization_id'
        },
        badge_template_id: {
          param: 'badge_template_id'
        }
      }),
      url: '/api/v1/organizations/[organization_id]/badge_templates/[badge_template_id]/copy'
    })
    .add({
      requestKey: 'getList',
      list: 'list',
      alwaysRefresh: true,
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      effect: 'read',
      method: 'GET',
      url: '/api/v1/organizations/[organization_id]/badge_templates'
    })
    .add({
      requestKey: 'create',
      alwaysRefresh: true,
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      effect: 'create',
      method: 'POST',
      url: '/api/v1/organizations/[organization_id]/badge_templates'
    })
    .add({
      requestKey: 'update',
      alwaysRefresh: true,
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      effect: 'update',
      method: 'PATCH',
      url: '/api/v1/organizations/[organization_id]/badge_templates/[id]'
    }, true)
    .add({
      requestKey: 'updateCollections',
      alwaysRefresh: true,
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      effect: 'update',
      method: 'PUT',
      url: '/api/v1/organizations/[organization_id]/badge_templates/[id]/reporting_tags'
    })
    .add({
      requestKey: 'delete',
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id' },
        id: { param: 'badge_template_id' }
      }),
      effect: 'delete',
      method: 'DELETE',
      url: '/api/v1/organizations/[organization_id]/badge_templates/[id]'
    })
    .add({
      requestKey: 'archive',
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id' },
        id: { param: 'badge_template_id' }
      }),
      effect: 'update',
      method: 'PUT',
      url: '/api/v1/organizations/[organization_id]/badge_templates/[id]/archive'
    });

export const selectBadgeTemplates = (new ActionManager('managementOrganizationSelectedBadgeTemplates')
  .add({
    requestKey: 'select',
    effect: 'read',
    method: 'GET',
    list: 'list',
    alwaysRefresh: true,
    url: '/api/v1/organizations/[organization_id]/badge_templates/select',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id' }
    })
  }));

export const badgeTemplateSkillActions =
  (new ActionManager('managementOrganizationsBadgeTemplateSkills'))
    .add({
      url: '/api/v1/skills',
      requestKey: 'get',
      alwaysRefresh: true,
      effect: 'read',
      method: 'GET',
      list: 'skillsByName',
      disableSingleItemBehavior: true,
      transform: (data) => {
        return data.map((v, i) => {
          return { id: i, name: v };
        });
      }
    });

export const badgeTemplateActivityActions =
    (new ActionManager('managementOrganizationsBadgeTemplateHistory'))
      .add({
        requestKey: 'getActivityLog',
        list: 'list',
        alwaysRefresh: true,
        urlSubs: new stringUtils.BracketSubs({
          organization_id: { param: 'organization_id', remove: true },
          badge_template_id: { param: 'badge_template_id', remove: true }
        }),
        effect: 'read',
        method: 'GET',
        url: '/api/v1/organizations/[organization_id]/badge_templates/[badge_template_id]/activity_log'
      });


modelSynchronizer.registerSynchronizedModel(
  /(\/api)?\/v1\/organizations\/[a-f0-9-]+\/badge_templates\/[a-f0-9-]+$/,
  'managementOrganizationsBadgeTemplates',
  // ignore create actions, because BadgeTemplatesController#create omits permissions metadata,
  // while BadgeTemplatesController#show includes it; including it just leads to other problems that
  // must be solved (https://acclaim.atlassian.net/browse/AC-4930); so accept the additional request
  // after create
  { ignoreCreate: true }
);

const badgeTemplateReportableListCommonParams = {
  url: '/api/v1/organizations/[organization_id]/badge_templates/index_report',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true }
  }),
  defaultParams: { sort: 'name' },
  effect: 'read',
  method: 'GET'
};

export const badgeTemplateListActions =
  (new ActionManager('managementOrganizationsBadgeTemplatesList'))
    .add({
      ...badgeTemplateReportableListCommonParams,
      requestKey: 'listForAnalyticsFilter',
      list: 'filter'
    }).add({
      ...badgeTemplateReportableListCommonParams,
      requestKey: 'searchListForAnalyticsFilter',
      list: 'searchFilter'
    }).add({
      ...badgeTemplateReportableListCommonParams,
      requestKey: 'getTemplatesByName',
      list: 'templateAnalyticsByName',
      method: 'POST',
      alwaysRefresh: true
    }).add({
      ...badgeTemplateReportableListCommonParams,
      requestKey: 'getTemplatesById',
      list: 'templateAnalyticsById',
      method: 'POST',
      alwaysRefresh: true
    });

const issuerCollectionListCommonParams = {
  url: '/api/v1/organizations/[organization_id]/issuer_collections',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true }
  }),
  effect: 'read',
  method: 'GET'
};

export const issuerCollectionsListActions =
  (new ActionManager('managementOrganizationsIssuerCollectionsList'))
    .add({
      ...issuerCollectionListCommonParams,
      requestKey: 'listForTable',
      list: 'tableList',
      alwaysRefresh: true
    })
    .add({
      ...issuerCollectionListCommonParams,
      requestKey: 'searchList',
      list: 'search',
      alwaysRefresh: true
    })
    .add({
      ...issuerCollectionListCommonParams,
      requestKey: 'listForFilter',
      list: 'filter',
      alwaysRefresh: true
    })
    .add({
      ...issuerCollectionListCommonParams,
      requestKey: 'searchListForFilter',
      list: 'searchFilter'
    });

export const BadgeImageUploader = async (blob) => {
  const getS3Params = async () => {
    const result = await ajax({
      uri: '/s3_image_processor/data_attributes',
      headers: {
        Accept: 'application/json'
      },
      responseType: 'json'
    });
    return result.success && result.body;
  };

  const uploadFile = async (s3Params, blob) => {
    const data = new FormData();
    const copyParams = {
      acl: 'data-acl',
      AWSAccessKeyId: 'data-aws-access-key-id',
      policy: 'data-policy',
      signature: 'data-signature'
    };

    // Simple translations
    for (const p of Object.keys(copyParams)) {
      data.append(p, s3Params[copyParams[p]]);
    }

    data.append('success_action_status', '201');
    data.append('Content-Type', blob.type);
    data.append('X-Requested-With', 'xhr');

    const key = s3Params['data-key']
      .replace(/{timestamp}/, Date.now())
      .replace(/{unique_id}/, generateUID())
      .replace(/\${filename}/, 'image.png');
    data.append('key', key);

    data.append('file', blob);

    const result = await ajax({
      method: 'POST',
      uri: s3Params['data-url'],
      body: data
    });

    if (result.success) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(result.body, 'text/xml');
      return {
        // IE11 doesn't support innerHTML in DOMParser, so use firstChild.nodeValue instead.
        id: xmlDoc.getElementsByTagName('Key')[0].firstChild.nodeValue,
        url: xmlDoc.getElementsByTagName('Location')[0].firstChild.nodeValue
      };
    }

    return null;
  };

  const sendToCredly = async (s3Response) => {
    const result = await ajax({
      method: 'POST',
      uri: '/s3_image_processor/process',
      headers: {
        Accept: 'application/json'
      },
      body: {
        image_url: s3Response.url,
        processor_name: 'image_upload',
        delay: true,
        delay_queue: 'interactive'
      }
    });

    if (result.success) {
      let body = result.body;
      if (typeof body === 'string') {
        body = JSON.parse(body);
      }
      return body.id;
    }
    return null;
  };

  const waitForImage = async (id) => {
    return await waitFor(async () => {
      const response = (await ajax({
        uri: '/s3_image_processor/process/image_upload/' + id,
        headers: {
          Accept: 'application/json'
        },
        responseType: 'json'
      })).body;

      if (response && response.image_upload && response.image_upload.complete) {
        return response.image_upload;
      }
      return null;
    }, 1000, PROCESS_TIMEOUT / 1000);
  };

  let image;
  let lastSuccess = 'start';
  try {
    const s3Params = await getS3Params();

    if (s3Params) {
      lastSuccess = 'getS3Params';
      const uploadResponse = await uploadFile(s3Params, blob);
      if (uploadResponse) {
        lastSuccess = 'uploadFile';
        const imageId = await sendToCredly(uploadResponse);
        if (imageId) {
          lastSuccess = 'sendToAcclaim';
          image = await waitForImage(imageId);
        }
      }
    }
  } catch (e) {
    // Log promise exceptions to make debugging easier.
    console.error(e);
  }

  if (image) {
    return image;
  } else {
    console.error('Badge image upload failed. Last success=' + lastSuccess);
    return false;
  }
};
