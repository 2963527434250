import React from "react";
import { usePerBreakpoints } from './use_per_breakpoints';
import { uniqueId } from 'utils';


export const useCarouselControls = (slider) => {
  const [prevDisabled, setPrevDisabled] = React.useState(false);
  const [prevVisible, setPrevVisible] = React.useState(false);
  const [nextDisabled, setNextDisabled] = React.useState(false);
  const [nextVisible, setNextVisible] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (slider.ready) {
      const onFirstSlide = slider.currentSlide === 1;
      const onLastSlide = slider.currentSlide === slider.totalSlides;

      setPrevVisible(!onFirstSlide);
      setNextVisible(!onLastSlide);
    }
  }, [slider.currentSlide, slider.totalSlides, slider.ready]);

  const disable = React.useCallback(() => setDisabled(true), [setDisabled]);
  const enable = React.useCallback(() => setDisabled(false), [setDisabled]);

  const cPrevVisible = React.useMemo(
    () => (!disabled && !prevDisabled && prevVisible),
    [disabled, prevDisabled, prevVisible]
  );
  const cPrevDisable = React.useCallback(() => setPrevDisabled(true), [setPrevDisabled]);
  const cPrevEnable = React.useCallback(() => setPrevDisabled(false), [setPrevDisabled]);
  const cPrevTo = React.useCallback(
    () => !disabled && !prevDisabled && slider.toPrevSlide(),
    [disabled, prevDisabled, slider.toPrevSlide]
  );
  const cPrevDisabled = React.useMemo(() => (disabled || prevDisabled), [disabled, prevDisabled]);

  const cNextVisible = React.useMemo(
    () => (!disabled && !nextDisabled && nextVisible),
    [disabled, nextDisabled, nextVisible]
  );
  const cNextDisable = React.useCallback(() => setNextDisabled(true), [setNextDisabled]);
  const cNextEnable = React.useCallback(() => setNextDisabled(false), [setPrevDisabled]);
  const cNextTo = React.useCallback(
    () => !disabled && !nextDisabled && slider.toNextSlide(),
    [disabled, nextDisabled, slider.toNextSlide]
  );
  const cNextDisabled = React.useMemo(() => (disabled || nextDisabled), [disabled, nextDisabled]);

  const controls = {
    disable,
    enable,
    disabled,
    prev: {
      visible: cPrevVisible,
      disable: cPrevDisable,
      to: cPrevTo,
      disabled: cPrevDisabled,
      enable: cPrevEnable
    },
    next: {
      visible: cNextVisible,
      disable: cNextDisable,
      to: cNextTo,
      disabled: cNextDisabled,
      enable: cNextEnable
    }
  };

  return controls;
};

export const DEFAULT_CAROUSEL_SETTINGS = {
  itemsPerSlideBreakpoints: { default: 3 },
  itemKey: (carouselId, item) => item?.id && `${carouselId}-${item.id}`,
  label: 'Carousel',
  itemLabel: '',
  description: ''
};

export const useCarousel = (items, settings) => {
  settings = { ...DEFAULT_CAROUSEL_SETTINGS, ...settings };
  const [visibleItemIndexRange, setVisibleItemIndexRange] = React.useState(
    {
      start: 0,
      end: false
    }
  );
  const carouselId = React.useMemo(() => uniqueId().toString(), []);
  const [currentSlide, setCurrentSlide] = React.useState(1);
  const [totalSlides, setTotalSlides] = React.useState(null);
  const [per] = usePerBreakpoints(settings.itemsPerSlideBreakpoints);

  React.useEffect(() => {
    if (items) {
      const rangeStartIndex = (per * (currentSlide - 1));
      const rangeEndIndex = rangeStartIndex + per - 1;
      const totalItems = items.length - 1;
      setVisibleItemIndexRange({
        start: rangeStartIndex,
        end: (rangeEndIndex < totalItems ? rangeEndIndex : totalItems)
      });
    } else {
      setVisibleItemIndexRange({
        start: 0,
        end: false
      });
    }
  }, [items?.length, per, currentSlide]);

  React.useEffect(() => {
    setTotalSlides(
      // only update totalSlides when per and items are present
      // to avoid NaN errors or jumpiness in UI when this value
      // is used to render UI elements.
      (per && items) ? Math.ceil(items.length / per) : totalSlides
    );
  }, [items?.length, per]);

  React.useEffect(() => {
    if (totalSlides && totalSlides < currentSlide) {
      // set currentSlide to the last slide
      setCurrentSlide(totalSlides);
    }

    if (currentSlide < 1) {
      setCurrentSlide(1);
    }
  }, [totalSlides, currentSlide]);

  const itemIsVisible = React.useCallback((itemIdx) => {
    if (visibleItemIndexRange.end === false) {
      return false;
    }
    return (itemIdx >= visibleItemIndexRange.start && itemIdx <= visibleItemIndexRange.end);
  }, [visibleItemIndexRange]);

  const toSlideIfExists = React.useCallback((slide) => {
    if (slide === currentSlide) {
      return;
    }

    if (slide >= 1 && slide <= totalSlides) {
      setCurrentSlide(parseInt(slide));
      return true;
    }
  }, [currentSlide, totalSlides]);

  const toNextSlide = React.useCallback(
    () => toSlideIfExists(currentSlide + 1), [currentSlide, totalSlides]
  );

  const toPrevSlide = React.useCallback(
    () => toSlideIfExists(currentSlide - 1), [currentSlide, totalSlides]
  );

  const isLastSlide = (currentSlide === totalSlides);

  const slider = {
    label: settings.label,
    description: settings.description,
    currentSlide,
    setCurrentSlide: toSlideIfExists,
    toNextSlide,
    toPrevSlide,
    isLastSlide,
    totalSlides,
    items,
    itemsPerSlide: per,
    totalItems: items?.length || 0,
    visibleItemIndexRange,
    // if per is unresolved and/or totalSlides is not yet calculated
    // UI's can attempt to render NaN or undefined values. To alleviate the need of dev
    // to manually check each of these properties, add the `ready` flag
    ready: items && per && typeof totalSlides == 'number'
  };

  slider.controls = useCarouselControls(slider);

  const itemContextValue = React.useCallback((idx) => ({
    index: idx,
    key: settings.itemKey(carouselId, items[idx]) || `${carouselId}-${idx}`,
    label: typeof settings.itemLabel === 'function' ? settings.itemLabel(items[idx]) : settings.itemLabel,
    data: items[idx],
    visible: itemIsVisible(idx),
    last: !items[idx + 1],
    first: idx === 0,
    slider: {
      ...slider,
      itemContextValue
    }
  }), [items, currentSlide, totalSlides, itemIsVisible, settings.itemLabel, slider]);

  return {
    ...slider,
    itemContextValue
  };
};
