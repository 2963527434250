import React from 'react';
import PropTypes from 'prop-types';
import { uuidPropType } from 'prop_types';
import { useAlerts } from 'alert';
import { useFormProps } from 'form';
import { employeeActions } from 'pages/workforce/employees/employees_actions';
import { EmployeesRouteHelpers } from 'pages/workforce/route_helpers';
import { FormattedMessage } from 'react-intl';
import { EmployeeForm } from './employee_form';
import './employee_add.sass';


/**
 * Component that displays the add (create) Employee page.
 *
 * @param {Object} props
 *   @param {String} props.baseUrl - The route that the EmployeeList is rendered from the Layout
 *   @param {Object} props.organization - Organization to retrieve employees for
 *   @param {Object} props.routeProps
 *      @param {Object} props.routeProps.history - History from CredlyRoute
 */
export const EmployeeAdd = (props) => {
  const alerts = useAlerts();
  const routeHelpers = EmployeesRouteHelpers.forUrl(props.baseUrl);
  const [formProps] =
    useFormProps(
      employeeActions.get('create'),
      { initialValues: { organization_id: props.organization.id } }
    );

  const onSubmitSuccess = () => {
    alerts.add({
      type: 'notice',
      text: (
        <FormattedMessage
          id="workforce.employees.show.add.notice"
          defaultMessage="Your employee has been added."
        />)
    });
    props.routeProps.history.push(routeHelpers.employees.parameterize());
  };

  return (
    <div className="c-employee-add">
      <EmployeeForm
        formProps={formProps}
        onSubmitSuccess={onSubmitSuccess}
        organization={props.organization}
      />
    </div>
  );
};

EmployeeAdd.propTypes = {
  baseUrl: PropTypes.string,
  organization: PropTypes.shape({
    id: uuidPropType,
    integrated_with_faethm: PropTypes.bool
  }),
  routeProps: PropTypes.object
};
