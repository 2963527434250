import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import CredlyRoute from 'controls/credly_route';
import { CredlySwitch } from 'controls/credly_switch';
import { AccountsRouteHelpers } from 'pages/workforce/route_helpers';
import { Routes as SubscriptionsRoutes } from './subscriptions/routes';
import { useIntl } from 'react-intl';

/**
 * Top-level for the "Accounts" section of workforce
 *
 * @param props
 *   @param {string} props.baseUrl - the base URL of the preferences section of workforce org
 *   @param {OrganizationBackboneModel} props.organization - the Backbone organization model for the
 *     current organization context
 * @returns {React.element}
 */
export const Routes = (props) => {
  const intl = useIntl();
  const routeHelpers = AccountsRouteHelpers.forUrl(props.baseUrl);

  return (
    <CredlySwitch>
      <Redirect from={routeHelpers.root.route()} exact to={routeHelpers.subscriptions.route()}/>
      <CredlyRoute
        path={routeHelpers.subscriptions.route()}
        title={intl.formatMessage({
          id: 'workforce.accounts.routes.subscription',
          defaultMessage: 'Subscription'
        })}
        render={(routeProps) =>
          <SubscriptionsRoutes baseUrl={routeProps.match.url} organization={props.organization}/>
        }
      />
    </CredlySwitch>
  );
};

Routes.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  organization: PropTypes.object
};
