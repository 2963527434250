import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { faExternalLinkSquare } from '@fortawesome/pro-light-svg-icons/faExternalLinkSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Heading } from 'controls/heading';
import { badgeTemplateUrl, organizationVanityUrl } from 'utils/routes';
import BadgePlaceholderImage from 'images/badge-placeholder.png';

import './learning_plan_details_header.sass';

export const LearningPlanDetailsHeader = ({ badgeTemplate }) => {
  const intl = useIntl();

  const orgUrl = organizationVanityUrl(badgeTemplate.owner_vanity_slug);
  const templateUrl = badgeTemplateUrl({ vanity_url: orgUrl }, badgeTemplate);

  return (
    <div className="workforce-learning-plans__heading-container">
      <img
        src={badgeTemplate.image || BadgePlaceholderImage}
        className="workforce_learning_plans_badges_activity__image"
        alt={intl.formatMessage({
          id: 'workforce.learning.plan.header.image.alt',
          defaultMessage: 'Badge image'
        })}
      />
      <div className="workforce-learning-plans__heading-text">
        <div>
          <Heading appearance="custom" className="workforce-learning-plans__heading">
            {badgeTemplate.name}
          </Heading>
          <FormattedMessage
            id="workforce.employees.learning.activity.description.issued.by"
            defaultMessage="Issued by "
          />
          <Link
            to={orgUrl}
            className="workforce-learning_plans-organization__see-profile"
          >
            {intl.formatMessage(
              {
                id: 'workforce.learning.plan.show.badge.link',
                defaultMessage: '{badgeOwnerName}'
              },
              { badgeOwnerName: badgeTemplate.owner_name }
            )}
          </Link>
        </div>
        {
          !badgeTemplate.archived &&
            <div className="workforce_learning_plan_link_text-image">
              <Link
                className="workforce-learning_plan-profile-header__view-badge-link"
                to={templateUrl}
              >
                <FormattedMessage
                  id="workforce.learning.learning_plan_details.view_badge"
                  defaultMessage="View Badge"
                />
                <FontAwesomeIcon
                  className="learning_plan_view_badge_button_icon"
                  icon={faExternalLinkSquare}
                />
              </Link>
            </div>
        }
      </div>
    </div>
  );
};

LearningPlanDetailsHeader.propTypes = {
  badgeTemplate: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    owner_name: PropTypes.string.isRequired,
    owner_vanity_slug: PropTypes.string.isRequired,
    archived: PropTypes.bool
  }).isRequired
};
