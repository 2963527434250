import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { useQueryString } from 'controls/with_query_string';
import {
  useStandardSortHandler, standardInitialSort
} from 'controls/data_table/use_standard_sort_handler';
import { ResourceStatusPropType } from 'utils/prop_types';
import { makeClassName } from 'utils';
import { OrgMgmtDataTable } from 'pages/workforce/organizations';
import { EmployeesEmptySearch } from 'pages/workforce/employees/employees_empty_search';
import { EmployeeBadgeTemplateInfo } from './employee_badge_template_info';

import './employee_badge_templates_table.sass';

/**
 * A table view of badges earned by employees of
 * the viewing organization.
 *
 * @param {Object} props
 *   @param {Array} props.data - Badge data array.
 *   @param {ResourceStatus} props.status - Redux status for the data request.
 */

export const EmployeeBadgeTemplatesTable = props => {
  const sortHandler = useStandardSortHandler();
  const queryString = useQueryString(props.routeProps.location);

  const currentSort =
    standardInitialSort(queryString.get('sort')) || { key: 'name', ascending: true };

  const columnCommonProps = {
    headerClassName: "c-employees-badge-templates-table__column-header",
    className: "c-employees-badge-templates-table__detail-column"
  };
  return (
    <div>
      <OrgMgmtDataTable
        data={props.data}
        header={{}}
        loader={{ loading: props.status.pending }}
        keyboardControl
        rowHref={(badgeTemplate) => badgeTemplate.path}
        sort={{ onSort: sortHandler, ...currentSort }}
        hoverStyle="default"
        emptyResultNode={EmployeesEmptySearch}
      >
        <OrgMgmtDataTable.Col
          header={
            <FormattedMessage
              id="title.badge"
              defaultMessage="Badge"
            />
          }
          sortKey="name"
          className="c-employees-badge-templates-table__badges-column"
          headerClassName={makeClassName(
            "c-employees-badge-templates-table__column-header__badges",
            columnCommonProps.headerClassName
          )}
          renderer={(badgeTemplate) => <EmployeeBadgeTemplateInfo badgeTemplate={badgeTemplate} />}
        />
        <OrgMgmtDataTable.Col
          hideAt={["mobile"]}
          header={
            <FormattedMessage
              id="badge.type"
              defaultMessage="Type"
            />
          }
          sortKey="type_category"
          {...columnCommonProps}
          renderer={badge => badge.type_category}
        />
        <OrgMgmtDataTable.Col
          hideAt={["mobile"]}
          header={
            <FormattedMessage
              id="badge.level"
              defaultMessage="Level"
            />
          }
          sortKey="level"
          {...columnCommonProps}
          renderer={badge => badge.level}
        />
        <OrgMgmtDataTable.Col
          hideAt={["mobile"]}
          header={
            <FormattedMessage
              id="workforce.employees.badges.total_employees"
              defaultMessage="Total Employees"
            />
          }
          sortKey="employee_count"
          initialSortDescending
          {...columnCommonProps}
          renderer={badge => badge.times_issued.toLocaleString()}
        />
        <OrgMgmtDataTable.Col
          hideAt={["mobile"]}
          header={
            <FormattedMessage
              id="workforce.employees.badges.last_issued"
              defaultMessage="Last Issued"
            />
          }
          sortKey="last_issued"
          initialSortDescending
          {...columnCommonProps}
          renderer={badge =>
            <FormattedDate
              value={badge.last_issued}
              year="numeric"
              month="short"
              day="numeric"
            />
          }
        />
      </OrgMgmtDataTable>
    </div>
  );
};

EmployeeBadgeTemplatesTable.propTypes = {
  data: PropTypes.array,
  status: ResourceStatusPropType.isRequired,
  routeProps: PropTypes.object
};
