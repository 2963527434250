import * as routes from 'utils/routes';
import urlUtils from 'utils/url';
import * as objUtils from 'utils/object';
import {DEFAULT_URL} from 'utils/routes';


/**
 * Redirect after successful signin.
 */
export const redirectAfterSignin = (redirectUrl) => {
  let reloadUrl;

  if (routes.isSigninUrl(routes.currentHref()) || /^\/?$/.test(routes.currentPath())) {
    // If we're at the signin url, load the default page.
    reloadUrl = redirectUrl || DEFAULT_URL;
  } else {
    // Otherwise, reload the current page.
    reloadUrl = urlUtils.removeParam(routes.currentHref(), 'twoFactorAuth');
  }

  // Force a page reload, since we don't currently have way to reload initialAppValues.
  if (reloadUrl === routes.currentHref()) {
    routes.reload();
  } else {
    routes.setHref(reloadUrl);
  }
};

/**
 * Function to check whether Sign in with Apple is enabled.
 */
export const isAppleSSOEnabled = () => !!window?.initialAppValues?.sso?.apple?.clientId;

/**
 * Function to check whether Sign in with Google is enabled.
 */
export const isGoogleSSOEnabled = () => !!window?.initialAppValues?.sso?.google?.clientId;

/**
 * Function to check whether Sign in with Microsoft is enabled.
 */
export const isMicrosoftSSOEnabled = () => !!window?.initialAppValues?.sso?.microsoft?.clientId;


/**
 * Devise requires all signin params to be in a user object. This function can be used to
 * intercept Form.submit() and adjust params appropriately.
 *
 * @param {Object} values - The values passed to Form.submit().
 * @returns {Object} The modified values object.
 */
export const prepareSigninSubmit = values => {
  let modValues = {user: {}};

  objUtils.forEach(values, (key, val) => {
    if (key in signinKeys) {
      modValues.user[key] = val;
    }
    else {
      modValues[key] = val;
    }
  });

  return modValues;
};
const signinKeys = {
  email: 1,
  password: 1,
  password_confirmation: 1,
  reset_password_token: 1,
  nonce_token: 1,
  otp_attempt: 1,
  data: 1,
  remember_me: 1
};
