import React from 'react';
import PropTypes from 'prop-types';
import { HeaderMenuNavToggle } from 'header';
import { getProfileAction } from 'profile';
import { CurrentOrganization } from './current_organization';

import './workforce_organization_header.sass';

/**
 * Displays the custom Workforce header, which replaces the standard Credly header on non-mobile
 * screens within Workforce.
 *
 * @param {object} props
 *   @param {object} props.organization - data related to the current Workforce organization
 * @returns {JSX.Element}
 * @constructor
 */
export const WorkforceOrganizationHeader = (props) => {
  const [currentUserState] = getProfileAction.useAction();

  return (
    <div className="cr-workforce-organization-header">
      <CurrentOrganization organization={props.organization}/>
      <HeaderMenuNavToggle currentUser={currentUserState.resources}/>
    </div>
  );
};

WorkforceOrganizationHeader.propTypes = {
  organization: PropTypes.object.isRequired
};
