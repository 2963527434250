import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from 'controls/button';
import { LearningRouteHelpers } from 'pages/workforce/route_helpers';
import { LoadingSpinner } from 'controls/loading_spinner';
import { WithSectionHeader } from 'controls/section_header';
import { useToggle, useRaiseOnFailedRequest } from 'utils/react_utils';
import { ChicletGroup } from 'pages/workforce/components/chiclet';
import { LearningPlanActions } from 'pages/workforce/learning/actions';
import { LearningPlanPageDropdown } from 'pages/workforce/learning/shared/learning_plan_page_dropdown';
import { RemoveAllRecommendationsModal } from 'pages/workforce/learning/shared/remove_all_recommendations_modal';
import { BadgeDetailEmployeeTable } from './badge_detail_employee_table';
import { BadgeDetailStats } from './badge_detail_stats';
import { LearningPlanDetailsHeader } from 'pages/workforce/learning/shared/learning_plan_details_header';
import { Redirect } from "react-router";

import './show.sass';

export const LearningPlanDetails = (props) => {
  const intl = useIntl();
  const [displayRemoveAll, toggleDisplayRemoveAll] = useToggle(false);

  const [learningPlanData] =
    LearningPlanActions.useOnMount('show', props.learningPlanId,
      { organization_id: props.organizationId });

  const data = learningPlanData.resources;
  const routeHelpers = LearningRouteHelpers.forUrl(props.baseUrl);

  /* eslint-disable camelcase */
  const sectionHeader = React.useCallback(() => {
    if (data?.badge_template) {
      return (<LearningPlanDetailsHeader badgeTemplate={data.badge_template}/>);
    }
    return null;
  }, [data?.badge_template]);
  /* eslint-enable camelcase */

  useRaiseOnFailedRequest(
    learningPlanData.status,
    intl.formatMessage(
      {
        id: 'workforce.learning.plan.show.failed_request',
        defaultMessage: 'Organization ({organizationId}): LearningPlan > Learning Plan Badge Details'
      },
      { organizationId: props.organizationId }
    )
  );

  const renderRemoveRecommendationModal = () => {
    return (
      <RemoveAllRecommendationsModal
        showModal={displayRemoveAll}
        setShowModal={toggleDisplayRemoveAll}
        organizationId={props.organizationId}
        learningPlanId={props.learningPlanId}
        baseUrl={props.baseUrl}
      />
    );
  };

  if (learningPlanData?.status.pending ||
    (learningPlanData?.status.idle && !learningPlanData?.resources)) {
    return <LoadingSpinner position="window-center" />;
  }

  if (learningPlanData?.status?.succeeded &&
    data.total_employments === 0) {
    return <Redirect to={routeHelpers.learningPlans.parameterize()}/>;
  }

  return (
    <>
      {learningPlanData?.status?.succeeded && (
        <WithSectionHeader sectionHeader={sectionHeader}>
          <div className="col-12">
            <div className="workforce-learning-plans-chiclet_row">
              <ChicletGroup layout="quad">
                <BadgeDetailStats
                  employments={data.total_employments}
                  viewed={data.total_viewed}
                  clicked={data.total_clicked}
                  earned={data.total_earned}
                />
              </ChicletGroup>
            </div>
            <div className="workforce-learning-plans-dropdown">
              {
                data.badge_template.badge_template_earnable &&
                  <Button
                    className="workforce-learning-plans-add-employeee_button"
                    to={LearningRouteHelpers.forUrl(props.baseUrl).edit.parameterize(
                      {
                        learningPlanId: props.learningPlanId,
                        organization_id: props.organizationId
                      }
                    )}
                  >
                    <FormattedMessage
                      id="workforce.employees.employees.employee_directory.add_employee"
                      defaultMessage="Add Employee"
                    />
                  </Button>
              }
              <LearningPlanPageDropdown
                toggleDisplayRemoveAll={toggleDisplayRemoveAll}
                removelAllenabled={!data.learning_plan_employments}
              />
            </div>
            <BadgeDetailEmployeeTable
              baseUrl={props.baseUrl}
              organizationId={props.organizationId}
              learningPlanId={props.learningPlanId}
              location={props.location}
            />
          </div>
        </WithSectionHeader>
      )}
      {renderRemoveRecommendationModal()}
    </>
  );
};

LearningPlanDetails.propTypes = {
  organizationId: PropTypes.string.isRequired,
  learningPlanId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired
};
