import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';

export const directoryExportActions =
  new ActionManager('managementOrganizationsDirectoryExports').add({
    requestKey: 'create',
    url: '/api/v1/organizations/[organization_id]/directory_exports',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    }),
    effect: 'create',
    method: 'POST',
    list: 'created',
    alwaysRefresh: true
  }).add({
    requestKey: 'get',
    url: '/api/v1/organizations/[organization_id]/directory_exports/[id]',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true },
      id: { param: 'id', remove: true }
    }),
    effect: 'read',
    method: 'GET',
    list: 'read',
    alwaysRefresh: true
  });
