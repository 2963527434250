import React from 'react';
import PropTypes from 'prop-types';
import { useBrowserQueryString } from 'controls/with_query_string';
import { PaginationMetadata } from 'management/controls/pagination_metadata';
import { filter } from 'pages/public/organization/earner_directory/earner_directory_actions';
import { DirectorySortSelector } from 'pages/public/organization/earner_directory/shared/directory_sort_selector';
import { EmployeeDirectoryDropdown } from 'pages/public/organization/earner_directory/shared/employee_directory_dropdown';
import { useToggle } from 'utils/react_utils';
import { EmployeeDirectoryContext } from 'pages/workforce/employees/employees/employee_directory';
import { DirectoryExport } from '../directory_export';
import './directory_results_header.sass';

/**
 * Component that renders the content to be displayed one row above the directory results
 * content such as information about earner count, sort selector, export, etc.
 *
 * @param {Object} props
 *   @param {Node} [props.addRowTag] - Component containing the button for adding a new "row",
 *     in the case of employees see EmployeeDirectory
 *   @param {Object} props.baseParams - Query params to pass through in all API requests.
 *   @param {Object} props.createExportArgs - Object describing the type of export that will
 *   be created. See EmployeeDirectory.
 *   @param {Object} props.readExportArgs - Object describing the type of export that will
 *   be created. See EmployeeDirectory.
 *   @param {Boolean} props.exportEnabled - Displays an export button to export results if enabled.
 *   @param {Boolean} props.exportRemoveFilter - Removes the addition of the query string to
 *   the export creation action body
 *   @param {Boolean} [props.sortSelectorEnabled=true] - Controls if the DirectorySortSelector in
 *     this component should be rendered
 */
export const DirectoryResultsHeader = (props) => {
  const [filterState] = props.filterAction.useAction();
  const meta = filterState.metadata;
  const queryString = useBrowserQueryString();
  const [displayExport, toggleDisplayExport] = useToggle(false);
  const employeeDirectoryContext = React.useContext(EmployeeDirectoryContext);
  const inviteEmployeesMetadata = employeeDirectoryContext?.inviteEmployeesMetadata;
  const inEmployeeDirectory = employeeDirectoryContext?.inEmployeeDirectory;

  let createExportArgs = {};
  if (props.exportEnabled) {
    if (props.exportRemoveFilter) {
      createExportArgs = {
        ...props.baseParams,
        ...props.createExportArgs
      };
    } else {
      createExportArgs = {
        ...createExportArgs,
        ...queryString.get(),
        ...props.baseParams,
        ...props.createExportArgs
      };
      delete createExportArgs.page;
    }
  }

  const renderAddRowTag = () => {
    if (props.addRowTag) {
      return (
        <div className="c-directory-results-header__add-row-container">
          {props.addRowTag}
        </div>
      );
    }
  };

  const renderExportButton = () => {
    if (props.exportButton && !inEmployeeDirectory) {
      return props.exportButton(props);
    }
  };

  const renderApplicableActions = () => {
    if (inEmployeeDirectory) {
      return (
        <>
          {renderAddRowTag()}
          <EmployeeDirectoryDropdown
            exportEnabled={props.exportEnabled}
            inviteEmployeesEnabled={inviteEmployeesMetadata.enabled}
            exportOccupationEnabled={inviteEmployeesMetadata.exportOccupationEnabled}
            toggleDisplayExport={toggleDisplayExport}
            toggleDisplayInvite={inviteEmployeesMetadata.toggle}
            toggleDisplayExportOccupations={inviteEmployeesMetadata.toggleDisplayExportOccupations}
          />
          <DirectoryExport
            className="c-directory-results-header__export"
            readExportArgs={props.readExportArgs}
            createExportArgs={createExportArgs}
            show={displayExport}
            shouldClose={toggleDisplayExport}
          />
        </>
      );
    } else {
      return (
        <>
          {renderAddRowTag()}
          {renderExportButton()}
        </>
      );
    }
  };

  return (
    <>
      <div className="c-directory-results-header">
        <div className="c-directory-results-header__results-count-sort-container">
          <div>
            <span className="c-directory-results-header__results-count-value">
              <PaginationMetadata hideNoResults metadata={meta} exceedNumber={10000}/>
            </span>
          </div>
          {props.sortSelectorEnabled && <DirectorySortSelector/>}
        </div>
        {renderApplicableActions()}
      </div>
    </>
  );
};

DirectoryResultsHeader.propTypes = {
  addRowTag: PropTypes.node,
  baseParams: PropTypes.object,
  exportButton: PropTypes.func,
  createExportArgs: PropTypes.object,
  readExportArgs: PropTypes.object,
  exportEnabled: PropTypes.bool,
  exportRemoveFilter: PropTypes.bool,
  filterAction: PropTypes.object,
  sortSelectorEnabled: PropTypes.bool
};

DirectoryResultsHeader.defaultProps = {
  filterAction: filter,
  createExportArgs: {},
  readExportArgs: {},
  sortSelectorEnabled: true
};
