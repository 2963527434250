import { useEffect } from 'react';

/**
 * Assigns a custom className to the footer in the non-React layout. Useful for customizing the
 * footer to better match the React-managed content.
 *
 * @param {string} className - the custom className to assign to the footer element
 */
export const useCustomFooterClassName = (className) => {
  useEffect(() => {
    /* eslint-disable no-unused-expressions */
    document.getElementById('footer')?.classList?.add(className);
    return () => {
      document.getElementById('footer')?.classList?.remove(className);
    };
    /* eslint-enable no-unused-expressions */
  }, [className]);
};
