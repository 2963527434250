import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSpinner } from 'controls/loading_spinner';
import PropTypes from 'prop-types';
import { SimpleButton } from 'controls/button';
import Dialog from 'controls/dialog';
import { downloadUrl } from 'utils/download';
import { useDebounced } from 'utils/react_utils';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { makeClassName } from 'utils';
import { Button } from 'controls/button';

import './export.sass';

/**
 * A general use component that solely renders the Dialog that starts and polls for
 * an export being ready. This is useful for components where the button to
 * render the export dialog is nested in another modal view or some component where it is hidden
 * after opening the dialog (otherwise the dialog itself would not be shown).
 * See below for other components that integrates the rendering of an export Button.
 *
 * @param {object} props
 *   @param {object} props.createExportData - Redux resource data related to the response given from
 *   creating an export. Should at least contain the state of the export (ready, working, done, failed)
 *   @param {object} props.createExportStatus - Redux status data related to create the export
 *   @param {func} props.createExport - Redux action to send a request to create an export, will
 *   be called with props.createExportArgs as the args.
 *   @param {func} props.makeDownloadUrl - Callback function which should return the downloadUrl of the
 *   completed export. Will be called with the id of the export as its argument.
 *   @param {object} props.readExportArgs - Additional args that may be necessary to make the request
 *   to read the export such as an organization id will be merged with the id of the export
 *   obtained after creating it.
 *   @param {func} props.readExportAction - An action that will be used to query the backend
 *   for directory export data. Will be called with the id of the export as an argument.
 *   @param {object} props.readExportState - An object representing the redux state of the
 *   readExportAction.
 *   @param {function} props.onDownloadCleanup - Optional callback function that is triggered
 *   after downloading the export
 *   @param {boolean} props.noEmail - Set to true to indicate to the user that an email
 *   will not be sent.
 *   @param {string} props.
 * @returns {React.element}
 */
export const ExportDialog = (props) => {
  const [downloadReady, setDownloadReady] = useState(false);
  useEffect(() => {
    if (props.show) {
      props.createExport(props.createExportArgs);
    }
    return () => {
      setDownloadReady(false);
    };
  }, [props.show]);

  let eventType;
  const resourceType = props.readExportState?.requestDetails?.resourceType;
  if (resourceType === "managementOrganizationsBadgeTemplatesExport") {
    eventType = "export_template_list_success";
  } else if (resourceType === "managementOrganizationsRecommendations") {
    eventType = "export_recommendations_list_success";
  };

  const dialogContent = () => {
    if (downloadReady) {
      const exportId = props.createExportData.id;
      return (
        <ExportReady
          downloadUrl={props.makeDownloadUrl(exportId)}
          onDownloadCleanup={props.onDownloadCleanup}
          eventType={eventType}
          noEmail={props.noEmail}
          shouldClose={props.shouldClose}
        />
      );
    } else if (props.createExportStatus.idle || props.createExportStatus.pending) {
      return <ExportInProgress noEmail={props.noEmail} shouldClose={props.shouldClose} />;
    } else if (props.createExportStatus.succeeded) {
      return (
        <ExportPoll
          exportId={props.createExportData.id}
          readExportAction={props.readExportAction}
          readExportArgs={props.readExportArgs}
          readExportState={props.readExportState}
          downloadReady={() => setDownloadReady(true)}
          noEmail={props.noEmail}
          shouldClose={props.shouldClose}
        />
      );
    }
  };

  return (
    <Dialog
      className={props.className}
      title="Exporting"
      show={props.show}
      shouldClose={props.shouldClose}
      size="half"
    >
      {dialogContent()}
    </Dialog>
  );
};

ExportDialog.propTypes = {
  className: PropTypes.string,
  createExportData: PropTypes.object,
  createExportStatus: PropTypes.object,
  createExport: PropTypes.func,
  makeDownloadUrl: PropTypes.func,
  readExportAction: PropTypes.func,
  readExportArgs: PropTypes.object,
  readExportState: PropTypes.object,
  createExportArgs: PropTypes.object,
  noEmail: PropTypes.bool,
  onDownloadCleanup: PropTypes.func,
  shouldClose: PropTypes.func,
  show: PropTypes.bool
};

/**
 * A general use component that renders its children with an onClick activator that will handle
 * making requests to the backend to create, poll, and display a download button for exports.
 *
 * @param {object} props
 *   @param {string} props.buttonType - The type of SimpleButton that should be rendered for the export
 *   button component.
 *   @param {object} props.createExportData - Redux resource data related to the response given from
 *   creating an export. Should at least contain the state of the export (ready, working, done, failed)
 *   @param {object} props.createExportStatus - Redux status data related to create the export
 *   @param {func} props.createExport - Redux action to send a request to create an export, will
 *   be called with props.createExportArgs as the args.
 *   @param {func} props.makeDownloadUrl - Callback function which should return the downloadUrl of the
 *   completed export. Will be called with the id of the export as its argument.
 *   @param {func} props.readExportAction - An action that will be used to query the backend
 *   for directory export data. Will be called with the id of the export as an argument.
 *   @param {object} props.readExportState - An object representing the redux state of the
 *   readExportAction.
 *   @param {string} props.buttonText - Text rendered within SimpleButton. Defaults to "Export".
 *   @param {string} props.
 * @returns {React.element}
 */
export const Export = (props) => {
  const [showExportDialog, setShowExportDialog] = useState(false);

  const closeDialogAndCleanup = () => {
    setShowExportDialog(false);
  };

  const onExportButtonClick = () => {
    setShowExportDialog(true);
  };

  return (
    <div className={makeClassName("c-export", props.className)}>
      <SimpleButton type={props.buttonType} onClick={onExportButtonClick}>
        {props.buttonText}
      </SimpleButton>
      <ExportDialog
        show={showExportDialog}
        shouldClose={closeDialogAndCleanup}
        {...props}
      />
    </div>
  );
};

Export.propTypes = {
  buttonType: PropTypes.string,
  className: PropTypes.string,
  createExportData: PropTypes.object,
  createExportStatus: PropTypes.object,
  createExport: PropTypes.func,
  makeDownloadUrl: PropTypes.func,
  readExportAction: PropTypes.func,
  readExportState: PropTypes.object,
  createExportArgs: PropTypes.object,
  readExportArgs: PropTypes.object,
  onDownloadCleanup: PropTypes.func,
  buttonText: PropTypes.string
};

Export.defaultProps = {
  buttonType: 'primary',
  buttonText: 'Export'
};

/**
 * A sub component used by Export. Visually just renders the ExportInProgress component
 * but has after render useEffects such as polling the api for export data and signalling its
 * parent component that the export is ready for download.
 *
 * @param {object} props
 *   @param {string} props.exportId - The id of the export to poll for
 *   @param {func} props.downloadReady - Callback function that is called when
 *   the export is ready for download
 * @returns {React.element}
 */
export const ExportPoll = (props) => {
  const exportData = props.readExportState.resources[0];
  const readArgs = { id: props.exportId, ...(props.readExportArgs || {}) };
  const readExportPoll = useDebounced(() => props.readExportAction(readArgs));

  useEffect(() => {
    if (!exportData || exportData.id !== props.exportId || ['ready', 'working'].includes(exportData.state)) {
      readExportPoll();
    } else if (exportData.state === 'done') {
      props.downloadReady && props.downloadReady();
    }
  });
  return <ExportInProgress noEmail={props.noEmail} shouldClose={props.shouldClose}/>;
};

ExportPoll.propTypes = {
  exportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  downloadReady: PropTypes.func,
  readExportAction: PropTypes.func,
  shouldClose: PropTypes.func,
  readExportState: PropTypes.object,
  readExportArgs: PropTypes.object,
  noEmail: PropTypes.bool
};

/**
 * A sub component used by Export and ExportPoll that purely displays HTML.
 * Renders visual content signaling that export is still in progress.
 * @param {object} props
 *  @param {boolean} props.noEmail - When set to true will hide text
 *  indicating to user that an email will be sent to them.
 * @returns {React.element}
 */
export const ExportInProgress = (props) => (
  <Dialog.Content>
    <p>
      Larger reports may take a little longer to generate.
      {!props.noEmail &&
        "If you do not wish to wait, a copy of this report will also be emailed to you."
      }
    </p>
    <div className="c-export__generating-report__item">
      <LoadingSpinner className="c-export-in-progress__spinner" size="small"/>
      <span className="c-export__generating-your-report">Generating report</span>
    </div>
    <div className="c-export__actions">
      <Button type="min" onClick={() => props.shouldClose()}>Cancel</Button>
      <Button disabled>Download</Button>
    </div>
  </Dialog.Content>
);

ExportInProgress.propTypes = {
  noEmail: PropTypes.bool,
  shouldClose: PropTypes.func
};


/**
 * A sub component used by Export. Displays visual content indicating that the export is ready
 * for download and downloads the export when the user clicks the download button.
 *
 * @param {object} props
 *   @param {string} props.downloadUrl - The url that the export can be downloaded from
 *   @param {func} props.onDownloadCleanup - Callback function that is called when the user
 *   has downloaded the export csv.
 * @returns {React.element}
 */
export const ExportReady = (props) => {
  const downloadExportButtonRef = useRef();
  const handleDownload = () => {
    downloadUrl(props.downloadUrl, null, downloadExportButtonRef.current, props.eventType);
    props.onDownloadCleanup && props.onDownloadCleanup();
    props.shouldClose && props.shouldClose();
  };
  return (
    <Dialog.Content>
      <p>
        Larger reports may take longer to generate.
        {!props.noEmail &&
          "If you do not wish to wait, a copy of this report will also be emailed to you."
        }
      </p>
      <div className="c-export__generating-report__item">
        <FontAwesomeIcon className="c-export-ready__icon" icon={faCheck}/>
        <span className="c-export__generating-your-report">Report complete</span>
      </div>
      <div className="c-export__actions" ref={downloadExportButtonRef}>
        <Button type="min" onClick={() => props.shouldClose()}>Cancel</Button>
        <Button onClick={() => handleDownload()}>Download</Button>
      </div>
    </Dialog.Content>
  );
};

ExportReady.propTypes = {
  downloadUrl: PropTypes.string.isRequired,
  onDownloadCleanup: PropTypes.func,
  shouldClose: PropTypes.func,
  eventType: PropTypes.string,
  noEmail: PropTypes.bool
};
