import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { GenericSectionHeader } from 'controls/section_header';
import { AccountsRouteHelpers } from 'pages/workforce/route_helpers';
import { useIntl } from 'react-intl';

import './accounts_section_header.sass';
/**
 * Renders the section header for the "Accounts" section of workforce org. Consumes
 *
 * @param {Object} props
 *   @param {string} props.baseUrl - the base URL of workforce org under which the
 *     accounts section is nested (e.g. /workforce/organizations/org-1)
 * @returns {React.element}
 */
export const AccountsSectionHeader = (props) => {
  const routeHelpers = AccountsRouteHelpers.forUrl(props.baseUrl);
  const intl = useIntl();
  const items = useMemo(() => {
    const items = [
      {
        action: routeHelpers.subscriptions.parameterize(),
        label: intl.formatMessage({
          id: 'workforce.account.section_header.subscription',
          defaultMessage: 'Subscription'
        })
      }
    ];
    return items;
  }, [props.baseUrl]);

  return (
    <GenericSectionHeader
      heading={intl.formatMessage({
        id: 'workforce.account.section_header',
        defaultMessage: 'Account'
      })}
      items={items}
      breadcrumbs={false}
    />
  );
};

AccountsSectionHeader.propTypes = {
  baseUrl: PropTypes.string.isRequired
};
