const mapCoordinateToColors = (coordinate) => {
  if (coordinate.x <= 50 && coordinate.y > 0) {
    return '#167A62';
  } else if (coordinate.x > 50 && coordinate.y > 0) {
    return '#B14F17';
  } else if (coordinate.x > 50 && coordinate.y <= 0) {
    return '#167A62';
  } else if (coordinate.x <= 50 && coordinate.y <= 0) {
    return '#4E4E4E';
  }
};

const chartBackgroundColors = {
  topLeft: '#C6E0DA',
  topRight: '#F8DBCB',
  bottomRight: '#C6E0DA',
  bottomLeft: '#EFEFEF'
};

export const makeDrawChartBackground = () => {
  let chartQuadrants = [];
  return (chart) => {
    if (chartQuadrants.length > 0) {
      chartQuadrants.forEach(q => q.destroy());
    }
    chartQuadrants = [];
    const xAxis = chart.xAxis[0];
    const yAxis = chart.yAxis[0];
    const x0 = xAxis.toPixels(0);
    const y0 = yAxis.toPixels(-110);
    const x1 = xAxis.toPixels(50);
    const y1 = yAxis.toPixels(0);
    const x2 = xAxis.toPixels(100);
    const y2 = yAxis.toPixels(110);

    chartQuadrants.push(chart.renderer.rect(x0, y2, x2 - x1, y0 - y1).attr({
      fill: chartBackgroundColors.topLeft,
      opacity: 0.25
    }).add());
    chartQuadrants.push(chart.renderer.rect(x1, y2, x2 - x1, y1 - y2).attr({
      fill: chartBackgroundColors.topRight,
      opacity: 0.25
    }).add());
    chartQuadrants.push(chart.renderer.rect(x1, y1, x1 - x0, y1 - y2).attr({
      fill: chartBackgroundColors.bottomRight,
      opacity: 0.25
    }).add());
    chartQuadrants.push(chart.renderer.rect(x0, y1, x1 - x0, y0 - y1).attr({
      fill: chartBackgroundColors.bottomLeft,
      opacity: 0.5
    }).add());
  };
};

export const structureData = (data) => {
  const setOne = [];
  const setTwo = [];

  for (let i = 0; i < data.length; i++) {
    const dataPoint = data[i];

    setOne.push({
      x: dataPoint.future_importance_score,
      y: dataPoint.skill_demand_change,
      actual_y_val: dataPoint.skill_demand_change,
      z: dataPoint.employees_inferred,
      num_employees_inferred: dataPoint.employees_inferred,
      num_employees_verified: dataPoint.employees_credentialed,
      name: `Skill ${i}`,
      skill_ID: 'LoremSkillsumDolor',
      future_proof: dataPoint.future_proof,
      verified: false,
      from_occupation: true,
      identifier: 'dataPoint-' + i,
      quadrant_name: 'Quadrant Name'
    });
    setTwo.push({
      x: dataPoint.future_importance_score,
      y: dataPoint.skill_demand_change,
      actual_y_val: dataPoint.skill_demand_change,
      z: dataPoint.employees_credentialed,
      num_employees_inferred: dataPoint.employees_inferred,
      num_employees_verified: dataPoint.employees_credentialed,
      name: `Skill ${i}`,
      skill_ID: 'LoremSkillsumDolor',
      future_proof: dataPoint.future_proof,
      verified: true,
      from_occupation: false,
      identifier: 'dataPoint-' + i,
      quadrant_name: 'Quadrant Name'
    });
  }

  return { setOne: setOne, setTwo: setTwo };
};

export const tooltip = (dataPoint) => {
  return `
        <div class="tooltip-container">
        <h3 style="margin: 0;">${dataPoint.point.name}</h3>
        <span class="quandrant-name" style="color: ${dataPoint.point.marker.fillColor}">${dataPoint.point.quadrant_name}</span>
          <table>
            <tr><th style="min-width:180px;">Skill Demand</th><td>${dataPoint.point.actual_y_val.toFixed(0)}%</td></tr>
            <tr><th style="min-width:180px;">Importance</th><td>${dataPoint.point.x.toFixed(0)}</td></tr>
            <tr><th style="min-width:180px;"><span class="tooltip-dot" style="background: ${dataPoint.point.marker.fillColor};border: 1px solid ${dataPoint.point.marker.fillColor}"></span>Employees</th><td>${dataPoint.point.num_employees_inferred.toFixed(0)}</td></tr>
            <tr><th style="min-width:180px;"><span class="tooltip-dot" style="background: ${dataPoint.point.marker.fillColor}; opacity: 15%;"></span>Verified Skills</th><td>${dataPoint.point.num_employees_verified.toFixed(0)}</td></tr>
          </table>
        </div>`;
};

export const buildSeries = (data) => {
  const normalizedData = normalizeData(data);
  return [
    {
      data: normalizedData.setOne.map((dataPoint) => ({
        ...dataPoint,
        marker: {
          fillColor: mapCoordinateToColors(dataPoint),
          lineColor: mapCoordinateToColors(dataPoint)
        }
      })).sort((a, b) => b.z - a.z),
      marker: {
        fillOpacity: 0.15,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    {
      data: normalizedData.setTwo.map((dataPoint) => ({
        ...dataPoint,
        marker: {
          fillColor: mapCoordinateToColors(dataPoint),
          lineColor: mapCoordinateToColors(dataPoint)
        }
      })).sort((a, b) => b.z - a.z),
      marker: {
        fillOpacity: 0.8,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    }
  ];
};

/*
  - Normalizes data points with y values outside the range [-100,100] within either [-110,-100] or [100,110]
  - Normalizes all z values to fall within the range [5,25]
 */
const normalizeData = (data) => {
  const yValuesPositive = [];
  const yValuesNegative = [];

  for (let i = 0; i < data.setOne.length; i++) {
    if (data.setOne[i].y > 100) {
      yValuesPositive.push(data.setOne[i].y);
    }
  }
  for (let i = 0; i < data.setOne.length; i++) {
    if (data.setOne[i].y < -100) {
      yValuesNegative.push(data.setOne[i].y);
    }
  }

  const maxOutlierYPositiveVal = Math.max(...yValuesPositive);
  const minOutlierYPositiveVal = Math.min(...yValuesPositive);
  const maxOutlierYNegativeVal = Math.max(...yValuesNegative);
  const minOutlierYNegativeVal = Math.min(...yValuesNegative);

  const normalizeZValue = (value) => {
    return (value - 5) / 20;
  };
  const maxOutlierPositiveFunc = (value) => {
    return (value - minOutlierYPositiveVal) / (maxOutlierYPositiveVal - minOutlierYPositiveVal);
  };
  const maxOutlierNegativeFunc = (value) => {
    return -(value - minOutlierYNegativeVal) / (maxOutlierYNegativeVal - minOutlierYNegativeVal);
  };

  Object.keys(data).forEach(function(key) {
    data[key].map((dataPoint) => {
      if (dataPoint.y > 100) {
        const normalizedPositiveYValue = maxOutlierPositiveFunc(dataPoint.y);
        return {
          ...dataPoint,
          y: (10 * normalizedPositiveYValue) + 100,
          z: normalizeZValue(dataPoint.z)
        };
      } else if (dataPoint.y < -100) {
        const normalizedNegativeYValue = maxOutlierNegativeFunc(dataPoint.y);
        return {
          ...dataPoint,
          y: (10 * normalizedNegativeYValue) - 100,
          z: normalizeZValue(dataPoint.z)
        };
      } else {
        return {
          ...dataPoint,
          z: normalizeZValue(dataPoint.z)
        };
      }
    });
  });

  return data;
};
