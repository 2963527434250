import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { getRailsEnv } from 'app_utils';
import * as objUtils from 'utils/object';
import { QueryString } from 'utils/query_string';
import { ImmediateProperty } from 'utils/react_utils';
import { ResourceStatusPropType } from 'utils/prop_types';
import { RoleButton } from 'aria/role_button';
import { Search, SearchBar, SearchResults } from 'controls/search';
import { SelectList } from 'controls/select_list';
import { Form } from 'form';
import { search } from './earner_directory_actions';

import './recruit_directory_search.sass';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FACETS = [
  { name: 'issuer', label: 'Issuers' },
  { name: 'badge', label: 'Badges' },
  { name: 'skill', label: 'Skills' },
  { name: 'location', label: 'Location' }
];

/**
 * Search bar for the recruit directory.
 *
 * @param {function(array{key: string, value: string})} onSelect - Called when an item is selected
 *   from the list. The parameter is a set of new filters.
 * @param {function(term: String, type: String)} onTermChanged - Called when the search term changes
 * @param {Object} baseParams - Params that are passed through with every search request.
 * @param {Array} excludeSearchTypes - Set of search types to hide. If unspecified, allow all.
 */
class RecruitDirectorySearch extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.m_currentSearchRequest = null;

    const createFilter = (key, value) => {
      if (this.props.operator === 'not') {
        key = `not_${key}`;
      }
      return { key: key, value: value };
    };

    this.dataToFilter = {
      badge: data => createFilter('badge_name', data.value),
      skill: data => createFilter('skill_name', data.value),
      location: data => createFilter('location_name', data.value),
      issuer: data => createFilter('issuer_name', data.value)
    };

    const facets = this.getFacets();

    this.state = {
      clearSearch: new ImmediateProperty(),
      facets: facets,
      focusNow: new ImmediateProperty(),
      hasTerm: false,
      term: '',
      searchResultsHasFocus: false
    };
  }

  /**
   * The type of join for this row. Used by the left column label.
   *
   * @returns {String}
   */
  joinLabel() {
    return this.props.index === '0' ? 'Where' : 'And';
  }

  /**
   * The component was just updated.
   *
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!objUtils.shallowEquals(prevProps.excludeSearchTypes, this.props.excludeSearchTypes)) {
      const facets = this.getFacets();
      // facets is calculated from excludeSearchTypes, so the above guard prevents loops
      this.setState({ facets: facets });
    } else if (this.props.facet !== prevProps.facet) {
      this.search(this.state.term);
    }
  };

  /**
   * Get a list of available facets.
   *
   * @returns {{name: string, label: string}[]}
   */
  getFacets() {
    return FACETS.filter(facet => !this.props.excludeSearchTypes.includes(facet.name));
  }

  /**
   * Callback to handle clicks on the search icon in search bar. In this case, simply focus the
   * input element.
   */
  handleOnSearchIconClick = () => {
    this.setState({ focusNow: this.state.focusNow.next() });
  };

  /**
   * Perform a search.
   *
   * @param {String} term - The search term.
   */
  search = term => {
    if (term) {
      // Strip "NOT" and "substring" indicators
      const adjustedTerm = term.replace(/^!(.*)$|^\*(.*)\*$/, '$1$2');
      const q = new QueryString()
        .set('query', adjustedTerm)
        .set('search_type', this.props.facet);

      objUtils.forEach(this.props.baseParams, (key, val) => q.set(key, val));

      this.m_currentSearchRequest && this.m_currentSearchRequest.abort();
      this.m_currentSearchRequest = this.props.search(q.get());

      this.setState({ hasTerm: !!term, term: term });
    } else {
      this.setState({ hasTerm: false, term: '' });
    }
  };

  /**
   * The search term changed.
   *
   * @param {String} term - The search term.
   */
  searchTermChanged = term => {
    // Don't search in the background.
    if (this.state.searchResultsHasFocus) {
      this.search(term);
      if (term) {
        this.trackSearching(term, this.props.facet);
      }
    } else {
      this.setState({ hasTerm: !!term, term: term });
    }
  };

  showSearchResults() {
    return this.state.searchResultsHasFocus && !!this.state.term;
  }

  /**
   * Track strings typed during an active search.
   * At most once a second, track the most recent, non-empty search string.
   *
   * @param {String} term - The current (or most recent after debounce) search term.
   * @param {String} tab - The tab the user was on when this search took place.
   */
  trackSearching =
    debounce(this.props.onTermChanged, RecruitDirectorySearch.SEARCH_TRACKING_DELAY);

  /**
   * Open the search results.
   */
  handleOpenSearch = () => {
    this.setState({ searchResultsHasFocus: true });
  };

  /**
   * Close the search results.
   */
  handleCloseSearch = () => {
    this.setState({
      clearSearch: this.state.clearSearch.next(),
      searchResultsHasFocus: false,
      hasTerm: false,
      term: ''
    });
  };

  /**
   * Render the "no results" view.
   *
   * @returns {String}
   */
  renderNoResults = () => {
    if (this.props.searchStatus.failed) {
      if (getRailsEnv() === 'development') {
        return 'An error occurred while retrieving search results. ' +
          'See elasticsearch.md for instructions on how to set up ElasticSearch correctly.';
      }
      return 'An error occurred while retrieving search results. Please try again later.';
    }

    switch (this.props.facet) {
      case 'badge':
        return 'No badges match your search.';
      case 'skill':
        return 'No skills match your search.';
      case 'issuer':
        return 'No issuers match your search.';
      default:
        return 'No results found.';
    }
  };

  /**
   * A search result has been selected.
   *
   * @param {{key: string, value: string}} data - Filter for the search result.
   */
  handleSelect = data => {
    this.props.onSelect(this.props.index, this.dataToFilter[this.props.facet](data));

    this.handleCloseSearch();
  };

  onSelectOperator = (_name, data) => {
    this.props.onSelectOperator(data, this.props.facet);
  };

  onSelectFacet = (_name, data) => {
    this.props.onSelectFacet(this.props.index, data);
  };

  handleDeleteFacet = () => {
    this.onSelectFacet(undefined);
  };

  /**
   * Render search results.
   *
   * @param {String} tab - The tab to render.
   * @returns {React.element}
   */
  renderResults = facet => {
    if (facet !== this.props.facet) {
      return null;
    }

    let resultsUI;
    let results = this.props.results;

    if (this.props.searchStatus.succeeded) {
      // Hidden feature: Substring search.
      if (this.state.term.match(/^\*.+\*$/) && results.length > 0) {
        results = [{ id: this.state.term, value: this.state.term }, ...results];
      }

      resultsUI = (
        <SelectList>{results.map(data =>
          <SelectList.Item
            key={data.id}
            onSelect={this.handleSelect}
            onEscape={this.handleCloseSearch}
            data={data}
          >
            {data.id}
          </SelectList.Item>
        )}
        </SelectList>);
    }

    return (
      <SearchResults
        renderNoResults={this.renderNoResults}
        className="recruit-directory-search__results"
      >
        {resultsUI}
      </SearchResults>
    );
  };

  /**
   * Populates the search operator select
   *
   * @returns {{selectionValue: string, displayValue: string}}
   */
  operatorChoices = () => {
    if (this.props.facet === 'location') {
      return [
        { selectionValue: 'or', displayValue: 'Is any of' },
        { selectionValue: 'not', displayValue: 'Is not' }
      ];
    } else {
      return [
        { selectionValue: 'or', displayValue: 'Is any of' },
        { selectionValue: 'and', displayValue: 'Is all of' },
        { selectionValue: 'not', displayValue: 'Is not' }
      ];
    }
  }

  /**
   * Render the component.
   *
   * @returns {React.element}
   */
  render() {
    return (
      <div className="recruit-directory-search" ref={this.container}>
        <div className="recruit-directory-search__container">
          <div className="recruit-directory-search__label-facet">
            <div className="recruit-directory-search__label">
              <span>{this.joinLabel()}</span>
            </div>
            <Form.Select
              aria-label="Filter by characteristic"
              name="facet"
              enclosed
              fullHeight
              className="recruit-directory-search__dropdown recruit-directory-search__facet"
              value={this.props.facet}
              handleChange={this.onSelectFacet}
              options={this.state.facets.map((facet) => (
                { selectionValue: facet.name, displayValue: facet.label })
              )}
            />
          </div>
          <Form.Select
            aria-label="Filter operator"
            value={this.props.operator}
            handleChange={this.onSelectOperator}
            enclosed
            fullHeight
            className="recruit-directory-search__dropdown recruit-directory-search__operator"
            name="operator-select"
            options={this.operatorChoices()}
          />
          <div className="recruit-directory-search__input">
            <Search
              search={this.searchTermChanged}
              searching={this.props.searchStatus.pending}
              showResults={this.showSearchResults()}
              results={this.props.searchStatus.succeeded ? this.props.results : []}
              clearSearch={this.state.clearSearch}
              onFocus={this.handleOpenSearch}
              onBlur={this.handleCloseSearch}
              focusContainer={this.container}
              focusNow={this.state.focusNow}
              className="recruit-directory-search__search"
            >
              <SearchBar
                className="recruit-directory-search__search-bar"
                placeholder="Search directory"
                theme="compact-borderless"
                showIcon
                searchIconPosition="end"
                handleSearchClick={this.handleOnSearchIconClick}
              />
              {
                this.showSearchResults() &&
                  <div className="recruit-directory-search__wrap">
                    <div>
                      {this.renderResults(this.props.facet)}
                    </div>
                  </div>
              }
            </Search>
            {
              this.props.allowDelete &&
                <RoleButton
                  onClick={this.handleDeleteFacet}
                  className="recruit-directory-search__delete-button"
                  ariaLabel="Remove filter"
                  tagName="div"
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="recruit-directory-search__delete-icon"
                  />
                </RoleButton>
            }
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

RecruitDirectorySearch.SEARCH_TRACKING_DELAY = 700; // 700 + Search's max wait of 300 = 1s

RecruitDirectorySearch.propTypes = {
  children: PropTypes.node,
  facet: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectFacet: PropTypes.func,
  onSelectOperator: PropTypes.func,
  onTermChanged: PropTypes.func.isRequired,
  excludeSearchTypes: PropTypes.array.isRequired,
  baseParams: PropTypes.object.isRequired,
  allowDelete: PropTypes.bool.isRequired,

  // From connect()
  results: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  search: PropTypes.func.isRequired,
  searchStatus: ResourceStatusPropType.isRequired
};


const Connected = connect(
  state => ({
    results: search.getResources(state),
    searchStatus: search.getStatus(state)
  }),
  dispatch => ({
    search: query => dispatch(search.action(query))
  })
)(RecruitDirectorySearch);

export { Connected as RecruitDirectorySearch };
export const testing = { RecruitDirectorySearch };
export { FACETS };
