import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToggle } from 'utils/react_utils';
import { makeClassName } from 'utils';
import Dialog from 'controls/dialog';

import './info_modal.sass';

/**
 * Renders an information icon ("i" enclosed in a circle) that when clicked will open a modal
 * containing explanatory text.
 *
 * @param {object} props
 *   @param {string} props.title - title to show on the modal that opens; also used in the
 *     accessible label for the icon
 *   @param {string|React.ReactElement} props.body - explanatory content for the body of the modal
 * @returns {JSX.Element}
 * @constructor
 */
export const InfoModal = (props) => {
  const [modalOpen, toggleModalOpen] = useToggle();
  const intl = useIntl();
  const dialogSize = props.dialogSize || 'half';

  return (
    <>
      <button
        type="button"
        className={makeClassName('workforce-info-modal__button', props.className)}
        onClick={toggleModalOpen}
        aria-label={intl.formatMessage(
          {
            defaultMessage: 'What is "{title}"?',
            id: 'workforce.info_modal.button_accessible_label'
          },
          { title: props.title }
        )}
      >
        <FontAwesomeIcon icon={faCircleInfo}/>
      </button>
      <Dialog
        show={modalOpen}
        shouldClose={toggleModalOpen}
        size={dialogSize}
        title={props.title}
      >
        <Dialog.Content>
          {props.body}
        </Dialog.Content>
      </Dialog>
    </>
  );
};

InfoModal.propTypes = {
  body: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  dialogSize: PropTypes.string
};
