import { Action } from 'utils/action';
import * as stringUtils from 'utils/string';

export const employeeSearch = new Action({
  resourceType: 'directorySearch',
  requestKey: 'search',
  method: 'GET',
  effect: 'read',
  url: '/api/v1/organizations/[organization_id]/employee_directory/search/[type]',
  urlSubs: new stringUtils.BracketSubs({
    type: { param: 'search_type', remove: true },
    organization_id: { param: 'organization_id', remove: true }
  }),
  alwaysRefresh: true,
  list: 'search',
  preTransform: data => (data.search_results || []).map(r => ({ id: r, value: r }))
});

export const employeeFilter = new Action({
  resourceType: 'directoryCards',
  requestKey: 'filter',
  method: 'GET',
  effect: 'read',
  url: '/api/v1/organizations/[organization_id]/employee_directory',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true }
  }),
  alwaysRefresh: true,
  list: 'search'
});
