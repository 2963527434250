import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons/faChartLine';
import { faCode } from '@fortawesome/pro-regular-svg-icons/faCode';
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { faHouse } from '@fortawesome/pro-regular-svg-icons/faHouse';
import { faBookOpenCover } from '@fortawesome/pro-regular-svg-icons/faBookOpenCover';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { makeClassName } from 'utils';
import { supportSsoUrl, docsUrl } from 'utils/routes';
import IssuerSupport from 'svg/life-ring-solid.svg';
import { usePermissions, Permissions } from 'management/organizations/permissions';
import { WorkforceRouteHelpers } from 'pages/workforce/route_helpers';

import './workforce_nav.sass';

/**
 * Primary navigation links within the Workforce product area.
 *
 * @param {object} props
 *   @param {string} props.baseUrl - base URL for the Workforce organization context where this is
 *     rendered (e.g. /workforce/organizations/:organization_id)
 *   @param {string} [props.className] - optional extra class to assign to the root element
 *   @param {function} [props.onClick] - optional callback to invoke when an item in the nav is
 *     clicked (expected use case is to provide a callback that will collapse the nav when it's
 *     used within a CollapsibleSection on small screens)
 *   @param {boolean} props.hideSubscription - attribute thathides the account menu item if true
 * @returns {JSX.Element}
 * @constructor
 */
export const WorkforceNav = (props) => {
  const commonLinkProps = {
    className: 'cr-workforce-nav__link',
    onClick: props.onClick
  };
  const commonNavLinkProps = { ...commonLinkProps, activeClassName: 'cr-workforce-nav__link--active' };

  const routeHelpers = WorkforceRouteHelpers.forUrl(props.baseUrl);
  const [hasPermissions] = usePermissions();
  const hasLearningPlanAccess = hasPermissions(Permissions.LEARNING_PLAN_READ);

  const accountNavVisible = (
    hasPermissions(Permissions.WORKFORCE_SUBSCRIPTION_READ) ||
      hasPermissions(Permissions.WORKFORCE_SUBSCRIPTION_MANAGE)
  );
  const analyticsVisible = hasPermissions(Permissions.EMPLOYMENT_REPORT_AGGREGATE);

  return (
    <div className={makeClassName('cr-workforce-nav', props.className)}>
      <NavLink {...commonNavLinkProps} to={routeHelpers.dashboard.route()}>
        <div className="cr-workforce-nav__icon-container"><FontAwesomeIcon icon={faHouse}/></div>
        <span><FormattedMessage id="workforce.nav.dashboard" defaultMessage="Dashboard"/></span>
      </NavLink>
      <NavLink {...commonNavLinkProps} to={routeHelpers.employees.route()}>
        <div className="cr-workforce-nav__icon-container"><FontAwesomeIcon icon={faUsers}/></div>
        <span><FormattedMessage id="workforce.nav.employees" defaultMessage="Employees"/></span>
      </NavLink>
      {
        hasLearningPlanAccess && (
          <NavLink {...commonNavLinkProps} to={routeHelpers.learning.route()}>
            <div className="cr-workforce-nav__icon-container"><FontAwesomeIcon icon={faBookOpenCover}/></div>
            <span><FormattedMessage id="workforce.nav.learning" defaultMessage="Learning"/></span>
          </NavLink>
        )
      }
      {
        analyticsVisible && (
          <NavLink {...commonNavLinkProps} to={routeHelpers.analytics.route()}>
            <div className="cr-workforce-nav__icon-container"><FontAwesomeIcon icon={faChartLine}/></div>
            <span><FormattedMessage id="workforce.nav.analytics" defaultMessage="Analytics"/></span>
          </NavLink>
        )
      }
      {accountNavVisible && !props.hideSubscription &&
        <NavLink {...commonNavLinkProps} to={routeHelpers.accounts.route()}>
          <div className="cr-workforce-nav__icon-container"><FontAwesomeIcon icon={faGear}/></div>
          <span><FormattedMessage id="workforce.nav.account" defaultMessage="Account"/></span>
        </NavLink>
      }
      <a {...commonLinkProps} href={`${docsUrl()}/workforce`} target="_blank" rel="noreferrer noopener">
        <div className="cr-workforce-nav__icon-container"><FontAwesomeIcon icon={faCode}/></div>
        <span><FormattedMessage id="workforce.nav.developers" defaultMessage="Developers"/></span>
      </a>
      <a {...commonLinkProps} href={supportSsoUrl()} target="_blank" rel="noreferrer noopener">
        <div className="cr-workforce-nav__icon-container"><IssuerSupport/></div>
        <span><FormattedMessage id="workforce.nav.support" defaultMessage="Support"/></span>
      </a>
    </div>
  );
};

WorkforceNav.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  hideSubscription: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
};
