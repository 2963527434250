import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import { useToggle } from 'utils/react_utils';
import { LearningPlanEmploymentActions, CongratulationActions } from 'pages/workforce/learning/actions';
import { BlockClickPropagate } from 'controls/block_click_propagate';
import { OrgMgmtDataTable } from 'management/controls/org_mgmt_data_table';
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { useAlerts } from 'alert';
import dateUtils from 'utils/date.js';
import { useQueryString } from "controls/with_query_string";
import {
  standardInitialSort,
  useStandardSortHandler
} from "controls/data_table/use_standard_sort_handler";
import { InfoModal } from 'pages/workforce/components/info_modal';
import { InvitationStatusIndicator } from 'pages/workforce/components/invitation_status_indicator';
import { RemoveRecommendationModal } from 'pages/workforce/learning/shared/remove_recommendation_modal';
import { makeFilteredUrl } from 'pages/public/organization/earner_directory';

import { LearningPlanRecommendationDropdown } from 'pages/workforce/learning/shared/learning_plan_recommendation_dropdown';

import { BadgeRecommendedBy } from 'pages/workforce/learning/shared/badge_recommended_by';
import "./badge_detail_employee_table.sass";

const StatusCheckOrDash = ({ field, formatShortDate }) => {
  if (field) {
    return (
      <>
        <FontAwesomeIcon
          className="c-workforce-learning-check__icon"
          icon={faCheckCircle}
        />
        {formatShortDate && <div>{dateUtils.formatShort(field)}</div>}
      </>
    );
  }

  return <div>--</div>;
};

StatusCheckOrDash.propTypes = {
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  formatShortDate: PropTypes.bool
};

export const BadgeDetailEmployeeTable = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const alerts = useAlerts();

  const [displayRemove, toggleDisplayRemove] = useToggle(false);
  const [recomendationToRemove, setRecommendationToRemove] = useState(null);

  const [learningPlanEmploymentData, setLearningPlanEmploymentData] = LearningPlanEmploymentActions.useAction('index', null);
  const [congratulationData, setCongratulation] = CongratulationActions.useAction('create', null);

  const onSort = useStandardSortHandler();
  const queryString = useQueryString(props.location);
  const sortQueryparam = queryString.get('sort');
  const dataTableSortProps =
    standardInitialSort(sortQueryparam) || { key: 'name', ascending: true };

  React.useEffect(() => {
    setLearningPlanEmploymentData({
      organization_id: props.organizationId,
      learning_plan_id: props.learningPlanId,
      sort: sortQueryparam
    });
  }, [
    props.organizationId,
    props.learningPlanId,
    sortQueryparam,
    toggleDisplayRemove
  ]);

  useEffect(() => {
    if (congratulationData.status.failed) {
      alerts.add({
        type: 'error',
        text: intl.formatMessage({
          id: 'workforce.learning.congratulate.recommendation.removed.failed.text',
          defaultMessage: 'Failed to congratulate.'
        })
      });
    }
    if (congratulationData.status.succeeded) {
      alerts.add({
        type: 'notice',
        text: intl.formatMessage({
          id: 'workforce.learning.congratulate.recommendation.removed.succeeded.text',
          defaultMessage: 'Employee successfully congratulated.'
        })
      });
    }
  }, [congratulationData.status]);

  const title = intl.formatMessage({
    id: 'workforce.learning.learning_plan_details.title',
    defaultMessage: 'Invitation status'
  });

  const body = intl.formatMessage({
    id: 'workforce.learning.learning_plan_details.body',
    defaultMessage: 'Employees with the “Rejected” invitation state have elected' +
      ' not to share their badge information with your organization, so we cannot' +
      ' support learning recommendations for them at this time.'
  });

  const renderRemoveRecommendationModal = (recommendationId) => {
    setRecommendationToRemove(recommendationId);
    toggleDisplayRemove();
  };

  const celebrateEnabled = (data) => {
    return !data.celebrated && data.badge_celebrable;
  };

  const celebrate = (badgeId) => {
    setCongratulation({ badge_id: badgeId });
  };

  const redirectToProfile = (url) => {
    const filteredUrl = makeFilteredUrl('', url, 'learning_plans');
    history.push(filteredUrl);
  };

  return (
    <>
      <OrgMgmtDataTable
        data={learningPlanEmploymentData.resources}
        ariaLabel="Learning Plan Detail Employee Table"
        header={{}}
        loader={{
          loading: learningPlanEmploymentData.status.pending
        }}
        sort={{
          onSort: onSort,
          ...dataTableSortProps
        }}
      >
        <OrgMgmtDataTable.Col
          width="col-xl-3 col-lg-3 col-md-4 col-sm-5"
          header="Employee"
          sortKey="name"
          renderer={(data) => (
            <BadgeRecommendedBy name={data.name} recommendedBy={data.recommended_by} />
          )}
        />
        <OrgMgmtDataTable.Col
          width="col-xl-2 col-lg-3 col-md-4 col-sm-5"
          header="Recommended"
          sortKey="created_at"
          renderer={data => (
            dateUtils.formatShort(data.recommended_at)
          )}
        />
        <OrgMgmtDataTable.Col
          hideAt={["mobile", "tablet"]}
          width="col-xl-1 col-lg-2"
          header="Viewed"
          renderer={(data) => (
            <StatusCheckOrDash
              field={data.viewed}
            />
          )}
        />
        <OrgMgmtDataTable.Col
          width="col-md-1"
          hideAt={['mobile', 'tablet', 'large']}
          header="Clicked"
          renderer={data => (
            <StatusCheckOrDash
              field={data.clicked}
            />
          )}
        />
        <OrgMgmtDataTable.Col
          width="col-xl-2"
          hideAt={['mobile', 'tablet', 'large']}
          header="Earned"
          renderer={data => (
            <StatusCheckOrDash
              field={data.earned_at}
              formatShortDate
            />
          )}
        />
        <OrgMgmtDataTable.Col
          width="col-xl-2 col-lg-3 col-md-3"
          hideAt={['mobile']}
          header={
            <>
              {title}
              <BlockClickPropagate>
                <InfoModal
                  className="c-workforce-learning-plan-badge-employee-table__tooltip-wrapper"
                  title={title}
                  body={body}
                />
              </BlockClickPropagate>
            </>
          }
          renderer={data => {
            return (
              <InvitationStatusIndicator status={data.invitation_status} />
            );
          }}
        />
        <OrgMgmtDataTable.Col
          width="col-xl-1 col-lg-1 col-md-3 col-sm-3"
          renderer={(data) => (
            <>
              <LearningPlanRecommendationDropdown
                toggleDisplayRemove={toggleDisplayRemove}
                celebrateEnabled={celebrateEnabled(data)}
                celebrate={() => celebrate(data.badge_id)}
                redirectToProfile={() => redirectToProfile(data.url)}
                renderRemoveRecommendationModal={() => renderRemoveRecommendationModal(data.id)}
              />
            </>
          )}
        />
      </OrgMgmtDataTable>
      {
        recomendationToRemove &&
          <RemoveRecommendationModal
            recommendationId={recomendationToRemove}
            showModal={displayRemove}
            setShowModal={toggleDisplayRemove}
            organizationId={props.organizationId}
            learningPlanId={props.learningPlanId}
          />
      }
    </>
  );
};

BadgeDetailEmployeeTable.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  learningPlanId: PropTypes.string.isRequired
};
