/*
 ************************************
  These functions are only being used for generating random sample data
 ************************************
 */
const randomNumberToX = (x) => {
  return 1 + (Math.random() * (x - 1));
};

const randomNumberToXNegative = (x) => {
  let limit = x;
  // roll to see if we should make an outlier (> or < x)
  const roll = randomNumberToX(3);
  if (roll > 2) {
    limit = x * 3;
  }
  const num = 1 + (Math.random() * (limit - 1));
  return randomBoolean() ? (num * -1) : num;
};

const randomPercentage = (maxVal) => {
  return Math.random() * maxVal;
};

const randomBoolean = () => {
  return randomNumberToX(100) > 50;
};

export const randomData = () => {
  const numberOfDataPoints = 1 + (Math.random() * (200 - 1));
  const data = [];

  for (let i = 0; i < numberOfDataPoints; i++) {
    let numberOfEmployeesWithSkill = randomNumberToX(100);
    let numberOfEmployeesWithSkillVerified = randomPercentage(numberOfEmployeesWithSkill);
    const xVal = randomNumberToX(100);
    const yVal = randomNumberToXNegative(100);
    const futureProof = randomBoolean();

    if (numberOfEmployeesWithSkill < 5) {
      numberOfEmployeesWithSkill = 5;
    }

    if (numberOfEmployeesWithSkillVerified < 3) {
      numberOfEmployeesWithSkillVerified = 3;
    }

    const dataPoint = {
      faethm_id: "FS00" + i,
      name: "Skill " + i,
      // total_employees: null,
      employees_credentialed: numberOfEmployeesWithSkillVerified,
      employees_inferred: numberOfEmployeesWithSkill,
      skill_demand_change: yVal,
      future_importance_score: xVal,
      future_proof: futureProof
    };
    data.push(dataPoint);
  }

  return data;
};
