import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { messageDefinitionPropType } from 'prop_types';
import { compact as arrayCompact } from 'utils/array';
import { Placeholder } from 'controls/placeholder';
import {
  DonutCore,
  DONUT_CENTER_LABEL_DEFAULT_STYLES,
  normalizeCategoryColors
} from 'charts/donut';
import DonutLoadingSVG from 'svg/charts-donut_loading.svg';

import './donut.sass';

/**
 * Renders a Donut chart using the specified data, and highlighting the indicated category in the
 * label at the center of the chart.
 *
 * @param {object} props
 *  @param {string[]|Object<string, string>} props.categoryColors - an array of colors to use for
 *     the slices of the chart; ideally, this should have the same or greater length as props.data,
 *     but if it is shorter, colors will be; alternatively provide an object with keys matching the
 *     x values from the data array and values of the desired color codes, in this case, the size
 *     of the object must exactly match the length of the data array
 *   @param {Array<{ x:string, y:number }>} props.data - the data to render as a donut (pie) chart
 *   @param {string} props.highlightedCategory - the name of a category (i.e. an x value from
 *     props.data) to highlight in the center of the donut chart
 *   @param {MessageDefinition} props.highlightedCategoryLabel - an i18n message definition that
 *     can be used to format the central label of the donut chart; will be passed the percentage of
 *     the total made up by the highlighted category as "percentage", and can make use of the tag
 *     "<line>" to separate lines of the label; the first line will be displayed in a larger font
 *     and differentiated color, as it is expected to contain the percentage
 *   @param {string} props.totalLabel - label for the sum of all categories, displayed as the last
 *     row of the legend
 *   @param {string[]} [props.omitEmptyCategories=[]] - optional list of categories to omit from
 *     the legend if their values are 0
 * @returns {JSX.Element}
 * @constructor
 */
export const Donut = (props) => {
  const categoryColors = useMemo(() => {
    return normalizeCategoryColors(props.categoryColors, props.data);
  }, [props.data, props.categoryColors]);

  const intl = useIntl();

  const centerLabel = useCallback((total, data) => {
    let percentage;
    if (total === 0) {
      percentage = 0;
    } else {
      const numerator = data.find((e) => e.x === props.highlightedCategory)?.y || 0;
      percentage = numerator / total;
    }

    const result = intl.formatMessage(
      props.highlightedCategoryLabel,
      { percentage, line: (wrapped) => `${wrapped}<br/>` }
    );
    return arrayCompact(result.split('<br/>'));
  }, [props.highlightedCategory, props.highlightedCategoryLabel, intl]);

  const centerLabelStyles = useMemo(() => {
    const highlightedIndex =
      props.data.findIndex((d) => d.x === props.highlightedCategory);
    return [
      { ...DONUT_CENTER_LABEL_DEFAULT_STYLES[0], color: categoryColors[highlightedIndex] },
      { ...DONUT_CENTER_LABEL_DEFAULT_STYLES[1], maxSize: 14 }
    ];
  }, [categoryColors, props.data, props.highlightedCategory]);

  return (
    <div className="workforce-charts-donut">
      <div className="workforce-charts-donut__headline">
        <FormattedMessage
          id="workforce.charts.donut_status_label"
          defaultMessage="Status"
        />
      </div>
      <div className="workforce-charts-donut__chart-container">
        <DonutCore
          data={props.data}
          centerLabel={centerLabel}
          centerLabelStyles={centerLabelStyles}
          categoryColors={props.categoryColors}
        />
      </div>
      <DonutLegend
        data={props.data}
        categoryColors={categoryColors}
        totalLabel={props.totalLabel}
        omitEmptyCategories={props.omitEmptyCategories}
      />
    </div>
  );
};

Donut.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired
    })
  ).isRequired,
  categoryColors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object
  ]).isRequired,
  highlightedCategory: PropTypes.string.isRequired,
  highlightedCategoryLabel: messageDefinitionPropType.isRequired,
  omitEmptyCategories: PropTypes.arrayOf(PropTypes.string),
  totalLabel: PropTypes.string
};

Donut.defaultProps = {
  totalLabel: null
};

/**
 * Renders a legend for the Donut chart, optionally omitting empty categories if they are specified
 * in props.omitEmptyCategories
 *
 * @param {object} props
 *   @param {string[]|Object<string, string>} props.categoryColors - an array of colors to use for
 *     the slices of the chart; ideally, this should have the same or greater length as props.data,
 *     but if it is shorter, colors will be; alternatively provide an object with keys matching the
 *     x values from the data array and values of the desired color codes, in this case, the size
 *     of the object must exactly match the length of the data array
 *   @param {Array<{ x:string, y:number }>} props.data - the data to render as a donut (pie) chart
 *   @param {string} props.totalLabel - label for the sum of all categories, displayed as the last
 *     row of the legend
 *   @param {string[]} [props.omitEmptyCategories=[]] - optional list of categories to omit from
 *     the legend if their values are 0
 * @returns {JSX.Element}
 * @constructor
 */
const DonutLegend = (props) => {
  const total = useMemo(() => {
    return props.data.reduce((acc, d) => acc + d.y, 0);
  }, [props.data]);

  const legendData = useMemo(() => {
    const legendsArray = [...props.data];
    if (props.totalLabel) { legendsArray.push({ x: props.totalLabel, y: total }); }
    return legendsArray;
  }, [props.data]);

  return (
    <div className="workforce-charts-donut__legend">
      {
        legendData.reduce((result, item, index) => {
          if (item.y > 0 || !props.omitEmptyCategories?.includes(item.x)) {
            result.push(
              <div className="workforce-charts-donut__legend-category" key={item.x}>
                <div className="workforce-charts-donut__legend-category-label">
                  <div
                    className="workforce-charts-donut__legend-swatch"
                    style={{ color: props.categoryColors[index] || 'transparent' }}
                  />
                  <div className="workforce-charts-donut__legend-category-label-text">
                    {item.x}
                  </div>
                </div>
                <div className="workforce-charts-donut__legend-category-numbers">
                  <div className="workforce-charts-donut__legend-total">
                    <FormattedMessage
                      id="workforce.charts.donut_category_total"
                      defaultMessage="{total, number}"
                      values={{ total: item.y }}
                    />
                  </div>
                </div>
              </div>
            );
          }
          return result;
        }, [])
      }
    </div>
  );
};

DonutLegend.propTypes = {
  data: Donut.propTypes.data,
  categoryColors: Donut.propTypes.categoryColors,
  omitEmptyCategories: Donut.propTypes.omitEmptyCategories,
  totalLabel: Donut.propTypes.totalLabel
};

const EMPTY_COLOR = '#efefef';

/**
 * Renders a loading/placeholder view of the Workforce Donut chart.
 *
 * @param {object} props
 *   @param {number} [props.categoryCount=3] - optional number of categories to show placeholders
 *     for
 * @returns {JSX.Element}
 * @constructor
 */
export const DonutLoading = (props) => {
  const placeholders = [];
  for (let i = 0; i < (props.categoryCount || 3); ++i) {
    placeholders.push(i);
  }
  return (
    <div className="workforce-charts-donut workforce-charts-donut--loading">
      <div className="workforce-charts-donut__chart-container">
        <DonutLoadingSVG/>
      </div>
      <div className="workforce-charts-donut__legend">
        {
          placeholders.map((e) => (
            <div
              className="
                workforce-charts-donut__legend-category
                workforce-charts-donut__legend-category--loading
              "
              key={e}
            >
              <div className="workforce-charts-donut__legend-category-label">
                <div
                  className="workforce-charts-donut__legend-swatch"
                  style={{ color: EMPTY_COLOR }}
                />
                <Placeholder
                  className="
                    workforce-charts-donut__legend-category-label
                    workforce-charts-donut__legend-category-label--placeholder
                  "
                />
              </div>
              <div className="workforce-charts-donut__legend-category-numbers">
                <Placeholder
                  className="
                    workforce-charts-donut__legend-total
                    workforce-charts-donut__legend-total--placeholder
                  "
                />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

DonutLoading.propTypes = {
  categoryCount: PropTypes.number
};
