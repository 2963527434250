import { Action } from 'utils/action';
import * as stringUtils from 'utils/string';

export const similarEmployeesAction = new Action({
  resourceType: 'similarEmployees',
  requestKey: 'index',
  url: '/api/v1/organizations/[organization_id]/employees/[id]/similar_employees',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true },
    id: { param: 'id', remove: true }
  }),
  effect: 'read',
  method: 'GET',
  list: 'list',
  alwaysRefresh: true
});
