import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { makeClassName } from 'utils';
import PropTypes from "prop-types";

export const DiffPill = ({ total, previousTotal, useTotalPercent }) => {
  const intl = useIntl();

  const percentage = total / previousTotal;
  const percentText = useMemo(() => (intl.formatMessage({
    id: 'workforce.chiclet.pill.percentage_text',
    defaultMessage: '{percentage, number, ::percent}'
  }, { percentage: percentage })), [intl, percentage]);

  const diff = total - previousTotal;
  const diffText = useMemo(() => (intl.formatMessage({
    id: 'workforce.chiclet.pill.text',
    defaultMessage: '{diff, number, ::sign-except-zero}'
  }, { diff: diff })), [intl, diff]);

  const pillType = useMemo(() => {
    if (useTotalPercent) {
      return 'workforce-chiclet__total--percent';
    } else if (diff > 0) {
      return 'workforce-chiclet__change--positive';
    } else if (diff < 0) {
      return 'workforce-chiclet__change--negative';
    } else {
      return 'workforce-chiclet__change--zero';
    }
  }, [diff]);

  return (
    <div
      id={useTotalPercent ? "workforce-chiclet-percent" : "workforce-chiclet-change"}
      className={makeClassName(["workforce-chiclet__change", pillType])}
    >
      <span className="workforce-chiclet__change-text">
        {useTotalPercent ? percentText : diffText}
      </span>
    </div>
  );
};

DiffPill.propTypes = {
  total: PropTypes.number,
  previousTotal: PropTypes.number,
  useTotalPercent: PropTypes.bool
};
