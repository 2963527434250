import { actionTypes } from 'redux-resource';
import { useDispatch } from 'react-redux';
import { uniqueId } from 'utils';
import { useEffectExceptOnMount } from 'utils/react_utils';

/**
 * @typedef {Object} AlertParams
 * @property {String} type - One of {error, warning, info, notice}
 * @property {String} text - The message text
 * @property {String} [head] - The message header
 * @property {String} [category] - The message category
 */
let AlertParamsDocOnly;

/**
 * Operations that can be performed on the alert stack.
 */
export default class AlertsActions {
  /**
   * Return dispatch bindings for this class.
   *
   * @param {Function} dispatch
   * @returns {{add: function(AlertParams), remove: function({id: string})}}
   */
  static bind(dispatch) {
    const actions = new AlertsActions();
    return {
      add: data => dispatch(actions.add(data)),
      remove: id => dispatch(actions.remove(id))
    };
  }


  /**
   * Add an alert.
   *
   * @param {AlertParams} data
   * @returns {Function}
   */
  add(data) {
    return dispatch => {
      let id = data.id || uniqueId();
      data = {id: id, ...data};

      dispatch({
        type: actionTypes.UPDATE_RESOURCES,
        resources: {
          alerts: {[id]: data}
        }
      });

      return id;
    };
  };


  /**
   * Remove an alert.
   *
   * @param {String} id - The id of the alert to remove.
   * @returns {Function}
   */
  remove(id) {
    return dispatch => {
      dispatch({
        type: actionTypes.DELETE_RESOURCES,
        resourceType: 'alerts',
        resources: {
          alerts: [id]
        }
      });
    };
  };
}


const hookActions = new AlertsActions();

/**
 * React hook for alerts.
 *
 * @returns {{add: function(AlertParams), remove: function({id: string})}}
 */
export const useAlerts = () => {
  const dispatch = useDispatch();
  return {
    add: data => dispatch(hookActions.add(data)),
    remove: data => dispatch(hookActions.remove(data))
  };
};

/**
 * React hook to pop up an alert whenever a redux-resource request succeeds or fails.
 *
 * @param {ResourceStatus} status - The redux-resource status to watch.
 * @param {AlertParams} successAlert - Show this on success.
 * @param {AlertParams} failAlert - Show this on failure.
 */
export const useAlertsOnReduxChange = (status, successAlert, failAlert) => {
  const alerts = useAlerts();

  useEffectExceptOnMount(() => {
    successAlert && status.succeeded && alerts.add(successAlert);
  }, [status.succeeded]);

  useEffectExceptOnMount(() => {
    failAlert && status.failed && alerts.add(failAlert);
  }, [status.failed]);
};
