import React from "react";

export const useActionPagination = (action, actionParams = {}) => {
  const [actionState, actionCall] = action.useAction();

  const nextPage = actionState.metadata &&
    (actionState.metadata.current_page || 0) < (actionState.metadata.total_pages || 0) &&
    (actionState.metadata.current_page || 0) + 1;

  const loadNextPage = React.useCallback(() => {
    if (!actionState.status.pending && nextPage) {
      actionCall({ ...actionParams, page: nextPage });
    }
  }, [actionState.status.pending, nextPage, actionParams, actionCall]);

  return [
    actionState?.metadata?.current_page || 1,
    loadNextPage,
    !!nextPage
  ];
};
