import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import * as objUtils from 'utils/object';

/**
 * Function to check that the user's effective permissions - passed as the first argument - include
 * all of the required permissions for a given feature/functionality - passes as the second
 * argument.
 *
 * @param {array<string>} effectivePermissions - what permissions the user actually has
 * @param {array<string>} requiredPermissions - what permissions are needed to access the
 *   functionality guarded by this check
 * @param {object} [opts] - additional options to control the behavior of the function
 *   @param {boolean} [opts.any=false] - if true, any of the listed permissions is sufficient,
 *     instead of all of the permissions being required
 * @returns {boolean}
 */
const hasPermissions = (effectivePermissions, requiredPermissions, opts = { any: false }) => {
  effectivePermissions = effectivePermissions || [];
  requiredPermissions = requiredPermissions || [];
  const match = (p) => effectivePermissions.indexOf(p) >= 0;
  return opts.any ? requiredPermissions.some(match) : requiredPermissions.every(match);
};

/**
 * Helper for generating permission strings for object-level permission checking, given the object's
 * identifying information and a list of activities.
 *
 * @param {string} objectType - the type of object for which to produce object-level permission
 *   strings (e.g. BadgeTemplate, Organization, etc.)
 * @param {string} objectId - the ID of the object for which to produce object-level permission
 *   strings
 * @param {array<string>} activities - the list of context-dependent activities for which to
 *   generate permission strings (e.g. update, read, etc.)
 * @returns {string[]}
 */
export const makeObjectPermissions = (objectType, objectId, activities) => {
  return (activities || []).map((a) => `${objectType}:${objectId}.${a}`);
};

/**
 * React.Context for setting the general, organization-level permissions that a user has, in order
 * to decide whether certain UI elements should be available.
 *
 * @type {React.Context<{permissions:array<string>}>}
 */
export const OrganizationPermissionsContext = React.createContext({ permissions: [] });

/**
 * Helper component for providing general, organization-level permissions via
 * OrganizationPermissionsContext to components that need to make use of
 * @param {object} props - component's props
 * @param {{permissions:array<string>}} props.organizationMetadata - the metadata for the
 *   Organization, which should include a key for permissions
 * @param {React.node} props.children - the content to render with the new
 *   OrganizationPermissionsContext value
 * @returns {React.element}
 * @constructor
 */
export const OrganizationPermissionsProvider = (props) => {
  const permissions = [...objUtils.dig(props.organizationMetadata, 'permissions')];
  return (
    <OrganizationPermissionsContext.Provider value={{ permissions }}>
      {props.children}
    </OrganizationPermissionsContext.Provider>
  );
};

OrganizationPermissionsProvider.propTypes = {
  children: PropTypes.node,
  organizationMetadata: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string)
  })
};

/**
 * Custom hook to make use of general permissions provided by OrganizationPermissionsProvider, as
 * well as object-level permissions provided as arguments to the hook call.  If no arguments are
 * provided to the hook, only general permissions will be available.  The first function returned
 * from the usePermissions call is the function used to test for general permissions, and the second
 * is called with a list of object-level activities to test against objectPermissions, by
 * translating them into actual permissions using the values from object.
 *
 * @param {{type:string, id:string}} [object] - the object for which to apply object-level
 *   permissions, if any (can be left blank)
 * @param {array<string>} [objectPermissions] - the effective permissions for object
 * @returns {[function(...string, object?):boolean, function(...string, object?):boolean]}
 */
export const usePermissions = (object, objectPermissions) => {
  const value = useContext(OrganizationPermissionsContext);

  const hasObjectPermissions = useCallback(
    (...activities) => {
      if (object && objectPermissions) {
        let opts = {};
        if (activities.length > 0 && typeof activities[activities.length - 1] === 'object') {
          opts = activities.pop();
        }
        const asObjectPermissions =
          makeObjectPermissions(object.type, object.id, activities);
        return hasPermissions(objectPermissions, asObjectPermissions, opts);
      } else {
        return false;
      }
    },
    [object, objectPermissions]
  );

  const boundHasPermissions = useCallback(
    (...required) => {
      let opts = {};
      if (required.length > 0 && typeof required[required.length - 1] === 'object') {
        opts = required.pop();
      }
      return hasPermissions(value.permissions, required, opts);
    },
    [value.permissions]
  );

  return [boundHasPermissions, hasObjectPermissions];
};

/**
 * Consumes OrganizationPermissionsContext, as well as any object-level permissions provided to it
 * as props, and renders its children prop with an object containing two methods to interrogate
 * the user's permissions: hasPermissions and hasObjectPermissions to test for general and object-
 * level permissions, respectively.
 *
 * @property {function():React.element} children - function to render the content that is controlled
 *   by the user's effective permissions, receives an object containing two functions:
 *   hasPermissions and hasObjectPermissions
 * @property {{id:string, type:string}} [object] - ID and type of the object for which object-level
 *   permissions are defined
 * @property {array<string>} [objectPermissions] - list of object-level permissions to use
 */
export const UsingPermissions = (props) => {
  const [hasPermissions, hasObjectPermissions] = usePermissions(
    props.object, props.objectPermissions
  );

  return props.children({ hasPermissions, hasObjectPermissions });
};
UsingPermissions.propTypes = {
  children: PropTypes.func.isRequired,
  object: PropTypes.shape({ type: PropTypes.string.isRequired, id: PropTypes.string.isRequired }),
  objectPermissions: PropTypes.arrayOf(String)
};

/**
 * Constants for general (organization-level) permissions.  These values must be a subset of the
 * ones emitted by lib/user_permissions.rb
 *
 * @type {{PLATFORM_MANAGER: string}}
 */
export const Permissions = {
  Activities: {
    BADGE_TEMPLATE_LANGUAGE: 'language',
    DELETE: 'delete',
    ENDORSE: 'endorse',
    ISSUE: 'issue',
    PUBLISH: 'publish',
    REPORT: 'report',
    UNPUBLISH: 'unpublish',
    UPDATE: 'update',
    UPDATE_MEMBERSHIPS: 'update_memberships',
    VIEW_ACTIVITY_LOG: 'view_activity_log'
  },
  BADGE_READ: 'Badge.read',
  SUBSCRIPTION_READ: 'Subscription.read',
  BADGE_TEMPLATE_CREATE: 'BadgeTemplate.create',
  BADGE_TEMPLATE_EDIT: 'BadgeTemplate.edit',
  BADGE_TEMPLATE_ISSUE: 'BadgeTemplate.issue',
  BADGE_TEMPLATE_LANGUAGE: 'BadgeTemplate.language',
  BADGE_TEMPLATE_UPDATE: 'BadgeTemplate.update',
  BADGE_TEMPLATE_PUBLISH: 'BadgeTemplate.publish',
  BADGE_TEMPLATE_READ: 'BadgeTemplate.read',
  BADGE_TEMPLATE_REPORT: 'BadgeTemplate.report',
  EMPLOYMENT_CREATE: 'Employment.create',
  EMPLOYMENT_DELETE: 'Employment.delete',
  EMPLOYMENT_READ: 'Employment.read',
  EMPLOYMENT_REPORT_AGGREGATE: 'Employment.report_aggregate',
  EMPLOYMENT_UPDATE: 'Employment.update',
  ISSUER_COLLECTION_CREATE: 'IssuerCollection.create',
  ISSUER_COLLECTION_READ: 'IssuerCollection.read',
  LEARNING_PLAN_READ: 'LearningPlan.read',
  MEMBERSHIP_ASSIGN_ROLE_STANDARD: 'Membership.assign_role_standard',
  MEMBERSHIP_ASSIGN_ROLE_WORKFORCE: 'Membership.assign_role_workforce',
  MEMBERSHIP_CREATE: 'Membership.create',
  MEMBERSHIP_UPDATE: 'Membership.update',
  RECOMMENDATION_CREATE: 'Recommendation.create',
  RECOMMENDATION_READ: 'Recommendation.read',
  ORGANIZATION_MANAGE: 'Organization.manage',
  PLATFORM_MANAGER: 'PlatformManager',
  WORKFORCE_SUBSCRIPTION_MANAGE: 'Workforce::Subscription.manage',
  WORKFORCE_SUBSCRIPTION_READ: 'Workforce::Subscription.read'
};
