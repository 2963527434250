import React from 'react';
import PropTypes from 'prop-types';
import { SavedDirectorySearchPropType } from 'pages/public/organization/earner_directory/saved_directory_searches/utils';
import { CreateSavedDirectorySearchButton } from 'pages/public/organization/earner_directory/saved_directory_searches/create_saved_directory_search_button';
import { UpdateSavedDirectorySearchButton } from 'pages/public/organization/earner_directory/saved_directory_searches/update_saved_directory_search_button';

/**
 * Component that conditionally renders a button to create a saved directory search or
 * update a saved directory search depending on whether or not it is being viewed through
 * the context of an existing saved directory search.
 * @param {Object} props
 *   @param {String} props.filters - Filter information from advanced search
 *   to save to the search
 *   @param {String} props.facets - Facet information from advanced search
 *   to save to the search
 *   @param {SavedDirectorySearchPropType} [props.savedDirectorySearch] - An optionally
 *   provided object representing the saved directory search to use as context for rendering
 */
export const SavedDirectorySearchCreateUpdateButton = props => {
  if (props.savedDirectorySearch) {
    return (
      <UpdateSavedDirectorySearchButton
        id={props.savedDirectorySearch.id}
        filters={props.filters}
        facets={props.facets}
      />
    );
  } else {
    return (
      <CreateSavedDirectorySearchButton
        filters={props.filters}
        facets={props.facets}
      />
    );
  }
};

SavedDirectorySearchCreateUpdateButton.propTypes = {
  savedDirectorySearch: SavedDirectorySearchPropType,
  filters: PropTypes.object.isRequired,
  facets: PropTypes.object.isRequired
};
