import React from 'react';
import { uuidPropType } from 'prop_types';
import PropTypes from 'prop-types';
import Dialog from 'controls/dialog';
import { Heading } from 'controls/heading';
import { ProgressBar } from 'controls/progress_bar';
import { useUploadEmployeesForm } from './use_upload_employees_form';
import { useIntl, FormattedMessage } from 'react-intl';

import '../upload_employees_progress.sass';

const STATUS_TO_LABEL = {
  default: {
    id: 'workforce.employees.add.upload.progress.uploading_file',
    defaultMessage: 'Uploading file'
  },
  starting: {
    id: 'workforce.employees.add.upload.progress.starting',
    defaultMessage: 'Starting'
  },
  detecting_encoding: {
    id: 'workforce.employees.add.upload.progress.detecting_encoding',
    defaultMessage: 'Detecting file format'
  },
  validating_csv: {
    id: 'workforce.employees.add.upload.progress.validating_csv',
    defaultMessage: 'Validating file'
  },
  validating_data: {
    id: 'workforce.employees.add.upload.progress.validating_data',
    defaultMessage: 'Validating data'
  },
  processing: {
    id: 'workforce.employees.add.upload.progress.processing',
    defaultMessage: 'Importing data'
  }
};

/**
 * Component that displays a progress bar for a file in progress of importing employees
 *
 * @param {Object} props
 *   @param {String} props.organization_id - Id of the organization to upload employees for
 */
export const UploadEmployeesProgress = (props) => {
  const intl = useIntl();
  const { processingFile } = useUploadEmployeesForm(props.organization_id);
  const progress = processingFile ? processingFile.progress : 0;
  const nextProgress = processingFile ? processingFile.next_progress : 0;
  const progressLabelParams = STATUS_TO_LABEL[processingFile?.state] || STATUS_TO_LABEL.default;
  const progressLabel = intl.formatMessage(progressLabelParams);

  return (
    <Dialog.Content>
      <div
        className="
          c-upload-employees-progress__upload-box
          c-upload-employees-progress__upload-box-new
        "
      >
        <Heading appearance="org-mgmt-subhead">
          <FormattedMessage
            id="workforce.employees.add.upload.progress.title"
            defaultMessage="Uploading New CSV..."
          />
        </Heading>
        <div className="c-upload-employees-progress__upload-progress">
          <ProgressBar
            progress={progress}
            nextProgress={nextProgress}
            label={progressLabel}
          />
        </div>
      </div>
    </Dialog.Content>
  );
};

UploadEmployeesProgress.propTypes = {
  organization_id: uuidPropType
};
