import React from 'react';
import PropTypes from 'prop-types';
import CredlyRoute from 'controls/credly_route';
import { CredlySwitch } from 'controls/credly_switch';
import { EmployeesRouteHelpers } from 'pages/workforce/route_helpers';
import { EmployeesBadgeTemplates } from "./employees_badge_templates/employees_badge_templates";
import { useIntl } from 'react-intl';

export const Routes = (props) => {
  const intl = useIntl();
  const routeHelpers = EmployeesRouteHelpers.forUrl(props.baseUrl);

  return (
    <CredlySwitch>
      <CredlyRoute
        path={routeHelpers.badges.route()}
        render={(routeProps) => {
          return (
            <EmployeesBadgeTemplates
              organization={props.organization}
              routeProps={routeProps}
            />
          );
        }}
        title={intl.formatMessage({
          id: 'workforce.employees.badges.routes.badges',
          defaultMessage: 'Badges'
        })}
      />
    </CredlySwitch>
  );
};

Routes.propTypes = {
  baseUrl: PropTypes.string,
  organization: PropTypes.object
};
