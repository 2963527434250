import React from "react";
import PropTypes from "prop-types";
import { makeClassName } from 'utils';
import { FormattedNumber } from "react-intl";
import { JobDetailsLocationWorkplacePreferencesAndComp } from './job_details_location_workplace_preferences_and_comp';
import orgPlaceholderImage from 'images/job_board_chiclet_org_logo_default.png';
import "./job_chiclet.sass";

export const JobChiclet = (props) => {
  const job = props.job;
  const newJob = (job.number_of_days_since_posting <= 7);
  const className = makeClassName([
    'job-chiclet',
    props.active && 'active'
  ]);

  return (
    <div onClick={props.onClick} className={className} tabIndex={props.tabIndex}>
      <div className="row no-gutters">
        <div className="chiclet-company-logo-wrapper col-12 col-lg-3">
          <img className="chiclet-company-logo" src={job.company_logo?.image || orgPlaceholderImage} />
        </div>
        <div className="col-12 col-lg-9">
          <div className="chiclet-job-details-wrapper">
            <div className="chiclet-org-and-date">
              <div className="org">{job.company_name}</div>
              {newJob
                ? <div className="new-posting">NEW</div>
                : <div className="date-posted">{job.number_of_days_since_posting}d ago</div>
              }
            </div>
            <div className="title">
              {job.title}
            </div>
            <div className="pay-type-location">
              <JobDetailsLocationWorkplacePreferencesAndComp
                city={job.city}
                state_or_province={job.state_or_province}
                workplace_preferences={job.workplace_preferences}
                compensation_type={job.compensation_type}
              />
            </div>
            <div className="salary">
              <FormattedNumber
                value={job.compensation_minimum}
                style="currency"
                currency="USD"
              />
              &nbsp;&ndash;&nbsp;
              <FormattedNumber
                value={job.compensation_maximum}
                style="currency"
                currency="USD"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

JobChiclet.propTypes = {
  tabIndex: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  job: PropTypes.shape({
    number_of_days_since_posting: PropTypes.number,
    title: PropTypes.string,
    city: PropTypes.string,
    state_or_province: PropTypes.string,
    workplace_preferences: PropTypes.arrayOf(PropTypes.string),
    compensation_type: PropTypes.string,
    compensation_minimum: PropTypes.string,
    compensation_maximum: PropTypes.string,
    company_name: PropTypes.string,
    company_logo: PropTypes.string
  })
};
