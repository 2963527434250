import React from 'react';
import PropTypes from 'prop-types';
import { RouterPropTypes } from 'utils/prop_types';
import {
  useFaethmOccupationMappingEnabled
} from 'pages/workforce/faethm_occupation_mapping_hooks';
import { EmployeeProfileDetails } from './employee_profile_details';
import { SimilarEmployeesCarousel } from './similar_employees_carousel';
import { TrendingAndEmergingSkills } from './trending_and_emerging_skills';

import './employee_profile.sass';

/**
 * Employee Profile component that shows content when viewing an employee under Workforce.
 *
 * @param {Object} props
 *   @param {object} props.organization - The organization
 *   of the employer
 *   @param {object} props.employee - Resource object representing employee profile api response
 *   @param {RouterLocation} props.location - React Router location object representing the
*                                             application's current location/URL
 */
export const EmployeeProfile = props => {
  const occupationMappingEnabled = useFaethmOccupationMappingEnabled();

  return (
    <>
      <div className="employee-profile col-12">
        <EmployeeProfileDetails {...props.employee} baseUrl={props.location.pathname}/>
        {occupationMappingEnabled && (
          <TrendingAndEmergingSkills
            organizationId={props.organization.id}
            employeeId={props.employee.id}
            employee={props.employee}
          />
        )}

        <SimilarEmployeesCarousel
          organizationId={props.organization.id}
          employeeId={props.employee.id}
          faethmOccupationName={props.employee.occupation?.name}
          faethmOccupation={props.employee.occupation?.id}
        />
      </div>
    </>
  );
};

EmployeeProfile.propTypes = {
  employee: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  location: RouterPropTypes.location.isRequired
};
