import React, { useContext } from 'react';
import Carousel from '../carousel';
import { makeClassName } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { CarouselManagerContext } from './carousel_manager_context';
import { getControlArrowClassName } from './utils';

export const Controls = ({ direction, children }) => {
  const { cardType, layout, size } = useContext(CarouselManagerContext);

  return (
    <Carousel.Control
      className={makeClassName([
        `${cardType}-carousel-manager__control`,
        getControlArrowClassName({ size, layout, cardType, direction })
      ])}
      direction={direction}
    >
      <div className={`${cardType}-carousel-manager__control-${direction}`}>
        <span className="carousel-manager__control-bg">
          {children}
        </span>
      </div>
    </Carousel.Control>
  );
};


Controls.propTypes = {
  ...Carousel.Control.propTypes
};


const CarouselManagerControlArrows = () => {
  return (
    <>
      <Controls direction="prev">
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="carousel-manager__control-icon"
        />
      </Controls>
      <Controls direction="next">
        <FontAwesomeIcon
          icon={faChevronRight}
          className="carousel-manager__control-icon"
        />
      </Controls>
    </>
  );
};

export const ControlArrows = (
  <Carousel.Controls>
    {CarouselManagerControlArrows}
  </Carousel.Controls>
);
