import React from 'react';
import PropTypes from 'prop-types';
import { ClampLines } from 'controls/clamp_lines';
import { EarnerPropType, makeFilteredUrl } from 'pages/public/organization/earner_directory/utils';
import { useDirectoryQueryStringFilter } from 'pages/public/organization/earner_directory/shared/use_directory_query_string_filter';
import { Link } from 'react-router-dom';
import { QueryString } from 'utils/query_string';

import './directory_table_earner_info.sass';

export const DirectoryTableEarnerInfo = (props) => {
  const earnerData = props.data;
  const filters = useDirectoryQueryStringFilter();
  let earnerUrl;
  if (props.filterEarnerUrl) {
    earnerUrl = makeFilteredUrl(filters, earnerData.url, props.source);
  } else {
    earnerUrl = new QueryString().set('source', props.source).makeUrl(earnerData.url);
  }
  return (
    <div>
      <ClampLines
        lines={2}
        cssClamp={{ lineHeight: 28 }}
        attributes={{ className: 'directory_table_earner_info__name' }}
      >
        <Link
          to={earnerUrl}
          onClick={props.onEarnerNameClick}
        >
          {[earnerData.first_name, earnerData.middle_name, earnerData.last_name].filter(s => s).join(' ')}
        </Link>
      </ClampLines>
      <div className="directory_table_earner_info__location">{earnerData.location}</div>
    </div>
  );
};

DirectoryTableEarnerInfo.propTypes = {
  data: EarnerPropType.isRequired,
  onEarnerNameClick: PropTypes.func,
  filterEarnerUrl: PropTypes.bool,
  source: PropTypes.oneOf(
    ['earner_directory', 'employee_directory', 'recruit_directory', 'custom_directory']
  )
};

DirectoryTableEarnerInfo.defaultPropTypes = {
  source: 'earner_directory'
};
