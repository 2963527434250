import React from 'react';
import { getProfileAction } from 'profile';
import { useDirectoryQueryStringFilter } from 'pages/public/organization/earner_directory/shared/use_directory_query_string_filter';
import { SavedDirectorySearchList } from 'pages/public/organization/earner_directory/saved_directory_searches';
import { DirectoryLayout } from 'pages/public/organization/earner_directory/shared/directory_layout';
import { DirectoryResultsWrapper } from 'pages/public/organization/earner_directory/shared/directory_results_wrapper';
import { DirectorySearchWithFilters } from 'pages/public/organization/earner_directory/shared/directory_search_with_filters';
import { RecruitTable } from 'pages/public/organization/earner_directory/recruit_table';

import './talent_directory.sass';

const resultsRender = (directoryResultsFilterState) => (
  <RecruitTable
    data={directoryResultsFilterState.resources}
    status={directoryResultsFilterState.status}
  />
);

/**
 * The Talent Directory is a global search of all users in the system.
 */
export const TalentDirectory = () => {
  const baseParams = { talent: true };
  const excludeSearchTypes = {};
  const trackingData = {
    object_id: getProfileAction.useAction()[0].resources.id,
    object_type: 'User'
  };
  const filters = useDirectoryQueryStringFilter();
  return (
    <DirectoryLayout baseParams={baseParams} suppressFetch={filters.isEmpty()}>
      <DirectorySearchWithFilters
        baseParams={baseParams}
        excludeSearchTypes={excludeSearchTypes}
        trackingData={trackingData}
        suppressSearchWithoutTerm
      />
      {filters.isEmpty() &&
        <SavedDirectorySearchList className="c-talent-directory__saved-directory-search-list"/>
      }
      {!filters.isEmpty() &&
        <DirectoryResultsWrapper
          baseParams={baseParams}
          resultsRender={resultsRender}
        />
      }
    </DirectoryLayout>
  );
};
