import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import CredlyRoute from 'controls/credly_route';
import { CredlySwitch } from 'controls/credly_switch';
import { LearningRouteHelpers } from 'pages/workforce/route_helpers';
import { LearningPlanDetails } from './pages/show/show';
import { LearningPlans } from './pages/learning_plans';
import { EditLearningPlan } from './pages/edit';
import { NewLearningPlanDirector } from './shared/new_learning_plan_director';

/**
 * Top-level for the "Learning" section of workforce
 *
 * @param props
 *   @param {string} props.baseUrl - the base URL of the learning section of workforce org
 *   @param {OrganizationBackboneModel} props.organization - the Backbone organization model for the
 *     current organization context
 * @returns {React.element}
 */
export const Routes = ({ routeProps, organization }) => {
  const intl = useIntl();
  const routeHelpers = LearningRouteHelpers.forUrl(routeProps.match.url);

  return (
    <CredlySwitch>
      <Redirect from={routeHelpers.root.route()} exact to={routeHelpers.learningPlans.route()}/>
      <CredlyRoute
        path={routeHelpers.show.route()}
        title={intl.formatMessage({
          id: 'workforce.learning.routes.badge_details',
          defaultMessage: 'Learning Plan Details'
        })}
        render={(routeProps) => (
          <LearningPlanDetails
            organizationId={organization.id}
            learningPlanId={routeProps.match.params.id}
            location={routeProps.location}
            baseUrl={routeProps.match.url}
          />
        )}
      />
      <CredlyRoute
        path={routeHelpers.learningPlans.route()}
        title={intl.formatMessage({
          id: 'workforce.learning.routes.my_team_development',
          defaultMessage: 'My Team’s Development'
        })}
        render={(routeProps) => (
          <LearningPlans
            organization={organization}
            baseUrl={routeProps.match.url}
          />
        )}
      />
      <CredlyRoute
        path={routeHelpers.new.route()}
        title={intl.formatMessage({
          id: 'workforce.learning.routes.create_learning_recommendation',
          defaultMessage: 'Make a learning recommendation'
        })}
        render={(routeProps) => (
          <NewLearningPlanDirector
            organization={organization}
            templateId={routeProps.match.params.templateId}
            baseUrl={routeProps.match.url}
          />
        )}
      />
      <CredlyRoute
        path={routeHelpers.edit.route()}
        title={intl.formatMessage({
          id: 'workforce.learning.routes.edit_learning_plan',
          defaultMessage: 'Add employees to recommendation'
        })}
        render={(routeProps) => (
          <EditLearningPlan
            organizationId={organization.id}
            learningPlanId={routeProps.match.params.learningPlanId}
            baseUrl={routeProps.match.url}
          />
        )}
      />
    </CredlySwitch>
  );
};

Routes.propTypes = {
  routeProps: PropTypes.object.isRequired,
  organization: PropTypes.object
};
