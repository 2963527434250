import 'regenerator-runtime'; // Needed for Webpack boilerplate code.
import { config } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import ReactDOM from 'react-dom';
import { LocaleWrapper } from 'translations/locale_wrapper';
import { LoadingSpinner } from 'controls/loading_spinner';
import { Routes } from 'routes';
import { ApplicationStructure, prerender } from 'pages/app_structure';

document.addEventListener('DOMContentLoaded', initApplication);

// squash FontAwesome's desire to inject additional CSS into our page
config.autoAddCss = false;

function initApplication() {
  const rootNode = document.querySelector('#root');
  if (rootNode.getAttribute('data-suppress-application-init')) {
    return;
  }

  prerender();

  const userLocale = defineUserLocale();

  // Note: The upcoming createRoot feature of ReactDOM makes the page load much faster.
  ReactDOM.render(
    <LocaleWrapper
      userLocale={userLocale}
      loadingPlaceholder={<LoadingSpinner position="center" delay={500}/>}
    >
      <ApplicationStructure hasSiteHeader>{() =>
        <Routes />
      }
      </ApplicationStructure>
    </LocaleWrapper>,
    rootNode
  );
}

export function defineUserLocale() {
  const english = 'en';
  const globals = window.initialAppValues ? window.initialAppValues : english;
  const userLocale = globals === english ? english : (
    globals.currentUser.locale ? globals.currentUser.locale : english
  );
  return userLocale;
}
