import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { translations } from 'translations';

export const Context = React.createContext({
  locale: 'en-US',
  selectLanguage: () => null
});

export const LocaleWrapper = (props) => {
  const [locale, setLocale] = useState(
    props.userLocale ? props.userLocale : 'en'
  );
  const [messages, setMessages] = useState();
  const [loading, setLoading] = useState(true);
  // allow mocking of translations
  const [locales] = useState(props.translations || translations);

  useEffect(() => {
    selectLanguage(locale);
  }, []);

  useEffect(() => {
    setLoading(!messages);
  }, [!!messages]);

  function selectLanguage(value) {
    const newLocale = value;
    setLocale(newLocale);

    locales[newLocale]().then((messages) => {
      setMessages(messages);
    });
  }

  if (loading) {
    return props.loadingPlaceholder ? props.loadingPlaceholder : null;
  } else {
    return (
      <Context.Provider value={{ locale, selectLanguage }}>
        <IntlProvider messages={messages} locale={locale} key={locale}>
          {props.children}
        </IntlProvider>
      </Context.Provider>
    );
  }

};

LocaleWrapper.propTypes = {
  loadingPlaceholder: PropTypes.node, 
  children: PropTypes.node,
  userLocale: PropTypes.string,
  translations: PropTypes.object
};
