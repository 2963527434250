import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName, cancelEvent } from 'utils';
import { isIE } from 'utils/browser';
import { useEventCallback } from 'utils/react_utils';
import FieldGroup from 'form/field_group';
import { FakeButton } from 'controls/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from "@fortawesome/pro-regular-svg-icons/faCloudUpload";
import { uniqueId } from 'utils';
import './file_upload.sass';

/**
 * Wrapper around <input type="file"> for the Form component.
 *
 * @param {Object} props - Supports the properties of FieldGroup, as well as:
 *   @param {String} props.accept - Accepted file types.
 *   @param {String} props.ui - If omitted, render the default file input. Otherwise:
 *     - custom - Render the input as an overlay on top of props.children. These children will not
 *       receive pointer events.
 *     - drop - Default drag & drop UI.
 */
export const FileUpload = props => {
  const [isDragging, setIsDragging] = React.useState(false);
  const input = React.useRef();

  // File inputs can't be controlled by React, but form reset still has to be honored. To
  // handle this, catch the case where value becomes falsy, and clear the field.
  React.useEffect(() => {
    if (!props.value) {
      input.current.value = '';
    }
  }, [!!props.value]);

  // Generate unique label id for custom ui
  const customUiLabelId = React.useRef(null);
  if (customUiLabelId.current === null) {
    customUiLabelId.current = uniqueId();
  }

  /**
   * Called when a file is selected. Override the handler in Form, to get the selected file into
   * state.
   *
   * @param {Event} e
   */
  const onChange = React.useCallback(e => {
    props.handleChange(props.name, e.target.files[0]);
  }, [props.handleChange, props.name]);

  /**
   * Called when a file is dragged over the input.
   */
  const onDragEnter = useEventCallback(() => setIsDragging(true), false, []);

  /**
   * Called when a file is dragged over the input.
   */
  const onDragLeave = useEventCallback(() => setIsDragging(false), false, []);

  /**
   * Called when a file is dropped on the control (IE-only).
   *
   * @param {Event} e
   */
  const onDropIE = useEventCallback(e => {
    props.handleChange(props.name, e.dataTransfer.files[0]);
  }, true, []);

  /**
   * Render the input field.
   *
   * @param {Object} fieldProps - Properties passed in from FieldGroup.
   * @return {React.element}
   */
  const renderInput = fieldProps => {
    // Skip `value`, because file inputs can't be managed controls.
    const { required, value, ...otherProps } = fieldProps;

    const attrs = {
      type: 'file',
      ...otherProps,
      'aria-required': required,
      onChange: onChange,
      ref: input
    };

    // IE 11 doesn't support drag&drop on file inputs by default.
    if (isIE()) {
      attrs.onDrop = onDropIE;
      attrs.onDragOver = cancelEvent;
    }

    if (props.ui === 'drop') {
      // Basic drag & drop UI.
      const className = makeClassName(
        props.className,
        'file-upload__custom-ui',
        'file-upload__drop-ui'
      );

      return <>
        <input
          className={makeClassName(
            'file-upload__input-hidden-overlay',
            isDragging && 'file-upload__input-drag'
          )}
          {...attrs}
          title=""
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
        />
        <div className={className}>
          <FontAwesomeIcon icon={faCloudUpload} className="file-upload__drop-ui-icon"/>
          <div className="file-upload__drop-ui-text">
            <div className="file-upload__drop-ui-drag-text">Drag and drop here</div>
            <div>or</div>
          </div>
          <FakeButton type="secondary" className="file-upload__drop-ui-button">Browse Files</FakeButton>
        </div>
      </>;
    } else if (props.ui === 'custom') {
      // Render children alongside an absolutely-positioned input, for a custom UI.
      return (
        <div className={props.className}>
          <input
            {...attrs}
            className="file-upload__input-hidden-overlay"
            aria-labelledby={`upload_${customUiLabelId.current}`}
          />
          <div
            className="file-upload__custom-ui"
            id={`upload_${customUiLabelId.current}`}
          >
            {props.children}
          </div>
        </div>
      );
    } else {
      return <input className={props.className} {...attrs}/>;
    }
  };

  return <FieldGroup {...props} render={renderInput} fullHeight={!!props.ui}/>;
};

FileUpload.propTypes = {
  ...FieldGroup.propTypes,
  value: PropTypes.instanceOf(File),
  accept: PropTypes.string,
  label: PropTypes.string, // Unlike FieldGroup, this is not required.
  // Custom properties
  ui: PropTypes.oneOf(['drop', 'custom'])
};

FileUpload.defaultProps = {
  label: ''
};
