import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { GenericSectionHeader } from 'controls/section_header';
import { Permissions, usePermissions } from 'management/organizations/permissions';
import { EmployeesRouteHelpers } from 'pages/workforce/route_helpers';

import './employees_section_header.sass';

/**
 * Renders the section header for the "Employees" section of organization management.
 *
 * @param {Object} props
 *   @param {string} props.baseUrl - the base URL of organization management under which this
 *     section is nested (e.g. /mgmt/organizations/org-1).
 * @returns {React.element}
 */
export const EmployeesSectionHeader = (props) => {
  const intl = useIntl();
  const routeHelpers = EmployeesRouteHelpers.forUrl(props.baseUrl);
  const [hasPermissions] = usePermissions();
  const hasReportAggregatePermission = hasPermissions(Permissions.EMPLOYMENT_REPORT_AGGREGATE);

  const items = [
    {
      action: routeHelpers.employees.parameterize(),
      label: intl.formatMessage({
        id: 'workforce.employees.employees_section_header.employees',
        defaultMessage: 'Employees'
      })
    },
    hasReportAggregatePermission && {
      action: routeHelpers.badges.parameterize(),
      label: intl.formatMessage({
        id: 'workforce.employees.employees_section_header.badges',
        defaultMessage: 'Badges'
      })
    },
    hasReportAggregatePermission && {
      action: routeHelpers.occupations.parameterize(),
      label: intl.formatMessage({
        id: 'workforce.employees.employees_section_header.occupations',
        defaultMessage: 'Occupations'
      })
    },
    hasReportAggregatePermission && {
      action: routeHelpers.skills.parameterize(),
      label: intl.formatMessage({
        id: 'workforce.employees.employees_section_header.skills',
        defaultMessage: 'Skills'
      })
    }
  ].filter((i) => i);

  return (
    <GenericSectionHeader
      heading={intl.formatMessage({
        id: 'workforce.employees.employees_section_header.employees',
        defaultMessage: 'Employees'
      })}
      items={items}
    />
  );
};

EmployeesSectionHeader.propTypes = {
  baseUrl: PropTypes.string.isRequired
};
