import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash.debounce';
import { getRailsEnv } from 'app_utils';
import * as objUtils from 'utils/object';
import { QueryString } from 'utils/query_string';
import { ImmediateProperty } from 'utils/react_utils';
import { ResourceStatusPropType } from 'utils/prop_types';
import { Search, SearchBar, SearchResults } from 'controls/search';
import { SelectList } from 'controls/select_list';
import Tabs from 'controls/tabs';

import './directory_search.sass';

const DEFAULT_TABS = [
  { name: 'issuer', label: 'Issuers' },
  { name: 'badge', label: 'Badges' },
  { name: 'skill', label: 'Skills' },
  { name: 'location', label: 'Location' },
  { name: 'user', label: 'Earners' }
];

const NO_TERM_PROMPTS = {
  issuer: 'Type to select an issuer.',
  badge: 'Type to select a badge.',
  skill: 'Type to select a skill.',
  location: 'Type to select a location',
  user: 'Search for earners by name.'
};

/**
 * Search bar for directory.
 *
 * @param {function(array{key: string, value: string})} onSelect - Called when an item is selected
 *   from the list. The parameter is a set of new filters.
 * @param {function(term: String, type: String)} onTermChanged - Called when the search term changes
 * @param {Object} baseParams - Params that are passed through with every search request.
 * @param {Object} excludeSearchTypes - Set of search types to hide. If unspecified, allow all.
 * @param {Node} [filterTag] - Component containing the button for opening the filters modal
 * @param {String} [searchBarPlaceholderText] - Text to be displayed in the SearchBar component
 * @param {boolean} [suppressSearchWithoutTerm=false] - if false, don't make a search request until
 *   the user enters a search term and show a prompt to that effect
 * @param {Array} [tabs] - array of tabs to display/use to search. The name will be the key that's sent
 *   as the search type to the backend (unless using tabNamesToFilterKeyTransforms)
 * @param {Object} [tabNamesToFilterKeyTransforms] - Object containing function overrides when the tab
 *   names and filter keys need to differ or depend on the search term value. For example,
 *   { issuer: (data) => { data.value === 'test' ? 'something_totally_different' : 'user' } }
 * @param {boolean} [allowNotSearches] - prepends a `not_` to your search key if search term start with !
 */
class BaseDirectorySearch extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.m_currentSearchRequest = null;

    const tabs = this.getTabs();

    this.state = {
      clearSearch: new ImmediateProperty(),
      focusNow: new ImmediateProperty(),
      curTab: tabs[0].name,
      tabs: tabs,
      hasTerm: false,
      term: '',
      showSearchResults: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!objUtils.shallowEquals(prevProps.excludeSearchTypes, this.props.excludeSearchTypes)) {
      const tabs = this.getTabs();
      this.setState({ curTab: tabs[0].name, tabs: tabs });
    }
  };


  createFilter = data => {
    const overrideKeys = Object.keys(this.props.tabNamesToFilterKeyTransforms);
    let filterKey;
    if (overrideKeys && overrideKeys.includes(this.state.curTab)) {
      filterKey = this.props.tabNamesToFilterKeyTransforms[this.state.curTab](data);
    } else {
      filterKey = this.state.curTab;
    }
    // Hidden feature: NOT filters.
    if (this.props.allowNotSearches && this.state.term.substr(0, 1) === '!') {
      filterKey = `not_${filterKey}`;
    }
    return { key: filterKey, value: data.value };
  };

  /**
   * Get a list of available tabs.
   *
   * @returns {{name: string, label: string}[]}
   */
  getTabs() {
    const tabs = this.props.tabs || DEFAULT_TABS;
    return tabs.filter(tab => !this.props.excludeSearchTypes[tab.name]);
  }

  search = term => {
    // Don't automatically search users or locations unless there's a search term.
    if ((this.state.curTab === 'user' || this.state.curTab === 'location') && !term) {
      this.setState({ hasTerm: false, term: '' });
    } else {
      // Strip "NOT" and "substring" indicators
      const adjustedTerm = term.replace(/^!(.*)$|^\*(.*)\*$/, '$1$2');
      const q = new QueryString()
        .set('query', adjustedTerm)
        .set('search_type', this.state.curTab);

      objUtils.forEach(this.props.baseParams, (key, val) => q.set(key, val));

      this.m_currentSearchRequest && this.m_currentSearchRequest.abort();
      this.m_currentSearchRequest = this.props.search(q.get());

      this.setState({ hasTerm: !!term, term: term });
    }
  };

  searchTermChanged = term => {
    // Don't search in the background.
    if (this.state.showSearchResults) {
      this.search(term);
      if (term) {
        this.trackSearching(term, this.state.curTab);
      }
    } else {
      this.setState({ hasTerm: !!term, term: term });
    }
  };

  /**
   * Track strings typed during an active search.
   * At most once a second, track the most recent, non-empty search string.
   *
   * @param {String} term - The current (or most recent after debounce) search term.
   * @param {String} tab - The tab the user was on when this search took place.
   */
  trackSearching =
    debounce(this.props.onTermChanged, BaseDirectorySearch.SEARCH_TRACKING_DELAY);

  openSearch = () => {
    if (!this.state.showSearchResults) {
      this.setState({ showSearchResults: true });
      if (!this.props.suppressSearchWithoutTerm) {
        this.search('');
      }
    }
  };

  closeSearch = () => {
    this.setState({
      clearSearch: this.state.clearSearch.next(),
      showSearchResults: false,
      hasTerm: false,
      term: ''
    });
  };

  /**
   * A tab was clicked. Change the view, search, and re-focus the text box.
   *
   * @param {String} name
   */
  selectTab = name => {
    this.setState({ curTab: name }, () => {
      if (!this.props.suppressSearchWithoutTerm || this.state.term) {
        this.search(this.state.term);
      }
      // Re-focus the text box.
      this.setState({ focusNow: this.state.focusNow.next() });
    });
  };

  isBlankUserSearch() {
    return this.state.curTab === 'user' && !this.state.term;
  }

  isBlankLocationSearch() {
    return this.state.curTab === 'location' && !this.state.term;
  }

  /**
   * Render the "no results" view.
   *
   * @returns {String}
   */
  renderNoResults = () => {
    const noResultsText = () => {
      if (this.props.searchStatus.failed) {
        if (getRailsEnv() === 'development') {
          return (
            <FormattedMessage
              id="workforce.base.directory.development.error.message"
              defaultMessage="An error occurred while retrieving search results. See elasticsearch.md for instructions on how to set up ElasticSearch correctly."
            />
          );
        }
        return (
          <FormattedMessage
            id="workforce.base.directory.general.error.message"
            defaultMessage="An error occurred while retrieving search results. Please try again later."
          />
        );
      }

      if (!this.state.hasTerm) {
        if (this.isBlankUserSearch()) {
          return (
            <FormattedMessage
              id="workforce.base.directory.search.message"
              defaultMessage="Search for earners by name."
            />
          );
        } else if (this.isBlankLocationSearch()) {
          return (
            <FormattedMessage
              id="workforce.base.directory.type.select.message"
              defaultMessage="Type to select a location."
            />
          );
        } else if (this.props.suppressSearchWithoutTerm) {
          return NO_TERM_PROMPTS[this.state.curTab];
        } else {
          return (
            <FormattedMessage
              id="workforce.base.directory.no.results"
              defaultMessage="No results found."
            />
          );
        }
      }

      switch (this.state.curTab) {
        case 'badge':
          return (
            <FormattedMessage
              id="workforce.base.directory.no.badges"
              defaultMessage="No badges match your search."
            />
          );
        case 'skill':
          return (
            <FormattedMessage
              id="workforce.base.directory.no.skills"
              defaultMessage="No skills match your search."
            />
          );
        case 'issuer':
          return (
            <FormattedMessage
              id="workforce.base.directory.no.issuers"
              defaultMessage="No issuers match your search."
            />
          );
        case 'user':
          return (
            <FormattedMessage
              id="workforce.base.directory.no.earners"
              defaultMessage="There are no earners with that name in this directory."
            />
          );
        default:
          return (
            <FormattedMessage
              id="workforce.base.directory.no.results"
              defaultMessage="No results found."
            />
          );
      }
    };

    return (
      <p className="c-directory-search-results__no-results_text">
        {noResultsText()}
      </p>
    );
  };

  /**
   * A search result has been selected.
   *
   * @param {{key: string, value: string}} data - Filter for the search result.
   */
  onSelect = data => {
    const filterKeyAndValue = this.createFilter(data);
    this.props.onSelect(filterKeyAndValue);
    this.closeSearch();
  };

  /**
   * Render search results.
   *
   * @param {String} tab - The tab to render.
   * @returns {React.element}
   */
  renderResults = tab => {
    if (tab !== this.state.curTab) {
      return null;
    }

    let resultsUI;
    let results = this.props.results;

    if (
      (this.isBlankUserSearch() || this.isBlankLocationSearch() ||
        (!this.state.term && this.props.suppressSearchWithoutTerm)) &&
      results.length > 0
    ) {
      // Short-circuit the normal process. In this case, we've already done a search, so
      // SearchResults.renderNoResults won't fire, but we don't want to do a new search or display
      // the current results.
      resultsUI = this.renderNoResults();
    } else if (this.props.searchStatus.succeeded) {
      // Hidden feature: Substring search.
      if (this.state.term.match(/^\*.+\*$/) && results.length > 0) {
        results = [{ id: this.state.term, value: this.state.term }, ...results];
      }

      resultsUI = (
        <SelectList>{results.map(data =>
          <SelectList.Item
            key={data.id}
            onSelect={this.onSelect}
            onEscape={this.closeSearch}
            data={data}
            className="c-directory-search-bar__select-list-item"
          >
            {data.id}
          </SelectList.Item>)}
        </SelectList>
      );
    }

    return (
      <SearchResults
        renderNoResults={this.renderNoResults}
        className="c-directory-search__results"
      >
        {resultsUI}
      </SearchResults>
    );
  };

  render() {
    return (
      <div className="c-directory-search" ref={this.container}>
        <Search
          search={this.searchTermChanged}
          searching={this.props.searchStatus.pending}
          showResults={this.state.showSearchResults}
          results={this.props.searchStatus.succeeded ? this.props.results : []}
          showResultsWithNoTerm={!!this.props.suppressSearchWithoutTerm}
          clearSearch={this.state.clearSearch}
          onFocus={this.openSearch}
          onBlur={this.closeSearch}
          focusContainer={this.container}
          focusNow={this.state.focusNow}
        >
          <div className="c-directory-search__container">
            <div className="c-directory-search__wrapper">
              <SearchBar
                placeholder={this.props.searchBarPlaceholderText || 'Search directory'}
                showIcon
                theme="recruit"
              />
              {
                this.state.showSearchResults &&
                  <div className="c-directory-search__tabs-wrap">
                    <Tabs
                      current={this.state.curTab}
                      onChange={this.selectTab}
                      className="c-directory-search__tabs"
                      theme="text"
                    >
                      {this.state.tabs.map(tab =>
                        <Tabs.Tab key={tab.name} {...tab}>{this.renderResults(tab.name)}</Tabs.Tab>
                      )}
                    </Tabs>
                  </div>
              }
            </div>
            {this.props.filterTag && this.props.filterTag}
          </div>
        </Search>
      </div>
    );
  }
}

BaseDirectorySearch.SEARCH_TRACKING_DELAY = 700; // 700 + Search's max wait of 300 = 1s

BaseDirectorySearch.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onTermChanged: PropTypes.func.isRequired,
  excludeSearchTypes: PropTypes.object.isRequired,
  baseParams: PropTypes.object.isRequired,
  filterTag: PropTypes.node,
  suppressSearchWithoutTerm: PropTypes.bool,
  allowNotSearches: PropTypes.bool,
  searchBarPlaceholderText: PropTypes.string,
  tabNamesToFilterKeyTransforms: PropTypes.object,
  tabs: PropTypes.array,

  // From connect()
  results: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  search: PropTypes.func.isRequired,
  searchStatus: ResourceStatusPropType.isRequired
};

BaseDirectorySearch.defaultProps = {
  tabNamesToFilterKeyTransforms: {
    issuer: () => 'issuer_name',
    badge: () => 'badge_name',
    skill: () => 'skill_name',
    location: () => 'location_name',
    user: data => data.value.indexOf('@') > 0 ? 'email' : 'user_name'
  }
};

export { BaseDirectorySearch };
