import { Card } from 'controls/card';
import { LoadingSpinner } from 'controls/loading_spinner';
import { SmartLink } from 'controls/smart_link';
import { EmployeePhoto } from './employee_photo';
import { ConditionalWrapper } from 'controls/conditional_wrapper';
import { makeClassName } from 'utils';
import { Text } from "controls/text";
import React from 'react';
import PropTypes from 'prop-types';

import './employee_card.sass';

export const EmployeeCardContext = React.createContext({});
export const EMPLOYEE_CARD_IMAGE_SMALL_WIDTH = 120;

export const EmployeeCard = ({
  data,
  display,
  className,
  loading,
  ...otherProps
}) => {
  if (!data) {
    return <></>;
  }
  return (
    <Card
      {...otherProps}
      className={makeClassName(
        'employee-card',
        'employee-card--small',
        loading && `employee-card--loading`,
        className
      )}
    >
      <EmployeeCardContext.Provider
        value={{
          data,
          loading
        }}
      >
        {(display({
          Visual: EmployeeCard.Visual,
          Body: EmployeeCard.Body,
          Footer: EmployeeCard.Footer
        }))}
      </EmployeeCardContext.Provider>
    </Card>
  );
};

EmployeeCard.displayName = 'EmployeeCard';
EmployeeCard.propTypes = {
  data: PropTypes.shape({
    photo_url: PropTypes.string,
    full_name: PropTypes.string,
    email: PropTypes.string.isRequired,
    position_title: PropTypes.string,
    department: PropTypes.string
  }),
  display: PropTypes.func.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool
};

EmployeeCard.defaultProps = {
  display: ({ Visual, Body, Footer }) => (
    <>
      <Visual
        className="employee-card__visual--small"
        width={EMPLOYEE_CARD_IMAGE_SMALL_WIDTH}
      />
      <Body />
      <Footer />
    </>
  )
};

const EmployeeCardVisual = ({ className, ...otherProps }) => {
  const contextValue = React.useContext(EmployeeCardContext);

  const { data, loading } = contextValue;
  const visual = (
    <ConditionalWrapper
      tagName={SmartLink}
      props={{ action: data?.employee_profile_path }}
      active={!loading}
    >
      <Card.Visual
        {...otherProps}
        size="small"
        className={makeClassName("employee-card__visual", className)}
      >
        {!loading ? (
          <EmployeePhoto
            className="employee-card__image"
            photo_url={data?.photo_url}
          />
        ) : <LoadingSpinner className="employee-card__image-placeholder" />}
      </Card.Visual>
    </ConditionalWrapper>
  );

  return visual;
};

EmployeeCardVisual.displayName = 'EmployeeCard.Visual';
EmployeeCardVisual.propTypes = {
  data: PropTypes.shape({
    photo_url: PropTypes.string,
    full_name: PropTypes.string,
    email: PropTypes.string.isRequired,
    employee_profile_path: PropTypes.string
  }),
  width: PropTypes.number
};

EmployeeCard.Visual = EmployeeCardVisual;

const EmployeeCardTitle = ({ ...props }) => {
  const contextValue = React.useContext(EmployeeCardContext);
  const { className, data } = {
    ...contextValue,
    ...props
  };

  return (
    <Text
      appearance="title"
      size={2}
      display="block"
      nowrap
      className={
        makeClassName('employee-card__title', className)
      }
    >
      {data.full_name || data.email}
    </Text>
  );
};

EmployeeCardTitle.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    full_name: PropTypes.string,
    email: PropTypes.string.isRequired
  }),
  className: PropTypes.string
};

EmployeeCardTitle.displayName = 'EmployeeCard.Title';
EmployeeCard.Title = EmployeeCardTitle;

const EmployeeCardSubTitle = ({ ...props }) => {
  const contextValue = React.useContext(EmployeeCardContext);
  const { className, data } = {
    ...contextValue,
    ...props
  };

  return (
    <Text
      appearance="title"
      size={2}
      display="block"
      nowrap
      className={
        makeClassName(
          'employee-card__sub-title',
          className
        )
      }
    >
      {data.position_title}
    </Text>
  );
};

EmployeeCardSubTitle.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    position_title: PropTypes.string
  }),
  className: PropTypes.string
};

EmployeeCardSubTitle.displayName = 'EmployeeCard.SubTitle';
EmployeeCard.SubTitle = EmployeeCardSubTitle;

const EmployeeCardBody = ({ className, display, ...otherProps }) => {
  return (
    <Card.Body
      {...otherProps}
      className={makeClassName('employee-card__body', className)}
    >
      {display({
        Title: EmployeeCard.Title,
        SubTitle: EmployeeCard.SubTitle
      })}
    </Card.Body>
  );
};

EmployeeCardBody.displayName = 'EmployeeCard.Body';
EmployeeCardBody.propTypes = {
  className: PropTypes.string,
  display: PropTypes.func.isRequired
};

EmployeeCardBody.defaultProps = {
  display: ({ Title, SubTitle }) => (
    <>
      <Title/>
      <SubTitle />
    </>
  )
};

EmployeeCard.Body = EmployeeCardBody;

const EmployeeCardFooter = ({ className }) => {
  const contextValue = React.useContext(EmployeeCardContext);
  const { data } = contextValue;
  return (
    <Card.Footer
      className={makeClassName('employee-card__footer', className)}
    >
      {data.department || '--'}
    </Card.Footer>
  );
};

EmployeeCardFooter.displayName = 'EmployeeCard.Footer';
EmployeeCardFooter.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    department: PropTypes.string
  })
};

EmployeeCard.Footer = EmployeeCardFooter;
