import React, { useRef, useState, useEffect } from 'react';
import { LineChart, LineChartLabel } from 'charts/line_chart/line_chart';
import { Button } from 'controls/button';
import PropTypes from 'prop-types';
import mockData from './mock_skill_gained_data.json';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading } from 'controls/heading';
import { DataFilters, skillsGainedSortFilterOptions } from './data_filters';
import { getMaxAxis, randomizeData, sortData, formatTooltip } from './utils';

import './skills_gained_chart.sass';


export const SkillsGainedChart = ({ data = mockData }) => {
  const scrollContainerRef = useRef();
  const { formatMessage } = useIntl();

  const [sortBy, setSortBy] = useState(
    skillsGainedSortFilterOptions(formatMessage)[0]?.selectionValue
  );
  const [currentData, setCurrentData] = useState([...data]);
  const [sortedData, setSortedData] = useState(sortData([...currentData], sortBy));
  const [maxAxis, setMaxAxis] = useState(getMaxAxis(sortedData));

  useEffect(() => {
    setSortedData(sortData([...currentData], sortBy));
    setMaxAxis(getMaxAxis(currentData));
  }, [currentData, sortBy]);

  const handleRandomize = () => {
    let randomizedData = randomizeData(data);
    randomizedData = sortData(randomizedData, sortBy);
    setCurrentData(randomizedData);
  };

  return (
    <div>
      <Button
        onClick={handleRandomize}
      >
        Randomize
      </Button>

      <div className="workforce__skills-gained-chart-header-container">
        <div>
          <Heading
            appearance="section-subheader"
            className="workforce__skills-gained-chart-title"
          >
            <FormattedMessage
              defaultMessage="Skills Gained"
              id="workforce.skills_explorer.skills_gained_chart.heading"
            />
          </Heading>
          <FormattedMessage
            className="workforce__skills-gained-chart-subtitle"
            defaultMessage="Track gains in verified skills."
            id="workforce.skills_explorer.skills_gained_chart.sub_heading"
          />
        </div>
        <DataFilters
          sortBy={sortBy}
          setSortFilterState={setSortBy}
        />
      </div>

      <div
        className="workforce__skill-gained-chart-container"
      >
        <div
          ref={scrollContainerRef}
          className="workforce__skill-gained-chart-container__content"
        >
          <div className="workforce__skill-gained-chart-container__sticky">
            <div
              className="workforce__skill-gained-chart-container__legend"
            >
              <div className="legend-circle black" />
              <FormattedMessage
                defaultMessage="Previous count"
                id="workforce.skills_explorer.skills_gained_chart.legend_prev"
              />
              <div className="legend-circle orange" />
              <FormattedMessage
                defaultMessage="Current count"
                id="workforce.skills_explorer.skills_gained_chart.legend_current"
              />
            </div>
            <div className="skills-gained-container__data-label">
              <LineChartLabel
                roundedMax={maxAxis}
              />
            </div>
          </div>
          {
            sortedData.map((skill, idx) => (
              <div
                key={skill.skill_id} className="skills_gained_container"
              >
                <h5>{skill.skill_name}</h5>
                <LineChart
                  formatTooltip={formatTooltip(formatMessage)}
                  scrollContainerRef={scrollContainerRef}
                  data={skill} showLabel={idx === 0}
                  roundedMax={maxAxis}
                />
              </div>
            )
            )
          }
        </div>
      </div>
    </div >

  );
};


SkillsGainedChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    skill_name: PropTypes.string,
    skill_id: PropTypes.string,
    trend_period: PropTypes.string,
    prev: PropTypes.number,
    current: PropTypes.number
  }))
};
