import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import HCmore from 'highcharts/highcharts-more';
import { mergeDeep } from './utils';

import './bubble_chart.sass';

HCmore(Highcharts);
highchartsAccessibility(Highcharts);

/**
 * Renders a Highcharts bubble chart
 *
 * @param {object} props
 * @param {object} props.customOptions - A hash of custom overrides (see https://api.highcharts.com/highcharts/)
 * @param {array} props.dataSeries - The formatted data to feed to Highcharts
 * @param {object} props.onResize - A function to run on window resize
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const BubbleChart = (
  {
    dataSeries,
    customOptions,
    onResize
  }
) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (onResize) {
      const throttled = throttle(() => {
        const chart = chartRef.current?.chart;
        if (chart) {
          onResize(chart);
        }
      }, 500, { leading: true, trailing: true });
      window.addEventListener('resize', throttled);
      return () => {
        window.removeEventListener('resize', throttled);
      };
    }
  }, [onResize, chartRef.current]);

  const defaultOptions = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'bubble',
      plotBorderWidth: 1,
      events: {
        load: function() {
          this.container.addEventListener('wheel', function(e) {
            e.preventDefault();
            // Manually scroll container
            // eslint-disable-next-line no-unused-expressions
            window?.scrollBy({
              top: e.deltaY,
              left: e.deltaX,
              behavior: 'auto'
            });
          }, { passive: false });
        }
      },
      style: {
        fontFamily: 'Open Sans'
      },
      alignTicks: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: '' // if this isn't explicitly set, the text 'Chart Title' gets rendered
    },
    xAxis: {
      max: 100,
      min: 0,
      tickLength: 0,
      labels: {
        format: '{value}',
        style: {
          fontWeight: 'light',
          color: '#4E4E4E'
        }
      },
      lineWidth: 1,
      lineColor: '#000000',
      plotLines: [{
        color: '#4E4E4E',
        dashStyle: 'dash',
        width: 0.75,
        value: 50
      }],
      tickAmount: 3
    },
    yAxis: {
      labels: {
        format: '{value}%',
        style: {
          fontWeight: 'light',
          color: '#4E4E4E'
        }
      },
      max: 100,
      min: 0,
      tickAmount: 3,
      lineWidth: 1,
      lineColor: '#000000',
      plotLines: [{
        color: '#4E4E4E',
        dashStyle: 'dash',
        width: 0.75,
        value: 50
      }]
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        stickyTracking: false,
        point: {
          events: {}
        }
      }
    },
    series: dataSeries
  };

  const mergedOptions = mergeDeep(defaultOptions, customOptions);

  return (
    <HighchartsReact
      className="highcharts-bubble-chart"
      containerProps={{ style: { height: "600px", display: 'flex' } }}
      highcharts={Highcharts}
      options={mergedOptions}
      ref={chartRef}
    />
  );
};

BubbleChart.propTypes = {
  customOptions: PropTypes.object,
  dataSeries: PropTypes.array.isRequired,
  onResize: PropTypes.func
};
