import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { signinRouteList } from 'pages/signin/signin_routes_map';
import CredlyRoute from 'controls/credly_route';
import SkipLinkTarget from 'controls/skip_link_target';
import { CredlySwitch } from 'controls/credly_switch';
import { EarnerRoutes } from 'pages/earner';
import { EarnerTranscriptRoutes, UpdateProfileForDownload } from 'pages/transcript';
import { SignInRoutes } from 'pages/signin';
import { ShareRoutes } from 'pages/earner/share_badge';
import { JobsRoutes } from 'pages/jobs';
import { OccupationsRoutes } from 'pages/occupations';
import { SkillsRoutes } from 'pages/skills';
import { ShowBadgeTemplate } from 'pages/public/badge_templates';
import { EmailRoutes } from 'pages/from_email';
import { CredlyMigrationActivation } from 'pages/credly_migration';
import { PublicBadgeLayout } from 'pages/badges/public_badge';
import { EarnedBadgeLayout } from 'pages/badges/earner_badge';
import { PreservedBadgeLayout } from 'pages/badges/preserved_badge';
import { ViewableBadgeLayout } from 'pages/badges/viewable_badge';
import { BadgeTemplateLmiRoute } from 'pages/badge_templates';
import { ManagementLayout } from 'management';
import {
  OrganizationLayout,
  CustomDirectoryLayout
} from 'pages/public/organization';
import { Routes as RecruitRoutes, TalentMatchRoutes } from 'pages/credly_recruit';
import { WorkforceDispatch } from 'pages/workforce';
import { UserLayout, TranscriptDownloadLayout } from 'pages/public/user';
import { Homepage } from 'pages/homepage/homepage';
import { DownloadRedirect } from 'download_redirect';
import { organizationManagementUrl, workforceUrl } from 'utils/routes';
import { Devtools } from 'pages/devtools';
import { JobBoard } from './pages/credly_recruit/job_board';


/**
 * Global routes for react-router client-side routing.
 *
 * ========================================================
 * READ THIS BEFORE ADDING ROUTES. REVENUE MAY BE IMPACTED.
 * ========================================================
 *
 * To maximize page performance and minimize bounce rate:
 *
 * 1) Files in the import list above must lazy-load all components. Light-weight, dependency-free
 *    support files do not have to be lazy-loaded.
 * 2) Reference lazy-loaded components inside the `render` property of the route, not as
 *    direct children of <Routes>, or they will be sucked into the main roll-up, or even duplicated.
 */
export class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = { title: 'Badge Template' };
  }


  /**
   * A template has been loaded. Change the page title to match the name of the badge template
   *
   * @param {{title: String}} template - The badge template object that was loaded.
   */
  onTemplateLoaded = template => {
    this.setState({
      title: template ? template.title : 'Badge Template'
    });
  };

  render() {
    return (
      <Fragment>
        <SkipLinkTarget name="root"/>
        <CredlySwitch>
          {/* Handles routes within Organization Management UI */}
          <CredlyRoute
            path={organizationManagementUrl.root()}
            render={(routeProps) =>
              <ManagementLayout
                baseUrl={routeProps.match.url}
                history={routeProps.history}
              />
            }
            title="Organization Management"
          />

          {/* For notification preferences link in email */}
          <Redirect from="/profile/settings" exact to="/earner/settings/account"/>

          {/* Aliases to maintain the routes of the old UI */}
          <Redirect from="/profile/badges/:id" to="/badges/:id"/>
          <Redirect from="/profile/badges" to="/earner/earned"/>
          <Redirect
            from="/secondary_email_addresses/unconfirmed/:id"
            to="/earner/settings/account"/>
          <Redirect from="/profile/onboarding/badge/:id" to="/badges/:id"/>
          <Redirect
            from="/profile/merge_account/confirm_authorization"
            exact
            to="/earner/settings/merge/activate"
          />
          <CredlyRoute
            path="/"
            exact
            render={(_routeProps) => (<Homepage />)}
            title="Credly"
            anonymous
          />
          <CredlyRoute
            path="/download_redirect"
            render={(routeProps) => {
              const queryParams = new URLSearchParams(routeProps.location.search);
              const downloadUrl = queryParams.get('downloadUrl');
              const redirectUrl = queryParams.get('redirectUrl');
              return <DownloadRedirect downloadUrl={downloadUrl} redirectUrl={redirectUrl}/>;
            }}
          />
          <CredlyRoute
            path="/users/credly/activation"
            render={(routeProps) => (<CredlyMigrationActivation {...routeProps} />)}
            title="Activate Your Account"
            anonymous
          />
          <CredlyRoute
            path="/earner/earned/share"
            render={(routeProps) => (<ShareRoutes {...routeProps} />)}
            title="Share Badge"
          />
          <CredlyRoute
            path="/earner/transcript"
            render={(routeProps) => (<EarnerTranscriptRoutes {...routeProps} />)}
          />
          <CredlyRoute
            exact
            path="/earner/earned/badge/:id"
            render={routeProps => (
              <EarnedBadgeLayout
                onBadgeLoaded={() => {}}
                badgeId={routeProps.match.params.id}
              />
            )}
            title={this.state.showBadgeTitle}
          />
          <CredlyRoute
            path="/earner"
            render={(routeProps) => (<EarnerRoutes {...routeProps} />)}
          />
          <CredlyRoute
            path="/email_preferences"
            render={(routeProps) => (<EmailRoutes {...routeProps}/>)}
            anonymous
          />
          <CredlyRoute
            path="/jobs"
            render={(routeProps) => (<JobsRoutes {...routeProps} ui="jobs"/>)}
            title="Jobs"
            anonymous
          />

          {/*
             SigninRoutes and BadgesLayout need this "matches" syntax so they don't
             interfere with each other. Both handle /badge routes. */
          }
          <CredlyRoute
            path={signinRouteList}
            render={(routeProps) => (<SignInRoutes {...routeProps}/>)}
            title="Credly - Sign In"
            anonymous
          />
          <CredlyRoute
            path="/badges/:id/:source?"
            render={(routeProps) => (<PublicBadgeLayout {...routeProps}/>)}
            title="Badge"
            anonymous
          />
          <CredlyRoute
            path="/viewable_badge/:organization_id/:badge_id"
            render={(routeProps) => (<ViewableBadgeLayout {...routeProps}/>)}
            title="Badge"
          />
          <CredlyRoute
            path="/preserved-badge/:id"
            render={(routeProps) => (<PreservedBadgeLayout {...routeProps}/>)}
            title="Preserved Badge"
            anonymous
          />
          <CredlyRoute
            path="/directory/:filterId"
            render={(routeProps) => (
              <CustomDirectoryLayout filterId={routeProps.match.params.filterId}/>
            )}
            title="Earner Directory"
            anonymous
          />
          <CredlyRoute
            path="/recruit"
            render={() => <RecruitRoutes/>}
          />
          <CredlyRoute
            path="/talent_match"
            render={(routeProps) => (
              <TalentMatchRoutes basePath={routeProps.match.url} />
            )}
          />
          <CredlyRoute
            path="/job_board"
            title="Job Board"
            render={(_routeProps) => (
              <JobBoard />
            )}
          />
          <CredlyRoute
            anonymous
            path="/occupations"
            render={(routeProps) => (
              <OccupationsRoutes match={{ url: routeProps.match.url }} />
            )}
            title="Occupation"
          />
          <CredlyRoute
            exact
            anonymous
            path="/org/:org_vanity_slug"
            render={(routeProps) => (
              <Redirect to={`/organizations/${routeProps.match.params.org_vanity_slug}`}/>
            )}
            title="Organization"
          />
          <CredlyRoute
            anonymous
            path="/org/:organization_id/badge/:badge_template_id/lmi"
            title={this.state.badgeTemplateLmiTitle}
            render={routeProps => (
              <BadgeTemplateLmiRoute
                routeProps={routeProps}
                path="/org/:organization_id/badge/:badge_template_id/lmi"
              />
            )}
          />
          <CredlyRoute
            path="/org/:org_vanity_slug/badge/:badge_template_vanity_slug"
            render={(routeProps) => (
              <ShowBadgeTemplate
                organizationVanitySlug={routeProps.match.params.org_vanity_slug}
                badgeTemplateVanitySlug={routeProps.match.params.badge_template_vanity_slug}
                onTemplateLoaded={this.onTemplateLoaded}
              />
            )}
            title={this.state.title}
            anonymous
          />
          <CredlyRoute
            anonymous
            path="/organizations/:org_vanity_slug"
            render={(routeProps) =>
              (<OrganizationLayout
                location={routeProps.location}
                path={routeProps.match.url}
                org_vanity_slug={routeProps.match.params.org_vanity_slug}
              />)
            }
            title="Organization"
          />
          <CredlyRoute
            path="/users/:user_vanity_slug/transcripts/:id/download_via_update_profile"
            render={(routeProps) => (
              <UpdateProfileForDownload
                userVanitySlug={routeProps.match.params.user_vanity_slug}
                transcriptRecipientId={routeProps.match.params.id}
              />
            )}
            title="Complete Your Profile to Download Transcript"
          />
          <CredlyRoute
            anonymous
            path={TranscriptDownloadLayout.matches}
            render={(routeProps) =>
              (<TranscriptDownloadLayout
                location={routeProps.location}
                path={routeProps.match.url}
                userVanitySlug={routeProps.match.params.user_vanity_slug}
                transcriptDistributionRecipientId={routeProps.match.params.id}
              />)
            }
            title="User"
          />
          <CredlyRoute
            anonymous
            path="/users/:user_vanity_slug"
            render={(routeProps) =>
              (<UserLayout
                location={routeProps.location}
                path={routeProps.match.url}
                userVanitySlug={routeProps.match.params.user_vanity_slug}
              />)
            }
            title="User"
          />
          <CredlyRoute
            path="/skills"
            render={(routeProps) => (<SkillsRoutes {...routeProps} ui="skills"/>)}
            title="Skills"
            anonymous
          />

          <CredlyRoute
            path="/devtools"
            render={() => (<Devtools />)}
            title="Credly Developer Tools"
            anonymous
          />

          <CredlyRoute
            path={workforceUrl.root()}
            render={() => (<WorkforceDispatch/>)}
            title="Workforce"
          />
        </CredlySwitch>
      </Fragment>
    );
  }
}
