import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { intlKeyFromValue } from 'translations';
import { Heading } from 'controls/heading';
import { WithSectionHeader } from 'controls/section_header';
import { SectionNavigation } from 'controls/section_navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { faIdCardClip } from '@fortawesome/pro-regular-svg-icons/faIdCardClip';
import { faCircleMinus } from '@fortawesome/pro-regular-svg-icons/faCircleMinus';
import { faTimer } from '@fortawesome/pro-regular-svg-icons/faTimer';
import { Permissions, usePermissions } from 'management/organizations/permissions';
import { EmployeeRouteHelpers } from 'pages/workforce/route_helpers';
import { EmployeeProfileInvitationStatus } from './employee_profile_invitation_status';
import { Link } from 'react-router-dom';
import { ClampLines } from 'controls/clamp_lines';

import './employee_profile_header.sass';

const InvitationStatusIconVariants = {
  Accepted: faCheckCircle,
  Created: faCircleMinus,
  Invited: faTimer,
  Pending: faTimer,
  Rejected: faCircleXmark
};

export const WithEmployeeProfileHeader = (props) => {
  const intl = useIntl();
  const employee = props.employee;
  const organization = props.organization;
  const routeHelpers = EmployeeRouteHelpers.forUrl(props.baseUrl);
  const name = useMemo(() => (
    (employee.full_name || '').trim() ||
    employee.email ||
    intl.formatMessage({
      id: 'workforce.employees.show.profile.header.unknown_email',
      defaultMessage: 'Unknown'
    })
  ), [employee.full_name, employee.email]);

  const sectionHeader = useMemo(() => {
    return () =>
      <EmployeeProfileSectionHeader
        employee={employee}
        organization={organization}
        name={name}
        {...props}
      />;
  }, [
    name,
    props.baseUrl,
    organization?.attributes?.name,
    employee
  ]);


  return (
    <WithSectionHeader
      sectionHeader={sectionHeader}
    >
      {props.children}
    </WithSectionHeader>
  );
};

const EmployeeProfileSectionHeader = (props) => {
  const employee = props.employee;
  const invitationStatusKey = employee.invitation_status?.replace(' ', '');
  const [hasPermissions] = usePermissions();
  const hasEditPermission = hasPermissions(Permissions.EMPLOYMENT_UPDATE);
  const intl = useIntl();
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const routeHelpers = EmployeeRouteHelpers.forUrl(props.baseUrl);

  return (
    <div className="wf-employees-employee-profile-header">
      <div className="wf-employees-employee-profile-header__overview-section">
        <div className="wf-employees-employee-profile-header__overview-header-container">
          <div className="wf-employees-employee-profile-header__overview-header-info-container">
            <div
              className="wf-employees-employee-profile-header__overview-header-text-info-container"
            >
              <div className="wf-employees-employee-profile-header__overview-title-container">
                <Heading
                  appearance="custom"
                  className="wf-employees-employee-profile-header__heading"
                >
                  <ClampLines
                    lines={2}
                    cssClamp={{ lineHeight: 48 }}
                    attributes={{ title: props.name || employee.email }}
                  >
                    {props.name || employee.email}
                  </ClampLines>
                </Heading>
                <div className="wf-employees-employee-profile-header__overview-links-container">
                  {employee.public_profile_path &&
                    <Link
                      id="wf-employees-employee-profile-header__employee-public-profile-link"
                      to={`${employee.public_profile_path}`}
                      className="wf-employees-employee-profile-header__overview-link"
                    >
                      <h3>
                        <FormattedMessage
                          id="employee_profile.header.view_public_profile"
                          defaultMessage="View Public Profile"
                        />
                      </h3>
                    </Link>
                  }
                  {
                    hasEditPermission && (
                      <Link
                        id="wf-employees-employee-profile-header__employee-edit-profile-link"
                        to={`${props.baseUrl}/edit`}
                        className="wf-employees-employee-profile-header__overview-link"
                      >
                        <h3>
                          <FormattedMessage
                            id="employee_profile.header.edit_profile"
                            defaultMessage="Edit Profile"
                          />
                        </h3>
                      </Link>
                    )
                  }
                </div>
              </div>
              {employee.position_title &&
                <div className="wf-employees-employee-profile-header__subtitle-container">
                  <p>
                    {employee.position_title}
                    {props.organization?.name &&
                      ` at ${props.organization?.name}`}
                  </p>
                </div>
              }
              {employee.bio &&
                <div className="wf-employees-employee-profile-header__bio-container">
                  <p id="wf-employees-employee-profile-header__bio">
                    {isReadMore ? employee.bio.slice(0, 150) : employee.bio}
                    {employee.bio.length > 150 &&
                      <span
                        onClick={toggleReadMore}
                        className="wf-employees-employee-profile-header__bio-expansible-text-toggle"
                      >
                        {isReadMore ? intl.formatMessage({
                          id: 'employee_profile.header.read_more',
                          defaultMessage: '...   Read more'
                        }) : intl.formatMessage({
                          id: 'employee_profile.header.show_less',
                          defaultMessage: '   Show less'
                        })}
                      </span>
                    }
                  </p>
                </div>
              }
            </div>
            <div className="wf-employees-employee-profile-header__overview-tags">
              <div>
                <EmployeeProfileInvitationStatus
                  children={
                    employee.invitation_status &&
                      <div
                        id="wf-employees-employee-profile-header__invitation-status-chip"
                        className={`wf-employees-employee-profile-header__overview-status
                        status-${invitationStatusKey}-background`}
                      >
                        <div
                          id="wf-employees-employee-profile-header__invitation-status-chip-icon"
                          className={`wf-employees-employee-profile-header__overview-status-icon
                          status-${invitationStatusKey}-icon`}
                        >
                          <FontAwesomeIcon
                            icon={InvitationStatusIconVariants[invitationStatusKey]}
                          />
                        </div>
                        <div
                          id="wf-employees-employee-profile-header__invitation-status-chip-title"
                          className="wf-employees-employee-profile-header__overview-status-title"
                        >
                          <h3>
                            <FormattedMessage
                              id={intlKeyFromValue(
                                employee.invitation_status,
                                "employee_profile.header.invitation_status"
                              )}
                              defaultMessage={employee.invitation_status}
                            />
                          </h3>
                        </div>
                      </div>
                  }
                  organization={props.organization}
                  employee={props.employee}
                />
              </div>
              {employee.mapped !== null && employee.mapped !== undefined &&
                <div className={
                  `wf-employees-employee-profile-header__overview-status
                  ${employee.mapped ? 'status-accepted-background'
                    : 'status-rejected-background'}`}
                >
                  <div className={
                    `wf-employees-employee-profile-header__overview-status-icon
                    ${employee.mapped ? 'status-accepted-icon'
                      : 'status-rejected-icon'}`}
                  >
                    <FontAwesomeIcon icon={faIdCardClip}/>
                  </div>
                  <div
                    className="wf-employees-employee-profile-header__overview-status-title"
                  >
                    <h3>
                      <FormattedMessage
                        id="employee_profile.header.confidently_mapped"
                        defaultMessage="Confidently mapped"
                      />
                    </h3>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <SectionNavigation>
        <SectionNavigation.Link
          exact
          action={routeHelpers.showEmployee.parameterize({ id: employee.id })}
        >
          <h3>
            <FormattedMessage
              id="employee_profile.header.tab.details"
              defaultMessage="Details"
            />
          </h3>
        </SectionNavigation.Link>
        <SectionNavigation.Link
          exact
          action={routeHelpers.showEmployeeBadges.parameterize({ id: employee.id })}
        >
          <h3>
            <FormattedMessage
              id="employee_profile.header.tab.badges"
              defaultMessage="Badges"
            />
          </h3>
        </SectionNavigation.Link>
        <SectionNavigation.Link
          action={routeHelpers.showEmployeeSkills.parameterize({ id: employee.id })}
        >
          <h3>
            <FormattedMessage
              id="employee_profile.header.tab.skills"
              defaultMessage="Skills"
            />
          </h3>
        </SectionNavigation.Link>
      </SectionNavigation>
    </div>
  );
};

EmployeeProfileSectionHeader.propTypes = {
  baseUrl: PropTypes.string,
  employee: PropTypes.object,
  name: PropTypes.string,
  organization: PropTypes.object
};

WithEmployeeProfileHeader.propTypes = {
  ...WithSectionHeader.propTypes,
  ...EmployeeProfileSectionHeader.propTypes
};
