import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import './pagination_metadata.sass';

export const PaginationMetadata = (props) => {
  const intl = useIntl();
  const metadata = props.metadata;
  const exceedNumber = props.exceedNumber;
  const showingFloor = (metadata.current_page - 1) * metadata.per + 1;
  const showingCeiling = showingFloor + metadata.count - 1;
  const totalCount = exceedNumber && metadata.total_count >= exceedNumber
    ? intl.formatMessage({ id: 'total_count.plus', defaultMessage: '{exceedNumber, number}+' }, { exceedNumber })
    : intl.formatMessage({ id: 'total_count.plain', defaultMessage: '{totalCount, number}' }, { totalCount: metadata.total_count });

  if (metadata.total_count === 0 && props.hideNoResults) {
    return null;
  } else {
    return (
      <div className="c-pagination-metadata">
        <FormattedMessage
          id="pagination_meta_data_count"
          defaultMessage="Showing {showingFloor, number}-{showingCeiling, number} of {totalCount}"
          values={{
            showingFloor: showingFloor,
            showingCeiling: showingCeiling,
            totalCount: totalCount
          }}
        />
      </div>
    );
  }
};
