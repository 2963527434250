import React from 'react';
import { FormattedMessage } from 'react-intl';
import EmptySearch from 'images/employees_empty_search.svg';

import './empty_learning_plans.sass';

export const EmptyLearningPlans = () => {
  return (
    <div className="empty-learning-plan">
      <div className="empty-learning-plan__image-container">
        <img
          className="empty-learning-plan__image"
          src={EmptySearch}
          alt=""
        />
        <FormattedMessage
          id="workforce.learning_plan.index.empty_learning_plan"
          defaultMessage="No recommendations yet. Once you recommend a badge to your team you can track their progress here."
        />
      </div>
    </div>
  );
};
