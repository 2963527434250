import React from 'react';
import PropTypes from 'prop-types';
import { useIsMounted } from 'utils/react_utils';
import { useDirectoryQueryStringFilter } from 'pages/public/organization/earner_directory/shared/use_directory_query_string_filter';
import { LoadingSpinner } from 'controls/loading_spinner';
import { filter } from 'pages/public/organization/earner_directory/earner_directory_actions';
import { DirectoryResultsHeader } from 'pages/public/organization/earner_directory/shared/directory_results_header';
import { DirectoryPagination } from 'pages/public/organization/earner_directory/shared/directory_pagination';
import './directory_results_wrapper.sass';

/**
 * Component that wraps the content of directory results with header content and pagination.
 * Also handles displaying content conditional on state of the directory results request
 * (pending, failed, succeeded)
 * @param {Object} props
 *   @param {Node} [props.addRowTag=null] - Component containing the button for adding a new "row",
 *     in the case of employees see EmployeeDirectory
 *   @param {Boolean} [props.alwaysRenderHeader=false] - When true it forces the table header to render
 *     even if there are no results
 *   @param {Object} props.baseParams - Query params to pass through in all API requests.
 *   @param {Object} props.trackingData - Base stat object data to pass to tracking functions.
 *   @param {Object} props.createExportArgs - Object describing the type of export that will
 *   be created. See EmployeeDirectory.
 *   @param {Object} props.readExportArgs - Object describing the type of export that will
 *   be created. See EmployeeDirectory.
 *   @param {Boolean} props.exportEnabled - Displays an export button to export results if enabled.
 *   @param {Function} props.resultsRender - Callback function that accepts two parameters filterState,
 *   filters and passes it in to the rendered output. If not defined will fallback to a default.
 *   @param {Boolean} [props.sortSelectorEnabled=true] - Controls if the DirectorySortSelector in
 *     DirectoryResultsHeader should be rendered
 */
export const DirectoryResultsWrapper = (props) => {
  const [filterState] = props.filterAction.useAction();
  const isMounted = useIsMounted();
  const filters = useDirectoryQueryStringFilter();
  const meta = filterState.metadata;
  const content = () => {
    if (filterState.status.pending || !isMounted) {
      return <LoadingSpinner position="below"/>;
    } else if (filterState.status.failed) {
      return (
        <div className="c-directory-results-wrapper__load-error">
          <div>An error occurred while searching the directory.</div>
          {props.baseParams.filter_id && <div>The url may no longer be valid.</div>}
        </div>
      );
    } else {
      return (
        <>
          {
            (props.alwaysRenderHeader || meta.total_count > 0) &&
              <DirectoryResultsHeader
                baseParams={props.baseParams}
                createExportArgs={props.createExportArgs}
                exportButton={props.exportButton}
                readExportArgs={props.readExportArgs}
                addRowTag={props.addRowTag}
                sortSelectorEnabled={props.sortSelectorEnabled}
                exportEnabled={props.exportEnabled}
                exportRemoveFilter={props.exportRemoveFilter}
                filterAction={props.filterAction}
              />
          }
          {props.resultsRender && props.resultsRender(filterState, filters)}
          <DirectoryPagination filterAction={props.filterAction}/>
        </>
      );
    }
  };
  return (
    <div className="c-directory-results-wrapper">
      {content()}
    </div>
  );
};

DirectoryResultsWrapper.propTypes = {
  addRowTag: PropTypes.node,
  alwaysRenderHeader: PropTypes.bool,
  baseParams: PropTypes.object,
  exportButton: PropTypes.func,
  createExportArgs: PropTypes.object,
  readExportArgs: PropTypes.object,
  resultsRender: PropTypes.func,
  sortSelectorEnabled: PropTypes.bool,
  exportEnabled: PropTypes.bool,
  exportRemoveFilter: PropTypes.bool,
  filterAction: PropTypes.object
};

DirectoryResultsWrapper.defaultProps = {
  addRowTag: null,
  alwaysRenderHeader: false,
  filterAction: filter,
  sortSelectorEnabled: true
};
