import React from 'react';
import PropTypes from 'prop-types';
import { RouterPropTypes } from 'utils/prop_types';
import { OrgMgmtDataTable } from 'management/controls/org_mgmt_data_table';
import { useIntl, FormattedMessage } from 'react-intl';
import dateUtils from 'utils/date.js';
import { SizedImage } from 'controls/sized_image';
import BadgePlaceholderImage from 'images/badge-placeholder.png';
import { PaginationNavControls } from 'controls/pagination';
import { employeeBadgeDataActions } from 'pages/workforce/employees/employees_actions';
import { useViewableProfileTabParam } from './employee_profile_hooks';
import { useRaiseOnFailedRequest } from 'utils/react_utils';
import { useStandardSortHandler, standardInitialSort } from 'controls/data_table/use_standard_sort_handler';
import { ClampLines } from 'controls/clamp_lines';
import { Text } from 'victory';
import './employee_profile_earned_badges_table.sass';

const badgeTablePageData = (intl, currentPage, currentPageCount, pageLimit) => {
  const expectedEntityTotal = currentPage * pageLimit;
  const succeedingPagesSubtrahend = pageLimit - 1;
  const firstEntityIndex = expectedEntityTotal - succeedingPagesSubtrahend;
  const lastEntityIndex = firstEntityIndex + currentPageCount - 1;

  return currentPageCount === 1 ? `${firstEntityIndex}`
    : intl.formatMessage(
      {
        id: 'table.page_count',
        defaultMessage: '{from, number}-{to, number}'
      },
      { from: firstEntityIndex, to: lastEntityIndex }
    );
};

/**
 * Employee Profile Earned Badges Table component that shows earned badges on profile when viewing an employee under Workforce.
 *
 * @param {Object} props
 *   @param {string} props.organization_id - The organization
 *   @param {string} props.employee_id - Resource object representing employee profile api response
 *   @param {RouterLocation} props.location - React Router location object representing the
 *                                             application's current location/URL
 */
export const EmployeeProfileEarnedBadgesTable = (props) => {
  const intl = useIntl();
  const queryParams = useViewableProfileTabParam(props.location);

  // Stub out sort param for API request after component initialization / re-render
  queryParams.sort = queryParams?.sort ? queryParams.sort : 'name';

  const [employeeBadgeData] = employeeBadgeDataActions.useOnMount(
    'getBadgeData',
    '',
    { ...queryParams, organization_id: props.organization_id, id: props.employee_id }
  );
  useRaiseOnFailedRequest(
    employeeBadgeData.status,
    intl.formatMessage(
      {
        id: 'workforce.employees.show.profile.earned_badges_table.failed_request',
        defaultMessage: 'Organization ({organizationId}): Employee Profile > Employee Badge Data'
      },
      { organizationId: props.organization_id }
    )
  );

  const rowHref = data => data.badge_path;
  const rowClass = data => data.badge_path ? 'employee-profile-earned-badges-table__section-row' : undefined;

  const sortHandler = useStandardSortHandler();
  const currentSort = standardInitialSort(queryParams.sort);

  const badgeMetadata = employeeBadgeData.metadata;
  const metadataPresent = Object.keys(badgeMetadata).length > 0;
  let paginationHeaderData = '';
  if (metadataPresent) {
    paginationHeaderData = badgeTablePageData(
      intl,
      badgeMetadata.current_page,
      badgeMetadata.count,
      badgeMetadata.per
    );
  }

  return (
    <>
      {metadataPresent &&
        employeeBadgeData.status.succeeded &&
        employeeBadgeData.resources.length > 0 &&
        (
          <div className="col-12 employee-profile-earned-badges-pagination__header">
            <h3 className="employee-profile-earned-badges-table__section-heading">
              <FormattedMessage
                id="employee_profile.earned_badges_header"
                defaultMessage="Showing {currentPageData} of {totalBadges}"
                values={{
                  currentPageData: paginationHeaderData,
                  totalBadges: employeeBadgeData.metadata.total_count
                }}
              />
            </h3>
          </div>
        )
      }
      <div className="col-12 employee-profile-earned-badges-table__body-container">
        <OrgMgmtDataTable
          data={employeeBadgeData.resources}
          header={{}}
          loader={{
            loading: employeeBadgeData.status.pending
          }}
          ariaLabel={intl.formatMessage({
            id: 'workforce.employees.show.profile.earned_badges_table.aria_label',
            defaultMessage: 'Employee Badges'
          })}
          hideNoResults
          rowClassName={rowClass}
          rowHref={rowHref}
          sort={{ onSort: sortHandler, ...currentSort }}
        >
          <OrgMgmtDataTable.Col
            width="col-lg-6 col-md-10"
            header={intl.formatMessage({
              id: 'workforce.employees.show.profile.earned_badges_table.name',
              defaultMessage: 'Name'
            })}
            sortKey="name"
            renderer={data => (
              <>
                <div>
                  <SizedImage
                    src={data.badge_template_image.image_url || BadgePlaceholderImage}
                    alt={data.badge_template_name}
                    width={48}
                    className="employee-profile-earned-badges-table__template_image"
                  />
                </div>
                <div className="employee-profile-earned-badges-table__name-container">
                  <ClampLines
                    lines={2}
                    attributes={{ className: 'employee-profile-earned-badges-table__badge-name' }}
                    overflowNode={<Text>...</Text>}
                  >
                    {data.badge_template_name}
                  </ClampLines>
                  <div className="employee-profile-earned-badges-table__badge-issuer">
                    {data.badge_primary_issuer_name}
                  </div>
                </div>
              </>
            )}
          />
          <OrgMgmtDataTable.Col
            hideAt={['mobile', 'tablet']}
            width="col-lg-2"
            header={intl.formatMessage({
              id: 'workforce.employees.show.profile.earned_badges_table.type',
              defaultMessage: 'Type'
            })}
            renderer={data => (
              data.badge_template_type
            )}
          />
          <OrgMgmtDataTable.Col
            hideAt={['mobile', 'tablet']}
            width="col-lg-2"
            header={intl.formatMessage({
              id: 'workforce.employees.show.profile.earned_badges_table.level',
              defaultMessage: 'Level'
            })}
            renderer={(data) => (
              data.badge_template_level
            )}
          />
          <OrgMgmtDataTable.Col
            hideAt={['mobile']}
            sortKey="issued_at"
            sortIcon="arrow"
            width="col-lg-2 col-md-2"
            header={intl.formatMessage({
              id: 'workforce.employees.show.profile.earned_badges_table.date_issued',
              defaultMessage: 'Date Issued'
            })}
            renderer={data => (
              dateUtils.formatMonthShortDayYear(data.occurred_at)
            )}
          />
        </OrgMgmtDataTable>
        <PaginationNavControls
          data={employeeBadgeData.metadata}
        />
      </div>
    </>
  );
};

EmployeeProfileEarnedBadgesTable.propTypes = {
  organization_id: PropTypes.string.isRequired,
  employee_id: PropTypes.string.isRequired,
  location: RouterPropTypes.location.isRequired
};
