import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SimpleButton } from 'controls/button';
import { savedDirectorySearchActionManager } from './actions';
import { CreateSavedDirectorySearchDialog } from './create_saved_directory_search_dialog';

import './create_saved_directory_search_button.sass';

/**
 * Renders a button to open a dialog to create a saved directory search. Disables itself
 * if the user has 10 or more saved directory searches.
 * @param {Object} props
 *   @param {String} props.filters - Filter information from advanced search
 *   to save to the search
 *   @param {String} props.facets - Facet information from advanced search
 *   to save to the search
 */
export const CreateSavedDirectorySearchButton = props => {
  const [savedDirectorySearchList] = savedDirectorySearchActionManager.get(
    'list'
  ).useOnMount();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const hasReachedMaxLimit = () => (
    savedDirectorySearchList.resources && savedDirectorySearchList.resources.length >= 10
  );
  return (
    <div className="c-create-saved-directory-search-button">
      {hasReachedMaxLimit() &&
        <div className="c-create-saved-directory-search-button__max-limit-message">
          You have reached the maximum limit of 10 saved searches.
        </div>
      }
      <div className="c-create-saved-directory-search-button__button-container">
        <SimpleButton
          type="secondary"
          disabled={hasReachedMaxLimit()}
          loading={savedDirectorySearchList.status.pending}
          onClick={() => setShowCreateDialog(true)}
        >
          Save Search
        </SimpleButton>
      </div>
      <CreateSavedDirectorySearchDialog
        show={showCreateDialog}
        shouldClose={() => setShowCreateDialog(false)}
        filters={props.filters}
        facets={props.facets}
      />
    </div>
  );
};

CreateSavedDirectorySearchButton.propTypes = {
  filters: PropTypes.object.isRequired,
  facets: PropTypes.object.isRequired
};
