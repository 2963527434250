import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { MultiSelectList } from 'controls/multi_select_list';
import { EmployeeSelectFilterSection } from './employee_select_filter_section';
import { useIntl } from 'react-intl';

import './employee_filter_section.sass';

/**
 * General component that decides what specific FilterSection sub component to render
 *
 * @param {Object} props
 *   @param {String} props.displayName - String indicating the value for the header
 *   for the filter section
 *   @param {String[]} props.filterOptions - All of the possible filter options to select
 *   for the category e.g. ['accepted', 'pending', 'rejected'] for employee_state
 *   @param {String} props.filterOptionKey - The category for which current filter options
 *   are being displayed e.g. (employee_state, role, etc.)
 *   @param {String[]} props.selectedFilters - An array containing all currently selected
 *   filters. Should be a subset of props.filterOptions (obviously)
 *   @param {function(filterOptionKey: string, filterValue: string, sectionType: string)} props.onFilterClick -
 *   A callback function that will be called whenever a filter value has been clicked.
 *   e.g. onFilterClick('employee_state', 'accepted', 'list')
 *   @param {String} props.i18nPrefix - A string used to translate the values in the list from the
 *   list value into a translated value.  The prefix will be appended with
 *   `.%{props.filterOptionKey}.%{item}` to find the translation value.  If no translation is found
 *   the item will be used as the default (as if no prefix was defined.)
 */
export const EmployeeFilterSection = (props) => {
  const intl = useIntl();
  const filterOptionKey = props.filterOptionKey;
  const filterOptions = props.filterOptions;
  const selectedFilters = props.selectedFilters;
  const handleListSelection = useCallback((value) => {
    props.onFilterClick(filterOptionKey, 'list', value);
  }, [filterOptionKey, props.onFilterClick]);

  const itemLabel = (item) => {
    if (props.i18nPrefix) {
      return intl.formatMessage({
        id: `${props.i18nPrefix}.${filterOptionKey}.${item}`,
        defaultMessage: item
      });
    }

    return item;
  };

  const renderedFilterSection = () => {
    if (props.sectionType === 'list') {
      const asItems = filterOptions.map((item) => ({ label: itemLabel(item), value: item }));
      return (
        <MultiSelectList
          className="c-employee-filter-section__content"
          items={asItems}
          value={selectedFilters}
          onChange={handleListSelection}
          revertAllSelectedToEmpty
        />
      );
    } else if (props.sectionType === 'select') {
      return (
        <EmployeeSelectFilterSection
          displayName={props.displayName}
          filterOptionKey={filterOptionKey}
          filterOptions={props.filterOptions}
          selectedFilters={selectedFilters}
          onFilterClick={props.onFilterClick}
        />
      );
    } else {
      return null;
    }
  };
  return (
    <div className="c-employee-filter-section">
      <div className="c-employee-filter-section__header">{props.displayName}</div>
      {renderedFilterSection()}
    </div>
  );
};

EmployeeFilterSection.propTypes = {
  displayName: PropTypes.string,
  filterOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterOptionKey: PropTypes.string.isRequired,
  sectionType: PropTypes.string.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterClick: PropTypes.func.isRequired,
  i18nPrefix: PropTypes.string
};
