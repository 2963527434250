import React from 'react';
import { FormattedMessage } from 'react-intl';
import ConnectYourEmployees from 'svg/connect_your_employees.svg';

import './empty_dashboard.sass';

export const EmptyDashboard = () => {
  return (
    <div className="workforce__empty-dashboard__group">
      <div className="workforce__empty-dashboard__message">
        <div>
          <h1 className="workforce__empty-dashboard__message-header">
            <FormattedMessage
              id="workforce.dashboard.empty.header"
              defaultMessage="Connect your employees"
            />
          </h1>
          <div className="workforce__empty-dashboard__message-details">
            <FormattedMessage
              id="workforce.dashboard.empty.details"
              defaultMessage="Credly Workforce advances your organizations strategic upskilling and skill analytics. Thanks to Credly’s automated ontology and huge learning library, Workforce will make sure you don’t have to do the heavy lifting."
            />
          </div>
        </div>
      </div>
      <div className="workforce__empty-dashboard__image">
        <ConnectYourEmployees/>
      </div>
    </div>
  );
};

EmptyDashboard.propTypes = {
};
