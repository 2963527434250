import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons/faShieldCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouterPropTypes } from 'utils/prop_types';
import dateUtils from 'utils/date.js';
import { useRaiseOnFailedRequest } from 'utils/react_utils';
import { skillsInsightsUrl } from 'utils/routes';
import { BlockClickPropagate } from 'controls/block_click_propagate';
import { ClampLines } from 'controls/clamp_lines';
import {
  standardInitialSort, useStandardSortHandler
} from 'controls/data_table/use_standard_sort_handler';
import { PaginationNavControls } from 'controls/pagination';
import { OrgMgmtDataTable } from 'management/controls/org_mgmt_data_table';
import { InfoModal } from 'pages/workforce/components/info_modal';
import { employeeProfileSkillsActions } from 'pages/workforce/employees/employees_actions';
import {
  useFaethmOccupationMappingEnabled
} from 'pages/workforce/faethm_occupation_mapping_hooks';
import { useViewableProfileTabParam } from './employee_profile_hooks';

import './employee_profile_skills_table.sass';

const skillTablePageData = (intl, currentPage, currentPageCount, pageLimit) => {
  const expectedEntityTotal = currentPage * pageLimit;
  const succeedingPagesSubtrahend = pageLimit - 1;
  const firstEntityIndex = expectedEntityTotal - succeedingPagesSubtrahend;
  const lastEntityIndex = firstEntityIndex + currentPageCount - 1;

  return currentPageCount === 1 ? `${firstEntityIndex}`
    : intl.formatMessage(
      {
        id: 'table.page_count',
        defaultMessage: '{from, number}-{to, number}'
      },
      { from: firstEntityIndex, to: lastEntityIndex }
    );
};


/**
 * Employee Profile Skills Table component that shows skills on profile when viewing an employee under Workforce.
 *
 * @param {Object} props
 *   @param {string} props.organization_id - The organization
 *   @param {string} props.employee_id - Resource object representing employee profile api response
 *   @param {RouterLocation} props.location - React Router location object representing the
 *                                             application's current location/URL
 */
export const EmployeeProfileSkillsTable = (props) => {
  const intl = useIntl();
  const occupationMappingEnabled = useFaethmOccupationMappingEnabled();
  const queryParams = useViewableProfileTabParam(props.location);
  // Stub out sort param for API request after component initialization / re-render
  queryParams.sort = queryParams?.sort ? queryParams.sort : 'name';

  const [employeeSkillData] = employeeProfileSkillsActions.useOnMount(
    'getSkillData',
    '',
    { ...queryParams, organization_id: props.organization_id, id: props.employee_id }
  );
  useRaiseOnFailedRequest(
    employeeSkillData.status,
    intl.formatMessage(
      {
        id: 'workforce.employees.show.profile.skills_table.failed_request',
        defaultMessage: 'Organization ({organizationId}): Employee Profile > Employee Skill Data'
      },
      { organizationId: props.organization_id }
    )
  );
  const rowHref = (skill) => {
    return skillsInsightsUrl(skill.id);
  };

  const skillMetadata = employeeSkillData.metadata;
  const metadataPresent = Object.keys(skillMetadata).length > 0;
  let paginationHeaderData = '';
  if (metadataPresent) {
    paginationHeaderData = skillTablePageData(
      intl,
      skillMetadata.current_page,
      skillMetadata.count,
      skillMetadata.per
    );
  }
  const sortHandler = useStandardSortHandler();
  const currentSort = standardInitialSort(queryParams.sort);

  const skillAreaHeader = intl.formatMessage({
    id: 'workforce.skills_table.skill_area_header',
    defaultMessage: 'Skill Area'
  });
  const occupationSkillsHeader = intl.formatMessage({
    id: 'workforce.skills_table.occupation_skills_header',
    defaultMessage: 'Occupation Skills'
  });
  const badgeSkillsHeader = intl.formatMessage({
    id: 'workforce.skills_table.verified_header',
    defaultMessage: 'Verified'
  });

  return (
    <>
      {metadataPresent &&
        employeeSkillData.status.succeeded &&
        employeeSkillData.resources.length > 0 &&
        (
          <div className="col-12 employee-profile-skills-pagination__header">
            <h3 className="employee-profile-skills-table__section-heading">
              <FormattedMessage
                id="employee_profile.skills_header"
                defaultMessage="Showing {currentPageData} of {totalSkills, number}"
                values={{
                  currentPageData: paginationHeaderData,
                  totalSkills: employeeSkillData.metadata.total_count
                }}
              />
            </h3>
          </div>
        )
      }
      <div className="col-12 employee-profile-skills-table__name-container">
        <OrgMgmtDataTable
          data={employeeSkillData.resources}
          header={{}}
          loader={{
            loading: employeeSkillData.status.pending
          }}
          ariaLabel={intl.formatMessage({
            id: "workforce.employee_profile.skills",
            defaultMessage: "Employee Skills"
          })}
          sort={{ onSort: sortHandler, ...currentSort }}
          rowHref={rowHref}
          rowClassName="employee-profile-earned-badges-table__section-row"
          hideNoResults
        >
          <OrgMgmtDataTable.Col
            className="employee-profile-skills-table__name-col"
            width={occupationMappingEnabled ? 'col-lg-3 col-md-3 col-6' : 'col-6 col-md-6'}
            header={intl.formatMessage({
              id: 'workforce.skills_table.skill_header',
              defaultMessage: 'Skill'
            })}
            sortKey="name"
            renderer={(data) => (
              data.name
            )}
          />
          <OrgMgmtDataTable.Col
            className="employee-profile-skills-table__skill-area-name"
            width={occupationMappingEnabled ? 'col-lg-2 col-md-3 col-6' : 'col-6 col-md-4'}
            header={
              <>
                {skillAreaHeader}
                <BlockClickPropagate>
                  <InfoModal
                    className="employee-profile-skills-table__tooltip-wrapper"
                    title={skillAreaHeader}
                    body={intl.formatMessage({
                      id: 'workforce.skills_table.skill_area.tooltip_body',
                      defaultMessage: 'Skill area is a collection of skills that are categorized ' +
                        'based on their similarities and relationships according to ESCO skills, ' +
                        'O*NET job attributes and CRICOS education frameworks. Skill area offers ' +
                        'a group of more actionable skills.'
                    })}
                  />
                </BlockClickPropagate>
              </>
            }
            sortKey="skill_area_name"
            renderer={(data) => (
              <ClampLines lines={2}>
                {data.skill_area_name}
              </ClampLines>
            )}
          />
          <OrgMgmtDataTable.Col
            hideAt={["mobile", "tablet"]}
            className="employee-profile-skills-table__skill-issued"
            width={occupationMappingEnabled ? 'col-lg-2 col-sm-1' : 'col-sm-2'}
            header={intl.formatMessage({
              id: 'workforce.skills_table.issued_header',
              defaultMessage: 'Issued'
            })}
            sortKey="created_at"
            renderer={data => (
              !data.source.includes('inferred') && (
                dateUtils.formatDateMonthShortYear(data.created_at)
              )
            )}
          />
          {
            occupationMappingEnabled && (
              <>
                <OrgMgmtDataTable.Col
                  hideAt={["mobile"]}
                  width="col-3"
                  header={
                    <>
                      {occupationSkillsHeader}
                      <BlockClickPropagate>
                        <InfoModal
                          className="employee-profile-skills-table__tooltip-wrapper"
                          title={occupationSkillsHeader}
                          body={intl.formatMessage({
                            id: 'workforce.skills_table.occupation_skills.tooltip_body',
                            defaultMessage: 'The number of employees who are assumed to have ' +
                              'this skill, based on the skills associated with the professional ' +
                              'performance of a specific occupation.'
                          })}
                        />
                      </BlockClickPropagate>
                    </>
                  }
                  renderer={(data) => (
                    data.source.includes('inferred') && (
                      <div className="employee-profile-skills-table__check-container">
                        <FontAwesomeIcon
                          className="employee-profile-skills-table__check-icon"
                          icon={faCheck}
                        />
                      </div>
                    )
                  )}
                />
                <OrgMgmtDataTable.Col
                  hideAt={["mobile"]}
                  width="col-2"
                  header={
                    <>
                      {badgeSkillsHeader}
                      <BlockClickPropagate>
                        <InfoModal
                          className="employee-profile-skills-table__tooltip-wrapper"
                          title={badgeSkillsHeader}
                          body={intl.formatMessage({
                            id: 'workforce.skills_table.badge_skills.tooltip_body',
                            defaultMessage: 'The number of employees who acquired this skill ' +
                              'through a badge.'
                          })}
                        />
                      </BlockClickPropagate>
                    </>
                  }
                  renderer={(data) => (
                    data.source.includes('credentialed') && (
                      <div className="employee-profile-skills-table__shield-container">
                        <FontAwesomeIcon
                          className="employee-profile-skills-table__check-icon"
                          icon={faShieldCheck}
                        />
                      </div>
                    )
                  )}
                />
              </>
            )
          }
        </OrgMgmtDataTable>
        <PaginationNavControls
          data={employeeSkillData.metadata}
        />
      </div>
    </>
  );
};

EmployeeProfileSkillsTable.propTypes = {
  organization_id: PropTypes.string.isRequired,
  employee_id: PropTypes.string.isRequired,
  location: RouterPropTypes.location.isRequired
};
