import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * This is an accessibility component that implements the typical semantics of an element with
 * ARIA role="button".
 *
 * @property {string} tagName - the HTML tag to use for the element
 * @property {function(React.SyntheticEvent)} onClick - handler for clicks or keypresses (of
 * Space or Enter) that the element receives; this function should call preventDefault() if that
 * is appropriate for the element type.
 * @property {*} children - content of the button element (e.g. text, icon, etc.)
 * @property {string} [className] - class name to assign to the rendered element
 * @property {string} [aria-label] - optional ARIA label; defaults to title property, if that
 * attribute is present but this one is not; this property is usually only necessary if the content
 * of the button is not useful to screen readers (e.g. if the control is an icon)
 * @property {string} [title] - optional title; similar to aria-label, this property is usually
 * only useful if the purpose of the button is not explicit in its content, as in the case of
 * of icons
 * @property {object} [tagProps] - Optional object to set props on tagName element
 * @property {boolean} [autoFocus] - Automatically focus the element.
 */
class RoleButtonWithInnerRef extends PureComponent {
  constructor(props) {
    super(props);
    this.m_buttonRef = React.createRef();
  }

  buttonRef() {
    return this.props.innerRef || this.m_buttonRef;
  }

  componentDidUpdate() {
    if (this.props.autoFocus) {
      setTimeout(() => this.buttonRef().current && this.buttonRef().current.focus(), 100);
    }
  }

  handleKeyPress = (evt) => {
    if (evt.key === 'Enter' || evt.key === ' ') {
      // Block browser events, like MacOS/Chrome using space to page down.
      evt.preventDefault();
      this.props.onClick(evt);
    }
  };

  render() {
    const RoleButtonTagName = this.props.tagName;
    const tagProps = this.props.tagProps || {};
    if (this.props.hasOwnProperty('title')) {
      tagProps.title = tagProps['aria-label'] = this.props['title'];
    }
    if (this.props.hasOwnProperty('aria-label')) {
      tagProps['aria-label'] = this.props['aria-label'];
    }

    return (
      <RoleButtonTagName
        className={this.props.className}
        onClick={this.props.onClick}
        aria-controls={this.props['aria-controls']}
        aria-expanded={this.props['aria-expanded']}
        onKeyPress={this.handleKeyPress}
        tabIndex="0"
        role="button"
        ref={this.buttonRef()}
        {...tagProps}
      >
        {this.props.children}
      </RoleButtonTagName>
    );
  }
}

const propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  tagProps: PropTypes.object,
  tagName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  "aria-label": PropTypes.string,
  "aria-controls": PropTypes.string,
  "aria-expanded": PropTypes.bool,
  title: PropTypes.string
};
RoleButtonWithInnerRef.propTypes = { innerRef: PropTypes.any, ...propTypes };

const RoleButton = React.forwardRef((props, ref) => (
  <RoleButtonWithInnerRef {...props} innerRef={ref} />
));

RoleButton.propTypes = propTypes;

RoleButton.displayName = 'RoleButton';

export { RoleButton, propTypes };

export const testing = { RoleButtonWithInnerRef };
