export const initialState = {
  parentDataStack: [],
  currentData: [],
  path: [],
  levelTwoView: false,
  totalValue: 0
};

export function reducer(state, action) {
  switch (action.type) {
    case 'SET_LEVEL_TWO_VIEW':
      return { ...state, levelTwoView: action.payload };
    case 'SET_PARENT_DATA_STACK':
      return { ...state, parentDataStack: action.payload };
    case 'SET_CURRENT_DATA':
      return {
        ...state,
        currentData: action.payload,
        totalValue: action.payload.reduce((total, data) => total + data?.value, 0)
      };
    case 'SET_PATH':
      return { ...state, path: action.payload };
    default:
      return state;
  }
}

/*
Temporary function to flatten level two data in order to get the default level two view
Ideally this will come from the server structured correctly
*/
export function restructureData(data) {
  const result = [];
  for (const item of data) {
    if (item.children) {
      result.push(...item.children);
    }
  }
  return result;
};


/*
Temporary function, this should come from the server response
*/
const calculatePercentage = (value, total) => ((value / total) * 100).toFixed(0);

export const toolTipFormatter = (parentDataStackLength) => function() {
  const level = Number(parentDataStackLength) + 1;
  const getLowestChildren = (node, result = { count: 0, value: 0 }) => {
    if (!node.children) {
      result.count++;
      result.value += node.value;
    } else {
      node.children.forEach(child => getLowestChildren(child, result));
    }
    return result;
  };

  const { count: skillsCount, value: employees } = getLowestChildren(this.point);

  return `
    <div class="skills_distribution_tooltip">
      <div class="tooltip__title">${this.point.name}</div>
      <div class="tooltip__level">Level ${level}</div>
      <div class="tooltip__info tooltip__info--count">
        <div class="tooltip__info-label">Skills Count</div>
        <div class="tooltip__info-value">${skillsCount}</div>
      </div>
      <div class="tooltip__info tooltip__info--employees">
        <div class="tooltip__info-label">Employees</div>
        <div class="tooltip__info-value">${employees}</div>
      </div>
    </div>`;
};

export const pointChartFormatter = (totalValue) => function() {
  const boxWidth = this.point.shapeArgs.width;
  const charWidth = 8;
  const maxLength = Math.floor(boxWidth / charWidth);

  let formattedName = this.point.name;
  if (formattedName.length > maxLength) {
    formattedName = `${formattedName.substring(0, maxLength - 3)}...`;
  }

  return `
    <div class="skills_distribution_chart__point">
      <div class="chart__point-title">${formattedName}</div>
      <div class="chart__point-value">${calculatePercentage(this.point.value, totalValue)}%</div>
    </div>`;
};
