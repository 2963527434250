import React from 'react';
import PropTypes from 'prop-types';
import { DirectoryBase } from 'pages/public/organization/earner_directory/shared/directory_base';

/**
 * The Earner Directory limits results to earners of badges issued by an organization.
 *
 * @param {Object} props
 *   @param {String} props.id - The id of the DirectoryFilter.
 *   @param {Object} props.excludeSearchTypes - Set of search types to hide.
 *   @param {String} [props.className] - optional extra className to assign to root element
 */
export const EarnerDirectory = props => {
  return (
    <DirectoryBase
      allowNotSearches
      excludeSearchTypes={props.excludeSearchTypes}
      baseParams={{ organization_id: props.id }}
      trackingData={{ object_id: props.id, object_type: 'Organization' }}
      className={props.className}
    />
  );
};
EarnerDirectory.propTypes = {
  className: PropTypes.string,
  excludeSearchTypes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired
};
