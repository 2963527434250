import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { LoadingSpinner } from 'controls/loading_spinner';
import { SectionHeader, SectionHeaderContextProvider } from 'controls/section_header';
import { DefaultSiteHeader, useCustomHeader } from 'header';
import { useCustomFooterClassName } from 'utils/custom_footer';
import { useWidthBreakpoints } from 'utils/use_width_breakpoints';
import { WorkforceSidebar } from 'pages/workforce/workforce_layout/workforce_sidebar';
import { WorkforceOrganizationHeader } from 'pages/workforce/workforce_layout/workforce_organization_header';
import {
  CheckSessionAlive,
  OrganizationPermissionsProvider,
  OrganizationFeaturesProvider,
  OrganizationNotFoundError,
  organizationActions,
  orgMgmtSwitchContextAction
} from 'pages/workforce/organizations';
import { FaethmOccupationMappingProvider } from 'pages/workforce/faethm_occupation_mapping_provider';
import { Routes } from 'pages/workforce/routes';
import { isSmallScreen } from './helpers';

import './workforce_layout.sass';

export const WorkforceLayout = (props) => {
  const [organizationState] =
    organizationActions.useOnMount('getOrganization', props.organizationId);

  const dispatch = useDispatch();
  useEffect(() => {
    return () => { orgMgmtSwitchContextAction(dispatch)(); };
  }, [props.organizationId]);

  useCustomHeader(SuppressHeader);
  useCustomFooterClassName('cr-workforce-layout__footer-overrides');
  const view = useWidthBreakpoints();

  if (organizationState.status.pending || organizationState.status.idle) {
    return <LoadingSpinner position="window-center" delay={300}/>;
  } else if (organizationState.status.failed) {
    return <OrganizationNotFoundError/>;
  }

  const divClassName = makeClassName('cr-workforce-layout__content',
    isSmallScreen(view) ? '' : 'row');

  return (
    <SectionHeaderContextProvider>
      <OrganizationPermissionsProvider organizationMetadata={organizationState.metadata}>
        <OrganizationFeaturesProvider organizationMetadata={organizationState.metadata}>
          <FaethmOccupationMappingProvider organizationResources={organizationState.resources}>
            <div className="cr-workforce-layout">
              <CheckSessionAlive/>
              <div className="cr-workforce-layout__sidebar">
                <WorkforceSidebar organization={organizationState.resources} baseUrl={props.baseUrl}/>
              </div>
              <div className="cr-workforce-layout__content-outer">
                <div className="cr-workforce-layout__content-container">
                  {
                    !isSmallScreen(view) && (
                      <div className="cr-workforce-layout__header row">
                        <div className="col">
                          <WorkforceOrganizationHeader organization={organizationState.resources}/>
                        </div>
                      </div>
                    )
                  }
                  <div className={divClassName}>
                    <div className="col-12">
                      <SectionHeader />
                    </div>
                    <Routes baseUrl={props.baseUrl} organization={organizationState.resources}/>
                  </div>
                </div>
              </div>
            </div>
          </FaethmOccupationMappingProvider>
        </OrganizationFeaturesProvider>
      </OrganizationPermissionsProvider>
    </SectionHeaderContextProvider>
  );
};

WorkforceLayout.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired
};

/**
 * Custom "Header" component used by WorkforceLayout to suppress header in large screens and instead embed the "main
 * nav" menu in the layout header.
 *
 * @param {object} _props - unused
 * @returns {JSX.Element}
 * @constructor
 */
const SuppressHeader = (_props) => {
  const className = 'cr-workforce-layout__header-override';
  useEffect(() => {
    document.getElementById('website-header').classList.add(className);
    return () => document.getElementById('website-header').classList.remove(className);
  }, []);
  const view = useWidthBreakpoints();

  return isSmallScreen(view) ? <DefaultSiteHeader/> : <></>;
};
