import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { pluralize } from 'utils/localization';
import { QueryString } from 'utils/query_string';
import dateUtils from 'utils/date';
import { array } from 'utils/array';
import { trySetLinkSource } from 'app_utils/tracking';
import { ResourceStatusPropType } from 'utils/prop_types';
import { Tag, TruncatedTagList } from 'controls/tags';
import { SizedImage } from 'controls/sized_image';
import { DataCards } from 'controls/data_table';
import { NoEarnerResults } from './no_earner_results';
import { EarnerPropType, makeFilteredUrl } from './utils';
import './earner_cards.sass';


/**
 * Render a cards view of earners.
 */
export const EarnerCards = props =>
  <DataCards
    data={props.data}
    loader={{ loading: props.status.pending }}
    cardClassName={props.cardClassName}
    emptyResultNode={() => <NoEarnerResults status={props.status}/>}
    renderer={data => <EarnerCard earner={data} filters={props.filters}/>}
  />;

EarnerCards.propTypes = {
  data: PropTypes.arrayOf(EarnerPropType).isRequired,
  status: ResourceStatusPropType.isRequired,
  filters: PropTypes.instanceOf(QueryString).isRequired,
  cardClassName: PropTypes.string
};

/**
 * Private component to render data for a single user inside a DataCards layout.
 *
 * Displays an earner and their skills for a relevant badge
 */
const EarnerCard = props => {
  const history = useHistory();

  // Prevent openProfile (clicking anywhere on the card) from working when we're clicking on
  // another link. This is necessary because `preventDefault()` doesn't prevent multiple levels of
  // React routing.
  const handlingLink = React.useRef(false);
  const preventDuplicateLinkClick = React.useCallback(() => handlingLink.current = true, []);
  React.useEffect(() => () => handlingLink.current = false);


  /**
   * Route to the user profile, if we're not already handling a link.
   */
  const openProfile = React.useCallback(() => {
    if (!handlingLink.current) {
      trySetLinkSource('earner_directory');
      history.push(makeFilteredUrl(props.filters, props.earner.url));
    }
  }, [props.filters, props.earner.url]);

  /**
   * Clicking on the username opens the user profile.
   */
  const openProfileFromUsername = React.useCallback(() => {
    handlingLink.current = true;
    trySetLinkSource('earner_directory');
  }, []);

  /**
   * Render the skills section.
   *
   * @returns {React.element}
   */
  const renderSkills = () => {
    const skillNames = props.filters.arrayValueSet('skill_name');
    const skills = array.promotedSort(props.earner.skills, skillNames, 'name');

    return <div className="earner-card__col-skills">
      <TruncatedTagList className="earner-card__col-list" maxRows={2} ui="smallbold" noEllipsesExpand>
        {skills.map(skill =>
          <Tag key={skill.name} highlighted={!!skillNames[skill.name]} ui="smallbold">{skill.name}</Tag>
        )}
      </TruncatedTagList>
    </div>;
  };

  /**
   * Render the badges section.
   *
   * @returns {React.element}
   */
  const renderBadges = () => {
    const earner = props.earner;

    if (earner.highlighted_badges.length > 0) {
      const filters = props.filters;
      const badgeNames = filters.arrayValueSet('badge_name');

      const badges = earner.highlighted_badges.map(badge =>
        <div className="earner-card__badge-info" key={badge.id}>
          {badge.name in badgeNames ? 'Earned ' : 'Last earned '}
          <Link
            className="earner-card__badge-link"
            to={badge.url}
            onClick={preventDuplicateLinkClick}
          >
            {badge.name}
          </Link>{' '}
          <span className="earner-card__date">
            on {dateUtils.formatDateMonthShortYear(badge.date)}
          </span>
        </div>
      );

      const firstBadge = earner.highlighted_badges[0];

      return <div className="earner-card__orginfo">
        <SizedImage
          className="earner-card__badge-image"
          alt={firstBadge.name}
          src={firstBadge.image}
          width={64}
        />
        <div className="earner-card__badge-info-container">
          <div className="earner-card__badge-info">
            <span className="earner-card__badge-count">
              {
                earner.badge_count + ' ' +
                pluralize(earner.badge_count, 'badge', 'badges', {textOnly: true})
              }
            </span>
            <span> {
              earner.issuer_count === 1
                ? `issued by ${firstBadge.issuer_name}`
                : `from ${pluralize(earner.issuer_count, 'issuer')}`
            }</span>
          </div>

          {badges}
        </div>
      </div>;
    }
  };

  /**
   * Render the component.
   *
   * Accessibility note: The card itself is clickable, but that click is deliberately not
   * accessible (no role=button or tabIndex). Accessible links can be found within the card,
   * in the form of the username (routes to profile) and badge name (routes to badge).
   *
   * @returns {React.element}
   */
  const render = () => {
    const earner = props.earner;

    return (
      <div className="earner-card__container" onClick={openProfile}>
        <div className="earner_card__earner-heading">
          <Link
            to={makeFilteredUrl(props.filters, props.earner.url)}
            onClick={openProfileFromUsername}
          >
            {array.joinCompact([earner.first_name, earner.middle_name, earner.last_name], ' ')}
          </Link>
        </div>

        <div className="earner-card__subhead">
          <div className="earner-card__role">
            {earner.role}
          </div>
          <div className="earner-card__location">
            {earner.location}
          </div>
        </div>

        {renderSkills()}
        {renderBadges()}
      </div>
    );
  };

  return render();
};

EarnerCard.propTypes = {
  earner: EarnerPropType.isRequired,
  filters: PropTypes.instanceOf(QueryString).isRequired
};

export const testing = {EarnerCard};
