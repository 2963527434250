import React, { useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading, NewHeadingLevel } from 'controls/heading';
import { employeesLearningActivityActionManager } from './employers_badges_activity_actions';
import { OrgMgmtDataTable } from 'management/controls/org_mgmt_data_table';
import { organizationVanityUrl } from 'utils/routes';
import { EmployeePhoto } from '../employees/employees/employee_photo';
import { Text } from 'controls/text';
import { useEnabledFeatures } from 'utils/enabled_features';
import { CelebrateButtonWithCheck } from "celebrations/celebrate_button_with_check";
import './employers_badges_activity.sass';

const CELEBRATE_LEARNING = 'celebrate_learning';

/**
 *
 * Component that shows a list of recent learning activity.
 *
 * Shown in paths:
 * - /workforce
 *
 * @param {Object} props
 *   @param {uuid} props.organization_id - The organization ID.
 * @returns {React.element}
 */
export const EmployeesBadgesActivity = (props) => {
  const [learningActivity] = employeesLearningActivityActionManager.useOnMount(
    'get', props.organizationId, {
      organization_id: props.organizationId
    }
  );
  const badges = learningActivity.resources?.badges;

  // Don't render this at all if we received no activity back.
  if (
    !learningActivity.status.pending &&
    !badges?.length
  ) {
    return null;
  }

  return (
    <div className="employers_badges_activity__container">
      <Heading appearance="org-mgmt-subhead">
        <FormattedMessage
          id="workforce.employees.learning.activity.header.title"
          defaultMessage="Employee learning activity"
        />
      </Heading>
      <OrgMgmtDataTable
        data={badges}
        loader={{ loading: learningActivity.status.pending }}
        hideColumnHeaders
        hoverStyle="none"
      >
        <OrgMgmtDataTable.Col
          renderer={(item) =>
            <EmployeeLearningActivityItem
              activityData={item}
              organizationId={props.organizationId}
            />
          }
        />
      </OrgMgmtDataTable>
    </div>
  );
};

EmployeesBadgesActivity.propTypes = {
  organizationId: PropTypes.string
};

/**
 *
 * The component item for showing a Learning Activity Item / Badge activity.
 *
 * @param {Object} props
 *   @param {Object} props.activityData - The activity item data to populate the component.
 * @returns {React.element}
 */
export const EmployeeLearningActivityItem = (props) => {
  const data = props.activityData;
  const history = useHistory();

  // To avoid nesting anchor tags, we add an onClick event to the wrapping <div>, with exceptions for
  //  the org profile link and the button
  const navigateToBadgePage = useCallback((event) => {
    const clickedElement = event.target;
    const isCelebrateButtonChild = clickedElement.closest('.celebrate-button__container');

    if (
      data.badge_path &&
      !event.target.dataset.preventNavigation &&
      !isCelebrateButtonChild
    ) {
      history.push(data.badge_path);
    }
  }, [data.badge_path]);

  const isFeatureEnabled = useEnabledFeatures();

  return (
    <div
      className="employers_badges_activity-item__container"
      onClick={navigateToBadgePage}
    >
      <div className="employers_badges_activity-image__container">
        <img
          src={data.badge_image_url}
          className="employers_badges_activity__image"
          alt={useIntl().formatMessage({
            id: 'workforce.employees.learning.activity.header.image.alt',
            defaultMessage: 'Badge image'
          })}
        />
      </div>
      <div className="employers_badges_activity-info__container">
        <div className="employers_badges_activity-info__employer_earned">
          <EmployeePhoto
            className="employers_badges_activity-info-employer_earned__picture"
            photo_url={data.user_image_url}
            width={24}
          />
          <Text className="employers_badges_activity-info-employer_earned__description">
            <FormattedMessage
              id="workforce.employees.learning.activity.description.employee.earned"
              defaultMessage="{employeeName} earned:"
              values={{
                employeeName: data.user_name
              }}
            />
          </Text>
        </div>
        <Heading appearance="settings-head">
          {data.badge_title}
        </Heading>
        <NewHeadingLevel>
          <Heading appearance="plain-text">
            <FormattedMessage
              id="workforce.employees.learning.activity.description.issued.by"
              defaultMessage="Issued by "
            />
            <Link
              to={organizationVanityUrl(data.issuer_vanity_slug)}
              className="cr-org-mgmt-organizations-sidebar-current-organization__see-profile"
              data-prevent-navigation
            >
              {data.issuer_name}
            </Link>
          </Heading>

        </NewHeadingLevel>
      </div>
      {isFeatureEnabled(CELEBRATE_LEARNING) &&
        <div className="employers_badges_activity-button__container">
          <CelebrateButtonWithCheck
            badge={data}
            onlyIcon
            organizationId={props.organizationId}
          />
        </div>}
    </div>
  );
};

EmployeeLearningActivityItem.propTypes = {
  organizationId: PropTypes.string,
  activityData: PropTypes.shape({
    id: PropTypes.string,
    badge_image_url: PropTypes.string,
    badge_path: PropTypes.string,
    has_congratulated: PropTypes.bool,
    user_image_url: PropTypes.string,
    user_name: PropTypes.string,
    badge_title: PropTypes.string,
    issuer_name: PropTypes.string,
    issuer_vanity_slug: PropTypes.string,
    status: PropTypes.string
  }).isRequired
};
