import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'form';
import { useIntl } from 'react-intl';

/**
 * Subcomponent that renders filter values in a select form.
 *
 * @param {Object} props
 *   @param {String} props.displayName - String indicating the value for the header
 *   for the select input
 *   @param {String[]} props.filterOptions - All of the possible filter options to select
 *   for the category e.g. ['accepted', 'pending', 'rejected'] for employee_state
 *   @param {String} props.filterOptionKey - The category for which current filter options
 *   are being displayed e.g. (employee_state, role, etc.)
 *   @param {String[]} props.selectedFilters - An array containing all currently selected
 *   filters. In reality this will/should only ever have one filter value due to the nature
 *   of the select input visual.
 *   @param {function(filterOptionKey: string, filterValue: string, sectionType: string)} props.onFilterClick -
 *   A callback function that will be called whenever a filter value has been clicked.
 *   e.g. onFilterClick('employee_state', 'accepted', 'select')
 */
export const EmployeeSelectFilterSection = (props) => {
  const intl = useIntl();
  const all = {
    selectionValue: '',
    displayValue: intl.formatMessage({
      id: 'workforce.employees.list.filters.all',
      defaultMessage: 'All'
    })
  };
  const selectOptions = [all].concat(props.filterOptions.map(
    (filterOption) => ({
      selectionValue: filterOption,
      displayValue: filterOption
    })
  ));
  const currentValue =
    props.selectedFilters.length > 0 ? props.selectedFilters[0] : all.selectionValue;
  const triggerFilterChange = (name, selectionValue) => {
    props.onFilterClick(props.filterOptionKey, 'select', selectionValue);
  };
  return (
    <Form.Select
      name={props.filterOptionKey}
      label={props.displayName}
      aria-label={props.displayName}
      value={currentValue}
      options={selectOptions}
      handleChange={triggerFilterChange}
    />
  );
};

EmployeeSelectFilterSection.propTypes = {
  displayName: PropTypes.string,
  filterOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterOptionKey: PropTypes.string.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterClick: PropTypes.func.isRequired
};
