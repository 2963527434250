import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { WithSectionHeader } from 'controls/section_header';
import { LearningPlanActions, LearningPlanEmploymentActions } from 'pages/workforce/learning/actions';
import { BadgeLinkSectionHeader } from 'pages/workforce/learning/shared/badge_link_section_header';
import { LearningPlanForm } from 'pages/workforce/learning/shared/learning_plan_form';

const headingTranslationData = {
  id: 'workforce.learning.edit.section_header',
  defaultMessage: 'Add employees to a recommendation'
};

/**
 * The primary entry point for adding Direct Reports to a Learning Plan.
 * This component fetches the Learning Plan and provides a form for
 * selecting one or more employees to add to the plan.
 * It manages the state for the Learning Plan and passes down relevant props
 * to LearningPlanForm.
 *
 * @param {string} learningPlanId - ID of an existing Learning Plan
 * @param {string} templateId - the template ID for the learning plan
 * @returns {JSX.Element}
 */

export const EditLearningPlan = ({ learningPlanId, organizationId, baseUrl }) => {
  const learningPlanFormAction = LearningPlanEmploymentActions.get('create', learningPlanId);
  const [learningPlan, setLearningPlan] = useState(null);

  const [showLearningPlanState, showLearningPlan] = LearningPlanActions.useAction('show', learningPlanId);
  const showLearningPlanIdle = !!showLearningPlanState.status.idle;
  const showLearningPlanSucceeded = !!showLearningPlanState.status.succeeded;

  useEffect(() => {
    if (showLearningPlanIdle && !learningPlan) {
      showLearningPlan({ organization_id: organizationId });
    } else if (showLearningPlanSucceeded && !learningPlan) {
      setLearningPlan({
        ...showLearningPlanState.resources,
        organization_id: organizationId,
        direct_report_ids: []
      });
    }
  }, [showLearningPlanIdle, showLearningPlanSucceeded, showLearningPlanState.resources]);

  const sectionHeader = useCallback(() => (
    // eslint-disable-next-line camelcase
    learningPlan?.badge_template &&
      <BadgeLinkSectionHeader
        badgeTemplate={learningPlan.badge_template}
        translationData={headingTranslationData}
      />
  ), [organizationId, learningPlan]);

  return (
    <>
      {learningPlan &&
        <WithSectionHeader sectionHeader={sectionHeader}>
          <div className="workforce-new-learning-plan__container container-fluid">
            <LearningPlanForm
              learningPlanState={learningPlan}
              learningPlanAction={learningPlanFormAction}
              updateLearningPlanState={setLearningPlan}
              baseUrl={baseUrl}
            />
          </div>
        </WithSectionHeader>
      }
    </>
  );
};

EditLearningPlan.propTypes = {
  learningPlanId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired
};
