import React, { Component, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * @typedef StackHandle
 * @property {Number} id - unique identifier for the handle within the stack context
 * @property {*} element - the data for the stack element
 */
let StackHandleDocOnly;


/**
 * Create a set of component functions/classes for working with stacks tied to a React tree, such
 * that the last/top item of the stack is the one provided by the WithStackElement component
 * instance that is deepest in the tree. Returns React context that will be used to maintain and
 * observe the stack (StackContext), a component that wraps the React context provider to use the
 * necessary logic to maintain the stack (ContextProvider), and a component that can be used to mark
 * where elements should be pushed and popped on the stack (WithStackElement).
 *
 * @param {String} contextName - the base name for the stack context that will be created; this
 *   value is used to create friendly display names for the components
 * @returns {{WithStackElement: *, StackContext: *, ContextProvider: *}}
 */
export const makeStackContext = (contextName) => {
  const StackContextIndex = React.createContext(0);

  const StackContext = React.createContext({
    stack: [],
    mounted: (_handle) => null,
    unmounted: (_handle) => null
  });

  /**
   * Sets up a StackContext provider with the appropriate functions to maintain the stack when
   * elements are mounted, updated, and unmounted.
   *
   * @property {React.node} children - the content to render within a stack context provider
   */
  const ContextProvider = class extends Component {
    constructor(props) {
      super(props);
      this.state = { stack: [] };
      this.isUnmounted = false;
    }

    /**
     * Component is about to unmount.  Make note of that in an instance variable, so we can avoid
     * state updates after the component is unmounted.
     */
    componentWillUnmount() {
      this.isUnmounted = true;
    }

    /**
     * Callback to be used by the component that reserved a slot in the stack when that component
     * mounts, to signal that the element should be pushed onto the stack.
     *
     * @param {StackHandle} reservation - the reservation for the stack element that should now be
     *   pushed
     */
    mounted = (reservation) => {
      if (!this.isUnmounted) {
        this.setState(state => {
          let idx = state.stack.findIndex(entry => entry.id > reservation.id);
          if (idx < 0) {
            // not found, so add it to the end
            idx = state.stack.length;
          }
          const newStack = [
            ...state.stack.slice(0, idx), reservation, ...state.stack.slice(idx)
          ];
          return { stack: newStack };
        });
      }
    };

    /**
     * Callback to be used by the component that pushed an element onto the stack when the component
     * unmounts, so that the associated element can be popped.
     *
     * @param {StackHandle} handle - the reservation of the stack element that should be removed
     */
    unmounted = (handle) => {
      if (!this.isUnmounted) {
        this.setState(state => {
          const newStack = state.stack.filter(entry => entry.id !== handle.id);
          return { stack: newStack };
        });
      }
    };

    /**
     * Render the component.
     * @returns {React.element}
     */
    render() {
      const contextValue = {
        ...this.state,
        mounted: this.mounted,
        unmounted: this.unmounted
      };
      return (
        <StackContext.Provider value={contextValue}>
          {this.props.children}
        </StackContext.Provider>
      );
    }
  };

  ContextProvider.propTypes = {
    children: PropTypes.node
  };

  ContextProvider.displayName = `${contextName}ContextProvider`;

  /**
   * Component that can be used to wrap content that pushes a new element onto the stack.
   *
   * @param {object} props
   *   @param {Array<React.element>} props.children - content
   *   @param {*} props.element - the element to push onto the stack. Can be any type that is useful
   *     to the consumer.  Changing the identity of this element will trigger an update of the
   *     stack context, so it should be a simple object or else memoized using more granular
   *     dependencies to avoid unnecessary re-rendering.
   */
  const WithStackElement = (props) => {
    const stackIndex = useContext(StackContextIndex);
    const stack = useContext(StackContext);
    // on mount, and when props.element pointer changes, update the item in the stack with the
    // current value for element; stackIndex is included in dependencies for completeness, but
    // realistically, StackContextIndex value should never be changed outside of instances of this
    // component, so it should not be be possible for it to change
    useEffect(() => {
      const handle = { id: stackIndex, element: props.element };
      stack.mounted(handle);
      return () => { stack.unmounted(handle); };
    }, [stackIndex, props.element]);

    return (
      <StackContextIndex.Provider value={stackIndex + 1}>
        {props.children}
      </StackContextIndex.Provider>
    );
  };

  WithStackElement.propTypes = {
    children: PropTypes.node,
    element: PropTypes.any.isRequired
  };

  WithStackElement.displayName = `With${contextName}Element`;

  return { ContextProvider, StackContext, WithStackElement };
};
