import * as objUtils from 'utils/object';

export const FILTER_NAMES = {
  user_name: 'Earner',
  badge_name: 'Badge',
  skill_name: 'Skill',
  location_name: 'Location',
  occupation_name: 'Occupation',
  occupation: 'Occupation',
  department: 'Department',
  email: 'Email',
  issuer_name: 'Issuer',
  role: 'Role',
  team: 'Team',
  not_user_name: 'Exclude employee',
  not_badge_name: 'Exclude badge',
  not_skill_name: 'Exclude skill',
  not_location_name: 'Exclude location',
  not_email: 'Exclude email',
  not_issuer_name: 'Exclude issuer',
  not_role: 'Exclude role',
  not_team: 'Exclude team',
  employee_state: 'Status',
  invitation_status: 'Status',
  level: 'Badge Level'
};

// Exclude filters that have nothing to do with badges.
export const USER_PROFILE_FILTER_NAMES =
  objUtils.except(FILTER_NAMES, [
    'user_name', 'not_user_name',
    'location_name', 'not_location_name',
    'email', 'not_email',
    'role', 'not_role',
    'team', 'not_team'
  ]);
