import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import CredlyRoute from 'controls/credly_route';
import { CredlySwitch } from 'controls/credly_switch';
import { WithSectionHeader } from 'controls/section_header';
import { EmployeesRouteHelpers } from 'pages/workforce/route_helpers';
import { EmployeesSectionHeader } from './employees_section_header';
import { Routes as EmployeesRoutes } from './employees/routes';
import { Routes as BadgesRoutes } from './badges/routes';
import { Routes as SkillsRoutes } from './skills/routes';
import { Routes as OccupationsRoutes } from './occupations/routes';
import { useIntl } from 'react-intl';

/**
 * Top-level routes for the "Workforce" section of org management, including Employees tabs.
 *
 * @param props
 *   @param {string} props.baseUrl - the base URL of the analytics section of org management
 *   @param {object} props.organization - API data for the current Organization context
 * @returns {React.element}
 */
export const Routes = (props) => {
  const intl = useIntl();
  const routeHelpers = EmployeesRouteHelpers.forUrl(props.baseUrl);
  const sectionHeader = React.useCallback(() => (
    <EmployeesSectionHeader
      baseUrl={props.baseUrl}
      organizationId={props.organization.id}
    />
  ), [props.baseUrl, props.organization.id]);

  return (
    <WithSectionHeader
      breadcrumb={{
        action: props.baseUrl,
        label: intl.formatMessage({
          id: 'workforce.employees.routes.employees',
          defaultMessage: 'Employees'
        })
      }}
      sectionHeader={sectionHeader}
    >
      <CredlySwitch>
        <Redirect from={routeHelpers.root.route()} exact to={routeHelpers.employees.route()}/>
        <CredlyRoute
          path={routeHelpers.badges.route()}
          render={(routeProps) => (
            <BadgesRoutes
              baseUrl={routeProps.match.url}
              organization={props.organization}
            />
          )}
          title={intl.formatMessage({
            id: 'workforce.employees.routes.badges',
            defaultMessage: 'Badges'
          })}
        />
        <CredlyRoute
          path={routeHelpers.occupations.route()}
          render={(routeProps) => (
            <OccupationsRoutes
              baseUrl={routeProps.match.url}
              organization={props.organization}
            />
          )}
        />
        <CredlyRoute
          path={routeHelpers.skills.route()}
          render={(routeProps) => (
            <SkillsRoutes
              baseUrl={routeProps.match.url}
              organization={props.organization}
            />
          )}
          title={intl.formatMessage({
            id: 'workforce.employees.routes.skills',
            defaultMessage: 'Skills'
          })}
        />
        <CredlyRoute
          path={routeHelpers.employees.route()}
          render={(routeProps) => (
            <EmployeesRoutes
              baseUrl={routeProps.match.url}
              organization={props.organization}
            />
          )}
          title={intl.formatMessage({
            id: 'workforce.employees.routes.employees',
            defaultMessage: 'Employees'
          })}
        />
      </CredlySwitch>
    </WithSectionHeader>
  );
};

Routes.propTypes = {
  baseUrl: PropTypes.string,
  organization: PropTypes.object
};
