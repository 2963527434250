import React from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import {
  fixScrollLockBehavior,
  getCommonChartProps,
  getCommonXAxisProps,
  getYAxisProps,
  positioner
} from './utils';

import './line_chart.sass';


export const LineChart = ({ data, scrollContainerRef, roundedMax, formatTooltip }) => {
  const { prev, current } = data;
  const options = {
    ...getCommonChartProps(),
    chart: {
      type: 'line',
      panning: false,
      events: {
        load: fixScrollLockBehavior(scrollContainerRef)
      }
    },
    xAxis: {
      ...getCommonXAxisProps(roundedMax),
      gridLineWidth: 1,
      labels: {
        enabled: false
      }
    },
    yAxis: {
      ...getYAxisProps(),
      gridLineWidth: 0,
      labels: {
        enabled: false
      },
      maxPadding: 0.2,
      min: -1,
      max: 1,
      lineWidth: 2
    },
    plotOptions: {
      line: {
        enableMouseTracking: true,
        states: {
          hover: {
            enabled: false
          }
        },
        lineWidth: 10,
        color: '#D0D0D0',
        marker: {
          enabled: true,
          radius: 8
        }
      }
    },
    tooltip: {
      followTouchMove: false,
      followPointer: false,
      outside: true,
      shared: true,
      useHTML: true,
      hideDelay: 0,
      shadow: false,
      backgroundColor: 'transparent',
      borderWidth: 0,
      formatter: formatTooltip(data),
      positioner: positioner
    },
    series: [{
      name: '',
      data: [
        { x: prev, y: 0, color: '#2E2E2E', marker: { fillColor: '#2E2E2E' } },
        { x: current, y: 0, color: '#F36D21', marker: { fillColor: '#F36D21' } }
      ],
      marker: {
        enabled: true,
        radius: 8
      }
    }]
  };

  return (
    <div className="line-chart">
      <HighchartsReact
        containerProps={{ style: { height: "77px", display: 'flex' } }}
        highcharts={Highcharts}
        options={options}
      />
    </div>

  );
};

LineChart.propTypes = {
  formatTooltip: PropTypes.func.isRequired,
  data: PropTypes.shape({
    skill_name: PropTypes.string,
    skill_id: PropTypes.string,
    trend_period: PropTypes.string,
    future_proof: PropTypes.bool,
    prev: PropTypes.number,
    current: PropTypes.number
  }).isRequired,
  roundedMax: PropTypes.shape({
    max: PropTypes.number,
    tickInterval: PropTypes.number
  }),
  scrollContainerRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    PropTypes.oneOf([null])
  ])
};


export const LineChartLabel = ({ roundedMax }) => {
  const options = {
    ...getCommonChartProps(),
    chart: {
      events: null
    },
    xAxis: {
      ...getCommonXAxisProps(roundedMax),
      gridLineWidth: 0,
      labels: {
        enabled: true,
        align: 'high',
        reserveSpace: false,
        y: 0,
        x: -8
      }
    },
    yAxis: {
      visible: false
    },
    plotOptions: {
      series: {
        enableMouseTracking: false,
        marker: {
          enabled: false
        }
      }
    },
    tooltip: {
      enabled: false
    },
    series: [{
      name: '',
      data: []
    }]
  };

  return (
    <div className="line_chart_label-container">
      <HighchartsReact
        containerProps={{ style: { height: "20px", display: 'flex' } }}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

LineChartLabel.propTypes = {
  roundedMax: PropTypes.shape({
    max: PropTypes.number,
    tickInterval: PropTypes.number

  })
};
