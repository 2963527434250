/**
 * Search for any of a set of substrings in a string and return the first match as a pair of the
 * index where the substring starts and which substring was found there
 *
 * @param {string} str - string to find matches in
 * @param {string[]} match - array of possible matches to look for in str; NOTE: if one element
 *   matches a prefix of another, the longer one should appear first in the array, or the shorter
 *   one will be matched and returned
 * @param {Number} [fromIndex=0] - the index of str to start look for matches at
 * @returns {[number, *]}
 */
const scan = (str, match, fromIndex = 0) => {
  for (let i = fromIndex; i < str.length; ++i) {
    for (const m of match) {
      let isMatch = true;
      for (let j = 0; j < m.length; ++j) {
        isMatch = isMatch && str.charAt(i + j) === m.charAt(j);
        if (!isMatch) {
          break;
        }
      }
      if (isMatch) {
        return [i, m];
      }
    }
  }
  return [-1];
};

/**
 * Return the number of non-blank lines in a file.
 *
 * @param {File} file
 */
export const countLines = async file => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => {
      let lineCount = 0;
      let x = 0;
      while (x < reader.result.length && x >= 0) {
        const newLineMetadata = {
          found: false,
          skip: 0,
          index: -1
        };
        const scanResults = scan(reader.result, ['\r\n', '\n', '\r'], x);
        newLineMetadata.index = scanResults[0];
        if (newLineMetadata.index > -1) {
          newLineMetadata.found = true;
          newLineMetadata.skip = scanResults[1].length;
        }
        if (newLineMetadata.found && newLineMetadata.index === x) {
          // If the new line starts where we are beginning our scan treat it as a empty line
          x += newLineMetadata.skip;
        } else {
          lineCount++;
          if (newLineMetadata.found) {
            x = newLineMetadata.index + newLineMetadata.skip;
          } else {
            x = -1;
          }
        }
      }
      resolve(lineCount);
    };
    reader.readAsText(file);
  });
};

/**
 * Get the filename portion of the url.
 *
 * @param {String} url
 * @returns {String}
 */
export const urlToFilename = url => url.replace(/.*\//, '').replace(/\?.*$/, '');
