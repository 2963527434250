/* eslint-disable semi */
import React from "react";
import PropTypes from 'prop-types';
import { Heading } from "controls/heading";
import { FormattedMessage } from "react-intl";
import { SkillsDistributionChart } from "./skills_distribution_chart";
import mockData from './mockData.json';

import './skills_distribution.sass'


export const SkillsDistribution = ({ data = mockData }) => {
  return (
    <div>
      <div className="skills-distribution__header">
        <Heading
          appearance="section-subheader"
          className="skills-distribution__header-title"
        >
          <FormattedMessage
            defaultMessage="Skills Distribution"
            id="workforce.skills_explorer.skills_distribution.heading"
          />
        </Heading>
        <FormattedMessage
          className="workforce_skills-gained-chart-subtitle"
          defaultMessage="Get an overview of the skills in your organization"
          id="workforce.skills_explorer.skills_distribution.sub_heading"
        />
      </div>
      <SkillsDistributionChart data={data} />
    </div>
  );
};

SkillsDistribution.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    children: PropTypes.array
  }))
};
