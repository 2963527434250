import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'form';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeleteEmployee } from '../show/delete_employee';
import { Permissions, usePermissions } from 'management/organizations/permissions';
import { useFaethmOccupationMappingEnabled } from 'pages/workforce/faethm_occupation_mapping_hooks';
import { useHistory } from 'react-router-dom';
import { RoleButton } from 'aria/role_button';

import './employee_form.sass';



/**
 * Internal component used by EmployeeAdd and EditEmployee to render a form
 *
 * @param {object} props
 *   @param {object} props.formProps - props to be passed to the form component
 *   @param {Function} props.onSubmitSuccess - function to call when the form is successfully submitted
 *   @param {object} props.organization - organization object for which the employee belongs
 *   @param {object} props.employee - employee object to edit
 * @returns {JSX.Element}
 * @constructor
 */

export const EmployeeForm = ({ formProps, onSubmitSuccess, organization, employee }) => {
  const intl = useIntl();
  const integratedFaethm = useFaethmOccupationMappingEnabled();
  const [hasPermissions] = usePermissions();
  const editing = !!employee;
  const history = useHistory();
  const handleCancel = () => {
    history.goBack();
  };
  const buttonText = editing ? 'workforce.employees.employee.show.edit.save'
    : 'workforce.employees.employee.show.add.add_employee';
  return (
    <Form className="c-employee-form__form" {...formProps} onSubmitSuccess={onSubmitSuccess}>
      {({ propsForFieldGroup, isSubmitting }) => {
        return (
          <>
            <div className="c-employee-form__side-by-side-text-box">
              <Form.TextField
                className="c-employee-form__side-by-side-text-box-small"
                label={
                  intl.formatMessage({
                    id: 'workforce.employees.employee.show.add_edit.first_name',
                    defaultMessage: 'First name'
                  })
                }
                enclosed
                required
                {...propsForFieldGroup('first_name')}
              />
              <Form.TextField
                className="c-employee-form__side-by-side-text-box-small"
                label={
                  intl.formatMessage({
                    id: 'workforce.employees.employee.show.add_edit.last_name',
                    defaultMessage: 'Last name'
                  })
                }
                enclosed
                required
                {...propsForFieldGroup('last_name')}
              />
            </div>
            <Form.TextField
              label={
                intl.formatMessage({
                  id: 'workforce.employees.employee.show.add_edit.email',
                  defaultMessage: 'Email address'
                })
              }
              enclosed
              required
              readOnly={editing}
              {...propsForFieldGroup('email')}
            />
            <Form.TextField
              label={
                intl.formatMessage({
                  id: 'workforce.employees.employee.show.add_edit.employee_id',
                  defaultMessage: 'Employee ID'
                })
              }
              required={integratedFaethm}
              enclosed
              {...propsForFieldGroup('external_id')}
            />
            <Form.TextField
              label={
                intl.formatMessage({
                  id: 'workforce.employees.employee.show.add_edit.job_title',
                  defaultMessage: 'Job title'
                })
              }
              required={integratedFaethm}
              enclosed
              {...propsForFieldGroup('position_title')}
            />
            <Form.TextField
              label={
                intl.formatMessage({
                  id: 'workforce.employees.employee.show.add_edit.job_family',
                  defaultMessage: 'Job family'
                })
              }
              enclosed
              {...propsForFieldGroup('job_family')}
            />
            <Form.TextField
              label={
                intl.formatMessage({
                  id: 'workforce.employees.employee.show.add_edit.level',
                  defaultMessage: 'Level'
                })
              }
              enclosed
              {...propsForFieldGroup('level')}
            />
            <div className="c-employee-form__side-by-side-text-box">
              <Form.TextField
                className="c-employee-form__side-by-side-text-box-small"
                label={
                  intl.formatMessage({
                    id: 'workforce.employees.employee.show.add_edit.manager_first_name',
                    defaultMessage: 'Manager first name'
                  })
                }
                enclosed
                {...propsForFieldGroup('manager_first_name')}
              />
              <Form.TextField
                className="c-employee-form__side-by-side-text-box-small"
                label={
                  intl.formatMessage({
                    id: 'workforce.employees.employee.show.add_edit.manager_last_name',
                    defaultMessage: 'Manager last name'
                  })
                }
                enclosed
                {...propsForFieldGroup('manager_last_name')}
              />
            </div>
            <Form.TextField
              label={
                intl.formatMessage({
                  id: 'workforce.employees.employee.show.add_edit.manager_id',
                  defaultMessage: 'Manager ID'
                })
              }
              required={integratedFaethm}
              enclosed
              {...propsForFieldGroup('manager_external_id')}
            />
            <Form.TextField
              label={
                intl.formatMessage({
                  id: 'workforce.employees.employee.show.add_edit.organization_level_1',
                  defaultMessage: 'Organization level 1'
                })
              }
              required={integratedFaethm}
              enclosed
              {...propsForFieldGroup('organization_level_1')}
            />
            <Form.TextField
              label={
                intl.formatMessage({
                  id: 'workforce.employees.employee.show.add_edit.organization_level_2',
                  defaultMessage: 'Organization level 2'
                })
              }
              enclosed
              {...propsForFieldGroup('organization_level_2')}
            />
            <Form.TextField
              label={
                intl.formatMessage({
                  id: 'workforce.employees.employee.show.add_edit.organization_level_3',
                  defaultMessage: 'Organization level 3'
                })
              }
              enclosed
              {...propsForFieldGroup('organization_level_3')}
            />
            <Form.TextField
              label={
                intl.formatMessage({
                  id: 'workforce.employees.employee.show.add_edit.department',
                  defaultMessage: 'Department'
                })
              }
              enclosed
              {...propsForFieldGroup('department')}
            />
            <div className="c-employee-form__side-by-side-text-box">
              <Form.StateOrProvinceTypeaheadSelectOrDropdown
                initialValue={editing && formProps?.initialValues?.state_or_province}
                className="c-employee-form__side-by-side-text-box-small"
                enclosed
                {...propsForFieldGroup('state_or_province')}
                hideDoNotDiscloseOption
                typeaheadSelect
                hostCountry={propsForFieldGroup('country')?.value}
                label={
                  intl.formatMessage({
                    id: 'workforce.employees.employee.show.add_edit.state',
                    defaultMessage: 'State or Province'
                  })
                }
              />
              <Form.CountryTypeaheadSelectOrDropdown
                initialValue={editing && formProps?.initialValues?.country}
                className="c-employee-form__side-by-side-text-box-small"
                placeholder={
                  intl.formatMessage({
                    id: 'workforce.employees.employee.show.add_edit.placeholder',
                    defaultMessage: 'Select One'
                  })
                }
                enclosed
                hideDoNotDiscloseOption
                typeaheadSelect
                {...propsForFieldGroup('country')}
                label={
                  intl.formatMessage({
                    id: 'workforce.employees.employee.show.add_edit.country',
                    defaultMessage: 'Country'
                  })
                }
              />
            </div>

            <div className="c-employee-form__action-button">
              <RoleButton
                tagName="a"
                onClick={handleCancel}
                className="c-employee-form__cancel-link"
              >
                <FormattedMessage id="workforce.employees.employee.show.add_edit.cancel" defaultMessage="Cancel" />
              </RoleButton>
              {
                editing && hasPermissions(Permissions.EMPLOYMENT_DELETE) && (
                  <DeleteEmployee
                    className="c-edit-employee__action-buttons-accepted__remove"
                    state={employee.employee_state}
                    organization_id={organization.id}
                    id={employee.id}
                    employeeName={employee.full_name}
                    employeeEmail={employee.email}
                  />
                )
              }
              <Form.Submit
                type="primary"
                loading={isSubmitting}
                className="c-employee-form__submit-button"
              >
                <FormattedMessage
                  id={`${buttonText}`}
                  defaultMessage={editing ? "Save" : "Add Employee"}
                />
              </Form.Submit>
            </div>
          </>
        );
      }}
    </Form>
  );
};

EmployeeForm.propTypes = {
  formProps: PropTypes.func.required,
  onSubmitSuccess: PropTypes.func,
  organization: PropTypes.object.required,
  employee: PropTypes.object
};
