import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffectExceptOnMount } from 'utils/react_utils';
import { useAlerts } from 'alert';
import { ClampLines } from 'controls/clamp_lines';
import { EllipsisMenu } from 'controls/ellipsis_menu';
import {
  processSearchParamsToFilterTags,
  SavedDirectorySearchPropType,
  SavedDirectorySearchRouteHelpers
} from './utils';
import { savedDirectorySearchActionManager } from './actions';
import { NewCountTag } from './new_count_tag';

import './saved_directory_search_card.sass';

/**
 * Renders a saved search in card form. Can edit, share, and remove the search.
 * Clicking on the name will direct user to view their saved search.
 *
 * @param props
 *  @param {SavedDirectorySearchPropType} props.data
 *  @param {Function} props.handleRemove - Callback function called when remove option on menu is clicked
 */
export const SavedDirectorySearchCard = (props) => {
  const [updateSavedDirectorySearchState, updateSavedDirectorySearch] =
    savedDirectorySearchActionManager.useAction('update', props.data.id);

  // should we display the "Receive email updates" menu item as "loading"?
  const updateSavedDirectorySearchPending = updateSavedDirectorySearchState.status.pending;

  // if user has attempted to update "Receive email updates" and got an error, show an alert
  const alerts = useAlerts();
  useEffectExceptOnMount(() => {
    if (updateSavedDirectorySearchState.status.failed) {
      alerts.add({ type: 'error', text: 'Failed to update saved search. Please try again later.' });
    }
  }, [updateSavedDirectorySearchState.status]);

  const displayedFilterText = () => {
    return processSearchParamsToFilterTags(props.data.search_params).join(', ');
  };

  const handleSelect = (value) => {
    switch (value) {
      case 'receive-email-updates':
        updateSavedDirectorySearch({ send_email_updates: !props.data.send_email_updates });
        return false;
      case 'remove':
        props.handleRemove();
    }
  };

  return (
    <div className="c-saved-directory-search-card">
      <div className="c-saved-directory-search-card__header">
        <Link
          className="c-saved-directory-search-card__name"
          to={SavedDirectorySearchRouteHelpers.showSavedDirectorySearch.parameterize({
            id: props.data.id
          })}
        >
          {props.data.name}
        </Link>
        {props.data.displayable_matches &&
          <div className="c-saved-directory-search-card__new-count-tag-container">
            <NewCountTag
              className="c-saved-directory-search-card__new-count-tag"
              value={props.data.new_candidates}
            />
          </div>
        }
        <EllipsisMenu
          containerClassName="c-saved-directory-search-card__more-actions"
          onSelect={handleSelect}
        >
          <EllipsisMenu.CheckboxItem
            value="receive-email-updates"
            checked={props.data.send_email_updates}
            loading={updateSavedDirectorySearchPending}
            disabled={receiveEmailUpdatesDisabled(props.data)}
          >
            Receive email updates
          </EllipsisMenu.CheckboxItem>
          <EllipsisMenu.Item value="remove" className="c-saved-directory-search-card__menu-item-remove">
            Remove
          </EllipsisMenu.Item>
        </EllipsisMenu>
      </div>
      <div className="c-saved-directory-search-card__filters" title={displayedFilterText()}>
        <ClampLines lines={3}>
          {displayedFilterText()}
        </ClampLines>
      </div>
    </div>
  );
};

SavedDirectorySearchCard.propTypes = {
  data: SavedDirectorySearchPropType.isRequired,
  handleRemove: PropTypes.func
};

/**
 * Helper for showing message when email updates are disabled. Currently only disables the UI to
 * toggle email updates when new candidate tracking is disabled; however, it could also be correct
 * to disable it when waiting for the initial matches to be found.
 *
 * @param {{new_candidate_tracking_enabled:boolean, last_matched_new_candidates_at:string}} savedSearch
 * @returns {{message: JSX.Element}|boolean}
 */
const receiveEmailUpdatesDisabled = (savedSearch) => {
  if (!savedSearch.new_candidate_tracking_enabled && savedSearch.last_matched_new_candidates_at) {
    return { message: (<>Email updates disabled,<br/> too many matches</>) };
  } else {
    return false;
  }
};
