import { useMemo } from 'react';
import { useFaethmOccupationMappingContext } from './faethm_occupation_mapping_context';

/**
 * A custom hook that components within Workforce can use to check whether Tier 3
 * occupation mapping features are available to a given organization.
 *
 * @returns {boolean} - `true` if the organization is integrated with Faethm and Tier 3
 *  occupation mapping features are available, `false` otherwise.
 */
export const useFaethmOccupationMappingEnabled = () => {
  const isIntegratedWithFaethm = useFaethmOccupationMappingContext();
  return isIntegratedWithFaethm;
};

/**
 * A custom hook that components within Workforce can use to retrieve
 * excluded search types depending on whether the current Organization
 * is mapped with Faethm or not.
 *
 * Intended to be used within components that render DirectoryBase as
 * a child.
 *
 * @returns {object} - { occupation: true } which can be passed to
 * the DirectoryBase component through the excludeSearchTypes prop.
 */
export const useExcludedSearchTypes = () => {
  const isIntegratedWithFaethm = useFaethmOccupationMappingContext();
  const excludedSearchTypes = useMemo(() => {
    return { occupation: !isIntegratedWithFaethm };
  }, [isIntegratedWithFaethm]);

  return excludedSearchTypes;
};
