import { lazyWithRetry } from 'app_utils/lazy_load';

/**
 * @typedef ApiOrganization
 * @property {String} id
 * @property {String} synthetic_id
 * @property {String} vanity_slug
 * @property {Boolean} has_public_earned_badges
 * @property {Boolean} has_public_issuer_collections
 * @property {Boolean} has_earner_directory
 * @property {Boolean} has_custom_directory
 */
let ApiOrganizationDocOnly;

export const OrganizationLayout = lazyWithRetry(() =>
  import(/* webpackChunkName: "public-organization" */ './organization_layout')
    .then(module => ({default: module.OrganizationLayout}))
);

export const CustomDirectoryLayout = lazyWithRetry(() =>
  import(/* webpackChunkName: "public-organization" */ './earner_directory/custom_directory_layout')
    .then(module => ({default: module.CustomDirectoryLayout}))
);
