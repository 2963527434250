import { lazyWithRetry } from 'app_utils/lazy_load';

export * from './selectors';
export * from './action_creators';
export * from './react_hooks';

const CREDENTIAL_SOLUTIONS_ACCESS = 'Organization.access_credential_solutions';
const WORKFORCE_ACCESS = 'Organization.access_workforce';

export const ProfileContextHeader = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "header" */'./profile_context_header')
    .then(module => ({default: module.ProfileContextHeader}))
);

/**
 * Confirm a user is a member of at least one active organization
 *
 * @param {Object} user
 * @returns {boolean}
 */
export const isActiveOrgMember = (user) => {
  return !!user.memberships.find((membership) => membership.organization_active);
};

/**
 * Returns true if the user has Credential Solutions access to some organization or a specific one
 * (depending on whether the organizationId parameter is given).
 *
 * @param {object} user - the user to check for access
 * @param {string|null} [organizationId] - the organization to check for whether user has CredSol
 *   access; if omitted, returns if user has CredSol access to any organization
 * @returns {boolean}
 */
export const hasCredentialSolutionsAccess = (user, organizationId = null) => {
  return user.memberships.find((membership) => (
    (!organizationId || membership.organization_id === organizationId) &&
    membership.organization_active &&
    membership.permissions.includes(CREDENTIAL_SOLUTIONS_ACCESS)
  ));
};

/**
 * Returns true if the user has Workforce access to some organization or a specific one (depending
 * on whether the organizationId parameter is given).
 *
 * @param {object} user - the user to check for access
 * @param {string|null} [organizationId] - the organization to check for whether user has Workforce
 *   access; if omitted, returns if user has access to any Workforce organization
 * @returns {boolean}
 */
export const hasWorkforceAccess = (user, organizationId = null) => {
  return user.memberships.find((membership) => (
    (!organizationId || membership.organization_id === organizationId) &&
      membership.workforce_enabled && membership.organization_active &&
      membership.permissions.includes(WORKFORCE_ACCESS)
  ));
};


export const findMembership = (user, organizationId, { onlyActive } = { onlyActive: true }) => {
  return user.memberships.find((membership) => (
    membership.organization_id === organizationId && (!onlyActive || membership.organization_active)
  ));
};

export const membershipHasWorkforceRole = (membership) => {
  return membership.role?.startsWith('workforce:');
};
