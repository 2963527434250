import { Action } from 'utils/action';
import * as stringUtils from 'utils/string';

export const transformPayload = data => data.map(result => {
  result.payload.issuer_entity = result.payload.issuer.entities[0].entity;
  return result.payload;
});

export const exploreBadgesAction = new Action({
  resourceType: 'exploreBadges',
  requestKey: 'fetchExploreBadges',
  url: '/api/v1/users/self/explore',
  effect: 'read',
  method: 'GET',
  transform: transformPayload
});

export const popularBadgesAction = new Action({
  resourceType: 'popularOrgBadgesOrg',
  requestKey: 'fetchPopularBadges',
  url: '/api/v1/organizations/[organization_id]/employees/popular_badges',
  effect: 'read',
  method: 'GET',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true }
  }),
  alwaysRefresh: true,
  list: 'list'
});
