export const getCommonChartProps = () => ({
  title: {
    text: ''
  },
  credits: {
    enabled: false
  },
  legend: {
    enabled: false
  }
});

export const getCommonXAxisProps = (roundedMax) => {
  const { max, tickInterval } = roundedMax;

  return {
    min: 0,
    max: max || 100,
    tickInterval: tickInterval || 100,
    title: {
      text: null
    },
    lineColor: 'transparent',
    lineWidth: 0,
    tickColor: 'transparent'
  };
};

export const getYAxisProps = () => ({
  startOnTick: false,
  endOnTick: false,
  title: null,
  lineColor: 'transparent',
  minorTickLength: 0,
  tickLength: 0,
  minorGridLineWidth: 0
});


export const positioner = function(labelWidth, labelHeight) {
  const startPoint = this.chart.series[0].data[0].plotX + this.chart.plotLeft;
  const endPoint = this.chart.series[0].data[1].plotX + this.chart.plotLeft;
  const xCenter = (startPoint + endPoint) / 2;
  const yCenter = this.chart.plotTop + this.chart.plotHeight / 2;
  return { x: xCenter - (labelWidth / 2), y: yCenter - labelHeight - 30 + 4 };
};

export const fixScrollLockBehavior = (scrollContainerRef) => function() {
  this.container.addEventListener('wheel', function(e) {
    // Prevent highcharts default
    e.preventDefault();

    const ref = scrollContainerRef?.current;
    const atTop = ref.scrollTop === 0;
    const atBottom = ref.scrollHeight - ref.scrollTop === ref.clientHeight;

    const shouldScrollWindow = (atTop && e.deltaY < 0) || (atBottom && e.deltaY > 0);

    if (shouldScrollWindow) {
      window.scrollBy({
        top: e.deltaY,
        left: e.deltaX,
        behavior: 'auto'
      });
    } else {
      // Manually scroll container
      // eslint-disable-next-line no-unused-expressions
      ref?.scrollBy({
        top: e.deltaY,
        left: e.deltaX,
        behavior: 'auto'
      });
    }
  }, { passive: false });
};
