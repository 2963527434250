import React from 'react';
import { useEffectExceptOnMount } from 'utils/react_utils';
import { useAlerts } from 'alert';
import ConfirmationModal from 'controls/confirmation_modal';
import { LearningPlanEmploymentActions } from 'pages/workforce/learning/actions';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { LearningRouteHelpers } from 'pages/workforce/route_helpers';
import { useHistory } from "react-router-dom";

/**
 * Remove Recomendation component. When clicked will display a modal asking
 * confirmation to remove one or all recommendations.
 *
 * @param {Object} props
 *   @param {uuid} props.organizationId - The organization ID.
 *   @param {uuid} props.learningPlanId - The learning plan ID.
 *   @param {Boolean} props.showModal display dialog or not
 *   @param {Function} props.setShowModal toggle the showModal to true or false
 */

export const RemoveAllRecommendationsModal = (props) => {
  const intl = useIntl();
  const [RemoveEmploymentState, RemoveEmployment] =
    LearningPlanEmploymentActions.useAction('deleteAll', null);
  const alerts = useAlerts();
  const history = useHistory();
  const routeHelpers = LearningRouteHelpers.forUrl(props.baseUrl);

  const removeRecommendation = () => {
    RemoveEmployment(
      {
        organization_id: props.organizationId,
        learning_plan_id: props.learningPlanId
      });
  };

  useEffectExceptOnMount(() => {
    if (props.showModal) {
      props.setShowModal(false);
    }
    if (RemoveEmploymentState.status.failed) {
      alerts.add({
        type: 'error',
        text: intl.formatMessage({
          id: 'workforce.learning.modal.recommendation.removed.failed.text',
          defaultMessage: 'Failed to remove recommendation.'
        })
      });
    }
    if (RemoveEmploymentState.status.succeeded) {
      alerts.add({
        type: 'notice',
        text: intl.formatMessage({
          id: 'workforce.learning.modal.recommendation.removed.succeeded.text',
          defaultMessage: 'Recommendation successfully removed.'
        })
      });
      history.push(routeHelpers.learningPlans.route());
    }
  }, [RemoveEmploymentState.status]);

  const modalTitle = intl.formatMessage({
    id: 'workforce.learning.modal.title.remove_all_recommendations',
    defaultMessage: 'Remove all recommendations'
  });

  const modalButtonLabel = intl.formatMessage({
    id: 'workforce.learning.modal.button.remove_all_recommendations',
    defaultMessage: 'Remove all'
  });

  const confirmationModalDescription = intl.formatMessage({
    id: 'workforce.learning.modal.recommendation.remove.description',
    defaultMessage: 'Once an employee is removed they will no longer see this recommendation. ' +
    'If they have already earned a badge it will not be removed from their profile.'
  });

  return (
    <div className="remove-all-recommendations-modal">
      <ConfirmationModal
        title={modalTitle}
        question=""
        buttonAction={removeRecommendation}
        buttonActionDescription={modalButtonLabel}
        description={confirmationModalDescription}
        showModal={props.showModal}
        setShowModal={props.setShowModal}
        isLoading={RemoveEmploymentState.status.pending}
      />
    </div>
  );
};

RemoveAllRecommendationsModal.propTypes = {
  organizationId: PropTypes.string,
  learningPlanId: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  baseUrl: PropTypes.string
};
