import React from 'react';
import widthWatcher from 'utils/watch_width_breakpoints';

/**
 * React hook that returns the current value of the window width
 * as one of the following breakpoints:
 * - 'xs'
 * - 'sm'
 * - 'md'
 * - 'lg'
 * - 'xl'
 *
 * @returns {string} the current value of the window width as a breakpoint
 */
export const useWidthBreakpoints = () => {
  const [view, setView] = React.useState();

  React.useEffect(() => {
    const id = widthWatcher.add(setView);
    return () => widthWatcher.remove(id);
  }, []);

  return view;
};

export const BREAKPOINTS = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl'
};
