import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 * Wraps components to stop propagation of click event.
 *
 * @param {React.ReactNode} children - content to wrap in click-event propagation stopping behavior
 * @param {string} [tag='span'] - which HTML tag to use as the wrapper
 * @param tagProps - additional props passed to BlockClickPropagate will be applied directly to the
 *   wrapper element
 * @returns {JSX.Element}
 * @constructor
 */
export const BlockClickPropagate = ({ tag, children, ...tagProps }) => {
  const handleClick = useCallback((event) => {
    event.stopPropagation();
  }, []);
  const Tag = tag || 'span';
  return (<Tag onClick={handleClick} {...tagProps}>{children}</Tag>);
};

BlockClickPropagate.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.string
};
