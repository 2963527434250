import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ajax } from 'utils';
import { useAlerts } from 'alert';
import { useOnOff } from 'utils/react_utils';
import { pluralize } from 'utils/localization';
import { RoleButton } from 'aria/role_button';
import Dialog from 'controls/dialog';
import { Permissions, usePermissions } from 'management/organizations/permissions';
import { useIntl } from 'react-intl';

import './employee_profile_invitation_status.sass';

/**
 * Renders invitation status of employee and conditionally displays
 * modal when clicked to resend / send notification again.
 *
 * @param {Object} props
 *   @param {object} props.organization - The organization
 *   of the employer
 *   @param {object} props.employee - Resource object representing employee profile api response
 */
export const EmployeeProfileInvitationStatus = props => {
  const intl = useIntl();
  const [showInvitationDialog, openInvitationDialog, closeInvitationDialog] = useOnOff(false);
  const [hasPermissions] = usePermissions();
  const invitationDialogTitle = () => {
    if (props.employee.invitation_status === 'Invited') {
      return intl.formatMessage({
        id: 'workforce.employees.show.profile.invitation.resend',
        defaultMessage: 'Resend Invitation'
      });
    } else {
      return intl.formatMessage({
        id: 'workforce.employees.show.profile.invitation.send',
        defaultMessage: 'Send Invitation'
      });
    }
  };
  const invitationDialogPrompt = () => {
    if (props.employee.invitation_status === 'Invited') {
      const daysSinceLastNotification = (() => {
        const timeDiff = (
          new Date()).getTime() - (new Date(props.employee.last_invitation_sent_at)
        ).getTime();
        return Math.floor(timeDiff / (1000 * 24 * 60 * 60));
      })();

      return intl.formatMessage(
        {
          id: 'workforce.employees.show.profile.invitation.resend_message',
          defaultMessage: '{fullName}\'s invitation has been pending for ' +
            '{daysSinceLastNotification, plural, one {1 day} other ' +
            '{{daysSinceLastNotification, number} days}}. Would you like to resend this invitation?'
        },
        { fullName: props.employee.full_name, daysSinceLastNotification }
      );
    } else {
      return intl.formatMessage(
        {
          id: 'workforce.employees.show.profile.invitation.send_message',
          defaultMessage: '{fullName} has not yet been invited. Would you like to send an ' +
            'invitation?'
        },
        { fullName: props.employee.full_name }
      );
    }
  };

  const invitationDialogButtonText = () => {
    if (props.employee.invitation_status === 'Invited') {
      return intl.formatMessage({
        id: 'workforce.employees.show.profile.invitation.resend',
        defaultMessage: 'Resend Invitation'
      });
    } else {
      return intl.formatMessage({
        id: 'workforce.employees.show.profile.invitation.send',
        defaultMessage: 'Send Invitation'
      });
    }
  };
  const alerts = useAlerts();
  const [requestPending, setRequestPending] = useState(false);

  const handleSuccess = () => {
    if (props.employee.invitation_status === 'Invited') {
      alerts.add({
        type: 'notice',
        text: intl.formatMessage({
          id: 'workforce.employees.show.profile.invitation.message.resent.success',
          defaultMessage: 'Your invitation has been resent.'
        })
      });
    } else {
      alerts.add({
        type: 'notice',
        text: intl.formatMessage({
          id: 'workforce.employees.show.profile.invitation.message.sent.success',
          defaultMessage: 'Your invitation has been sent.'
        })
      });
    }
    setRequestPending(false);
    closeInvitationDialog();
  };

  const handleFailure = () => {
    setRequestPending(false);
    alerts.add({
      type: 'error',
      text: intl.formatMessage({
        id: 'workforce.employees.show.profile.invitation.message.failure',
        defaultMessage: 'Failed to resend invitation.'
      })
    });
  };

  const resendNotification = () => {
    if (!requestPending) {
      setRequestPending(true);
    }
  };

  useEffect(() => {
    if (requestPending) {
      const resendApiUrl =
        `/api/v1/organizations/${props.organization.id}/employees/` +
        `${props.employee.id}/resend_notification`;
      ajax({
        method: 'POST',
        uri: resendApiUrl,
        headers: { Accept: 'application/json' }
      }).then(handleSuccess).catch(handleFailure);
    }
  }, [requestPending]);

  let status = props.children;
  if (
    ['Created', 'Invited', 'Pending'].includes(props.employee.invitation_status) &&
    hasPermissions(Permissions.EMPLOYMENT_UPDATE)
  ) {
    status = (
      <RoleButton
        tagName="span"
        onClick={openInvitationDialog}
        className="employee-profile-invitation-status__status-text-resend-notification"
      >
        {props.children}
      </RoleButton>
    );
  }

  return (
    <div>
      <Dialog
        show={showInvitationDialog}
        shouldClose={closeInvitationDialog}
        title={invitationDialogTitle()}
      >
        <Dialog.Content>
          {invitationDialogPrompt()}
        </Dialog.Content>
        <Dialog.Footer>
          <Dialog.Action action={resendNotification} noClose loading={requestPending}>
            {invitationDialogButtonText()}
          </Dialog.Action>
        </Dialog.Footer>
      </Dialog>
      {status}
    </div>
  );
};

EmployeeProfileInvitationStatus.propTypes = {
  employee: PropTypes.object.isRequired,
  children: PropTypes.node,
  organization: PropTypes.object.isRequired
};
