import { Avatar } from 'controls/avatar';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';

import './employee_photo.sass';

const DEFAULT_WIDTH = 48;

/**
 * Renders an employees profile picture or else an empty avatar
 *
 * @param {Object} props
 *   @param {uuid} props.photo_url - Source url of employee profile picture
 */
export const EmployeePhoto = (props) => {
  const photoSize = props.width || DEFAULT_WIDTH;
  const photoSizeClass = `photo__size-${photoSize}`;

  return (
    <Avatar
      className={`c-employee-photo__photo ${photoSizeClass}`}
      width={photoSize}
      src={props.photo_url}
      emptyImage={
        <FontAwesomeIcon
          className={`c-employee-photo__empty-photo ${photoSizeClass}`}
          icon={faUser}
        />
      }
    />
  );
};

EmployeePhoto.propTypes = {
  photo_url: PropTypes.string,
  width: PropTypes.oneOf([24, 48])
};
