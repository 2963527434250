import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'controls/placeholder';
import { InfoModal } from '../info_modal';
import { DiffPill } from './diff_pill';

import './chiclet.sass';

/**
 * "Chiclet" used to display a key metric along with indicating change as compared to a prior
 * period/point in time.
 *
 * @param {object} props
 *   @param {string} props.label - indicates what the chiclet is summarizing, e.g. "Connections"
 *   @param {number} props.previousTotal - the total number of whatever props.label was in the
 *     previous period (currently always 1 month ago), used to compute the amount of change since
 *     the previous period
 *   @param {number} props.total - the total number of whatever props.label is, displayed as the
 *     most prominent element of the chiclet
 *   @param {boolean} props.useTotalPercent - optional percentage block with the total number of whatever props.label is,
 *     displayed as the small element of the chiclet represented by a percentage of the total to the previous total attribute
 *   @param {React.ReactElement} [props.tooltip] - if present, renders an info icon which will open
 *     a modal when clicked to give more details about the metric
 * @returns {JSX.Element}
 * @constructor
 */

export const Chiclet = ({ label, tooltip, displayStatic, displayDifference, useTotalPercent }) => {
  const { total, previousTotal } = displayDifference;
  const usingDifference = (typeof total === 'number' && typeof previousTotal === 'number');
  if (usingDifference && displayStatic) {
    throw new Error('Cannot use both displayStatic, and displayDifference props together.');
  }

  const renderValue = useMemo(() => {
    if (usingDifference || useTotalPercent) {
      return Number(total).toLocaleString();
    } else {
      return displayStatic;
    }
  }, [displayStatic, total, previousTotal]);

  return (
    <div className="workforce-chiclet">
      <div className="workforce-chiclet__top_content_container">
        <div className="workforce-chiclet__label-container">
          <div className="workforce-chiclet__label">
            {label}
          </div>
          {tooltip && <InfoModal title={label} body={tooltip}/>}
        </div>
        <div className="workforce-chiclet__count">
          {renderValue}
        </div>
      </div>

      {(usingDifference || useTotalPercent) && (
        <DiffPill
          total={total}
          previousTotal={previousTotal}
          useTotalPercent={useTotalPercent}
        />
      )}

    </div>
  );
};

Chiclet.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.node,
  total: PropTypes.number.isRequired,
  useTotalPercent: PropTypes.bool,
  displayDifference: PropTypes.shape({
    total: PropTypes.number,
    previousTotal: PropTypes.number
  }),
  displayStatic: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Chiclet.defaultProps = {
  displayDifference: {
    total: null,
    previousTotal: null
  }
};

/**
 * Displays a skeleton "Chiclet" component that doesn't require or show any actual data. Useful for
 * when data required to render the actual chiclet is loading.
 *
 * @param {object} _props - unused React props argument
 * @returns {JSX.Element}
 * @constructor
 */
export const ChicletLoading = (_props) => {
  return (
    <div className="workforce-chiclet workforce-chiclet--loading">
      <Placeholder className="worforce-chiclet__label"/>
      <Placeholder className="workforce-chiclet__count"/>
      <Placeholder className="workforce-chiclet__change workforce-chiclet__change--placeholder"/>
    </div>
  );
};
