import React from 'react';
import { GenericSectionHeader } from 'controls/section_header';
import { useIntl } from 'react-intl';

/**
 * Renders the section header for the "Learning" section of workforce org.
 */
export const LearningSectionHeader = () => {
  const intl = useIntl();

  return (
    <GenericSectionHeader
      heading={intl.formatMessage({
        id: 'workforce.learning.section_header.my_team_development',
        defaultMessage: 'My Team’s Development'
      })}
      breadcrumbs={false}
    />
  );
};

