import React, { useEffect } from 'react';
import { uuidPropType } from 'prop_types';
import { useAlerts } from 'alert';
import { useUploadEmployeeOccupationsForm } from './upload/employee_occupation_upload/use_upload_employee_occupations_form';
import { UploadEmployeeOccupationsForm } from './upload/employee_occupation_upload/upload_employee_occupations_form';
import { UploadEmployeeOccupationsProgress } from './upload/employee_occupation_upload/upload_employee_occupations_progress';
import { useIntl, FormattedMessage } from 'react-intl';

/**
 * Component that displays the upload EmployeeOccupation page.
 *
 * @param {*} props
 *   @param {String} props.organization_id - Id of the organization to retrieve employee_occupations for
 */
export const EmployeeOccupationUpload = (props) => {
  const {
    processingFile,
    uploading,
    failedFile,
    importedFile
  } = useUploadEmployeeOccupationsForm(props.organization_id);

  const alerts = useAlerts();
  const intl = useIntl();

  useEffect(() => {
    if (!processingFile && !failedFile && importedFile) {
      alerts.add({
        type: 'notice',
        head: intl.formatMessage({
          id: 'workforce.employee.occupations.upload.alert.success.head',
          defaultMessage: 'Success'
        }),
        text: intl.formatMessage({
          id: 'workforce.employee.occupations.upload.alert.success.text',
          defaultMessage: 'Your CSV was imported successfully.'
        })
      });
    }
  }, [processingFile && processingFile.id]);

  useEffect(() => {
    if (!processingFile && failedFile) {
      alerts.add({
        type: 'error',
        head: intl.formatMessage({
          id: 'workforce.employee.occupations.upload.alert.upload_failed.head',
          defaultMessage: 'Upload failed'
        }),
        text: intl.formatMessage({
          id: 'workforce.employee.occupations.upload.alert.upload_failed.text',
          defaultMessage: 'Errors were found while processing your file.'
        })
      });
    }
  }, [processingFile && processingFile.id]);

  const renderContent = () => {
    if (!!processingFile || uploading) {
      return (
        <UploadEmployeeOccupationsProgress organization_id={props.organization_id}/>
      );
    } else {
      return (
        <UploadEmployeeOccupationsForm organization_id={props.organization_id}/>
      );
    }
  };

  return (
    <div className="c-employee-upload">
      <p>
        <FormattedMessage
          id="workforce.employee_occupation_upload.description"
          defaultMessage="Upload a comma delimited text file (.csv) to verify/update employee matched occupations. Please use this <a>template</a>."
          values={{ a: chunks => <a href="/employee_occupation_upload_template">{chunks}</a> }}
        />
      </p>

      {renderContent()}
    </div>
  );
};

EmployeeOccupationUpload.propTypes = {
  organization_id: uuidPropType
};
