import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useToggle } from 'utils/react_utils';
import { useAlerts } from 'alert';
import { Form, useFormProps } from 'form';
import { LearningRouteHelpers } from 'pages/workforce/route_helpers';
import { ConfirmationModal } from 'pages/workforce/learning/shared/confirmation_modal';
import { DirectReportsTable } from './components/direct_reports_table';
import { FormButtons } from './components/form_buttons';

import './learning_plan_form.sass';

/**
 * The form component for creating a Learning Plan, and adding direct reports
 * to an existing Learning Plan. A child of NewLearningPlan, and EditLearningPlan.
 * This component handles the form state, validations, and submission logic.
 *
 * @param {Object} props.learningPlanState - the state of the learning plan
 * @param {Object} props.learningPlanAction - the action to be taken on form submit
 * @param {function} props.updateLearningPlanState - useState setter to update the state
 * of the learning plan
 * @returns {JSX.Element} A form with selection table and submission buttons
 */

export const LearningPlanForm = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const {
    learningPlanState,
    updateLearningPlanState,
    learningPlanAction,
    baseUrl
  } = props;

  const alerts = useAlerts();
  const [showConfirmation, toggleShowConfirmation] = useToggle();
  const [formProps, state] = useFormProps(
    learningPlanAction,
    {
      initialValues: {
        direct_report_ids: learningPlanState.direct_report_ids,
        badge_template_id: learningPlanState.badge_template.id,
        organization_id: learningPlanState.organization_id
      }
    }
  );

  const handleCancel = useCallback(() => {
    if (learningPlanState.id) {
      history.push(LearningRouteHelpers.show.parameterize({
        id: learningPlanState.id,
        organization_id: learningPlanState.organization_id
      }));
    } else {
      const urlBase = '/org';
      const urlParams = learningPlanState.badge_template.url.split(urlBase)[1];
      history.push(`${urlBase}${urlParams}`);
    }
  }, [learningPlanState.id, learningPlanState.badge_template?.url]);

  const handleContinue = useCallback((selectedDirectReports) => {
    if (!selectedDirectReports.length) {
      alerts.add({
        type: 'error',
        text: intl.formatMessage({
          id: 'workforce.learning.form.one_or_more_employees',
          defaultMessage: 'Please select one or more employees'
        })
      });
    } else {
      toggleShowConfirmation();
    }
  }, [showConfirmation, toggleShowConfirmation]);

  const handleSubmitSuccess = useCallback(({ direct_report_ids: directReportIds }) => {
    if (learningPlanState?.id) {
      const routeHelpers = LearningRouteHelpers.forUrl(baseUrl);
      const url = routeHelpers.show.parameterize({ id: learningPlanState.id });

      history.push(url);
    } else {
      const learningPlan = state.resources[0];
      if (learningPlan.id && directReportIds.length) {
        updateLearningPlanState({
          ...learningPlan,
          direct_report_ids: directReportIds
        });
      }
    }
  }, [history, baseUrl, learningPlanState.id, state.resources, updateLearningPlanState]);

  return (
    <>
      <h2 className="workforce-learning-plan__form-subheading">
        <FormattedMessage
          id="workforce.learning.form.subheading"
          defaultMessage="Select one or more employees"
        />
      </h2>
      <Form
        {...formProps}
        onSubmitSuccess={handleSubmitSuccess}
      >
        {({ propsForFieldGroup, isSubmitting, submit }) =>
          <>
            <DirectReportsTable
              propsForFieldGroup={propsForFieldGroup('direct_report_ids')}
              learningPlanState={learningPlanState}
            />
            <FormButtons
              employeeSelected={propsForFieldGroup('direct_report_ids').value.length > 0}
              isSubmitting={isSubmitting}
              handleCancel={handleCancel}
              toggleConfirmation={() => {
                handleContinue(propsForFieldGroup('direct_report_ids').value);
              }}
            />
            <ConfirmationModal
              show={showConfirmation}
              toggleShow={toggleShowConfirmation}
              badgeTemplateName={learningPlanState.badge_template.name}
              selectedDirectReports={propsForFieldGroup('direct_report_ids').value}
              submitForm={submit}
            />
          </>
        }
      </Form>
    </>
  );
};

LearningPlanForm.propTypes = {
  learningPlanState: PropTypes.object.isRequired,
  learningPlanAction: PropTypes.object.isRequired,
  updateLearningPlanState: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired
};
