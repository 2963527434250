import React from 'react';
import { useIntl } from 'react-intl';
import { GenericSectionHeader } from 'controls/section_header';

/**
 * Renders section header for Workforce analytics; currently just the heading
 *
 * @param {object} _props - React props (not currently used)
 * @returns {JSX.Element}
 * @constructor
 */
export const AnalyticsSectionHeader = (_props) => {
  const intl = useIntl();

  return (
    <GenericSectionHeader
      heading={intl.formatMessage({ id: 'workforce.nav.analytics', defaultMessage: 'Analytics' })}
    />
  );
};
