import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import CredlyRoute from 'controls/credly_route';
import { CredlySwitch } from 'controls/credly_switch';
import { EmployeeDirectory } from 'pages/workforce/employees/employees/employee_directory';
import { EmployeeRouteHelpers } from 'pages/workforce/route_helpers';
import { Layout as ShowLayout } from './show/layout';
import { Layout as AddLayout } from './add/layout';
import { useIntl } from 'react-intl';

/**
 * Routes for the "Employees" section of Employers
 *
 * @param props
 *   @param {string} props.baseUrl - the base URL of the employees subsection of Employers
 *   @param {Object} props.organization
 * @returns {React.element}
 */
export const Routes = (props) => {
  const intl = useIntl();
  const routeHelpers = EmployeeRouteHelpers.forUrl(props.baseUrl);

  return (
    <CredlySwitch>
      <Redirect from={routeHelpers.root.route()} exact to={routeHelpers.employees.route()}/>
      <CredlyRoute
        path={routeHelpers.employees.route()}
        exact
        render={(routeProps) => {
          return (
            <EmployeeDirectory
              baseUrl={routeProps.match.url}
              id={props.organization.id}
              organization={props.organization}
              routeProps={routeProps}
            />
          );
        }}
        title={intl.formatMessage({
          id: 'workforce.employees.employees.routes.employees',
          defaultMessage: 'Employees'
        })}
      />
      <CredlyRoute
        path={routeHelpers.employeeAdd.route()}
        render={(routeProps) => (
          <AddLayout
            baseUrl={routeProps.match.url}
            organization={props.organization}
            routeProps={routeProps}
          />
        )}
        title={intl.formatMessage({
          id: 'workforce.employees.employees.routes.add_employees',
          defaultMessage: 'Add Employee(s)'
        })}
      />
      <CredlyRoute
        path={routeHelpers.showEmployee.route()}
        render={(routeProps) =>
          <ShowLayout
            baseUrl={routeProps.match.url}
            organization={props.organization}
            organization_id={props.organization.id}
            id={routeProps.match.params.id}
          />
        }
        title={intl.formatMessage({
          id: 'workforce.employees.employees.routes.employee',
          defaultMessage: 'Employee'
        })}
      />
    </CredlySwitch>
  );
};

Routes.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired
};
