import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { WithSectionHeader } from 'controls/section_header';
import {
  LearningPlanActions,
  LearningPlanEmploymentActions
} from 'pages/workforce/learning/actions';
import { badgeTemplateActions } from 'pages/public/badge_templates/action_creators';
import { BadgeLinkSectionHeader } from 'pages/workforce/learning/shared/badge_link_section_header';
import { LearningPlanForm } from 'pages/workforce/learning/shared/learning_plan_form';
import { LearningRouteHelpers } from 'pages/workforce/route_helpers';

const headingTranslationData = {
  id: 'workforce.learning.create.section_header',
  defaultMessage: 'Make a learning recommendation'
};

/**
 * The primary entry point for creating a new Learning Plan.
 * This component fetches the appropriate badge template and
 * provides a form for selecting one or more employees to include in the plan.
 * It manages the state for the new Learning Plan and passes down relevant props
 * to LearningPlanForm.
 *
 * @param {Object} organization - organization details
 * @param {string} templateId - the template ID for the learning plan
 * @returns {JSX.Element}
 */

export const NewLearningPlan = ({ organization, templateId, baseUrl }) => {
  const history = useHistory();
  const learningPlanAction = LearningPlanActions.get('create');
  const [newLearningPlan, setNewLearningPlan] = useState({
    id: null,
    direct_report_ids: [],
    badge_template: null,
    organization_id: organization.id
  });

  const [badgeTemplateState] = badgeTemplateActions.useOnMount('fetchById', templateId);
  const badgeTemplatePending = badgeTemplateState.status.pending;

  useEffect(() => {
    if (badgeTemplateState.resources && !badgeTemplatePending) {
      setNewLearningPlan({
        ...newLearningPlan,
        badge_template: badgeTemplateState.resources
      });
    }
  }, [
    badgeTemplatePending,
    badgeTemplateState.resources,
    newLearningPlan.badgeTemplate
  ]);

  const [createLearningPlanEmploymentsState, createLearningPlanEmployments] =
    LearningPlanEmploymentActions.useAction(
      'create', newLearningPlan.id
    );
  const createComplete = !!newLearningPlan.id;

  useEffect(() => {
    if (createComplete) {
      createLearningPlanEmployments({
        ...newLearningPlan,
        organization_id: organization.id
      });
    }
  }, [createComplete]);

  useEffect(() => {
    if (!createLearningPlanEmploymentsState.status.succeeded || !newLearningPlan.id) {
      return;
    }

    history
      .push(LearningRouteHelpers.forUrl(baseUrl).show.parameterize({ id: newLearningPlan.id }));
  }, [history, newLearningPlan.id, baseUrl, createLearningPlanEmploymentsState.status.succeeded]);

  const sectionHeader = useCallback(() => (
    newLearningPlan.badge_template?.id &&
      <BadgeLinkSectionHeader
        badgeTemplate={newLearningPlan.badge_template}
        translationData={headingTranslationData}
      />
  ), [organization.id, newLearningPlan.badge_template]);

  return (
    <>
      {newLearningPlan.badge_template?.id &&
        <WithSectionHeader sectionHeader={sectionHeader}>
          <div className="workforce-new-learning-plan__container container-fluid">
            <LearningPlanForm
              learningPlanState={newLearningPlan}
              learningPlanAction={learningPlanAction}
              updateLearningPlanState={setNewLearningPlan}
              baseUrl={baseUrl}
            />
          </div>
        </WithSectionHeader>
      }
    </>
  );
};

NewLearningPlan.propTypes = {
  organization: PropTypes.object.isRequired,
  templateId: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired
};
