import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { uuidPropType } from 'prop_types';
import { useAlerts } from 'alert';
import { useUploadEmployeesForm } from './upload/employee_upload/use_upload_employees_form';
import { UploadEmployeesForm } from './upload/employee_upload/upload_employees_form';
import { UploadEmployeesProgress } from './upload/employee_upload/upload_employees_progress';
import { useIntl, FormattedMessage } from 'react-intl';

/**
 * Component that displays the upload Employee page.
 *
 * @param {*} props
 *   @param {String} props.organization_id - Id of the organization to retrieve employees for
 */
export const EmployeeUpload = (props) => {
  const {
    processingFile,
    uploading,
    failedFile,
    importedFile
  } = useUploadEmployeesForm(props.organization_id);

  const alerts = useAlerts();
  const intl = useIntl();

  useEffect(() => {
    if (!processingFile && !failedFile && importedFile) {
      alerts.add({
        type: 'notice',
        head: intl.formatMessage({
          id: 'workforce.employees.upload.alert.success.head',
          defaultMessage: 'Success'
        }),
        text: intl.formatMessage({
          id: 'workforce.employees.upload.alert.success.text',
          defaultMessage: 'Your CSV was imported successfully.'
        })
      });
    }
  }, [processingFile && processingFile.id]);

  useEffect(() => {
    if (!processingFile && failedFile) {
      alerts.add({
        type: 'error',
        head: intl.formatMessage({
          id: 'workforce.employees.upload.alert.upload_failed.head',
          defaultMessage: 'Upload failed'
        }),
        text: intl.formatMessage({
          id: 'workforce.employees.upload.alert.upload_failed.text',
          defaultMessage: 'Errors were found while processing your file.'
        })
      });
    }
  }, [processingFile && processingFile.id]);

  const renderContent = () => {
    if (!!processingFile || uploading) {
      return (
        <UploadEmployeesProgress organization_id={props.organization_id}/>
      );
    } else {
      return (
        <UploadEmployeesForm organization_id={props.organization_id}/>
      );
    }
  };

  return (
    <div className="c-employee-upload">
      <p>
        <FormattedMessage
          id="workforce.employee_upload.description"
          defaultMessage="Upload a comma delimited text file (.csv) of up to 5,000 rows to import a list of employees to Credly. Please make sure your CSV file is in the <a>required format</a> and all required fields are completed."
          values={{ a: chunks => <a href="/employee_upload_template">{chunks}</a> }}
        />
      </p>

      {renderContent()}
    </div>
  );
};

EmployeeUpload.propTypes = {
  organization_id: uuidPropType
};
