import React from 'react';
import PropTypes from 'prop-types';
import CredlyRoute from 'controls/credly_route';
import { CredlySwitch } from 'controls/credly_switch';
import { EmployeeRouteHelpers } from 'pages/workforce/route_helpers';
import { EmployeeAdd } from 'pages/workforce/employees/employees/add/employee_add';
import { EmployeeUpload } from 'pages/workforce/employees/employees/add/employee_upload';
import {
  EmployeeOccupationUpload
} from 'pages/workforce/employees/employees/add/employee_occupation_upload';
import { Redirect } from 'react-router';
import { useIntl } from 'react-intl';
import { Permissions, usePermissions } from 'management/organizations/permissions';

/**
 * Routes for the "Employees" section of Employers
 *
 * @param props
 *   @param {string} props.baseUrl - the base URL of the employees subsection of Employers
 *   @param {Object} props.organization
 * @returns {React.element}
 */
export const Routes = (props) => {
  const intl = useIntl();
  const routeHelpers = EmployeeRouteHelpers.forUrl(props.baseUrl);
  const [hasPermissions] = usePermissions();

  return (
    <CredlySwitch>
      <Redirect
        from={routeHelpers.employeeAdd.route()}
        exact
        to={routeHelpers.employeeAddSingle.route()}
      />
      <CredlyRoute
        path={routeHelpers.employeeAddSingle.route()}
        render={(routeProps) => (
          <EmployeeAdd
            baseUrl={routeProps.match.url}
            organization={props.organization}
            routeProps={routeProps}
          />
        )}
        title={intl.formatMessage({
          id: 'workforce.employees.add.title.single',
          defaultMessage: 'Add Employee(s) - Single'
        })}
      />
      <CredlyRoute
        path={routeHelpers.employeeAddBulk.route()}
        render={(routeProps) => (
          <EmployeeUpload
            baseUrl={routeProps.match.url}
            organization_id={props.organization.id}
            routeProps={routeProps}
            key="create"
          />
        )}
        title={intl.formatMessage({
          id: 'workforce.employees.add.title.bulk',
          defaultMessage: 'Add Employee(s) - Bulk'
        })}
      />
      {hasPermissions(Permissions.PLATFORM_MANAGER) && (
        <CredlyRoute
          path={routeHelpers.employeeUpdateBulk.route()}
          render={(routeProps) => {
            return (
              <EmployeeOccupationUpload
                baseUrl={routeProps.match.url}
                organization_id={props.organization.id}
                routeProps={routeProps}
                key="update"
              />
            );
          }}
          title={intl.formatMessage({
            id: 'workforce.employees.add.title.occupation_upload',
            defaultMessage: 'Occupation Upload'
          })}
        />
      )}
    </CredlySwitch>
  );
};

Routes.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired
};
