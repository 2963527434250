import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { uuidPropType } from 'prop_types';
import { combineLoaders } from 'utils/combine_loaders';
import dateUtils from 'utils/date';
import { standardAggregators } from 'charts/timeseries';
import {
  useFaethmOccupationMappingEnabled
} from 'pages/workforce/faethm_occupation_mapping_hooks';
import { Chiclet, ChicletGroup, ChicletLoading } from 'pages/workforce/components/chiclet';
import { Donut, DonutLoading } from 'pages/workforce/components/charts/donut';
import { InfoModal } from 'pages/workforce/components/info_modal';
import { TrendChart } from 'pages/workforce/organizations';
import { employerStatsHistoryActions } from 'pages/workforce/employer_stats_actions';

import './dashboard.sass';

const DONUT_COLORS = {
  accepted: '#005850',
  pending: '#D1EAEA',
  rejected: '#F7DE8D'
};

const DONUT_LABELS_I18N_MAP = {
  accepted: {
    id: 'workforce.analytics.accepted_employees_label',
    defaultMessage: 'Accepted employees'
  },
  pending: {
    id: 'workforce.analytics.pending_employees_label',
    defaultMessage: 'Pending employees'
  },
  rejected: {
    id: 'workforce.analytics.rejected_employees_label',
    defaultMessage: 'Rejected employees'
  }
};

const EMPTY_EMPLOYER_STAT = {
  date: '000-01-01',
  total_accepted: 0,
  total_pending: 0,
  total_rejected: 0,
  total_badges: 0,
  total_skills_by_earner: 0
};

/**
 * Renders the Analytics dashboard within Workforce.
 *
 * @param props
 *   @param {string} props.organizationId - the ID of the current Workforce organization context
 * @returns {JSX.Element}
 * @constructor
 */
export const Dashboard = (props) => {
  const [employerStatsHistory] = employerStatsHistoryActions.useOnMount(
    'past_year', '', { organization_id: props.organizationId }
  );
  const [lastMonthEmployerStatsHistory] = employerStatsHistoryActions.useOnMount(
    'last_month', props.organizationId
  );

  const intl = useIntl();
  const occupationMappingEnabled = useFaethmOccupationMappingEnabled();

  const loadingStatus = combineLoaders(
    employerStatsHistory.status, lastMonthEmployerStatsHistory.status
  );

  const transformedData = useMemo(() => (
    (employerStatsHistory.resources || []).map((datum) => ({
      ...datum, interval: dateUtils.formatISO(datum.date)
    }))
  ), [employerStatsHistory.resources]);

  const mostRecent = useMemo(() => {
    return employerStatsHistory.resources.reduce((curMax, nextVal) => {
      if (curMax.date < nextVal.date) {
        return nextVal;
      } else {
        return curMax;
      }
    }, EMPTY_EMPLOYER_STAT);
  }, [employerStatsHistory.resources]);

  const { data: invitedEmployeesData, categoryColors } = useTranslatedDonutLabels(mostRecent);

  const badgesTitle = intl.formatMessage({
    id: 'workforce.analytics.badges_title',
    defaultMessage: 'Badges'
  });
  const acceptancesTitle = intl.formatMessage({
    id: 'workforce.analytics.acceptances_title',
    defaultMessage: 'Acceptances'
  });
  const skillsTitle = intl.formatMessage({
    id: 'workforce.analytics.skills_title',
    defaultMessage: 'Skills'
  });

  const commonChartProps = {
    data: transformedData,
    dataAggregator: standardAggregators.mean.rounded,
    totalAggregator: standardAggregators.last,
    loading: employerStatsHistory.status.pending || employerStatsHistory.status.idle
  };

  return (
    <div className="workforce-analytics-dashboard col">
      <div className="workforce-analytics-dashboard__summary row">
        <div className="col-lg-7 col-12">
          <div className="workforce-analytics-dashboard__accepted-employees">
            {
              (employerStatsHistory.status.pending || employerStatsHistory.status.idle)
                ? <DonutLoading/>
                : (
                  <Donut
                    data={invitedEmployeesData}
                    categoryColors={categoryColors}
                    highlightedCategory={intl.formatMessage(DONUT_LABELS_I18N_MAP.accepted)}
                    highlightedCategoryLabel={{
                      id: 'workforce.analytics.accepted_employees_chart_title',
                      defaultMessage: '<line>{percentage, number, ::percent}</line>' +
                        '<line>Employees</line><line>accepted</line>'
                    }}
                    omitEmptyCategories={[intl.formatMessage(DONUT_LABELS_I18N_MAP.rejected)]}
                    totalLabel="Total invited employees"
                  />
                )
            }
          </div>
        </div>
        <ChicletGroup layout="stacked" className="col-lg-5 col-12">
          {
            (loadingStatus.idle || loadingStatus.pending)
              ? (
                <>
                  <ChicletLoading/>
                  <ChicletLoading/>
                </>
              ) : (
                <>
                  <Chiclet
                    displayDifference={{
                      // eslint-disable-next-line camelcase
                      total: mostRecent.total_skills_by_earner,
                      // eslint-disable-next-line camelcase
                      previousTotal: lastMonthEmployerStatsHistory.resources?.total_skills_by_earner
                    }}
                    label="Skills"
                    tooltip={
                      occupationMappingEnabled
                        ? intl.formatMessage({
                          defaultMessage: 'Total number of skills held by your employees, ' +
                            'both Verified and Occupation Skills.',
                          id: 'workforce.analytics.skills_chiclet_explanation_l3'
                        }) : intl.formatMessage({
                          defaultMessage: 'Total number of skills held by your employees.',
                          id: 'workforce.analytics.skills_chiclet_explanation_l2'
                        })
                    }
                  />
                  <Chiclet
                    displayDifference={{
                      // eslint-disable-next-line camelcase
                      total: mostRecent.total_badges,
                      // eslint-disable-next-line camelcase
                      previousTotal: lastMonthEmployerStatsHistory.resources?.total_badges
                    }}
                    label="Badges"
                    tooltip={intl.formatMessage({
                      defaultMessage: 'Total number of badges your employees have earned to ' +
                        'date. This includes all badges that you know of. Your employees may ' +
                        'have more - to learn more, send them an invitation, or remind them to ' +
                        'accept their invitation.',
                      id: 'workforce.analytics.badges_chiclet_explanation'
                    })}
                  />
                </>
              )
          }
        </ChicletGroup>
      </div>
      <TrendChart
        {...commonChartProps}
        itemLabel={(items) => (
          <FormattedMessage
            id="workforce.analytics.acceptances_item_label"
            defaultMessage={(
              '{items, plural, one {{items, number} Acceptance} ' +
              'other {{items, number} Acceptances}}'
            )}
            values={{ items }}
          />
        )}
        title={(
          <div className="workforce-analytics-dashboard__line-chart-title">
            {acceptancesTitle}
            <InfoModal
              title={acceptancesTitle}
              body={(
                <div className="workforce-analytics-dashboard__line-chart-explanation">
                  <FormattedMessage
                    id="workforce.analytics.acceptances_explanation"
                    defaultMessage="This line graph shows the total number of accepted employees over time."
                  />
                </div>
              )}
            />
          </div>
        )}
        yKey="total_accepted"
      />
      <TrendChart
        {...commonChartProps}
        itemLabel={(items) => (
          <FormattedMessage
            id="workforce.analytics.skills_item_label"
            defaultMessage={(
              '{items, plural, one {{items, number} Skill} ' +
              'other {{items, number} Skills}}'
            )}
            values={{ items }}
          />
        )}
        title={(
          <div className="workforce-analytics-dashboard__line-chart-title">
            {skillsTitle}
            <InfoModal
              title={skillsTitle}
              body={(
                <div className="workforce-analytics-dashboard__line-chart-explanation">
                  <FormattedMessage
                    id="workforce.analytics.skills_explanation"
                    defaultMessage={(
                      'This line graph displays the total unique skills attained ' +
                      'by your accepted employees over time.'
                    )}
                  />
                </div>
              )}
            />
          </div>
        )}
        yKey="total_skills_by_earner"
      />
      <TrendChart
        {...commonChartProps}
        itemLabel={(items) => (
          <FormattedMessage
            id="workforce.analytics.badges_item_label"
            defaultMessage={(
              '{items, plural, one {{items, number} Badge} ' +
              'other {{items, number} Badges}}'
            )}
            values={{ items }}
          />
        )}
        title={(
          <div className="workforce-analytics-dashboard__line-chart-title">
            {badgesTitle}
            <InfoModal
              title={badgesTitle}
              body={(
                <div className="workforce-analytics-dashboard__line-chart-explanation">
                  <FormattedMessage
                    id="workforce.analytics.badges_explanation"
                    defaultMessage={(
                      'This line graph displays the total number of badges your employees have ' +
                      'earned over time. This includes all badges that you know of. Your ' +
                      'employees may have more - to learn more, send them an invitation, or ' +
                      'remind them to accept their invitation.'
                    )}
                  />
                </div>
              )}
            />
          </div>
        )}
        yKey="total_badges"
      />
    </div>
  );
};

Dashboard.propTypes = {
  organizationId: uuidPropType
};

/**
 * Internal helper function that returns data and color scheme for the donut chart with labels
 * translated into current locale
 *
 * @param {{total_accepted:number,total_pending:number,total_rejected:number}} mostRecent -
 *   data from the most recent EmployerDailyStat snapshot
 * @returns {{data: [{x: string, y: (number|*)},{x: string, y}], categoryColors: {}}}
 */
const useTranslatedDonutLabels = (mostRecent) => {
  const intl = useIntl();
  const data = useMemo(() => {
    const result = [
      { x: intl.formatMessage(DONUT_LABELS_I18N_MAP.accepted), y: mostRecent.total_accepted },
      { x: intl.formatMessage(DONUT_LABELS_I18N_MAP.pending), y: mostRecent.total_pending }
    ];
    if (mostRecent.total_rejected > 0) {
      result.push({
        x: intl.formatMessage(DONUT_LABELS_I18N_MAP.rejected), y: mostRecent.total_rejected
      });
    }
    return result;
  }, [intl, mostRecent]);

  const categoryColors = useMemo(() => {
    return Object.keys(DONUT_COLORS).reduce((result, key) => {
      result[intl.formatMessage(DONUT_LABELS_I18N_MAP[key])] = DONUT_COLORS[key];
      return result;
    }, {});
  }, [intl]);

  return { data, categoryColors };
};
