import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useRaiseOnFailedRequest, useOnOff } from "utils/react_utils";
import { SimpleButton } from "controls/button";
import Dialog from "controls/dialog";
import { LoadingSpinner } from "controls/loading_spinner";
import { Form, FormFieldWrapper } from "form";
import { NewCountTag } from "./saved_directory_searches/new_count_tag";
import { savedDirectorySearchActionManager } from "./saved_directory_searches/actions";
import { SavedDirectorySearchRouteHelpers } from "./saved_directory_searches/utils";

import "./saved_directory_searches_button.sass";

export const SavedDirectorySearchesButton = (props) => {
  const [showDialog, openDialog, closeDialog] = useOnOff(false);

  return (
    <>
      <div className={props.className}>
        <SimpleButton
          mobileSize="auto"
          type="filter"
          onClick={openDialog}
        >
          Saved Searches
        </SimpleButton>
        <Dialog show={showDialog} shouldClose={closeDialog} title="Saved Searches" size="half">
          <Content {...props} />
        </Dialog>
      </div>
    </>
  );
};

const Content = (props) => {
  const { savedDirectorySearch } = props;
  const history = useHistory();
  const [selectedSavedSearchID, setSelectedSavedSearchID] = useState(savedDirectorySearch?.id);

  const handleApply = () => {
    if (selectedSavedSearchID === savedDirectorySearch?.id) {
      return;
    }

    const route = SavedDirectorySearchRouteHelpers.showSavedDirectorySearch.parameterize({
      id: selectedSavedSearchID
    });
    history.push(route);
  };

  const [savedDirectorySearchList] =
    savedDirectorySearchActionManager.useOnMount("list");
  const status = savedDirectorySearchList.status;
  const resources = savedDirectorySearchList.resources;

  useRaiseOnFailedRequest(status);

  return (
    <>
      <Dialog.Content>
        {status.pending && resources.length === 0 &&
          <LoadingSpinner delay={500}/>
        }
        {resources.length > 0 &&
          <FormFieldWrapper>
            <Form.RadioSet
              handleChange={(name, val) => setSelectedSavedSearchID(val)}
              name="saved-search"
              value={selectedSavedSearchID}
              ariaLabel="Saved directory searches"
            >
              <div className="saved-directory-searches-button__fieldset">
                {resources.map((savedSearch, idx) => {
                  return (
                    <Form.Radio
                      key={idx}
                      value={savedSearch.id}
                      label={
                        <div className="saved-directory-searches-button__item-row">
                          <div>{savedSearch.name}</div>
                          {savedSearch.displayable_matches &&
                            <NewCountTag
                              className="saved-directory-searches-button__new-count-tag"
                              value={savedSearch.new_candidates}
                            />
                          }
                        </div>
                      }
                    />
                  );
                })}
              </div>
            </Form.RadioSet>
          </FormFieldWrapper>
        }
        {status.succeeded && resources.length === 0 &&
          <div>No saved searches</div>
        }
      </Dialog.Content>
      <Dialog.Footer>
        <Dialog.Action action={handleApply} disabled={!selectedSavedSearchID}>
          Apply
        </Dialog.Action>
      </Dialog.Footer>
    </>
  );
};

SavedDirectorySearchesButton.propTypes = {
  savedDirectorySearch: PropTypes.object
};
