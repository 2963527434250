import React from 'react';
import { useHistory } from 'react-router';
import { useBrowserQueryString } from 'controls/with_query_string';
import SortSelector from 'controls/sort_selector';

/**
 * Component displays the Sort Selector with properties specific to Directory
 */
export const DirectorySortSelector = () => {
  const queryString = useBrowserQueryString();
  const history = useHistory();
  const sort = React.useCallback(type => {
    const newQuery = queryString.clone();
    newQuery.removeKey('page').set('sort', type);
    history.replace({ search: newQuery.toString() });
  }, [queryString]);
  return (
    <SortSelector
      onSort={sort}
      selectedSortValue={queryString.get('sort') || '-activity_date'}
      sortValues={[
        { displayValue: 'Recent Activity', sortValue: '-activity_date' },
        { displayValue: 'Name', sortValue: 'alphabetical' }
      ]}
    />
  );
};
