import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useShallowEquals, useEffectExceptOnMount } from 'utils/react_utils';
import { useBrowserQueryString } from 'controls/with_query_string';
import { makeClassName } from 'utils';
import { filter } from '../earner_directory_actions';

import './directory_layout.sass';

/**
 * Component that handles some basic logic in fetching directory results and
 * resetting them if needed. Renders child components in outer container.
 * @param {Object} props
 *   @param {Object} props.baseParams - Query params to pass through in all API requests.
 *   @param {String} [props.className] - Optional class to apply to layout container
 *   @param {String} [suppressFetch] - Set to true to not fetch results.
 *   Useful for wanting to display some other visual besides the results in which
 *   case fetching results on a query string update or route update is not needed
 */
export const DirectoryLayout = (props) => {
  const queryString = useBrowserQueryString();
  const [filterState, performFilterAction] = props.filterAction.useAction();
  const params = useShallowEquals({
    ...props.baseParams,
    ...queryString.get()
  });
  useEffect(() => {
    if (!props.suppressFetch) {
      performFilterAction(params);
    }
  }, [props.suppressFetch, params]);
  const history = useHistory();
  // If the user has a saved bookmark with a page number that's too high for the results, and
  // the number of results happens to be less than 8, there will be no pagination UI, so the user
  // inaccessible, page. Avoid this case by always redirecting to page 1 when there are no results
  useEffectExceptOnMount(() => {
    if (
      filterState.status.succeeded &&
      filterState.resources.length === 0 &&
      filterState.metadata.current_page !== 1
    ) {
      history.replace({ search: queryString.removeKey('page').toString() });
    }
  }, [filterState.status.succeeded, filterState.resources, filterState.metadata]);
  return (
    <div className={makeClassName("c-directory-layout", props.className)}>
      {props.children}
    </div>
  );
};

DirectoryLayout.propTypes = {
  baseParams: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  suppressFetch: PropTypes.bool,
  filterAction: PropTypes.object
};

DirectoryLayout.defaultProps = {
  filterAction: filter
};
