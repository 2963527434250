import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uuidPropType } from 'prop_types';
import { useLocation, useHistory } from 'react-router';
import { SimpleButton } from 'controls/button';
import { useQueryString } from 'controls/with_query_string';
import Icon from 'controls/icon';
import Dialog from 'controls/dialog';
import { faSlidersH } from '@fortawesome/pro-regular-svg-icons/faSlidersH';
import { employeeFilterOptionAction } from 'pages/workforce/employees/employees_actions';
import { useRaiseOnFailedRequest } from 'utils/react_utils';
import { EmployeeFilterSection } from './employee_filter_section';
import { FormattedMessage, useIntl } from 'react-intl';

import './employee_filters.sass';

const FILTER_OPTION_KEY_NAME_MAPPING = {
  invitation_status: 'Invitation Status',
  level: 'Badge Level',
  type_category: 'Badge Type'
};

/**
 * Component that manages displaying a modal Dialog to select or deselect filters
 * on the EmployeeList page.
 *
 * @param {Object} props
 *   @param {function(filters: Object)} props.applyFilters - A callback function that will be
 *   called when saving the selected filters on the Dialog modal.
 *   @param {String} props.organization_id - The organization ID.
 *   @param {Object} props.filters - The current filter state. Temporarily selected
 *   or deselected filters are kept track of as part of the state of this component and
 *   will be communicated back to the parent component via applyFilters callback
 *   @param {array} props.allowedOptions - array of keys of desired filters to show
 */
export const EmployeeFilters = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const queryString = useQueryString(location);

  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [employeesFilterOptions] = employeeFilterOptionAction.useOnMount(
    { organization_id: props.organization_id }
  );

  const dialogTitle = intl.formatMessage({
    id: 'employee_list.filters.dialog_title',
    defaultMessage: 'Filter'
  });

  const loadingMessage = intl.formatMessage({
    id: 'employee_list.filters.loading_options',
    defaultMessage: 'Loading filter options...'
  });

  const noFilterOptionsMessage = intl.formatMessage({
    id: 'employee_list.filters.no_options',
    defaultMessage: 'No filter options found.'
  });

  const orValue = (filters) => {
    return Object.keys(filters).filter(val => val !== 'or' && filters[val] && filters[val].length > 0);
  };

  const filterOptionKeyName = (filterOptionKey) => {
    return intl.formatMessage({
      id: `employee_list.filters.option_key_name.${filterOptionKey}`,
      defaultMessage: FILTER_OPTION_KEY_NAME_MAPPING[filterOptionKey]
    });
  };

  const applyFilters = (selectedFilters) => {
    const currentFilters = queryString.get('filter');
    const newFilters = { ...currentFilters, ...selectedFilters };

    queryString.set('filter', newFilters);
    queryString.set('page', 1);
    history.push({ search: queryString.toString() });
  };

  useRaiseOnFailedRequest(
    employeesFilterOptions.status,
    `EmployeeFilterOptions ${props.organization_id}`
  );

  const initialSelectedFilters = (filters, filterOptions) => {
    const filterOptionKeys = Object.keys(filterOptions);
    return filterOptionKeys.reduce((accumulator, currentFilterOptionKey) => {
      if (Object.prototype.hasOwnProperty.call(filters, currentFilterOptionKey)) {
        return { ...accumulator, [currentFilterOptionKey]: filters[currentFilterOptionKey] };
      } else {
        return { ...accumulator, [currentFilterOptionKey]: [] };
      }
    }, {});
  };

  const onFilterClick = (filterKey, filterType, filterValue) => {
    setSelectedFilters(
      oldSelectedFilters => {
        let filters = {};

        if (filterType === 'list') {
          filters = { ...oldSelectedFilters, [filterKey]: filterValue };
        }

        return { ...filters, or: orValue(filters) };
      }
    );
  };

  const clearFilters = () => {
    const clearedSelectedFilters =
      Object.keys(selectedFilters).reduce((accumulator, currentValue) => {
        return { ...accumulator, [currentValue]: [] };
      }, {});
    setSelectedFilters(clearedSelectedFilters);
  };

  const onClose = () => {
    if (employeesFilterOptions.status.succeeded) {
      const filterOptions = employeesFilterOptions.resources[0];
      setSelectedFilters(initialSelectedFilters(props.filters, filterOptions));
    }
    setShowFiltersModal(false);
  };

  useEffect(() => {
    const [resource] = employeesFilterOptions.resources;
    if (employeesFilterOptions.status.succeeded && resource) {
      setSelectedFilters(initialSelectedFilters(props.filters, resource));
    }
  }, [employeesFilterOptions.status, props.filters, setSelectedFilters, employeesFilterOptions.resources]);


  const dialogContent = () => {
    if (employeesFilterOptions.status.pending || Object.keys(selectedFilters).length === 0) {
      return <div data-testid="loading-message">{loadingMessage}</div>;
    }

    const filterOptions = employeesFilterOptions.resources?.[0] || {};
    const { id: _, ...filteredOptionsWithoutId } = filterOptions;

    const renderFilterSection = (filterOptionKey) => {
      if (props.allowedOptions.includes(filterOptionKey) && filterOptions[filterOptionKey]?.length > 0) {
        return (
          <EmployeeFilterSection
            key={filterOptionKey}
            sectionType="list"
            displayName={filterOptionKeyName(filterOptionKey)}
            filterOptionKey={filterOptionKey}
            filterOptions={filterOptions[filterOptionKey]}
            i18nPrefix="employee_list.filters.option_value"
            onFilterClick={onFilterClick}
            selectedFilters={selectedFilters[filterOptionKey]}
          />
        );
      }
    };

    return (
      <div className="c-employee-filters-inner">
        {Object.keys(filteredOptionsWithoutId).map(renderFilterSection)}
        {Object.keys(filteredOptionsWithoutId).length === 0 && <div>{noFilterOptionsMessage}</div>}
      </div>
    );
  };

  return (
    <>
      <SimpleButton type="tertiary" onClick={() => setShowFiltersModal(true)}>
        <div className="c-employee-filters-content">
          <FormattedMessage
            id="employee_list.filters.dialog_title"
            defaultMessage="Filter"
          />
          <Icon
            image={faSlidersH}
            className="c-employee-filters__icon"
          />
        </div>
      </SimpleButton>
      <Dialog
        allowScroll
        title={dialogTitle}
        size="half"
        shouldClose={onClose}
        show={showFiltersModal}
      >
        <Dialog.Content noScroll>
          {dialogContent()}
        </Dialog.Content>
        <Dialog.Footer>
          <Dialog.Action noClose action={clearFilters} type="secondary">
            <FormattedMessage
              id="employee_list.filters.reset"
              defaultMessage="Reset"
            />
          </Dialog.Action>
          <Dialog.Action action={() => applyFilters(selectedFilters)}>
            <FormattedMessage
              id="employee_list.filters.apply"
              defaultMessage="Apply"
            />
          </Dialog.Action>
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

EmployeeFilters.propTypes = {
  organization_id: uuidPropType,
  filters: PropTypes.object,
  allowedOptions: PropTypes.array
};

EmployeeFilters.defaultProps = {
  allowedOptions: []
};
