export const COLORS = {
  darkDark: '#2e2e2e',
  darkMedium: '#4e4e4e',
  lightGreen: 'rgba(141, 207, 208, 0.2)',
  lightDark: '#c2c2c2',
  lightLight: '#ffffff',
  lightLightAccent: '#efefef',
  mediumGreen: '#004438',
  pinkishGrey: '#8d8c8f'
};

export const HEADING_FONT_FAMILY = 'Grad, Georgia, serif';
export const FONT_FAMILY = "'Open Sans', Arial, sans-serif";
