import React from 'react';
import PropTypes from 'prop-types';
import { EllipsisMenu } from 'controls/ellipsis_menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeDot } from '@fortawesome/pro-regular-svg-icons/faEnvelopeDot';
import { faFileExport } from '@fortawesome/pro-regular-svg-icons/faFileExport';
import { useIntl } from 'react-intl';
import { Permissions, usePermissions } from 'management/organizations/permissions';
import { useFaethmOccupationMappingEnabled } from 'pages/workforce/faethm_occupation_mapping_hooks';

import './employee_directory_dropdown.sass';

/**
 * Component used to render a more ("...") button. Once clicked, a dropdown menu will be rendered.
 * Dropdown menu items are rendered with a value property containing a useToggle function used
 * to show the modal tied to the item.
 *
 * Once a Menu Item is clicked, EllipsisMenu onSelect is triggered which calls the corresponding
 * Item's useToggle function contained in its value property.
 *
 * @param {Boolean} exportEnabled - Displays an export button to export results if enabled.
 * @param {Boolean} inviteEmployeesEnabled - Used to render 'Invite Employees' menu item depending
 * on selected filters.
 * @param {Boolean} exportOccupationsEnabled - Displays an export button to export occupation
 * mapping information for validation.
 * @param {Function} toggleDisplayExport - Parent useToggle function to show Export modal when Export
 * menu item is clicked.
 * @param {Function} toggleDisplayInvite - Parent useToggle function to show Employee Invitation modal when Invite
 * Employees menu item is clicked.
 * @param {Function} toggleDisplayExportOccupations - Parent useToggle function to show Export
 * Occupations modal when menu item is clicked.
 */
export const EmployeeDirectoryDropdown = ({
  exportEnabled,
  inviteEmployeesEnabled,
  exportOccupationEnabled,
  toggleDisplayExport,
  toggleDisplayInvite,
  toggleDisplayExportOccupations
}) => {
  const intl = useIntl();
  const faethmOccupationEnabled = useFaethmOccupationMappingEnabled();
  const [hasPermissions] = usePermissions();
  const dropdownItems = [
    {
      itemIcon: faFileExport,
      itemClassName: 'ac-file-export__icon',
      enabled: exportEnabled,
      value: toggleDisplayExport,
      label: intl.formatMessage({
        id: 'workforce.employees.list.menu.export_employees',
        defaultMessage: 'Export Employees'
      })
    },
    hasPermissions(Permissions.EMPLOYMENT_UPDATE) && {
      itemIcon: faEnvelopeDot,
      itemClassName: 'ac-envelope-dot__icon',
      enabled: inviteEmployeesEnabled,
      value: toggleDisplayInvite,
      label: intl.formatMessage({
        id: 'workforce.employees.list.menu.invite_employees',
        defaultMessage: 'Invite Employees'
      })
    },
    faethmOccupationEnabled && hasPermissions(Permissions.PLATFORM_MANAGER) && {
      itemIcon: faFileExport,
      itemClassName: 'ac-file-export__icon',
      enabled: exportOccupationEnabled,
      value: toggleDisplayExportOccupations,
      label: intl.formatMessage({
        id: 'workforce.employees.list.menu.export_occupation',
        defaultMessage: 'Export for Mapping'
      })
    }
  ].filter(e => e);

  return (
    <EllipsisMenu
      onSelect={(openModalFunc) => openModalFunc()}
      appearance="enclosed"
      containerClassName="c-workforce-employee-directory-list__more-button"
    >
      {dropdownItems.map((item, index) =>
        <EllipsisMenu.Item key={index} value={item.value} disabled={!item.enabled}>
          <FontAwesomeIcon icon={item.itemIcon} className={item.itemClassName}/>
          {item.label}
        </EllipsisMenu.Item>
      )}
    </EllipsisMenu>
  );
};

EmployeeDirectoryDropdown.propTypes = {
  toggleDisplayExport: PropTypes.func,
  toggleDisplayInvite: PropTypes.func,
  toggleDisplayExportOccupations: PropTypes.func,
  exportEnabled: PropTypes.bool,
  inviteEmployeesEnabled: PropTypes.bool,
  exportOccupationEnabled: PropTypes.bool
};

