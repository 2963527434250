/**
 * Change the current pathname. Using this function instead of setting pathname directly will
 * make code easier to test.
 *
 * @param {String} path - The path name, starting with slash.
 * @returns {String}
 */
export const setPath = path => { document.location.pathname = path; };
export const setHref = href => { document.location.href = href; };
export const reload = () => document.location.reload(true);
export const currentHref = () => document.location.href;
export const currentPath = () => document.location.pathname;
export const currentSearch = () => document.location.search;

export const API_BASE = '/api/v1';
export const DEFAULT_URL = '/earner/dashboard';
export const ROOT_PATH = '/';

/**
 * Util for enforcing urls to be relative to the host. This is mainly to ensure when
 * redirecting or opening urls that could be defined by 3rd party entities that the user
 * will never end up opening a url to some other domain perhaps set by a nefarious entity.
 * @param url
 * @returns {string|string}
 */
export const asRelativeUrl = (url) => {
  try {
    const parsedUrl = new URL(url, document.location.origin);
    return `${parsedUrl.pathname}${parsedUrl.search}`;
  } catch (_err) {
    // polyfill for old browsers that don't have URL
    if (typeof URL !== 'function') {
      const fallbackParsed = (url || '').match(/^\/[/a-zA-Z0-9_-]+$/);
      if (fallbackParsed) {
        return fallbackParsed[0] || '';
      }
    }
  }
};

export const badgeTemplateUrl = (organization, badgeTemplate) => {
  return `${organization.vanity_url}/badge/${badgeTemplate.vanity_slug}`;
};

export const PublicBadgeTemplateUrl = (organizationSlug, badgeTemplateSlug) => {
  return `/org/${organizationSlug}/badge/${badgeTemplateSlug}`;
};

export const badgeTemplateFromIdUrl = (badgeTemplateId) => {
  return `/badge_templates/${badgeTemplateId}`;
};

export const badgeTemplateLmiUrl = (badgeTemplate) => {
  return `${badgeTemplate.url.replace(document.location.origin, '')}/lmi`;
};

export const createOrganizationUrl = () => '/create_organization';

export const directoryExportDownloadUrl = (id) => `/directory_exports/${id}/download`;
export const badgeExportDownloadUrl = (id) => `/badge_exports/${id}/download`;
export const occupationExportDownloadUrl = (id) => `/occupation_exports/${id}/download`;

export const earnerPdfUrl = (orgId, badgeId) => (
  `/organizations/${orgId}/pdf_generator/template/print/${badgeId}`
);

export const earnerDashboardUrl = () => '/earner/dashboard';

export const earnerBadgesUrl = () => '/earner/earned';

export const editAccountUrl = () => '/earner/settings/account';

export const editProfileUrl = () => '/earner/settings/profile';

export const editPreferencesUrl = () => '/earner/settings/preferences';

export const editOrganizationsUrl = () => '/earner/settings/organizations';

export const editConnectedApplicationsUrl = () => '/earner/settings/applications';

export const badgeUrl = id => `/badges/${id}`;

export const earnedBadgeUrl = id => `/earner/earned/badge/${id}`;

export const publicBadgeUrl = id => `/badges/${id}/public_url`;

export const organizationVanityUrl = (vanitySlug) => `/org/${vanitySlug}`;

export const userVanityUrl = (vanitySlug) => `/users/${vanitySlug}/badges`;

export const userVanityUrlForceLogin = (vanitySlug) => `/users/${vanitySlug}/badges/sign_in`;

export const deleteProfileUrl = () => (
  '/earner/settings/profile/confirm-profile-delete'
);

export const jobsBySkillUrl = (skillVanity) => (
  `/jobs/by_skill/${encodeURIComponent(skillVanity)}`
);

export const jobsBySkillNameUrl = (skillName) => (
  `/jobs/by_skill_name/${encodeURIComponent(skillName)}`
);

export const userRemovedUrl = () => '/users/profile/removed';

export const signinUrl = (badgeId, isSSO) => {
  const baseUrl = badgeId ? `/badges/${badgeId}/accept_via_sign_in` : '/users/sign_in';
  return isSSO ? `${baseUrl}?sso=1` : baseUrl;
};
export const signupUrl = badgeId =>
  badgeId ? `/badges/${badgeId}/accept_via_sign_up` : '/users/sign_up';
export const chooseSignupUrl = badgeId => `/badges/${badgeId}/accept_via_choose_sign_up`;
export const ssoSignupUrl = () => '/users/registrations/sso';
export const isSigninUrl = url => (
  /users\/sign_|accept_via_sign|users\/[^/]+\/transcripts\/[^/]+\/download_via_sign|users\/sessions/.test(url)
);
export const twoFactorAuthUrl = () => '/users/sessions/two_factor_authentication';
export const almostDone = `/registrations/almost_done`;

export const authorizeAppUrl = (key, rerequest = false, returnPath = document.location.pathname) =>
  `/application_providers/${key}_application_provider/authorization_request?` +
  `redirect_to_target=${encodeURIComponent(returnPath)}${rerequest ? '&rerequest=true' : ''}`;

export const linkedInProfile = badgeId =>
  `${API_BASE}/users/self/badges/${badgeId}/social_shares/linkedin_profile`;

export const shareToSeekUrl = badgeId =>
  `${API_BASE}/users/self/badges/${badgeId}/social_shares/share_to_seek`;

export const occupationUrl = (occupationId) => `/occupations/${occupationId}/details`;

export const skillsInsightsUrl = (skillName) => `/skills/${skillName}`;

export const blockcertDownloadUrl = (badgeId, certId) =>
  `/badges/${badgeId}/blockcerts/${certId}/download_btc_blockcert`;

/**
 * Returns the absolute URL (to trigger open-in-new-tab behaviors) to the API documentation.
 *
 * @returns {string}
 */
export const docsUrl = () => _full('/docs');

/**
 * Returns the path to the support page/redirect.
 *
 * @returns {string}
 */
export const supportUrl = () => '/support';

/**
 * Returns the path to the support SSO redirect.
 *
 * @returns {string}
 */
export const supportSsoUrl = () => _full('/support/sso');

/**
 * Returns the path to the Skilljar SSO redirect.
 *
 * @returns {string}
 */
export const skilljarSsoUrl = () => _full('/support/skilljar');

export const organizationManagementUrl = {
  root: () => '/mgmt',
  organization: (organizationId) => `${organizationManagementUrl.root()}/organizations/${organizationId}`
};

export const workforceUrl = {
  root: () => '/workforce',
  organization: (organizationId) => `${workforceUrl.root()}/organizations/${organizationId}`
};

/**
 * URLs for pages in the Backbone app (rooted at /profile).
 */
export const profileUrl = {
  /**
   * URL for the details page of a badge template w/in org management.
   *
   * @param organizationId - the organization context in which to present the template
   * @param state - the state of the badge template (mostly only affects "Back" link behavior)
   * @param badgeTemplateId - the ID of the badge template to link to
   * @returns {string}
   */
  badgeTemplateDetails: (organizationId, state, badgeTemplateId) => (
    _full(`/profile/organizations/${organizationId}/badges/${state}/${badgeTemplateId}`)
  ),

  organization: () => organizationManagementUrl.root()
};

export const skillByNameUrl = (skillName) =>
  _full(`/skills_by_name/${encodeURIComponent(skillName)}`);

export const recruitUrl = () => '/recruit';

export const jobBoardUrl = () => '/job_board';
export const jobBoardByStatusUrl = (statusFilter) =>
  `/job_board?statusFilter=${encodeURIComponent(statusFilter)}`;

export const talentMatchUrl = () => '/talent_match';
export const talentMatchJobsByStatusUrl = (statusFilter) =>
  `/talent_match?statusFilter=${encodeURIComponent(statusFilter)}`;

export const createJobRequisitionUrl = () => '/talent_match/job_requisition';

export const editJobRequisitionUrl = (jobReqId) => `/talent_match/job_requisition/${jobReqId}/edit`;

export const jobRequisitionMatchUrl = (jobReqId, candidateId) =>
  `/talent_match/jobs/${jobReqId}/match/${candidateId}`;

export const signOutUrl = () => _full('/users/sign_out');

export const marketingPageRootUrl = () => _full('');

export const collectionsUrl = (organizationId) => `/organizations/${organizationId}/collections`;

export const collectionTemplatesUrl = (orgSlug, collectionSlug) =>
  `/organizations/${orgSlug}/collections/${collectionSlug}/badge_templates`;

export const primaryTemplateUrl = (organizationId, templateId) =>
`/mgmt/organizations/${organizationId}/badges/templates/${templateId}/settings`;

/**
 * Badge sharing urls.
 */
export const shareUrl = {
  /**
   * The sharing UI.
   *
   * @param {String} badgeId
   * @returns {string}
   */
  main: badgeId => `/earner/earned/share/${badgeId}`,

  /**
   * An app provider redirect with return path.
   *
   * @param {String} provider - Provider name, for use in the share return url.
   * @param {String} providerType - Provider type, from the self/application_providers API.
   * @param {String} providerId - Provider id, from the self/application_providers API.
   * @param {String} badgeId - A badge ID.
   * @returns {string}
   */
  appProvider: (provider, providerType, providerId, badgeId) =>
    `/application_providers/${providerType}/authorization_request?redirect_to_target=` +
    encodeURIComponent(
      `${shareUrl.main(badgeId)}?new_connection=${providerId}&provider_id=${providerId}` +
      `&provider=${provider}`
    ),

  /**
   * URL for the external Xing UI.
   *
   * @param {String} badgeId
   * @returns {string}
   */
  xing: badgeId => 'https://www.xing.com/spi/shares/new?url=' +
    encodeURIComponent(_full(badgeUrl(badgeId))),

  /**
   * URL for the external Weibo UI.
   *
   * @param {String} badgeId
   * @returns {string}
   */
  weibo: badgeId => 'https://service.weibo.com/share/share.php?url=' +
    encodeURIComponent(_full(badgeUrl(badgeId))) + '&title=' +
    encodeURIComponent('View my verified achievement on Credly.'),

  /**
   * URL to open the WhatsApp mobile app.
   *
   * @param {String} badgeId
   * @returns {string}
   */
  whatsapp: badgeId => `https://api.whatsapp.com/send?text=${encodeURIComponent(
    _full(`${badgeUrl(badgeId)}/whatsapp`)
  )}`,

  /**
   * URL to open the Facebook Messenger mobile app.
   *
   * @param {String} badgeId
   * @returns {string}
   */
  fbmessenger: badgeId => `fb-messenger://share?link=${encodeURIComponent(
    _full(`${badgeUrl(badgeId)}/facebook_messenger`)
  )}`,

  ziprecruiter: () => initialAppValues.share.ziprecruiter.url
};

/**
 * Academic transcript URLs
 */
export const transcriptUrl = {
  /**
   * URL to confirm a draft TranscriptDistribution (i.e. confirm send)
   * @param {string} transcriptId - ID of the TranscriptDistribution to confirm
   * @returns {string}
   */
  confirm: (transcriptId) => `/earner/transcript/${transcriptId}/confirm`,

  /**
   * URL to download a transcript that has been shared with you.
   * @param {string} userVanitySlug - the vanity slug of the user who sent the transcript
   * @param {string} transcriptRecipientId - the ID of the TranscriptDistributionRecipient that
   *   corresponds to the email sent to the user attempting to access the transcript
   */
  download: (userVanitySlug, transcriptRecipientId) => (
    `/users/${userVanitySlug}/transcripts/${transcriptRecipientId}`
  ),

  /**
   * URL to create an account to download a transcript that has been shared with you
   * @param {string} userVanitySlug - the vanity slug of the user who sent the transcript
   * @param {string} transcriptRecipientId - the ID of the TranscriptDistributionRecipient that
   *   corresponds to the email sent to the user attempting to access the transcript
   */
  downloadViaSignup: (userVanitySlug, transcriptRecipientId) => (
    `/users/${userVanitySlug}/transcripts/${transcriptRecipientId}/download_via_sign_up`
  ),

  /**
   * URL to sign in to download a transcript that has been shared with you
   * @param {string} userVanitySlug - the vanity slug of the user who sent the transcript
   * @param {string} transcriptRecipientId - the ID of the TranscriptDistributionRecipient that
   *   corresponds to the email sent to the user attempting to access the transcript
   */
  downloadViaSignin: (userVanitySlug, transcriptRecipientId) => (
    `/users/${userVanitySlug}/transcripts/${transcriptRecipientId}/download_via_sign_in`
  ),

  /**
   * URL to provide employer and position information to download a transcript that has been shared
   * with you
   *
   * @param {string} userVanitySlug - the vanity slug of the user who sent the transcript
   * @param {string} transcriptRecipientId - the ID of the TranscriptDistributionRecipient that
   *   corresponds to the email sent to the user attempting to access the transcript
   */
  downloadViaUpdateProfile: (userVanitySlug, transcriptRecipientId) => (
    `/users/${userVanitySlug}/transcripts/${transcriptRecipientId}/download_via_update_profile`
  ),

  /**
   * URL to edit a draft TranscriptDistribution
   * @param {string} transcriptId - ID of the TranscriptDistribution to edit
   * @returns {string}
   */
  edit: (transcriptId) => `/earner/transcript/${transcriptId}/edit`,

  /**
   * URL to create a new TranscriptDistribution (start process of sending academic transcript)
   *
   * @returns {string}
   */
  new: () => '/earner/transcript/new'
};

/**
 * Create a full url from a path.
 *
 * @param {String} path
 * @returns {string}
 * @private
 */
const _full = path => document.location.origin + path;
