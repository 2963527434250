import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'controls/button';
import './form_buttons.sass';

/**
 * Component that contains the LearningPlanForm "Continue" and "Cancel" buttons.
 * "Continue" is used to trigger the LearningPlanForm's confirmation modal for form submission.
 * "Cancel" navigates the user back to the previous page. (for the time being)
 *
 * @param {function} handleCancel - callback function provided by LearningPlanForm to handle
 * click of cancel button
 * @param {boolean} isSubmitting - flag to indicate if the form is currently being submitted
 * @param {boolean} employeeSelected - flag to indicate if the form currently has an employee selected
 * @param {function} toggleConfirmation - function to toggle the confirmation state
 * @returns {JSX.Element} The form buttons
 */

export const FormButtons = ({ isSubmitting, toggleConfirmation, handleCancel, employeeSelected }) => {
  return (
    <>
      <div className="workforce-learning-recommendations-form__buttons-container">
        <Button
          type="secondary"
          onClick={handleCancel}
          className="workforce-learning-recommendations-form__cancel-button"
        >
          <FormattedMessage
            id="workforce.learning.form.cancel_button"
            defaultMessage="Cancel"
          />
        </Button>
        <Button
          type="primary"
          onClick={toggleConfirmation}
          disabled={!employeeSelected || isSubmitting}
          className="workforce-learning-recommendations-form__submit-button"
        >
          <FormattedMessage
            id="workforce.learning.form.continue_button"
            defaultMessage="Continue"
          />
        </Button>
      </div>
    </>
  );
};

FormButtons.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  employeeSelected: PropTypes.bool.isRequired
};
