import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { WithSectionHeader } from 'controls/section_header';
import { AccountsSectionHeader } from "./accounts_section_header";
import { useIntl } from 'react-intl';
import { Routes } from './routes';

import './accounts_layout.sass';

/**
 * Layout for the account workforce page.
 *
 * @param {Object} props
 *  @param {String} props.baseUrl
 *  @param {Object} props.organization
 *
 * @return {JSX.Element}
 */
export const AccountsLayout = ({ baseUrl, organization }) => {
  const intl = useIntl();

  const sectionHeader = useCallback(() => (
    <AccountsSectionHeader baseUrl={baseUrl}/>
  ), [baseUrl]);

  const breadcrumb = {
    action: baseUrl,
    label: intl.formatMessage({
      id: 'workforce.accounts.accounts_layout.breadcrumb',
      defaultMessage: 'Accounts'
    })
  };

  return (
    <WithSectionHeader sectionHeader={sectionHeader} breadcrumb={breadcrumb}>
      <Routes organization={organization} baseUrl={baseUrl}/>
    </WithSectionHeader>
  );
};

AccountsLayout.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired
};
