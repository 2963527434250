import React from 'react';
import PropTypes from 'prop-types';
import CheckLock from 'svg/check-lock.svg';
import { FormattedMessage } from 'react-intl';

import './based_on.sass';

export const BasedOn = props => {
  const firstName = props.employee.first_name || 'this employee';
  const possessiveFirstName = firstName.slice(-1) === 's' ? `${firstName}'` : `${firstName}'s`;

  return (
    <div className="workforce__based_on-panel">
      <CheckLock className="workforce__based_on-panel-lock"/>
      <FormattedMessage
        id="workforce.based-on.tag.label"
        defaultMessage="Based on {possessiveFirstName} occupation"
        values={{ possessiveFirstName }}
      />
    </div>
  );
};

BasedOn.propTypes = {
  employee: PropTypes.object
};
