import { connect } from 'react-redux';
import { employeeSearch } from 'pages/workforce/employees/employees/employee_directory/employee_directory_actions';
import { BaseDirectorySearch } from 'pages/public/organization/earner_directory/base_directory_search';

const EmployeeConnected = connect(
  state => ({
    results: employeeSearch.getResources(state),
    searchStatus: employeeSearch.getStatus(state)
  }),
  dispatch => ({
    search: query => dispatch(employeeSearch.action(query))
  })
)(BaseDirectorySearch);

export { EmployeeConnected as EmployeeDirectorySearch };
export const testing = { EmployeeDirectorySearch: BaseDirectorySearch };
