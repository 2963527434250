import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Select } from 'form/select';

import './future_proof_data_filters.sass';

const skillTypeFilterOptions = [
  {
    displayValue: 'All',
    selectionValue: 'all'
  },
  {
    displayValue: 'Future Proof',
    selectionValue: 'future-proof'
  }
];

const skillSignalFilterOptions = [
  {
    displayValue: 'All',
    selectionValue: 'all'
  },
  {
    displayValue: 'Verified',
    selectionValue: 'verified'
  },
  {
    displayValue: 'From Occupations',
    selectionValue: 'from-occupations'
  }
];

export const DataFilters = ({ setSkillTypeFilterState, setSkillSignalFilterState }) => {
  const handleSkillTypeChange = (name, value) => {
    setSkillTypeFilterState(value);
  };

  const handleSkillSignalChange = (name, value) => {
    setSkillSignalFilterState(value);
  };

  return (
    <div className="future-proof-skills__data-filter-container">
      <Select
        className="future-proof-skills__select future-proof-skills__select--signal"
        name="Skill Signal"
        label={useIntl().formatMessage({
          id: "workforce.skills_explorer.future_proof_skills.skill_signal",
          defaultMessage: "Skill Signal:"
        })}
        appearance="horizontal-label"
        value="all"
        handleChange={handleSkillSignalChange}
        options={skillSignalFilterOptions}
      />
      <Select
        className="future-proof-skills__select future-proof-skills__select--type"
        name="Skill Type"
        appearance="horizontal-label"
        label={useIntl().formatMessage({
          id: "workforce.skills_explorer.future_proof_skills.skill_type",
          defaultMessage: "Skill Type:"
        })}
        value="all"
        handleChange={handleSkillTypeChange}
        options={skillTypeFilterOptions}
      />
    </div>
  );
};

DataFilters.propTypes = {
  setSkillTypeFilterState: PropTypes.func.isRequired,
  setSkillSignalFilterState: PropTypes.func.isRequired
};
