import React, { useState } from 'react';
import { Routes } from "utils";
import { useHistory } from 'react-router-dom';
import { Heading, NewHeadingLevel } from 'controls/heading';
import { SectionNavigation } from "controls/section_navigation";
import { JobChiclet } from './job_chiclet';
import { JobDetails } from './job_details';
import { FormattedMessage } from "react-intl";
import { EmptyJobBoard } from './empty_job_board';
import { LoadingSpinner } from "controls/loading_spinner";
import { PaginationNavControls, PaginationProgressIndicator } from 'controls/pagination';
import { useBrowserQueryString } from 'controls/with_query_string';
import { JobBoardFilters } from './job_board_filters';
import { jobBoardPublicJobsActions } from './action_creators';

import './job_board.sass';

export const JobBoard = () => {
  const history = useHistory();
  const [jobBoardState, jobBoardSearch] = jobBoardPublicJobsActions.useAction('list');
  const publicJobs = jobBoardState.resources || [];
  const firstJob = publicJobs[0];
  const meta = jobBoardState.metadata;
  const [responseIsPending, listLoading] = useState(jobBoardState.status.pending);
  const noJobsAvailable = !responseIsPending && publicJobs.length === 0;
  const [jobIndex, setJobIndex] = useState(0);
  const queryString = useBrowserQueryString();
  const [statusFilter, setStatusFilter] = useState(queryString.get('statusFilter'));
  const page = parseInt(queryString.get('page')) || 1;
  const [prevPage, setPrevPage] = useState();
  const [prevStatusFilter, setPrevStatusFilter] = useState();
  const [jobDetailsFilter, setjobDetailsFilter] = useState();
  const [pageReset, setPageReset] = useState(false);
  const [prevJobDetailsFilter, setPrevjobDetailsFilter] = useState();
  const queryParamsChanged = (
    page !== prevPage ||
    statusFilter !== prevStatusFilter ||
    jobDetailsFilter !== prevJobDetailsFilter
  );

  const statusQueryString = () => {
    if (statusFilter) {
      return ('statusFilter=' + statusFilter);
    } else {
      return ('');
    }
  };

  const performBoardSearch = () => {
    const boardParams = { page: page };

    if (pageReset) {
      boardParams.page = 1;
      history.push({ search: statusQueryString() });
      setPageReset(false);
    }

    if (statusFilter) {
      boardParams.status_filter = statusFilter;
    }

    if (jobDetailsFilter) {
      boardParams.job_details_filter = jobDetailsFilter;
    }

    jobBoardSearch(boardParams);
    setJobIndex(0);
  };

  if (queryParamsChanged) {
    // If the query params have changed, make a new request and refresh UI
    setPrevPage(page);
    setPrevStatusFilter(statusFilter);
    setPrevjobDetailsFilter(jobDetailsFilter);
    listLoading(true);
    performBoardSearch();
  } else if (responseIsPending && !jobBoardState.status.pending) {
    // After the initial load finishes, keep this false
    listLoading(false);
  }

  const renderJobChiclets = () => {
    return (
      publicJobs.map((job, index) =>
        <JobChiclet
          job={job}
          key={index}
          tabIndex={index}
          onClick={() => setJobIndex(index)}
          active={index === jobIndex}
        />
      )
    );
  };

  const handleFiltering = (value) => {
    setjobDetailsFilter(value);
    setPageReset(true);

    performBoardSearch();
  };

  const renderPaginationDetails = () => {
    if (noJobsAvailable) {
      return (
        <Heading appearance="custom" className="job-board-header-text-counter">
          <FormattedMessage
            id="talent_match.job_board.empty_board.pagination_copy"
            defaultMessage="Showing 0 jobs"
          />
        </Heading>
      );
    } else {
      return (
        <Heading appearance="custom" className="job-board-header-text-counter">
          <PaginationProgressIndicator data={meta} />
        </Heading>
      );
    }
  };

  return (
    <>
      <div className="job-board-layout">
        <div className="container job-board-layout__container">
          <div className="job-board-header-filter-tabs">
            <NewHeadingLevel>
              <div className="">
                <SectionNavigation>
                  <SectionNavigation.Link
                    onClick={() => setStatusFilter()}
                    action={Routes.jobBoardUrl()}
                    activeQueryParams={{ statusFilter: statusFilter }}
                  >
                    All Jobs
                  </SectionNavigation.Link>
                  <SectionNavigation.Link
                    onClick={() => setStatusFilter('applied')}
                    action={Routes.jobBoardByStatusUrl('applied')}
                    activeQueryParams={{ statusFilter: statusFilter }}
                  >
                    Applied
                  </SectionNavigation.Link>
                  <SectionNavigation.Link
                    onClick={() => setStatusFilter('saved')}
                    action={Routes.jobBoardByStatusUrl('saved')}
                    activeQueryParams={{ statusFilter: statusFilter }}
                  >
                    Saved
                  </SectionNavigation.Link>
                </SectionNavigation>
              </div>
            </NewHeadingLevel>
            <div className="job-board-filters">
              <JobBoardFilters onSubmit={handleFiltering} />
            </div>
          </div>
          {noJobsAvailable ? (
            <EmptyJobBoard filter={statusFilter} />
          ) : (
            <>
              <div className="job-board-header">
                <Heading appearance="custom" className="job-board-header-text">All Jobs</Heading>
                {renderPaginationDetails()}
              </div>
              <div className="row no-gutters">
                <div className="col-12 col-lg-5">
                  <div className="job-board-layout__sidebar">
                    {responseIsPending ? (
                      <LoadingSpinner delay={200} position="center" />
                    ) : (
                      <>
                        {renderJobChiclets()}
                        <div className="job-board-pagination">
                          <PaginationNavControls
                            data={meta}
                            pageNumbers
                            centered
                            compact
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-7">
                  <div className="job-board-layout__main">
                    {responseIsPending ? (
                      <LoadingSpinner delay={200} position="center" />
                    ) : (
                      <div className="job-board-details">
                        <div className="job-board-details-content">
                          <JobDetails
                            job={publicJobs[jobIndex] || firstJob}
                            onChangeListContents={performBoardSearch}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
