import React, { useCallback, useEffect, useRef } from 'react';
import sortedIndexOf from 'lodash.sortedindexof';
import { uniqueId } from 'utils';
import * as objUtils from 'utils/object';
import { array } from 'utils/array';
import { ResourceNotFoundError } from 'utils/resource_not_found_error';
import { useIntl } from 'react-intl';

/**
 * Set React state, only if the new state differs from the old. This avoids constructs like:
 *   if (this.state.bla != bla) this.setState({bla: bla});
 * As with React's setState, the callback (if provided) is always called.
 *
 * @param {*|Component} component - The React component.
 * @param {Object} state - New React state.
 * @param {Function=} callback - Callback for setState(). If anything has changed, this will be
 *   called as a side-effect of setState(). Otherwise, it will be called immediately.
 * @returns {Boolean} true if anything changed.
 */
export const setStateIfChanged = (component, state, callback = null) => {
  const filteredState =
    objUtils.filter(state, (k, v) => JSON.stringify(component.state[k]) !== JSON.stringify(v));

  if (Object.keys(filteredState).length > 0) {
    if (callback) {
      component.setState(filteredState, callback);
    } else {
      component.setState(filteredState);
    }
    return true;
  }

  callback && callback();
  return false;
};

/**
 * Set temporary React state. Useful for properties that are toggled on and immediately off again.
 * For example, you can use this to focus a component immediately, then forget that it happened,
 * so you can focus again later.
 *
 * Use this function instead of ImmediateProperty when a property has other uses than being toggled
 * like this. ImmediateProperty is roughly twice as performant.
 */
export const setStateImmediate = (component, name, on = true, off = false) => {
  component.setState({ [name]: on }, () => component.setState({ [name]: off }));
};

/**
 * @typedef {int} ImmediatePropertyVal
 */

/**
 * An efficient way to have "perform once" properties on a component.
 *
 * Example usage:
 *
 * class MyComponent {
 *   componentDidMount(prevProps) {
 *     if (this.props.doItOnce.updateOnInit()) {
 *       doSomething();
 *     }
 *   }
 *   componentDidUpdate(prevProps) {
 *     if (this.props.doItOnce.changed(prevProps.doItOnce)) {
 *       doSomething();
 *     }
 *   }
 * }
 * MyComponent.propTypes = {
 *   doItOnce: PropTypes.instanceOf(ImmediateProperty).isRequired
 * }
 *
 * class OtherComponent {
 *   constructor(props) {
 *     super(props);
 *     this.doItCounter = new ImmediateProperty();
 *     this.state = {doIt: this.doItCounter.val()};
 *   }
 *   onIWantToDoIt() {
 *     setState({doIt: this.doItCounter.next()});
 *   }
 *   render() {
 *     return <MyComponent doItNow={this.state.doIt}/>
 *   }
 * }
 */
export class ImmediateProperty {
  /**
   * @param {Boolean} updateOnInit - If true, the first call to .changed() will return true.
   *   If false, .changed() only return true after .next() is called.
   */
  constructor(updateOnInit = false) {
    this.m_count = 0;
    this.m_id = uniqueId();
    this.m_updateOnInit = updateOnInit;
  }

  /**
   * Create a new instance, which can be passed into setState().
   *
   * @returns {ImmediateProperty}
   */
  next() {
    const next = new ImmediateProperty(this.m_updateOnInit);
    next.m_count = this.m_count + 1;
    next.m_id = this.m_id;
    return next;
  }

  /**
   * Should the property update on init (usually componentDidMount)?
   */
  updateOnInit() {
    return this.m_updateOnInit;
  }

  /**
   * Has the property changed?
   *
   * @param {ImmediateProperty} prev - Either this object, or a parent through calls to .next().
   */
  changed(prev) {
    if (this.m_id !== prev.m_id) {
      throw new Error(
        'ImmediateProperty.changed(): Instances must be related through calls to .next()'
      );
    }
    if (this.m_count < prev.m_count) {
      throw new Error(
        'ImmediateProperty.changed(): Must be derived from "prev" through calls to .next()'
      );
    }

    return this.m_count > prev.m_count;
  }
}

/**
 * Like React.setState, but debounces the setter.
 *
 * @param {*} initialValue - The initial value for setState().
 * @param {int} delay - The debounce delay, in milliseconds.
 */
export const useDebouncedState = (initialValue, delay) => {
  const [val, setVal] = React.useState(initialValue);
  const timeout = React.useRef();
  const debouncedSetVal = React.useCallback((newVal) => {
    timeout.current && clearTimeout(timeout.current);
    timeout.current = setTimeout(() => setVal(newVal), delay);
  }, []);
  React.useEffect(() => () => clearTimeout(timeout.current), []);
  return [val, debouncedSetVal];
};

/**
 * Creates a setter for an attribute of a state object.  Only sets the attribute if the new value is
 * different, so that unnecessary re-renders can be avoided.
 *
 * @param {string} attr
 * @param {Function} setState
 * @returns {Function}
 */
export const useSetter = (attr, setState) => useCallback((val) => {
  setState(curState => {
    if (curState[attr] === val) {
      return curState;
    } else {
      return { ...curState, [attr]: val };
    }
  });
}, []);

/**
 * Simplified React state hook for a boolean toggle.
 *
 * @param {Boolean|*} on - True if the state should intialize to true (truthy values are accepted).
 * @returns {[Boolean, function(Boolean|*|undefined)]} - The useState getter and setter.
 *   - getter: The value. This is always a boolean, even if a truthy value is passed to the setter.
 *   - setter: Flip the boolean value, or set it to the truthiness of the passed-in value.
 */
export const useToggle = (on = false) => {
  const [getter, setter] = React.useState(!!on || false);

  // Make sure that the setters are stable references.
  const setters = React.useRef({});
  if (setters.current.saved !== setter) {
    setters.current.saved = setter;
    setters.current.toggle = val => setter(old => val === undefined ? !old : !!val);
  }

  return [getter, setters.current.toggle];
};

/**
 * Another simple boolean state hook. This one returns turnOn and turnOff functions.
 *
 * @param {Boolean|*} on - True if the state should intialize to true (truthy values are accepted).
 * @returns {[Boolean, function(Boolean), function(Boolean)]}
 *   - getter: The current value. This is always a boolean.
 *   - turnOn: Change the value to true.
 *   - setter: Change the value to false.
 */
export const useOnOff = (on = false) => {
  const [getter, setter] = React.useState(!!on || false);

  // Make sure that the setters are stable references.
  const setters = React.useRef({});
  if (setters.current.saved !== setter) {
    setters.current.saved = setter;
    setters.current.turnOn = () => setter(true);
    setters.current.turnOff = () => setter(false);
  }

  return [getter, setters.current.turnOn, setters.current.turnOff];
};

/**
 * React state hook to keep track of whether the component is mounted.
 *
 * @return {Boolean} true if mounted.
 */
export const useIsMounted = () => {
  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true;
    return () => mounted.current = false;
  }, []);
  return mounted.current;
};

/**
 * Identical to React.useEffect, except that it never runs on mount. This is the equivalent of
 * the componentDidUpdate lifecycle function.
 *
 * @param {function:function} effect - A useEffect effect.
 * @param {array} dependencies - useEffect dependency list.
 */
export const useEffectExceptOnMount = (effect, dependencies) => {
  const mounted = useIsMounted();
  React.useEffect(() => {
    if (mounted) {
      const unmount = effect();
      return () => unmount && unmount();
    }
  }, dependencies);
};

/**
 * React hook to memoize state, updating only when the value changes with a shallow comparison.
 *
 * Usage:
 * const val = useShallowEquals(something);
 * useEffect(() => {}, [val]);
 *
 * @param {Object|Array} val - The current value to compare.
 * @returns {Object|Array} - The memoized value.
 */
export const useShallowEquals = val => {
  const ref = React.useRef();
  if (Array.isArray(val)) {
    if (!array.shallowEquals(val, ref.current)) {
      ref.current = [...val];
    }
  } else if (!objUtils.shallowEquals(val, ref.current)) {
    ref.current = { ...val };
  }
  return ref.current;
};

/**
 * React hook to memoize state, updating only when the value changes with a deep comparison.
 *
 * Usage:
 * const val = useDeepEquals(something);
 * useEffect(() => {}, [val]);
 *
 * @param {Object|Array} val - The current value to compare.
 * @returns {Object|Array} - The memoized value.
 */
export const useDeepEquals = val => {
  const ref = React.useRef();
  if (!objUtils.deepEquals(val, ref.current)) {
    if (Array.isArray(val)) {
      ref.current = [...val];
    } else {
      ref.current = { ...val };
    }
  }
  return ref.current;
};

/**
 * React hook to debounce a function call. The timer is automatically cleared on unmount.
 *
 * To avoid this causing problems in a unit test, either use jest.useFakeTimers(), or
 * jest.spyOn(reactUtils, 'useDebounced').mockImplementation(f => () => f());
 *
 * @param {function} cb - The delayed function.
 * @param {int} delay - Delay in miliseconds.
 * @returns {function}
 */
export const useDebounced = (cb, delay = 1000) => {
  const timer = React.useRef(0);
  const cleanupCb = React.useRef(null);
  const clear = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = 0;
    }
  };

  const cleanup = () => {
    if (cleanupCb.current) {
      cleanupCb.current();
      cleanupCb.current = null;
    }
  };

  // Clear on unmount.
  React.useEffect(() => clear, []);

  return () => {
    clear();
    timer.current = setTimeout(() => {
      // stash a reference to the cleanup function returned by the debounced callback (if any), so
      // that it can be called on unmount or if the debounced callback changes
      const cleanupFn = cb();
      if (cleanupFn && typeof cleanupFn === 'function') {
        cleanupCb.current = cleanupFn;
      }
    }, delay);
    return cleanup;
  };
};

/**
 * Shortcut to call preventDefault() on a DOM event before calling the callback function.
 * As with useCallback(), parameter changes other than `dependencies` are ignored after the first
 * call.
 *
 * @param {function} cb - The callback.
 * @param {Boolean} passEvent - Pass the event into the callback. Setting this to false makes it
 *   easier to use function references as the `cb` parameter.
 * @param {Array} dependencies - Hook dependencies.
 */
export const useEventCallback = (cb, passEvent, dependencies) => {
  return React.useCallback(e => {
    if (e) {
      e.preventDefault();
    }
    passEvent ? cb(e) : cb();
  }, dependencies);
};

/**
 * Calls the callback whenever the condition changes. This is similar to:
 *   useEffect(() => {condition && condition()}, [condition()]);
 * However, useEffect gets complicated when condition() references props or state. You'll want to
 * do this:
 *   useEffect(() => {condition && cb(prop.x)}, [condition(), prop.x]);
 * but that will cause the callback to be executed more often than expected. useCallOnCondition
 * addresses that by using a callback that can change at any time, independent of dependencies.
 *
 * @param {function} cb - The callback. This may return a teardown function.
 * @param {function:boolean} cond - Call the callback when this condition changes.
 * @param {{callOnMount:boolean}} [opts={callOnMount:false}] - options controlling behavior of the
 *   hook; currently, just whether to invoke the callback on mount when the condition is true, or
 *   to wait until the first time the condition becomes true after mount
 */
export const useCallOnCondition = (cb, cond, opts = { callOnMount: false }) => {
  /** @type {{current: function}} */
  const callback = React.useRef();
  if (callback.current !== cb) {
    callback.current = cb;
  }
  /** @type {{current: function}} */
  const condition = React.useRef();
  if (condition.current !== cond) {
    condition.current = cond;
  }

  const mounted = useRef(opts.callOnMount);
  useEffect(() => {
    const isMounted = mounted.current;
    mounted.current = true;
    if (isMounted && condition.current()) {
      return callback.current();
    }
  }, [condition.current()]);
};

/**
 * Variation on React's built-in useMemo hook that guarantees the value won't be discarded if
 * dependencies do not change (in contrast with useMemo, which is only for performance and cannot be
 * relied upon for semantics).
 *
 * @param {function} generator - function to produce the value that will be memoized; only called if
 *   the value needs to be updated either due to initial mount or because dependencies change
 * @param {Array<*>} dependencies - dependencies that will be observed for changes and cause value
 *   to be regenerated from generator on change
 */
export const useMemoStrict = (generator, dependencies) => {
  const previousDependencies = useRef();
  const value = useRef();
  if (
    !previousDependencies.current ||
    !array.shallowEquals(previousDependencies.current, dependencies)
  ) {
    previousDependencies.current = dependencies;
    value.current = generator();
  }
  return value.current;
};

/**
 * React hook to raise an exception when a redux-resource request's status changes to failed.
 *
 * @param {ResourceStatus} status - the request status
 * @param {string} [objectId] - optional string identifying the object, which will be included in
 *   the exception message
 */
export const useRaiseOnFailedRequest = (status, objectId = null) => {
  const intl = useIntl();
  const previousStatus = React.useRef(status);

  if (status.failed && !previousStatus.current.failed) {
    const idOrDefault = objectId || intl.formatMessage({
      id: 'react_utils.useRaiseOnFailedRequest.data',
      defaultMessage: 'data'
    });

    // Future enhancement: eventually, we may want to provide request details to this hook, as well,
    // and raise a more specific type of exception, based on what error we encountered
    throw new ResourceNotFoundError(
      intl.formatMessage(
        {
          id: 'react_utils.useRaiseOnFailedRequest.error',
          defaultMessage: 'Error loading {objectId}'
        },
        { objectId: idOrDefault }
      )
    );
  }

  previousStatus.current = status;
};

/**
 * Execute a callback synchronously when dependencies change.  Suitable for making updates to a
 * derived value stored in a ref, for example, without triggering a re-render.
 *
 * @param {function} effect - the callback to execute when dependencies change
 * @param {Array<*>} dependencies - dependencies array to monitor for changes
 */
export const useImmediateEffect = (effect, dependencies) => {
  const prev = useRef();
  const ref = useShallowEquals(dependencies);
  if (prev.current !== ref) {
    effect();
  }
  prev.current = ref;
};

/**
 * Facilitates working with arrays of values that effectively operate as sets. The values will be
 * sorted and a new array will be returned from this hook if and only if the set of values actually
 * changes between invocations (i.e. providing the same list of values but as an array with a
 * different identity, or even an array with the same list of values but re-ordered won't result in
 * a different return value). Also returns three functions to operate on the array: add an element,
 * remove one or more elements, and check whether a given element is a member of the array. The
 * operations to add and remove elements return new arrays, rather than mutating the value.
 *
 * @param {*[]} originalValue - an array of values to sort and maintain
 * @returns {[*[], (function(*=): *[]), (function(...[*]): *[]), (function(*=): boolean)]}
 */
export const useSortedArray = (originalValue) => {
  const valueUnsorted = useRef();
  const value = useRef();
  if (valueUnsorted.current !== originalValue) {
    valueUnsorted.current = originalValue;
    const sortedValue = originalValue.slice().sort();
    if (!array.shallowEquals(value.current, sortedValue)) {
      value.current = sortedValue;
    }
  }

  const has = useCallback((element) => {
    return sortedIndexOf(value.current, element) >= 0;
  }, []);

  const add = useCallback((newElement) => {
    const cur = sortedIndexOf(value.current, newElement);
    let result = value.current;
    if (cur < 0) {
      let inserted = false;
      result = [];
      for (const item of value.current) {
        if (item >= newElement && !inserted) {
          inserted = true;
          result.push(newElement);
        }
        result.push(item);
      }
      if (!inserted) {
        result.push(newElement);
      }
    }
    return result;
  }, []);

  const remove = useCallback((...toRemove) => {
    const newValue = [];
    const toRemoveHash = toRemove.reduce((memo, el) => {
      memo[el] = true;
      return memo;
    }, {});
    for (const el of value.current) {
      if (!toRemoveHash[el]) {
        newValue.push(el);
      }
    }
    return newValue.length < value.current.length ? newValue : value.current;
  }, []);

  return [value.current, add, remove, has];
};
