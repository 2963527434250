import React from 'react';
import PropTypes from 'prop-types';
import { GenericSectionHeader } from 'controls/section_header';
import {
  useFaethmOccupationMappingEnabled
} from 'pages/workforce/faethm_occupation_mapping_hooks';
import { EmployeeRouteHelpers } from 'pages/workforce/route_helpers';
import { useIntl } from 'react-intl';
import { Permissions, usePermissions } from 'management/organizations/permissions';

/**
 * Renders the section header for the "Add Employee(s)" section of organization management.
 *
 * @param {Object} props
 *   @param {string} props.baseUrl - the base URL of organization management under which this
 *     section is nested (e.g. /mgmt/organizations/org-1/employees/employees).
 * @returns {React.element}
 */
export const EmployeesAddSectionHeader = (props) => {
  const [hasPermissions] = usePermissions();
  const intl = useIntl();
  const occupationMappingEnabled = useFaethmOccupationMappingEnabled();
  const routeHelpers = EmployeeRouteHelpers.forUrl(props.baseUrl);
  const items = [
    {
      action: routeHelpers.employeeAddSingle.parameterize(),
      label: intl.formatMessage({
        id: 'workforce.employees.add.header.single',
        defaultMessage: 'Single'
      })
    },
    {
      action: routeHelpers.employeeAddBulk.parameterize(),
      label: intl.formatMessage({
        id: 'workforce.employees.add.header.bulk',
        defaultMessage: 'Bulk'
      })
    }
  ];

  if (hasPermissions(Permissions.PLATFORM_MANAGER) && occupationMappingEnabled) {
    items.push({
      action: routeHelpers.employeeUpdateBulk.parameterize(),
      label: intl.formatMessage({
        id: 'workforce.employees.add.header.occupation_upload',
        defaultMessage: 'Occupation Upload'
      })
    });
  }

  return (
    <GenericSectionHeader
      heading={intl.formatMessage({
        id: 'workforce.employees.add.employees_add_section_header.header',
        defaultMessage: 'Add Employee(s)'
      })}
      items={items}
      breadcrumbs={false}
    />
  );
};

EmployeesAddSectionHeader.propTypes = {
  baseUrl: PropTypes.string.isRequired
};
