import React from "react";
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { except } from 'utils/object';
import { useCarousel } from 'controls/carousel/use_carousel';
import { Carousel } from '../carousel';
import { ControlArrows } from './carousel_manager_control';
import { useActionCarousel } from '../use_action_carousel';
import { CarouselManagerItem, LoadingCard } from './carousel_manager_card';
import { getContainerClassName, useCarouselManagerLoadingDisplayProps } from './utils';
import { CarouselManagerProvider } from './carousel_manager_context';
import { carouselCardTypes, carouselLayoutTypes } from './constants';

import './carousel_manager.sass';

/**
* Renders a carousel, using paginated action resources, with "infinite scroll".
*
* @param {Function} action - must return an Action, if using the carousel manager the action should already be fetched:   const [actionState] = action.useOnMount();
* @param {Object} actionParams - parameters used when performing Action calls
* @param {Object} settings - same settings that the normal carousel takes
* @param {Object} paginatedData - if the carousel has paginated data pass the action.resources as this object, here we will use `useCarousel` instead of `useCarouselManager`. See badge_card_carousel for example usage
* @param {String} cardType - one of carouselCardTypes
* @param {String} layout - one of carouselLayoutTypes
* @param {Boolean} workforceContent - for layouts which have a sidebar such as the workforce dashboard
* @param {String} sourceTrack - badge tracking type
* @param {Boolean} useLeftOffset - gives the carousel a left offset which allows room for the `prev` arrow when there is no room in the left gutter.
example:
        <CarouselManager
          cardType={carouselCardTypes.BADGE}
          layout={carouselLayoutTypes.DASHBOARD}
          action={popularOrgBadges}
          actionParams={popularOrgParams}
          settings={{
            label: intl.formatMessage({
              id: 'earner_dashboard.popular_badges_carousel.label',
              defaultMessage: 'Popular Badge Carousel'
            }),
            description: intl.formatMessage({
              id: 'earner_dashboard.popular_badges_carousel.description',
              defaultMessage: 'Explore other popular badges'
            }),
            itemLabel: (badge) => intl.formatMessage({
              id: 'earner_dashboard.popular_badges_carousel.item_label',
              defaultMessage: '{badgeName} badge'
            }, { badgeName: badge.name }),
            itemsPerSlideBreakpoints: { md: 2, xs: 1, sm: 1, default: 3 }
          }}
        />
*/

export const CarouselManager = ({
  actionParams,
  settings,
  loadingItemPlaceholder,
  secondaryDisplay,
  action,
  cardType,
  className,
  useLeftOffset,
  sourceTrack,
  layout,
  paginatedData,
  size,
  ...otherProps
}) => {
  const carouselData = useCarousel(paginatedData, settings);
  const [actionCarouselData] = useActionCarousel(action, actionParams, settings, true);
  // behavior is different if carousel has paginated data
  const slider = paginatedData ? carouselData : actionCarouselData;

  const [actionState] = action.useAction();
  const loadingProps = useCarouselManagerLoadingDisplayProps({
    status: actionState.status,
    slider
  });

  return (
    <CarouselManagerProvider
      size={size}
      type={cardType}
      action={action}
      actionParams={actionParams}
      layout={layout}
      sourceTrack={sourceTrack}
    >
      <Carousel
        hasVariableWidth={cardType !== carouselCardTypes.BADGE || !!size}
        overflow="visible"
        useLeftOffset={layout === carouselLayoutTypes.WORKFORCE || useLeftOffset}
        {...otherProps}
        {...{ slider }}
        className={makeClassName([
          getContainerClassName({
            layout,
            cardType,
            size
          }),
          className
        ])}
        secondaryDisplay={
          <>
            <Carousel.ItemLoaderDisplay
              {...loadingProps}
              itemPlaceholder={loadingItemPlaceholder}
            />
            {secondaryDisplay}
          </>
        }
      />
    </CarouselManagerProvider>

  );
};

CarouselManager.propTypes = {
  ...except(Carousel.propTypes, ['slider']),
  loadingItemPlaceholder: PropTypes.func,
  action: PropTypes.object.isRequired,
  actionParams: PropTypes.object,
  paginatedData: PropTypes.object,
  controls: PropTypes.object
};


CarouselManager.defaultProps = {
  controls: ControlArrows,
  display: CarouselManagerItem,
  loadingItemPlaceholder: () => <LoadingCard />
};
