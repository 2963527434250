import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextSize, VictoryLabel, VictoryPie } from 'victory';
import { makeClassName } from 'utils';
import { pluralize } from 'utils/localization';
import * as objUtils from 'utils/object';
import { FONT_FAMILY, HEADING_FONT_FAMILY } from './constants';
import DonutLoadingSVG from 'svg/charts-donut_loading.svg';

import './donut.sass';

/**
 * @typedef DonutSizes
 * @prop {number} innerRadius
 * @prop {number} middle
 * @prop {number} outerRadius
 * @prop {number} padding
 * @prop {number} size
 * @prop {string} viewBox
 */
let _DocOnlyDonutSizes;

const DONUT_SIZES = {
  size: 250,
  viewBox: '0 0 250 250',
  padding: 0,
  outerRadius: 125,
  innerRadius: 105,
  // used for positioning tooltips in center of chart
  middle: 125
};

const EMPTY_DATA = [{ x: 'EMPTY', y: 1 }];
const EMPTY_COLORS = ['#E9EFF4'];

/**
 * Renders a donut (pie) chart of the provided data using colors specified as props. The sum of the
 * metric across all categories will be displayed as a label in the center of the donut. A legend is
 * displayed to the right of the donut with the category names, a circle of the corresponding color,
 * and the percentage of the total that the category represents.
 *
 * @param {object} props
 * @param {string[]|Object<string, string>} props.categoryColors - an array of colors to use for the
 *   slices of the chart; ideally, this should have the same or greater length as props.data, but if
 *   it is shorter, colors will be; alternatively provide an object with keys matching the x values
 *   from the data array and values of the desired color codes, in this case, the size of the object
 *   must exactly match the length of the data array
 * @param {Array<{ x:string, y:number }>} props.data - the data to render as a donut (pie) chart
 * @param {string} props.itemLabel - the singular name of the charted metric (e.g. "Badge")
 * @param {string} [props.itemLabelPlural] - the plural name of the charted metric; if not provided,
 *   a plural form will be generated from the singular form by appending "s"
 * @param {string} [props.rowBreakpoint] - the breakpoint at which to arrange legend and chart
 *   horizontally, rather than vertically
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Donut = (props) => {
  const centerLabel = useCallback((total) => {
    return [
      total.toLocaleString(),
      pluralize(total, props.itemLabel, props.itemLabelPlural, { textOnly: true })
    ];
  }, []);

  // handle the possibility that categoryColors prop has been passed as a hash, instead of as an
  // array
  const categoryColors = useMemo(() => {
    if (Array.isArray(props.categoryColors)) {
      return props.categoryColors;
    } else {
      return props.data.map((e) => props.categoryColors[e.x]);
    }
  }, [props.data, props.categoryColors]);

  return (
    <div
      className={makeClassName(
        'cr-charts-donut',
        props.rowBreakpoint && `cr-charts-donut--row-layout-${props.rowBreakpoint}`
      )}
    >
      <div className="cr-charts-donut__chart-container">
        <DonutCore {...props} centerLabel={centerLabel}/>
      </div>
      <div className="cr-charts-donut__legend">
        {
          props.data.map((datum, index) => (
            <LegendCategoryItem
              x={datum.x}
              y={datum.y}
              color={categoryColors[index % categoryColors.length]}
              key={datum.x}
            />
          ))
        }
      </div>
    </div>
  );
};

Donut.propTypes = {
  categoryColors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string), PropTypes.object
  ]).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.string.isRequired, y: PropTypes.number
  })).isRequired,
  itemLabel: PropTypes.string.isRequired,
  itemLabelPlural: PropTypes.string,
  rowBreakpoint: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'])
};

/**
 * Component that renders just the donut portion of the Donut chart.
 *
 * @param {object} props
 *   @param {string[]|Object<string, string>} props.categoryColors - an array of colors to use for
 *     the slices of the chart; ideally, this should have the same or greater length as props.data,
 *     but it is shorter, colors will be; alternatively provide an object with keys matching the x
 *     values from the data array and values of the desired color codes, in this case, the size of
 *     the object must exactly match the length of the data array
 *   @param {Array<{ x:string, y:number }>} props.data - the data to render as a donut (pie) chart
 *   @param {function(number, {x:string,y:number}[]):string[]} props.centerLabel - a function that
 *     can generate the label to show in the center of the donut; each element of the array becomes
 *     a line of text
 *   @param {object[]} [props.centerLabelStyles] - an array of styles to apply to the label at the
 *     center of the donut chart; styles will be applied line-by-line to the centerLabel output,
 *     and if the length of the centerLabeLStyles array is shorter than the output of centerLabel,
 *     the last style will be applied to all remaining lines
 *   @param {DonutSizes} [props.donutSizes] - an optional object describing the dimensions of the
 *     donut chart; defaults to {@link DONUT_SIZES}
 * @returns {JSX.Element}
 * @constructor
 */
export const DonutCore = (props) => {
  const total = props.data.reduce((acc, d) => acc + (d.y || 0), 0);

  const centerLabel = useMemo(() => {
    return props.centerLabel(total, props.data);
  }, [total, props.data, props.centerLabel]);

  const titleStyles = calculateTitleStyles(
    centerLabel, props.donutSizes.innerRadius, props.centerLabelStyles
  );
  // maybe because of the larger and smaller fonts, but the title doesn't _look_ centered where
  // Victory chooses to put it: bump the center down a little
  const titleBump = titleStyles.slice(0, -1).reduce((acc, e) => acc + e.fontSize, 0) / 2;

  // handle the possibility that categoryColors prop has been passed as a hash, instead of as an
  // array
  const categoryColors = useMemo(() => {
    return normalizeCategoryColors(props.categoryColors, props.data);
  }, [props.data, props.categoryColors]);

  return (
    <svg viewBox={props.donutSizes.viewBox} className="cr-charts-donut__chart">
      <VictoryLabel
        text={centerLabel}
        x={props.donutSizes.middle}

        y={props.donutSizes.middle + props.donutSizes.padding + titleBump}
        lineHeight={1.25}
        textAnchor="middle"
        verticalAnchor="middle"
        style={titleStyles}
      />
      <VictoryPie
        data={total > 0 ? props.data : EMPTY_DATA}
        colorScale={total > 0 ? categoryColors : EMPTY_COLORS}
        innerRadius={props.donutSizes.innerRadius}
        // suppress labels
        labels={[]}
        padding={props.donutSizes.padding}
        width={props.donutSizes.size}
        height={props.donutSizes.size}
        standalone={false}
        radius={props.donutSizes.outerRadius}
      />
    </svg>
  );
};

const MIN_COUNT_FONT_SIZE = 24;
const MAX_COUNT_FONT_SIZE = 48;
const MIN_TITLE_FONT_SIZE = 12;
const MAX_TITLE_FONT_SIZE = 12;

export const DONUT_CENTER_LABEL_DEFAULT_STYLES = [
  { family: HEADING_FONT_FAMILY, minSize: MIN_COUNT_FONT_SIZE, maxSize: MAX_COUNT_FONT_SIZE },
  { family: FONT_FAMILY, minSize: MIN_TITLE_FONT_SIZE, maxSize: MAX_TITLE_FONT_SIZE }
];

DonutCore.defaultProps = {
  donutSizes: DONUT_SIZES,
  centerLabelStyles: DONUT_CENTER_LABEL_DEFAULT_STYLES
};

DonutCore.propTypes = {
  categoryColors: Donut.propTypes.categoryColors,
  centerLabel: PropTypes.func.isRequired,
  data: Donut.propTypes.data,
  donutSizes: PropTypes.shape({
    innerRadius: PropTypes.number.isRequired,
    middle: PropTypes.number.isRequired,
    outerRadius: PropTypes.number.isRequired,
    padding: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    viewBox: PropTypes.string.isRequired
  }).isRequired,
  centerLabelStyles: PropTypes.arrayOf(
    PropTypes.shape({
      family: PropTypes.string.isRequired,
      minSize: PropTypes.number.isRequired,
      maxSize: PropTypes.number.isRequired,
      color: PropTypes.string
    })
  ).isRequired
};

/**
 * Normalize the provided categoryColors prop as an array of colors matching the order of the
 * values in data.
 *
 * @param {string[]|Object<string,string>} categoryColors - an array containing colors for the
 *   chart categories in the same order as data, or a hash with keys corresponding to the x values
 *   of data and values corresponding to the desired color
 * @param {{x:string, y:number}} data - chart data; required to normalize the category colors when
 *   they are passed as a mapping from category labels to colors
 * @returns {string[]}
 */
export const normalizeCategoryColors = (categoryColors, data) => {
  if (Array.isArray(categoryColors)) {
    return categoryColors;
  } else {
    return data.map((e) => categoryColors[e.x]);
  }
};

const CATEGORY_PLACEHOLDER_COUNT = 3;

/**
 * Internal component used by Donut when data is loading. Just displays a dummy donut image and
 * legend.
 *
 * @param {object} props
 *   @param {string} [props.rowBreakpoint] - breakpoint at which to switch to side-by-side layout
 *     of legend and chart (see {@link Donut})
 * @returns {JSX.Element}
 * @constructor
 */
export const DonutLoading = (props) => {
  const categories = [];
  for (let i = 0; i < CATEGORY_PLACEHOLDER_COUNT; ++i) {
    categories.push(i);
  }

  return (
    <div
      className={makeClassName(
        'cr-charts-donut cr-charts-donut--loading',
        props.rowBreakpoint && `cr-charts-donut--row-layout-${props.rowBreakpoint}`
      )}
    >
      <div className="cr-charts-donut__chart-container">
        <DonutLoadingSVG className="cr-charts-donut__chart"/>
      </div>
      <div className="cr-charts-donut__legend">
        {
          categories.map((id) => (
            <div
              key={id}
              className="
                cr-charts-donut__legend-category
                cr-charts-donut__legend-category--placeholder
              "
            >
              <div className="cr-charts-donut__legend-category-icon"/>
              <div
                className="
                  cr-charts-donut__legend-category-label
                  cr-charts-donut__legend-category-label--placeholder
                "
              />
              <div
                className="
                  cr-charts-donut__legend-category-percent
                  cr-charts-donut__legend-category-percent--placeholder
                "
              />
            </div>
          ))
        }
      </div>
    </div>
  );
};

DonutLoading.propTypes = {
  rowBreakpoint: Donut.propTypes.rowBreakpoint
};

/**
 * Internal component used by Donut to render an individual element of the legend.
 *
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const LegendCategoryItem = (props) => {
  return (
    <div className="cr-charts-donut__legend-category">
      <div
        className="cr-charts-donut__legend-category-icon"
        style={{ backgroundColor: props.color }}
      />
      <div className="cr-charts-donut__legend-category-label">{props.x}</div>
      <div className="cr-charts-donut__legend-category-percent">
        {(props.y || 0).toLocaleString()}
      </div>
    </div>
  );
};

LegendCategoryItem.propTypes = {
  color: PropTypes.string.isRequired,
  x: PropTypes.string.isRequired,
  y: PropTypes.number.isRequired
};

const PADDING = 15;

/**
 * Internal helper to determine what styles to give to the title text displayed in the center of the
 * Donut chart.
 *
 * @param {string[]} titleLines - lines of text to use for the center chart title
 * @param {number} innerRadius - inner radius of the donut, used to compute how much horizontal
 *   space is available to render the title and adjust font sizes accordingly
 * @param {{ family: String, minSize: number, maxSize: number}[]} styles - styles for center chart
 *   title lines
 * @returns {object[]}
 */
const calculateTitleStyles = (titleLines, innerRadius, styles) => {
  return titleLines.map((line, index) => {
    const availableWidth = innerRadius * 2 - PADDING * 2;
    const { color, ...fitStyles } = styles[index] || styles[styles.length - 1];
    const baseStyles = {
      fontFamily: fitStyles.family, fontWeight: fitStyles.weight || 700, fill: color
    };
    return objUtils.compact({
      ...baseStyles,
      fontSize: fitFontSize(line, baseStyles, fitStyles.minSize, fitStyles.maxSize, availableWidth)
    });
  });
};

/**
 * Internal helper to find an appropriate font size to render some text so that it will fit in the
 * available area. Uses Victory's TextSize.approximateTextSize function, which has some limitations,
 * most obvious of which is that it doesn't include character size data for the fonts we use.
 *
 * @param {string} text - the text to be rendered
 * @param {object} baseStyles - some base styles (such as font family) that will be factored into
 *   the calculation of how large the rendered text will be
 * @param {number} minFontSize - the minimum font size to return; if the text is estimated to still
 *   exceed availableWidth at this size, it will be returned anyway
 * @param {number} maxFontSize - the maximum font size to return; if the text is estimated to fit
 *   within availableWidth at this size, it will be used (i.e. preference is for largest possible
 *   font size)
 * @param {number} availableWidth - the available horizontal space in which the text is to be
 *   rendered; vertical space is not taken into account at this time
 * @returns {number}
 */
const fitFontSize = (text, baseStyles, minFontSize, maxFontSize, availableWidth) => {
  const atMaxSize = TextSize.approximateTextSize(text, { ...baseStyles, fontSize: maxFontSize });
  if (atMaxSize.width <= availableWidth) {
    return maxFontSize;
  }
  const atMinSize = TextSize.approximateTextSize(text, { ...baseStyles, fontSize: minFontSize });
  if (atMinSize.width >= availableWidth) {
    return minFontSize;
  }
  const midSize = minFontSize + Math.round((maxFontSize - minFontSize) / 2);
  if (midSize < maxFontSize && midSize > minFontSize) {
    const atMidSize = TextSize.approximateTextSize(
      text, { ...baseStyles, fontSize: midSize }
    );
    if (atMidSize.width >= availableWidth) {
      return fitFontSize(text, baseStyles, minFontSize, midSize);
    } else {
      return fitFontSize(text, baseStyles, midSize, maxFontSize);
    }
  } else {
    // nowhere left to go...fall back to min font size
    return minFontSize;
  }
};

export const testing = { EMPTY_COLORS, EMPTY_DATA, fitFontSize, LegendCategoryItem };
