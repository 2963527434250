import React from 'react';
import PropTypes from 'prop-types';
import { DirectoryBase } from 'pages/public/organization/earner_directory/shared/directory_base';
import { employeeSkillsAction } from 'pages/workforce/employees/employees_actions';
import { useIntl } from 'react-intl';
import { EmployeeSkillsSearch } from './employee_skills_search';
import { EmployeeSkillsTable } from './employee_skills_table';
import { useExcludedSearchTypes } from '../../../faethm_occupation_mapping_hooks';

import './employee_skills.sass';

/**
 * View for the Employee skills list view for workforce.
 *
 * @param {object} props
 *   @param {Object} props.organization - Viewing organization
 *   @param {RouterLocation} props.location - React Router location object representing the
 *     application's current location/URL
 * @returns {React.element}
 */

const SEARCH_TABS = [
  { name: 'name', stringId: 'workforce.employees.skills.employee_skills.search.skill_name', label: 'Skill Name' },
  { name: 'department', stringId: 'workforce.employees.skills.employee_skills.search.departments', label: 'Departments' },
  { name: 'location', stringId: 'workforce.employees.skills.employee_skills.search.locations', label: 'Locations' },
  { name: 'occupation', stringId: 'workforce.employees.skills.employee_skills.search.occupations', label: 'Occupations' }
];

const FILTER_NAMES = [
  { key: 'name', stringId: 'workforce.employees.skills.employee_skills.filter.skill', label: 'Skill' },
  { key: 'department', stringId: 'workforce.employees.skills.employee_skills.filter.department', label: 'Department' },
  { key: 'location', stringId: 'workforce.employees.skills.employee_skills.filter.location', label: 'Location' },
  { key: 'occupation', stringId: 'workforce.employees.skills.employee_skills.filter.occupation', label: 'Occupation' }
];

export const EmployeeSkills = (props) => {
  const intl = useIntl();
  const excludedSearchTypes = useExcludedSearchTypes();

  const searchTabs = SEARCH_TABS.map((item) => {
    return {
      name: item.name,
      label: intl.formatMessage({ id: item.stringId, defaultMessage: item.label })
    };
  });

  const filterNames = FILTER_NAMES.reduce(
    (hash, item) => {
      hash[item.key] = intl.formatMessage({ id: item.stringId, defaultMessage: item.label });
      return hash;
    },
    {}
  );

  return (
    <DirectoryBase
      className={['col', 'c-employees-skills']}
      alwaysRenderHeader
      directorySearchTag={EmployeeSkillsSearch}
      filterAction={employeeSkillsAction.get('filter')}
      searchBarPlaceholderText={intl.formatMessage({
        id: 'workforce.employees.skills.employee_skills.search_skills',
        defaultMessage: 'Search skills'
      })}
      filterNames={filterNames}
      tabs={searchTabs}
      sortSelectorEnabled={false}
      excludeSearchTypes={excludedSearchTypes}
      baseParams={{ organization_id: props.organization.id }}
      trackingData={{ object_id: props.organization.id, object_type: 'Organization', directory_type: 'workforce-employees-skills' }}
      tabNamesToFilterKeyTransforms={{
        name: () => 'name',
        department: () => 'department',
        location: () => 'location',
        occupation: () => 'occupation'
      }}
      resultsRender={(directoryResultsFilterState) => (
        <EmployeeSkillsTable
          data={directoryResultsFilterState.resources}
          status={directoryResultsFilterState.status}
          routeProps={props.routeProps}
        />
      )}
      orFilters
    />
  );
};

EmployeeSkills.propTypes = {
  organization: PropTypes.object,
  routeProps: PropTypes.object
};
