import React, { Component } from 'react';
import PropTypes from 'prop-types';
import element from 'utils/element';
import * as objUtils from 'utils/object';
import { makeClassName } from 'utils';
import './select_list.sass';

/**
 * Create a list of items that can be selected. Supports keyboard navigation.
 *
 * Example usage:
 * <SelectList>{this.props.results.map(data =>
 *   <SelectList.Item key={data.id} onSelect={this.onSelect} onEscape={this.closeSearch}>
 *     {data.content}
 *   </SelectList.Item>
 * )}</SelectList>
 *
 * @property {String} className - A CSS class to add at the top level.
 */
export const SelectList = props =>
  <ul className={makeClassName(
    props.className,
    props.theme === 'org-mgmt' && "cr-select-list--org-mgmt",
    "cr-select-list"
  )}
  >
    {props.children}
  </ul>;

/**
 * A list item for SelectList.
 *
 * @property {String} className - A CSS class to add at the top level.
 * @property {*} data - Arbitrary data to pass to events. This helps optimize the container, by
 *   allowing for static references to function properties.
 * @property {function(*, SyntheticEvent)} onSelect - Called when an item is selected with the mouse
 *   or keyboard. Passed this.props.data.
 * @property {function(*, SyntheticEvent)} onEscape - Called when the list is escaped, by pressing
 *   the escape key, or by tabbing out of the list. Passed this.props.data.
 */
SelectList.Item = class extends Component {
  /**
   * Handle the keydown event.
   *
   * @param {SyntheticEvent} evt
   */
  onKeyDown = evt => {
    element.listKeyboardNavHandler(evt, {
      data: this.props.data,
      onSelect: this.props.onSelect,
      onEscape: this.props.onEscape
    });
  };

  /**
   * An item was selected.
   *
   * @param {SyntheticEvent} evt
   */
  onSelect = evt => {
    evt.preventDefault();
    this.props.onSelect(this.props.data);
  };

  /**
   * Render the component.
   *
   * @returns {React.element}
   */
  render() {
    return (
      <li
        tabIndex={0}
        {...objUtils.filter(this.props, k => !(k in SelectList.Item.propTypes))}
        className={makeClassName(
          'cr-select-list__item',
          this.props.className,
          this.props.theme === 'org-mgmt' && "cr-select-list__item--org-mgmt"
        )}
        onClick={this.onSelect}
        onKeyDown={this.onKeyDown}
      >
        {this.props.children}
      </li>
    );
  }
};
SelectList.Item.displayName = 'SelectList.Item';

SelectList.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  theme: PropTypes.string
};
SelectList.Item.propTypes = {
  children: PropTypes.any,
  onSelect: PropTypes.func,
  onEscape: PropTypes.func,
  data: PropTypes.shape({ id: PropTypes.any }),
  className: PropTypes.string
};
