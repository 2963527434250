import { connect } from 'react-redux';
import { employeeOccupationsSearch } from 'pages/workforce/employees/employees_actions';
import { BaseDirectorySearch } from 'pages/public/organization/earner_directory/base_directory_search.jsx';

const EmployeeOccupationsSearchConnected = connect(
  state => ({
    results: employeeOccupationsSearch.getResources(state),
    searchStatus: employeeOccupationsSearch.getStatus(state)
  }),
  dispatch => ({
    search: query => dispatch(employeeOccupationsSearch.action(query))
  })
)(BaseDirectorySearch);

export { EmployeeOccupationsSearchConnected as EmployeeOccupationsSearch };
export const testing = { EmployeeOccupationsSearch: BaseDirectorySearch };
