import React from 'react';
import PropTypes from "prop-types";
import { DataTable } from 'controls/data_table';
import { makeClassName } from 'utils';

import './org_mgmt_data_table.sass';

export const OrgMgmtDataTable = (props) => {
  const uiTweaks = [...(props.uiTweaks || [])];
  const rowClassName = [].concat('mgmt-data-table__row', props.rowClassName).filter(element => element);

  // force unstyled headers, so we can define our own
  if (!uiTweaks.includes(DataTable.UNSTYLED_HEADERS)) {
    uiTweaks.push(DataTable.UNSTYLED_HEADERS);
  }

  return (
    <DataTable
      {...props}
      loader={{ showLoadingPlaceholder: true, ...props.loader }}
      className="mgmt-data-table"
      uiTweaks={uiTweaks}
      rowClassName={rowClassName}
    />
  );
};

OrgMgmtDataTable.defaultProps = {
  header: {},
  uiTweaks: [DataTable.ROW_BORDERS]
};

OrgMgmtDataTable.Col = (props) => {
  return (
    <DataTable.Col
      {...props}
      headerClassName={makeClassName('mgmt-data-table__header', props.headerClassName)}
    />
  );
};

OrgMgmtDataTable.propTypes = {
  className: PropTypes.string,
  uiTweaks: PropTypes.array,
  rowClassName: PropTypes.oneOfType( // see makeDataTableRowClassName
    [
      PropTypes.string,
      PropTypes.func,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
    ]
  ),
  loader: PropTypes.object
};

OrgMgmtDataTable.Col.propTypes = {
  headerClassName: PropTypes.string
};
