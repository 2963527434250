import React from 'react';
import PropTypes from 'prop-types';

/**
 * React.Context for determining if shared components within DirectoryBase
 * are being rendered within the Employee Directory. Doing so to avoid
 * prop drilling further into the shared directory components.
 *
 * Should also contain metadata for Invite Employees flow.
 * See propTypes for details.
 */
export const EmployeeDirectoryContext = React.createContext();

export const EmployeeDirectoryContextProvider = (props) => {
  return (
    <EmployeeDirectoryContext.Provider value={props}>
      {props.children}
    </EmployeeDirectoryContext.Provider>
  );
};

EmployeeDirectoryContextProvider.propTypes = {
  children: PropTypes.node,
  inEmployeeDirectory: PropTypes.bool.isRequired,
  inviteEmployeesMetadata: PropTypes.shape({
    alertStack: PropTypes.object,
    selectedFilters: PropTypes.object,
    enabled: PropTypes.bool,
    show: PropTypes.bool,
    toggle: PropTypes.func,
    exportOccupationEnabled: PropTypes.bool,
    displayExportOccupations: PropTypes.bool,
    toggleDisplayExportOccupations: PropTypes.func,
    totalEmployees: PropTypes.number,
    invitableEmployeeCount: PropTypes.number
  })
};

EmployeeDirectoryContextProvider.defaultProps = {
  inEmployeeDirectory: true,
  inviteEmployeesMetadata: {
    alertStack: {},
    selectedFilters: {},
    enabled: true,
    show: true,
    toggle: () => null,
    exportOccupationEnabled: false,
    displayExportOccupations: false,
    toggleDisplayExportOccupations: () => null,
    totalEmployees: 0,
    invitableEmployeeCount: 0
  }
};
