import { connect } from 'react-redux';
import { BaseDirectorySearch } from './base_directory_search';
import { search } from './earner_directory_actions';

const Connected = connect(
  state => ({
    results: search.getResources(state),
    searchStatus: search.getStatus(state)
  }),
  dispatch => ({
    search: query => dispatch(search.action(query))
  })
)(BaseDirectorySearch);

export { Connected as DirectorySearch };
export const testing = { DirectorySearch: BaseDirectorySearch };
