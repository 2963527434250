import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Dialog from 'controls/dialog';
import { LoadingSpinner } from 'controls/loading_spinner';
import { ajax } from 'utils';
import { EmployeeDirectoryContext } from 'pages/workforce/employees/employees/employee_directory';

/**
 * Component that renders modal (Dialog), and Employee Invitation logic for Employee Directory.
 * Primary Dialog.Action triggers a POST request to send_invitations endpoint containing current
 * ElasticSearch filters set within DirectoryBase component.
 *
 * This component is configured to leverage the EmployeeDirectoryContext to alleviate prop amounts.
 *
 * @param {Object} props
 *   @param {String} props.organizationId - ID of current organization to be used in request.
 */

export const InviteEmployeesModal = (props) => {
  const [clickedSend, setClickedSend] = useState(false);
  const handleSend = useCallback(() => setClickedSend(true));

  const metadata = React.useContext(EmployeeDirectoryContext).inviteEmployeesMetadata;

  useEffect(() => {
    if (clickedSend) {
      const filterDataJson = metadata.selectedFilters.get();
      const sendInvitations = async () => {

        return ajax({
          method: 'POST',
          uri: `/api/v1/organizations/${props.organizationId}/employee_directory/send_invitations`,
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify({ filter: filterDataJson })
        });
      };
      sendInvitations().then(handleAjaxSuccess, handleError);
    }
  }, [clickedSend]);

  const handleError = () => {
    closeModal();
    metadata.alertStack.add({
      type: 'error',
      text: <FormattedMessage
        id="workforce.employees.error_toast_message"
        defaultMessage="An error has occurred. Please try again later."
      />
    });
  };

  const handleAjaxSuccess = (res) => {
    if (res.success && res.body) {
      closeModal();
      metadata.alertStack.add({
        type: 'notice',
        text: <FormattedMessage
          id="workforce.employees.success_toast_message"
          defaultMessage="Sending invitations. A confirmation will be emailed to you soon."
        />

      });
    } else {
      handleError();
    }
  };

  const closeModal = () => {
    setClickedSend(false);
    metadata.toggle();
  };

  return (
    <Dialog
      title={<FormattedMessage
        id="workforce.employees.title.invite_employees"
        defaultMessage="Invite Employees"
      />}
      show={metadata.show}
      shouldClose={closeModal}
    >
      <Dialog.Content>
        {clickedSend
          ? <LoadingSpinner />
          : (
            <>
              <FormattedMessage
                id="workforce.employees.invite_employees"
                defaultMessage="Are you sure you want to send invitations to {employeeCount} employees? If an employee has already accepted, they will not receive an invitation."
                values={{ employeeCount: metadata.invitableEmployeeCount }}
              />
              <Dialog.Footer confirmation>
                <Dialog.Action type="secondary">
                  <FormattedMessage
                    id="button.cancel"
                    defaultMessage="Cancel"
                  />
                </Dialog.Action>
                <Dialog.Action action={handleSend} noClose>
                  <FormattedMessage
                    id="button.send"
                    defaultMessage="Send"
                  />
                </Dialog.Action>
              </Dialog.Footer>
            </>
          )
        }
      </Dialog.Content>
    </Dialog>
  );
};

InviteEmployeesModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
};

InviteEmployeesModal.defaultProps = {
  organizationId: "123"
};
