import { SimpleButton } from 'controls/button';
import React, { useEffect, useState } from 'react';
import { uuidPropType } from 'prop_types';
import { useAlerts } from 'alert';
import ConfirmationModal from 'controls/confirmation_modal';
import { employeeActions } from '../../employees_actions';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

/**
 * Delete Employee component. When clicked will display a modal asking
 * confirmation to delete the employee.
 *
 * @param {Object} props
 *   @param {uuid} props.organization_id - The organization ID.
 *   @param {uuid} props.id - id of the employee
 *   @param {String} props.state - alternate button type based on employee state - optional
 *   @param {String} props.employeeName - name of the employee - optional
 *   @param {String} props.employeeEmail - email of the employee - optional
 */

export const DeleteEmployee = (props) => {
  const intl = useIntl();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteEmployeeState, deleteEmployee] = employeeActions.useAction('delete', props.id);
  const alerts = useAlerts();

  const removeEmployee = () => {
    deleteEmployee({ organization_id: props.organization_id, id: props.id });
  };

  useEffect(() => {
    if (deleteEmployeeState.status.failed) {
      setShowDeleteDialog(false);
      alerts.add({
        type: 'error',
        text: intl.formatMessage({
          id: 'workforce.employees.show.delete.failed.text',
          defaultMessage: 'Failed to remove employee.'
        })
      });
    }
  }, [deleteEmployeeState.status]);

  const buttonName = intl.formatMessage({
    id: 'workforce.employees.show.delete.button.delete_employee',
    defaultMessage: 'Remove Employee'
  });

  const confirmationModalTitle = intl.formatMessage({
    id: 'workforce.employees.show.delete.dialog.title',
    defaultMessage: 'Remove Employee'
  });

  const confirmationModalQuestion = intl.formatMessage({
    id: 'workforce.employees.show.delete.dialog.question',
    defaultMessage: 'Are you sure you want to remove the employee from your organization?'
  });

  const confirmationModalDescription = intl.formatMessage({
    id: 'workforce.employees.show.delete.dialog.description',
    defaultMessage: 'This employee’s details, and your access to their badges and skills will be removed from your organization. This action cannot be undone.'
  });

  const buttonType = () => {
    if (props.state) {
      return 'secondary';
    } else {
      return 'min-danger';
    }
  };

  return (
    <>
      <SimpleButton type={buttonType()} onClick={() => setShowDeleteDialog(true)} className="c-delete-employee">
        {buttonName}
      </SimpleButton>
      <ConfirmationModal
        title={confirmationModalTitle}
        question={confirmationModalQuestion}
        buttonAction={removeEmployee}
        buttonActionDescription={buttonName}
        description={confirmationModalDescription}
        objectDetail={props.employeeName}
        additionalInformation={props.employeeEmail}
        showModal={showDeleteDialog}
        setShowModal={setShowDeleteDialog}
        isLoading={deleteEmployeeState.status.pending}
      />
    </>
  );
};

DeleteEmployee.propTypes = {
  organization_id: uuidPropType,
  id: uuidPropType,
  state: PropTypes.string,
  employeeName: PropTypes.string,
  employeeEmail: PropTypes.string
};
