import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';
import { actionTypes } from 'redux-resource';

function bulkDeleteEmploymentsFromLearningPlan(dispatch, getState, action) {
  const deletedIds = action.res.body.data;
  const resourceType = 'managementOrganizationsWorkforceLearningPlanEmployments'; // Replace with the appropriate resource type

  dispatch({
    type: [actionTypes.DELETE_RESOURCES],
    resources: {
      [resourceType]: deletedIds
    }
  });
}

function deleteEmploymentFromLearningPlan(dispatch, getState, action) {
  const learningPlan = action.res.body.data;

  dispatch({
    type: actionTypes.UPDATE_RESOURCES,
    resources: {
      managementOrganizationsWorkforceLearningPlans: {
        [learningPlan.learning_plan_id]: {
          total_clicked: learningPlan.total_clicked,
          total_earned: learningPlan.total_earned,
          total_employments: learningPlan.total_employments,
          total_viewed: learningPlan.total_viewed
        }
      }
    }
  });
}

function updateRecommendationCelebratedAttr(dispatch, getState, action) {
  const badgeId = action.res.body.data.badge_id;
  const resources = Object.values(
    getState().managementOrganizationsWorkforceLearningPlanEmployments.resources
  );
  const recommendationId = resources.find(r => r.badge_id === badgeId)?.id;

  dispatch({
    type: actionTypes.UPDATE_RESOURCES,
    resources: {
      managementOrganizationsWorkforceLearningPlanEmployments: {
        [recommendationId]: { celebrated: true }
      }
    }
  });
}

export const LearningPlanActions =
  new ActionManager('managementOrganizationsWorkforceLearningPlans')
    .add(
      {
        requestKey: 'dashboard',
        url: '/api/v1/organizations/[organization_id]/workforce/learning_plans/dashboard',
        urlSubs: new stringUtils.BracketSubs({
          organization_id: { param: 'organization_id', remove: true }
        }),
        effect: 'read',
        method: 'GET',
        list: 'dashboard',
        alwaysRefresh: true
      }
    ).add(
      {
        requestKey: 'create',
        url: '/api/v1/organizations/[organization_id]/workforce/learning_plans',
        urlSubs: new stringUtils.BracketSubs(
          { organization_id: { param: 'organization_id', remove: true } }
        ),
        effect: 'create',
        method: 'POST',
        list: 'create',
        alwaysRefresh: true
      }
    ).add(
      {
        requestKey: 'update',
        url: '/api/v1/organizations/[organization_id]/workforce/learning_plans/[id]',
        urlSubs: new stringUtils.BracketSubs(
          { organization_id: { param: 'organization_id', remove: true } }
        ),
        effect: 'update',
        method: 'PUT',
        list: 'update',
        alwaysRefresh: true
      }, true
    ).add(
      {
        requestKey: 'show',
        url: '/api/v1/organizations/[organization_id]/workforce/learning_plans/[id]',
        urlSubs: new stringUtils.BracketSubs(
          { organization_id: { param: 'organization_id', remove: true } }
        ),
        effect: 'read',
        method: 'GET',
        list: 'show',
        alwaysRefresh: true
      }, true
    ).add(
      {
        requestKey: 'availableDirectReports',
        url: '/api/v1/organizations/[organization_id]/workforce/learning_plans/[learning_plan_id]/available_candidates',
        urlSubs: new stringUtils.BracketSubs(
          {
            organization_id: { param: 'organization_id', remove: true },
            learning_plan_id: { param: 'learning_plan_id', remove: true }
          }
        ),
        effect: 'read',
        method: 'GET',
        list: 'availableDirectReports',
        alwaysRefresh: true
      }
    ).add(
      {
        requestKey: 'allDirectReports',
        url: '/api/v1/organizations/[organization_id]/workforce/learning_plans/all_candidates',
        urlSubs: new stringUtils.BracketSubs(
          { organization_id: { param: 'organization_id', remove: true } }
        ),
        effect: 'read',
        method: 'GET',
        list: 'availableDirectReports',
        alwaysRefresh: true
      }
    ).add(
      {
        requestKey: 'show',
        url: '/api/v1/organizations/[organization_id]/workforce/learning_plans/[id]',
        urlSubs: new stringUtils.BracketSubs(
          { organization_id: { param: 'organization_id', remove: true } }
        ),
        effect: 'read',
        alwaysRefresh: true,
        method: 'GET'
      }, true
    );

export const LearningPlanEmploymentActions =
  new ActionManager('managementOrganizationsWorkforceLearningPlanEmployments')
    .add(
      {
        requestKey: 'create',
        url: '/api/v1/organizations/[organization_id]/workforce/learning_plans/[id]/employments/bulk_create',
        urlSubs: new stringUtils.BracketSubs(
          { organization_id: { param: 'organization_id', remove: true } }
        ),
        effect: 'create',
        method: 'POST',
        list: 'create',
        alwaysRefresh: true
      }
    ).add(
      {
        requestKey: 'index',
        url: '/api/v1/organizations/[organization_id]/workforce/learning_plans/[learning_plan_id]/employments',
        urlSubs: new stringUtils.BracketSubs(
          {
            organization_id: { param: 'organization_id', remove: true },
            learning_plan_id: { param: 'learning_plan_id', remove: true }
          }
        ),
        effect: 'read',
        method: 'GET',
        list: 'index',
        alwaysRefresh: true
      }
    ).add(
      {
        requestKey: 'delete',
        url: '/api/v1/organizations/[organization_id]/workforce/learning_plans/[learning_plan_id]/employments/[id]',
        urlSubs: new stringUtils.BracketSubs({
          organization_id: { param: 'organization_id', remove: true },
          learning_plan_id: { param: 'learning_plan_id', remove: true }
        }),
        effect: 'delete',
        method: 'DELETE',
        onSuccess: deleteEmploymentFromLearningPlan

      }, true
    ).add(
      {
        requestKey: 'deleteAll',
        url: '/api/v1/organizations/[organization_id]/workforce/learning_plans/[learning_plan_id]/employments/destroy_all',
        urlSubs: new stringUtils.BracketSubs({
          organization_id: { param: 'organization_id', remove: true },
          learning_plan_id: { param: 'learning_plan_id', remove: true }
        }),
        effect: 'delete',
        method: 'DELETE',
        onSuccess: bulkDeleteEmploymentsFromLearningPlan
      }
    );

export const CongratulationActions = new ActionManager('congratulations')
  .add(
    {
      requestKey: 'create',
      url: '/api/v1/congratulations',
      effect: 'create',
      method: 'POST',
      list: 'create',
      alwaysRefresh: true,
      onSuccess: updateRecommendationCelebratedAttr
    }
  );


