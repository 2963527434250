import { ActionManager } from 'utils/action_manager';
import { updatePublicBadgeAttributes } from 'badges';

export const CREATE = 'create';
export const DELETE = 'delete';

const updatePublicBadgeCongratsOnCreate = (dispatch, getState, action) => {
  updatePublicBadgeAttributes(dispatch, getState, action, {
    has_congratulated: true
  });
};

const updatePublicBadgeCongratsOnDelete = (dispatch, getState, action) => {
  updatePublicBadgeAttributes(dispatch, getState, action, {
    has_congratulated: false
  });
};

export const congratulateActions = new ActionManager('congratulations')
  .add({
    requestKey: CREATE,
    url: '/api/v1/congratulations?badge_id=[id]',
    effect: 'create',
    method: 'POST',
    onSuccess: updatePublicBadgeCongratsOnCreate
  })
  .add({
    requestKey: DELETE,
    url: '/api/v1/congratulations?badge_id=[id]',
    effect: 'delete',
    method: 'DELETE',
    onSuccess: updatePublicBadgeCongratsOnDelete
  });
