import React from 'react';
import PropTypes from 'prop-types';
import { OrgMgmtDataTable } from 'management/controls/org_mgmt_data_table';
import { BlockClickPropagate } from 'controls/block_click_propagate';
import { ClampLines } from 'controls/clamp_lines';
import { makeClassName } from 'utils';
import { ResourceStatusPropType } from 'utils/prop_types';
import {
  useStandardSortHandler, standardInitialSort
} from 'controls/data_table/use_standard_sort_handler';
import { useQueryString } from 'controls/with_query_string';
import { sentenceCase } from 'utils/string';
import { skillsInsightsUrl } from 'utils/routes';
import { useIntl } from 'react-intl';
import { useFaethmOccupationMappingEnabled } from '../../../faethm_occupation_mapping_hooks';
import { InfoModal } from 'pages/workforce/components/info_modal';

import './employee_skills_table.sass';

/**
 * A table view of skills earned by employees of
 * the viewing organization.
 *
 * @param {Object} props
 *   @param {Array} props.skills - Skill data array.
 */


export const EmployeeSkillsTable = (props) => {
  const intl = useIntl();
  const faethmMapped = useFaethmOccupationMappingEnabled();
  const columnCommonProps = {
    header: "c-workforce-employee-skills-table__column-header",
    column: "c-workforce-employee-skills-table__detail-column"
  };

  const sortHandler = useStandardSortHandler();
  const queryString = useQueryString(props.routeProps.location);

  const currentSort =
    standardInitialSort(queryString.get('sort')) || { key: 'name', ascending: true };

  const skillAreaHeader = intl.formatMessage({
    id: 'workforce.skills_table.skill_area_header',
    defaultMessage: 'Skill Area'
  });
  const occupationSkillsHeader = intl.formatMessage({
    id: 'workforce.skills_table.occupation_skills_header',
    defaultMessage: 'Occupation Skills'
  });
  const badgeSkillsHeader = intl.formatMessage({
    id: 'workforce.skills_table.verified_header',
    defaultMessage: 'Verified'
  });

  return (
    <div>
      <OrgMgmtDataTable
        data={props.data}
        header={{}}
        loader={{
          loading: props.status.pending
        }}
        hoverStyle="default"
        ariaLabel={intl.formatMessage({
          id: 'workforce.employees.skills.employee_skills.employee_skills_table.skills_listing',
          defaultMessage: 'Skills Listing'
        })}
        rowHref={(skill) => skillsInsightsUrl(skill.id)}
        sort={{ onSort: sortHandler, ...currentSort }}
      >
        <OrgMgmtDataTable.Col
          header={intl.formatMessage({
            id: 'workforce.employees.skills.employee_skills.employee_skills_table.name',
            defaultMessage: 'Name'
          })}
          width="col"
          className="c-workforce-employee-skills-table__skills-column"
          headerClassName={makeClassName(
            "c-workforce-employee-skills-table__column-header__skills",
            columnCommonProps.header
          )}
          sortKey="name"
          renderer={(data) => (
            <ClampLines
              attributes={{ className: 'c-employee-skills-table__name' }}
              lines={3}
            >
              {data.name}
            </ClampLines>
          )}
        />
        <OrgMgmtDataTable.Col
          width="col"
          hideAt={['mobile']}
          header={
            <>
              {skillAreaHeader}
              <BlockClickPropagate>
                <InfoModal
                  className="c-workforce-employee-skills-table__tooltip-wrapper"
                  title={skillAreaHeader}
                  body={intl.formatMessage({
                    id: 'workforce.skills_table.skill_area.tooltip_body',
                    defaultMessage: 'Skill area is a collection of skills that are categorized ' +
                      'based on their similarities and relationships according to ESCO skills, ' +
                      'O*NET job attributes and CRICOS education frameworks. Skill area offers ' +
                      'a group of more actionable skills.'
                  })}
                />
              </BlockClickPropagate>
            </>
          }
          className="c-workforce-employee-skills-table__skill-area-column"
          headerClassName={columnCommonProps.header}
          sortKey="skill_area_name"
          renderer={(data) => (sentenceCase(data.skill_area_name) || '').toLocaleString()}
        />
        {faethmMapped &&
          <>
            <OrgMgmtDataTable.Col
              width="col"
              hideAt={['mobile']}
              header={
                <>
                  {occupationSkillsHeader}
                  <BlockClickPropagate>
                    <InfoModal
                      className="c-workforce-employee-skills-table__tooltip-wrapper"
                      title={occupationSkillsHeader}
                      body={intl.formatMessage({
                        id: 'workforce.skills_table.occupation_skills.tooltip_body',
                        defaultMessage: 'The number of employees who are assumed to have this ' +
                          'skill, based on the skills associated with the professional ' +
                          'performance of a specific occupation.'
                      })}
                    />
                  </BlockClickPropagate>
                </>
              }
              className={columnCommonProps.column}
              headerClassName={columnCommonProps.header}
              sortKey="employee_inferred_count"
              initialSortDescending
              renderer={(data) => data.employee_inferred_count.toLocaleString()}
            />
            <OrgMgmtDataTable.Col
              width="col"
              hideAt={['mobile']}
              header={
                <>
                  {badgeSkillsHeader}
                  <BlockClickPropagate>
                    <InfoModal
                      className="c-workforce-employee-skills-table__tooltip-wrapper"
                      title={badgeSkillsHeader}
                      body={intl.formatMessage({
                        id: 'workforce.skills_table.badge_skills.tooltip_body',
                        defaultMessage: 'The number of employees who acquired this skill through ' +
                          'a badge.'
                      })}
                    />
                  </BlockClickPropagate>
                </>
              }
              className={columnCommonProps.column}
              headerClassName={columnCommonProps.header}
              sortKey="employee_credentialed_count"
              initialSortDescending
              renderer={(data) => data.employee_credentialed_count.toLocaleString()}
            />
          </>
        }
        <OrgMgmtDataTable.Col
          width="col"
          hideAt={["mobile"]}
          header={intl.formatMessage({
            id: 'workforce.employees.skills.employee_skills.employee_skills_table.total_employees',
            defaultMessage: 'Total Employees'
          })}
          className={columnCommonProps.column}
          headerClassName={columnCommonProps.header}
          sortKey="employee_count"
          initialSortDescending
          renderer={(data) => (data.employee_count || 0).toLocaleString()}
        />
      </OrgMgmtDataTable>
    </div>
  );
};

EmployeeSkillsTable.propTypes = {
  data: PropTypes.array,
  status: ResourceStatusPropType.isRequired,
  routeProps: PropTypes.object
};
