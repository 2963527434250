export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects, overwriting {target} with {sources}, except when the values being
 * merged are functions. In the case they are functions, a new function is created that calls
 * both the default and custom values for that key.
 *
 * @param target - the original object
 * @param ...sources - the object to merge on top of the original
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        // For values containing functions, we want to combine the default and custom functions,
        //  rather than simply overwrite the default
        if (typeof (source[key]) === 'function' && typeof (target[key]) === 'function') {
          const defaultFunction = target[key];
          const customFunction = source[key];
          const combinedFunction = function() {
            defaultFunction.call(this);
            customFunction.call(this, arguments[0].target);
          };
          Object.assign(target, { [key]: combinedFunction });
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  }
  return mergeDeep(target, ...sources);
}
