export const carouselCardTypes = {
  ORGANIZATION: 'organization',
  BADGE: 'badge',
  EMPLOYEE: 'employee'
};

export const carouselLayoutTypes = {
  WORKFORCE: 'workforce-content',
  DASHBOARD: 'dashboard-content',
  SKILLS_AND_OCCUPATION: 'skills-content'
};
