import React from 'react';
import PropTypes from 'prop-types';
import { combineLoadersIgnoreIdle } from 'utils/combine_loaders';
import { useEventCallback, useCallOnCondition } from 'utils/react_utils';
import { useAlerts } from 'alert';
import { faBadgeCheck, faUser, faBolt } from '@fortawesome/pro-regular-svg-icons';
import {
  faBadgeCheck as faBadgeCheckSolid,
  faUser as faUserSolid
} from '@fortawesome/pro-solid-svg-icons';
import { faBolt as faBoltSolid } from '@fortawesome/free-solid-svg-icons';
import { employments } from 'employments/action_creators';
import { PermissionsRequest } from './permissions_request';
import { PERMISSIONS_STATES } from './permissions_utils';
import { useIntl } from "react-intl";


const permissions = organization => ([
  {
    sectionHeader: {
      id: "accept_employment.organization.view.header",
      defaultMessage: "What will {organization} be able to view?",
      values: { organization }
    },
    sectionIconOpen: faBadgeCheckSolid,
    sectionIconClosed: faBadgeCheck,
    sectionOverview: {
      id: "accept_employment.organization.view.overview",
      defaultMessage: "Profile Data, Badge Data."
    },
    sectionItems: [
      {
        id: "accept_employment.organization.view.section1",
        defaultMessage: "Your profile information."
      },
      {
        id: "accept_employment.organization.view.section2",
        defaultMessage: "Your public badges."
      },
      {
        id: "accept_employment.organization.view.section3",
        defaultMessage: "Your skill interests."
      }]
  },
  {
    sectionHeader: {
      id: "accept_employment.organization.do.header",
      defaultMessage: "What will {organization} be able to do?",
      values: { organization }
    },
    sectionIconOpen: faBoltSolid,
    sectionIconClosed: faBolt,
    sectionOverview: {
      id: "accept_employment.organization.do.overview",
      defaultMessage: "Analytics"
    },
    sectionItems: [
      {
        id: "accept_employment.organization.do.section1",
        defaultMessage: "Make recommendations to you."
      },
      {
        id: "accept_employment.organization.do.section2",
        defaultMessage: "Send notifications to you."
      },
      {
        id: "accept_employment.organization.do.section3",
        defaultMessage: "Highlight your achievements to co-workers."
      },
      {
        id: "accept_employment.organization.do.section4",
        defaultMessage: "Add details to your profile visible only to you."
      }]
  },
  {
    sectionHeader: {
      id: "accept_employment.user.do.header",
      defaultMessage: "What will I be able to do?"
    },
    sectionIconOpen: faUserSolid,
    sectionIconClosed: faUser,
    sectionOverview: {
      id: "accept_employment.user.do.overview",
      defaultMessage: "Profile Data, Badge Management, Organization Data"
    },
    sectionItems: [
      {
        id: "accept_employment.user.do.section1",
        defaultMessage: "Own your profile."
      },
      {
        id: "accept_employment.user.do.section2",
        defaultMessage: "View details about your team."
      },
      {
        id: "accept_employment.user.do.section3",
        defaultMessage: "View details about your organization."
      },
      {
        id: "accept_employment.user.do.section4",
        defaultMessage: "Own your own data."
      }]
  }
]);

/**
 * Permissions UI for accepting an employment request.
 *
 * @param {Object} props
 *   @param {String} props.id - The request id.
 *   @param {String} props.requesterName - The name of the requester.
 *   @param {String} props.requesterImageUrl - The image of the requester needed for the header.
 *   @param {function(id: string)} props.onComplete - Called when accept or reject is complete.
 */
export const AcceptEmployment = props => {
  const alerts = useAlerts();
  const [acceptAction, accept] = employments.useAction('accept', props.id);
  const acceptRejectStatus = combineLoadersIgnoreIdle(acceptAction.status);

  const onClickAccept = useEventCallback(accept, false, [accept]);

  useCallOnCondition(() => {
    alerts.add({
      type: 'notice',
      head: 'Request succeeded!',
      text: `You have successfully connected to ${props.requesterName}.`
    });
  }, () => acceptAction.status.succeeded);

  useCallOnCondition(() => {
    alerts.add({
      type: 'error',
      head: 'Request failed',
      text:
        'An unexpected error caused the connection to fail. This page will be hidden until the ' +
        'next time you sign in.'
    });
  }, () => acceptRejectStatus.failed);

  useCallOnCondition(() => {
    props.onComplete(PERMISSIONS_STATES.accepted);
  }, () => acceptRejectStatus.succeeded || acceptRejectStatus.failed);

  return <>
    <PermissionsRequest.Header
      requesterImage={props.requesterImageUrl}
      requesterName={props.requesterName}
      heading={
        useIntl().formatMessage({
          id: "accept_employment.header",
          defaultMessage: "{organization} would like to connect with you"
        }, { organization: props.requesterName })
      }
      subHeading={
        useIntl().formatMessage({
          id: "accept_employment.subheading",
          defaultMessage: "By connecting with {organization} you can unlock an enhanced experience and explore new ways to learn and grow your skills."
        }, { organization: props.requesterName })
      }
    />
    <PermissionsRequest.Items requesterName={props.requesterName}>
      <PermissionsRequest.Items.AccordionGroup permissions={permissions(props.requesterName)} />
    </PermissionsRequest.Items>
    <PermissionsRequest.Actions
      acceptRejectStatus={acceptAction.status}
      onSkip={() => props.onComplete(PERMISSIONS_STATES.skipped)}
      onClickAccept={onClickAccept}
      acceptAction={acceptAction}
    />
  </>;
};

AcceptEmployment.propTypes = {
  id: PropTypes.string.isRequired,
  requesterName: PropTypes.string.isRequired,
  requesterImageUrl: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired
};
