import React from 'react';
import { SkillsDistribution } from './charts/skills_distribution/skills_distribution';
import { SkillsGainedChart } from './charts/skills_gained/skills_gained_chart';
import { FutureProofSkills } from './charts/future_proof_skills/future_proof_skills';

export const ChartDemos = () => {
  return (
    <div className="col">
      <SkillsDistribution />
      <SkillsGainedChart />
      <FutureProofSkills />
    </div>
  );
};
