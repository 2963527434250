import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import { Routes, stdCompare } from 'utils';
import { Button } from 'controls/button';
import { LoadingSpinner } from 'controls/loading_spinner';
import { Heading } from 'controls/heading';
import { managementShortOrganizationActions } from 'management/organizations/action_creators';
import {
  OrganizationSelectorCard
} from 'management/organizations/sidebar/organization_modal_selector/organization_selector_card';
import { useEnabledFeatures } from 'utils/enabled_features';

import './few_organizations_modal_selectors.sass';

/**
 * Renders content to be displayed in a modal for choosing between different organizations
 * that number 6 or lower.
 *
 * @property {string} current_organization_id - Id of the current organization that the user is
 * viewing.
 * @property {object} currentUser - Api resource object representing the current user
 * @property {string} onClose - Callback function to handle closing the modal view
 */
export const FewOrganizationsModalSelector = (props) => {
  const [fetchedOrganizationsState] =
    managementShortOrganizationActions.useOnMount('getOrganizations');
  const orgs = fetchedOrganizationsState.resources.sort(
    (org1, org2) => {
      if (org1.id === props.current_organization_id) {
        return -1;
      } else if (org2.id === props.current_organization_id) {
        return 1;
      } else {
        return stdCompare(org1.name, org2.name);
      }
    }
  );
  const handleAddOrganizationClick = useCallback(() => {
    Routes.setPath(Routes.createOrganizationUrl());
  }, []);

  const orgSelectorCards = () => {
    if (fetchedOrganizationsState.status.pending) {
      return <LoadingSpinner/>;
    } else {
      return orgs.map((org) => (
        <OrganizationSelectorCard
          className="c-few-organizations-modal-selector__org-cards__card"
          current={org.id === props.current_organization_id}
          key={org.id}
          organization={org}
        />
      ));
    }
  };
  const isFeatureEnabled = useEnabledFeatures();

  return (
    <div className="c-few-organizations-modal-selector">
      <div className="c-few-organizations-modal-selector__orgs">
        <Heading className="c-few-organizations-modal-selector__header" appearance="custom">Switch Org</Heading>
        <div className="c-few-organizations-modal-selector__org-cards">
          {orgSelectorCards()}
        </div>
      </div>
      <div className="c-few-organizations-modal-selector__buttons">
        <Button
          className="c-few-organizations-modal-selector__buttons__button"
          onClick={props.onClose}
          type="cancel"
        >
          Cancel
        </Button>
        {isFeatureEnabled('self-service-org-creation') &&
          props.currentUser.platform_manager &&
            <Button
              className="c-few-organizations-modal-selector__buttons__button"
              onClick={handleAddOrganizationClick}
            >
              Add Organization
            </Button>
        }
      </div>
    </div>
  );
};

FewOrganizationsModalSelector.propTypes = {
  current_organization_id: PropTypes.string,
  currentUser: PropTypes.object,
  onClose: PropTypes.func
};
