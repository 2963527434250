import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SimpleButton } from 'controls/button';
import { Heading } from 'controls/heading';
import dateUtils from 'utils/date.js';
import { usePermissions, Permissions } from 'management/organizations/permissions';
import { OrgMgmtDataTable } from 'management/controls/org_mgmt_data_table';
import { workforceSubscriptionsActions } from 'pages/workforce/accounts/subscriptions/actions';
import { employerStatsActions } from 'pages/workforce/employer_stats_actions';
import { combineLoaders } from 'utils/combine_loaders';
import { SubscriptionsRouteHelpers } from 'pages/workforce/route_helpers';
import './subscription_history.sass';

export const SubscriptionHistory = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const routeHelpers = SubscriptionsRouteHelpers.forUrl(props.baseUrl);
  const showBackButton = location.pathname.startsWith(location.state?.backScope);
  const [hasPermissions] = usePermissions();
  const isPlatformManager = !!hasPermissions(Permissions.PLATFORM_MANAGER);
  const [subscriptionData] = workforceSubscriptionsActions.useOnMount(
    'list',
    null,
    { organization_id: props.organization.id }
  );
  const [currentEmployerStatsState] = employerStatsActions.useOnMount('current', props.organization.id);
  const loadingStatus = combineLoaders(
    subscriptionData.status, currentEmployerStatsState.status
  );
  const newClicked = useCallback(
    () => {
      history.push(routeHelpers.new.route());
    }, [history, routeHelpers]);

  const handleRowClick = useCallback((rowData) => {
    history.push(routeHelpers.manage.parameterize({ id: rowData.id }));
  }, [history, props.baseUrl]);

  return (
    <div className="workforce-subscription-history__container container-fluid">
      <span className="workforce-account-subscription-history-another-flex-button">
        <Heading appearance="org-mgmt-subhead" className="c-subscription-history-title">
          <FormattedMessage
            id="workforce.account.subscription.history.page_title"
            defaultMessage="Subscription History"
          />
        </Heading>
        {isPlatformManager && (
          <SimpleButton
            onClick={newClicked}
            className="workforce-account-subscription-history-add-button"
          >
            <FormattedMessage
              id="workforce.accounts.subscription_history.add_subscription"
              defaultMessage="Add Subscription"
            />
          </SimpleButton>
        )}
      </span>
      {loadingStatus.succeeded &&
        <>
          <div className="row">
            <div className="col-12">
              <OrgMgmtDataTable
                data={subscriptionData.resources}
                onRowClick={handleRowClick}
                header={{}}
                ariaLabel={intl.formatMessage({
                  id: 'workforce.account.subscription.history.aria_label',
                  defaultMessage: 'Subscription History'
                })}
              >
                <OrgMgmtDataTable.Col
                  width="col-lg-3 col-sm-5"
                  header={intl.formatMessage({
                    id: 'workforce.subscription_history.start',
                    defaultMessage: 'Start'
                  })}
                  renderer={data => (
                    dateUtils.formatDateMonthShortYear(data.start_date)
                  )}
                />
                <OrgMgmtDataTable.Col
                  hideAt={["mobile", "tablet"]}
                  width="col-lg-3"
                  header={intl.formatMessage({
                    id: 'workforce.subscription_history.end',
                    defaultMessage: 'End'
                  })}
                  renderer={(data) => (
                    dateUtils.formatDateMonthShortYear(data.end_date)
                  )}
                />
                <OrgMgmtDataTable.Col
                  width="col-lg-3 col-sm-7"
                  header={intl.formatMessage({
                    id: 'workforce.subscription_history.subscription_limit',
                    defaultMessage: 'Subscription Limit'
                  })}
                  renderer={data => (
                    data.subscription_limit
                  )}
                />
                <OrgMgmtDataTable.Col
                  hideAt={["mobile", "tablet"]}
                  width="col-lg-3"
                  header={intl.formatMessage({
                    id: 'workforce.subscription_history.subscription_usage',
                    defaultMessage: 'Subscription Usage'
                  })}
                  renderer={() => currentEmployerStatsState.resources?.total_employees}
                />
              </OrgMgmtDataTable>
            </div>
          </div>
          {showBackButton &&
            <div className="workforce-subscriptions-history__button-container">
              <SimpleButton
                type="secondary"
                onClick={() => history.goBack()}
              >
                <FormattedMessage
                  id="workforce.subscription_history.back_button"
                  defaultMessage="Back"
                />
              </SimpleButton>
            </div>
          }
        </>
      }
    </div>
  );
};


SubscriptionHistory.propTypes = {
  organization: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired
};
