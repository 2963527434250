import { waitFor, ajax, generateUID } from 'utils';
import { Action } from 'utils/action';
import { ActionManager } from 'utils/action_manager';
import {
  getProfileAction,
  invalidatePublicUsers,
  profileSelfId,
  profileResource,
  profileCommonParams
} from 'profile';
import { publicUsers } from 'pages/public/user/action_creators';
import { actionTypes } from 'redux-resource';
import * as routes from 'utils/routes';
const PROCESS_TIMEOUT = 180000;

export const twoFactorAuthGetQRCode = new Action({
  resourceType: 'temp',
  requestKey: 'qrCode',
  id: 'qrCode',
  url: '/api/v1/users/self/qr_code',
  effect: 'read',
  method: 'GET'
});


/**
 * Use this to create a component which works on a single profile setting. For example:
 * <pre>
 * const mySettingName = 'my_setting_name';
 * export default connect(
 *   state => {
 *     let n = getProfileSettingAction(mySettingName);
 *     return {
 *       status: n.getStatus(state),
 *       value: n.getValue(state)
 *     };
 *   },
 *   dispatch => ({
 *     save: value => dispatch(getProfileSettingAction(mySettingName).setValue(value))
 *   })
 * )(MyComponent);
 * </pre>
 *
 * @param {String} key - The profile setting that this controls.
 * @returns {Action} - An Action object, extended with getValue() and setValue()
 */
export const getProfileSettingAction = key => {
  if (!profileSettings.has(key)) {
    profileSettings.add({
      id: profileSelfId,
      requestKey: key,
      resourceType: 'profile',
      url: '/api/v1/users/self',
      effect: 'update',
      method: 'PUT'
    });
    const setting = profileSettings.get(key);
    setting.setValue = value => setting.action({ [key]: value });
    setting.getValue = state => setting.getResources(state)[key];
  }
  return profileSettings.get(key);
};
const profileSettings = new ActionManager('profile');


export const deleteProfileAction = new Action({
  ...profileCommonParams,
  requestKey: 'deleteProfile',
  effect: 'delete',
  method: 'DELETE',
  // Do this here instead of in ConfirmDeleteProfile, because once your account is deleted, that
  // page and its parents can no longer be rendered properly.
  onSuccess: (dispatch, getState, action) => {
    routes.setPath(routes.userRemovedUrl());
  }
});

export const updatePasswordAction = new Action({
  ...profileCommonParams,
  requestKey: 'updatePassword',
  url: '/api/v1/users/self/password',
  effect: 'update',
  method: 'PUT'
});

export const updateAvatarAction = new Action({
  ...profileCommonParams,
  requestKey: 'updateAvatar',
  effect: 'update',
  method: 'PUT',
  onSuccess: invalidatePublicUsers
});

// Language
export const language = (new ActionManager('language'))
  .add({
    requestKey: 'get',
    url: '/api/v1/users/self/locale',
    effect: 'read',
    method: 'GET'
  })
  .add({
    requestKey: 'put',
    url: '/api/v1/users/self/locale',
    effect: 'update',
    method: 'PUT'
  });

// Reload the public profile when job preferences change
// to get the latest value for messaging enabled.
const jobPreferencesOnSuccess = (dispatch, getState) => {
  const userVanitySlug = profileResource(getState()).vanity_slug;
  dispatch(publicUsers.get('get', userVanitySlug).action());
};

export const jobPreferencesActionManager = new ActionManager('jobPreferences')
  .add({
    id: profileSelfId,
    requestKey: 'fetch',
    effect: 'read',
    method: 'GET',
    url: '/api/v1/users/self/job_preferences/self'
  }, true)
  .add({
    id: profileSelfId,
    requestKey: 'update',
    effect: 'update',
    method: 'PUT',
    url: '/api/v1/users/self/job_preferences/self',
    onSuccess: jobPreferencesOnSuccess
  }, true)
  .add({
    id: profileSelfId,
    requestKey: 'delete',
    effect: 'delete',
    method: 'DELETE',
    url: '/api/v1/users/self/job_preferences/self',
    onSuccess: jobPreferencesOnSuccess
  }, true);

// Secondary emails actions.
// Retrieve data with:
//   secondaryEmails.get(list|add)
// Retrieve id-based data with:
//   secondaryEmails.get(remove|verify|setDefault)
export const secondaryEmails = (new ActionManager('secondaryEmails'))
  .add({
    requestKey: 'list',
    url: '/api/v1/users/self/secondary_email_addresses',
    effect: 'read',
    method: 'GET'
  })
  .add({
    requestKey: 'add',
    url: '/api/v1/users/self/secondary_email_addresses',
    effect: 'update',
    method: 'POST'
  })
  .add({
    requestKey: 'remove',
    url: '/api/v1/users/self/secondary_email_addresses/[id]',
    effect: 'delete',
    method: 'DELETE'
  }, true)
  .add({
    requestKey: 'verify',
    url: '/api/v1/users/self/secondary_email_addresses/[id]/resend_confirmation',
    effect: 'update',
    method: 'POST'
  }, true)
  .add({
    requestKey: 'setDefault',
    url: '/api/v1/users/self/secondary_email_addresses/[id]/promote',
    // Delete the email from the secondary list.
    effect: 'delete',
    method: 'PUT',
    // This endpoint doesn't return any data, and we need the new email key. The only option is
    // to refresh the list.
    onSuccess: (dispatch, getState, action) => {
      // Refresh the email list.
      dispatch(secondaryEmails.get('list').action());

      // Find the new default email address.
      const email = (() => {
        try {
          const id = action.resources[0].id;
          return getState().secondaryEmails.resources[id].email;
        } catch (e) {
        }
        return null;
      })();

      if (email) {
        // Update the one piece of data that changed.
        dispatch({
          type: actionTypes.UPDATE_RESOURCES,
          resources: {
            profile: {
              [profileSelfId(getState().profile)]: { email: email }
            }
          }
        });
      } else {
        // This should never happen, but re-fetch the profile if it does, so the UI can update
        // properly.
        dispatch(getProfileAction.action());
      }
    }
  }, true);


export const mergeAccounts = {
  login: new Action({
    resourceType: 'mergeAccounts',
    requestKey: 'login',
    url: '/api/v1/users/self/send_merge_account_confirmation',
    effect: 'update',
    method: 'POST',
    defaultError: 'Either your username or password is invalid. Please try again.'
  }),
  // Response.data looks like this:
  // {
  //   "source_user":"user1@credly.com",
  //   "destination_user":"user2@credly.com",
  //   "token":"nQi3-h4Pp-TDi2-LArX-zAJf",
  //   "badges":{"accepted": 1, "pending": 1},
  //   "emails_to_merge":["user1@credly.com","user1+1@credly.com"]
  // }
  checkAuthCode: new Action({
    resourceType: 'mergeAccounts',
    requestKey: 'checkAuthCode',
    url: '/api/v1/users/self/confirm_merge_authorization',
    effect: 'update',
    method: 'POST',
    defaultError: 'That authorization code is invalid. Please try again.'
  }),
  // Takes this in the body:
  // {
  //   "badges":{"accepted":1, "pending":1},
  //   "destination_user":"user2@credly.com",
  //   "emails_to_merge":["user1@credly.com","user1+1@credly.com"],
  //   "merge_token":"ljAj-k9b7-6XRr-oHAo-947i",
  //   "source_user":"user1@credly.com"
  // }
  // This is the same as the body of confirm_merge_authoirization,
  // except "token" becomes "merge_token"
  merge: new Action({
    resourceType: 'mergeAccounts',
    requestKey: 'merge',
    url: '/api/v1/users/self/perform_merge_account',
    effect: 'update',
    method: 'POST',
    // This action adds emails to the list, so refresh the email list.
    // TODO: If we start caching the badge list, we should also invalidate that.
    onSuccess: dispatch => dispatch(secondaryEmails.get('list').action())
  })
};

/**
 * The image profile touches three separate endpoints, including one on s3. Rather than create
 * three global reducers or wedge this into the current redux slice scheme, create a custom reducer.
 */
export class ProfileUploadActions {
  /**
   * Get the redux bindings for these actions.
   *
   * @param {Function} dispatch
   * @returns {Object}
   */
  static bind(dispatch) {
    const actions = new ProfileUploadActions();
    return {
      upload: imageBlob => dispatch(actions.upload(imageBlob)),
      remove: () => dispatch(actions.remove())
    };
  }


  /**
   * Remove the avatar from the user's profile.
   *
   * @returns {function}
   */
  remove() {
    return dispatch => dispatch(updateAvatarAction.action({ photo: { _destroy: true } }));
  }


  /**
   * Redux dispatcher to upload a profile image.
   *
   * @param {Blob} imageBlob
   * @returns {Function}
   */
  upload(imageBlob) {
    return async dispatch => {
      dispatch({
        type: actionTypes.UPDATE_RESOURCES_PENDING,
        requestKey: 'updateAvatar',
        resourceType: 'profile'
      });

      let urls;
      let lastSuccess = 'start';
      try {
        const s3Params = await this._getS3Params();

        if (s3Params) {
          lastSuccess = 'getS3Params';
          const uploadResponse = await this._uploadFile(s3Params, imageBlob);
          if (uploadResponse) {
            lastSuccess = 'uploadFile';
            const imageId = await this._sendToAcclaim(uploadResponse);
            if (imageId) {
              lastSuccess = 'sendToAcclaim';
              urls = await this._waitForImage(imageId);
            }
          }
        }
      } catch (e) {
        // Log promise exceptions to make debugging easier.
        console.error(e);
      }

      if (urls) {
        // Because we dispatch here, we end up with two UPDATE_RESOURCES_PENDING dispatches.
        // It would be nice to fix that, but it's harmless.
        dispatch(updateAvatarAction.action({ photo: { id: urls.id } }));
      } else {
        console.error('Profile image upload failed. Last success=' + lastSuccess);
        dispatch({
          type: actionTypes.UPDATE_RESOURCES_FAILED,
          requestKey: 'updateAvatar',
          resourceType: 'profile',
          requestProperties: {
            result: { message: 'Upload failed' }
          }
        });
      }
    };
  }


  /**
   * Get parameters required by S3.
   *
   * @returns {Promise.<Object>}
   * @private
   */
  async _getS3Params() {
    const result = await ajax({
      uri: '/s3_image_processor/data_attributes',
      headers: {
        Accept: 'application/json'
      },
      responseType: 'json'
    });

    return result.success && result.body;
  }


  /**
   * Upload a file to S3.
   *
   * @param {Object} s3Params - The result of _getS3Params()
   * @param {Blob} blob - The image
   * @returns {Promise.<{id:string, url:string}>}
   * @private
   */
  async _uploadFile(s3Params, blob) {
    const data = new FormData();

    const copyParams = {
      acl: 'data-acl',
      AWSAccessKeyId: 'data-aws-access-key-id',
      policy: 'data-policy',
      signature: 'data-signature'
    };

    // Simple translations
    for (const p of Object.keys(copyParams)) {
      data.append(p, s3Params[copyParams[p]]);
    }

    data.append('success_action_status', '201');
    data.append('Content-Type', blob.type);
    data.append('X-Requested-With', 'xhr');

    const key = s3Params['data-key']
      .replace(/{timestamp}/, Date.now())
      .replace(/{unique_id}/, generateUID());
    data.append('key', key);

    data.append('file', blob);

    const result = await ajax({
      method: 'POST',
      uri: s3Params['data-url'],
      body: data
    });

    if (result.success) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(result.body, 'text/xml');
      return {
        // IE11 doesn't support innerHTML in DOMParser, so use firstChild.nodeValue instead.
        id: xmlDoc.getElementsByTagName('Key')[0].firstChild.nodeValue,
        url: xmlDoc.getElementsByTagName('Location')[0].firstChild.nodeValue
      };
    }

    return null;
  }


  /**
   * Send the S3 URL to Acclaim.
   *
   * @param {Object} s3Response - The result of _uploadFile()
   * @returns {Promise.<String>}
   * @private
   */
  async _sendToAcclaim(s3Response) {
    const result = await ajax({
      method: 'POST',
      uri: '/s3_image_processor/process',
      headers: {
        Accept: 'application/json'
      },
      body: {
        image_url: s3Response.url,
        processor_name: 'image_upload',
        // id: s3Response.id,
        delay: true,
        delay_queue: 'interactive'
      }
    });

    if (result.success) {
      let body = result.body;
      if (typeof body === 'string') {
        // Even though the resulting content-type is application/json, the data comes back as a
        // string. Until we figure it out, parse here.
        body = JSON.parse(body);
      }
      return body.id;
    }
    return null;
  }


  /**
   * Wait for the image to be processed.
   *
   * @param {String} id - The image id from _sendToAcclaim()
   * @returns {Promise.<String>} - The new avatar URL
   * @private
   */
  async _waitForImage(id) {
    return await waitFor(async () => {
      const response = (await ajax({
        uri: '/s3_image_processor/process/image_upload/' + id,
        headers: {
          Accept: 'application/json'
        },
        responseType: 'json'
      })).body;

      if (response && response.image_upload && response.image_upload.complete) {
        return response.image_upload;
      }
      return null;
    }, 1000, PROCESS_TIMEOUT / 1000);
  }
}
