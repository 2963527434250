export const toolTipPositioner = function(labelWidth, labelHeight, point) {
  var tooltipX, tooltipY;
  var chartWidth = this.chart.chartWidth;

  // If the tooltip extends beyond the right side of the chart,
  // move it to the left by the amount it exceeds
  if (point.plotX + labelWidth / 2 > chartWidth) {
    tooltipX =
      point.plotX + this.chart.plotLeft - labelWidth /
      2 - (point.plotX + labelWidth / 2 - chartWidth);
  } else if (point.plotX - labelWidth / 2 < 0) {
    tooltipX = this.chart.plotLeft;
  } else {
    tooltipX = point.plotX + this.chart.plotLeft - labelWidth / 2;
  }

  tooltipY = point.plotY - labelHeight - 20;

  return {
    x: tooltipX,
    y: tooltipY
  };
};
