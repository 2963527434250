import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAlerts } from 'alert';
import { GenericSectionHeader, WithSectionHeader } from 'controls/section_header';
import { SubscriptionForm } from '../../components/subscription_form';
import { workforceSubscriptionsActions } from '../../actions';
import { SubscriptionsRouteHelpers } from '../../../../route_helpers';

export const SubscriptionAdd = ({ organization, baseUrl }) => {
  const history = useHistory();
  const intl = useIntl();
  const alerts = useAlerts();
  const routeHelpers = SubscriptionsRouteHelpers.forUrl(baseUrl);

  const submitAction = workforceSubscriptionsActions.get('create');
  const initialValues = { organization_id: organization.id };

  const onSubmitSuccess = () => {
    alerts.add({
      type: 'notice',
      text: intl.formatMessage({
        id: 'workforce.account.subscription.create.success',
        defaultMessage: 'Subscription created successfully.'
      })
    });
    history.push(routeHelpers.root.route());
  };

  const sectionHeader = useCallback(
    () => {
      const headerText = intl.formatMessage({
        id: 'workforce.subscription.create.section_header.heading',
        defaultMessage: 'Create Subscription'
      });

      return <GenericSectionHeader heading={headerText} breadcrumbs={false} />;
    },
    [intl]
  );

  return (
    <WithSectionHeader sectionHeader={sectionHeader}>
      <div className="c-subscription-add">
        <SubscriptionForm
          submitAction={submitAction}
          initialValues={initialValues}
          onSubmitSuccess={onSubmitSuccess}
          organization={organization}
        />
      </div>
    </WithSectionHeader>
  );
};

SubscriptionAdd.propTypes = {
  organization: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired
};
