import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { EllipsisMenu } from 'controls/ellipsis_menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";
import { faPartyHorn } from "@fortawesome/pro-regular-svg-icons/faPartyHorn";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare";
import { useIntl } from 'react-intl';
import { makeClassName } from 'utils';

import './learning_plan_recommendation_dropdown.sass';

export const LearningPlanRecommendationDropdown = ({
  celebrateEnabled,
  celebrate,
  redirectToProfile,
  renderRemoveRecommendationModal
}) => {
  const intl = useIntl();

  const dropdownItems = useCallback(() => {
    return [
      {
        itemIcon: faPartyHorn,
        itemClassName: 'ac-party-horn__icon',
        value: celebrate,
        enabled: celebrateEnabled,
        label: intl.formatMessage({
          id: 'workforce.learning.learning_plans.list.menu.celebrate',
          defaultMessage: 'Celebrate'
        })
      },
      {
        itemIcon: faArrowUpRightFromSquare,
        itemClassName: 'ac-view-prifile__icon',
        value: redirectToProfile,
        enabled: true,
        label: intl.formatMessage({
          id: 'workforce.learning.learning_plans.list.menu.view_profile',
          defaultMessage: 'View Profile'
        })
      },
      {
        itemIcon: faTrash,
        itemClassName: 'ac-trash__icon',
        ellipsisItemClassName: 'ac-trash__item',
        value: renderRemoveRecommendationModal,
        enabled: true,
        label: intl.formatMessage({
          id: 'workforce.learning.learning_plans.list.menu.remove',
          defaultMessage: 'Remove'
        })
      }
    ];
  }, [renderRemoveRecommendationModal, intl]);

  const items = dropdownItems();

  return (
    <EllipsisMenu
      onSelect={(openModalFunc) => openModalFunc()}
      appearance="enclosed"
      containerClassName={
        makeClassName(
          "c-workforce-learning-plan-employments-list__more-button ",
          "c-workforce-learning-employee_dropdown"
        )
      }
    >
      {items.map((item, index) => (
        <EllipsisMenu.Item
          key={index}
          value={item.value}
          disabled={!item.enabled}
          className={item.ellipsisItemClassName}
        >
          <FontAwesomeIcon icon={item.itemIcon} className={item.itemClassName}/>
          {item.label}
        </EllipsisMenu.Item>
      ))}
    </EllipsisMenu>
  );
};

LearningPlanRecommendationDropdown.propTypes = {
  renderRemoveRecommendationModal: PropTypes.func,
  celebrateEnabled: PropTypes.bool,
  celebrate: PropTypes.func,
  redirectToProfile: PropTypes.func
};
