import React from 'react';
import PropTypes from 'prop-types';
import { ErrorView } from 'pages/error_views';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle";
import { organizationManagementUrl } from 'utils/routes';

const OrganizationNotFoundError = props => {
  const message = "The organization you selected could not be found.";
  return (
    <ErrorView
      title="Error"
      subTitle="We're sorry"
      message={message}
      icon={
        <FontAwesomeIcon
          icon={faExclamationTriangle}
        />
      }
      suppressContainer={props.suppressContainer}
      links={[
        { text: 'Back to management index', link: organizationManagementUrl.root() }
      ]}
    />
  );
};

OrganizationNotFoundError.propTypes = {
  suppressContainer: PropTypes.bool
};

export { OrganizationNotFoundError };
