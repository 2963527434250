/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAlerts } from 'alert';
import { GenericSectionHeader, WithSectionHeader } from 'controls/section_header';
import { SubscriptionsRouteHelpers } from 'pages/workforce/route_helpers';
import { useIntl } from 'react-intl';
import { SubscriptionForm } from '../../components/subscription_form';
import { workforceSubscriptionsActions } from '../../actions';

export const SubscriptionEdit = ({ organization, baseUrl }) => {
  const intl = useIntl();
  const alerts = useAlerts();
  const history = useHistory();
  const routeHelpers = SubscriptionsRouteHelpers.forUrl(baseUrl);

  const { id: subscriptionId } = useParams();
  const [subscriptionState, subscriptionAction] = workforceSubscriptionsActions.useAction('show', subscriptionId);
  const loadingStatus = subscriptionState.status;

  useEffect(() => {
    if (loadingStatus.idle) {
      subscriptionAction({ organization_id: organization.id });
    }
  }, [subscriptionAction, loadingStatus]);

  const subscription = useMemo(() => {
    return subscriptionState.resources;
  }, [subscriptionState.resources]);

  const submitAction = workforceSubscriptionsActions.get('update', subscriptionId);
  const staticParams = {
    organization_id: organization.id,
    id: subscription?.id
  };
  const initialValues = {
    payment_type: subscription?.payment_type,
    annual_auto_renew: subscription?.annual_auto_renew,
    start_date: subscription?.start_date,
    end_date: subscription?.end_date,
    subscription_limit: subscription?.subscription_limit
  };


  const onSubmitSuccess = () => {
    alerts.add({
      type: 'notice',
      text: intl.formatMessage({
        id: 'workforce.account.subscription.edit.success',
        defaultMessage: 'Subscription updated successfully.'
      })
    });
    history.push(routeHelpers.manage.parameterize({ id: subscriptionId }));
  };

  const sectionHeader = useCallback(
    () => {
      const headerText = intl.formatMessage({
        id: 'workforce.account.subscription.edit.page_title',
        defaultMessage: 'Edit Subscription'
      });

      return <GenericSectionHeader heading={headerText} breadcrumbs={false} />;
    },
    [intl]
  );

  return (
    <WithSectionHeader sectionHeader={sectionHeader}>
      {loadingStatus.succeeded &&
        <div className="c-subscription-edit">
          <SubscriptionForm
            onSubmitSuccess={onSubmitSuccess}
            organization={organization}
            submitAction={submitAction}
            initialValues={initialValues}
            staticParams={staticParams}
          />
        </div>
      }
    </WithSectionHeader>
  );
};

SubscriptionEdit.propTypes = {
  organization: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired
};
