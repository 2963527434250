import { useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useQueryString } from 'controls/with_query_string';
import urlUtils from 'utils/url';

export const useStandardSortHandler = () => {
  const location = useLocation();
  const history = useHistory();
  const queryString = useQueryString(location);

  return useCallback((sortKey, ascending) => {
    const keyWithDirection = ascending ? sortKey : `-${sortKey}`;
    const newQuery = queryString.clone();
    newQuery.set('sort', keyWithDirection);
    newQuery.removeKey('page');

    history.push(urlUtils.makeUrl(location.pathname, newQuery));
  }, [location.pathname, queryString]);
};

export const standardInitialSort = (sort) => {
  if (!sort) return;

  const isAscending = sort[0] !== '-';
  return {
    key: isAscending ? sort : sort.slice(1),
    ascending: isAscending
  };
};
