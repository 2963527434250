import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { WithSectionHeader } from 'controls/section_header';
import { LearningSectionHeader } from "./learning_section_header";
import { Routes } from './routes';

/**
 * Layout for the learning workforce page.
 *
 * @param {Object} props
 *  @param {String} props.baseUrl
 *  @param {Object} props.organization
 *
 * @return {JSX.Element}
 */

export const LearningLayout = ({ routeProps, organization }) => {
  const baseUrl = routeProps.match.url;
  const sectionHeader = useCallback(() => (
    <LearningSectionHeader/>
  ), []);

  return (
    <WithSectionHeader sectionHeader={sectionHeader}>
      <Routes organization={organization} routeProps={routeProps}/>
    </WithSectionHeader>
  );
};

LearningLayout.propTypes = {
  routeProps: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired
};
