import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Dialog from 'controls/dialog';

const modalBody = 'Recommend {badgeName} to {totalDirectReports} employees. ' +
  'Employees will receive an email and in-app notification. Once recommended ' +
  'you will be able to track their progress.';

export const ConfirmationModal = (props) => {
  const { show, toggleShow, submitForm, selectedDirectReports, badgeTemplateName } = props;
  const intl = useIntl();

  return (
    <Dialog
      title={intl.formatMessage({
        id: 'workforce.learning.confirmation_modal.header',
        defaultMessage: 'Recommend this badge'
      })}
      show={show}
      size="half"
      shouldClose={toggleShow}
      titleTheme="small"
    >
      <Dialog.Content>
        <>
          <FormattedMessage
            id="workforce.learning.confirmation_modal.body"
            defaultMessage={modalBody}
            values={{
              badgeName: badgeTemplateName,
              totalDirectReports: selectedDirectReports.length
            }}
          />
          <Dialog.Footer confirmation>
            <Dialog.Action type="min" >
              <FormattedMessage
                id="workforce.learning.confirmation_modal.cancel"
                defaultMessage="Cancel"
              />
            </Dialog.Action>

            <Dialog.Action action={submitForm}>
              <FormattedMessage
                id="workforce.learning.confirmation_modal.confirm"
                defaultMessage="Confirm"
              />
            </Dialog.Action>
          </Dialog.Footer>
        </>
      </Dialog.Content>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleShow: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  badgeTemplateName: PropTypes.string.isRequired,
  selectedDirectReports: PropTypes.array.isRequired
};
