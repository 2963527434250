import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import dateUtils from 'utils/date';
import { combineLoaders } from 'utils/combine_loaders';
import { Button } from 'controls/button';
import { employerStatsActions } from 'pages/workforce/employer_stats_actions';
import { workforceSubscriptionsActions } from 'pages/workforce/accounts/subscriptions/actions';
import { usePermissions, Permissions } from 'management/organizations/permissions';
import { Donut, DonutLoading } from 'pages/workforce/components/charts/donut';
import { Chiclet, ChicletLoading } from 'pages/workforce/components/chiclet';
import { DeleteSubscription } from '../../components/delete_subscription';
import { SubscriptionsRouteHelpers } from 'pages/workforce/route_helpers';

import './subscription_management.sass';

const DONUT_COLORS = {
  total_employees: '#005850',
  remaining: '#EFEFEF'
};

const DONUT_LABELS_I18N_MAP = {
  total_employees: {
    id: 'workforce.account.subscription.employees',
    defaultMessage: 'Employees'
  },
  remaining: {
    id: 'workforce.account.subscription.employees_remaining',
    defaultMessage: 'Employees remaining'
  }
};

const EMPTY_SUBSCRIPTION = {
  start_date: '2023-01-01',
  end_date: '2024-01-01',
  total_employees: 0,
  subscription_limit: 0
};

const EMPTY_EMPLOYER_STAT = {
  date: '000-01-01',
  total_accepted: 0,
  total_pending: 0,
  total_rejected: 0,
  total_badges: 0,
  total_skills_by_earner: 0
};

/**
 * Renders the Subscription Management page within Workforce > Account.
 *
 * @param props
 *   @param {string} props.organization - Current Workforce Organization
 * @returns {JSX.Element}
 * @constructor
 */
export const SubscriptionManagement = (props) => {
  const intl = useIntl();
  const history = useHistory();

  const routeHelpers = SubscriptionsRouteHelpers.forUrl(props.baseUrl);
  const routeProps = props.routeProps;

  const subscriptionId = props.subscriptionId;
  const [workforceSubscriptionState, workforceSubscriptionAction] =
    workforceSubscriptionsActions.useAction('show', subscriptionId);
  const [currentEmployerStatsState] = employerStatsActions.useOnMount('current', props.organization.id);
  const loadingStatus = combineLoaders(
    workforceSubscriptionState.status, currentEmployerStatsState.status
  );

  useEffect(() => {
    if (loadingStatus.idle) {
      workforceSubscriptionAction({ organization_id: props.organization.id });
    }
  }, [loadingStatus, workforceSubscriptionAction]);

  const handleEditClick = () => {
    const subscription = workforceSubscriptionState.resources;
    routeProps.history.push(routeHelpers.edit.parameterize({ id: subscription.id }));
  };

  const [hasPermissions] = usePermissions();
  const readPermissions = hasPermissions(Permissions.WORKFORCE_SUBSCRIPTION_READ);
  const managePermissions = hasPermissions(Permissions.WORKFORCE_SUBSCRIPTION_MANAGE);

  const workforceSubscriptionData = useMemo(() => {
    return workforceSubscriptionState.resources || EMPTY_SUBSCRIPTION;
  }, [workforceSubscriptionState.resources]);

  const employerStatsData = useMemo(() => {
    return currentEmployerStatsState.resources || EMPTY_EMPLOYER_STAT;
  }, [currentEmployerStatsState.resources]);

  const unlimitedSubscription = useMemo(() => {
    return workforceSubscriptionData.is_unlimited;
  }, [workforceSubscriptionData]);

  const {
    data: subscriptionData,
    categoryColors
  } = useTranslatedDonutLabels(
    workforceSubscriptionData.subscription_limit, employerStatsData.total_employees
  );

  const [startDate, shortEndDate, endDate, subscriptionLimit, totalEmployees] = useMemo(() => {
    return [
      abbreviateYear(dateUtils.formatShortMonthYear(workforceSubscriptionData.start_date)),
      abbreviateYear(dateUtils.formatShortMonthYear(workforceSubscriptionData.end_date)),
      dateUtils.formatDateMonthShortYear(workforceSubscriptionData.end_date),
      unlimitedSubscription ? 'Unlimited' : workforceSubscriptionData.subscription_limit,
      unlimitedSubscription ? 'Unlimited' : employerStatsData.total_employees
    ];
  }, [workforceSubscriptionData, employerStatsData, unlimitedSubscription]);

  const goToHistory = () => {
    history.push({
      pathname: routeHelpers.history.parameterize({ organization_id: props.organization.id }),
      state: {
        backScope: routeHelpers.root.parameterize({ organization_id: props.organization.id })
      }
    });
  };

  return (
    <div className="col">
      <div className="row">
        <div className="col-12 workforce-subscription__date-header">
          <h1 className="ac-heading ac-heading--custom cr-generic-section-header__heading">
            {loadingStatus.succeeded &&
              <>{`${startDate} - ${shortEndDate}`}</>
            }
          </h1>
        </div>
      </div>
      {!unlimitedSubscription &&
        <div className="row workforce-subscription__donut-row">
          <div className="col-12">
            <div className="workforce-subscription__donut">
              {
                (loadingStatus.idle || loadingStatus.pending)
                  ? <DonutLoading/>
                  : (
                    <Donut
                      data={subscriptionData}
                      categoryColors={categoryColors}
                      highlightedCategory={intl.formatMessage(
                        DONUT_LABELS_I18N_MAP.total_employees
                      )}
                      highlightedCategoryLabel={{
                        id: 'workforce.account.subscription.usage_label',
                        defaultMessage: '<line>{percentage, number, ::percent}</line>' +
                          '<line>Used</line>'
                      }}
                    />
                  )
              }
            </div>
          </div>
        </div>
      }
      <div className="row workforce-subscription__chiclet-row">
        {
          (loadingStatus.idle || loadingStatus.pending)
            ? (
              <>
                <div className="col-lg-4 col-sm-12">
                  <ChicletLoading/>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <ChicletLoading/>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <ChicletLoading/>
                </div>
              </>
            )
            : (
              <>
                <div className="col-lg-4 col-sm-12">
                  <Chiclet
                    displayStatic={totalEmployees}
                    label={intl.formatMessage({
                      id: "workforce.account.subscription.employees",
                      defaultMessage: "Employees"
                    })}
                  />
                </div>
                <div className="col-lg-4 col-sm-12">
                  <Chiclet
                    displayStatic={subscriptionLimit}
                    label={intl.formatMessage({
                      id: "workforce.account.subscription.employee_allowance",
                      defaultMessage: "Employee allowance"
                    })}
                  />
                </div>
                <div className="col-lg-4 col-sm-12">
                  <Chiclet
                    displayStatic={endDate}
                    label={intl.formatMessage({
                      id: "workforce.account.subscription.renewal_date",
                      defaultMessage: "Renewal date"
                    })}
                  />
                </div>
              </>
            )
        }
      </div>
      {loadingStatus.succeeded &&
        <div className="row workforce-subscription__button-row">
          {(readPermissions || managePermissions) &&
            <Button
              onClick={goToHistory}
              className="workforce-subscription_button"
            >
              <FormattedMessage
                id="workforce.account.subscription.view_history"
                defaultMessage="View History"
              />
            </Button>
          }
          {managePermissions &&
            <>
              <DeleteSubscription
                organizationId={props.organization.id}
                id={subscriptionId}
                startDate={workforceSubscriptionData.start_date}
                endDate={workforceSubscriptionData.end_date}
                baseUrl={props.baseUrl}
              />
              <Button onClick={handleEditClick}>
                <FormattedMessage
                  id="button.edit"
                  defaultMessage="Edit"
                />
              </Button>
            </>
          }
        </div>
      }
    </div>
  );
};

/**
 * Returns date string with abbreviated year.
 * @param dateString - returned date string format from dateUtils.formatShortMonthYear
 * @returns {`${*} ${string}`}
 */
const abbreviateYear = (dateString) => {
  const dateParts = dateString.split(' ');

  if (dateParts.length !== 2) {
    throw new Error('Invalid input format. Expected "month YYYY".');
  }

  const month = dateParts[0];
  const year = dateParts[1];

  if (year.length !== 4 || isNaN(year)) {
    throw new Error('Invalid year format. Expected a 4-digit year.');
  }

  const abbreviatedYear = `'${year.slice(-2)}`;

  return `${month} ${abbreviatedYear}`;
};

/**
 * Internal helper function that returns data and color scheme for the donut chart with labels
 * translated into current locale
 *
 * @param {subscriptionLimit:number, totalEmployees:number} Employee limit on subscription,
 * and total employee count from most recent EmployerDailyStat.
 * @returns {{data: [{x: string, y: (number|*)},{x: string, y}], categoryColors: {}}}
 */
const useTranslatedDonutLabels = (subscriptionLimit, totalEmployees) => {
  const intl = useIntl();
  const data = useMemo(() => {
    const result = [
      {
        x: intl.formatMessage(DONUT_LABELS_I18N_MAP.total_employees),
        y: totalEmployees
      },
      {
        x: intl.formatMessage(DONUT_LABELS_I18N_MAP.remaining),
        y: Math.max(0, (subscriptionLimit - totalEmployees))
      }
    ];
    return result;
  }, [intl, subscriptionLimit, totalEmployees]);

  const categoryColors = useMemo(() => {
    return Object.keys(DONUT_COLORS).reduce((result, key) => {
      result[intl.formatMessage(DONUT_LABELS_I18N_MAP[key])] = DONUT_COLORS[key];
      return result;
    }, {});
  }, [intl]);

  return { data, categoryColors };
};

SubscriptionManagement.propTypes = {
  organization: PropTypes.object,
  baseUrl: PropTypes.string,
  routeProps: PropTypes.object.isRequired,
  subscriptionId: PropTypes.string
};
