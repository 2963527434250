import React from 'react';
import PropTypes from 'prop-types';
import { EmployerStatistics } from './components/employer_statistics';
import { EmptyDashboard } from './components/empty_dashboard';
import { ExploreBadges } from '../earner/dashboard/explore_badges/explore_badges';
import { PopularSkills } from 'pages/workforce/components/popular_skills';
import { IndustryTrendingEmergingSkills } from 'pages/workforce/components/industry_trending_emerging_skills';
import { getProfileAction } from 'profile';
import { FormattedMessage } from 'react-intl';
import { EmployeesBadgesActivity } from './components/employers_badges_activity';

import './workforce_admin_dashboard.sass';

export const WorkforceAdminDashboard = props => {
  const [profileState] = getProfileAction.useOnMount();
  const currentUser = profileState.resources;

  if (!props.organization.has_employees) {
    return <EmptyDashboard/>;
  }

  return (
    <div className="col">
      <h1 className="workforce-dashboard__hello">
        <FormattedMessage
          id="workforce.dashboard.hello"
          defaultMessage="Hello {firstName}!"
          values={{ firstName: currentUser?.first_name }}
        />
      </h1>
      <div className="workforce-dashboard__content-container">
        <EmployerStatistics organizationId={props.organization.id}/>
        <EmployeesBadgesActivity organizationId={props.organization.id}/>
        <ExploreBadges
          workforceContent
          showPopularBadges
          organizationId={props.organization.id}
          headingTitleIntlObj={{
            id: 'workforce_dashboard.popular_badges.heading.title',
            defaultMessage: 'Popular learning in your organization'
          }}
        />
        <PopularSkills organizationId={props.organization.id}/>
        <IndustryTrendingEmergingSkills organizationId={props.organization.id}/>
      </div>
    </div>
  );
};

WorkforceAdminDashboard.propTypes = {
  organization: PropTypes.object.isRequired
};
