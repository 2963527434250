import React from 'react';
import PropTypes from 'prop-types';
import CredlyRoute from 'controls/credly_route';
import { CredlySwitch } from 'controls/credly_switch';
import { EmployeesRouteHelpers } from 'pages/workforce/route_helpers';
import { EmployeesOccupations } from './employees_occupations/employees_occupations';
import { useIntl } from 'react-intl';

export const Routes = (props) => {
  const intl = useIntl();
  const routeHelpers = EmployeesRouteHelpers.forUrl(props.baseUrl);

  return (
    <CredlySwitch>
      <CredlyRoute
        path={routeHelpers.occupations.route()}
        render={(routeProps) => {
          return (
            <EmployeesOccupations
              organization={props.organization}
              routeProps={routeProps}
            />
          );
        }}
        title={intl.formatMessage({
          id: 'workforce.employees.occupations.routes.occupations',
          defaultMessage: 'Occupations'
        })}
      />
    </CredlySwitch>
  );
};

Routes.propTypes = {
  baseUrl: PropTypes.string,
  organization: PropTypes.object
};
