import { connect } from 'react-redux';
import { employeeSkillsSearch } from 'pages/workforce/employees/employees_actions';
import { BaseDirectorySearch } from 'pages/public/organization/earner_directory/base_directory_search.jsx';

const EmployeeSkillsSearchConnected = connect(
  state => ({
    results: employeeSkillsSearch.getResources(state),
    searchStatus: employeeSkillsSearch.getStatus(state)
  }),
  dispatch => ({
    search: query => dispatch(employeeSkillsSearch.action(query))
  })
)(BaseDirectorySearch);

export { EmployeeSkillsSearchConnected as EmployeeSkillsSearch };
export const testing = { EmployeeSkillsSearch: BaseDirectorySearch };
