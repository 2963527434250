import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';

export const badgeTemplateActions = new ActionManager('publicBadgeTemplates')
  .add({
    requestKey: 'fetch',
    url: '/org/[org_vanity_slug]/badge/[badge_template_vanity_slug]',
    urlSubs: new stringUtils.BracketSubs({
      org_vanity_slug: { param: 'org_vanity_slug', remove: true },
      badge_template_vanity_slug: { param: 'badge_template_vanity_slug', remove: true }
    }),
    effect: 'read',
    method: 'GET'
  }).add({
    requestKey: 'fetchById',
    url: '/badge_templates/[id]',
    effect: 'read',
    method: 'GET'
  }, true);
