import React from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from 'controls/loading_spinner';
import { LearningPlanActions } from 'pages/workforce/learning/actions';
import { LearningPlanCard } from './learning_plan_card';
import { EmptyLearningPlans } from './empty_learning_plans';

export const LearningPlans = ({ organization, baseUrl }) => {
  const [learningPlansState] = LearningPlanActions.useOnMount(
    'dashboard',
    null,
    { organization_id: organization.id }
  );
  const learningPlans = learningPlansState.resources;
  const learningPlansStatus = learningPlansState.status;

  if (learningPlansStatus.pending) {
    return <LoadingSpinner position="below"/>;
  }

  if (learningPlans?.length === 0 || learningPlansStatus.failed) {
    return <EmptyLearningPlans/>;
  }

  return (
    <>
      {learningPlans.map((learningPlan) => (
        <LearningPlanCard key={learningPlan.id} learningPlan={learningPlan} baseUrl={baseUrl}/>
      ))}
    </>
  );
};

LearningPlans.propTypes = {
  organization: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired
};
