import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { EllipsisMenu } from 'controls/ellipsis_menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { useIntl } from 'react-intl';

import './learning_plan_page_dropdown.sass';

export const LearningPlanPageDropdown = ({
  toggleDisplayRemoveAll,
  removelAllenabled
}) => {
  const intl = useIntl();

  const dropdownItems = useCallback(() => {
    return [
      {
        itemIcon: faTrash,
        itemClassName: 'ac-trash__icon',
        ellipsisItemClassName: 'ac-trash__item',
        value: toggleDisplayRemoveAll,
        enabled: removelAllenabled,
        label: intl.formatMessage({
          id: 'workforce.learning.learning_plans.list.menu.remove_all',
          defaultMessage: 'Remove all'
        })
      }
    ];
  }, [toggleDisplayRemoveAll, removelAllenabled, intl]);

  const items = dropdownItems();

  return (
    <EllipsisMenu
      onSelect={(openModalFunc) => openModalFunc()}
      appearance="enclosed"
      containerClassName="c-workforce-learning-plan-employments-list__more-button"
    >
      {items.map((item, index) => (
        <EllipsisMenu.Item
          key={index}
          value={item.value}
          disabled={!item.enabled}
          className={item.ellipsisItemClassName}
        >
          <FontAwesomeIcon icon={item.itemIcon} className={item.itemClassName}/>
          {item.label}
        </EllipsisMenu.Item>
      ))}
    </EllipsisMenu>
  );
};

LearningPlanPageDropdown.propTypes = {
  toggleDisplayRemoveAll: PropTypes.func,
  removelAllenabled: PropTypes.bool
};
