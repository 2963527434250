import { useDeepEquals, useEffectExceptOnMount } from 'utils/react_utils';

/**
 * Helper component to be displayed as a child to Forms that update the search
 * params field for a saved directory search. It acts as a custom "hidden field"
 * that will make an appropriate call to `onChange` when its value changes.
 * @param {Object} props
 *   @param {boolean} props.value - Object representing the search params for
 *   a saved directory search
 */
export const SearchParamsFormValueTracker = props => {
  useEffectExceptOnMount(() => {
    props.onChange('search_params', props.value);
  }, [useDeepEquals(props.value)]);
  return null;
};
