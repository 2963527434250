import { QueryString } from 'utils/query_string';
import PropTypes from 'prop-types';

/**
 * Return directory search types which should be excluded for an organization. Do not use this for
 * standalone directory.
 *
 * @param {Object} org - The organization.
 */
export const excludedSearchTypesWithOrg = org => {
  const excluded = { issuer: true };
  if (!org || !org.has_earner_directory_location_search) {
    excluded.location = true;
  }
  return excluded;
};

/**
 * Add filter parameters to a url (for example, a user profile url).
 *
 * @param {Object} filters - directory filters.
 * @param {String} url - The base url.
 * @returns {String} - The modified url.
 */
export const makeFilteredUrl = (filters, url, source = 'earner_directory') => {
  return new QueryString().set('filter', filters).set('source', source).makeUrl(url);
};

/**
 * PropType for the earner object that comes out of earner_directory_controller.
 */
export const EarnerPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  role: PropTypes.string,
  location: PropTypes.string.isRequired,
  skills: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired
  })).isRequired,
  url: PropTypes.string,
  vanity_url: PropTypes.string,
  badge_count: PropTypes.number.isRequired,
  highlighted_badges: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    date: PropTypes.string,
    url: PropTypes.string
  })).isRequired,
  most_recently_accepted_credential: PropTypes.object.isRequired,
  issuer_count: PropTypes.number.isRequired
});

export const EmployeeEarnerPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  role: PropTypes.string,
  location: PropTypes.string.isRequired,
  url: PropTypes.string,
  vanity_url: PropTypes.string,
  badge_count: PropTypes.number.isRequired,
  total_badge_count: PropTypes.number.isRequired,
  highlighted_badges: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    date: PropTypes.string,
    url: PropTypes.string
  })).isRequired,
  issuer_count: PropTypes.number.isRequired
});
