import React, { useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { clone } from 'utils/object';
import { useHistory } from 'react-router-dom';
import { TreeMap } from "charts/tree_map/tree_map";
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiagramNested } from '@fortawesome/pro-regular-svg-icons/faDiagramNested';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import {
  initialState,
  pointChartFormatter,
  reducer,
  restructureData,
  toolTipFormatter
} from './utils';

import './skills_distribution.sass';

export const SkillsDistributionChart = ({ data }) => {
  const history = useHistory();
  const intl = useIntl();
  const [state, dispatch] = useReducer(reducer, initialState);
  const granularNodeIdRef = useRef(null);

  useEffect(() => {
    dispatch({ type: 'SET_LEVEL_TWO_VIEW', payload: true });
    dispatch({ type: 'SET_PARENT_DATA_STACK', payload: [] });
  }, []);

  useEffect(() => {
    if (state.levelTwoView) {
      const _data = restructureData(data);
      dispatch({ type: 'SET_CURRENT_DATA', payload: _data });
    }
  }, [state.levelTwoView, data]);

  const pointClick = (selection) => {
    if (!selection.children && selection.id === granularNodeIdRef.current) {
      history.push(`/skills/${selection?.id}`);
      return;
    }
    if (!selection?.children) {
      granularNodeIdRef.current = selection?.id;
    } else {
      granularNodeIdRef.current = null;
    }

    const nextData = selection.children || [selection];
    const nextPath = [...state.path, selection.name].filter(Boolean);

    dispatch({ type: 'SET_PARENT_DATA_STACK', payload: [...state.parentDataStack, [...state.currentData]] });
    dispatch({ type: 'SET_PATH', payload: nextPath });
    dispatch({ type: 'SET_CURRENT_DATA', payload: clone(nextData) });
  };

  const handleBackClick = () => {
    const newStack = [...state.parentDataStack];
    const lastElement = newStack.pop();
    const newPath = [...state.path];
    newPath.pop();

    dispatch({ type: 'SET_PARENT_DATA_STACK', payload: newStack });
    dispatch({ type: 'SET_PATH', payload: newPath });
    dispatch({ type: 'SET_CURRENT_DATA', payload: clone(lastElement || data) });

    if (!state.parentDataStack.length) {
      if (state.levelTwoView) {
        dispatch({ type: 'SET_LEVEL_TWO_VIEW', payload: false });
      } else {
        dispatch({ type: 'SET_LEVEL_TWO_VIEW', payload: true });
      }
    }
  };

  const getChartTitle = () => {
    if (state.path.length === 0) {
      const chartTitle = intl.formatMessage(
        {
          id: 'workforce.skills_explorer.skills_distribution.chart_title',
          defaultMessage: 'Skill Domain Level {level}'
        },
        {
          level: state.levelTwoView ? '2' : '1'
        }
      );
      return chartTitle;
    } else {
      const level = Number(state.parentDataStack.length) + 1;
      const chartSubtext = intl.formatMessage(
        {
          id: 'workforce.skills_explorer.skills_distribution.chart_with_subtitle',
          defaultMessage: ' - Level {subtext}'
        },
        { subtext: level }
      );
      return (
        <span>
          <strong>{state.path[state.path.length - 1]}</strong>
          {level < 5 && level >= 2 ? chartSubtext : ''}
        </span >
      );
    }
  };

  const getButtonText = () => {
    if (!state.parentDataStack.length && state.levelTwoView) return 'View from a higher level';
    let level = Number(state.parentDataStack.length) + 1;
    if (state.levelTwoView) {
      level += 1;
    }

    if (level === 1) {
      return intl.formatMessage(
        {
          id: 'workforce.skills_explorer.skills_distribution.chart_button_view_lower_level',
          defaultMessage: 'View from a lower level'
        }
      );
    };

    return intl.formatMessage(
      {
        id: 'workforce.skills_explorer.skills_distribution.chart_button_view_from_level',
        defaultMessage: 'Back to level {level} skill domain'
      },
      {
        level: level - 1
      }
    );
  };

  return (
    <div className="skills-distribution">
      <div className="tree-chart__header">
        <div
          className="tree-chart__back-button"
          type="button"
          onClick={handleBackClick}
        >
          <FontAwesomeIcon
            icon={
              state.parentDataStack.length === 0
                ? faDiagramNested
                : faArrowLeft
            }
            className="tree-chart__back-icon"
          />
          {getButtonText()}
        </div>
        <div className="tree-chart__title">
          {getChartTitle()}
        </div>
      </div>
      <div className="skills-distribution__content">
        <TreeMap
          pointClick={pointClick}
          currentData={state.currentData}
          toolTipFormatter={toolTipFormatter(state.parentDataStack?.length)}
          pointChartFormatter={pointChartFormatter(state.totalValue)}
        />
      </div>
    </div>
  );
};

SkillsDistributionChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    children: PropTypes.array
  })).isRequired
};
