import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { stdCompare } from 'utils';
import { earnerDashboardUrl, organizationManagementUrl, workforceUrl } from 'utils/routes';
import { CredlySwitch } from 'controls/credly_switch';
import CredlyRoute from 'controls/credly_route';
import { LoadingSpinner } from 'controls/loading_spinner';
import { getProfileAction, isActiveOrgMember, hasWorkforceAccess } from 'profile';
import { WorkforceLayout } from 'pages/workforce/workforce_layout/workforce_layout';
import { recentlyViewedOrganizationsActions } from 'pages/workforce/organizations';
import { WorkforceRouteHelpers } from 'pages/workforce/route_helpers';

/**
 * Top-level routing component for Workforce section (/workforce). If user accesses /workforce, determines appropriate
 * workforce-enabled organization to redirect to. Otherwise, delegates to WorkforceLayout.
 *
 * @param {object} _props - React props; not used by this component
 * @returns {JSX.Element}
 * @constructor
 */
export const WorkforceDispatch = (_props) => {
  return (
    <CredlySwitch>
      <CredlyRoute
        path={workforceUrl.root()}
        exact
        render={(_routeProps) => <WorkforceRoot/>}
      />
      <CredlyRoute
        path={WorkforceRouteHelpers.root.route()}
        render={(routeProps) => (
          <WorkforceLayout
            baseUrl={routeProps.match.url}
            organizationId={routeProps.match.params.organization_id}
          />
        )}
      />
    </CredlySwitch>
  );
};

/**
 * Internal component used by WorkforceDispatch to select an appropriate place to redirect the user to if they arrive
 * at the root Workforce URL (/workforce).
 *
 * @param {object} _props
 * @returns {JSX.Element}
 * @constructor
 */
const WorkforceRoot = (_props) => {
  const [recentlyViewedOrganizationsState] =
    recentlyViewedOrganizationsActions.useOnMount('getRecentlyViewedOrganizations');
  const history = useHistory();
  const [{ resources: currentUser }] = getProfileAction.useAction();

  useEffect(() => {
    // don't redirect to the wrong place simply because we haven't finished loading recent orgs
    if (recentlyViewedOrganizationsState.status.pending) {
      return;
    }

    const recentlyViewed = (recentlyViewedOrganizationsState.resources || []).reduce(
      (acc, recentOrg) => {
        if (recentOrg.organization.workforce_enabled) {
          return [...acc, { ...recentOrg.organization, last_viewed_at: recentOrg.last_viewed_at }];
        }
        return acc;
      },
      []
    );
    // entries added to recently viewed organizations after initial load won't be in the proper order in store
    recentlyViewed.sort((left, right) => stdCompare(right.last_viewed_at, left.last_viewed_at));

    const workforceOrgId = recentlyViewed.find((o) => hasWorkforceAccess(currentUser, o.id))?.id ||
      currentUser.memberships.find((m) =>
        hasWorkforceAccess(currentUser, m.organization_id)
      )?.organization_id; // eslint-disable-line camelcase

    if (workforceOrgId) {
      history.replace(
        WorkforceRouteHelpers.root.parameterize({ organization_id: workforceOrgId })
      );
    } else if (isActiveOrgMember(currentUser)) {
      history.replace(organizationManagementUrl.root());
    } else if (recentlyViewedOrganizationsState) {
      history.replace(earnerDashboardUrl());
    }
  }, [recentlyViewedOrganizationsState.status]);

  return <LoadingSpinner position="window-center" delay={300}/>;
};

export const testing = { WorkforceRoot };
