import React, { Fragment, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import * as objUtils from 'utils/object';

/**
 * React.Context for setting the features that are enabled for the current organization, in order to
 * decide whether certain UI elements should be available.
 *
 * @type {React.Context<{features: []}>}
 */
const OrganizationFeatureToggleContext = React.createContext({ features: [] });

/**
 * Convenience wrapper for OrganizationFeatureToggleContext.Provider to find and set the features
 * key of the context, given the organizationMetadata.
 *
 * @param {object} props - the component's props
 * @param {object} props.organizationMetadata - the metadata associated with the organization,
 *   including a features key for the enabled features
 * @returns {React.element}
 * @constructor
 */
export const OrganizationFeaturesProvider = (props) => {
  const features = objUtils.arrayToSet(
    objUtils.dig(props.organizationMetadata, 'features', {}).map(f => f[0])
  );
  return (
    <OrganizationFeatureToggleContext.Provider value={{ features }}>
      {props.children}
    </OrganizationFeatureToggleContext.Provider>
  );
};

OrganizationFeaturesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  organizationMetadata: PropTypes.shape({ features: PropTypes.array }).isRequired
};

/**
 * Custom hook to check whether a given feature or list of features is enabled or not, by pulling
 * the enabled feature list from context (OrganizationFeatureToggleContext).
 *
 * Return value is a function that can take either a string or an array of strings (corresponding
 * to a single feature key or multiple feature keys) and will return true if all of the specified
 * features are enabled.
 *
 * @returns {function(string|array<string>): boolean}
 */
export const useFeatureToggles = () => {
  const value = useContext(OrganizationFeatureToggleContext);

  return useCallback(
    (feature) => {
      const requiredFeatures = Array.isArray(feature) ? feature : [feature];
      // All features must be enabled to consider this check to have passed, hence #every
      return requiredFeatures.every(f => value.features[f]);
    },
    [value.features]
  );
};

/**
 * Component to help conditionally render content based on whether the given feature(s) is enabled
 * or not.
 *
 * @param {object} props
 * @param {*} props.children - React-renderable content (e.g. JSX, strings, etc.) that will be
 *   rendered only if the requiredFeatures are enabled
 * @param {string|array<string>} props.feature - the feature or list of features that must be
 *   enabled in order to render the component's children; if a list of features is provided ALL of
 *   the features must be enabled in order for the children to be rendered
 * @param {function} [props.renderDisabled] - a function to call to render content that should be
 *   displayed in case the feature(s) is not enabled, e.g. a prompt for the issuer to contact their
 *   CSM to have it enabled
 * @returns {*}
 * @constructor
 */
export const UsingFeatures = (props) => {
  const featuresEnabled = useFeatureToggles();
  // If the features are enabled, show the children, otherwise show a disabled view, if given.
  if (featuresEnabled(props.feature)) {
    return props.children;
  } else if (props.renderDisabled) {
    return props.renderDisabled();
  } else {
    return <Fragment />;
  }
};

UsingFeatures.propTypes = {
  children: PropTypes.node.isRequired,
  feature: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  renderDisabled: PropTypes.func
};

/**
 * Constants for feature toggle keys.
 *
 * DRY VIOLATION: this list should be a subset of that defined in feature_controls/feature.rb
 *
 * @type {{AUTHORIZED_ISSUING: string, RULES_ISSUING_ENABLED: string}}
 */
export const FeatureKeys = {
  API_SSO: 'api.sso',
  AUTHORIZATION_TOKEN_PERMISSIONS: 'authorization_token_permissions',
  AUTHORIZED_ISSUING: 'organization.authorized_issuing',
  EMPLOYEE_DIRECTORY: 'employee_directory',
  LMI_SETTINGS: 'lmi-settings-manage',
  ORGANIZATION_BADGE_ISSUING: 'organization-badge-issuing',
  PRIVATE_BADGES: 'private-badges',
  PRIVATE_EARNER_DIRECTORY: 'private-earner-directory',
  RESTRICTED_TO_TRANSCRIPT_BADGES: 'restricted-to-transcript-badges',
  ROI_DASHBOARD: 'roi-dashboard',
  RULES_ISSUING_ENABLED: 'rules_issuing_enabled',
  SKILLJAR_ENABLED: 'organization-skilljar',
  TRANSCRIPT_REPORTING: 'transcript_reporting',
  UPLOAD_EVIDENCE: 'upload_evidence',
  WEBHOOK_AUTHENTICATION: 'webhook-authentication'
};
