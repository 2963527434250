import React, { useState } from "react";
import PropTypes from 'prop-types';
import { makeClassName, uniqueId } from 'utils';
import { LoadingSpinner } from 'controls/loading_spinner';
import { useIntl } from 'react-intl';
import './carousel.sass';

export const CarouselItemContext = React.createContext();

export const CarouselControlsContext = React.createContext();

/**
 * Generates memoized function to handle ArrowLeft/Right events
 * for the given slider.
 *
 * @param {Object} slider - slider object generated by `useCarousel`.
 **/

const useArrowNavClickHandler = (slider) => {
  const handleArrowNavClick = React.useCallback((event) => {
    if (event.key === 'ArrowLeft') {
      slider.controls.prev.to();
    } else if (event.key === 'ArrowRight') {
      slider.controls.next.to();
    }
  }, [slider.controls]);

  return handleArrowNavClick;
};

/**
 * generates object with `onKeyDown` prop.
 *
 * @param {Object} slider - slider object generated by `useCarousel`.
 */

export const useSliderArrowNavProps = (slider, inFocus, formCarousel) => {
  const handleArrowNavClick = useArrowNavClickHandler(slider);

  if (inFocus && formCarousel) {
    // disables arrow keys for the slider while interacting w. form content
    return null;
  } else {
    return { onKeyDown: handleArrowNavClick };
  }
};

/**
 * Renders an accessible ui container for Carousel Item on display. Invokes children function
 * passing in the value of `CarouselItemContext`, which includes the `slider` object and
 * properties specific to the carousel item.
 *
 * @param {Boolean} formCarousel - pass this if you are using the Carousel to house a form.
 * @param {Object} slider - slider object generated by `useCarousel`.
 * @param {Array} items - items being displayed by the carousel.
 * @param {String} className - string name of css class to append to wrapping html element.
 * @param {Object} display - react component being rendered once for each item in the carousel display, passes unique CarouselItemContext values to each item
 * @param {Object} secondaryDisplay - content being rendered inside the carousel display that isn't tracked with useCarousel state. ex: CarouselItemLoaderDisplay
 * @param {Object} itemSpacingOffset - used to calculate the space between each item in display
 * @param {Boolean} hasVariableWidth - pass this prop so translateX uses (slide width * number of slides displayed) instead of 100% of carousel width
 * @param {Object} controls - react components rendered inside the CarouselControlsContext in order to provide UI controls for the carousel.
 */

export const Carousel = ({
  formCarousel,
  slider,
  className,
  displayContainerClassName,
  display,
  secondaryDisplay,
  controls,
  style,
  itemSpacingOffset = 21,
  hasVariableWidth,
  overflow,
  useLeftOffset,
  ...otherProps
}) => {
  const [inFocus, setFocus] = useState(false);
  const arrowNavProps = useSliderArrowNavProps(slider, inFocus, formCarousel);
  const firstActiveItemRef = React.useRef();
  const [carouselDisplayWidth, setCarouselDisplayWidth] = React.useState('inherit');

  const resizeCarouseDisplay = React.useCallback(() => {
    // don't attempt to resize if the `style.width` css proprty is being manually set via props.
    // firstActiveItemRef is passed to `CarouselItem` through CarouselItemContext. To resize the
    // container according the size of it's contents.
    const item = firstActiveItemRef.current?.firstChild;
    if (item && item.getBoundingClientRect && !style?.width) {
      const { width } = item.getBoundingClientRect();
      setCarouselDisplayWidth(
        `${(width * slider.itemsPerSlide) + (itemSpacingOffset * slider.itemsPerSlide)}px`
      );
    }
  }, [firstActiveItemRef.current, slider.itemsPerSlide]);

  React.useEffect(() => {
    if (slider.ready) {
      resizeCarouseDisplay();
      window.addEventListener('resize', resizeCarouseDisplay);

      return () => window.removeEventListener('resize', resizeCarouseDisplay);
    }
  }, [resizeCarouseDisplay, slider.ready]);

  /*
  a helper function to calculate how much the slides should translate-x when we view the next slides
  * `hasVariableWidth` is used when the slide width doesnt match the exact width of the carousel
  * `useLeftOffset` shifts the first slide on the carousel view in order to accomodate for the `previous arrow` when there is no left margin on the carousel.
  */
  const getTranslateX = React.useCallback(() => {
    let translateX = '';
    if (hasVariableWidth) {
      if (carouselDisplayWidth === 'inherit') {
        requestAnimationFrame(resizeCarouseDisplay);
        translateX = "0px";
      } else {
        let leftOffsetPx = 0;
        if (useLeftOffset && slider.currentSlide !== 1) {
          leftOffsetPx = 72;
        }
        const calculatedTranslateX =
        -(slider.currentSlide - 1) * parseInt(carouselDisplayWidth, 10) + leftOffsetPx;
        translateX = `${calculatedTranslateX}px`;
      }
    } else {
      translateX = `-${(slider.currentSlide - 1) * 100}%`;

      if (useLeftOffset && slider.currentSlide !== 1) {
        translateX = `calc(-${(slider.currentSlide - 1) * 100}% + 72px)`;
      }
    }
    return `translateX(${translateX})`;
  }, [carouselDisplayWidth, slider]);

  return (
    <div
      role="region"
      aria-roledescription={slider.description}
      aria-label={slider.label}
      {...otherProps}
      className={
        makeClassName(['cr-carousel', className, overflow && `cr-carousel-overflow-${overflow}`])}
      tabIndex="0"
      {...arrowNavProps}
      style={{ width: carouselDisplayWidth, ...style }}
    >
      <div className="cr-carousel__inner" onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
        <div
          className={makeClassName('cr-carousel__display', displayContainerClassName)}
          style={{ transform: getTranslateX() }}
        >
          {slider.ready && slider.items.map((item, idx) => {
            const ctxValue = slider.itemContextValue(idx);
            if (idx === slider.visibleItemIndexRange.start) {
              ctxValue.ref = firstActiveItemRef;
            }
            return (
              <CarouselItemContext.Provider
                value={ctxValue}
                key={ctxValue.key}
              >
                {display}
              </CarouselItemContext.Provider>
            );
          })}
          {secondaryDisplay && (
            <CarouselItemContext.Provider value={{ slider }}>
              {secondaryDisplay}
            </CarouselItemContext.Provider>
          )}
        </div>
      </div>
      {controls && (
        <CarouselControlsContext.Provider value={{ slider }}>
          {controls}
        </CarouselControlsContext.Provider>
      )}
    </div>
  );
};

Carousel.propTypes = {
  formCarousel: PropTypes.bool,
  items: PropTypes.array,
  display: PropTypes.object.isRequired,
  displayContainerClassName: PropTypes.string,
  secondaryDisplay: PropTypes.object,
  itemSpacingOffset: PropTypes.number,
  slider: PropTypes.shape({
    ready: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    toPrevSlide: PropTypes.func.isRequired,
    toNextSlide: PropTypes.func.isRequired,
    currentSlide: PropTypes.number.isRequired,
    itemContextValue: PropTypes.func.isRequired,
    itemsPerSlide: PropTypes.number.isRequired,
    visibleItemIndexRange: PropTypes.shape({
      start: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  controls: PropTypes.object,
  'aria-label': PropTypes.string,
  'aria-roledescription': PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({
    width: PropTypes.string
  }),
  hasVariableWidth: PropTypes.bool,
  useLeftOffset: PropTypes.bool,
  overflow: PropTypes.string
};

/**
 * Renders an accessible ui container for Carousel Item on display. Invokes children function
 * passing in the value of `CarouselItemContext`, which includes the `slider` object and
 * properties specific to the carousel item.
 *
 * @param {Function} children - React children
 * @param {String} className - string name of css class to append to wrapping html element.
 */

export const CarouselItem = ({
  children,
  className,
  ...otherProps
}) => {
  const itemProps = React.useContext(CarouselItemContext);
  const itemClassName =
    makeClassName(
      'cr-carousel__item',
      `cr-carousel__item--per-${itemProps.slider.itemsPerSlide}`,
      itemProps.visible && `cr-carousel__item--visible`,
      className
    );

  const ariaLabel = React.useMemo(() => (
    otherProps['aria-label'] || itemProps.label
  ), [otherProps['aria-label'], itemProps.label]);

  const intl = useIntl();

  const description = React.useMemo(() => (
    !isNaN(itemProps.index) &&
    intl.formatMessage(
      {
        id: 'carousel.item.roledescription',
        defaultMessage: '{count} of {total}'
      },
      {
        count: itemProps.index + 1,
        total: itemProps.slider.totalItems
      }
    )
  ), [otherProps['aria-label'], itemProps.index]);

  return (
    <div
      ref={itemProps.ref}
      {...otherProps}
      aria-hidden={!itemProps.visible}
      role="group"
      aria-roledescription={description}
      aria-label={ariaLabel}
      tabIndex={itemProps.visible ? 0 : -1}
      className={itemClassName}
    >
      {children(itemProps)}
    </div>
  );
};

CarouselItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.func.isRequired
};

Carousel.Item = CarouselItem;

/**
 * Renders a ui container for Carousel controlling elements.
 *
 * @param {Object} children - React children
 */

export const CarouselControls = ({
  children,
  ...otherProps
}) => {
  const data = React.useContext(CarouselControlsContext);
  return (
    <div className="cr-carousel__controls" {...otherProps}>
      {children(data)}
    </div>
  );
};

CarouselControls.propTypes = {
  children: PropTypes.func.isRequired
};

Carousel.Controls = CarouselControls;

/**
 * Renders an accessible ui container for a Prev/Next Slide Carousel control .
 *
 * @param {String} direction - (must be either prev/next)
 * @param {Object} children - React children
 * @param {String} className - string name of css class to append to wrapping html element.
 * @param {String} aria-label - accessibility label for element. defaults to "Previous|Next Slide"
 */

export const CarouselControl = ({
  direction,
  children,
  className,
  onTrigger,
  ...otherProps
}) => {
  const { slider } = React.useContext(CarouselControlsContext);
  const control = slider.controls[direction];

  const to = React.useCallback((event) => {
    if (onTrigger) {
      onTrigger(event, control.to);
      return;
    }
    control.to();
  }, [control.to, onTrigger]);

  const enterPressHandler = React.useCallback((event) => {
    if (event.key === 'Enter') {
      to(event);
    }
  }, [to]);

  const controlClassName =
    makeClassName(`carousel-control__${direction}`, className);

  if (
    slider.totalSlides === 0 ||
    !control.visible
  ) {
    return (<></>);
  }

  return (
    <div
      aria-label={direction === 'prev' ? 'Previous Slide' : 'Next Slide'}
      {...otherProps}
      role="button"
      tabIndex={0}
      className={controlClassName}
      onClick={to}
      onKeyPress={enterPressHandler}
    >
      {children}
    </div>
  );
};

CarouselControl.propTypes = {
  direction: PropTypes.oneOf(['prev', 'next']).isRequired,
  children: PropTypes.any.isRequired,
  'aria-label': PropTypes.string,
  className: PropTypes.string,
  onTrigger: PropTypes.func
};

Carousel.Control = CarouselControl;

/**
 * Renders carousel item loading placeholders.
 *
 * @param {Number} count - placeholder item count
 */

export const CarouselItemLoaderDisplay = ({
  count = 0,
  itemPlaceholder
}) => {
  const items = [];
  const itemKey = React.useMemo(() => uniqueId().toString(), []);
  for (let i = count; i > 0; i--) {
    items.push(
      <CarouselItem key={`${itemKey}-${i}`} className="carousel__item--loading">
        {itemPlaceholder}
      </CarouselItem>
    );
  }
  return (
    <div className="carousel-item-loader__placeholder-container">
      {items}
    </div>
  );
};

CarouselItemLoaderDisplay.propTypes = {
  count: PropTypes.number,
  itemPlaceholder: PropTypes.func
};

CarouselItemLoaderDisplay.defaultProps = {
  itemPlaceholder: () => (
    <div className="carousel-item__loading-placeholder">
      <LoadingSpinner position="below" />
    </div>
  )
};

Carousel.ItemLoaderDisplay = CarouselItemLoaderDisplay;

export default Carousel;
