import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from 'controls/button';
import { BubbleChart } from 'charts/bubble_chart/bubble_chart';
import { Heading } from "controls/heading";
import { DataFilters } from "./future_proof_data_filters";
import { buildSeries, tooltip, structureData, makeDrawChartBackground } from "./utils";
import { randomData } from "./mock_data";

import './future_proof_skills.sass';

export const FutureProofSkills = () => {
  const history = useHistory();
  const intl = useIntl();
  const [data, setData] = useState(randomData());
  const [skillSignalFilterState, setSkillSignalFilterState] = useState('');
  const [skillTypeFilterState, setSkillTypeFilterState] = useState('');
  const drawChartBackground = makeDrawChartBackground();

  const onRandomClick = () => {
    setData(randomData);
  };

  const structuredData = structureData(data);
  let filteredData;

  if (skillTypeFilterState === 'future-proof') {
    filteredData = {
      setOne: structuredData.setOne.filter(member => member.future_proof === false),
      setTwo: structuredData.setTwo.filter(member => member.future_proof === false)
    };
  } else {
    filteredData = {
      setOne: structuredData.setOne,
      setTwo: structuredData.setTwo
    };
  }

  if (skillSignalFilterState === 'verified') {
    filteredData = {
      setOne: filteredData.setOne.filter(member => member.verified === false),
      setTwo: filteredData.setTwo.filter(member => member.verified === false)
    };
  } else if (skillSignalFilterState === 'from-occupations') {
    filteredData = {
      setOne: filteredData.setOne.filter(member => member.from_occupation === false),
      setTwo: filteredData.setTwo.filter(member => member.from_occupation === false)
    };
  } else {
    filteredData = {
      setOne: filteredData.setOne,
      setTwo: filteredData.setTwo
    };
  }

  const resizeFunction = (chart) => {
    // This is in a 300ms timeout because we need to wait for the chart animation to finish when zooming
    // with CMD+/- before recalculating the chart background positions
    setTimeout(function() {
      drawChartBackground(chart);
    }, 300);
  };

  const options = {
    chart: {
      events: {
        load: function(chart) {
          drawChartBackground(chart);
        }
      }
    },
    xAxis: {
      title: {
        text: `<b>${intl.formatMessage({
          id: 'workforce.skills_explorer.future_proof_skills.x_axis_label',
          defaultMessage: 'Importance to Future'
        })}</b>`
      },
      max: 100,
      min: 0
    },
    yAxis: {
      gridLineWidth: 0,
      startOnTick: false,
      endOnTick: false,
      tickPositions: [-100, 0, 100],
      title: {
        text: `<b>${intl.formatMessage({
          id: 'workforce.skills_explorer.future_proof_skills.y_axis_label',
          defaultMessage: 'Skill Trend'
        })}</b>`
      },
      max: 110,
      min: -110,
      plotLines: [{
        color: '#4E4E4E',
        dashStyle: 'dash',
        width: 0.75,
        value: 0
      }]
    },
    tooltip: {
      useHTML: true,
      className: 'future-proof-skills-tooltip',
      hideDelay: 0,
      outside: true,
      formatter: function() {
        return tooltip(this);
      }
    },
    plotOptions: {
      series: {
        point: {
          events: {
            click: function() {
              history.push(`/skill/${this.skill_ID}`);
            },
            mouseOver: function(e) {
              const pointIdentifier = e.target.identifier;
              const points = document.querySelectorAll('.bubble-chart__future-proof-skills .highcharts-point');
              for (const element of points) {
                if (element.point.identifier !== pointIdentifier) {
                  element.style.opacity = 0.1;
                }
              }
            },
            mouseOut: function() {
              const points = document.querySelectorAll('.bubble-chart__future-proof-skills .highcharts-point');
              for (const element of points) {
                element.style.opacity = 1;
              }
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        states: {
          inactive: false
        }
      }
    }
  };

  return (
    <div className="col">
      <Button
        className="future-proof-skills-random-button"
        onClick={onRandomClick}
      >
        Randomize
      </Button>
      <div className="bubble-chart__future-proof-skills">
        <div className="bubble-chart__future-proof-skills--header">
          <div>
            <Heading
              appearance="section-subheader"
              className="bubble-chart__future-proof-skills--heading"
            >
              <FormattedMessage
                defaultMessage="Your Organization's Future Proof Skills"
                id="workforce.skills_explorer.future_proof_skills.heading"
              />
            </Heading>
            <FormattedMessage
              className="bubble-chart__future-proof-skills--subheading"
              defaultMessage="See the in-demand, future proof skills your workforce has today."
              id="workforce.skills_explorer.future_proof_skills.sub_heading"
            />
          </div>
          <DataFilters
            setSkillSignalFilterState={setSkillSignalFilterState}
            setSkillTypeFilterState={setSkillTypeFilterState}
          />
        </div>
        <BubbleChart
          dataSeries={buildSeries(filteredData)}
          customOptions={options}
          onResize={resizeFunction}
        />
      </div>
    </div>
  );
};
