import React from "react";
import { useWidthBreakpoints } from 'utils/use_width_breakpoints';

export const usePerBreakpoints = (perBreakpoints) => {
  const [per, setPer] = React.useState(perBreakpoints.default);
  const view = useWidthBreakpoints();

  if (!isNaN(perBreakpoints.default) && perBreakpoints.default <= 0) {
    throw new Error('breakpoint.default must be a number greater than zero');
  }

  React.useEffect(() => {
    setPer(
      !!(view && perBreakpoints[view]) ? perBreakpoints[view] : perBreakpoints.default
    );
  }, [view]);

  return [per, view];
};
