import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons/faCalendarDays';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons/faCircleCheck';
import BadgePlaceholderImage from 'images/badge-placeholder.png';
import { Card } from 'controls/card';
import { ClampLines } from 'controls/clamp_lines';
import { SizedImage } from 'controls/sized_image';
import { organizationVanityUrl } from 'utils/routes';
import { LearningRouteHelpers } from 'pages/workforce/route_helpers';

import './learning_plan_card.sass';

export const LearningPlanCard = ({ learningPlan, baseUrl }) => {
  const templateName = learningPlan.badge_template.name;
  const ownerName = learningPlan.badge_template.owner_name;
  const percentComplete = learningPlan.total_employments ? Math.round(
    (learningPlan.total_earned / learningPlan.total_employments) * 100
  ) : 100;
  const history = useHistory();

  const cardClick = useCallback(() => {
    history.push(LearningRouteHelpers.forUrl(baseUrl).show.parameterize({ id: learningPlan.id }));
  }, [history, baseUrl, learningPlan.id]);

  const orgClick = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();

    history.push(organizationVanityUrl(learningPlan.badge_template.owner_vanity_slug));
  }, [history, baseUrl, learningPlan.id, learningPlan.badge_template.owner_vanity_slug]);

  return (
    <Card className="learning-plan-card" onClick={cardClick}>
      <Card.Body className="learning-plan-card__body">
        <div className="learing-plan-card__image-container">
          <SizedImage
            className="learning-plan-card__image"
            src={learningPlan.badge_template.image || BadgePlaceholderImage}
            width={120}
          />
        </div>
        <div className="learning-plan-card__name">
          <ClampLines lines={2}>{templateName}</ClampLines>
        </div>
        <div className="learning-plan-card__owner">
          <a
            href={organizationVanityUrl(learningPlan.badge_template.owner_vanity_slug)}
            onClick={orgClick}
          >
            <ClampLines lines={1}>{ownerName}</ClampLines>
          </a>
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="learning-plan-card__stat-line">
          <div className="learning-plan-card__stat-line__image-col">
            <FontAwesomeIcon icon={faCalendarDays} className="learning-plan-card__stat-line__image"/>
          </div>
          <div className="learning-plan-card__stat-line__stat-name">
            {learningPlan.most_recent_recommendation ? (
              <FormattedMessage
                id="workforce.learning_plan.index.learning_plan_card.most_recent_date"
                defaultMessage="{mostRecentDate, date, medium}"
                values={{ mostRecentDate: Date.parse(learningPlan.most_recent_recommendation) }}
              />
            ) : (
              <FormattedMessage
                id="workforce.learning_plan.index.learning_plan_card.most_recent_date.null"
                defaultMessage="N/A"
              />
            )}
          </div>
        </div>
        <div className="learning-plan-card__stat-line">
          <div className="learning-plan-card__stat-line__image-col">
            <FontAwesomeIcon icon={faUsers} className="learning-plan-card__stat-line__image"/>
          </div>
          <div className="learning-plan-card__stat-line__stat-name">
            <FormattedMessage
              id="workforce.learning_plan.index.learning_plan_card.total_employments"
              defaultMessage="{totalEmployments, number} Employees"
              values={{ totalEmployments: learningPlan.total_employments }}
            />
          </div>
        </div>
        <div className="learning-plan-card__stat-line">
          <div className="learning-plan-card__stat-line__image-col">
            <FontAwesomeIcon icon={faCircleCheck} className="learning-plan-card__stat-line__image"/>
          </div>
          <div className="learning-plan-card__stat-line__stat-name">
            <FormattedMessage
              id="workforce.learning_plan.index.learning_plan_card.percent_complete"
              defaultMessage="{percent, number}% Complete"
              values={{ percent: percentComplete }}
            />
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};

LearningPlanCard.propTypes = {
  learningPlan: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired
};
