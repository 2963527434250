import React, { useCallback } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import dateUtils from 'utils/date';
import { LoadingSpinner } from 'controls/loading_spinner';
import { SubscriptionsRouteHelpers } from 'pages/workforce/route_helpers';
import { workforceSubscriptionsActions } from 'pages/workforce/accounts/subscriptions/actions';

/**
 * Component rendered under '/accounts/subscriptions' used to fetch the
 * subscriptions index for the current Organization, and redirect the User
 * to the appropriate page with the following rules:
 *
 * Redirect Rules:
 * 1) Has an active Workforce Subscription:
 * - Redirect to Subscription Management Page
 * 2) No active Workforce Subscription, but has previous Workforce Subscriptions:
 * - Redirect to Subscription History Page
 * 3) No active or previous Workforce Subscription:
 * - Redirect to Empty Subscription Page
 *
 * @param {object} props
 *   @param {string} props.baseUrl - base URL of the Workforce Accounts link.
 *   @param {object} props.organization - current organization context.
 *
 */
export const SubscriptionsDirector = ({ organization, baseUrl }) => {
  const routeHelpers = SubscriptionsRouteHelpers.forUrl(baseUrl);
  const [workforceSubscriptionState] = workforceSubscriptionsActions.useOnMount(
    'list', null, { organization_id: organization.id }
  );
  const loadingStatus = workforceSubscriptionState.status;
  const subscriptions = workforceSubscriptionState.resources;

  const toRoute = useCallback(() => {
    if (subscriptions.length) {
      const activeSubscription = subscriptions.find(subscription => isDateInRange(subscription));
      return activeSubscription
        ? routeHelpers.manage.parameterize({ id: activeSubscription.id })
        : routeHelpers.history.route();
    } else {
      return routeHelpers.empty.route();
    }
  }, [subscriptions]);

  return (
    <>
      {(loadingStatus.idle || loadingStatus.pending) &&
        <LoadingSpinner/>
      }
      {(loadingStatus.succeeded) &&
        <Redirect
          from={baseUrl}
          exact
          to={toRoute()}
        />
      }
    </>
  );
};

const isDateInRange = (subscription) => {
  const today = dateUtils.formatShort(dateUtils.today());
  return today >= subscription.start_date && today < subscription.end_date;
};

SubscriptionsDirector.propTypes = {
  organization: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired
};
