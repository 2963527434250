/* eslint-disable camelcase */
import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { SigninRequiredError } from 'utils/signin_required_error';
import { LoadingSpinner } from 'controls/loading_spinner';
import { injectIntl } from 'react-intl';
import { intlKeyFromValue } from 'translations';

/**
 * How dare I use subclassing in React?!
 *
 * The more Reacty approach of rendering <Route> in a render() method doesn't work. The Router
 * component doesn't like that, and pages no longer change.
 */
export default class CredlyRoute extends Route {
  UNSAFE_componentWillMount() {
    this.updateTitle(this.props.title);
  }

  /**
   * Why componentWillUpdate? componentDidUpdate is called in the wrong order -- high level routes
   * after low level.
   *
   * @param {Object} nextProps
   * @param {Object} nextState
   */
  UNSAFE_componentWillUpdate(nextProps) {
    this.updateTitle(nextProps.title);
  }


  /**
   * Update the title if necessary.
   *
   * @param {String|Function} title
   */
  updateTitle(title) {
    if (typeof title === 'function') {
      setTitle(
        title({
          ...this.props,
          match: this.props.match || this.props.computedMatch
        }),
        this.props.intl
      );
    } else {
      setTitle(title, this.props.intl);
    }
  }


  /**
   * Call the Route's render function.
   *
   * @param {Object} routeProps
   */
  renderWrap = routeProps => {
    // This route-level Suspense prevents some of the flicker that comes from the higher-level
    // Suspense on some pages.
    return (
      <Suspense fallback={<LoadingSpinner position="window-center" delay={500} />}>
        {this.props.render(routeProps)}
      </Suspense>
    );
  };


  /**
   * Render the component. If the user isn't logged in, render the signin page.
   *
   * @returns {*}
   */
  render() {
    const globals = window.initialAppValues;
    const user = globals.currentUser;
    if (user.anonymous && !this.props.anonymous) {
      // Cause the signin page to render, replacing all content, even if it's higher up in the DOM.
      SigninRequiredError.raise();
      return '';
    } else {
      const innerProps = { ...this.props };
      if (this.props.render) {
        innerProps.render = this.renderWrap;
      }
      return <Route {...innerProps} />;
    }
  }
}


// remove-proptypes is needed for babel-plugin-transform-react-remove-prop-types, which doesn't
// handle component inheritance.
CredlyRoute.propTypes /* remove-proptypes */ = {
  ...Route.propTypes,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  anonymous: PropTypes.bool
};

CredlyRoute.MIN_DEBOUNCE_WAIT = 100;
CredlyRoute.MAX_DEBOUNCE_WAIT = 300;

/**
 * Set the title. We have a multi-level Route structure, which results in multiple Routes updating
 * on page change. To avoid performance and accessibility issues caused by multiple title changes,
 * debounce.
 *
 * @type {Function}
 */
const setTitle = debounce((title, intl) => {
  const credly = 'Credly';
  if (title) {
    if (/.* - Badges$/.test(title)) { // '{name} - Badges' title of public badge view.
      const userName = title.split(" - ")[0];
      title = userName + ' - ' + intlMessage(intl, 'Badges') + ' - ' + credly;
    } else {
      title = intlMessage(intl, title) + ' - ' + credly;
    }
  } else {
    title = credly;
  }

  if (document.title !== title) {
    document.title = title;
  }
}, CredlyRoute.MIN_DEBOUNCE_WAIT, { maxWait: CredlyRoute.MAX_DEBOUNCE_WAIT });

/**
 * i18n for page titles
 *
 * @type {Function}
 */
function intlMessage(intl, title) {
  return intl.formatMessage({
    id: intlKeyFromValue(title, "title"),
    defaultMessage: title
  });
}

// eslint-disable-next-line no-class-assign
CredlyRoute = injectIntl(CredlyRoute);
