import React from 'react';
import PropTypes from 'prop-types';
import { SizedImage } from 'controls/sized_image';
import BadgePlaceholderImage from 'images/badge-placeholder.png';

import './employee_badge_template_info.sass';

/**
 * Renders the badge image, name, and title in employees
 * badges table for the first column.
 * @param {Object} props
 *  @param {Object} props.badgeTemplate
 */

export const EmployeeBadgeTemplateInfo = (props) => {
  const badgeTemplate = props.badgeTemplate;
  return (
    <div className="c-employee-badge-template-info">
      <SizedImage
        src={badgeTemplate.image?.url || BadgePlaceholderImage}
        className="c-employee-badge-template-info__avatar"
        width={48}
      />
      <div className="c-employee-badge-template-info__name-issuer">
        <div title={badgeTemplate.name} className="c-employee-badge-template-info__name">
          <span className="c-employee-badge-template-info__name__text">
            {badgeTemplate.name}
          </span>
        </div>
        <div title={badgeTemplate.issuer} className="c-employee-badge-template-info__issuer">
          {badgeTemplate.issuers.map((issuer) => (
            <span className="c-employee-badge-template-info__issuer__text" key={issuer}>
              {issuer}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

EmployeeBadgeTemplateInfo.propTypes = {
  badgeTemplate: PropTypes.object.isRequired
};
