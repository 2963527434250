import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SizedImage } from 'controls/sized_image';
import BadgePlaceholderImage from 'images/badge-placeholder.png';
import { PublicBadgeTemplateUrl } from 'utils/routes';
import './badge_template_link.sass';

/**
 * Component for creating a link to a Badge Template.
 * Displays the template name and an image from the template's image URL
 * or a placeholder if no image exists.
 *
 * @param {Object} badgeTemplate - badge template details
 * @returns {JSX.Element} A link to the badge template
 */

export const BadgeTemplateLink = ({ badgeTemplate }) => {
  const templateUrl = PublicBadgeTemplateUrl(
    badgeTemplate.owner_vanity_slug,
    badgeTemplate.vanity_slug
  );

  return (
    <>
      {badgeTemplate &&
        <Link
          className="workforce-learning-section-header__badge-template-link"
          to={templateUrl}
        >
          <SizedImage
            className="workforce-learning-section-header__badge-template-link-image"
            src={badgeTemplate.image_url || BadgePlaceholderImage}
            width={24}
          />
          {badgeTemplate.name}
        </Link>
      }
    </>
  );
};

BadgeTemplateLink.propTypes = {
  badgeTemplate: PropTypes.object.isRequired,
};
