import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dateUtils from 'utils/date';
import { ResourceStatusPropType } from 'utils/prop_types';
import { EarnerPropType } from 'pages/public/organization/earner_directory/utils';
import { DataTable } from 'controls/data_table';
import { trySetLinkSource } from 'app_utils/tracking';
import { BaseDirectoryTable } from './base_directory_table';
import { DirectoryTableEarnerInfo } from 'pages/public/organization/earner_directory/directory_table_earner_info';

import './recruit_table.sass';

/**
 * A table view of the directory earner cards. For a cards view, use EarnerCards instead.
 *
 * @param {Object} props
 *   @param {Array} props.data - User data array.earner-table__name
 *   @param {ResourceStatus} props.status - Redux status for the data request.
 *   @param {QueryString} props.filters - Directory filters.
 */
export const RecruitTable = props => {
  const beforeOpenProfile = React.useCallback(
    () => trySetLinkSource('recruit_directory'),
    []
  );
  const rowClassNameFn = (data) => {
    return data.new_match ? 'c-recruit-table__row--new-match' : '';
  };
  return (
    <BaseDirectoryTable
      data={props.data}
      status={props.status}
      rowClassName={rowClassNameFn}
    >
      <DataTable.Col
        width="col-md-4 col-xs-6"
        header="Name / Location"
        renderer={data =>
          <DirectoryTableEarnerInfo
            data={data}
            onEarnerNameClick={beforeOpenProfile}
            source="recruit_directory"
          />
        }
      />
      <DataTable.Col
        width="col-md-4 col-xs-6"
        header="Last Earned Credential"
        renderer={data => {
          const lastEarnedCredential = data.most_recently_accepted_credential;
          if (lastEarnedCredential) {
            return (
              <Link
                to={lastEarnedCredential.url}
                onClick={beforeOpenProfile}
              >
                {lastEarnedCredential.name}
              </Link>
            );
          } else {
            return null;
          }
        }}
      />
      <DataTable.Col
        width="col-2"
        header="Date Accepted"
        hideAt="mobile"
        renderer={(data) => {
          const lastEarnedCredential = data.most_recently_accepted_credential;
          if (lastEarnedCredential) {
            return (
              <div>
                {dateUtils.formatDateMonthShortYear(lastEarnedCredential.date)}
              </div>
            );
          } else {
            return null;
          }
        }}
      />
      <DataTable.Col
        width="col-2"
        header="Total Credentials"
        hideAt={['tablet', 'mobile']}
        renderer={data => (
          <div>{data.total_badge_count}</div>
        )}
      />
    </BaseDirectoryTable>
  );
};

RecruitTable.propTypes = {
  data: PropTypes.arrayOf(EarnerPropType).isRequired,
  status: ResourceStatusPropType.isRequired
};
