import React from 'react';
import PropTypes from 'prop-types';
import { DirectoryBase } from 'pages/public/organization/earner_directory/shared/directory_base';
import { employeeOccupationActions } from 'pages/workforce/employees/employees_actions';
import { EmployeesOccupationsTable } from './employees_occupations_table';
import { EmployeeOccupationsSearch } from './employees_occupations_search';
import { useIntl } from 'react-intl';

import './employees_occupations.sass';

/**
 * A page displaying the employees occupations table
 *
 * @param {Object} props
 *   @param {Object} props.organization - Viewing organization
 *   @param {Object} props.routeProps - Contains the properties of the current route
 */

const SEARCH_TABS = [
  { name: 'name', label: 'Occupation Name' },
  { name: 'department', label: 'Departments' },
  { name: 'location', label: 'Locations' }
];

export const EmployeesOccupations = props => {
  const intl = useIntl();

  return (
    <DirectoryBase
      alwaysRenderHeader
      baseParams={{ organization_id: props.organization.id }}
      className={['col', 'c-employees-occupations__results']}
      directorySearchTag={EmployeeOccupationsSearch}
      excludeSearchTypes={{}}
      filterAction={employeeOccupationActions.get('filter')}
      filterNames={
        { name: 'Occupation', department: 'Department', location: 'Location' }
      }
      resultsRender={(directoryResultsFilterState) => (
        <EmployeesOccupationsTable
          data={directoryResultsFilterState.resources}
          status={directoryResultsFilterState.status}
          routeProps={props.routeProps}
        />
      )}
      searchBarPlaceholderText={intl.formatMessage({
        id: 'workforce.employees.occupations.search_bar_placeholder',
        defaultMessage: 'Search occupations'
      })}
      sortSelectorEnabled={false}
      tabs={SEARCH_TABS}
      tabNamesToFilterKeyTransforms={{
        name: () => 'name',
        department: () => 'department',
        location: () => 'location'
      }}
      trackingData={{ object_id: props.organization.id, object_type: 'Organization', directory_type: 'workforce-employees-occupations' }}
      orFilters
    />
  );
};

EmployeesOccupations.propTypes = {
  organization: PropTypes.object,
  routeProps: PropTypes.object
};
