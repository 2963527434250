import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { DirectoryLayout } from './directory_layout';
import { DirectoryResultsWrapper } from './directory_results_wrapper';
import { DirectorySearchWithFilters } from './directory_search_with_filters';
import { DirectorySearch } from '../directory_search';
import { EarnerCards } from 'pages/public/organization/earner_directory/earner_cards';
import { filter } from '../earner_directory_actions';

import './directory_base.sass';

/**
 * Component that renders a default/starter layout for directories.
 *
 * @param {Object} props
 *   @param {Node} [props.addRowTag] - Component containing the button for adding a new "row",
 *     in the case of employees see EmployeeDirectory
 *   @param {Boolean} [props.alwaysRenderHeader=false] - When true it forces the DirectoryResultsHeader to render
 *     even if there are no results
 *   @param {Object} props.baseParams - Query params to pass through in all API requests.
 *   @param {String} [props.className] - optional extra className to assign to the root element
 *   @param {Object} [props.trackingData] - Base stat object data to pass to tracking functions.
 *   @param {Object} [props.excludeSearchTypes] - Set of search types to hide, for example
 *   EarnerDirectory hides searching by issuer e.g. { issuer: true }
 *   @param {Node} [props.filterTag] - Component containing the button for opening the filters modal
 *   @param {Object} [props.createExportArgs] - Object describing the type of export that will
 *   be created. See EmployeeDirectory.
 *   @param {Object} [props.readExportArgs] - Object describing the type of export that will
 *   be created. See EmployeeDirectory.
 *   @param {Boolean} [props.exportEnabled] - Displays an export button to export results if enabled.
 *   @param {function(ActionState,QueryString)} [props.resultsRender] - Callback function that will be\
 *   called with two parameters: an ActionState object representing the resource state of the directory
 *   results fetched and a QueryString object containing the filters configuration used.
 *   If not defined will fallback to a default.
 *   @param {String} [props.searchBarPlaceholderText] - Text to be displayed in the SearchBar component
 *   @param {Boolean} [props.sortSelectorEnabled=true] - Controls if the DirectorySortSelector in
 *     DirectoryResultsHeader should be rendered
 *   @param {boolean} [props.suppressSearchWithoutTerm=false] - if true, don't show suggested terms
 *     in the search bar unless the user has entered a query
 */
export const DirectoryBase = props => {
  return (
    <DirectoryLayout
      baseParams={props.baseParams}
      className={makeClassName('c-directory-base__layout', props.className)}
      filterAction={props.filterAction}
    >
      <DirectorySearchWithFilters
        baseParams={props.baseParams}
        excludeSearchTypes={props.excludeSearchTypes}
        trackingData={props.trackingData}
        suppressSearchWithoutTerm={props.suppressSearchWithoutTerm}
        directorySearchTag={props.directorySearchTag}
        filterTag={props.filterTag}
        searchBarPlaceholderText={props.searchBarPlaceholderText}
        tabs={props.tabs}
        dataToFilter={props.dataToFilter}
        filterNames={props.filterNames}
        tabNamesToFilterKeyTransforms={props.tabNamesToFilterKeyTransforms}
        orFilters={props.orFilters}
      />
      <DirectoryResultsWrapper
        baseParams={props.baseParams}
        createExportArgs={props.createExportArgs}
        readExportArgs={props.readExportArgs}
        exportEnabled={props.exportEnabled}
        exportButton={props.exportButton}
        exportRemoveFilter={props.exportRemoveFilter}
        resultsRender={props.resultsRender || DirectoryBase.DefaultResultsRender}
        filterAction={props.filterAction}
        addRowTag={props.addRowTag}
        alwaysRenderHeader={props.alwaysRenderHeader}
        sortSelectorEnabled={props.sortSelectorEnabled}
      />
    </DirectoryLayout>
  );
};

DirectoryBase.DefaultResultsRender = (directoryResultsFilterState, filtersQueryString) => (
  <EarnerCards
    data={directoryResultsFilterState.resources}
    status={directoryResultsFilterState.status}
    filters={filtersQueryString}
  />
);

DirectoryBase.propTypes = {
  addRowTag: PropTypes.node,
  alwaysRenderHeader: PropTypes.bool,
  baseParams: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  trackingData: PropTypes.object,
  excludeSearchTypes: PropTypes.object,
  createExportArgs: PropTypes.object,
  readExportArgs: PropTypes.object,
  exportEnabled: PropTypes.bool,
  exportButton: PropTypes.func,
  exportRemoveFilter: PropTypes.bool,
  resultsRender: PropTypes.func,
  suppressSearchWithoutTerm: PropTypes.bool,
  searchBarPlaceholderText: PropTypes.string,
  sortSelectorEnabled: PropTypes.bool,
  directorySearchTag: PropTypes.object,
  filterAction: PropTypes.object,
  filterTag: PropTypes.node,
  tabs: PropTypes.array,
  dataToFilter: PropTypes.object,
  filterNames: PropTypes.object,
  tabNamesToFilterKeyTransforms: PropTypes.object,
  orFilters: PropTypes.bool
};

DirectoryBase.defaultProps = {
  addRowTag: null,
  alwaysRenderHeader: false,
  directorySearchTag: DirectorySearch,
  filterAction: filter,
  sortSelectorEnabled: true
};
