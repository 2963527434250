import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { Avatar } from 'controls/avatar';
import { getProfileAction } from 'profile';
import { OrganizationModalSelector } from 'pages/workforce/organizations';

import './current_organization.sass';

export const CurrentOrganization = (props) => {
  const [orgSelectorOpen, setOrgSelectorOpen] = useState(false);

  const openOrgSelector = useCallback(() => setOrgSelectorOpen(true), []);
  const closeOrgSelector = useCallback(() => setOrgSelectorOpen(false), []);

  const [currentUserState] = getProfileAction.useAction();

  const orgContent = (
    <>
      <Avatar
        src={props.organization.photo_url}
        className="cr-workforce-current-organization__org-logo"
        emptyImage={
          <div className="cr-workforce-current-organization--empty">
            <FontAwesomeIcon icon={faBuilding}/>
          </div>
        }
        width={48}
      />
      <span className="cr-workforce-current-organization__org-name">
        {props.organization.name}
      </span>
    </>
  );

  // this condition isn't perfect; organizations can be deactivated, but it's close enough
  const enableOrgSwitcher = currentUserState.resources.memberships.length > 1;

  return (
    <div className="cr-workforce-current-organization">
      {
        enableOrgSwitcher
          ? (
            <button className="cr-workforce-current-organization__org" onClick={openOrgSelector}>
              {orgContent}
            </button>
          )
          : <div className="cr-workforce-current-organization__org">{orgContent}</div>
      }
      {
        enableOrgSwitcher && (
          <OrganizationModalSelector
            show={orgSelectorOpen}
            onClose={closeOrgSelector}
            currentUser={currentUserState.resources}
            current_organization_id={props.organization.id}
          />
        )
      }
    </div>
  );
};

CurrentOrganization.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    photo_url: PropTypes.string
  }).isRequired
};
