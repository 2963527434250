import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';

export const employeesLearningActivityActionManager =
  (new ActionManager('employeesLearningActivity'))
    .add({
      requestKey: 'get',
      resourceType: 'badgeEarnerActivity',
      url: '/api/v1/organizations/[organization_id]/employees/badge_earner_activity',
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      effect: 'read',
      method: 'GET'
    }, true);
