import PropTypes from "prop-types";
import React from "react";

const statusToColorClass = {
  Accepted: 'c-employees-employees-table__status-accepted',
  Pending: 'c-employees-employees-table__status-pending',
  Invited: 'c-employees-employees-table__status-invited',
  Rejected: 'c-employees-employees-table__status-rejected',
  Created: 'c-employees-employees-table__status-created'
};

export const InvitationStatusIndicator = ({ status = 'Created' }) => {
  const colorClass = statusToColorClass[status];

  return (
    <div>
      <svg className="mr-2" height="10" width="10">
        <circle className={colorClass} cx="5" cy="5" r="5"/>
      </svg>
      {status}
    </div>
  );
};

InvitationStatusIndicator.propTypes = {
  status: PropTypes.string.isRequired
};
