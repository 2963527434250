import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { timeDay, timeWeek, timeMonth, timeYear } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import {
  LineSegment,
  Rect,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer
} from 'victory';
import dateUtils from 'utils/date';
import elementUtils from 'utils/element';
import { onResizeManager } from 'utils/window_event_manager';
import { COLORS, FONT_FAMILY } from './constants';
import { Tooltip } from './tooltip';

import './timeseries.sass';

/**
 * Component for rendering a line chart depicting trend data over time. Its main prop "data" is an
 * array of x/y values where x is a JS Date and y is the value of the metric at that date/time. Not
 * all of the provided data points will be charted as-is. They may be aggregated together and
 * rendered as a cumulative point for a whole period of time (e.g. a week, month, etc.).
 *
 * The chart is intended to be displayed at around 195 pixels in height and allowed to use all of
 * the width available in its immediate container. It will automatically adjust the number of data
 * points and the viewBox of the rendered SVG to keep the chart legible under those conditions.
 *
 * @param {object} props
 * @param {Array<{ x: Date, y: number }>} props.data - the data to be rendered as a trend chart,
 *   as an array of objects order by x, with dates for x values and numbers for y; data will be
 *   automatically aggregated into a number of points that can fit within the width allowed for the
 *   component, and aligned to "nice" date ranges (e.g. weeks, months, etc.)
 * @param {(function:function(number[]):number)|function(number[]):number} [props.aggregator] -
 *   function to aggregate adjacent datapoints when they need to be combined due to display area
 *   limitations; can be either a factory function (e.g. in case the aggregation needs to maintain
 *   state between calls, such as for a moving average) or a simple function; defaults to simple
 *   sum; {@link standardAggregators} is available for common needs
 * @param {Array<Date>} [props.highlightedDomain=[]] - a pair of dates indicating the beginning and
 *   end of a range to highlight on the chart
 * @param {function({x:Date,y:Number}):string[]} [props.formatDataLabel] - optional function to use
 *   instead of default tooltip label format; should return a pair where the first element is the
 *   formatted y value and the second element is the formatted x value
 * @param {Date} [props.minX] - the minimum value that x should have; the chart will be padded out
 *   to this, if there are no actual values for x that small using 0 for the corresponding y value
 * @param {Date} [props.maxX] - the maximum value that x should have; the chart will be padded out
 *   to this, if there are no actual values for x that large using 0 for the corresponding y value
 * @returns {JSX.Element}
 * @constructor
 */
export const Timeseries = (props) => {
  const rootElement = useRef();
  // 720 is an arbitrary value that assumes most people will be viewing these charts on desktop
  const [width, setWidth] = useState(720);
  const widthRef = useRef(width);
  widthRef.current = width;

  const [targetTicks, targetTickLabels] = computeTargetTicks(width);

  /**
   * Data after it has been processed to make it suitable for rendering the chart, i.e. by aligning
   * it to "nice" time intervals and aggregating y values for the resulting intervals.
   *
   * @type {Array<{x: Date, y: number, endX: Date}>}
   */
  const processedData = useMemo(() => {
    if (props.processData) {
      return coalesceDataToTicks(props.data, props.minX, props.maxX, targetTicks, props.aggregator);
    } else {
      return props.data;
    }
  }, [props.data, props.minX, props.maxX, targetTicks, props.aggregator]);

  const domain = useMemo(() => {
    const yLow = 0;
    const yHigh = Math.max(5, Math.max(...processedData.map((d) => d.y)));
    return { x: [processedData[0].x, processedData[processedData.length - 1].x], y: [yLow, yHigh] };
  }, [processedData]);

  useLayoutEffect(() => {
    const onResize = () => {
      const size = elementUtils.innerWidth(rootElement.current);
      if (size !== widthRef.current) {
        setWidth(size);
      }
    };
    onResize();
    onResizeManager.add(onResize);
    return () => {
      onResizeManager.remove(onResize);
    };
  }, []);

  return (
    <div className="cr-charts-timeseries" ref={rootElement}>
      <VictoryChart
        width={width}
        height={195}
        domain={domain}
        domainPadding={{ x: [15, 10], y: 15 }}
        containerComponent={<VictoryVoronoiContainer className="cr-charts-timeseries__container" />}
        padding={{ top: 20, right: 20, bottom: 50, left: 50 }}
        scale={{ x: 'time', y: 'linear' }}
        style={{ parent: { width: `${width}px`, height: '195px' } }}
      >
        <VictoryAxis
          axisComponent={<LineSegment x1={0} x2={width} type="axis" />}
          style={{
            axis: {
              stroke: COLORS.pinkishGrey,
              strokeWidth: 2
            },
            tickLabels: { fontFamily: FONT_FAMILY }
          }}
          tickCount={props.tickCount}
          tickFormat={props.tickFormat || makeDateTickFormat(targetTickLabels)}
        />
        <VictoryAxis
          dependentAxis
          gridComponent={<LineSegment x1={0} x2={width} type="grid" />}
          orientation="left"
          style={{
            grid: { stroke: COLORS.lightLightAccent },
            axis: { stroke: 'none' },
            tickLabels: { fill: COLORS.darkMedium, fontFamily: FONT_FAMILY }
          }}
          tickFormat={yAxisTickFormat}
          tickLabelComponent={<VictoryLabel dy={-10} />}
        />
        <VictoryLine data={processedData} style={{ data: { stroke: COLORS.mediumGreen } }} />
        <VictoryScatter
          data={processedData}
          labelComponent={(
            <Tooltip
              dy={-20}
              dataLabel={props.formatDataLabel ?? dataLabel}
            />
          )}
          // force Victory to render the labelComponent, but the actual label value will be provided
          // by CustomTooltip
          labels={() => [""]}
          size={3}
          style={{ data: { stroke: COLORS.mediumGreen, fill: COLORS.lightLight, strokeWidth: 2 } }}
        />
        <Highlight highlightedDomain={props.highlightedDomain} data={processedData} />
      </VictoryChart>
    </div>
  );
};

Timeseries.defaultProps = {
  highlightedDomain: []
};

Timeseries.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ x: PropTypes.instanceOf(Date).isRequired, y: PropTypes.number.isRequired })
  ).isRequired,
  aggregator: PropTypes.func,
  highlightedDomain: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  maxX: PropTypes.instanceOf(Date),
  minX: PropTypes.instanceOf(Date),
  processData: PropTypes.bool,
  formatDataLabel: PropTypes.func,
  tickCount: PropTypes.number,
  tickFormat: PropTypes.func
};

/**
 * Internal component used by Timeseries to highlight a portion of the chart as defined by the date
 * range encompassed by the highlightedDomain prop.
 *
 * @param {object} props
 * @param {array<{x:Date, y:number}>} props.data - the chart data, used to ensure that the
 *   highlighted region does not extend past the last data point
 * @param {{ x: array<Date> }} props.domain - the chart's domain prop, including an array with the
 *   minimum and maximum x values
 * @param {array<Date>} props.highlightedDomain - the date range to highlight; may be empty, in
 *   which case, nothing will be highlighted
 * @param {{ x: function, y: function }} props.scale - the chart's scale function to translate the
 *   raw highlightedDomain value into the dimensions for the viewport of the chart
 * @returns {JSX.Element|null}
 * @constructor
 */
const Highlight = (props) => {
  const domain = props.domain;
  const start = dateUtils.boundedDate(props.highlightedDomain[0], domain.x[0], domain.x[1]);
  let end = dateUtils.boundedDate(props.highlightedDomain[1], domain.x[0], domain.x[1]);
  const dataMaxX = props.data[props.data.length - 1].x;
  if (dataMaxX < end) {
    end = dataMaxX;
  }
  if (start && end && end > start && props.data.length > 1) {
    const startX = props.scale.x(start);
    const width = props.scale.x(end) - startX;
    const startY = props.scale.y(domain.y[1]);
    const height = props.scale.y(domain.y[0]) - startY;
    return (
      <Rect x={startX} width={width} fill={COLORS.lightGreen} y={startY} height={height} />
    );
  } else {
    return null;
  }
};

Highlight.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ x: PropTypes.instanceOf(Date), y: PropTypes.number })),
  domain: PropTypes.shape({
    x: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    y: PropTypes.arrayOf(PropTypes.number)
  }),
  highlightedDomain: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  scale: PropTypes.shape({ x: PropTypes.func, y: PropTypes.func })
};

/**
 * Takes a datum and produces a label for it by formatting the y value in localized numeric style,
 * and producing a display of the date range covered by the data point.
 *
 * @param {{ x: Date, y: number}} datum - the data point for which to generate a label
 * @returns {[string, string]}
 */
const dataLabel = (datum) => {
  let end;
  if (datum.endX) {
    const inclusiveEndX = timeDay.offset(datum.endX, -1);
    if (inclusiveEndX > datum.x) {
      end = dateUtils.formatDateMonthShortYear(inclusiveEndX);
    }
  }
  // only include year once (even if they're different...users can figure it out), so skip it on
  // start unless there is no value for end
  const start =
    end ? dateUtils.formatDayShortMonth(datum.x) : dateUtils.formatDateMonthShortYear(datum.x);
  return [Number(datum.y).toLocaleString(), `${start}${end ? ` - ${end}` : ''}`];
};

// Date formatters used by dateTickFormat
const formatWeek = timeFormat('%d %b');
const formatMonth = timeFormat('%b %Y');
const formatYear = timeFormat('%Y');

/**
 * Function to produce a function to format an X-axis tick value (i.e. date) in a human-readable
 * way, constrained to a specified maximum number of label values.
 *
 * @returns {function(Date, number, Date[]):String|String[]}
 */
const makeDateTickFormat = (labelTarget) => (date, index, ticks) => {
  if (timeDay(date) < date) {
    // if tick granularity is less than one day, skip it
    return '';
  }
  if (labelTarget && labelTarget < ticks.length) {
    const step = Math.ceil(ticks.length / labelTarget);
    if (index % step > 0) {
      return '';
    }
  }
  return timeMonth(date) < date ? [formatWeek(date), formatYear(date)] : formatMonth(date);
};

/**
 * Breakpoints used to determine intervals at which data points should be rendered in a Timeseries.
 * Aims for no more than 30 points in the chart using "nice" intervals where possible, such as
 * weekly, monthly, quarterly, etc.
 */
const STEP_BREAKPOINTS = [
  {
    interval: timeDay,
    steps: [1, 2, 3]
  },
  {
    interval: timeWeek,
    steps: [1, 2]
  },
  {
    interval: timeMonth,
    steps: [1, 3, 6]
  }
];

/**
 * Given the date range covered by a Timeseries, choose the interval at which data points should be
 * rendered.
 *
 * @param {Date} start - beginning of the date range
 * @param {Date} end - end of the date rnage
 * @param {number} maxTicks - the maximum number of ticks to render for the range, based on
 *   available width
 * @returns {[*, number]|[*, number]}
 */
const chooseTickInterval = (start, end, maxTicks) => {
  for (let i = 0; i < STEP_BREAKPOINTS.length; ++i) {
    const interval = STEP_BREAKPOINTS[i].interval;
    const duration = interval.count(start, end);
    const breakpoints = STEP_BREAKPOINTS[i].steps;
    for (let j = 0; j < breakpoints.length; ++j) {
      if (duration / breakpoints[j] < maxTicks) {
        return [interval, breakpoints[j]];
      }
    }
  }
  return [timeYear, 1];
};

/**
 * Given an array of data elements and optional min and max bounds that the data was selected from,
 * chooses an appropriate interval at which to render data points and aggregates the provided data
 * according to the resulting buckets.
 *
 * @param {array<object>} data - the data set, containing at minimum x values of type Date and
 *   numeric y values
 * @param {Date} [min] - optional; the minimum possible value for x among the data (e.g. if the data
 *   were produced by filtering with min as the lower bound)
 * @param {Date} [max] - optional; the maximum possible value for x among the data (e.g. if the data
 *   were produced by filtering with max as the upper bound)
 * @param {number} [maxTicks=31] - the maximum number of data points to return, use this to limit
 *   the number of returned data points to avoid overcrowding narrow charts; default is 31, so that
 *   a full month's worth of data can be displayed as individual points
 * @param {(function:function(number[]):number)|function(number[]):number} [aggregator] - function used to aggregate
 *   y values of data points when necessary
 * @returns {array<{ x: Date, y: number, endX: Date }>}
 */
const coalesceDataToTicks = (
  data, min = null, max = null, maxTicks = 31, aggregator = sum
) => {
  if (!min && data.length < 1) {
    return [];
  } else if (!min && data.length < 2) {
    return [{ ...data[0], endX: data[0].x }];
  }

  // if we were passed a factory, call it to instantiate the actual aggregator
  if (aggregator.length === 0) {
    aggregator = aggregator();
  }

  const start = min || data[0].x;
  const end = max || data[data.length - 1].x;
  const [interval, step] = chooseTickInterval(start, end, maxTicks);
  const ticks = interval.range(interval.ceil(start), timeDay.offset(end), step);
  if (interval.floor(start) < interval.ceil(start)) {
    ticks.unshift(timeDay.floor(start));
  }

  let dataIndex = 0;
  const result = [];
  for (let tickIndex = 0; tickIndex < ticks.length; ++tickIndex) {
    let nextTick = ticks[tickIndex + 1];
    const yValues = [];
    for (; dataIndex < data.length && (!nextTick || data[dataIndex].x < nextTick); ++dataIndex) {
      yValues.push(data[dataIndex].y);
    }
    const y = aggregator(yValues);
    const x = (tickIndex === 0 && min) ? min : ticks[tickIndex];
    if (!nextTick) {
      nextTick = max || timeDay.offset(end);
    }
    result.push({ x, y, endX: nextTick });
  }
  return result;
};

/**
 * Given the width available to the component, decide on the maximum number of data points and axis
 * tick labels that will be shown.
 *
 * @param {number} width - the width available to the chart component
 * @returns {[number, null]|[number, number]}
 */
const computeTargetTicks = (width) => {
  if (width > 500) {
    return [31, null];
  } else if (width > 400) {
    return [20, 3];
  } else {
    return [10, 2];
  }
};

/**
 * Sums numeric values from an array
 *
 * @param {number[]} values - the values to aggregate
 * @returns {*}
 */
const sum = (values) => {
  return values.reduce((agg, current) => {
    return agg + current;
  }, 0);
};

/**
 * Computes the arithmetic mean of the values
 *
 * @param {number[]} values - the values to aggregate
 * @param {boolean} [round=false] - whether to round to the nearest whole number
 * @returns {number}
 */
const mean = (values, round = false) => {
  // this isn't necessarily the proper response, but in theory this should never be called with an
  // empty values argument
  if (values.length === 0) {
    return 0;
  }

  const total = sum(values);
  const result = total / values.length;
  if (round) {
    return Math.round(result);
  } else {
    return result;
  }
};

/**
 * Return the last value from the input array
 *
 * @param {number[]} values - the values to "aggregate"
 * @returns {number}
 */
const last = (values) => {
  return values[values.length - 1];
};

/**
 * Factory for {@link mean} aggregator that rounds result to nearest whole number
 *
 * @returns {function(number[]): number}
 */
mean.rounded = () => (values) => mean(values, true);

/**
 *
 * @type {object}
 * @property {function(number[]):number} last - aggregator to pick the last value;
 *   see {@link last}
 * @property {function(number[]):number} mean - aggregator to average values; see
 *   {@link mean}
 * @property {function:function(number[]):number} mean.rounded - aggregator to average
 *   values and round them to the nearest whole number; see {@link mean.rounded}
 * @property {function(number[]):number} sum - aggregator to sum values; see {@link sum}
 */
export const standardAggregators = { last, mean, sum };

/**
 * Function to nicely format y-axis tick values, so that they never exceed 5 characters long.
 *
 * @param {number} tickValue - the tick value to format
 * @param {number} _index - unused; index representing which position of ticks that this tick is
 * @param {number[]} ticks - the complete list of tick values
 * @returns {string} - formatted tick value
 */
const yAxisTickFormat = (tickValue, _index, ticks) => {
  if (tickValue < 10000) {
    return tickValue.toLocaleString();
  } else if (tickValue < 1000000) {
    const thousands = (tickValue / 1000).toFixed(0);
    return `${thousands}k`;
  } else {
    const digits = ticks.every(t => t < 1000000 || (t % 1000000) === 0) ? 0 : 1;
    return `${(tickValue / 1000000).toFixed(digits)}m`;
  }
};

export const testing = { coalesceDataToTicks, dataLabel };
