import React from 'react';
import PropTypes from 'prop-types';
import FaethmOccupationMappingContext from "./faethm_occupation_mapping_context";

/**
 * Convenience wrapper for FaethmOccupationMappingContext.Provider to set integrated_with_faethm
 * key of the context, given the organizationResources.
 *
 * @param {Object} organizationResources - the organization data
 * @param {boolean} [organizationResources.integrated_with_faethm=false] - A boolean value indicating whether the
 * organization is integrated with Faethm. Defaults to `false`.
 * @param {React.node} props.children
 * @returns {React.element}
 */

export const FaethmOccupationMappingProvider = ({ children, organizationResources }) => {
  return (
    <FaethmOccupationMappingContext.Provider value={organizationResources.integrated_with_faethm}>
      {children}
    </FaethmOccupationMappingContext.Provider>
  );
};

FaethmOccupationMappingProvider.propTypes = {
  children: PropTypes.node,
  organizationResources: PropTypes.shape({ integrated_with_faethm: PropTypes.boolean })
};
