import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from "highcharts";
import HighchartsTreeChart from "highcharts/modules/treemap";
import HighchartsReact from "highcharts-react-official";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import { clone } from 'utils/object';
import { toolTipPositioner } from './utils';

HighchartsAccessibility(Highcharts);
HighchartsTreeChart(Highcharts);

export const TreeMap = ({
  pointClick,
  currentData,
  toolTipFormatter,
  pointChartFormatter
}) => {
  const chartOptions = {
    series: [
      {
        type: "treemap",
        layoutAlgorithm: "squarified",
        data: currentData,
        dataLabels: {
          useHTML: true,
          enabled: true,
          style: {
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "600",
            fontSize: "16px",
            color: "#4E4E4E",
            textOutline: 0,
            textAlign: "center"
          },
          formatter: pointChartFormatter
        },
        point: {
          events: {
            click: function() {
              const selection = clone(this.options);
              pointClick(selection);
            }
          }
        }
      }
    ],
    plotOptions: {
      series: {
        animation: false
      },
      treemap: {
        color: '#D5E1E5',
        borderWidth: 2,
        borderColor: '#FFFFFF',
        states: {
          hover: {
            borderWidth: 0,
            borderColor: 'transparent'
          }
        }
      },
      states: {
        hover: {
          borderColor: 'transparent'
        }
      }
    },
    title: {
      text: ""
    },
    tooltip: {
      useHTML: true,
      outside: true,
      shape: 'square',
      shadow: false,
      backgroundColor: 'transparent',
      positioner: toolTipPositioner,
      style: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "400",
        fontSize: "16px",
        zIndex: 5
      },
      formatter: toolTipFormatter
    },
    chart: {
      borderRadius: 20
    }
  };

  return (
    <HighchartsReact
      containerProps={{ style: { height: "476px", display: 'flex' } }}
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
};

TreeMap.propTypes = {
  pointClick: PropTypes.func.isRequired,
  toolTipFormatter: PropTypes.func.isRequired,
  pointChartFormatter: PropTypes.func.isRequired,
  currentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    children: PropTypes.array
  }))
};
