import React, { useEffect } from 'react';
import { useAlerts } from 'alert';
import ConfirmationModal from 'controls/confirmation_modal';
import {
  LearningPlanEmploymentActions
} from '../../actions';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
/**
 * Remove Recomendation component. When clicked will display a modal asking
 * confirmation to remove one or all recommendations.
 *
 * @param {Object} props
 *   @param {uuid} props.organizationId - The organization ID.
 *   @param {uuid} props.learningPlanId - The learning plan ID.
 *   @param {uuid} props.recommendationId - The learning plan employment ID
 *   @param {Boolean} props.showModal display dialog or not
 *   @param {Function} props.setShowModal toggle the showModal to true or false
 */

export const RemoveRecommendationModal = (props) => {
  const intl = useIntl();
  const [RemoveEmploymentState, RemoveEmployment] =
    LearningPlanEmploymentActions.useAction('delete', props.recommendationId);
  const alerts = useAlerts();
  const removeRecommendation = () => {
    RemoveEmployment(
      {
        organization_id: props.organizationId,
        learning_plan_id: props.learningPlanId
      });
  };

  useEffect(() => {
    if (RemoveEmploymentState.status.failed) {
      props.setShowModal();
      alerts.add({
        type: 'error',
        text: intl.formatMessage({
          id: 'workforce.learning.modal.recommendation.removed.failed.text',
          defaultMessage: 'Failed to remove recommendation.'
        })
      });
    }
    if (RemoveEmploymentState.status.succeeded) {
      props.setShowModal();
      alerts.add({
        type: 'notice',
        text: intl.formatMessage({
          id: 'workforce.learning.modal.recommendation.removed.succeeded.text',
          defaultMessage: 'Recommendation successfully removed.'
        })
      });
    }
  }, [RemoveEmploymentState.status.succeeded, RemoveEmploymentState.status.failed]);

  const modalTitle = intl.formatMessage({
    id: 'workforce.learning.modal.title.remove_recommendation',
    defaultMessage: 'Remove recommendation'
  });

  const modalButtonLabel = intl.formatMessage({
    id: 'workforce.learning.modal.button.remove_recommendation',
    defaultMessage: 'Remove'
  });

  const confirmationModalDescription = intl.formatMessage({
    id: 'workforce.learning.modal.recommendation.remove.description',
    defaultMessage: 'Once an employee is removed they will no longer see this recommendation. ' +
    'If they have already earned a badge it will not be removed from their profile.'
  });

  return (
    <div className="remove-recommendation-modal">
      <ConfirmationModal
        title={modalTitle}
        question=""
        buttonAction={removeRecommendation}
        buttonActionDescription={modalButtonLabel}
        description={confirmationModalDescription}
        showModal={props.showModal}
        setShowModal={props.setShowModal}
        isLoading={RemoveEmploymentState.status.pending}
      />
    </div>
  );
};

RemoveRecommendationModal.propTypes = {
  organizationId: PropTypes.string,
  learningPlanId: PropTypes.string,
  recommendationId: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func
};
