import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { pluralize } from 'utils/localization';
import { tracking } from 'app_utils/tracking';
import { useEffectExceptOnMount, useShallowEquals } from 'utils/react_utils';
import Pagination from 'controls/pagination';
import SortSelector from 'controls/sort_selector';
import { useBrowserQueryString } from 'controls/with_query_string';
import { Filters } from 'controls/filters';
import { LoadingSpinner } from 'controls/loading_spinner';
import { getProfileAction } from 'profile';
import { FACETS, RecruitDirectorySearch } from './recruit_directory_search';
import { filter } from './earner_directory_actions';
import { FILTER_NAMES } from './constants';
import { Button, SimpleButton } from 'controls/button';
import { useRecruitDirectorySortSelector } from 'pages/public/organization/earner_directory/use_recruit_directory_sort_selector';
import { RecruitTable } from 'pages/public/organization/earner_directory/recruit_table';
import { SavedDirectorySearchPropType } from './saved_directory_searches/utils';
import { ContactAndLinksFilters } from "./contact_and_links_filters";
import { SavedDirectorySearchCreateUpdateButton }
  from 'pages/credly_recruit/saved_directory_search_create_update_button';
import { SavedDirectorySearchesButton } from "./saved_directory_searches_button";

import './recruit_directory.sass';

const MAX_RESULTS = 10000;

//
// Searchable user directories.
//
// Related controllers: earner_directory_controller, directory_filters_controller
// Adding new filters and search types: See directory/constants.rb
//

/**
 * The Recruit Directory limits results to earners of badges issued by an organization.
 *
 * @param {Object} props
 *   @param {String} props.id - The id of the DirectoryFilter.
 *   @param {Object} props.excludeSearchTypes - Set of search types to hide.
 */
export const RecruitDirectory = props => {
  return (
    <RecruitBase
      baseParams={{ talent: true }}
      savedDirectorySearch={props.savedDirectorySearch}
      trackingData={{
        object_id: getProfileAction.useAction()[0].resources.id,
        object_type: 'User'
      }}
    />
  );
};

RecruitDirectory.propTypes = {
  savedDirectorySearch: SavedDirectorySearchPropType
};

/**
 * Base component for recruit directories.
 *
 * @param {Object} props
 *   @param {Object} props.baseParams - Query params to pass through in all API requests.
 *   @param {Object} props.trackingData - Base data to pass to tracking functions.
 *   @param {Object} props.excludeSearchTypes - Set of search types to hide.
 */
export const RecruitBase = props => {
  const history = useHistory();
  const dirTracking = useDirectoryTracking(props.trackingData);

  const queryString = useBrowserQueryString();
  const filters = queryString.dig('filter');
  const facets = queryString.dig('facets');
  if (facets.isEmpty()) {
    facets.pushUnique('0', 'location');
    filters.pushUnique('or', 'location_name');
  }
  const currentFacets = facets.mapFlat((key, val) => val);
  const { currentSort, sortValues } = useRecruitDirectorySortSelector(props.savedDirectorySearch);
  const [filterState] = filter.useOnMount(
    {
      ...queryString.get(),
      ...props.baseParams,
      ...(currentSort === 'new_results' &&
          { saved_directory_search_id: props.savedDirectorySearch.id }
      )
    }
  );
  const meta = filterState.metadata;

  // If the user has a saved bookmark with a page number that's too high for the results, and
  // the number of results happens to be less than 8, there will be no pagination UI, so the user
  // will be presented with a "no results" page which actually has results, but on a previous, and
  // inaccessible, page. Avoid this case by always redirecting to page 1 when there are no results
  useEffectExceptOnMount(() => {
    if (
      filterState.status.succeeded &&
      filterState.resources.length === 0 &&
      meta.current_page !== 1
    ) {
      history.replace({ search: queryString.clone().removeKey('page').toString() });
    }
  }, [filterState.status.succeeded, filterState.resources, meta]);

  /**
   * Sort the list.
   *
   * @param {String} type
   */
  const sort = React.useCallback(type => {
    history.replace({ search: queryString.clone().set('sort', type).toString() });
  }, [queryString]);

  /**
   * Called on filter change.
   *
   * @param {QueryString} filters
   */
  const saveFilters = React.useCallback(filters => {
    const newString = queryString.clone().set('filter', filters).toString();
    history.push({ search: newString });
  }, [queryString]);

  /**
   * Called on facet change.
   *
   * @param {QueryString} filters
   */
  const onSelectFacet = React.useCallback((index, facet) => {
    const q = queryString.clone();
    // clear previous facet's filters
    const oldFacet = q.dig('facets').get(index);
    if (oldFacet) {
      const facetName = oldFacet + '_name';
      const notFacetName = "not_" + facetName;
      q.dig('filter').set(facetName, undefined);
      q.dig('filter').set(notFacetName, undefined);
      const oldOrFilters = q.dig('filter').dig(filterOperator(oldFacet));
      if (oldOrFilters.isPresent(facetName)) {
        oldOrFilters.removeFromArray(facetName);
      }
    }
    q.dig('facets').set(index, facet);
    if (undefined !== facet) {
      q.dig('filter').pushUnique('or', `${facet}_name`);
    }

    // compress facet array when deleting a facet from the list
    if (facet === undefined) {
      let nextIndex = parseInt(index) + 1;
      while (nextIndex < currentFacets.length) {
        const nextFacet = q.dig('facets').get(nextIndex);
        q.dig('facets').set(nextIndex - 1, nextFacet);
        nextIndex += 1;
      }
      q.dig('facets').removeKey(nextIndex - 1);
    }

    history.push({ search: q.toString() });
  }, [queryString]);

  /**
   * Called to add a facet
   *
   * @param {QueryString} queryString
   */
  const onAddFacet = React.useCallback(facet => {
    let q = queryString.clone();
    const nextFacet = FACETS.filter(f => !currentFacets.includes(f.name))[0].name;
    q.dig('facets').set(currentFacets.length, nextFacet);

    // default to 'or' for new facets
    q.dig('filter').pushUnique('or', facetName(nextFacet));
    history.push({search: q.toString()});
  }, [queryString]);

  /** Called to add a filter
   *
   * @param {(function(*=): void)|*}
   */
  const onFiltersChanged = React.useCallback((filterValues) => {
    const q = queryString.clone();
    const filters = q.dig('filter');
    for (const key in filterValues) {
      filters.set(key, filterValues[key]);
    }
    history.push({ search: q.toString() });
  }, [queryString]);

  /**
   * A search result has been selected.
   *
   * @param {{key: string, value: string}} result - Filter for the search result.
   */
  const onSelectSearchResult = React.useCallback((index, result) => {
    const q = queryString.clone().removeKey('page');
    q.dig('filter').pushUnique(result.key, result.value);
    dirTracking.trackSelectSearchResult(result);
    history.push({ search: q.toString() });
  }, [dirTracking.trackSelectSearchResult, queryString]);

  /**
   * A search operator has been selected.
   *
   * @param {{key: string, value: string}} result - Filter for the search operator.
   */
  const onSelectOperator = React.useCallback((newOperator, facet) => {
    const previousOperator = filterOperator(facet);
    if (previousOperator === newOperator) {
      return;
    }

    const q = queryString.clone().removeKey('page');
    const facetName = facet + "_name";
    const notFacetName = "not_" + facetName;

    const filter = q.dig('filter');
    filter.dig(previousOperator).removeFromArray(facetName);
    filter.pushUnique(newOperator, facetName);

    if (previousOperator === 'not') {
      // flip this facet's NOT filters to positives
      filter.set(facetName, filter.dig(notFacetName));
      filter.set(notFacetName, undefined);
    } else if (newOperator === 'not') {
      // flip this facet's filters to negatives
      filter.set(notFacetName, filter.dig(facetName));
      filter.set(facetName, undefined);
    }
    history.push({ search: q.toString() });
  }, [queryString]);

  /**
   * Render the earners.
   *
   * @returns {React.element}
   */
  const renderEarners = () => {
    return (
      <RecruitTable
        data={filterState.resources}
        status={filterState.status}
        filters={filters}
      />
    );
  };

  const facetName = facet => {
    return facet + '_name';
  };

  const filterOperator = facet => {
    let configuredOperator = ['or', 'and', 'not'].find(
      operator => filters.dig(operator).isPresent(facetName(facet))
    );
    return configuredOperator || 'and';
  };

  /**
   * Renders a search component
   *
   * @param {Integer} index
   *
   * @returns {React.element}
   */

  const renderSearchFilter = (index) => {
    const facet = facets.get(index) || 'location';
    const facetsNamesToInclude = {};
    const filterBaseName = facetName(facet);
    facetsNamesToInclude[filterBaseName] = FILTER_NAMES[filterBaseName];
    facetsNamesToInclude['not_' + filterBaseName] = FILTER_NAMES[filterBaseName];

    // don't include the facet of the current row in the exclusion list, but do include all of the
    // others
    const otherFacets = Object.values(facets.get() || {}).filter(f => f !== facet);
    const shouldAllowDelete = (Object.values(facets.get()).length > 1);

    return (
      <div key={facet + index}>
        <RecruitDirectorySearch
          baseParams={{ ...props.baseParams, ...(filters.isEmpty() ? {} : { filter: filters }) }}
          excludeSearchTypes={otherFacets}
          onTermChanged={dirTracking.trackSearchTermChanged}
          onSelect={onSelectSearchResult}
          onSelectFacet={onSelectFacet}
          onSelectOperator={onSelectOperator}
          operator={filterOperator(facet)}
          index={index}
          facet={facet}
          allowDelete={shouldAllowDelete}
        >
          <Filters
            className="recruit-directory__filters"
            filterNames={facetsNamesToInclude}
            filters={filters}
            hideRemoveAll
            hideType
            onChange={saveFilters}
            align="right"
            ui="standard"
          />
        </RecruitDirectorySearch>
      </div>
    );
  };

  /**
   * Renders all search filters
   *
   * @returns {React.element}
   */
  const renderSearchFilters = () => {
    return facets.mapFlat((index, _val) => renderSearchFilter(index));
  };

  const moreFacetsAvailable =
    FACETS.filter(facet => !currentFacets.includes(facet.name)).length > 0;

  /**
   * Render the component.
   */
  return (
    <div className="recruit-directory">
      <div className="recruit-directory__search-and-filters">
        <div className="recruit-directory__search-and-filters-inner">
          {renderSearchFilters()}
          {
            moreFacetsAvailable && (
              <SimpleButton
                onClick={onAddFacet}
                type="tertiary"
                className="recruit-directory__add-row"
              >
                Add Row
              </SimpleButton>
            )
          }
          <div className="recruit-directory__filter-actions">
            <div className="recruit-directory__filter-actions-row">
              <Button
                to="/recruit?mode=advanced"
                type="tertiary"
                className="recruit-directory__clear-all"
              >
                New Search
              </Button>
              <SavedDirectorySearchCreateUpdateButton
                savedDirectorySearch={props.savedDirectorySearch}
                filters={filters.get()}
                facets={facets.get()}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="recruit-directory__search-options">
        <ContactAndLinksFilters
          onFiltersChanged={onFiltersChanged}
          filters={filters.clone().get()}
        />
        <SavedDirectorySearchesButton
          savedDirectorySearch={props.savedDirectorySearch}
        />
      </div>

      {filterState.status.pending && <LoadingSpinner position="below"/>}

      {
        filterState.status.failed &&
          <div className="recruit-directory__load-error">
            <div>An error occurred while searching the directory.</div>
            {props.baseParams.filter_id && <div>The url may no longer be valid.</div>}
          </div>
      }

      {
        filterState.status.succeeded &&
          <>
            {
              meta.total_count > 0 &&
                <div className="recruit-directory__actions-row">
                  <div className="recruit-directory__count-sort">
                    <div className="recruit-directory__card-count">
                      <span className="recruit-directory__card-count-num">
                        {`${meta.total_count}${meta.total_count === MAX_RESULTS ? '+' : ''}`}
                      </span>
                      {' '}
                      {pluralize(meta.total_count, 'earner', 'earners', { textOnly: true })}
                    </div>
                    <div className="recruit-directory__sort">
                      <SortSelector
                        onSort={sort}
                        selectedSortValue={currentSort}
                        sortValues={sortValues}
                      />
                    </div>
                  </div>
                </div>
            }
            {renderEarners()}

            <Pagination
              data={meta}
              baseUrl={history.location.pathname}
              search={history.location.search}
            />
          </>
      }
    </div>
  );
};

RecruitBase.propTypes = {
  baseParams: PropTypes.object.isRequired,
  savedDirectorySearch: PropTypes.object,
  trackingData: PropTypes.object.isRequired
};

/**
 * Private hook for directory tracking. Tracks on mount, and returns specialized tracking functions.
 *
 * @param {{object_id: String, object_type: String}} trackingData - tracking data.
 * @returns {{
 *   trackSearchTermChanged: function(term: String, searchType: String),
 *   trackSelectSearchResult: function(result: String)
 * }}
 */
const useDirectoryTracking = trackingData => {
  const trackingDataChanged = useShallowEquals(trackingData);

  React.useEffect(() => {
    if (trackingData.object_id) {
      tracking.track({ ...trackingData, type: 'directory.viewed' });
    }
  }, [trackingDataChanged]);

  /**
   * The current search term has changed.
   *
   * @param {String} term - The current search term.
   * @param {String} searchType - the search type.
   */
  const trackSearchTermChanged = React.useCallback((term, searchType) => {
    if (trackingData.object_id) {
      tracking.track({
        ...trackingData,
        type: 'directory.queried',
        search_type: searchType,
        search_term: term
      });
    }
  }, [trackingDataChanged]);

  /**
   * A search result has been selected.
   *
   * @param {{key: string, value: string}} result - Filter for the search result.
   */
  const trackSelectSearchResult = React.useCallback(result => {
    if (trackingData.object_id) {
      tracking.track({
        ...trackingData,
        type: 'directory.filtered',
        filter_type: result.key.replace(/_name/, ''),
        filter_term: result.value
      });
    }
  }, [trackingDataChanged]);

  return { trackSearchTermChanged, trackSelectSearchResult };
};


export const testing = { MAX_RESULTS };
