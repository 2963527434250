import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uuidPropType } from 'prop_types';
import { useEffectExceptOnMount } from 'utils/react_utils';
import { useAlerts } from 'alert';
import { savedDirectorySearchActionManager } from 'pages/public/organization/earner_directory/saved_directory_searches/actions';
import Dialog from 'controls/dialog';

import './remove_saved_directory_search_dialog.sass';

/**
 * Renders a dialog that requests to delete a saved directory search.
 *
 * @param props
 *  @param {uuidPropType} props.id - The id of the saved directory search to remove.
 *  @param {Function} props.shouldClose - Callback function called to signal that the dialog
 *  should be closed.
 *  @param {Boolean} [props.show] - Whether or not to show the dialog
 */
export const RemoveSavedDirectorySearchDialog = (props) => {
  const [savedDirectorySearchState] = savedDirectorySearchActionManager.get(
    'get', props.id
  ).useAction();
  const [deleteState, performDelete] =
    savedDirectorySearchActionManager.get('delete', props.id).useAction();
  const [savedDirectorySearch, setSavedDirectorySearch] = useState(
    savedDirectorySearchState.resources
  );
  const alerts = useAlerts();
  useEffect(() => {
    if (deleteState.status.succeeded) {
      alerts.add({ type: 'notice', text: 'Your search has been removed.' });
      props.shouldClose();
    }
    if (deleteState.status.failed) {
      alerts.add({ type: 'error', text: 'Unable to remove your search.' });
    }
  }, [deleteState.status]);
  useEffectExceptOnMount(() => {
    // accounts for the case if changes to the store occur while this dialog is displayed
    // https://github.com/YourAcclaim/acclaim-server/pull/3927#discussion_r696204625
    if (savedDirectorySearchState.resources) {
      setSavedDirectorySearch(savedDirectorySearchState.resources);
    }
  }, [savedDirectorySearchState.resources]);
  return (
    <Dialog
      title="Remove Search"
      hideCloseButton
      size="half"
      show={props.show}
      shouldClose={props.shouldClose}
    >
      <Dialog.Content>
        <div>
          <div>Are you sure you want to delete the following Saved Search?</div>
          <div className="c-remove-saved-directory-search-dialog__name">
            {savedDirectorySearch.name}
          </div>
        </div>
      </Dialog.Content>
      <Dialog.Footer>
        <Dialog.Action type="tertiary">
          Cancel
        </Dialog.Action>
        <Dialog.Action action={performDelete} loading={deleteState.status.pending} noClose>
          Remove
        </Dialog.Action>
      </Dialog.Footer>
    </Dialog>
  );
};

RemoveSavedDirectorySearchDialog.propTypes = {
  id: uuidPropType,
  shouldClose: PropTypes.func.isRequired,
  show: PropTypes.bool
};
