import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from 'pages/error_views';

export const CredlySwitch = (props) => (
  <Switch>
    {props.children}
    <Route render={() => <NotFound suppressContainer={props.suppressContainer} />} />
  </Switch>
);

CredlySwitch.propTypes = {
  children: PropTypes.node,
  suppressContainer: PropTypes.bool
};
