import { Button } from 'controls/button';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlerts } from 'alert';
import ConfirmationModal from 'controls/confirmation_modal';
import { workforceSubscriptionsActions } from 'pages/workforce/accounts/subscriptions/actions';
import { SubscriptionsRouteHelpers } from '../../../../route_helpers';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dateUtils from 'utils/date';
/**
 * Delete Subscription component. When clicked will display a modal asking
 * confirmation to delete the subscription.
 *
 * @param {Object} props
 *   @param {uuid} props.organizationId - The organization ID.
 *   @param {uuid} props.id - id of the subscription
 *   @param {String} props.startDate - date the subscription was created
 *   @param {String} props.endDate - date which the subscription will be deleted
 */

export const DeleteSubscription = (props) => {
  const history = useHistory();
  const routeHelpers = SubscriptionsRouteHelpers.forUrl(props.baseUrl);
  const intl = useIntl();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteSubscriptionState, deleteSubscription] = workforceSubscriptionsActions.useAction('delete', props.id);
  const alerts = useAlerts();

  const removeSubscription = () => {
    deleteSubscription({ organization_id: props.organizationId });
  };

  useEffect(() => {
    if (deleteSubscriptionState.status.failed) {
      setShowDeleteDialog(false);
      alerts.add({
        type: 'error',
        text: intl.formatMessage({
          id: 'workforce.account.subscription.delete.failed.text',
          defaultMessage: 'Failed to remove subscription.'
        })
      });
    }
    if (deleteSubscriptionState.status.succeeded) {
      setShowDeleteDialog(false);
      alerts.add({
        type: 'notice',
        text: intl.formatMessage({
          id: 'workforce.account.subscription.delete.succeeded.text',
          defaultMessage: 'Subscription successfully deleted.'
        })
      });
      history.push(routeHelpers.root.route());
    }
  }, [deleteSubscriptionState.status]);

  const buttonName = intl.formatMessage({
    id: 'workforce.account.subscription.delete.button',
    defaultMessage: 'Delete'
  });

  const modalTitle = intl.formatMessage({
    id: 'workforce.account.subscription.delete.title',
    defaultMessage: 'Delete Subscription'
  });

  const modalButtonName = intl.formatMessage({
    id: 'workforce.account.subscription.delete.action.delete_subscription',
    defaultMessage: 'Remove Subscription'
  });

  const confirmationModalQuestion = intl.formatMessage({
    id: 'workforce.account.subscription.delete.question',
    defaultMessage: 'Are you sure you want to remove this subscription?'
  });

  const confirmationModalDescription = intl.formatMessage({
    id: 'workforce.account.subscription.delete.description',
    defaultMessage: 'Subscription period beginning {startDate} and ending {endDate}.'
  }, {
    startDate: dateUtils.formatMonthShortDayYear(props.startDate),
    endDate: dateUtils.formatMonthShortDayYear(props.endDate)
  });

  return (
    <div className="workforce-subscription-delete">
      <Button onClick={() => setShowDeleteDialog(true)}>
        {buttonName}
      </Button>
      <ConfirmationModal
        title={modalTitle}
        question={confirmationModalQuestion}
        buttonAction={removeSubscription}
        buttonActionDescription={modalButtonName}
        description={confirmationModalDescription}
        showModal={showDeleteDialog}
        setShowModal={setShowDeleteDialog}
        isLoading={deleteSubscriptionState.status.pending}
      />
    </div>
  );
};

DeleteSubscription.propTypes = {
  organizationId: PropTypes.string,
  id: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  baseUrl: PropTypes.string
};

