export const formatTooltip = (formatMessage) => (data) => function() {
  const _data = this.points[0].series.chart.series[0].options.data;

  const futureProofSkills = data.future_proof
    ? `<div class="workforce__skill-gained-chart-tooltip__futureproof">` +
    `${formatMessage({
      id: "workforce.skills_explorer.skills_gained_chart.future_proof",
      defaultMessage: "Future proof skill"
    })}</div>`
    : '';

  const tooltipStrong =
    `<strong class="workforce__skill-gained-chart-tooltip__strong">` +
    `${data.skill_name}</strong><br/>`;

  const pastBadgeText =
    `<div class="workforce__skill-gained-chart-tooltip__past-badge-acquisition-text">` +
    `${formatMessage({
      id: "workforce.skills_explorer.skills_gained_chart.tooltip_prev",
      defaultMessage: "Previous count:"
    })}</div>`;

  const pastBadgeValue =
    `<div class="workforce__skill-gained-chart-tooltip__past-badge-acquisition-value">`;
  const pastBadgeAcquisition =
    `<div class="workforce__skill-gained-chart-tooltip__past-badge-acquisition">` +
    `<div class="workforce__skill-gained-chart-tooltip__past-badge-acquisition-circle"></div>` +
    `${pastBadgeText}` +
    `${pastBadgeValue}${_data[0].x}</div>` +
    `</div>`;

  const currentAcquisitionText =
    `<div class="workforce__skill-gained-chart-tooltip__current-acquisition-text">` +
    `${formatMessage({
      id: "workforce.skills_explorer.skills_gained_chart.tooltip_current",
      defaultMessage: "Current count:"
    })}</div>`;

  const currentAcquisitionValue =
    `<div class="workforce__skill-gained-chart-tooltip__current-acquisition-value">`;
  const currentAcquisition =
    `<div class="workforce__skill-gained-chart-tooltip__current-acquisition">` +
    `<div class="workforce__skill-gained-chart-tooltip__current-acquisition-circle"></div>` +
    `${currentAcquisitionText}` +
    `${currentAcquisitionValue}${_data[1].x}</div>` +
    `</div>`;

  return `
    <div class="workforce__skill-gained-chart-tooltip">
      ${tooltipStrong}
      ${futureProofSkills}
      ${pastBadgeAcquisition}
      ${currentAcquisition}
    </div>
  `;
};



export const sortData = (data, sortBy) => {
  let compareFunc;

  if (sortBy === 'smallest-gain') {
    compareFunc = (a, b) => {
      const diffA = a.current - a.prev;
      const diffB = b.current - b.prev;

      if (diffA === diffB) {
        // If the gain is the same, sort alphabetically by skill_name
        return a.skill_name.localeCompare(b.skill_name);
      } else {
        return diffA - diffB;
      }
    };
  } else if (sortBy === 'largest-gain') {
    compareFunc = (a, b) => {
      const diffA = a.current - a.prev;
      const diffB = b.current - b.prev;

      if (diffA === diffB) {
        // If the gain is the same, sort alphabetically by skill_name
        return a.skill_name.localeCompare(b.skill_name);
      } else {
        return diffB - diffA;
      }
    };
  }

  return [...data].sort(compareFunc);
};

export const getMaxAxis = (data) => {
  let max = 0;
  data.forEach((item) => {
    max = Math.max(max, item.prev, item.current);
  });
  let interval = 10;
  if (max > 100) {
    interval = 25;
  }
  if (max > 500) {
    interval = 50;
  }
  if (max > 1000) {
    interval = 100;
  }

  return {
    max: Math.ceil(max / interval) * interval,
    tickInterval: interval
  };
};

/*
TEMPORARY: this should be removed when real data from the API is being consumed
*/
export const randomizeData = (data) => {
  const maxes = [100, 400, 700, 1100];
  const max = maxes[Math.floor(Math.random() * maxes.length)];

  return data.map((item) => {
    const before = Math.floor(Math.random() * max);
    const after = Math.floor(Math.random() * (max - before)) + before;

    return {
      ...item,
      prev: before,
      current: after
    };
  });
};
