import { Action } from 'utils/action';
import { profileSelfId } from 'profile/selectors';
import { actionTypes } from 'redux-resource';

export const profileCommonParams = {
  resourceType: 'profile',
  url: '/api/v1/users/self',
  id: profileSelfId
};

export const getProfileAction = new Action({
  ...profileCommonParams,
  requestKey: 'getProfile',
  effect: 'read',
  method: 'GET'
});

export const updateUserTosAction = new Action({
  resourceType: 'profile',
  url: '/api/v1/users/self/accept_terms',
  id: 'acceptTerms',
  requestKey: 'acceptTerms',
  effect: 'read',
  method: 'POST'
});

// If this gets called on a page that displays public users, it may cause issues
export const invalidatePublicUsers = (dispatch, _action, res) => {
  let vanitySlug = res.resources[0]['vanity_slug'];
  dispatch({
    type: actionTypes.DELETE_RESOURCES,
    resources: {
      publicUsers: [vanitySlug]
    }
  });
};

// Useful when two elements on the same page update the profile.
export const makeUpdateProfileAction = key => new Action({
  ...profileCommonParams,
  requestKey: 'updateProfile_' + key,
  effect: 'update',
  method: 'PUT',
  onSuccess: invalidatePublicUsers
});

export const updateProfileAction = makeUpdateProfileAction('');
