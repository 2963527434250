import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { QueryString } from 'utils/query_string';
import { Tag, TagIconButton, TruncatedTagList } from 'controls/tags';
import { RoleButton } from 'aria/role_button';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

import './filters.sass';

/**
 * Filters bar, to convert QueryString-style filters into a <TagList>.
 *
 * @property {Object<String, String>} filterNames - A map of filter keys to names. Keys outside this
 *   list will not be rendered.
 * @property {QueryString} filters - The current filters.
 * @property {function(QueryString)} onChange - Called whenever filters change. The parameter
 *   is a new object (not the same as the `filters` property) which contains all filters after the
 *   change.
 * @property {Boolean} [hideType] - if true, suppress the display of the filter type in the tag; for
 *   example, if other context makes this apparent
 * @property {Boolean} [hideRemoveAll] - Don't include a "remove all filters" link.
 * @property {String} [className] - A CSS class name to add to the root node.
 * @property {("default"|"standard")} [ui="default"] - the Tag UI to use
 */
export const Filters = ({
  filterNames,
  filters,
  onChange,
  hideType,
  hideRemoveAll,
  className,
  ui,
  includedFacets,
  align
}) => {
  /**
   * Remove one filter from the list.
   *
   * @param {String} param - The parameter name.
   * @param {String} value - The value to remove.
   */
  const removeFilter = (param, value) => {
    const q = filters.clone();
    q.dig(param).removeFromArray(value);
    onChange(q);
  };

  /**
   * Remove all filters.
   *
   * @param {Event} e
   */
  const removeAllFilters = e => {
    e.preventDefault();
    onChange(new QueryString());
  };

  /**
   * Array.sort() lambda to sort by object.key, then object.value.
   *
   * @param {{key: String, value: string}} a
   * @param {{key: String, value: string}} b
   */
  const keyValueSort = (a, b) => a.key + a.value < b.key + b.value ? -1 : 1;

  // Flatten the filters.
  const allFlat = filters.mapFlat((key, val) => ({
    param: key,
    key: filterNames[key],
    value: val
  })).filter(f => f.key);

  const flat = [];
  allFlat.forEach(item => {
    if (includedFacets === undefined || item.param === includedFacets) {
      flat.push(item);
    }
  });

  if (flat.length > 0) {
    flat.sort(keyValueSort);

    return (
      <div className={makeClassName('cr-filters', className)}>
        <TruncatedTagList
          maxRows={1}
          maxRowsMobile={0}
          ellipsesActive
          fullWidthMobile
          align={align}
          ui={ui}
        >
          {flat.map(f =>
            <Tag
              highlighted
              ui={ui}
              key={f.key + f.value}
              icon={
                <TagIconButton
                  aria-label="Remove"
                  icon={faTimes}
                  onClick={() => removeFilter(f.param, f.value)}
                />
              }
            >
              {!hideType && <span className="cr-filters__type">{f.key}: </span>}
              <span
                className={makeClassName(
                  'cr-filters__name',
                  ui === 'default' && 'cr-filters__name--default-ui'
                )}
              >
                {f.value}
              </span>
            </Tag>
          )}
        </TruncatedTagList>
        {
          !hideRemoveAll && (
            <RoleButton
              tagName="a"
              href="#"
              onClick={removeAllFilters}
              className="cr-filters__clear"
            >
              Remove all filters
            </RoleButton>
          )
        }
      </div>
    );
  }

  return null;
};

Filters.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  className: PropTypes.string,
  filterNames: PropTypes.object.isRequired,
  filters: PropTypes.instanceOf(QueryString).isRequired,
  includedFacets: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  hideRemoveAll: PropTypes.bool,
  hideType: PropTypes.bool,
  ui: PropTypes.oneOf(['default', 'standard'])
};

Filters.defaultProps = {
  align: 'left',
  ui: 'default'
};
