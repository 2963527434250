import React from 'react';
import PropTypes from 'prop-types';
import CredlyRoute from 'controls/credly_route';
import { CredlySwitch } from 'controls/credly_switch';
import { EmployeeProfile } from 'pages/workforce/employees/employees/show/profile/employee_profile';
import { EditEmployee } from 'pages/workforce/employees/employees/show/edit_employee';
import { EmployeeRouteHelpers } from 'pages/workforce/route_helpers';
import { EmployeeProfileEarnedBadgesTable } from './profile/employee_profile_earned_badges_table';
import { EmployeeProfileSkillsTable } from './profile/employee_profile_skills_table';
import { useIntl } from 'react-intl';

/**
 * Routes for showing an employee
 *
 * @param {Object} props
 *   @param {String} props.baseUrl - Base Url of showing an employee
 *   @param {object} props.organization - The organization object representing the employer.
 *   @param {object} props.employee - Resource object representing one employee
 */
export const Routes = (props) => {
  const intl = useIntl();
  const routeHelpers = EmployeeRouteHelpers.forUrl(
    props.baseUrl
  );

  return (
    <CredlySwitch>
      <CredlyRoute
        path={routeHelpers.editEmployee.route()}
        title={intl.formatMessage({
          id: 'workforce.employees.show.routes.edit',
          defaultMessage: 'Edit'
        })}
        render={(routeProps) => (
          <EditEmployee
            baseUrl={routeProps.match.url}
            organization={props.organization}
            employee={props.employee}
            history={routeProps.history}
          />
        )}
      />
      <CredlyRoute
        path={routeHelpers.showEmployeeBadges.route()}
        exact
        render={(routeProps) =>
          <EmployeeProfileEarnedBadgesTable
            baseUrl={routeProps.match.url}
            employee_id={props.employee.id}
            organization_id={props.organization.id}
            location={routeProps.location}
          />
        }
        title={intl.formatMessage({
          id: 'workforce.employees.show.routes.employee_badges',
          defaultMessage: 'Employee Badges'
        })}
      />
      <CredlyRoute
        path={routeHelpers.showEmployee.route()}
        title={intl.formatMessage({
          id: 'workforce.employees.show.routes.employee_profile',
          defaultMessage: 'Employee Profile'
        })}
        exact
        render={(routeProps) => (
          <EmployeeProfile
            employee={props.employee}
            organization={props.organization}
            location={routeProps.location}
          />
        )}
      />
      <CredlyRoute
        path={routeHelpers.showEmployeeSkills.route()}
        exact
        render={(routeProps) =>
          <EmployeeProfileSkillsTable
            baseUrl={routeProps.match.url}
            employee_id={props.employee.id}
            organization_id={props.organization.id}
            location={routeProps.location}
          />
        }
        title={intl.formatMessage({
          id: 'workforce.employees.show.routes.employee_skills',
          defaultMessage: 'Employee Skills'
        })}
      />
    </CredlySwitch>
  );
};

Routes.propTypes = {
  baseUrl: PropTypes.string,
  employee: PropTypes.object,
  organization: PropTypes.object
};
