import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as MenuButton from 'react-aria-menubutton';
import { makeClassName, uniqueId } from 'utils';
import { useDebouncedState } from 'utils/react_utils';
import { isIE } from 'utils/browser';
import element from 'utils/element';
import { LoadingSpinner } from 'controls/loading_spinner';
import { CheckboxWidget } from 'controls/checkbox_widget';

import './ellipsis_menu.sass';

/**
 * Create content that shows up when clicking on an ellipses icon.
 * <ul> tags will be formatted automatically.
 *
 * @param {Object} props
 *   @param {String} props.className
 *   @param {function(string)} props.onSelect - Called with the value of the selected item. If the
 *     handler returns false, the menu will remain open after the item is clicked, any other return
 *     value (including undefined) will cause the menu to close upon click.
 *   @param {String} props.ariaLabel - The aria-label property for the menu button
 * @returns {*}
 * @constructor
 */
class EllipsisMenu extends Component {
  constructor(props) {
    super(props);
    this.m_elt = React.createRef();
    this.state = {
      menuPosition: {},
      isOpen: false
    };
    this.id = `ellipsis_menu_${uniqueId()}`;
  }

  /**
   * The component updated. If the menu was just opened, reposition it to avoid overflow:hidden
   * regions.
   *
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isOpen && this.state.isOpen) {
      // Position the calendar so that it's fully visible.
      const menu = this.m_elt.current.querySelector('.ellipsis-menu__items');
      const cropper = element.closestCroppingParent(menu);
      const style = element.moveWithin(menu, cropper);
      this.setState({ menuPosition: style });
    }
  }

  /**
   * Handler to call when an item is selected from the menu. Takes the value of the selected item
   * and a reference to the React event. Closes the menu unless the props.onSelect handler returns
   * false.
   *
   * @param {string} value - the value of the selected menu item
   * @param {React.SyntheticEvent} event - the event that triggered the selection; used to check
   *   whether the item should be disabled, and if so, ignore the selection
   */
  handleSelect = (value, event) => {
    if (event.currentTarget.dataset.disabled === 'true') {
      // element is disabled; don't trigger selection and don't close menu
      return;
    }
    const result = this.props.onSelect(value);
    if (result !== false) {
      MenuButton.closeMenu(this.id);
    }
  };

  /**
   * The menu was opened or closed. Since this happens before render, side-effects have to wait
   * until componentDidUpdate().
   *
   * @param {{isOpen: boolean}} menuProps
   */
  onToggle = menuProps => this.setState({ isOpen: menuProps.isOpen });

  /**
   * Render the component.
   *
   * @returns {React.element}
   */
  render() {
    const props = this.props;

    // IE 11 will continue the keyframe animation endlessly when the busy class is added and
    // removed, or even animate multiple times simultaneously. I was unable to find any CSS fix
    // for this, but destroying and recreating the component (by changing a top-level property)
    // fixes it.
    const key = isIE() && props.busy ? 'emk-ietweak' : 'emk-normal';

    return <div className={props.containerClassName} ref={this.m_elt}>
      <MenuButton.Wrapper
        key={key}
        id={this.id}
        onSelection={this.handleSelect}
        onMenuToggle={this.onToggle}
        closeOnSelection={false}
        className={makeClassName([
          'ellipsis-menu',
          props.appearance === 'enclosed' && 'ellipsis-menu--enclosed',
          props.busy && 'ellipsis-menu--busy',
          props.className
        ])}
      >
        <MenuButton.Button
          className="ellipsis-menu__ellipsis"
          aria-label={props.ariaLabel || 'Open menu'}
          disabled={props.busy}
        >
          <div className="ellipsis-menu__dot"/>
          <div className="ellipsis-menu__dot"/>
          <div className="ellipsis-menu__dot"/>
        </MenuButton.Button>
        <MenuButton.Menu
          className="ellipsis-menu__items"
          tag="ul"
          style={this.state.menuPosition}
          ref={this.m_menu}
        >
          {props.children}
        </MenuButton.Menu>
      </MenuButton.Wrapper>
    </div>;
  }
}

/**
 * Menu item.
 *
 * @param {Object} props
 *   @param {String} props.className
 *   @param {String} props.value - The value which is passed to EllipsisMenu.props.onSelect()
 *   @param {Boolean} props.disabled - used to disable Menu Item when true.
 * @returns {*}
 * @constructor
 */
EllipsisMenu.Item = props =>
  <MenuButton.MenuItem
    className={makeClassName(
      ["ellipsis-menu__item", props.className],
      props.disabled && "ellipsis-menu__item--disabled"
    )}
    tag="li"
    value={props.value}
    aria-disabled={props.disabled}
    // duplicating disabled state because test library doesn't recognize ariaDisabled
    data-disabled={props.disabled}
  >
    {props.children}
  </MenuButton.MenuItem>;

/**
 * A toggleable menu item, with state represented by a checkbox.
 *
 * @param {Object} props
 *   @param {String} props.className
 *   @param {String} props.value - The value which is passed to EllipsisMenu.props.onSelect()
 *   @param {boolean} props.checked - Whether the item is currently checked; note that it is the
 *     responsibility of the consumer to ensure that this updates when onSelect is invoked.
 *   @param {boolean|{message:(String|JSX.Element)}} props.disabled - if true, this option is shown
 *     as disabled; can also be specified as an object with a message to render explaining why the
 *     option is disabled
 *   @param {boolean} props.loading - if true, displays a LoadingSpinner, instead of the checkbox
 * @returns {*}
 * @constructor
 */
EllipsisMenu.CheckboxItem = (props) => {
  const disabled = props.disabled || props.loading ? true : undefined;

  // debounce display of loading state, so that the loading spinner never appears for such a short
  // period of time that it looks glitchy, and won't appear at all if the request completes quickly
  /* eslint-disable react-hooks/rules-of-hooks */
  const [loading, setLoading] = useDebouncedState(props.loading, 250);
  useEffect(() => { setLoading(props.loading); }, [props.loading]);
  /* eslint-enable react-hooks/rules-of-hooks */

  return (
    <MenuButton.MenuItem
      className={makeClassName(
        'ellipsis-menu__item',
        props.className
      )}
      tag="li"
      value={props.value}
      aria-checked={props.checked}
      role="menuitemcheckbox"
      aria-disabled={disabled}
      // duplicating disabled state because test library doesn't recognize ariaDisabled
      data-disabled={disabled}
    >
      <div
        className={makeClassName(
          'ellipsis-menu__checkbox-item-option',
          props.disabled && 'ellipsis-menu__checkbox-item-option--disabled'
        )}
      >
        <div className="ellipsis-menu__checkbox-item-checkbox-container">
          {
            loading ? <LoadingSpinner size="fill"/>
              : <CheckboxWidget checked={props.checked}/>
          }
        </div>
        {props.children}
      </div>
      {
        props.disabled && props.disabled.message && (
          <span className="ellipsis-menu__checkbox-item-option-disabled-message">
            {props.disabled.message}
          </span>
        )
      }
    </MenuButton.MenuItem>
  );
};


EllipsisMenu.propTypes = {
  appearance: PropTypes.oneOf(['enclosed', 'open']),
  busy: PropTypes.bool,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  ariaLabel: PropTypes.string
};

EllipsisMenu.defaultProps = {
  appearance: 'open',
  busy: false
};

EllipsisMenu.Item.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool
};

EllipsisMenu.CheckboxItem.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({ message: PropTypes.node })]),
  loading: PropTypes.bool,
  value: PropTypes.string
};

export { EllipsisMenu };
