import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { ajax } from "utils";
import { ResourceNotFoundError } from "utils/resource_not_found_error";
import { LoadingSpinner } from 'controls/loading_spinner';
import { LearningRouteHelpers } from 'pages/workforce/route_helpers';
import { NewLearningPlan } from 'pages/workforce/learning/pages/new';

/**
 *
 * @param {object} props
 *   @param {string} props.baseUrl - base URL of the Workforce Leaning link.
 *   @param {object} props.organization - current organization context.
 *   @param {object} props.templateId - the context badgeTemplateId.
 *
 */
export const NewLearningPlanDirector = ({ organization, baseUrl, templateId }) => {
  const routeHelpers = LearningRouteHelpers.forUrl(baseUrl);
  const [requestState, setRequestState] = useState({ status: 'pending', body: null });
  const handleSuccess = (response) => {
    setRequestState({ status: 'succeeded', body: response.body });
  };
  const handleFailure = (response) => {
    setRequestState({ status: 'failed', body: response.body });
  };
  useEffect(() => {
    ajax({
      method: 'GET',
      uri: `/api/v1/organizations/${organization.id}/workforce/learning_plan_existence_check/${templateId}`,
      headers: { Accept: 'application/json' }
    }).then(handleSuccess).catch(handleFailure);
  }, []);

  useEffect(() => {
    if (requestState.status === 'failed') {
      throw new ResourceNotFoundError('Request failed');
    }
  }, [requestState]);

  if (requestState.status === 'pending') {
    return <LoadingSpinner/>;
  } else if (requestState.status === 'succeeded') {
    if (requestState.body.data.learning_plan_id) {
      const redirectPath =
        routeHelpers.edit.parameterize(
          { learningPlanId: requestState.body.data.learning_plan_id }
        );
      return <Redirect to={redirectPath}/>;
    } else {
      return (
        <NewLearningPlan
          baseUrl={baseUrl}
          organization={organization}
          templateId={templateId}
        />
      );
    }
  } else {
    return <LoadingSpinner/>;
  };
};

NewLearningPlanDirector.propTypes = {
  organization: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired
};
