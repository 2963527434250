import React from 'react';
import PropTypes from 'prop-types';
import { CredlySwitch } from 'controls/credly_switch';
import CredlyRoute from 'controls/credly_route';
import {
  CredlyRecruit,
  CredlyTalentMatch,
  CredlyTalentMatchMatchedCandidate,
  JobRequisition,
  JobRequisitionForm
} from 'pages/credly_recruit';
import { SavedDirectorySearchRouteHelpers } from 'pages/public/organization/earner_directory/saved_directory_searches/utils';
import { SavedDirectorySearchResourceLoader } from 'pages/public/organization/earner_directory/saved_directory_searches/saved_directory_search_resource_loader';

const routeHelpers = SavedDirectorySearchRouteHelpers;

export const Routes = (_props) => {
  return (
    <CredlySwitch>
      <CredlyRoute
        title="Credly Recruit"
        path="/recruit"
        exact
        render={(_routeProps) => (
          <CredlyRecruit/>
        )}
      />
      <CredlyRoute
        title="Credly Recruit"
        path={routeHelpers.showSavedDirectorySearch.route()}
        render={(routeProps) =>
          <SavedDirectorySearchResourceLoader
            id={routeProps.match.params.id}
            render={(savedDirectorySearchState) => (
              <CredlyRecruit
                initialMode="advanced"
                savedDirectorySearch={savedDirectorySearchState.resources}
              />
            )}
          />
        }
      />
    </CredlySwitch>
  );
};

/**
 * TalentMatch routes.
 *
 * @param {Object} props
 * @returns {*}
 */
export const TalentMatchRoutes = (props) => {
  const talentMatchTitle = "Credly Talent Match";
  const jobDetailsTitle = "Job Details Page";
  const jobCandidateTitle = "Job Candidate Match";

  return (
    <CredlySwitch>
      <CredlyRoute
        title={talentMatchTitle}
        path={props.basePath}
        exact
        render={(_routeProps) => (
          <CredlyTalentMatch/>
        )}
      />
      <CredlyRoute
        title={jobDetailsTitle}
        path={`${props.basePath}/job_requisition`}
        exact
        render={(_routeProps) => (
          <JobRequisitionForm />
        )}
      />
      <CredlyRoute
        title={jobDetailsTitle}
        path={`${props.basePath}/job_requisition/:job_requisition_id/edit`}
        exact
        render={(routeProps) => (
          <JobRequisitionForm
            jobRequisitionId={routeProps.match.params.job_requisition_id}
          />
        )}
      />
      <CredlyRoute
        title={talentMatchTitle}
        path={`${props.basePath}/jobs/:job_requisition_id/matches`}
        render={(routeProps) => (
          <JobRequisition
            jobRequisitionId={routeProps.match.params.job_requisition_id}
            tab="matches"
          />
        )}
      />
      <CredlyRoute
        title={talentMatchTitle}
        path={`${props.basePath}/jobs/:job_requisition_id/details`}
        render={(routeProps) => (
          <JobRequisition
            jobRequisitionId={routeProps.match.params.job_requisition_id}
            tab="details"
          />
        )}
      />
      <CredlyRoute
        title={jobCandidateTitle}
        path={`${props.basePath}/jobs/:job_requisition_id/match/:user_id`}
        render={(routeProps) => (
          <CredlyTalentMatchMatchedCandidate
            jobRequisitionId={routeProps.match.params.job_requisition_id}
            userId={routeProps.match.params.user_id}
          />
        )}
      />
    </CredlySwitch>
  );
};

TalentMatchRoutes.propTypes = {
  basePath: PropTypes.string.isRequired
};
