import { actionTypes } from 'redux-resource';
import * as objUtils from 'utils/object';

/**
 * Produce an action creator that will update the indicated resource slice with data for an
 * individual resource.
 *
 * @param {string} resourceSlice - the name of the resource slice that will be updated
 * @param {object} [opts] - hash of options controlling how default action will be behave
 *   @param {bool} [opts.ignoreCreate=false] - whether to skip updates to Redux  store when
 *     action was create (i.e. require that the newly-created resource be loaded via separate API
 *     request)
 * @returns {Function}
 */
const makeDefaultUpdateModelAction = (resourceSlice, opts = {}) => {
  return (id, response, action, _url) => {
    return (dispatch) => {
      if (action === 'delete') {
        dispatch({
          type: actionTypes.DELETE_RESOURCES,
          resources: {
            [resourceSlice]: [id]
          }
        });
      } else if (action === 'update' || !opts.ignoreCreate) {
        dispatch({
          type: actionTypes.UPDATE_RESOURCES,
          resources: {
            [resourceSlice]: [objUtils.clone(response.data)]
          }
        });
      }
    };
  };
};

/**
 * Facilitates synchronization of model data between redux-resource and Backbone.  Rather than using
 * this class directly, consumers should typically use the modelSynchronizer instance exported by
 * this module.
 *
 * The two main points of interaction with this class are registerModelSynchronizer which adds a
 * new synchronization action to the registry used by the Backbone app to push updates to
 * redux-resource, and the synchronize function returned by bindSynchronize.
 */
export class ModelSynchronizer {
  /**
   * Constructs a new instance of ModelSynchronizer with an empty registry of synchronization
   * actions.
   */
  constructor() {
    this.modelRegistry = [];
  }

  /**
   * Returns an instance of this object's synchronize method bound to the provided Redux dispatch
   * function.
   *
   * @param {Function} dispatch - a dispatch function provided by a Redux store
   * @returns {Function}
   */
  bindSynchronize(dispatch) {
    return (url, id, response, modifyType) => {
      const action = this.synchronize(url, id, response, modifyType);
      if (action) {
        dispatch(action);
      }
    };
  }

  /**
   * Register a method of synchronizing a Backbone model with redux-resource, and a test (either
   * function or RegExp) that will be used to decide whether to apply it.
   *
   * @param {RegExp|Function} urlTest - a regular expression or a predicate function to be used to
   *   determine whether a given resource URL should be synchronized using this synchronization
   *   action
   * @param {string|Function} actionOrResourceSlice - the name of a resource slice for which to
   *   apply the default synchronization, or an action creator that will be used to synchronize the
   *   models
   * @param {object} [opts] - hash of options controlling how default action will be behave
   *   @param {bool} [opts.ignoreCreate=false] - if actionOrResourceSlice is string, controls generated
   *     synchronizer ignores "create" actions, and instead requires consumers to make a follow-up
   *     request to the resource "show" endpoint for data; useful in scenarios where the create action
   *     responds with incomplete information (such as missing metadata)
   */
  registerSynchronizedModel(urlTest, actionOrResourceSlice, opts = {}) {
    if (typeof actionOrResourceSlice === 'string') {
      actionOrResourceSlice = makeDefaultUpdateModelAction(actionOrResourceSlice, opts);
    }
    if (urlTest instanceof RegExp) {
      const regexp = urlTest;
      urlTest = (url) => regexp.test(url);
    }
    this.modelRegistry.push({ test: urlTest, updateAction: actionOrResourceSlice });
  }

  /**
   * Synchronize an update performed in Backbone with any equivalent, mapped redux-resource
   * resources that registered with the ModelSynchronizer.
   *
   * @param {string} url - the URL of the model that was modified
   * @param {string} id - the ID of the model that was modified
   * @param {object} response - the payload that the Backbone model received in response to the
   *   update performed
   * @param {string} action - the type of modification to be synchronized; either create, update, or delete
   */
  synchronize(url, id, response, action) {
    const synchronizers = this.modelRegistry.filter(synchronizer => synchronizer.test(url));
    return (dispatch) => {
      synchronizers.forEach(synchronizer => {
        dispatch(synchronizer.updateAction(id, response, action, url));
      });
    };
  }
}

/**
 * An instance of ModelSynchronizer to be used with the main store's redux-resource slices that
 * require synchronization with Backbone.
 *
 * @type {ModelSynchronizer}
 */
export const modelSynchronizer = new ModelSynchronizer();
