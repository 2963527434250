import React from "react";
import { useActionPagination } from 'utils/use_action_pagination';
import { useCarousel } from './use_carousel';

export const useActionCarousel = (action, actionParams, settings, prefetched) => {
  const [actionState] = !prefetched ? action.useOnMount(actionParams) : action.useAction();

  const [, loadNextPage, hasNextPage] = useActionPagination(action, actionParams);
  const resources = (actionState.resources?.[0]?.badges)
    ? actionState.resources[0].badges : actionState.resources;
  const slider = useCarousel(resources, settings);

  React.useEffect(() => {
    if (slider.currentSlide === slider.totalSlides && hasNextPage) {
      loadNextPage();
    }
  }, [slider.currentSlide]);

  return [slider];
};
