import parse from 'date-fns/parse';

const shortFormatRegex = /^([1-2]\d{3})-(0?[1-9]|1[0-2])-(0?[1-9]|[1-2]\d|3[0-1])$/;

/**
 * Memoization variable for localDateFormats function.
 *
 * @type {string[]}
 */
let localDateFormatsMemo;

/**
 * The locale for which localDateFormats will be generated.  Currently, there is no reason for the
 * live site not to use the default locale, but tests may want to override it.
 *
 * @type {string}
 */
let defaultLocale = navigator.language;

/**
 * Date utilities, encapsulated so they can later be localized.
 */
const dateUtils = {
  isDateObject: o => Object.prototype.toString.call(o) === '[object Date]',

  /**
   * Parse a string into a Date object, Apply the local timezone if it's missing
   *
   * @param {Date|String|int} string - a date, date string, or time in milliseconds
   * @returns {Date}
   */
  dateFromString: (string, options = {}) => {
    if (dateUtils.isDateObject(string)) {
      return string; // Nothing to do here
    }
    options.formats = options.formats || ['yyyy-M-d'];

    if (typeof string === 'string') {
      const now = new Date();
      for (const format of options.formats) {
        try {
          const parsed = parse(string, format, now);
          if (!isNaN(parsed.valueOf())) {
            return parsed;
          }
        } catch (_e) {
          // do nothing, just means that's not the right format
        }
      }
    }

    if (options.noDateFallback) {
      return new Date(NaN);
    } else {
      return new Date(string);
    }
  },

  /**
   * Ensure that the provided date is within a given range.  If it is not, it is adjusted to match
   * whichever end of the range is closest.
   *
   * @param {Date} date - the date to adjust within the provided bounds
   * @param {Date} minDate - the lower end of the range (can be null for no lower bound)
   * @param {Date} maxDate - the upper end of the range (can be null for no upper bound)
   * @returns {function(Date):Date}
   */
  boundedDate: (date, minDate, maxDate) => {
    if (date && maxDate && date > maxDate) {
      return maxDate;
    } else if (date && minDate && date < minDate) {
      return minDate;
    }
    return date;
  },

  /**
   * Format a date in the form, "April 2018."
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatMonthYear: date => {
    // Force into a date object, regardless of the original type.
    date = dateUtils.dateFromString(date);
    return MONTHS[date.getMonth()] + ' ' + date.getFullYear();
  },

  /**
   * Format a date as a string representing the full month name.
   *
   * @param {Date|string|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatMonth: date => {
    return MONTHS[dateUtils.dateFromString(date)];
  },

  /**
   * Format a date in the form, "1 Apr"
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatDayShortMonth: date => {
    date = dateUtils.dateFromString(date);
    return `${date.getDate()} ${MONTHS_SHORT[date.getMonth()]}`;
  },

  /**
   * Format a date in the form, "Apr"
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatShortMonth: date => {
    date = dateUtils.dateFromString(date);
    return `${MONTHS_SHORT[date.getMonth()]}`;
  },

  /**
   * Format a date in the form, "1 April 2018."
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatDayMonthYear: date => {
    date = dateUtils.dateFromString(date);
    return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
  },

  /**
   * Format a date in the form, "1:45 PM"
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatTimeTT: date => {
    date = dateUtils.dateFromString(date);
    // parse time into 12hr format
    let h = date.getHours();
    h = (h === 0 ? 12 : h);
    let tt = h > 12 ? 'PM' : 'AM';
    let time = (tt === 'PM' ? h - 12 : h) + `:${pad2(date.getMinutes())} ${tt}`;
    return time;
  },

  /**
   * Format a date in the form, "1 April 2018 at 1:45 PM"
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatDayMonthYearAtTimeTT: date => {
    date = dateUtils.dateFromString(date);
    let dmy = dateUtils.formatDayMonthYear(date);
    let time = dateUtils.formatTimeTT(date);
    return `${dmy} at ${time}`;
  },

  /**
   * Format a date in the form, "1 Apr 2018"
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatDateMonthShortYear: date => {
    date = dateUtils.dateFromString(date);
    return `${date.getDate()} ${MONTHS_SHORT[date.getMonth()]} ${date.getFullYear()}`;
  },

  /**
   * Format a date in the form, "Apr 2018"
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatShortMonthYear: date => {
    date = dateUtils.dateFromString(date);
    return `${MONTHS_SHORT[date.getMonth()]} ${date.getFullYear()}`;
  },

  /**
   * Format a date in the form, "Apr 1, 2018"
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatMonthShortDayYear: date => {
    date = dateUtils.dateFromString(date);
    if (!date.getTime()) {
      return '';
    }
    return `${MONTHS_SHORT[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  },

  /**
   * Format a date's time of dat in twelve hour format, "2:30 PM"
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */

  formatDateTimeTwelveHourFormat: date => {
    date = dateUtils.dateFromString(date);
    if (date.toString() !== 'Invalid Date') {
      return new Intl.DateTimeFormat([], { hour12: true, timeStyle: 'short' }).format(date);
    }
  },

  /**
   * Format a date in ISO-8601 standard form:
   * 2019-01-23T00:00:00.000Z
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatISO: date => dateUtils.dateFromString(date).toISOString(),

  /**
   * Return true if the string is a date of the form YYYY-MM-DD
   *
   * @param {String} s - A potential date.
   * @returns {boolean}
   */
  isShortFormat: s => shortFormatRegex.test(s),

  /**
   * Format a date in YYYY-MM-DD form
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatShort: date => {
    date = dateUtils.dateFromString(date);
    if (!date.getTime()) {
      return '';
    }

    const day = pad2(date.getDate());
    const month = pad2(date.getMonth() + 1);

    return `${date.getFullYear()}-${month}-${day}`;
  },

  /**
   * Format a date in YYYY-MM-DD H:M:S z form
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatShortWithTime: date => {
    date = dateUtils.dateFromString(date);
    if (!date.getTime()) {
      return '';
    }

    const year = date.getFullYear();
    const day = pad2(date.getDate());
    const month = pad2(date.getMonth() + 1);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const offset = dateUtils.getTzOffsetString(date);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${offset}`;
  },

  /**
   * Format a date in MMM 'YY to MMM 'YY form
   *
   * @param {Date|String|int} startDate - the start date, in any format compatible with the Date object.
   * @param {Date|String|int} endDate - the end date, in any format compatible with the Date object.
   * @returns {string}
   */
  formatMonthDuration: (startDate, endDate) => {
    const parseMonth = (date) => {
      return MONTHS_SHORT[date.getMonth()];
    };

    const parseYear = (date) => {
      return date.getFullYear().toString().slice(2);
    };

    const formatMonthYearStr = (month, year) => {
      return `${month} '${year}`;
    };

    startDate = dateUtils.dateFromString(startDate);
    endDate = dateUtils.dateFromString(endDate);

    return [
      formatMonthYearStr(parseMonth(startDate), parseYear(startDate)),
      formatMonthYearStr(parseMonth(endDate), parseYear(endDate))
    ].join(' to ');
  },

  /**
   * Gets a list of locale-specific format strings compatible with dateFromString so that dates can
   * be parsed from formats familiar to the user.
   *
   * Currently, only produces a single, all-numeric format, but could be extended to support other
   * formats.
   *
   * @returns {string[]}
   */
  localDateFormats: () => {
    if (!localDateFormatsMemo) {
      localDateFormatsMemo = [];
      let testDate;
      // if there's a problem using the Intl.DateTimeFormat API, skip the local date format
      try {
        testDate = Intl.DateTimeFormat(
          defaultLocale, { year: 'numeric', month: 'numeric', day: 'numeric' }
        ).format(new Date(2020, 9, 28));
      } catch (_e) {
        return [...localDateFormatsMemo];
      }
      const translations = { 2020: 'yyyy', 10: 'M', 28: 'd' };
      const parseStr = testDate.replace(/(\d+)/g, (datePart) => {
        if (translations[datePart]) {
          const result = translations[datePart];
          delete translations[datePart];
          return result;
        }
        return datePart;
      });
      if (Object.keys(translations).length === 0) {
        // IE 11 includes LRM marks in localized dates, strip them out; OTOH, if we've somehow
        // gotten here with an RLM mark (because the language uses 0-9 as digit representations, but
        // also wants them written right-to-left), then...sorry?
        localDateFormatsMemo = [parseStr.replace(/\u200e/g, '')];
      }
    }
    return [...localDateFormatsMemo];
  },

  /**
   * Returns the timezone offset of a given date.
   *
   * @param {Date|String|int} date - A date, in any format compatible with the Date object.
   * @returns {string}
   */
  getTzOffsetString: date => {
    date = dateUtils.dateFromString(date);
    return date.toTimeString().replace(/^.*GMT([-+\d]+) .*$/, '$1');
  },

  /**
   * Copy the time component of a Date object to another, or to a string of the form YYYY-MM-DD.
   *
   * @param {Date|String} dst - A Date object, or a string of the form YYYY-MM-DD.
   * @param {Date} src - Copy the time component from this.
   * @returns {Date}
   */
  copyTime: (dst, src) => {
    src = src || new Date(Date.now());

    if (typeof dst === 'string') {
      // If there's no timezone, the Date constructor assumes UTC, which is almost always wrong.
      // Since there's no way to override this behavior, we have to deconstruct the string.
      if (dateUtils.isShortFormat(dst)) {
        const d = new Date(src);
        const parts = dst.split(/\D+/).map(p => parseInt(p, 10));
        d.setFullYear(parts[0]);
        d.setMonth(parts[1] - 1);
        d.setDate(parts[2]);
        return d;
      }

      // Unknown format. Do our best.
      dst = new Date(dst);
    }

    const d = new Date(dst);
    if (src) {
      d.setHours(src.getHours());
      d.setMinutes(src.getMinutes());
      d.setSeconds(src.getSeconds());
    }
    return d;
  },

  /**
   * Format a Date object as "[number] [units] ago" or "[number] [units] from now"
   *
   * @param {Date|String|Number} date - The date to format
   * @returns {String} the formatted string
   */
  relativeTime: (date, intl) => {
    const diff = (Date.now() - new Date(date).getTime()) / 1000;
    const absDiff = Math.abs(diff);
    for (let x = 0; x < RELATIVE_TIME.length; x++) {
      if (absDiff <= RELATIVE_TIME[x][0]) {
        return RELATIVE_TIME[x][1](intl, absDiff, diff < 0, date);
      }
    }
    // After the maximum handled number, just show the date.
    return dateUtils.formatDateMonthShortYear(date);
  },

  /**
   * Get a Date representing midnight today in local time.
   *
   * @returns {Date}
   */
  today: () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  },

  /**
   * Get a Date representing midnight tomorrow in local time.
   *
   * @returns {Date}
   */
  tomorrow: () => {
    const result = dateUtils.today();
    result.setDate(result.getDate() + 1);
    return result;
  },

  /**
   * Get a Date representing a number of months ago
   *
   * @returns {Date}
   */
  monthsAgo: (numMonths) => {
    const now = dateUtils.today();
    let newMonth = now.getMonth() - numMonths;
    if (newMonth < 0) {
      newMonth += 12;
    }
    return new Date(now.getFullYear(), newMonth, now.getDate());
  }
};
export default dateUtils;

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const MONTHS_SHORT = MONTHS.map(m => m.substring(0, 3));

const pad2 = i => ('0' + i).slice(-2);

const RELATIVE_TIME = [
  // Up to 20 seconds
  [
    20,
    (intl, time, isFuture) => isFuture
      ? intl.formatMessage({ id: 'relative_time.few_seconds.from_now', defaultMessage: 'a few seconds from now' })
      : intl.formatMessage({ id: 'relative_time.few_seconds.ago', defaultMessage: 'a few seconds ago' })
  ],
  // Up to 59 seconds
  [
    50,
    (intl, time, isFuture) => isFuture
      ? intl.formatMessage({ id: 'relative_time.seconds.from_now', defaultMessage: '{time} seconds from now' }, { time: Math.floor(time) })
      : intl.formatMessage({ id: 'relative_time.seconds.ago', defaultMessage: '{time} seconds ago' }, { time: Math.floor(time) })
  ],
  // Up to 1.5 minutes
  [
    90,
    (intl, time, isFuture) => isFuture
      ? intl.formatMessage({ id: 'relative_time.a_minute.from_now', defaultMessage: 'a minute from now' })
      : intl.formatMessage({ id: 'relative_time.a_minute.ago', defaultMessage: 'a minute ago' })
  ],
  // Up to 55 minutes
  [
    55 * 60,
    (intl, time, isFuture) => isFuture
      ? intl.formatMessage({ id: 'relative_time.minutes.from_now', defaultMessage: '{time} minutes from now' }, { time: Math.max(2, Math.floor(time / 60)) })
      : intl.formatMessage({ id: 'relative_time.minutes.ago', defaultMessage: '{time} minutes ago' }, { time: Math.max(2, Math.floor(time / 60)) })
  ],
  // Up to 1.5 hours
  [
    90 * 60,
    (intl, time, isFuture) => isFuture
      ? intl.formatMessage({ id: 'relative_time.an_hour.from_now', defaultMessage: 'an hour from now' })
      : intl.formatMessage({ id: 'relative_time.an_hour.ago', defaultMessage: 'an hour ago' })
  ],
  // Up to 23 hours
  [
    23 * 3600,
    (intl, time, isFuture) => isFuture
      ? intl.formatMessage({ id: 'relative_time.hours.from_now', defaultMessage: '{time} hours from now' }, { time: Math.max(2, Math.floor(time / 3600)) })
      : intl.formatMessage({ id: 'relative_time.hours.ago', defaultMessage: '{time} hours ago' }, { time: Math.max(2, Math.floor(time / 3600)) })
  ],
  // Up to 1.5 days
  [
    36 * 3600,
    (intl, time, isFuture) => isFuture
      ? intl.formatMessage({ id: 'relative_time.a_day.from_now', defaultMessage: 'a day from now' })
      : intl.formatMessage({ id: 'relative_time.a_day.ago', defaultMessage: 'a day ago' })
  ],
  // Up to 20 days
  [
    20 * 24 * 3600,
    (intl, time, isFuture) => isFuture
      ? intl.formatMessage({ id: 'relative_time.days.from_now', defaultMessage: '{time} days from now' }, { time: Math.max(2, Math.floor(time / 3600 / 24)) })
      : intl.formatMessage({ id: 'relative_time.days.ago', defaultMessage: '{time} days ago' }, { time: Math.max(2, Math.floor(time / 3600 / 24)) })
  ]
];

/**
 * Helper functions for testing dateUtils methods.  Should not be used in live code.
 */
export const testing = {
  /**
   * Reset the memo for localDateFormats, so that the next call to that function will re-compute
   * the value.
   */
  resetLocalDateFormats: () => { localDateFormatsMemo = null; },

  /**
   * Temporarily changes the effective locale for dateUtils methods and executes the provided
   * function.  Restores the previous default locale on exit.
   *
   * @param {string} locale - the new effective locale for dateUtils methods that use one
   * @param {function} work - some work to perform while the new locale is in effect
   */
  withLocale: (locale, work) => {
    const restoreLocale = defaultLocale;
    try {
      defaultLocale = locale;
      testing.resetLocalDateFormats();
      work();
    } finally {
      defaultLocale = restoreLocale;
      testing.resetLocalDateFormats();
    }
  }
};
