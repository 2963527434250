import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Select } from 'form/select';

export const skillsGainedSortFilterOptions = (formatMessage) => {
  return [
    {
      displayValue: formatMessage({
        id: "workforce.skills_explorer.skills_gained_chart.largest_gain",
        defaultMessage: "Largest Gain"
      }),
      selectionValue: 'largest-gain'
    },
    {
      displayValue: formatMessage({
        id: "workforce.skills_explorer.skills_gained_chart.smallest_gain",
        defaultMessage: "Smallest Gain"
      }),
      selectionValue: 'smallest-gain'
    }
  ];
};

export const DataFilters = ({ setSortFilterState, sortBy }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="workforce__skills-gained-chart-filter-container">
      <Select
        className="c-field-group__input-group_type"
        name="Skill Type"
        selectedItem={sortBy}
        appearance="horizontal-label"
        label={formatMessage({
          id: "workforce.skills_explorer.skills_gained_chart.sort_by",
          defaultMessage: "Sort by:"
        })}
        aria-label={formatMessage({
          id: "workforce.skills_explorer.skills_gained_chart.sort_by",
          defaultMessage: "Sort by:"
        })}
        selectByDefault={sortBy}
        handleChange={(value, type) => {
          setSortFilterState(type);
        }}
        options={skillsGainedSortFilterOptions(formatMessage)}
      />
    </div>
  );
};


DataFilters.propTypes = {
  setSortFilterState: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired
};
