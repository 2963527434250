import React from 'react';
import PropTypes from 'prop-types';
import { useQueryString } from 'controls/with_query_string';
import { employeeBadgeTemplateActions } from 'pages/workforce/employees/employees_actions';
import {
  EmployeeFilters
} from 'pages/workforce/employees/employees/list/filters/employee_filters';
import { DirectoryBase } from 'pages/public/organization/earner_directory/shared/directory_base';
import { BadgeExport } from './badge_export';
import { EmployeeBadgeTemplateSearch } from './employee_badge_template_search';
import { EmployeeBadgeTemplatesTable } from './employee_badge_templates_table';
import { useExcludedSearchTypes } from '../../../faethm_occupation_mapping_hooks';

const SEARCH_TABS = [
  { name: 'name', label: 'Badge Template Name' },
  { name: 'issuer', label: 'Issuers' },
  { name: 'skill', label: 'Skills' },
  { name: 'department', label: 'Departments' },
  { name: 'location', label: 'Locations' },
  { name: 'occupation', label: 'Occupations' }
];

/**
 * A page displaying the employees badge templates table
 *
 * @param {Object} props
 *   @param {Object} props.organization - Viewing organization
 *   @param {Object} props.routeProps - Contains the properties of the current route
 */

export const EmployeesBadgeTemplates = props => {
  const queryString = useQueryString(props.routeProps.location);
  const excludedSearchTypes = useExcludedSearchTypes();
  const [filterState] = employeeBadgeTemplateActions.get('filter').useAction();
  const exportEnabled = !!filterState?.resources?.length;

  const employeeFilterTag = (
    <EmployeeFilters
      organization_id={props.organization.id}
      filters={queryString.get('filter') || {}}
      allowedOptions={['level', 'type_category']}
    />
  );

  return (
    <DirectoryBase
      alwaysRenderHeader
      baseParams={{ organization_id: props.organization.id }}
      className={['col', 'c-employees-badge-templates']}
      directorySearchTag={EmployeeBadgeTemplateSearch}
      excludeSearchTypes={excludedSearchTypes}
      exportRemoveFilter
      exportEnabled={exportEnabled}
      exportButton={exportEnabled ? BadgeExport : () => { }}
      createExportArgs={{ organization_id: props.organization.id }}
      filterAction={employeeBadgeTemplateActions.get('filter')}
      filterNames={{
        name: 'Badge Template Name',
        issuer: 'Issuer',
        skill: 'Skill',
        department: 'Department',
        location: 'Location',
        occupation: 'Occupation',
        type_category: 'Badge Type',
        level: 'Badge Level'
      }}
      filterTag={employeeFilterTag}
      sortSelectorEnabled={false}
      searchBarPlaceholderText="Search badges"
      resultsRender={(directoryResultsFilterState) => (
        <EmployeeBadgeTemplatesTable
          data={directoryResultsFilterState.resources}
          status={directoryResultsFilterState.status}
          routeProps={props.routeProps}
        />
      )}
      tabs={SEARCH_TABS}
      tabNamesToFilterKeyTransforms={{
        name: () => 'name',
        issuer: () => 'issuer',
        skill: () => 'skill',
        department: () => 'department',
        location: () => 'location',
        occupation: () => 'occupation'
      }}
      trackingData={{ object_id: props.organization.id, object_type: 'Organization', directory_type: 'workforce-employees-badge-templates' }}
      orFilters
    />
  );
};

EmployeesBadgeTemplates.propTypes = {
  organization: PropTypes.object,
  routeProps: PropTypes.object
};
