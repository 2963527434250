/* eslint-disable camelcase */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form } from 'form';
import { CheckboxWidget } from 'controls/checkbox_widget';
import { BlockClickPropagate } from 'controls/block_click_propagate';
import { OrgMgmtDataTable } from 'management/controls/org_mgmt_data_table';
import { LearningPlanActions } from 'pages/workforce/learning/actions';
import { InvitationStatusIndicator } from 'pages/workforce/components/invitation_status_indicator';
import { InfoModal } from 'pages/workforce/components/info_modal';
import { useSelectionManager } from './selection_manager';
import { useFaethmOccupationMappingEnabled } from 'pages/workforce/faethm_occupation_mapping_hooks';
import './direct_reports_table.sass';


const EMPLOYEE_STATES = ['accepted', 'created', 'pending', 'rejected'];
const INVALID_EMPLOYEE_STATE = 'rejected';
const TRANSLATION_KEY = 'workforce.employees.state.';

/**
 * Component for creating a table listing available direct reports for a learning plan.
 * Allows users to select or deselect employees for inclusion in the learning plan,
 * and provides status indicators and tooltips for context.
 *
 * @param {Object} propsForFieldGroup - prop for the direct_report_ids field group from LearningPlanForm
 * @param {Object} learningPlanState - the state of the learning plan
 * @returns {JSX.Element} A table of selectable direct reports
 */

export const DirectReportsTable = ({ propsForFieldGroup, learningPlanState }) => {
  const intl = useIntl();
  const invitationStatusHeader = intl.formatMessage({
    id: 'employee_list.filters.option_key_name.invitation_status',
    defaultMessage: 'Invitation Status'
  });

  const faethmOccupationEnabled = useFaethmOccupationMappingEnabled();

  const translatedEmployeeStates = useMemo(() => (
    EMPLOYEE_STATES.reduce((accumulator, state) => {
      accumulator[state] = intl.formatMessage({
        id: `${TRANSLATION_KEY}${state}`,
        defaultMessage: `${state.charAt(0).toUpperCase()}${state.slice(1)}`
      });
      return accumulator;
    }, {})
  ), [intl]);

  const { organization_id: organizationId } = learningPlanState;
  const [getDirectReportsState] = LearningPlanActions.useOnMount(
    learningPlanState.id ? 'availableDirectReports' : 'allDirectReports',
    null,
    {
      organization_id: organizationId,
      learning_plan_id: learningPlanState.id
    },
    [learningPlanState.id, organizationId]
  );
  const [directReports, setDirectReports] = useState(null);
  const [validDirectReports, setValidDirectReports] = useState(null);
  const getDirectReportsStatePending = getDirectReportsState.status.pending;
  const getDirectReportsStateSucceeded = getDirectReportsState.status.succeeded;

  useEffect(() => {
    if (getDirectReportsStateSucceeded) {
      const reports = Array(getDirectReportsState.resources)
        .flat()
        .filter(x => x);

      setDirectReports(reports);
      setValidDirectReports(reports.filter(
        direct_report => direct_report.employee_state !== INVALID_EMPLOYEE_STATE
      ));
    }
  }, [getDirectReportsStateSucceeded, getDirectReportsState.resources]);

  const { allSelected, onSelect } = useSelectionManager(validDirectReports, propsForFieldGroup);

  return (
    <OrgMgmtDataTable
      data={directReports}
      loader={{ loading: getDirectReportsStatePending }}
    >
      <OrgMgmtDataTable.Col
        width="col-lg-1"
        className="workforce-learning-recommendations-table__select"
        header={
          <div
            className="workforce-learning-recommendations-table__select-all"
            onClick={() => onSelect(validDirectReports, !allSelected)}
          >
            <CheckboxWidget
              name="select_all"
              checked={allSelected}
            />
          </div>
        }
        renderer={(directReport) => {
          return (
            <>
              {directReport.employee_state === 'rejected' ? (
                <InfoModal
                  className="employee-profile-skills-table__tooltip-wrapper"
                  title={invitationStatusHeader}
                  body={intl.formatMessage({
                    id: 'workforce.learning.create.rejected_employee.tooltip_body',
                    defaultMessage: 'At this time you cannot make learning ' +
                      'recommendations through our system for employees who ' +
                      'have elected not to share their badge information with your organization, ' +
                      'because we cannot track or share their progress toward or earning of badges.'
                  })}
                />
              ) : (
                <Form.CheckboxSingle
                  {...propsForFieldGroup}
                  name="direct_report_ids"
                  options={[{ selectionValue: directReport.id }]}
                  handleChange={() => onSelect([directReport])}
                />
              )}
            </>
          );
        }}
      />
      <OrgMgmtDataTable.Col
        width={`col-lg-${faethmOccupationEnabled ? '2' : '9'}`}
        className="workforce-learning-recommendations-table__employee"
        header={intl.formatMessage({
          id: 'workforce.employees.employees.employee_table.employee',
          defaultMessage: 'Employee'
        })}
        renderer={({ first_name, last_name }) => `${first_name} ${last_name}`}
      />
      {faethmOccupationEnabled && (
        <OrgMgmtDataTable.Col
          width="col-lg-7"
          className="workforce-learning-recommendations-table__occupation"
          header={intl.formatMessage({
            id: 'workforce.employees.employees.employee_table.occupation',
            defaultMessage: 'Occupation'
          })}
          renderer={(directReport) => directReport?.occupation?.foc_title}
        />
      )}
      <OrgMgmtDataTable.Col
        width="col-lg-2"
        className="workforce-learning-recommendations-table__invitation_status"
        header={
          <>
            {invitationStatusHeader}
            <BlockClickPropagate>
              <InfoModal
                className="employee-profile-skills-table__tooltip-wrapper"
                title={invitationStatusHeader}
                body={intl.formatMessage({
                  id: 'workforce.learning.create.invitation_status.tooltip_body',
                  defaultMessage: 'We track completion of badges for employees who ' +
                  'have been invited to connect with the organization. Employees with ' +
                  '“Rejected” invitation state have elected not to share their badge ' +
                  'information with your organization, so their badge completion ' +
                  'cannot be tracked at this time. Exceptions may include badges ' +
                  'issued by your organization.'
                })}
              />
            </BlockClickPropagate>
          </>
        }
        renderer={({ employee_state }) => (
          <InvitationStatusIndicator status={translatedEmployeeStates[employee_state]} />
        )}
      />
    </OrgMgmtDataTable>
  );
};

DirectReportsTable.propTypes = {
  propsForFieldGroup: PropTypes.object.isRequired,
  learningPlanState: PropTypes.object.isRequired
};
