import { useBrowserQueryString } from 'controls/with_query_string';

export const useRecruitDirectorySortSelector = (savedDirectorySearch) => {
  const queryString = useBrowserQueryString();
  const sortValues = allowedSortValues(savedDirectorySearch);
  const currentSort = () => {
    let defaultSortValue;
    if (savedDirectorySearch && savedDirectorySearch.displayable_matches) {
      defaultSortValue = 'new_results';
    } else {
      defaultSortValue = '-activity_date';
    }
    const qsSort = queryString.get('sort');
    if (!qsSort) {
      return defaultSortValue;
    } else if (sortValues.some(s => s.sortValue === qsSort)) {
      return qsSort;
    } else {
      return sortValues[0].sortValue;
    }
  };
  return { currentSort: currentSort(), sortValues };
};

const allowedSortValues = (savedDirectorySearch) => {
  let baseSortValues = [
    {
      displayValue: 'Recent Activity',
      sortValue: '-activity_date'
    },
    {
      displayValue: 'Name',
      sortValue: 'alphabetical'
    }
  ];
  if (savedDirectorySearch && savedDirectorySearch.displayable_matches) {
    baseSortValues = [{ displayValue: 'New Results', sortValue: 'new_results' }, ...baseSortValues];
  }
  return baseSortValues;
};
