import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { makeClassName } from 'utils';
import { useRaiseOnFailedRequest } from 'utils/react_utils';
import { useBrowserQueryString } from 'controls/with_query_string';
import { LoadingSpinner } from 'controls/loading_spinner';
import { savedDirectorySearchActionManager } from '../actions';
import { SavedDirectorySearchCard } from '../saved_directory_search_card';
import { RemoveSavedDirectorySearchDialog } from '../remove_saved_directory_search_dialog';
import { SavedDirectorySearchSortSelector } from '../saved_directory_search_sort_selector';
import { EmptyPlaceholder } from './empty_placeholder';

import './saved_directory_search_list.sass';

/**
 * Renders a list of saved searches. Shows a remove search dialog to remove them.
 * @param {Object} props
 * @param {String} [props.className] - Optional class to apply to container
 */
export const SavedDirectorySearchList = (props) => {
  const queryString = useBrowserQueryString();
  const sort = queryString.get('sort') || '-last_viewed_at';
  const [savedDirectorySearchList] = savedDirectorySearchActionManager.useOnMount(
    'list',
    null,
    { sort: sort },
    [sort]
  );
  useRaiseOnFailedRequest(savedDirectorySearchList.status);
  const history = useHistory();
  const handleSort = (sortValue) => {
    const newQueryString =
      queryString.clone().removeKey('page').set('sort', sortValue);
    history.replace({ search: newQueryString.toString() });
  };
  const [removeDialogState, setRemoveDialogState] = useState({
    show: false,
    id: null
  });
  const handleRemove = (id) => {
    setRemoveDialogState({ show: true, id: id });
  };
  const { resources: listResources, status: listStatus } = savedDirectorySearchList;
  useRaiseOnFailedRequest(listStatus);
  return (
    <div className={makeClassName("c-saved-directory-search-list", props.className)}>
      {listStatus.pending && listResources.length === 0 &&
        <LoadingSpinner delay={500}/>
      }
      {listResources.length > 0 &&
        <>
          {removeDialogState.show &&
            <RemoveSavedDirectorySearchDialog
              size="half"
              id={removeDialogState.id}
              show={removeDialogState.show}
              shouldClose={() => setRemoveDialogState({ show: false, id: null })}
            />
          }
          <div className="c-saved-directory-search-list__header">
            <div className="c-saved-directory-search-list__saved-text">Saved</div>
            <div className="c-saved-directory-search-list__sort-selector">
              <SavedDirectorySearchSortSelector
                onSort={handleSort}
                selectedSortValue={sort}
              />
            </div>
          </div>
          <div className="c-saved-directory-search-list__saved-search-card-container">
            {listResources.map((savedSearch) => {
              return (
                <SavedDirectorySearchCard
                  key={`${savedSearch.name}`}
                  data={savedSearch}
                  handleRemove={() => handleRemove(savedSearch.id)}
                />
              );
            })}
          </div>
        </>
      }
      {listStatus.succeeded &&
        listResources.length === 0 &&
          <div className="c-saved-directory-search-list__empty-img-container">
            <EmptyPlaceholder/>
          </div>
      }
    </div>
  );
};

SavedDirectorySearchList.propTypes = {
  className: PropTypes.string
};
