import React from 'react';
import PropTypes from 'prop-types';
import { BasedOn } from './based_on';
import { trendingAndEmergingSkillsAction } from './trending_and_emerging_skills_action';
import { FormattedMessage, useIntl } from 'react-intl';
import { SkillList } from 'pages/workforce/components/skill_list';
import { InfoModal } from 'pages/workforce/components/info_modal';

/**
 * Skills list of trending and emerging skills.
 *
 * @param {Object} props
 *   @param {object} props.employee - Resource object representing employee profile api response
 */
export const TrendingAndEmergingSkills = props => {
  const intl = useIntl();
  const similarParams = { organization_id: props.organizationId, id: props.employeeId };
  const [trendingSkillsState] = trendingAndEmergingSkillsAction.useOnMount(
    similarParams,
    [props.organizationId, props.employeeId]
  );

  if (!props.employee.occupation?.id ||
      (!trendingSkillsState.status.pending && !trendingSkillsState.resources?.length)) {
    return <></>;
  }

  const infoModalTitle = intl.formatMessage({
    id: 'workforce.trending_and_emerging_skills_industry.info_modal.title',
    defaultMessage: 'Trending & emerging skills'
  });
  const infoModalBody = intl.formatMessage({
    id: 'workforce.trending_and_emerging_skills_industry.info_modal.body',
    defaultMessage: 'Trending skills are well-known skills that are seeing significant growth in demand ' +
      'from employers. Emerging skills are new skills that are becoming more in demand and that may grow to ' +
      'become core skills for one or more occupations.'
  });

  const header = (
    <>
      <FormattedMessage
        id="workforce.employee_profile.trending_and_emerging_skills"
        defaultMessage="Recommended trending & emerging skills"
      />
      <InfoModal
        title={infoModalTitle}
        body={(
          <div className="cr-workforce-skill-list__info-modal-dialog">
            {infoModalBody}
          </div>
        )}
        dialogSize="auto"
      />
      <BasedOn employee={props.employee}/>
    </>
  );

  return (
    <div>
      <SkillList
        skills={trendingSkillsState.resources}
        header={header}
        loading={trendingSkillsState.status.pending}
      />
    </div>
  );
};

TrendingAndEmergingSkills.propTypes = {
  organizationId: PropTypes.string.isRequired,
  employeeId: PropTypes.string.isRequired,
  employee: PropTypes.object.isRequired
};

TrendingAndEmergingSkills.defaultProps = {
};
