import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { GenericSectionHeader } from 'controls/section_header';
import { BadgeTemplateLink } from 'pages/workforce/learning/shared/badge_template_link';

export const BadgeLinkSectionHeader = ({ badgeTemplate, translationData }) => {
  const intl = useIntl();

  return (
    <>
      <GenericSectionHeader
        heading={intl.formatMessage(translationData)}
        breadcrumbs={false}
      />
      <BadgeTemplateLink
        badgeTemplate={badgeTemplate}
      />
    </>
  );
};

BadgeLinkSectionHeader.propTypes = {
  badgeTemplate: PropTypes.object.isRequired,
  translationData: PropTypes.object.isRequired
};
