import React from 'react';
import PropTypes from 'prop-types';
import SortSelector from 'controls/sort_selector';

const SORT_VALUES = [
  { displayValue: 'Recent', sortValue: '-last_viewed_at' },
  { displayValue: 'Last Created', sortValue: '-created_at' },
  { displayValue: 'Name', sortValue: 'name' }
];

/**
 * Component displays the Sort Selector with properties specific to the Saved Directory Search List
 * @param {Object} props
 *  @param {SortSelectorOnSort} props.onSort - Callback for when a sort value is selected
 *  @param {string} props.selectedSortValue - The current sort value to be displayed
 */
export const SavedDirectorySearchSortSelector = (props) => {
  return (
    <SortSelector
      onSort={props.onSort}
      selectedSortValue={props.selectedSortValue}
      sortValues={SORT_VALUES}
    />
  );
};

const ValidSelectedSortValues = SORT_VALUES.reduce((prev, curr) => {
  prev.push(curr.sortValue);
  return prev;
}, []);

SavedDirectorySearchSortSelector.propTypes = {
  onSort: PropTypes.func.isRequired,
  selectedSortValue: PropTypes.oneOf(ValidSelectedSortValues).isRequired
};

