import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { Avatar } from 'controls/avatar';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrganizationSelectorItem } from './organization_selector_item';

import './organization_selector_row.sass';

/**
 * Renders a table row visual to enable a user to either navigate to a different organization in org management
 * or to view the organization's profile.
 *
 * @property {boolean} current - Whether or not this particular component is rendering information
 * about the current organization that user is on in org management.
 * @property {string} organization - The organization that the component is displaying information for
 */
export const OrganizationSelectorRow = (props) => {
  const org = props.organization;
  const selectorRowClassName =
    makeClassName(
      'c-organization-selector-row',
      props.current && 'c-organization-selector-row--current'
    );
  return (
    <OrganizationSelectorItem
      className={selectorRowClassName}
      current={props.current}
      organization={props.organization}
    >
      <div className="c-organization-selector-row__cell c-organization-selector-row__cell-org-photo">
        <Avatar
          src={org.photo_url}
          alt={org.name}
          emptyImage={
            <div className="c-organization-selector-row__cell c-organization-selector-row__cell-org-photo--blank">
              <FontAwesomeIcon icon={faBuilding}/>
            </div>
          }
          width={48}
        />
      </div>
      <div className="c-organization-selector-row__cell c-organization-selector-row__cell-org-name">{org.name}</div>
    </OrganizationSelectorItem>
  );
};

OrganizationSelectorRow.propTypes = {
  current: PropTypes.bool,
  organization: PropTypes.object.isRequired
};
