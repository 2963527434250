import React, { Component, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ClampLines } from "controls/clamp_lines";
import { Heading } from 'controls/heading';
import { SmartLink } from 'controls/smart_link';
import { SectionNavigation } from './section_navigation';
import { makeStackContext } from 'controls/stack_context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { TrackStatPropType } from 'utils/prop_types';

import './section_header.sass';

/**
 * Wrapper for items in the section header stack, which can be composed of either or both of a
 * section header sub-item and a breadcrumb sub-item.
 */
class SectionHeaderItem {
  constructor(sectionHeader, breadcrumb) {
    this.sectionHeader = sectionHeader;
    this.breadcrumb = breadcrumb;
  }
}

const stackContext = makeStackContext('SectionHeader');

export const SectionHeaderContext = stackContext.StackContext;
export const SectionHeaderContextProvider = stackContext.ContextProvider;
const WithSectionHeaderInner = stackContext.WithStackElement;

/**
 * Add a new item to the section header/breadcrumb stack.  Can have either or both of section header
 * and/or breadcrumb.
 *
 * @param {object} props
 *   @param {{action:string, label:string}} [props.breadcrumb] - an object describing a breadcrumb
 *     item that represents where in the navigational hierarchy this section is; will be displayed
 *     when there is at least one element with a sectionHeader higher in the stack
 *   @param {React.node} props.children - the content that logically falls under the section header
 *     or navigational breadcrumb described by this WithSectionHeader
 *   @param {function():React.element} [props.sectionHeader] - a function that generates a section
 *     header, which will be displayed when this element is the highest with a section header on the
 *     stack
 * @returns {React.element}
 * @constructor
 */
export const WithSectionHeader = (props) => {
  // memoize a breadcrumb link element, based on its attributes
  const breadcrumb = useMemo(() => {
    if (props.breadcrumb) {
      return () => <SmartLink action={props.breadcrumb.action}>{props.breadcrumb.label}</SmartLink>;
    }
  }, [props.breadcrumb && props.breadcrumb.action, props.breadcrumb && props.breadcrumb.label]);

  // memoize the overall section header element entry, based on section header and breadcrumb
  const item = useMemo(() => {
    return new SectionHeaderItem(props.sectionHeader, breadcrumb);
  }, [props.sectionHeader, breadcrumb]);
  return (<WithSectionHeaderInner element={item}>{props.children}</WithSectionHeaderInner>);
};

WithSectionHeader.propTypes = {
  breadcrumb: PropTypes.shape({
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  children: PropTypes.node,
  sectionHeader: PropTypes.func
};

/**
 * Find and return index of the last section header on the stack.  Returns -1 if no such element
 * exists.
 *
 * @param {Array<{element:SectionHeaderItem}>} stack - the section header stack
 * @returns {number}
 */
const indexOfLastSectionHeader = (stack) => {
  for (let i = stack.length - 1; i >= 0; --i) {
    if (stack[i].element.sectionHeader) {
      return i;
    }
  }
  return -1;
};

/**
 * Render the most-specific (determined by reservation ID) header available within a given
 * SectionHeaderContextProvider hierarchy.
 *
 * @param {Object} _props - ignored; React props for component
 * @returns {React.element}
 * @constructor
 */
export const SectionHeader = (_props) => (
  <SectionHeaderContext.Consumer>
    {(value) => {
      const stack = value.stack;
      const headerIndex = indexOfLastSectionHeader(stack);
      if (headerIndex >= 0) {
        return stack[headerIndex].element.sectionHeader();
      }
      return <Fragment />;
    }}
  </SectionHeaderContext.Consumer>
);

/**
 * Standard section header + navigation layout.  Takes a heading and a list of nav
 * items.
 *
 * @property {string} heading - the main heading to display for the section
 * @property {int} headingLevel - the level of the main heading. Default is 1
 * @property {bool} breadcrumbs - whether to display breadcrumbs. Default is true
 * @property {array<{action:string,label:string,exact:boolean,trackingParams:TrackingParams}>} items
 *   an array of props from which to construct SectionNavigation.Links;
 *   {@see SectionNavigation.Link} for documentation of props
 */
export const GenericSectionHeader = (props) => {
  return (
    <div className="cr-generic-section-header">
      <div className="cr-generic-section-header__current-position">
        {props.breadcrumbs && <Breadcrumb />}
        <Heading
          level={props.headingLevel}
          appearance="custom"
          className="cr-generic-section-header__heading"
        >
          <ClampLines
            lines={3}
            attributes={{ className: 'cr-generic-section-header__heading-clamped' }}
            cssClamp={{ lineHeight: 45 }}
          >
            {props.heading}
          </ClampLines>
        </Heading>
      </div>
      {props.items.length > 0 &&
        <SectionNavigation>
          {props.items.map((item) => (
            <SectionNavigation.Link
              action={item.action}
              key={item.action}
              exact={item.exact}
              trackingParams={item.trackingParams}
            >
              {item.label}
            </SectionNavigation.Link>
          ))}
        </SectionNavigation>
      }
    </div>
  );
};

GenericSectionHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  headingLevel: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      exact: PropTypes.bool,
      label: PropTypes.node.isRequired,
      action: PropTypes.string.isRequired,
      trackingParams: TrackStatPropType
    })
  ).isRequired,
  breadcrumbs: PropTypes.bool
};

GenericSectionHeader.defaultProps = {
  headingLevel: 1,
  items: [],
  breadcrumbs: true
};

/**
 * Render the breadcrumb specified by the SectionHeaderContext stack as defined by the React
 * component hierarchy.
 *
 * @param {Object} _props - ignored; React props for component
 * @returns {React.element}
 * @constructor
 */
export const Breadcrumb = (_props) => (
  <SectionHeaderContext.Consumer>
    {(value) => {
      const stack = value.stack;
      // locate the last section header that includes a heading and render breadcrumbs _before_ it
      const breadcrumbEnd = indexOfLastSectionHeader(stack);
      let content = <Fragment />;
      if (breadcrumbEnd > 0) {
        const separatorUpTo = breadcrumbEnd - 1;
        content = value.stack.slice(0, breadcrumbEnd).map((breadcrumbItem, idx) => (
          <div
            className="cr-section-header-breadcrumb__item"
            key={breadcrumbItem.id}
          >
            {breadcrumbItem.element.breadcrumb()}
            {
              idx < separatorUpTo && (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="cr-section-header-breadcrumb__item-separator"
                />
              )
            }
          </div>
        ));
      }
      return (<div className="cr-section-header-breadcrumb">{content}</div>);
    }}
  </SectionHeaderContext.Consumer>
);
