import React from "react";
import PropTypes from "prop-types";
import { makeClassName } from "utils";

import "./new_count_tag.sass";

/**
 * A tag showing the number of new items.
 *
 * @property {number} value
 */
export const NewCountTag = props => (
  <div className={makeClassName("new-count-tag", props.className)}>
    <p className="new-count-tag__text">
      {props.value.toLocaleString()} new
    </p>
  </div>
);

NewCountTag.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number
};
