import React from 'react';
import PropTypes from 'prop-types';

import './employee_personal_info.sass';

export const EmployeePersonalInfo = (props) => {
  const employee = props.employee;
  const name = employee.full_name || employee.email;

  return (
    <div className="c-employee-personal-info">
      <div className="c-employee-personal-info__name-location">
        <div title={name} className="c-employee-personal-info__name">
          <span className="c-employee-personal-info__name__text">
            {name}
          </span>
        </div>
        <div title={employee.location} className="c-employee-personal-info__location">
          <span className="c-employee-personal-info__location__text">
            {employee.location}
          </span>
        </div>
      </div>
    </div>
  );
};

EmployeePersonalInfo.propTypes = {
  employee: PropTypes.object.isRequired
};
