import { useBrowserQueryString } from 'controls/with_query_string';

/**
 * A simple hook that returns the part of the query string that holds the filter parameters
 * for Directory pages.
 * * @returns {QueryString}
 */
export const useDirectoryQueryStringFilter = () => {
  return useBrowserQueryString().dig('filter');
};
