import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useAlerts } from 'alert/index';
import { Button } from 'controls/button';
import Icon from 'controls/icon';
import { faPartyHorn } from '@fortawesome/pro-regular-svg-icons/faPartyHorn';
import { faPartyHorn as faPartyHornSolid } from '@fortawesome/pro-solid-svg-icons/faPartyHorn';
import { ConfettiAnimation } from 'controls/confetti_animation';
import { congratulateActions, CREATE, DELETE } from './celebrate_actions';
import { makeClassName } from 'utils';

import './celebrate_button.sass';

export const CelebrateButton = ({
  badge,
  onClick = () => {},
  shouldCelebrate = true,
  onlyIcon = false,
  organizationId = null
}) => {
  const intl = useIntl();
  const alerts = useAlerts();
  const confettiFocusRef = useRef();

  const [celebrated, setCelebrated] = useState(badge.has_congratulated);
  const [isLoading, setIsLoading] = useState(false);

  const [congratulateActionState, congratulateAction] = congratulateActions.useAction(
    CREATE,
    badge.id
  );
  const [congratulateDeleteActionState, congratulateDeleteAction] = congratulateActions.useAction(
    DELETE,
    badge.id
  );

  const firstName = badge.issued_to.split(' ')[0];
  const celebrateText = intl.formatMessage({ id: 'badge.view.celebrate', defaultMessage: 'Celebrate' });
  const undoText = intl.formatMessage({ id: 'badge.view.celebrate.undo', defaultMessage: 'Undo' });

  const celebrateAlertError = {
    type: 'error',
    text: intl.formatMessage({
      id: 'badge.view.celebrate.error',
      defaultMessage: 'We are unable to process your request, please try again.'
    })
  };

  const showCredfettiAnimation = () => {
    ConfettiAnimation({
      focusElement: confettiFocusRef?.current,
      alignment: 'center',
      zIndex: 1000
    });
  };

  useEffect(() => {
    if (congratulateActionState?.status.failed) {
      alerts.add(celebrateAlertError);
      setIsLoading(false);
      setCelebrated(false);
    } else if (congratulateActionState?.status.succeeded && isLoading) {
      showCredfettiAnimation();
      alerts.add({
        type: 'notice',
        text: intl.formatMessage({
          id: 'badge.view.celebrate.success',
          defaultMessage: '{firstName} will be notified that you have celebrated their achievement.'
        }, { firstName })
      });
      setIsLoading(false);
      setCelebrated(true);
    }
  }, [congratulateActionState?.status]);

  useEffect(() => {
    if (congratulateDeleteActionState?.status.failed) {
      alerts.add(celebrateAlertError);
      setIsLoading(false);
      setCelebrated(true);
    } else if (congratulateDeleteActionState?.status.succeeded && isLoading) {
      // Only celebrate if Id's match
      setIsLoading(false);
      setCelebrated(false);
      alerts.add({
        type: 'notice',
        text: intl.formatMessage({
          id: 'badge.view.celebrate.removed',
          defaultMessage: "Celebration removed for {firstName}'s badge."
        }, { firstName })
      });
    }
  }, [congratulateDeleteActionState?.status]);

  const onCelebrateClick = () => {
    onClick();
    if (shouldCelebrate) {
      setIsLoading(true);
      if (celebrated) {
        congratulateDeleteAction({ organization_id: organizationId });
      } else {
        congratulateAction({ organization_id: organizationId });
      }
    }
  };

  return (
    <div className="celebrate-button__container" ref={confettiFocusRef}>
      <Button
        type="secondary"
        className={makeClassName([
          'celebrate-button',
          onlyIcon && 'celebrate-button__only-icon',
          (onlyIcon && !celebrated) && 'uncelebrated'
        ])}
        onClick={onCelebrateClick}
        loading={isLoading}
        mobileSize="auto"
      >
        <Icon
          image={(onlyIcon && celebrated) ? faPartyHornSolid : faPartyHorn}
          className={makeClassName([
            'celebrate-button__icon',
            (onlyIcon && !celebrated) && 'uncelebrated'
          ])}
        />
        {onlyIcon && <span className="celebrate-tooltip">{celebrated ? undoText : celebrateText}</span>}
        {!onlyIcon && (celebrated ? undoText : celebrateText)}
      </Button>
    </div>
  );
};

CelebrateButton.propTypes = {
  badge: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  shouldCelebrate: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  organizationId: PropTypes.string
};
