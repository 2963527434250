import React, { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router';
import { SigninRequiredError } from 'utils/signin_required_error';
import { sessionLive } from 'management/organizations/action_creators';

/**
 * Monitors route changes and checks to see if the user session is still alive.  If not, raises
 * SigninRequiredError, so that the sign-in screen will be displayed.
 *
 * @param {object} _props - props for component; doesn't actually take any, so this should be empty
 * @returns {React.element}
 * @constructor
 */
export const CheckSessionAlive = (_props) => {
  const location = useLocation();
  const [sessionLiveState, sessionLiveAction] = sessionLive.useAction();

  // Trigger a request any time location changes; we'll monitor the state below and raise
  // SignInRequired if necessary
  useEffect(() => {
    sessionLiveAction();
  }, [location.pathname, location.search]);

  const resource = sessionLiveState.resources;
  // extract liveness state from resource; until we actually know what the state is, treat it as
  // alive
  const alive = resource ? resource.alive : true;
  useEffect(() => {
    if (!alive) {
      SigninRequiredError.raise();
    }
  }, [alive]);

  return <Fragment/>;
};
