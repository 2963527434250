import React from 'react';
import PropTypes from 'prop-types';
import { Chiclet } from 'pages/workforce/components/chiclet';
import { useIntl } from 'react-intl';
export const BadgeDetailStats = (props) => {
  const intl = useIntl();

  const viewed = intl.formatMessage({
    id: 'workforce.learning.learning_plan_details.viewed',
    defaultMessage: 'Viewed indicates the total number of employees who have viewed this recommendation. ' +
      'The percentage represents the percentage of employees who have viewed the recommendation ' +
      'out of the total of those who received it.'
  });
  const clicked = intl.formatMessage({
    id: 'workforce.learning.learning_plan_details.clicked',
    defaultMessage: 'Clicked indicates the the number and percent of employees who have viewed the badge detail page.'
  });
  const earned = intl.formatMessage({
    id: 'workforce.learning.learning_plan_details.earned',
    defaultMessage: 'Earned indicates the number and percent of employees who have completed the requirements and accepted the badge.'
  });
  const viewedLabel = intl.formatMessage({
    id: 'workforce.learning.learning_plan_details.viewed_label',
    defaultMessage: 'Viewed'
  });
  const clickedLabel = intl.formatMessage({
    id: 'workforce.learning.learning_plan_details.clicked_label',
    defaultMessage: 'Clicked'
  });
  const earnedLabel = intl.formatMessage({
    id: 'workforce.learning.learning_plan_details.earned_label',
    defaultMessage: 'Earned'
  });
  return (
    <>
      <Chiclet
        displayDifference={{
          total: props.viewed,
          previousTotal: props.employments
        }}
        label={viewedLabel}
        tooltip={viewed}
        useTotalPercent
      />
      <Chiclet
        displayDifference={{
          total: props.clicked,
          previousTotal: props.employments
        }}
        label={clickedLabel}
        tooltip={clicked}
        useTotalPercent
      />
      <Chiclet
        displayDifference={{
          total: props.earned,
          previousTotal: props.employments
        }}
        label={earnedLabel}
        tooltip={earned}
        useTotalPercent
      />
    </>
  );
};

BadgeDetailStats.propTypes = {
  employments: PropTypes.number,
  viewed: PropTypes.number,
  clicked: PropTypes.number,
  earned: PropTypes.number
};
