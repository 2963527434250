import { Action } from 'utils/action';
import * as stringUtils from 'utils/string';

export const trendingAndEmergingSkillsAction = new Action({
  resourceType: 'trendingEmergingSkills',
  requestKey: 'index',
  url: '/api/v1/organizations/[organization_id]/employees/[id]/trending_emerging_skills',
  urlSubs: new stringUtils.BracketSubs({
    id: { param: 'id', remove: true },
    organization_id: { param: 'organization_id', remove: true }
  }),
  effect: 'read',
  method: 'GET',
  list: 'list',
  alwaysRefresh: true
});
