import { Action } from 'utils/action';
import * as stringUtils from 'utils/string';

export const industryTrendingEmergingSkillsActions =
new Action({
  resourceType: 'industryTrendingEmergingSkills',
  requestKey: 'index',
  url: '/api/v1/organizations/[organization_id]/employees/industry_trending_emerging_skills',
  effect: 'read',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true }
  }),
  method: 'GET',
  list: 'list',
  alwaysRefresh: true
}, true);

