import { connect } from 'react-redux';
import { employeeBadgeTemplateSearchAction } from 'pages/workforce/employees/employees_actions';
import { BaseDirectorySearch } from 'pages/public/organization/earner_directory/base_directory_search.jsx';

const EmployeeBadgeTemplateConnected = connect(
  state => ({
    results: employeeBadgeTemplateSearchAction.getResources(state),
    searchStatus: employeeBadgeTemplateSearchAction.getStatus(state)
  }),
  dispatch => ({
    search: query => dispatch(employeeBadgeTemplateSearchAction.action(query))
  })
)(BaseDirectorySearch);

export { EmployeeBadgeTemplateConnected as EmployeeBadgeTemplateSearch };
export const testing = { EmployeeBadgeTemplateSearch: BaseDirectorySearch };
