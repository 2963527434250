import React from 'react';

/**
* Calculates props for `CarouselItemLoaderDisplay`. Determines when
* loading item placeholders should be rendered in the carousel based on
* ResourceStatus updates.
*
*
* @param {ResourceStatus} status
* @param {Object} slider - created by useCarousel
* @param {Boolean} - initialLoadingState - default true
*/

export const useCarouselManagerLoadingDisplayProps = ({
  status,
  slider,
  initialLoadingState = true
}) => {
  const [loading, setLoading] = React.useState(
    initialLoadingState
  );

  React.useEffect(() => {
    if (status.succeeded || status.failed) {
      setLoading(false);
    }

    if (status.pending && !loading) {
      setLoading(true);
    }
  }, [status]);
  return {
    count: !slider.ready || loading ? slider.itemsPerSlide : 0
  };
};


export const getControlArrowClassName = ({ size, layout, cardType, direction }) => {
  let className = `${cardType}-carousel-manager__control--${direction}`;
  if (size) {
    className = `${className}--${size}`;
  }
  if (layout) {
    className = `${layout}__${className}`;
  }

  return className;
};

export const getContainerClassName = ({ layout, cardType, size }) => {
  let className = `${cardType}-carousel-manager`;
  if (layout) {
    className = `${layout}__${className}`;
  }
  if (size) {
    className = `${className}--${size}`;
  }
  return className;
};
