import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Routes, stdCompare } from 'utils';
import { useDebouncedState } from 'utils/react_utils';
import * as stringUtils from 'utils/string';
import { Button } from 'controls/button';
import { Heading } from 'controls/heading';
import { LoadingSpinner } from 'controls/loading_spinner';
import { managementShortOrganizationActions } from 'management/organizations/action_creators';
import { recentlyViewedOrganizationsActions } from './action_creators';
import { OrganizationSelectorRow } from './organization_selector_row';
import { RecentlyViewedOrganizationCard } from './recently_viewed_organization_card';
import { useEnabledFeatures } from 'utils/enabled_features';

import './many_organizations_modal_selector.sass';

/**
 * Renders content to be displayed in a modal for choosing between different organizations
 * that number 7 or higher. Differs from FewOrganizationsModalSelector in that it also
 * displays recently viewed organizations that the user has navigated to previously.
 *
 * @property {string} current_organization_id - Id of the current organization that the user is
 * viewing.
 * @property {object} currentUser - Api resource object representing the current user
 * @property {string} onClose - Callback function to handle closing the modal view
 */
export const ManyOrganizationsModalSelector = (props) => {
  const [recentlyViewedOrganizationsState, fetchRecentlyViewedOrganizations] =
    recentlyViewedOrganizationsActions.useAction('getRecentlyViewedOrganizations');
  useEffect(() => {
    if (recentlyViewedOrganizationsState.status.idle) {
      fetchRecentlyViewedOrganizations();
    }
  }, [recentlyViewedOrganizationsState.status.idle]);
  const [fetchedOrganizationsState] = managementShortOrganizationActions.useOnMount(
    'getOrganizations'
  );
  const [fetchedSearchOrgsState, fetchSearchOrgs] = managementShortOrganizationActions
    .useAction('selectorSearchOrganizations');

  const [orgFilter, setOrgFilter] = useState('');

  const [debouncedOrgFilter, setDebouncedFilter] = useDebouncedState('', 300);
  const normalizedDebouncedOrgFilter = stringUtils.normalizeCaseInsensitive(debouncedOrgFilter);
  useEffect(() => {
    if (debouncedOrgFilter && debouncedOrgFilter !== '') {
      fetchSearchOrgs({ query: normalizedDebouncedOrgFilter });
    }
  }, [normalizedDebouncedOrgFilter]);

  const handleOrgFilter = useCallback((e) => {
    setOrgFilter(e.target.value);
    setDebouncedFilter(e.target.value);
  }, []);

  const lastSixViewed =
    recentlyViewedOrganizationsState.resources.slice().sort((rvo1, rvo2) => {
      return rvo2.last_viewed_at_sort - rvo1.last_viewed_at_sort;
    }).filter(
      rvo => rvo.organization_id !== props.current_organization_id
    ).slice(0, 6);

  const handleAddOrganizationClick = useCallback(() => {
    Routes.setPath(Routes.createOrganizationUrl());
  }, []);

  const processSelectorRowOrgs = (orgs, searchOrdered) => {
    return orgs.slice().sort((org1, org2) => {
      if (org1.id === props.current_organization_id) {
        return -1;
      } else if (org2.id === props.current_organization_id) {
        return 1;
      } else {
        // if the results are already ordered by the server (as from a search), then leave them as
        // they are relative to each other, except for the current organization
        return searchOrdered ? 0 : stdCompare(org1.name, org2.name);
      }
    });
  };

  const selectorRows = () => {
    const normalizedOrgFilter = stringUtils.normalizeCaseInsensitive(orgFilter);
    if (normalizedOrgFilter !== normalizedDebouncedOrgFilter ||
        (normalizedDebouncedOrgFilter && normalizedDebouncedOrgFilter !== fetchedSearchOrgsState.metadata.query) ||
          fetchedSearchOrgsState.status.pending ||
          fetchedOrganizationsState.status.pending) {
      return <LoadingSpinner/>;
    } else {
      let orgsToDisplay;
      let searchOrdered = false;
      if (debouncedOrgFilter) {
        // there's a search query, use the search resources and instruct processSelectorRowOrgs not
        // to reorder results
        orgsToDisplay = fetchedSearchOrgsState.resources;
        searchOrdered = true;
      } else {
        orgsToDisplay = fetchedOrganizationsState.resources;
      }
      return processSelectorRowOrgs(orgsToDisplay, searchOrdered).map((org) => {
        return (
          <OrganizationSelectorRow
            organization={org}
            current={org.id === props.current_organization_id}
            key={org.id}
          />
        );
      });
    }
  };

  const isFeatureEnabled = useEnabledFeatures();

  return (
    <div className="c-many-organizations-modal-selector">
      {recentlyViewedOrganizationsState.status.pending && <LoadingSpinner/>}
      {!recentlyViewedOrganizationsState.status.pending && lastSixViewed.length > 0 &&
        <div className="c-many-organizations-modal-selector__recently-viewed">
          <Heading
            className="c-many-organizations-modal-selector__recently-viewed__header"
            appearance="custom"
          >
            Recently Viewed
          </Heading>
          <div className="c-many-organizations-modal-selector__recently-viewed__orgs-container">
            <div className="c-many-organizations-modal-selector__recently-viewed__orgs">
              {lastSixViewed.map((rvo) => (
                <RecentlyViewedOrganizationCard
                  className="c-many-organizations-modal-selector__recently-viewed__orgs__selector-card"
                  key={rvo.id}
                  recentlyViewedOrganization={rvo}
                />
              ))}
            </div>
          </div>
        </div>
      }
      <input
        className="c-many-organizations-modal-selector__search"
        onChange={handleOrgFilter}
        value={orgFilter}
        placeholder="Search organizations"
      />
      <div className="c-many-organizations-modal-selector__organization-selector-rows-container">
        <div className="c-many-organizations-modal-selector__organization-selector-rows">
          {selectorRows()}
        </div>
      </div>
      <div className="c-many-organizations-modal-selector__buttons">
        <Button
          className="c-many-organizations-modal-selector__buttons__button"
          onClick={props.onClose}
          type="cancel"
        >
          Cancel
        </Button>
        {isFeatureEnabled('self-service-org-creation') &&
        props.currentUser.platform_manager &&
          <Button
            className="c-many-organizations-modal-selector__buttons__button"
            onClick={handleAddOrganizationClick}
          >
            Add Organization
          </Button>
        }
      </div>
    </div>
  );
};

ManyOrganizationsModalSelector.propTypes = {
  current_organization_id: PropTypes.string,
  currentUser: PropTypes.object,
  onClose: PropTypes.func
};
