import React from 'react';
import PropTypes from 'prop-types';
import { WithSectionHeader } from 'controls/section_header';
import { EmployeesAddSectionHeader } from "./employees_add_section_header";
import { Routes } from 'pages/workforce/employees/employees/add/routes';
import { useIntl } from 'react-intl';

/**
 * Layout for the employee(s) add page.
 *
 * @param {Object} props
 *  @param {String} props.baseUrl
 *  @param {Object} props.organization
 *
 * @return {JSX.Element}
 */
export const Layout = (props) => {
  const intl = useIntl();

  const sectionHeader = React.useCallback(() => (
    <EmployeesAddSectionHeader
      baseUrl={props.baseUrl}
      organizationId={props.organization.id}
    />
  ), [props.baseUrl]);

  const breadcrumb = {
    action: props.baseUrl,
    label: intl.formatMessage({
      id: 'workforce.employees.add.employees_add_section_header.header',
      defaultMessage: 'Add Employee(s)'
    })
  };

  return (
    <WithSectionHeader sectionHeader={sectionHeader} breadcrumb={breadcrumb}>
      <div className="col col-lg-9 col-xl-8">
        <Routes organization={props.organization} baseUrl={props.baseUrl}/>
      </div>
    </WithSectionHeader>
  );
};

Layout.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired
};
