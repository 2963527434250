import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';

// Actions for pages that deal with just one badge. Stores multiple badges so they can be reused.
export const employerStatsActions = new ActionManager('workforceEmployerStats')
  .add({
    requestKey: 'current',
    url: '/v1/organizations/[id]/employer_stats/current',
    effect: 'read',
    method: 'GET'
  }, true);

export const employerStatsHistoryActions = new ActionManager('workforceEmployerStatsHistory')
  .add({
    requestKey: 'last_month',
    url: '/v1/organizations/[id]/employer_stats/last_month',
    effect: 'read',
    method: 'GET'
  }, true)
  .add({
    requestKey: 'past_year',
    url: '/v1/organizations/[organization_id]/employer_stats?include_realtime_data=true',
    effect: 'read',
    method: 'GET',
    list: 'past_year',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    })
  });
