import { Action } from 'utils/action';
import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';

export const search = new Action({
  resourceType: 'directorySearch',
  requestKey: 'search',
  method: 'GET',
  effect: 'read',
  url: '/api/v1/directory/search/[type]',
  urlSubs: new stringUtils.BracketSubs({
    type: { param: 'search_type', remove: true }
  }),
  alwaysRefresh: true,
  list: 'search',
  preTransform: data => (data.search_results || []).map(r => ({id: r, value: r}))
});

export const filter = new Action({
  resourceType: 'directoryCards',
  requestKey: 'filter',
  method: 'GET',
  effect: 'read',
  url: '/api/v1/directory',
  alwaysRefresh: true,
  list: 'search'
});

export const directoryFilter = new ActionManager('directoryFilter')
  .add({
    requestKey: 'get',
    method: 'GET',
    effect: 'read',
    url: '/api/v1/directory_filters/[id]',
    alwaysRefresh: true
  }, true)
  .add({
    requestKey: 'getForOrg',
    method: 'GET',
    effect: 'read',
    url: '/api/v1/directory_filters',
    list: 'filters',
    alwaysRefresh: true
  });
