import React from 'react';
import PropTypes from 'prop-types';
import './badge_recommended_by.sass';
import { useIntl } from 'react-intl';

/**
 * Component for employee table of col for employee name and name of user who recommended that badge.
 * Displays the employee full name and full name of person who recomended the badge.
 *
 * @param {string} name - full name of employee
 * @param {string} recommendedBy - name of the user who recomended the badge
 * @returns {JSX.Element} A link to the badge template
 */

export const BadgeRecommendedBy = (props) => {
  const intl = useIntl();

  const recommendedByText = intl.formatMessage({
    id: 'workforce.learning.learning_plans.recommended_by',
    defaultMessage: 'By {recommendedBy}'
  }, { recommendedBy: props.recommendedBy });

  return (
    <div>
      {props.name}
      <p className="workforce-learning-employee__recomended-by">
        {recommendedByText}
      </p>
    </div>
  );
};

BadgeRecommendedBy.propTypes = {
  name: PropTypes.string.isRequired,
  recommendedBy: PropTypes.string.isRequired
};
