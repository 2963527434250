import { Action } from 'utils/action';
import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';

export const uploadEmployees = new Action({
  resourceType: 'uploadEmployees',
  requestKey: 'create',
  url: '/api/v1/organizations/[organization_id]/employee_import',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true }
  }),
  effect: 'create',
  method: 'POST',
  list: 'list',
  alwaysRefresh: true
});

export const getUploadEmployees = new Action({
  resourceType: 'uploadEmployees',
  requestKey: 'get',
  url: '/api/v1/organizations/[organization_id]/employee_import',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true }
  }),
  effect: 'read',
  method: 'GET',
  list: 'list',
  alwaysRefresh: true
});

export const uploadEmployeeOccupations = new Action({
  resourceType: 'uploadEmployeeOccupations',
  requestKey: 'create',
  url: '/api/v1/organizations/[organization_id]/employee_occupation_import',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true }
  }),
  effect: 'create',
  method: 'POST',
  list: 'list',
  alwaysRefresh: true
});

export const getUploadEmployeeOccupations = new Action({
  resourceType: 'uploadEmployeeOccupations',
  requestKey: 'get',
  url: '/api/v1/organizations/[organization_id]/employee_occupation_import',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true }
  }),
  effect: 'read',
  method: 'GET',
  list: 'list',
  alwaysRefresh: true
});

export const employeeProfileActions = (
  new ActionManager('managementOrganizationsEmployeeProfiles')
).add({
  requestKey: 'get',
  url: '/api/v1/organizations/[organization_id]/employees/[id]/profile',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true }
  }),
  effect: 'read',
  method: 'GET',
  alwaysRefresh: true
}, true).add({
  requestKey: 'update',
  url: '/api/v1/organizations/[organization_id]/employees/[id]',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true }
  }),
  effect: 'update',
  method: 'PATCH'
}, true);

export const employeeBadgeDataActions = (
  new ActionManager('managementOrganizationsEmployeeBadges')
).add({
  requestKey: 'getBadgeData',
  url: '/api/v1/organizations/[organization_id]/employees/[id]/badges',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true },
    id: { param: 'id', remove: true }
  }),
  effect: 'read',
  method: 'GET',
  list: 'list',
  alwaysRefresh: true
});

export const employeeBadgeTemplateSearchAction = new Action({
  resourceType: 'managementOrganizationsEmployeesBadgeTemplates',
  requestKey: 'search',
  method: 'GET',
  effect: 'read',
  url: '/api/v1/organizations/[organization_id]/employee_badge_template_directory/search/[type]',
  urlSubs: new stringUtils.BracketSubs({
    type: { param: 'search_type', remove: true },
    organization_id: { param: 'organization_id', remove: true }
  }),
  alwaysRefresh: true,
  list: 'search',
  preTransform: data => (data.search_results || []).map(r => ({ id: r, value: r }))
});

export const employeeProfileSkillsActions = (
  new ActionManager('managementOrganizationsEmployeeProfileSkills')
).add({
  requestKey: 'getSkillData',
  url: '/api/v1/organizations/[organization_id]/employees/[id]/skills',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: {
      param: 'organization_id',
      remove: true
    },
    id: {
      param: 'id',
      remove: true
    }
  }),
  effect: 'read',
  method: 'GET',
  list: 'list',
  alwaysRefresh: true
});

export const employeeBadgeTemplateActions =
  (new ActionManager('managementOrganizationsEmployeesBadgeTemplates'))
    .add({
      requestKey: 'filter',
      url: '/api/v1/organizations/[organization_id]/employee_badge_template_directory',
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      effect: 'read',
      method: 'GET',
      alwaysRefresh: true,
      list: 'all'
    });

export const employeeActions =
  (new ActionManager('managementOrganizationsEmployees'))
    .add({
      requestKey: 'create',
      url: '/api/v1/organizations/[organization_id]/employees',
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      effect: 'create',
      method: 'POST'
    }).add({
      requestKey: 'get',
      url: '/api/v1/organizations/[organization_id]/employees/[id]',
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      effect: 'read',
      method: 'GET'
    }, true).add({
      requestKey: 'delete',
      url: '/api/v1/organizations/[organization_id]/employees/[id]',
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      effect: 'delete',
      method: 'DELETE'
    }, true);

export const employeeFilterOptionAction = new Action({
  resourceType: 'managementOrganizationsEmployeesFilterOptions',
  requestKey: 'get',
  url: '/api/v1/organizations/[organization_id]/employees/filter_options',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true }
  }),
  effect: 'read',
  method: 'GET'
});

export const employeeOccupationActions =
  (new ActionManager('managementOrganizationsEmployeeOccupations'))
    .add({
      requestKey: 'filter',
      url: '/api/v1/organizations/[organization_id]/employee_occupation_directory',
      urlSubs: new stringUtils.BracketSubs({
        organization_id: { param: 'organization_id', remove: true }
      }),
      effect: 'read',
      method: 'GET',
      list: 'list',
      alwaysRefresh: true
    });

export const employeeOccupationsSearch = new Action({
  resourceType: 'managementOrganizationsEmployeeOccupationsSearch',
  requestKey: 'get',
  url: '/api/v1/organizations/[organization_id]/employee_occupation_directory/search/[type]',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true },
    type: { param: 'search_type', remove: true }
  }),
  effect: 'read',
  method: 'GET',
  list: 'search',
  alwaysRefresh: true,
  preTransform: data => (data.search_results || []).map(r => ({ id: r, value: r }))
});

export const employeeSkillsAction = (
  new ActionManager('managementOrganizationsEmployeeSkills'))
  .add({
    requestKey: 'filter',
    url: '/api/v1/organizations/[organization_id]/employee_skill_directory',
    urlSubs: new stringUtils.BracketSubs({
      organization_id: { param: 'organization_id', remove: true }
    }),
    effect: 'read',
    method: 'GET',
    list: 'list',
    alwaysRefresh: true
  });

export const employeeSkillsSearch = new Action({
  resourceType: 'managementOrganizationsEmployeeSkillsSearch',
  requestKey: 'get',
  url: '/api/v1/organizations/[organization_id]/employee_skill_directory/search/[type]',
  urlSubs: new stringUtils.BracketSubs({
    organization_id: { param: 'organization_id', remove: true },
    type: { param: 'search_type', remove: true }
  }),
  effect: 'read',
  method: 'GET',
  list: 'search',
  alwaysRefresh: true,
  preTransform: data => (data.search_results || []).map(r => ({ id: r, value: r }))
});
