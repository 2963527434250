import React, {Component} from 'react';
import PropTypes from 'prop-types';
import widthWatcher, { BREAKPOINT_NAMES } from 'utils/watch_width_breakpoints';


/**
 * Higher-order component to allow other components to watch for width breakpoints. This is just
 * like watch_width_breakpoints, except that it automatically cleans itself up when it unmounts.
 * Example: export default withWindowBreakpoints(MyComponent);
 *
 * @param WrappedComponent
 * @returns {WithWindowBreakpoints}
 */
export default function withWindowBreakpoints(WrappedComponent) {
  class WithWindowBreakpoints extends Component {
    constructor(props) {
      super(props);
      this.m_watcherId = 0;
      this.state = {view: widthWatcher.currentView()};
    }

    /**
     * The component just mounted.
     */
    componentDidMount() {
      this.m_watcherId = widthWatcher.add(view => this.setState({view: view}));
    }


    /**
     * The component is about to unmount.
     */
    componentWillUnmount() {
      widthWatcher.remove(this.m_watcherId);
    }


    /**
     * Wrap the component.
     *
     * @returns {React.element}
     */
    render() {
      return <WrappedComponent windowWidthBreakpoint={this.state.view} {...this.props}/>
    }
  }
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WithWindowBreakpoints.displayName = `WithWindowBreakpoints(${displayName})`;
  return WithWindowBreakpoints;
}

export const WithWindowBreakpointsPropTypes = {
  windowWidthBreakpoint: PropTypes.oneOf(BREAKPOINT_NAMES)
};
