import React from 'react';
import PropTypes from 'prop-types';
import { ResourceStatusPropType } from 'utils/prop_types';
import { uiTweaks } from 'controls/data_table/helpers';
import { DataTable } from 'controls/data_table';
import { EarnerPropType, EmployeeEarnerPropType } from './utils';
import { NoEarnerResults } from './no_earner_results';

import './base_directory_table.sass';

/**
 * Wrapper component that takes in <DataTable.Col> as children and wraps it in a
 * <DataTable> component with some preset props. Used to build various directory table
 * visuals such as <EmployeeTable>
 *
 * @param props
 *  @param props.data - Array of resource objects to be supplied to DataTable
 *  @param props.status - Redux resource status object for the data
 */
export const BaseDirectoryTable = (props) => {
  return (
    <DataTable
      {...props}
      loader={{ loading: props.status.pending }}
      rowClassName={['base-directory-table__row', props.rowClassName]}
      emptyResultNode={() => <NoEarnerResults status={props.status}/>}
      header={{}}
      uiTweaks={[uiTweaks.NO_ROW_HEIGHT, uiTweaks.ROW_BORDERS]}
    >
      {props.children}
    </DataTable>
  );
};

BaseDirectoryTable.propTypes = {
  children: PropTypes.node,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType(
      [
        EarnerPropType,
        EmployeeEarnerPropType
      ]
    )
  ).isRequired,
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  status: ResourceStatusPropType.isRequired
};
