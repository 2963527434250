import React from 'react';
import PropTypes from 'prop-types';
import { CarouselManager, carouselCardTypes, carouselLayoutTypes } from 'controls/carousel/carousel_manager';
import { Heading } from 'controls/heading';
import { exploreBadgesAction, popularBadgesAction } from './action_creators';
import { useIntl } from 'react-intl';
import dateUtils from 'utils/date';
import { makeClassName } from 'utils';

import './explore_badges.sass';


export const ExploreBadges = (props) => {
  // Subtracts one month from current date using milliseconds
  const dateMillis = Date.now() - 2629800000;
  // Creates date one month before now
  const date = new Date(dateMillis);

  let exploreParams = {
    'filter[previous_organizations]': true
  };

  if (props.showPopularBadges) {
    exploreParams = {
      ...exploreParams,
      organization_id: props.organizationId,
      'filters[badges][created_at]': dateUtils.formatShort(date)
    };
  }
  const action = props.showPopularBadges ? popularBadgesAction : exploreBadgesAction;
  const [exploreBadgesState] = action.useOnMount(exploreParams);
  const [issuerName, setIssuerName] = React.useState();
  const intl = useIntl();

  React.useEffect(() => {
    if (
      exploreBadgesState.metadata.total_organizations === 1 &&
      exploreBadgesState.resources.length
    ) {
      setIssuerName(exploreBadgesState.resources[0].issuer_entity.name);
    }
  }, [exploreBadgesState.metadata.total_organizations]);

  const finishedRequest = !exploreBadgesState.status.pending;
  const resourcesEmpty = !exploreBadgesState.resources?.length;
  const badgesEmpty = (
    !resourcesEmpty &&
    exploreBadgesState.resources[0].badges &&
    !exploreBadgesState.resources[0].badges.length
  );

  // Return nothing if the request is finished and there's nothing to show
  if (finishedRequest && (resourcesEmpty || badgesEmpty)) {
    return <></>;
  }

  const getClassNamePrefix = () => !props.workforceContent ? 'earner-dashboard__explore-badges' : 'workforce_dashboard__popular-badges';

  return (
    <div className="earner-dashboard__explore-badges-container">
      <Heading
        className={makeClassName([
          `${getClassNamePrefix()}-heading`,
          !props.workforceContent && "container"
        ])}
        appearance="serif-primary"
      >
        <span className={makeClassName([
          "earner-dashboard__explore-badges-heading-text",
          props.workforceContent && 'workforce-dashboard__popular-badges-text'
        ])}
        >
          {intl.formatMessage(
            props.headingTitleIntlObj ? props.headingTitleIntlObj
              : {
                id: 'earner_dashboard.explore_badges.heading',
                defaultMessage: 'Explore other badges'
              })}
        </span>
        {issuerName && (
          <span className="earner-dashboard__explore-badges-from-issuer">
            {' ' + intl.formatMessage({
              id: 'earner_dashboard.explore_badges.from_issuer',
              defaultMessage: 'from {issuerName}'
            }, { issuerName })}
          </span>
        )}
      </Heading>
      <div className="explore-badges-carousel-container">
        <CarouselManager
          layout={
            props.workforceContent ? carouselLayoutTypes.WORKFORCE : carouselLayoutTypes.DASHBOARD
          }
          cardType={carouselCardTypes.BADGE}
          sourceTrack="ExploreBadges"
          action={action}
          actionParams={exploreParams}
          settings={{
            label: intl.formatMessage({
              id: 'earner_dashboard.explore_badges_carousel.label',
              defaultMessage: 'Badge Carousel'
            }),
            description: intl.formatMessage({
              id: 'earner_dashboard.explore_badges_carousel.description',
              defaultMessage: 'Explore other badges'
            }),
            itemLabel: (badge) => intl.formatMessage({
              id: 'earner_dashboard.explore_badges_carousel.item_label',
              defaultMessage: '{badgeName} badge'
            }, { badgeName: badge.name }),
            itemsPerSlideBreakpoints: {
              md: 2,
              xs: 1,
              sm: 1,
              lg: props.workforceContent ? 2 : 3,
              default: 3
            }
          }}
        />
      </div>
    </div>
  );
};

ExploreBadges.propTypes = {
  headingTitleIntlObj: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string
  }),
  showPopularBadges: PropTypes.bool,
  organizationId: PropTypes.string,
  workforceContent: PropTypes.bool
};
