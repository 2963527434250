import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'controls/dialog';
import { SmartLink } from 'controls/smart_link';
import { LoadingSpinner } from 'controls/loading_spinner';
import { makeClassName } from "utils";

import './confirmation_modal.sass';

/**
 * Renders a confirmation modal that obscures the rest of the page content and traps focus.
 *
 * @param {object} props
 *   @property {String} title - The title of the dialog
 *   @property {Function} setShowModal - Called when the dialog should be show or hide without action
 *   @property {Boolean} show - True to show the dialog, false to hide it
 *   @property {String} question - Question title like "Are you sure you want to...."
 *   @property {Function} buttonAction - Confirm button action
 *   @property {String} buttonActionDescription - Confirm button description
 *   @property {String} description - Description of the action to perform
 *   @property {String} objectDetail - Name of the object to which we apply the action
 *   @property {String} additionalInformation - Additional information of the object to which we apply the action
 *   @property {String} alternativeText - Additional text of the object to which we apply the action
 *   @property {String} link - Additional link
 *   @property {Function} linkAction - Additional link action
 *   @property {Boolean} isLoading - Show a loading spinner
 * @returns {React.element}
 * @constructor
 */

const ConfirmationModal = (props) => {
  const {
    setShowModal,
    showModal,
    title,
    question,
    buttonAction,
    buttonActionDescription,
    description,
    objectDetail,
    additionalInformation,
    alternativeText,
    link,
    linkAction,
    isLoading,
    isDelete,
    size
  } = props;

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Dialog
      show={showModal}
      title={title}
      titleTheme="small"
      className={makeClassName("ac-confirmation-modal", props.className)}
      size={size || 'half'}
      shouldClose={handleCloseModal}
    >
      {isLoading
        ? <LoadingSpinner position="below"/>
        : (
          <>
            <Dialog.Content className="ac-confirmation-modal__content" contentPadTheme="withoutMarginBottom">
              <div className="ac-confirmation-modal__question">
                {question}
              </div>
              <div>
                {description}
              </div>
              <div className={isDelete ? "ac-confirmation-modal__object-detail-delete" : "ac-confirmation-modal__object-detail"}>
                {objectDetail}
              </div>
              <div>
                {additionalInformation}
              </div>
              {alternativeText &&
                <div className="ac-confirmation-modal__alternative-text">
                  {alternativeText}
                </div>
              }
              {link && linkAction &&
                <div>
                  <SmartLink
                    action={linkAction}
                    mobileSize="auto"
                  >
                    {link}
                  </SmartLink>
                </div>
              }
            </Dialog.Content>
            <Dialog.Footer confirmation>
              {!props.notCancelButton && <Dialog.Action type="min">Cancel</Dialog.Action>}
              <Dialog.Action action={buttonAction} noClose>{buttonActionDescription}</Dialog.Action>
            </Dialog.Footer>
          </>
        )
      }
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  buttonAction: PropTypes.func.isRequired,
  buttonActionDescription: PropTypes.string.isRequired,
  description: PropTypes.string,
  objectDetail: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  additionalInformation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  alternativeText: PropTypes.string,
  link: PropTypes.string,
  linkAction: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isLoading: PropTypes.bool,
  notCancelButton: PropTypes.bool,
  isDelete: PropTypes.bool,
  size: PropTypes.oneOf(['auto', 'full', 'half']),
  className: PropTypes.string
};

export default ConfirmationModal;
