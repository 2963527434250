import { useCallback, useState, useEffect } from 'react';

/**
 * Hook for managing selection state of a list of entries.
 * Each entry is an object that should have an 'id' property.
 *
 * @param {Array[Object]} iterativeEntries - List of resources containing an id attribute
 * @param {Object<Function, String>} {propsForFieldGroup.handleChange, propsForFieldGroup.name} - handleChange function, and name corresponding to the field group
 * @returns {Object} - Contains 'allSelected' and 'onSelect' properties
 */
export const useSelectionManager = (iterativeEntries, { handleChange, name }) => {
  // State representing whether all entries are selected
  const [allSelected, setAllSelected] = useState(false);

  // State representing a map of the selection status of each entry
  const [entrySelectionMap, setEntrySelectionMap] = useState({});

  // Effect to update selection map when the entries change, preserving selection status of existing entries
  useEffect(() => {
    if (iterativeEntries?.length) {
      const newMap = iterativeEntries.reduce((accumulator, entry) => {
        accumulator[entry?.id] = !!entrySelectionMap[entry?.id];
        return accumulator;
      }, {});
      setEntrySelectionMap(newMap);
    }
  }, [iterativeEntries]);

  /**
   * Callback to handle selection changes.
   *
   * @param {Array} entries - Entries to be selected or deselected depending on their current
   * mapped state
   * @param {boolean} [valueOverride=null] - Force all entries to this value if provided
   */
  const onSelect = useCallback((entries, valueOverride = null) => {
    // Create a new map from current selection map to prevent direct mutation
    const newSelectionMap = { ...entrySelectionMap };

    // Update selection map entries based on 'entries' array and 'valueOverride'
    entries.forEach(entry => {
      newSelectionMap[entry.id] = valueOverride ?? !newSelectionMap[entry.id];
    });

    // Extract IDs of selected entries
    const selectedEntryIds = getSelectedEntryIds(newSelectionMap);

    // Determine if all entries are selected
    const shouldSelectAll = selectedEntryIds.length === iterativeEntries.length;

    // Update 'allSelected' state and selection map
    setAllSelected(valueOverride ?? shouldSelectAll);
    setEntrySelectionMap(newSelectionMap);

    // Trigger external change handler with updated selected entry IDs
    handleChange(name, selectedEntryIds);
  }, [entrySelectionMap, setEntrySelectionMap, allSelected, setAllSelected]);

  // Return current selection state and selection handler
  return { allSelected, onSelect };
};

/**
 * Helper function to extract IDs of selected entries.
 *
 * @param {Object} selectionMap - Map of entries to their selection status
 * @returns {Array} - Array of IDs of selected entries
 */
const getSelectedEntryIds = (selectionMap) => (
  Object.entries(selectionMap)
    .filter(([_, value]) => value)
    .map(([key]) => key)
);
