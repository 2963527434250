import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/pro-light-svg-icons/faSlidersH';
import { FilterButton } from 'controls/button';
import { FilterSectionGroup, FilterSection } from 'management/controls/filter_section';
import Dialog from 'controls/dialog';
import { Form } from 'form';
import PropTypes from 'prop-types';

import './contact_and_links_filters.sass';

export const ContactAndLinksFilters = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);
  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);
  return (
    <>
      <div className={props.className}>
        <FilterButton
          mobileSize="auto"
          onClick={openDialog}
          appliedFilters={getFilterCount(props.filters)}
        >
          Filter
          <FontAwesomeIcon
            className="earner-directory-contact-and-links-filters__filter-icon"
            icon={faSlidersH}
          />
        </FilterButton>
        <Dialog show={dialogOpen} shouldClose={closeDialog} title="Filters" size="half">
          <ContactAndLinksFiltersContent {...props} />
        </Dialog>
      </div>
    </>
  );
};

ContactAndLinksFilters.propTypes = {
  onFiltersChanged: PropTypes.func.isRequired,
  filters: PropTypes.object
};

const getFilterCount = (filters) => {
  if (filters !== undefined) {
    return (filters.messaging_enabled ? 1 : 0) + (filters.has_external_link ? 1 : 0);
  } else {
    return 0;
  }
};

const ContactAndLinksFiltersContent = (props) => {
  const [withMessagingChecked, setWithMessagingChecked] = useState(
    props.filters.messaging_enabled === 'true'
  );
  const [withLinkChecked, setWithLinkChecked] = useState(
    props.filters.has_external_link === 'true'
  );
  const handleClear = useCallback(() => {
    props.onFiltersChanged({ messaging_enabled: undefined, has_external_link: undefined });
  });
  const handleSave = useCallback(() => {
    props.onFiltersChanged({
      messaging_enabled: withMessagingChecked ? true : undefined,
      has_external_link: withLinkChecked ? true : undefined
    });
  }, [withMessagingChecked, withLinkChecked]);
  const handleWithMessagingChanged = useCallback((name, value) => {
    setWithMessagingChecked(value);
  }, []);
  const handleWithLinkChanged = useCallback((_name, value) => {
    setWithLinkChecked(value);
  }, []);

  return (
    <>
      <Dialog.Content noScroll>
        <FilterSectionGroup>
          <FilterSection
              heading="Contact"
          >
            <Form.Checkbox
                value={withMessagingChecked}
                handleChange={handleWithMessagingChanged}
                name="messaging"
                label="Show only earners with messaging turned on."
            />
          </FilterSection>
          <FilterSection
              heading="Links"
          >
            <Form.Checkbox
                value={withLinkChecked}
                handleChange={handleWithLinkChanged}
                name="link"
                label="Show only earners with external links provided in their profile."
            />
          </FilterSection>
        </FilterSectionGroup>
      </Dialog.Content>
      <Dialog.Footer multiAction>
        <Dialog.Action action={handleClear} type="secondary">
          Clear
        </Dialog.Action>
        <Dialog.Action action={handleSave}>
          Save
        </Dialog.Action>
      </Dialog.Footer>
    </>
  );
};

ContactAndLinksFiltersContent.propTypes = {
  onFiltersChanged: PropTypes.func.isRequired
};

