import React from 'react';
import PropTypes from 'prop-types';

export const CarouselManagerContext = React.createContext();

export const CarouselManagerProvider = (
  {
    type,
    action,
    actionParams,
    layout,
    children,
    size,
    sourceTrack
  }) => {
  return (
    <CarouselManagerContext.Provider value={{
      cardType: type,
      action,
      actionParams,
      layout,
      size,
      sourceTrack
    }}
    >
      {children}
    </CarouselManagerContext.Provider>
  );
};


CarouselManagerProvider.propTypes = {
  type: PropTypes.string.isRequired,
  action: PropTypes.object.isRequired,
  actionParams: PropTypes.object,
  layout: PropTypes.string,
  size: PropTypes.string,
  sourceTrack: PropTypes.string,
  children: PropTypes.node
};
