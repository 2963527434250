import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useAlerts } from 'alert';
import { useToggle } from 'utils/react_utils';
import { Button } from 'controls/button';
import { useQueryString } from 'controls/with_query_string';
import { EmployeeRouteHelpers } from 'pages/workforce/route_helpers';
import { usePermissions, Permissions } from 'management/organizations/permissions';
import {
  EmployeeFilters
} from 'pages/workforce/employees/employees/list/filters';
import { EmployeeTable } from 'pages/workforce/employees/employees/employee_directory/employee_table';
import { DirectoryBase } from 'pages/public/organization/earner_directory/shared/directory_base';
import { EmployeeDirectoryContextProvider } from './employee_directory_context_provider';
import { ExportOccupationsDialog } from 'pages/workforce/components/export_occupations_dialog';
import { EmployeeDirectorySearch } from 'pages/workforce/employees/employees/employee_directory/employee_directory_search';
import { WORKFORCE_FILTER_NAMES } from 'pages/workforce/employees/employees/employee_directory';
import { useDirectoryQueryStringFilter } from 'pages/public/organization/earner_directory/shared/use_directory_query_string_filter';
import { useExcludedSearchTypes } from 'pages/workforce/faethm_occupation_mapping_hooks';
import { employeeFilter } from './employee_directory_actions';
import { InviteEmployeesModal } from './invite_employees_modal';

const SEARCH_TABS = [
  { name: 'user', label: 'Employee Name' },
  { name: 'skill', label: 'Skills' },
  { name: 'badge', label: 'Badges' },
  { name: 'location', label: 'Locations' },
  { name: 'occupation', label: 'Occupations' },
  { name: 'department', label: 'Departments' }
];

/**
 * The Employee Directory limits results based on the Employment table.
 *
 * @param {Object} props
 *   @param {String} props.baseUrl - the base URL of the employee directory
 *     e.g. /mgmt/organizations/org-id/employees/employees
 *   @param {String} props.id - The organization id of the employer.
 *   @param {Object} props.excludeSearchTypes - Set of search types to hide.
 *   @param {Object} props.routeProps - Contains the properties of the current route
 */
export const EmployeeDirectory = props => {
  const routeHelpers = EmployeeRouteHelpers.forUrl(props.baseUrl);
  const [hasPermissions] = usePermissions();
  const queryString = useQueryString(props.routeProps.location);
  const excludedSearchTypes = useExcludedSearchTypes();
  const exportOccupationEnabled = hasPermissions(Permissions.PLATFORM_MANAGER);
  const [filterState] = employeeFilter.useAction();
  const totalReturnedEmployees = filterState.metadata.total_count;
  const [displayInvite, toggleDisplayInvite] = useToggle(false);
  const [displayExportOccupations, toggleDisplayExportOccupations] = useToggle(false);
  const selectedFilters = useDirectoryQueryStringFilter();
  const invitableEmployeeCount = filterState.metadata.invitable_count;

  const inviteEmployeesMetadata = {
    alertStack: useAlerts(),
    selectedFilters: selectedFilters,
    enabled: !!invitableEmployeeCount && invitableEmployeeCount > 0,
    show: displayInvite,
    toggle: toggleDisplayInvite,
    exportOccupationEnabled: exportOccupationEnabled,
    displayExportOccupations: displayExportOccupations,
    toggleDisplayExportOccupations: toggleDisplayExportOccupations,
    totalEmployees: totalReturnedEmployees,
    invitableEmployeeCount: invitableEmployeeCount
  };

  const addEmployeeTag = (
    <Button
      to={routeHelpers.employeeAdd.parameterize()}
    >
      <FormattedMessage
        id="workforce.employees.employees.employee_directory.add_employee"
        defaultMessage="Add Employee"
      />
    </Button>
  );

  const employeeFilterTag = (
    <EmployeeFilters
      organization_id={props.organization.id}
      filters={queryString.get('filter') || {}}
      allowedOptions={['invitation_status']}
    />
  );

  return (
    <EmployeeDirectoryContextProvider inviteEmployeesMetadata={inviteEmployeesMetadata}>
      <DirectoryBase
        addRowTag={hasPermissions(Permissions.EMPLOYMENT_CREATE) && addEmployeeTag}
        allowNotSearches
        alwaysRenderHeader
        baseParams={{ organization_id: props.id }}
        className="col c-employees-employees"
        excludeSearchTypes={excludedSearchTypes}
        exportEnabled={!!filterState?.resources?.length}
        directorySearchTag={EmployeeDirectorySearch}
        filterAction={employeeFilter}
        filterTag={employeeFilterTag}
        filterNames={WORKFORCE_FILTER_NAMES}
        resultsRender={(directoryResultsFilterState) => (
          <EmployeeTable
            data={directoryResultsFilterState.resources}
            status={directoryResultsFilterState.status}
            routeProps={props.routeProps}
          />
        )}
        searchBarPlaceholderText="Search employees"
        sortSelectorEnabled={false}
        tabs={SEARCH_TABS}
        trackingData={{ object_id: props.id, object_type: 'Organization', directory_type: 'workforce-employees' }}
        orFilters
      />
      <InviteEmployeesModal
        totalEmployees={totalReturnedEmployees}
        organizationId={props.id}
      />
      <ExportOccupationsDialog
        className="c-directory-results-header__export"
        createExportArgs={{ organization_id: props.organization.id }}
        readExportArgs={{ organization_id: props.organization.id }}
        shouldClose={toggleDisplayExportOccupations}
        show={displayExportOccupations}
      />
    </EmployeeDirectoryContextProvider>
  );
};

EmployeeDirectory.propTypes = {
  id: PropTypes.string,
  baseUrl: PropTypes.string,
  organization: PropTypes.object,
  routeProps: PropTypes.object
};
