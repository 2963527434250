import React from 'react';
import PropTypes from 'prop-types';
import { useOnOff } from 'utils/react_utils';
import { array } from 'utils/array';
import { DataTable } from 'controls/data_table';
import Dialog from 'controls/dialog';
import { FormattedMessage } from 'react-intl';

import '../employees_upload_errors.sass';

/**
 * Errors display for employee upload (see upload_employees_form.jsx).
 *
 * @param {Object} props
 *   @param {Object} props.errorHash - `employee_import.error_hash`.
 *   @param {Array} props.rowErrors - Errors for individual rows of data.
 */
export const EmployeesUploadErrors = (props) => {
  const [dialogVisible, openDialog, closeDialog] = useOnOff();
  const rowErrors = props.rowErrors || [];
  let validationErrors = props.errorHash && props.errorHash.validation_errors;
  if (validationErrors) {
    validationErrors = array.flatten(
      props.errorHash.validation_errors.map((err) => {
        if (err.attribute_label) {
          return err.messages.map((m) => `${err.attribute_label}: ${m}`);
        }
        return err.messages;
      })
    );
  }

  const hasErrors =
    (validationErrors && validationErrors.length > 0) ||
    (rowErrors && rowErrors.length > 0);

  if (!hasErrors) {
    return <></>;
  }

  if (validationErrors && validationErrors.length) {
    return (
      <div className="employees-upload-errors employees-upload-errors__validation">
        <div className="employees-upload-errors__header">
          <FormattedMessage
            id="workforce.employees.add.upload.has_errors"
            defaultMessage="Your most recent upload failed due to the following errors"
          />
        </div>
        <div className="employees-upload-errors__errors-container">
          <div className="employees-upload-errors_errors-text">
            <FormattedMessage
              id="workforce.employees.add.upload.errors"
              defaultMessage="Errors:"
            />
          </div>
          <div className="employees-upload-errors_errors-items">
            {validationErrors.map((err, idx) => (
              <div key={idx}>{err}</div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="employees-upload-errors">
      <div className="employees-upload-errors__header">
        <FormattedMessage
          id="workforce.employees.add.upload.upload_failed"
          defaultMessage="Your most recent upload failed due to the following errors"
        />
      </div>
      <div className="employees-upload-errors__errors-container">
        <div className="employees-upload-errors_errors-text">
          <FormattedMessage
            id="workforce.employees.add.upload.errors"
            defaultMessage="Errors:"
          />
        </div>
        <div className="employees-upload-errors_errors-items">
          <FormattedMessage
            id="workforce.employees.add.upload.user_invalid"
            defaultMessage="User information is invalid"
          />
        </div>
        <a onClick={openDialog}>
          <FormattedMessage
            id="workforce.employees.add.upload.see_errors"
            defaultMessage="See errors"
          />
        </a>
      </div>
      <Dialog
        title={(
          <FormattedMessage
            id="workforce.employees.add.upload.validation_errors"
            defaultMessage="Validation Errors"
          />
        )}
        show={dialogVisible}
        shouldClose={closeDialog}
        size="full"
      >
        <Dialog.Content>
          <DataTable
            data={rowErrors}
            uiTweaks={[DataTable.ROW_BORDERS]}
            header={{}}
          >
            <DataTable.Col
              width="1"
              header={(
                <FormattedMessage
                  id="workforce.employees.add.upload.error_table.line"
                  defaultMessage="Line #"
                />
              )}
              renderer={(data) => data.line_number}
            />
            <DataTable.Col
              width="4"
              header={(
                <FormattedMessage
                  id="workforce.employees.add.upload.error_table.error"
                  defaultMessage="Error"
                />
              )}
              renderer={(data) =>
                data.message.replace(/Validation failed: /, '')
              }
            />
            <DataTable.Col
              width="2"
              header={(
                <FormattedMessage
                  id="workforce.employees.add.upload.error_table.user_id"
                  defaultMessage="User ID"
                />
              )}
              renderer={(data) => data.employee_user_id}
            />
            <DataTable.Col
              width="2"
              header={(
                <FormattedMessage
                  id="workforce.employees.add.upload.error_table.email"
                  defaultMessage="Email"
                />
              )}
              renderer={(data) => data.email}
            />
            <DataTable.Col
              width="2"
              header={(
                <FormattedMessage
                  id="workforce.employees.add.upload.error_table.first_name"
                  defaultMessage="First Name"
                />
              )}
              renderer={(data) => data.first_name}
            />
            <DataTable.Col
              width="1"
              header={(
                <FormattedMessage
                  id="workforce.employees.add.upload.error_table.last_name"
                  defaultMessage="Last Name"
                />
              )}
              renderer={(data) => data.last_name}
            />
          </DataTable>

          {rowErrors.length >= 50 && (
            <div className="employees-upload-errors__more">
              <FormattedMessage
                id="workforce.employees.add.upload.errors.too_many_errors"
                defaultMessage="There were too many errors. Please try again."
              />
            </div>
          )}
        </Dialog.Content>
        <Dialog.Footer>
          <Dialog.Action action={closeDialog}>
            <FormattedMessage
              id="workforce.employees.add.upload.errors.close"
              defaultMessage="Close"
            />
          </Dialog.Action>
        </Dialog.Footer>
      </Dialog>
    </div>
  );
};

EmployeesUploadErrors.propTypes = {
  errorHash: PropTypes.shape({
    validation_errors: PropTypes.arrayOf(
      PropTypes.shape({
        messages: PropTypes.arrayOf(PropTypes.string)
      })
    )
  }),
  rowErrors: PropTypes.arrayOf(
    PropTypes.shape({
      line_number: PropTypes.number,
      message: PropTypes.string,
      employee_user_id: PropTypes.string,
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  )
};
