import React from 'react';
import PropTypes from 'prop-types';
import { badgeExportDownloadUrl } from 'utils/routes';
import { badgeExportActions } from './badge_export_actions';
import { Export } from 'management/controls/export';

/**
 * A wrapper component that renders an Export component with props
 * that will create, poll for, and download badge exports.
 *
 * @param {object} props
 *   @param {string} props.className - Custom className that can be optionally given
 *   @param {object} props.createExportArgs - An object that represents what payload to be
 *   given when creating the badge export
 * @returns {React.element}
 */
export const BadgeExport = (props) => {
  const [createExportState, createExport] = badgeExportActions.useAction(
    'create'
  );
  const [readExportState, readExportAction] = badgeExportActions.useAction(
    'get'
  );

  return (
    <Export
      buttonType="primary"
      className={props.className}
      createExportData={createExportState.resources[0]}
      createExportStatus={createExportState.status}
      createExport={createExport}
      createExportArgs={props.createExportArgs}
      makeDownloadUrl={badgeExportDownloadUrl}
      readExportState={readExportState}
      readExportAction={readExportAction}
      readExportArgs={props.readExportArgs}
    />
  );
};

BadgeExport.propTypes = {
  className: PropTypes.string,
  createExportArgs: PropTypes.object,
  readExportArgs: PropTypes.object
};
