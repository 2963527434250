import React from 'react';
import { getRailsEnv } from 'app_utils';
import { ResourceStatusPropType } from 'utils/prop_types';
import './no_earner_results.sass';

/**
 * Render content when there are no cards.
 *
 * @param {Object} props
 *   @param {ResourceStatus} props.status
 */
export const NoEarnerResults = props => {
  return <div className="no-earner-results">
    No results found.
    {
      props.status.failed && getRailsEnv() === 'development' &&
      <div>
        Development error: Search failed.
        See elasticsearch.md for instructions on how to set up ElasticSearch correctly.
      </div>
    }
  </div>;
};

NoEarnerResults.propTypes = {
  status: ResourceStatusPropType.isRequired
};
