import React from 'react';
import { useShallowEquals } from 'utils/react_utils';
import { tracking } from 'app_utils/tracking';

/**
 * Hook for directory tracking. Tracks on mount, and returns specialized tracking functions.
 *
 * @param {{object_id: String, object_type: String}} trackingData - tracking data.
 * @returns {{
 *   trackSearchTermChanged: function(term: String, searchType: String),
 *   trackSelectSearchResult: function(result: String)
 * }}
 */
export const useDirectoryTracking = trackingData => {
  const trackingDataChanged = useShallowEquals(trackingData);

  React.useEffect(() => {
    if (trackingData.object_id) {
      tracking.track({ ...trackingData, type: 'directory.viewed' });
    }
  }, [trackingDataChanged]);

  /**
   * The current search term has changed.
   *
   * @param {String} term - The current search term.
   * @param {String} searchType - the search type.
   */
  const trackSearchTermChanged = React.useCallback((term, searchType) => {
    if (trackingData.object_id) {
      tracking.track({
        ...trackingData,
        type: 'directory.queried',
        search_type: searchType,
        search_term: term
      });
    }
  }, [trackingDataChanged]);

  /**
   * A search result has been selected.
   *
   * @param {{key: string, value: string}} result - Filter for the search result.
   */
  const trackSelectSearchResult = React.useCallback(result => {
    if (trackingData.object_id) {
      tracking.track({
        ...trackingData,
        type: 'directory.filtered',
        filter_type: result.key.replace(/_name/, ''),
        filter_term: result.value
      });
    }
  }, [trackingDataChanged]);

  return { trackSearchTermChanged, trackSelectSearchResult };
};
