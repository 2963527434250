import React from 'react';
import PropTypes from 'prop-types';
import { recentlyViewedOrganizationsActions } from 'management/organizations/sidebar/organization_modal_selector/action_creators';
import { OrganizationSelectorCard } from 'management/organizations/sidebar/organization_modal_selector/organization_selector_card';

/**
 * A higher order component that uses the OrganizationSelectorCard to renders a card visual
 * to enable a user to either navigate recently viewed organization or view its profile.
 * Passes in an onClose callback function to OrganizationSelectorCard to delete a recently viewed
 * organization.
 *
 * @property {object} recentlyViewedOrganization - An object representing the recently viewed organization
 */
export const RecentlyViewedOrganizationCard = (props) => {
  const [_v, deleteRecentlyViewedOrganizationAction] =
    recentlyViewedOrganizationsActions.useAction(
      'deleteRecentlyViewedOrganization', props.recentlyViewedOrganization.id
    );
  const handleClose = () => {
    deleteRecentlyViewedOrganizationAction({ id: props.recentlyViewedOrganization.id });
  };
  return (
    <OrganizationSelectorCard
      className={props.className}
      fixedWidth
      organization={props.recentlyViewedOrganization.organization}
      onClose={handleClose}
    />
  );
};

RecentlyViewedOrganizationCard.propTypes = {
  className: PropTypes.string,
  recentlyViewedOrganization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    organization: PropTypes.object.isRequired
  })
};
