import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ResourceStatusPropType } from 'utils/prop_types';
import { QueryString } from 'utils/query_string';
import { trySetLinkSource } from 'app_utils/tracking';
import { standardInitialSort, useStandardSortHandler } from 'controls/data_table/use_standard_sort_handler';
import { useQueryString } from 'controls/with_query_string';
import {
  useFaethmOccupationMappingEnabled
} from 'pages/workforce/faethm_occupation_mapping_hooks';
import { EmployeesEmptySearch } from 'pages/workforce/employees/employees_empty_search';
import { EmployeePersonalInfo } from 'pages/workforce/employees/employees/list';
import { OrgMgmtDataTable } from 'pages/workforce/organizations';
import { EmployeeEarnerPropType, makeFilteredUrl } from 'pages/public/organization/earner_directory';
import { InvitationStatusIndicator } from 'pages/workforce/components/invitation_status_indicator';

import './employee_table.sass';

/**
 * A table view of the directory earner cards. For a cards view, use EarnerCards instead.
 *
 * @param {Object} props
 *   @param {Array} props.data - Resource array for showing employee directory results
 *   @param {ResourceStatus} props.status - Redux status for the data request.
 *   @param {QueryString} props.filters - Directory filters.
 *   @param {Object} props.routeProps - Contains the properties of the current route
 */
export const EmployeeTable = props => {
  const intl = useIntl();
  const history = useHistory();
  const queryString = useQueryString(props.routeProps.location);
  const sortHandler = useStandardSortHandler();
  const occupationMappingEnabled = useFaethmOccupationMappingEnabled();
  const currentSort = standardInitialSort(queryString.get('sort')) || { key: 'sortable_name', ascending: true };
  const onRowClick = useCallback((data) => {
    trySetLinkSource('employee_directory');
    const url = makeFilteredUrl(props.filters, data.url, 'employee_directory');
    history.push(url);
  }, [props.filters]);

  const columnCommonProps = {
    headerClassName: "c-employees-employees-table__detail-column-header",
    className: "c-employees-employees-table__detail-column"
  };

  return (
    <div>
      <OrgMgmtDataTable
        data={props.data}
        loader={{ loading: props.status.pending }}
        sort={{ onSort: sortHandler, ...currentSort }}
        keyboardControl
        rowClassName="c-employees-employees-table__row"
        onRowClick={onRowClick}
        hoverStyle="default"
        emptyResultNode={EmployeesEmptySearch}
      >
        <OrgMgmtDataTable.Col
          {...columnCommonProps}
          width={occupationMappingEnabled ? 'col-xl-3 col-md-4 col-sm-6' : 'col-xl-6 col-md-8 col-sm-6'}
          header={intl.formatMessage({
            id: 'workforce.employees.employees.employee_table.employee',
            defaultMessage: 'Employee'
          })}
          sortKey="sortable_name"
          renderer={(employee) => (
            <EmployeePersonalInfo employee={employee}/>
          )}
        />
        {!!occupationMappingEnabled &&
          <OrgMgmtDataTable.Col
            {...columnCommonProps}
            width="col-xl-3 col-lg-5 col-md-4"
            hideAt={['mobile']}
            header={intl.formatMessage({
              id: 'workforce.employees.employees.employee_table.occupation',
              defaultMessage: 'Occupation'
            })}
            sortKey="employment_history.faethm_occupation_title"
            renderer={(employee) => employee.occupation_title}
          />
        }
        <OrgMgmtDataTable.Col
          {...columnCommonProps}
          width="col-xl-1"
          hideAt={['mobile', 'tablet', 'large']}
          header={intl.formatMessage({
            id: 'workforce.employees.employees.employee_table.skills',
            defaultMessage: 'Skills'
          })}
          sortKey="total_skills_count"
          renderer={(data) => data.total_skills_count > 0 ? data.total_skills_count.toLocaleString() : null}
        />
        <OrgMgmtDataTable.Col
          {...columnCommonProps}
          width="col-xl-3"
          hideAt={['mobile', 'tablet', 'large']}
          header={intl.formatMessage({
            id: 'workforce.employees.employees.employee_table.last_badge_accepted',
            defaultMessage: 'Last Badge Accepted'
          })}
          sortKey="last_credential_date"
          renderer={(data) => {
            if (data.last_credential_date) {
              return (
                <FormattedDate
                  value={data.last_credential_date}
                  year="numeric"
                  month="short"
                  day="numeric"
                />
              );
            }
          }}
        />
        <OrgMgmtDataTable.Col
          {...columnCommonProps}
          width="col-xl-2 col-lg-3 col-sm-6"
          hideAt={['mobile']}
          header={intl.formatMessage({
            id: 'workforce.employees.employees.employee_table.invitation_status',
            defaultMessage: 'Invitation Status'
          })}
          sortKey="invitation_status"
          renderer={(data) => {
            return (
              <InvitationStatusIndicator status={data.invitation_status} />
            );
          }}
        />
      </OrgMgmtDataTable>
    </div>
  );
};

EmployeeTable.propTypes = {
  data: PropTypes.arrayOf(EmployeeEarnerPropType).isRequired,
  filters: PropTypes.instanceOf(QueryString),
  status: ResourceStatusPropType.isRequired,
  routeProps: PropTypes.object
};
