import React from 'react';
import { useHistory } from 'react-router';
import Pagination from 'controls/pagination';
import { filter } from '../earner_directory_actions';
import PropTypes from "prop-types";

/**
 * Component renders pagination for Directory results.
 */
export const DirectoryPagination = (props) => {
  const [filterState] = props.filterAction.useAction();
  const history = useHistory();
  const meta = filterState.metadata;
  return (
    <Pagination
      centered={false}
      pageNumbers={false}
      data={meta}
      baseUrl={history.location.pathname}
      search={history.location.search}
    />
  );
};

DirectoryPagination.propTypes = {
  filterAction: PropTypes.object
};

DirectoryPagination.defaultProps = {
  filterAction: filter
};
