import React, { Component, PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { makeClassName, uniqueId } from 'utils';
import withWindowBreakpoints, { WithWindowBreakpointsPropTypes } from './with_window_breakpoints';
import Icon from 'controls/icon';
import { FormattedMessage, injectIntl } from "react-intl";
import { intlKeyFromValue } from "translations";

import './tabs.sass';

const MOBILE_BREAKPOINTS = { xs: 1, sm: 1 };

/**
 * Tabs component. All children are expected to be Tabs.Tab nodes.
 *
 * Accessibility based on https://simplyaccessible.com/article/danger-aria-tabs/
 *
 * @property {function(name: String)} onBeforeChange - Called when the tab is going to change.
 * @property {function(name: String)} onChange - Called when the tab changes. The "name" property
 *   of the tab is the parameter.
 * @property {String} current - The current tab. If this is specified, the component is controlled
 *   by the container. Set it to an empty string to show the first available tab.
 * @property {Boolean} openFirst - Open the first tab on mount. Ignored if "current" is set.
 * @property {String} className - An additional class name.
 * @property {String} rowClassName - Apply to each row of the view. If Bootstrap columns are being
 *   used within the tabs, set this to 'row' to get the proper layout.
 * @property {Boolean} hideLabelsOnDesktop - Don't show labels in the desktop view.
 * @property {String} [theme=tab] - Standard tab types that appear in the UI. One of:
 *   tab: Tabs are small and gray, displaying an icon and optionally a label.
 *   text: Tabs are labels, with the selected tab underlined.
 */
class Tabs extends Component {
  constructor(props) {
    super(props);
    this.m_baseId = 'tabs-' + uniqueId();
    this.m_changed = false; // Was the tab ever changed?

    this.state = {
      current: null,
      mobileOpen: false
    };
  }


  /**
   * Create a tab id.
   *
   * @param {String} name
   * @returns {*}
   */
  getTabId(name) {
    return this.m_baseId + name;
  }

  /**
   * Returns true if the current view is considered to be mobile form factor.
   *
   * @returns {boolean}
   */
  isMobile() {
    // Mobile view is disabled for "text" mode (or at least the behavior is different)
    return !!MOBILE_BREAKPOINTS[this.props.windowWidthBreakpoint] && this.props.theme !== 'text';
  }


  /**
   * Change the current tab.
   *
   * @param {String} name - The tab name that was clicked.
   */
  onTabClick(name) {
    this.m_changed = true;
    this.props.onBeforeChange && this.props.onBeforeChange(name);
    this.setState(state => ({
      current: name,
      mobileOpen: this.isMobile() ? !state.mobileOpen : false
    }), () => this.props.onChange && this.props.onChange(name));
  }


  /**
   * Render the component.
   * @returns {React.element}
   */
  render() {
    const props = this.props;
    const tabs = [];
    let currentTab = '';
    let isFirst = true;
    const isMobileWidth = this.isMobile();
    const controlled = 'current' in props;
    const current = controlled ? props.current : this.state.current;
    // Auto-open the first tab if:
    // 1) The openFirst property is set.
    // 2) props.current is set to an empty string.
    // 2) We're in mobile mode. The mobile UI doesn't work without an active tab.
    const openFirst = !current && (props.openFirst || controlled || isMobileWidth);
    let currentContent = '';

    // Twist the DOM around a little, to keep the <Tabs> markup clean. Pull the tab content out of
    // the tab node, and handle clicks in this component, instead of in Tabs.Tab.
    React.Children.forEach(props.children, child => {
      if (child) {
        const cprops = child.props;
        const isCurrent = (cprops.name == current) || (openFirst && isFirst);
        const name = cprops.name;
        const id = this.getTabId(name);

        const tabProps = {
          key: 'tab.' + name,
          _onClick: () => this.onTabClick(name),
          _current: isCurrent,
          _id: id,
          _hideLabel: props.hideLabelsOnDesktop && !isMobileWidth
        };

        const tab = React.cloneElement(child, tabProps);

        tabs.push(tab);

        if (isCurrent) {
          currentTab = tab;

          currentContent = (
            <div className={cprops.panelClassName}>
              <_TabPanel
                name={name}
                current={isCurrent}
                tabId={id}
                key={'panel.' + name}
                focus={this.m_changed}
              >
                {cprops.children}
              </_TabPanel>
            </div>
          );
        }

        isFirst = false;
      }
    });

    const panelsClassName = makeClassName('cr-tabs__panels', props.panelsClassName);
    const panels = <div className={panelsClassName}>{currentContent}</div>;
    const theme = props.theme === 'text' ? 'cr-tabs--underline-text' : null;

    return (
      <div className={makeClassName('cr-tabs', props.className, theme)}>
        {
          isMobileWidth && tabs.length > 1 && (
            <FontAwesomeIcon icon={faChevronDown} className="cr-tabs__mobile-down-icon" />)
        }

        <ul className={isMobileWidth ? 'cr-tabs__mobile' : undefined}>
          {!isMobileWidth || this.state.mobileOpen || !currentTab ? tabs : currentTab}
        </ul>

        {panels}
      </div>);
  }
}

// eslint-disable-next-line no-class-assign
Tabs = withWindowBreakpoints(Tabs);
export default Tabs;

Tabs.propTypes = {
  onChange: PropTypes.func,
  onBeforeChange: PropTypes.func,
  openFirst: PropTypes.bool,
  className: PropTypes.string,
  panelsClassName: PropTypes.string,
  hideLabelsOnDesktop: PropTypes.bool,
  current: PropTypes.string,
  theme: PropTypes.oneOf(['tab', 'text']),
  // from withWindowBreakpoints
  ...WithWindowBreakpointsPropTypes
};

Tabs.defaultProps = {
  theme: 'tab'
};


/**
 * A tab.
 *
 * See https://simplyaccessible.com/article/danger-aria-tabs/ for why this doesn't use tab roles
 * for accessibility.
 *
 * @type {Tabs.Tab}
 * @property {String} name - The name of the tab. This is passed to the onChange() property of Tabs.
 * @property {String} className - An extra class name for this tab.
 * @property {String} label - A text label, which appears after the icon (if there is one).
 * @property {String} panelClassName - An extra class name to add to the panel.
 * @property {Object} icon - A FontAwesome icon, a React constructor, or a source string.
 * @property {String} iconClassName - A class name for the icon.
 * @property {Boolean} noReplaceUrl - If true, the browser's back button will return to the previously selected tab.
 *   If false, the back button will go to the previous page.
 */
class Tab extends PureComponent {
  /**
   * Handle click events.
   *
   * @param {Event} e
   */
  onClick = e => {
    if (!this.props.url) {
      e.preventDefault();
    }
    this.props._onClick();
  };

  /**
   * Render the component.
   *
   * @returns {React.element}
   */
  render() {
    const props = this.props;

    const tabView = (
      <Fragment>
        {
          this.props.icon && (
            <Icon
              image={this.props.icon}
              className="cr-tab__icon-container"
              imageClassName={this.props.iconClassName}
            />)
        }
        {
          props.label && !props._hideLabel && (
            <span className="cr-tab__label">
              <FormattedMessage
                id={intlKeyFromValue(props.label, "badge.view.share")}
                defaultMessage={props.label}
              />
            </span>)
        }
      </Fragment>);

    const linkProps = {
      'aria-label': props.label,
      onClick: this.onClick,
      className: "cr-tab__link",
      id: props._id,
      title: props.intl.formatMessage({
        id: intlKeyFromValue(props.label, "badge.view.share"),
        defaultMessage: props.label
      })
    };
    if (props._current) {
      linkProps['aria-current'] = 'tab';
    }

    return (
      <li className={makeClassName('cr-tab', props._current && 'cr-tab--current', props.className)}>
        {
          props.url ? (
            <Link to={props.url} replace={!this.props.noReplaceUrl} {...linkProps}>
              {tabView}
            </Link>
          ) : (
            <span {...linkProps}>
              {tabView}
            </span>
          )
        }
      </li >);
  }
};

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  panelClassName: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
  iconClassName: PropTypes.string,
  url: PropTypes.string,
  _onClick: PropTypes.func,
  noReplaceUrl: PropTypes.bool,
  // Properties defined in Tabs.
  _current: PropTypes.bool,
  _id: PropTypes.string,
  _hideLabel: PropTypes.bool,
  intl: PropTypes.object
};

Tabs.Tab = injectIntl(Tab);

/**
 * Tab panel content. This renders the children of Tabs.Tab.
 *
 * @property {Boolean} current - Is this the current tab?
 * @property {String} tabId - A reference to the associated Tabs.Tab object.
 * @property {String} className - An extra class name, from Tabs.Tab.panelClassName.
 * @property {Boolean} focus - Focus the content immediately. This is desired when switching tabs.
 * @private
 */
class _TabPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.m_focusCatcher = React.createRef();
    this.m_me = React.createRef();
  }


  componentDidMount() {
    this.props.focus && this.focus();
  }


  /**
   * On tab click, focus the first input. If none exists, focus the first child, so that keyboard
   * users don't have to tab through all the tabs before they can get to the new content.
   *
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.current && this.props.current) {
      this.focus();
    }
  }


  /**
   * On tab click, focus a hidden element so that tabbing works as expected.
   */
  focus() {
    this.m_focusCatcher.current.tabIndex = '-1';
    this.m_focusCatcher.current.focus();
  }


  /**
   * Render the component.
   *
   * @returns {React.element|String}
   */
  render() {
    if (this.props.current) {
      return (
        <div
          className={makeClassName('cr-tabpanel', this.props.className)}
          aria-labelledby={this.props.tabId}
          ref={this.m_me}
        >
          <span ref={this.m_focusCatcher} className="cr-tab__focus-catcher" />
          {this.props.children}
        </div>);
    }
    return '';
  }
}
_TabPanel.propTypes = {
  current: PropTypes.bool,
  tabId: PropTypes.string.isRequired,
  className: PropTypes.string,
  focus: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export const TabPanelTestable = _TabPanel;
