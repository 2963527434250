import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { SimpleButton } from 'controls/button';
import { usePermissions, Permissions } from 'management/organizations/permissions';
import { SubscriptionsRouteHelpers } from 'pages/workforce/route_helpers';

import './empty_subscriptions.sass';

export const EmptySubscriptions = ({ baseUrl }) => {
  const history = useHistory();
  const intl = useIntl();
  const [hasPermissions] = usePermissions();
  const isPlatformManager = !!hasPermissions(Permissions.PLATFORM_MANAGER);
  const notSubscribed = intl.formatMessage({
    id: 'workforce.accounts.subscriptions.empty_subscription.not_subscribed',
    defaultMessage: 'You are not currently subscribed to Workforce'
  });
  const setupText = intl.formatMessage({
    id: 'workforce.accounts.subscriptions.empty_subscription.setup_subscription',
    defaultMessage: 'Setup Subscription'
  });
  const newClicked = useCallback(() => {
    history.push(
      SubscriptionsRouteHelpers.forUrl(baseUrl).new.route()
    );
  }, [history, baseUrl]);

  return (
    <div className="c-subscription-personal-info container-fluid">
      <div className="row">
        <div className="c-subscription-personal-info__name-location col-md-6 col-sm-12">
          {isPlatformManager && (
            <div title={setupText} className="c-subscription-personal-info__name">
              <span className="c-subscription-personal-info__name__text">
                {setupText}
              </span>
            </div>
          )}
          <div title={notSubscribed} className="c-subscription-personal-info__location">
            <span className="c-subscription-personal-info__location__text">
              {notSubscribed}
            </span>
          </div>
        </div>
        {isPlatformManager && (
          <div className="c-subscription-button-container col-md-6 col-sm-12">
            <SimpleButton
              onClick={newClicked}
            >
              <FormattedMessage
                id="workforce.accounts.subscriptions.empty_subscription.new_subscription"
                defaultMessage="New Subscription"
              />
            </SimpleButton>
          </div>
        )}
      </div>
    </div>
  );
};

EmptySubscriptions.propTypes = {
  baseUrl: PropTypes.string.isRequired
};
