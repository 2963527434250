import React, { useCallback } from 'react';
import { uuidPropType } from 'prop_types';
import { WithSectionHeader } from 'controls/section_header';
import { AnalyticsSectionHeader } from './analytics_section_header';
import { Dashboard } from './dashboard';

/**
 * Renders the layout for the Analytics section of Workforce.
 *
 * @param {object} props
 *   @param {string} props.organizationId - the ID of the current Workforce organization context
 * @returns {JSX.Element}
 * @constructor
 */
export const AnalyticsLayout = (props) => {
  const sectionHeader = useCallback(() => {
    return <AnalyticsSectionHeader/>;
  }, []);

  return (
    <WithSectionHeader sectionHeader={sectionHeader}>
      <Dashboard organizationId={props.organizationId}/>
    </WithSectionHeader>
  );
};

AnalyticsLayout.propTypes = {
  organizationId: uuidPropType
};
