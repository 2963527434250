import { lazyWithRetry } from 'app_utils/lazy_load';

export const CredlyRecruit = lazyWithRetry(() =>
  import(/* webpackChunkName: "public-organization" */ './credly_recruit')
    .then(module => ({ default: module.CredlyRecruit }))
);

export const CredlyTalentMatch = lazyWithRetry(() =>
  import(/* webpackChunkName: "public-organization" */ './credly_talent_match')
    .then(module => ({ default: module.CredlyTalentMatch }))
);

export const JobRequisition = lazyWithRetry(() =>
  import(/* webpackChunkName: "public-organization" */ './job_requisition')
    .then(module => ({ default: module.JobRequisition }))
);

export const CredlyTalentMatchFeedbackPlaceholder = lazyWithRetry(() =>
  import(/* webpackChunkName: "public-organization" */ './credly_talent_match_feedback_modal')
    .then(module => ({ default: module.CredlyTalentMatchFeedbackPlaceholder }))
);

export const JobRequisitionForm = lazyWithRetry(() =>
  import(/* webpackChunkName: "public-organization" */ './job_requisition_form')
    .then(module => ({ default: module.JobRequisitionForm }))
);

export const CredlyTalentMatchMatchedCandidate = lazyWithRetry(() =>
  import(/* webpackChunkName: "public-organization" */ './credly_talent_match_matched_candidate')
    .then(module => ({ default: module.CredlyTalentMatchMatchedCandidate }))
);

export { Routes, TalentMatchRoutes } from './routes';
