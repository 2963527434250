import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useFormProps, Form } from 'form';
import Dialog from 'controls/dialog';
import { savedDirectorySearchActionManager } from 'pages/public/organization/earner_directory/saved_directory_searches/actions';
import {
  savedDirectorySearchSearchParams,
  SavedDirectorySearchRouteHelpers
} from 'pages/public/organization/earner_directory/saved_directory_searches/utils';

/**
 * Renders a dialog to create a saved directory search.
 * @param {Object} props
 *   @param {String} props.filters - Filter information from advanced search
 *   to save to the search
 *   @param {String} props.facets - Facet information from advanced search
 *   to save to the search
 *   @param {boolean} [props.show] - Whether or not to display the dialog
 *   @param {function} props.shouldClose - Callback triggered when the dialog should be closed.
 */
export const CreateSavedDirectorySearchDialog = props => {
  const searchParams = savedDirectorySearchSearchParams(props.filters, props.facets);
  const [formProps, state] = useFormProps(
    savedDirectorySearchActionManager.get('create'),
    { initialValues: { search_params: searchParams } }
  );
  const history = useHistory();
  const handleSuccessfulSubmit = () => {
    const id = state.resources[0].id;
    history.push(
      SavedDirectorySearchRouteHelpers.showSavedDirectorySearch.parameterize({ id })
    );
  };
  return (
    <Dialog
      title="Save Search"
      size="half"
      show={props.show}
      shouldClose={props.shouldClose}
    >
      <Dialog.Content>
        <Form
          {...formProps}
          onSubmitSuccess={handleSuccessfulSubmit}
          submitSuccessAlert={{ type: 'notice', text: 'Search Saved' }}
        >
          {({ propsForFieldGroup, isSubmitting, submit }) => (
            <>
              <Form.TextField
                ignoreDefaultValidations
                enclosed
                required
                type="text"
                label="Search Name"
                {...propsForFieldGroup("name")}
              />
              <Dialog.Footer>
                <Dialog.Action action={submit} loading={isSubmitting} noClose>
                  Apply
                </Dialog.Action>
              </Dialog.Footer>
            </>
          )}
        </Form>
      </Dialog.Content>
    </Dialog>
  );
};

CreateSavedDirectorySearchDialog.propTypes = {
  filters: PropTypes.object.isRequired,
  facets: PropTypes.object.isRequired,
  show: PropTypes.bool,
  shouldClose: PropTypes.func.isRequired
};
