import React from 'react';
import PropTypes from 'prop-types';
import { uuidPropType } from 'prop_types';
import { Form, useFormProps } from 'form';
import { SimpleButton } from 'controls/button';
import { savedDirectorySearchActionManager } from './actions';
import { savedDirectorySearchSearchParams } from './utils';
import { SearchParamsFormValueTracker } from './search_params_form_value_tracker';

/**
 * Renders a button that tracks in filters and facets from props and saves them
 * to an existing saved directory search.
 * @param {Object} props
 *   @param {uuidPropType} props.id - Id of the saved directory search to update
 *   @param {String} props.filters - Filter information from advanced search
 *   to save to the search
 *   @param {String} props.facets - Facet information from advanced search
 *   to save to the search
 */
export const UpdateSavedDirectorySearchButton = props => {
  const searchParams = savedDirectorySearchSearchParams(props.filters, props.facets);
  const [editSavedDirectorySearchFormProps] = useFormProps(
    savedDirectorySearchActionManager.get(
      'update', props.id
    ),
    { initialValues: { searchParams: searchParams } }
  );
  return (
    <div>
      <Form
        {...editSavedDirectorySearchFormProps}
        submitSuccessAlert={{ type: 'notice', text: 'Your search was updated successfully.' }}
      >
        {({ propsForFieldGroup, isSubmitting, touched }) => (
          <>
            <SearchParamsFormValueTracker
              value={searchParams}
              onChange={propsForFieldGroup('search_params').handleChange}
            />
            <SimpleButton
              type="secondary"
              isSubmit
              disabled={!touched.search_params}
              loading={isSubmitting}
            >
              Save Changes
            </SimpleButton>
          </>
        )}
      </Form>
    </div>
  );
};

UpdateSavedDirectorySearchButton.propTypes = {
  id: uuidPropType,
  filters: PropTypes.object.isRequired,
  facets: PropTypes.object.isRequired
};
