import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import EmptySearch from 'images/employees_empty_search.svg';

import './employees_empty_search.sass';

/**
 * Renders the Empty placeholder image.
 */

export const EmployeesEmptySearch = (props) => {
  const messageToDisplay = () => {
    if (props?.message) {
      return (
        <p className="c-employees_empty-search-l2__text">
          {props.message}
        </p>
      );
    } else {
      return (
        <p className="c-employees_empty-search__text">
          <FormattedMessage
            id="workforce.employees.empty_search"
            defaultMessage="No data found"
          />
        </p>
      );
    }
  };

  return (
    <div className="c-employees_empty-search">
      <img
        className="c-employees_empty-search__image"
        src={EmptySearch}
        alt=""
      />
      {messageToDisplay()}
    </div>
  );
};

EmployeesEmptySearch.propTypes = {
  message: PropTypes.node
};
