import React from 'react';
import PropTypes from 'prop-types';
import { directoryExportDownloadUrl } from 'utils/routes';
import { directoryExportActions } from 'pages/public/organization/earner_directory/directory_export_actions';
import { ExportDialog } from 'management/controls/export';


/**
 * A wrapper component that renders an Export component with props
 * that will create, poll for, and download directory exports.
 *
 * @param {object} props
 *   @param {string} props.className - Custom className that can be optionally given
 *   @param {object} props.createExportArgs - An object that represents what payload to be
 *   given when creating the directory export
 *   @param {boolean} [props.show] - Whether or not to display the dialog
 *   @param {function} props.shouldClose - Callback triggered when the dialog should be closed.
 *
 * @returns {React.element}
 */
export const DirectoryExport = (props) => {
  const [createExportState, createExport] = directoryExportActions.useAction(
    'create'
  );
  const [readExportState, readExportAction] = directoryExportActions.useAction(
    'get'
  );

  return (
    <ExportDialog
      className={props.className}
      createExportData={createExportState.resources[0]}
      createExportStatus={createExportState.status}
      createExport={createExport}
      createExportArgs={props.createExportArgs}
      makeDownloadUrl={directoryExportDownloadUrl}
      readExportState={readExportState}
      readExportAction={readExportAction}
      readExportArgs={props.readExportArgs}
      show={props.show}
      shouldClose={props.shouldClose}
    />
  );
};

DirectoryExport.propTypes = {
  className: PropTypes.string,
  createExportArgs: PropTypes.object,
  readExportArgs: PropTypes.object,
  shouldClose: PropTypes.func,
  show: PropTypes.bool
};
