import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CredlyLogo from 'images/credly-logo-white.svg';
import { RoleButton } from 'aria/role_button';
import CollapsibleSection from 'controls/collapsible_section';
import { AccordionHeading } from 'controls/accordion';
import Dialog from 'controls/dialog';
import { SizedImage } from 'controls/sized_image';
import { FormattedMessage } from 'react-intl';
import { useWidthBreakpoints } from 'utils/use_width_breakpoints';
import { makeClassName, uniqueId } from 'utils';
import './permissions_request.sass';

/**
 * PermissionsRequest top-level component. Virtual DOM structure should be of the form:
 * <PermissionsRequest>
 *   <PermissionsRequest.Header/>
 *   <PermissionsRequest.Items>
 *     <PermissionsRequest.Items.Item/>
 *   </PermissionsRequest.Items>
 *   <PermissionsRequest.Actions>
 *   </PermissionsRequest.Actions>
 * </PermissionsRequest>
 *
 * This component should only be used by PermissionsManager.
 */
export const PermissionsRequest = props =>
  <Dialog size="auto" show hideCloseButton>
    <Dialog.Content contentPadTheme="withoutMarginBottom">
      <div className="permissions-request">
        {props.children}
      </div>
    </Dialog.Content>
  </Dialog>;
PermissionsRequest.propTypes = {
  children: PropTypes.node
};

/**
 * Header section for permissions requests.
 *
 * @param {Object} props
 *   @param {String} props.requesterImage - The image URL for the requester.
 *   @param {String} props.requesterName - The name of the requester.
 */
export const PermissionsRequestHeader = props => (
  <div className="permissions-request-header">
    <div className="permissions-request-header__connection-logos">
      <SizedImage
        className="permissions-request__avatar-requester"
        alt={props.requesterName}
        aria-label={props.requesterName}
        src={props.requesterImage}
        height={64}
        width={64}
      />
      <FontAwesomeIcon icon={faPlus} className="permissions-request__avatar-plus"/>
      <img src={CredlyLogo} height={64} width={64}/>
    </div>
    <div className="permissions-request-header__headings">
      <p className="permissions-request-header__primary-head">
        {props.heading}
      </p>
      <p className="permissions-request-header__sub-head">
        {props.subHeading}
      </p>
    </div>
  </div>
);
PermissionsRequestHeader.propTypes = {
  requesterImage: PropTypes.string,
  requesterName: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string
};

/**
 * Buttons for permissions requests. By default renders a reject and connect button
 * that will take in accept reject actions and statuses. Can accept an optional
 * onSkip prop that will render a "Skip" button in place of a Reject button
 * that will more simply invoke the onSkip as a callback when clicked.
 *
 */
export const PermissionsRequestActions = props => {
  const breakpoint = useWidthBreakpoints();
  const dialogActions = React.useMemo(() => {
    const actions = [
      props.onSkip ? {
        text: 'Skip',
        type: 'tertiary',
        action: props.onSkip,
        disabled: props.acceptRejectStatus.pending
      } : {
        text: 'Reject',
        type: 'secondary',
        action: props.onClickReject,
        loading: props.rejectAction.status.pending,
        disabled: props.acceptRejectStatus.pending
      },
      {
        text: 'Connect',
        action: props.onClickAccept,
        loading: props.acceptAction.status.pending,
        disabled: props.acceptRejectStatus.pending
      }
    ];
    // This logic is to re-order the Connect and Skip/Reject buttons in smaller viewports
    // without making modifications to the dialog footer.
    if (['xs', 'sm'].includes(breakpoint)) {
      return actions.reverse();
    }
    return actions;
  }, [props.onSkip,
    props.onClickAccept,
    props.onClickReject,
    props.acceptAction.status,
    props.acceptRejectStatus,
    breakpoint]);

  return (
    <Dialog.Footer confirmation>
      {dialogActions.map((dialogAction, index) =>
        <Dialog.Action
          key={`${dialogAction.text}-${index}`}
          {...dialogAction}
        >
          {dialogAction.text}
        </Dialog.Action>)}
    </Dialog.Footer>);
};
PermissionsRequestActions.propTypes = {
  onSkip: PropTypes.func,
  onClickReject: PropTypes.func,
  rejectAction: PropTypes.object,
  acceptRejectStatus: PropTypes.object.isRequired,
  onClickAccept: PropTypes.func.isRequired,
  acceptAction: PropTypes.object.isRequired
};

/**
 * List of items for permissions requests.
 */
export const PermissionsRequestItems = props =>
  <div className="permissions-request__items">
    {props.children}
  </div>;
PermissionsRequestItems.propTypes = {
  children: PropTypes.node,
  requesterName: PropTypes.string.isRequired
};

/**
 * Separator between items and actions for permissions request
 */
export const PermissionsRequestSeparator = props =>
  <hr className="permissions-request__separator" />;

/**
 * Child of PermissionsRequestItems, representing a single text node.
 *
 * @param {Object} props
 *   @param {String} props.id - The permissions request id.
 *   @param {String} [props.icon] - Icon for the text item, if any.
 *   @param {String} props.text - Text content.
 */
const PermissionsRequestItem = props =>
  <div className="permissions-request__item">
    <div className="permissions-request__item-image">
      {props.icon && <FontAwesomeIcon icon={props.icon}/>}
    </div>
    <div className="permissions-request__item-text">
      {props.text}
    </div>
  </div>;

PermissionsRequestItem.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired
};

const PermissionsRequestAccordionItem = props => {
  const onClick = React.useCallback(() => {
    props.onClick && props.onClick(props.id);
  }, [props.id, props.onClick]);
  const accordionId = useMemo(() => uniqueId().toString(), []);
  const expandedId = useMemo(() => uniqueId().toString(), []);
  return (
    <div className="permissions-request__accordion-item">
      <RoleButton
        onClick={onClick}
        tagName="div"
        id={accordionId}
        aria-controls={expandedId}
      >
        <AccordionHeading expanded={props.expanded}>
          <div className="permissions-request__accordion-section-header">
            <FormattedMessage {...props.sectionHeader} />
            <div className="permissions-request__accordion-item-sub-header">
              <div className="permissions-request__accordion-item-image">
                <FontAwesomeIcon
                  icon={props.expanded ? props.sectionIconOpen : props.sectionIconClosed}
                />
              </div>
              <div
                className={makeClassName("permissions-request__accordion-item-text",
                  props.expanded && "permissions-request__accordion-item-text-expanded")}
              >
                <FormattedMessage {...props.sectionOverview} />
              </div>
            </div>
          </div>
        </AccordionHeading>
      </RoleButton>
      <CollapsibleSection
        id={expandedId}
        tagName="div"
        role="region"
        collapsed={!props.expanded}
        aria-expanded={!props.expanded}
        aria-labelledby={accordionId}
        allowOverflow={props.expanded}
      >
        <div className="permissions-request__accordion-item-section-body">
          <ul className="permissions-request__accordion-section-item-list">
            {props.sectionItems.map((item, index) =>
              <li key={index}>
                <FormattedMessage {...item} />
              </li>)}
          </ul>
        </div>
      </CollapsibleSection>
    </div>);
};

PermissionsRequestAccordionItem.propTypes = {
  id: PropTypes.string,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  sectionHeader: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
    values: PropTypes.object
  }).isRequired,
  sectionOverview: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired
  }).isRequired,
  sectionIconClosed: PropTypes.object,
  sectionIconOpen: PropTypes.object,
  sectionItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string
  })).isRequired
};

const PermissionsRequestAccordionGroup = props => {
  const [expandedItem, setExpandedItem] = useState(-1);
  const expandedCallback = useCallback((index) => {
    const expandedIndex = expandedItem === index ? -1 : index;
    setExpandedItem(expandedIndex);
  }, [expandedItem]);

  return (
    <>
      {props.permissions.map((permission, index) =>
        <PermissionsRequestAccordionItem
          key={index}
          id={index}
          expanded={expandedItem === index}
          onClick={expandedCallback}
          sectionIconOpen={permission.sectionIconOpen}
          sectionIconClosed={permission.sectionIconClosed}
          sectionHeader={permission.sectionHeader}
          sectionOverview={permission.sectionOverview}
          sectionItems={permission.sectionItems}
        />
      )}
    </>
  );
};

PermissionsRequestAccordionGroup.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.shape(PermissionsRequestAccordionItem.propTypes))
};

PermissionsRequest.Header = PermissionsRequestHeader;
PermissionsRequest.Items = PermissionsRequestItems;
PermissionsRequest.Items.Item = PermissionsRequestItem;
PermissionsRequest.Items.AccordionItem = PermissionsRequestAccordionItem;
PermissionsRequest.Items.AccordionGroup = PermissionsRequestAccordionGroup;
PermissionsRequest.Separator = PermissionsRequestSeparator;
PermissionsRequest.Actions = PermissionsRequestActions;
