import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RoleButton } from 'aria/role_button';
import { Form, useFormProps } from 'form';
import { Action } from 'utils/action';

import './subscription_form.sass';

export const SubscriptionForm = (props) => {
  const { initialValues, staticParams, submitAction, onSubmitSuccess } = props;
  const intl = useIntl();
  const history = useHistory();
  const [formProps] = useFormProps(
    submitAction,
    {
      initialValues: initialValues,
      staticParams: staticParams
    }
  );

  const autoRenewOptions = [
    {
      selectionValue: true,
      displayValue: intl.formatMessage({
        id: 'workforce.account.subscription.auto_renew.on',
        defaultMessage: 'On'
      })
    },
    {
      selectionValue: false,
      displayValue: intl.formatMessage({
        id: 'workforce.account.subscription.auto_renew.off',
        defaultMessage: 'Off'
      })
    }
  ];

  return (
    <>
      <div className="c-subscription-form__header">
        <h5 className="c-subscription-form__header-details">
          <FormattedMessage
            id="workforce.account.subscription.header.details"
            defaultMessage="Subscription Details"
          />
        </h5>
        <p className="c-subscription-form__header-required">
          <FormattedMessage
            id="workforce.account.subscription.header.required"
            defaultMessage="All fields marked with * are required."
          />
        </p>
      </div>
      <Form {...formProps} onSubmitSuccess={onSubmitSuccess}>
        {({ propsForFieldGroup, isSubmitting }) => (
          <div className="c-subscription-form">
            <Form.TextField
              name="payment_type"
              label={intl.formatMessage({
                id: 'workforce.account.subscription.payment_type',
                defaultMessage: 'Payment Type'
              })}
              {...propsForFieldGroup('payment_type')}
              enclosed
              value={intl.formatMessage(
                {
                  id: 'workforce.account.subscription.paying_by_employee',
                  defaultMessage: 'Paying by employee'
                }
              )}
              className="c-subscription-form__field"
              disabled
            />
            <Form.RadioGroup
              name="annual_auto_renew"
              {...propsForFieldGroup('annual_auto_renew')}
              required
              label={intl.formatMessage(
                {
                  id: 'workforce.account.subscription.annual_auto_renew',
                  defaultMessage: 'Annual Auto-Renew'
                }
              )}
              options={autoRenewOptions}
              className="c-subscription-form__field"
            />
            <Form.DateField
              name="start_date"
              label={intl.formatMessage(
                {
                  id: 'workforce.account.subscription.start_date',
                  defaultMessage: 'Start date'
                }
              )}
              {...propsForFieldGroup('start_date')}
              enclosed
              required
              className="c-subscription-form__field"
            />
            <Form.DateField
              name="end_date"
              label={intl.formatMessage(
                {
                  id: 'workforce.account.subscription.end_date',
                  defaultMessage: 'End date'
                }
              )}
              {...propsForFieldGroup('end_date')}
              enclosed
              required
              className="c-subscription-form__field"
            />
            <Form.TextField
              name="subscription_limit"
              label={intl.formatMessage(
                {
                  id: 'workforce.account.subscription.subscription_limit',
                  defaultMessage: 'Subscription Limit'
                }
              )}
              {...propsForFieldGroup('subscription_limit')}
              enclosed
              required
              className="c-subscription-form__field"
            />
            <div className="c-subscription-form__action-buttons">
              <RoleButton
                tagName="a"
                onClick={() => history.goBack()}
                className="c-subscription-form__cancel-link"
              >
                <FormattedMessage
                  id="workforce.account.subscription.cancel.button"
                  defaultMessage="Cancel"
                />
              </RoleButton>
              <Form.Submit
                type="primary"
                loading={isSubmitting}
                className="c-subscription-form__submit"
                id="workforce-account-subscription-save"
              >
                <FormattedMessage
                  id="workforce.account.subscription.save"
                  defaultMessage="Save"
                />
              </Form.Submit>
            </div>
          </div>
        )}
      </Form>
    </>
  );
};

SubscriptionForm.propTypes = {
  staticParams: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  submitAction: PropTypes.instanceOf(Action),
  onSubmitSuccess: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired
};

