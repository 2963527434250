import { Action } from 'utils/action';
import { ActionManager } from 'utils/action_manager';

export const fetchAcceptedEmployments = new ActionManager('employments')
  .add({
    requestKey: 'fetchAcceptedEmployments',
    url: '/api/v1/users/self/employments/accepted_employments',
    effect: 'read',
    method: 'GET'
  });

export const fetchUserEmploymentHistory = new Action({
  resourceType: 'employmentHistories',
  requestKey: 'fetchEmploymentHistories',
  url: '/api/v1/users/self/employments/employment_histories',
  effect: 'read',
  method: 'GET'
});

export const employments = new ActionManager('employments')
  .add({
    requestKey: 'list',
    url: '/api/v1/users/self/employments',
    effect: 'read',
    method: 'GET'
  })
  .add({
    requestKey: 'accept',
    url: '/api/v1/users/self/employments/[id]/accept',
    effect: 'update',
    method: 'PUT'
  }, true)
  .add({
    requestKey: 'reject',
    url: '/api/v1/users/self/employments/[id]/reject',
    effect: 'update',
    method: 'PUT'
  }, true)
  .add({
    requestKey: 'details',
    url: '/api/v1/users/self/employments/[id]/details',
    effect: 'read',
    method: 'GET'
  }, true);
