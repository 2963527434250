import * as objUtils from 'utils/object';
import { useQueryString } from 'controls/with_query_string';

export const useViewableProfileTabParam = (location) => {
  const queryString = useQueryString(location);
  const queryParams = queryString.get();
  return objUtils.compact({
    page: queryParams.page,
    sort: queryParams.sort
  });
};
