import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingSpinner } from 'controls/loading_spinner';
import { industryTrendingEmergingSkillsActions } from './industry_trending_emerging_skills_actions';
import { SkillList } from 'pages/workforce/components/skill_list';

import './industry_trending_emerging_skills.sass';

export const IndustryTrendingEmergingSkills = props => {
  const intl = useIntl();
  const [industryTrendingEmergingSkillsState] = industryTrendingEmergingSkillsActions.useOnMount(
    { organization_id: props.organizationId }, [props.organizationId]
  );
  const industryTrendingEmergingSkills = industryTrendingEmergingSkillsState.resources;
  const industryTrendingEmergingSkillsStatus = industryTrendingEmergingSkillsState.status;

  if (industryTrendingEmergingSkills?.length === 0 || industryTrendingEmergingSkillsStatus.failed) {
    return null;
  }

  const infoModalTitle = intl.formatMessage({
    id: 'workforce.trending_and_emerging_skills_industry.info_modal.title',
    defaultMessage: 'Trending & emerging skills'
  });
  const infoModalBody = intl.formatMessage({
    id: 'workforce.trending_and_emerging_skills_industry.info_modal.body',
    defaultMessage: 'Trending skills are well-known skills that are seeing significant growth in demand ' +
      'from employers. Emerging skills are new skills that are becoming more in demand and that may grow to ' +
      'become core skills for one or more occupations.'
  });

  return (
    <div className="workforce__employer-industry-trending-emerging-skills">
      {industryTrendingEmergingSkillsStatus.pending ? <LoadingSpinner position="below"/> : null}
      {industryTrendingEmergingSkills ? (
        <SkillList
          header={(
            <FormattedMessage
              id="workforce.trending_and_emerging_skills_industry.header"
              defaultMessage="Trending & emerging skills in your industry"
            />
          )}
          infoModalTitle={infoModalTitle}
          infoModalBody={infoModalBody}
          skills={industryTrendingEmergingSkills}
          loading={industryTrendingEmergingSkillsStatus.pending}
        />
      ) : null}
    </div>
  );
};

IndustryTrendingEmergingSkills.propTypes = {
  organizationId: PropTypes.string.isRequired
};
