import React from 'react';
import PropTypes from 'prop-types';
import { OrgMgmtDataTable } from 'pages/workforce/organizations';
import { FormattedMessage } from 'react-intl';
import { ClampLines } from 'controls/clamp_lines';
import { occupationUrl } from 'utils/routes';
import { ResourceStatusPropType } from 'utils/prop_types';
import {
  useStandardSortHandler, standardInitialSort
} from 'controls/data_table/use_standard_sort_handler';
import { useQueryString } from 'controls/with_query_string';
import { L2EmptyOccupationsSearch } from './l2_empty_occupations_search';

import './employees_occupations_table.sass';

/**
 * A table view of occupations held by employees of
 * the viewing organization.
 *
 * @param {Object} props
 *   @param {Array} props.data - Occupation data array.
 *   @param {ResourceStatus} props.status - Redux status for the data request.
 */

export const EmployeesOccupationsTable = props => {
  const sortHandler = useStandardSortHandler();
  const queryString = useQueryString(props.routeProps.location);

  const currentSort =
    standardInitialSort(queryString.get('sort')) || { key: 'name', ascending: true };

  const columnCommonProps = {
    headerClassName: "c-employees-occupations-table__column-header"
  };

  return (
    <OrgMgmtDataTable
      data={props.data}
      header={{}}
      loader={{ loading: props.status.pending }}
      rowClassName="c-employees-occupations-table__row"
      keyboardControl
      hoverStyle="default"
      rowHref={(occupation) => occupationUrl(occupation.foc)}
      sort={{ onSort: sortHandler, ...currentSort }}
      emptyResultNode={<L2EmptyOccupationsSearch/>}
    >
      <OrgMgmtDataTable.Col
        {...columnCommonProps}
        width="col-sm-12 col-lg-3"
        header={
          <FormattedMessage
            id="workforce.employee_profile.occupation_label"
            defaultMessage="Occupation"
          />
        }
        sortKey="name"
        renderer={occupation => (
          <ClampLines
            attributes={{ className: 'c-employees-occupations-table__name' }}
            lines={3}
          >
            {occupation.name}
          </ClampLines>
        )}
      />
      <OrgMgmtDataTable.Col
        {...columnCommonProps}
        width="col-lg-6"
        hideAt={['mobile']}
        header={
          <FormattedMessage
            id="workforce.employees.occupations.description"
            defaultMessage="Description"
          />
        }
        renderer={occupation => (
          <ClampLines lines={2}>
            {occupation.description || (
              <FormattedMessage
                id="workforce.employees.occupations.not_applicable"
                defaultMessage="N/A"
              />
            )}
          </ClampLines>
        )}
      />
      <OrgMgmtDataTable.Col
        {...columnCommonProps}
        width="col-lg-2"
        hideAt={['mobile']}
        header={
          <FormattedMessage
            id="workforce.employees.occupations.employees_count"
            defaultMessage="Total Employees"
          />
        }
        className="employees_count"
        sortKey="employee_count"
        initialSortDescending
        renderer={occupation => occupation.employee_count.toLocaleString()}
      />
    </OrgMgmtDataTable>
  );
};

EmployeesOccupationsTable.propTypes = {
  data: PropTypes.array,
  status: ResourceStatusPropType.isRequired,
  routeProps: PropTypes.object
};
