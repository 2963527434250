import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';

import './chiclet_group.sass';

/**
 * Used to render a set of {@link Chiclet} elements in a specified layout
 *
 * Layouts:
 *
 *   quad - applicable for a group of 4 Chiclet children; arranges them in a tight quadrant on
 *          small screens
 *
 * @param {object} props
 *   @param {*} props.children - an array of {@link Chiclet} elements (or components that wrap such
 *     without any intervening DOM elements) to render with the given layout
 *   @param {'quad'} props.layout - the layout to render children in
 *   @param {string} [props.className] - optional extra className to apply to root element
 * @returns {JSX.Element}
 * @constructor
 */
export const ChicletGroup = (props) => {
  return (
    <div
      className={makeClassName(
        'workforce-chiclet-group',
        props.layout === 'quad' && 'workforce-chiclet-group--quad',
        props.layout === 'stacked' && 'workforce-chiclet-group--stacked',
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

ChicletGroup.propTypes = {
  children: PropTypes.node,
  layout: PropTypes.oneOf(['quad', 'stacked']).isRequired,
  className: PropTypes.string
};
