import React, { useEffect, useState } from "react";
import { ajax } from "utils";
import { CelebrateButton } from "./celebrate_button";

export const CelebrateButtonWithCheck = (props) => {
  const [canCelebrate, setCanCelebrate] = useState(false);

  const onSuccess = (res) => {
    if (res.statusCode === 200) {
      setCanCelebrate(true);
    } else {
      setCanCelebrate(false);
    }
  };

  const onFailure = () => {
    setCanCelebrate(false);
  };
  let canCongratulateUrl =
  `/api/v1/congratulations/can_congratulate?badge_id=${props.badge.id}`;
  if (props.organizationId) {
    canCongratulateUrl += `&organization_id=${props.organizationId}`;
  }
  useEffect(() => {
    ajax({
      method: 'GET',
      uri: canCongratulateUrl,
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
    }).then(onSuccess).catch(onFailure);
  }, []);

  return canCelebrate ? <CelebrateButton {...props}/> : null;
};

CelebrateButtonWithCheck.propTypes = {
  ...CelebrateButton.propTypes
};
