import React from 'react';
import { uuidPropType } from 'prop_types';
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { countLines, urlToFilename } from 'utils/file';
import { useAlerts } from 'alert';
import { Form } from 'form';
import { useUploadEmployeesForm } from './use_upload_employees_form';
import { EmployeesUploadErrors } from './employees_upload_errors';
import { useIntl, FormattedMessage } from 'react-intl';

import '../upload_employees_form.sass';

/**
 * Component that displays the UploadEmployees form on the Employees tab in org management.
 *
 * @param {Object} props
 *   @param {String} props.organization_id - Id of the organization to upload employees for
 */
export const UploadEmployeesForm = (props) => {
  const intl = useIntl();
  const { formProps, processingFile, failedFile } = useUploadEmployeesForm(props.organization_id);
  const alerts = useAlerts();
  const [newFileObject, setNewFileObject] = React.useState();
  const [newFileRows, setNewFileRows] = React.useState(0);
  const [
    doingClientSideProcessing,
    setDoingClientSideProcessing
  ] = React.useState(0);

  React.useEffect(() => {
    if (newFileObject) {
      setDoingClientSideProcessing(true);
      countLines(newFileObject).then((lineCount) => {
        setDoingClientSideProcessing(false);
        if (lineCount <= 1) {
          alerts.add({
            type: 'error',
            head: intl.formatMessage({
              id: 'workforce.employees.add.upload.form.alert.head',
              defaultMessage: 'Upload failed'
            }),
            text: intl.formatMessage({
              id: 'workforce.employees.add.upload.form.alert.text',
              defaultMessage: 'The file is empty'
            })
          });
          setNewFileRows(0);
        } else {
          // -1 to ignore column headers.
          setNewFileRows(Math.max(lineCount - 1, 0));
        }
      });
    }
  }, [newFileObject]);

  const renderForm = (form) => {
    const fieldProps = form.propsForFieldGroup('employees');
    const inputFile = fieldProps.value;
    if (inputFile && inputFile !== newFileObject) {
      setNewFileObject(inputFile);
    }
    return (
      <div>
        {!processingFile && failedFile && !inputFile && (
          <EmployeesUploadErrors
            errorHash={failedFile.error_hash}
            rowErrors={failedFile.errors}
          />
        )}
        {inputFile && (
          <div className="c-upload-employees-form__upload-file-details">
            <FontAwesomeIcon
              icon={faFile}
              className="c-upload-employees-form__last-upload-image"
            />
            <div className="c-upload-employees-form__last-upload-details">
              <div className="c-upload-employees-form__last-upload-details-row">
                <div className="c-upload-employees-form__last-upload-details-label">
                  <FormattedMessage
                    id="workforce.employees.add.upload.form.file_name"
                    defaultMessage="File Name:"
                  />
                </div>
                <div className="c-upload-employees-form__last-upload-filename">
                  {urlToFilename(inputFile.name)}
                </div>
              </div>
              <div className="c-upload-employees-form__last-upload-details-row">
                <div className="c-upload-employees-form__last-upload-details-label">
                  <FormattedMessage
                    id="workforce.employees.add.upload.form.records"
                    defaultMessage="Records:"
                  />
                </div>
                <div>
                  {doingClientSideProcessing
                    ? intl.formatMessage({
                      id: 'workforce.employees.add.upload.form.calculating',
                      defaultMessage: 'Calculating...'
                    })
                    : newFileRows}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="c-upload-employees-form__upload-fields">
          <Form.FileUpload
            {...form.propsForFieldGroup('employees')}
            accept=".csv"
            ui="drop"
          />
          <Form.Checkbox
            {...form.propsForFieldGroup("replace")}
            label={intl.formatMessage({
              id: 'workforce.employees.add.upload.form.remove_missing',
              defaultMessage: 'Remove all employees not in the file'
            })}
          />
        </div>
        {inputFile && newFileRows > 0 && (
          <>
            <hr className="c-upload-employees-form__hr" />
            <div className="c-upload-employees-form__action-button">
              <Form.Submit type="primary" loading={form.isSubmitting}>
                <FormattedMessage
                  id="workforce.employees.add.upload.form.upload"
                  defaultMessage="Upload"
                />
              </Form.Submit>
            </div>
          </>
        )}
      </div>
    );
  };
  return (
    <Form {...formProps} className="c-upload-employees-form__upload">
      {renderForm}
    </Form>
  );
};

UploadEmployeesForm.propTypes = {
  organization_id: uuidPropType
};
