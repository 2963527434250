import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CredlyRoute from 'controls/credly_route';
import { CredlySwitch } from 'controls/credly_switch';
import { NotFound } from 'pages/error_views';
import { Permissions, usePermissions } from 'management/organizations/permissions';
import { SubscriptionsRouteHelpers } from 'pages/workforce/route_helpers';
import { EmptySubscriptions } from './pages/empty';
import { SubscriptionManagement } from './pages/management';
import { SubscriptionsDirector } from './components/subscriptions_director';
import { SubscriptionHistory } from './pages/history';
import { SubscriptionAdd } from './pages/add';
import { SubscriptionEdit } from './pages/edit';

/**
 * Routes for the various Subscriptions Ids.
 *
 * @param {object} props
 *   @param {string} props.baseUrl - base URL of the earners subsection of accounts,
 *   @param {object} props.organization - current organization context
 * @returns {*}
 * @constructor
 *
 */
export const Routes = (props) => {
  const intl = useIntl();
  const routeHelpers = SubscriptionsRouteHelpers.forUrl(props.baseUrl);
  const [hasPermissions] = usePermissions();
  const readPermissions = hasPermissions(Permissions.WORKFORCE_SUBSCRIPTION_READ);
  const managePermissions = hasPermissions(Permissions.WORKFORCE_SUBSCRIPTION_MANAGE);
  const hasPagePermissions = useCallback((pageElement) => {
    return (readPermissions || managePermissions ? pageElement : <NotFound/>);
  }, [readPermissions, managePermissions]);

  return (
    <CredlySwitch>
      <CredlyRoute
        path={routeHelpers.root.route()}
        exact
        title={intl.formatMessage({
          id: 'workforce.account.subscription.page_title',
          defaultMessage: 'Subscription'
        })}
        render={(routeProps) => {
          return (
            <SubscriptionsDirector
              baseUrl={routeProps.match.url}
              organization={props.organization}
            />
          );
        }}
      />
      <CredlyRoute
        path={routeHelpers.manage.route()}
        exact
        title={intl.formatMessage({
          id: 'workforce.account.subscription.page_title',
          defaultMessage: 'Subscription'
        })}
        render={(routeProps) => {
          return hasPagePermissions(
            <SubscriptionManagement
              baseUrl={routeProps.match.url}
              organization={props.organization}
              routeProps={routeProps}
              subscriptionId={routeProps.match.params.id}
            />
          );
        }}
      />
      <CredlyRoute
        path={routeHelpers.history.route()}
        exact
        title={intl.formatMessage({
          id: 'workforce.account.subscription.history.page_title',
          defaultMessage: 'Subscription History'
        })}
        render={(routeProps) => {
          return hasPagePermissions(
            <SubscriptionHistory
              baseUrl={routeProps.match.url}
              organization={props.organization}
            />
          );
        }}
      />
      <CredlyRoute
        path={routeHelpers.empty.route()}
        exact
        title={intl.formatMessage({
          id: 'workforce.account.subscription.empty.page_title',
          defaultMessage: 'Empty Subscription'
        })}
        render={(routeProps) => {
          return hasPagePermissions(
            <EmptySubscriptions baseUrl={routeProps.match.url}/>
          );
        }}
      />
      <CredlyRoute
        path={routeHelpers.new.route()}
        exact
        title={intl.formatMessage({
          id: 'workforce.accounts.accounts.routes.new_subscription',
          defaultMessage: 'New Subscription'
        })}
        render={(routeProps) => {
          return hasPagePermissions(
            <SubscriptionAdd
              organization={props.organization}
              baseUrl={routeProps.match.url}
            />
          );
        }}
      />
      <CredlyRoute
        path={routeHelpers.edit.route()}
        exact
        title={intl.formatMessage({
          id: 'workforce.accounts.accounts.routes.edit_subscription',
          defaultMessage: 'Edit Subscription'
        })}
        render={(routeProps) => {
          return hasPagePermissions(
            <SubscriptionEdit
              organization={props.organization}
              baseUrl={routeProps.match.url}
            />
          );
        }}
      />
    </CredlySwitch>
  );
};

Routes.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  organization: PropTypes.object
};
