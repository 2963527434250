import { ActionManager } from 'utils/action_manager';
import * as stringUtils from 'utils/string';

export const workforceSubscriptionsActions =
  new ActionManager('managementOrganizationsWorkforceSubscription')
    .add(
      {
        requestKey: 'list',
        url: '/api/v1/organizations/[organization_id]/workforce/subscriptions',
        effect: 'read',
        urlSubs: new stringUtils.BracketSubs(
          { organization_id: { param: 'organization_id', remove: true } }
        ),
        method: 'GET',
        list: 'list',
        alwaysRefresh: true
      }
    ).add(
      {
        requestKey: 'show',
        url: '/api/v1/organizations/[organization_id]/workforce/subscriptions/[id]',
        urlSubs: new stringUtils.BracketSubs(
          { organization_id: { param: 'organization_id', remove: true } }
        ),
        effect: 'read',
        method: 'GET'
      }, true
    ).add(
      {
        requestKey: 'create',
        url: '/api/v1/organizations/[organization_id]/workforce/subscriptions',
        urlSubs: new stringUtils.BracketSubs(
          { organization_id: { param: 'organization_id', remove: true } }
        ),
        effect: 'create',
        method: 'POST'
      }
    ).add(
      {
        requestKey: 'update',
        url: '/api/v1/organizations/[organization_id]/workforce/subscriptions/[id]',
        urlSubs: new stringUtils.BracketSubs(
          { organization_id: { param: 'organization_id', remove: true } }
        ),
        effect: 'update',
        method: 'PUT'
      }, true
    ).add(
      {
        requestKey: 'delete',
        url: '/api/v1/organizations/[organization_id]/workforce/subscriptions/[id]',
        urlSubs: new stringUtils.BracketSubs({
          organization_id: { param: 'organization_id', remove: true }
        }),
        effect: 'delete',
        method: 'DELETE'
      }, true
    );
