import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Heading } from 'controls/heading';
import { useOnOff, useToggle } from 'utils/react_utils';
import { skillsInsightsUrl } from 'utils/routes';
import Dialog from 'controls/dialog';
import { Tag, TagList, TagsLoading, TruncatedTagList } from 'controls/tags';
import { RoleButton } from 'aria/role_button';
import { InfoModal } from './info_modal';
import { FormattedMessage, useIntl } from 'react-intl';

import './skill_list.sass';

export const SkillList = (props) => {
  const [showDialog, openDialog, closeDialog] = useOnOff(false);
  const intl = useIntl();
  const [showViewAll, setShowViewAll] = useToggle(false);

  const onTagsTruncatedChanged = useCallback((truncatedAt) => {
    setShowViewAll(truncatedAt);
  }, []);

  const skilList = useMemo(() => {
    return props.skills.map((value) => <SkillListTag key={value.id} skill={value}/>);
  }, [props.skills]);

  return (
    <div className="cr-workforce-skill-list">
      <div className="cr-workforce-skill-list__header">
        <Heading appearance="workforce-subhead">
          {props.header}
        </Heading>
        {
          props.infoModalTitle && props.infoModalBody && (
            <InfoModal
              title={props.infoModalTitle}
              body={(
                <div className="cr-workforce-skill-list__info-modal-dialog">
                  {props.infoModalBody}
                </div>
              )}
              dialogSize="auto"
            />
          )
        }
        {
          !props.loading && showViewAll && (
            <RoleButton
              className="cr-workforce-skill-list__view-all"
              tagName="span"
              onClick={props.viewAllOnClick || openDialog}
            >
              <FormattedMessage
                id="workforce.skill_list.view_all"
                defaultMessage="View all skills"
              />
            </RoleButton>
          )
        }
      </div>
      <Dialog
        show={showDialog}
        shouldClose={closeDialog}
        title={intl.formatMessage({
          id: 'workforce.skill_list.view_all_dialog_title',
          defaultMessage: 'Skills'
        })}
        size="half"
      >
        <Dialog.Content>
          <TagList>
            {skilList}
          </TagList>
        </Dialog.Content>
      </Dialog>

      <div>
        {
          props.loading ? (
            <TagsLoading/>
          ) : (
            <TruncatedTagList
              maxRows={2}
              ui="skill-public"
              suppressEllipses
              onTagsTruncatedChanged={onTagsTruncatedChanged}
            >
              {skilList}
            </TruncatedTagList>
          )
        }
      </div>
    </div>
  );
};

SkillList.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  header: PropTypes.node,
  loading: PropTypes.bool,
  viewAllOnClick: PropTypes.func,
  infoModalTitle: PropTypes.node,
  infoModalBody: PropTypes.node
};

SkillList.defaultProps = {
  header: 'Skills',
  viewAllOnClick: null
};

const SkillListTag = (props) => (
  <Link to={skillsInsightsUrl(props.skill.id)} className="cr-workforce-skill-list__skill-link">
    <Tag ui="skill-public" className="cr-workforce-skill-list__skill-name" clickable>
      {props.skill.name}
    </Tag>
  </Link>
);

SkillListTag.propTypes = {
  skill: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};
