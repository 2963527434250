import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextSize, VictoryLabel, VictoryTooltip } from 'victory';
import { uniqueId } from 'utils';
import { wrapArray } from 'utils/array';
import { isIE } from 'utils/browser';
import { COLORS, FONT_FAMILY } from 'charts/constants';

/**
 * Hash of styles used by the flyout for the Tooltip. These aren't in CSS, because most of them
 * are required to compute the size of the tooltip (based on size of text to be rendered, padding,
 * etc.), and most of the rest are styles that can't be applied to SVG via the embedding document's
 * CSS, anyway.
 *
 * @type {object}
 */
const CUSTOM_TOOLTIP_FLYOUT_BASE_STYLES = {
  rx: 5,
  fill: COLORS.lightLight,
  fontFamily: FONT_FAMILY,
  stroke: COLORS.lightDark,
  lineHeight: 1.75,
  padding: { top: 10, bottom: 10, left: 15, right: 15 }
};

/**
 * Component used internally by charts to style tooltips with rounded borders, drop shadow, etc.,
 * per the "enhanced analytics" designs.
 *
 * @param {object} props
 * @param {function} props.dataLabel - function that will take props.datum as its input and produce
 *   an array of strings representing lines of the tooltip text
 * @param {object} props.datum - object containing information about the specific data point (i.e
 *   x and y values)
 * @param {object} props.flyoutStyle - hash of properties provided by Victory parent component for
 *   the flyout sub-component
 * @param {object} props.style - hash of styles provided by Victory parent component
 * @param {array<string>|function} props.text - the text to render in the tooltip or (in case of
 *   initial usage in labelComponent, function to produce that text from props)
 * @returns {JSX.Element}
 * @constructor
 */
export const Tooltip = (props) => {
  const filterId = useRef();
  if (!filterId.current) {
    filterId.current = `filter_shadow_${uniqueId()}`;
  }
  const flyoutStyle = useMemo(() => {
    const result = {
      ...props.style,
      ...CUSTOM_TOOLTIP_FLYOUT_BASE_STYLES,
      ...props.flyoutStyle
    };
    // IE doesn't do well with the drop shadow...so drop it ;)
    if (!isIE()) {
      result.filter = `url(#${filterId.current})`;
    }
    return result;
  }, [props.style, props.flyoutStyle]);
  const text = useMemo(() => props.datum && props.dataLabel(props.datum), [props.datum]);
  let labelXOffset, width, height;
  if (text) {
    const size = approximateFlyoutTextSize({ text, style: flyoutStyle });
    width = size.width + flyoutStyle.padding.left + flyoutStyle.padding.right;
    labelXOffset = -1 * width / 2 + flyoutStyle.padding.left;
    height = size.height + flyoutStyle.padding.top + flyoutStyle.padding.bottom;
  }

  return (
    <>
      <filter id={filterId.current} x="0" y="0" width="110%" height="110%">
        <feDropShadow dx="3" dy="3" stdDeviation="2" floodColor="#000000" floodOpacity="0.1" />
      </filter>
      <VictoryTooltip
        {...props}
        text={text}
        flyoutStyle={flyoutStyle}
        flyoutWidth={width}
        flyoutHeight={height}
        pointerLength={0}
        labelComponent={
          <VictoryLabel
            textAnchor="start"
            dx={labelXOffset}
            lineHeight={1.75}
            style={[{ fill: COLORS.darkDark, fontWeight: 'bold' }, { fill: COLORS.darkMedium }]}
          />
        }
      />
    </>
  );
};
Tooltip.propTypes = {
  dataLabel: PropTypes.func.isRequired,
  datum: PropTypes.object.isRequired,
  flyoutStyle: PropTypes.shape({
    padding: PropTypes.shape({
      top: PropTypes.number,
      right: PropTypes.number,
      bottom: PropTypes.number,
      left: PropTypes.number
    }).isRequired
  }).isRequired,
  style: PropTypes.object
};

/**
 * Internal function to compute an approximate size of the text that will be displayed in
 * CustomTooltip, which is used to determine its size.
 *
 * @param {array<string>} text - an array of text lines that will be used
 * @param {object} style - styles (such as fontSize, fontFamily, etc.) that will be used in
 *   determining how much space is required to render the text
 * @returns {{width: number, height: number}}
 */
const approximateFlyoutTextSize = ({ text, style }) => {
  text = wrapArray(text);
  const result = { width: 0, height: 0 };
  text.forEach((textEl) => {
    const size = TextSize.approximateTextSize(textEl, style);
    result.width = Math.max(result.width, size.width);
    result.height += size.height;
  });
  return result;
};
