import React, { Component, Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from './modal';
import { makeClassName, uniqueId } from "utils";
import { Button } from "controls/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import "./dialog.sass";

const DialogContext = React.createContext();

/**
 * A simple dialog wrapping arbitrary content.
 * By default, it shows a button that says "OK" (which closes the dialog)
 * Clicking outside of the dialog dismisses it without action.
 *
 * Example:
 * <Dialog>
 *   <Dialog.Content>
 *     Content goes here.
 *   </Dialog.Content>
 *   <Dialog.Footer>
 *     <Dialog.Action {...}>Button Text</Dialog.Action>
 *   </Dialog.Footer>
 * </Dialog>
 *
 * @property {String} title - The title of the dialog
 * @property {Function} shouldClose - Called when the dialog should be closed without action
 * @property {Boolean} show - True to show the dialog, false to hide it
 * @property {Boolean} disableBackdropClose - Set to false to enable clicking away to close
 * @property {Boolean} hideCloseButton - Set to false to disable the close button in the corner
 * @property {String} [size=narrow]
 *   narrow: Use a fixed width on desktop, and slightly less than 100% width on mobile.
 *   half: The dialog width is roughly 50% that of the content area on desktop.
 *   custom: Custom, pass in a class to go with it
 * @property {String} [titleTheme=default]
 *   default: the standard title theme, using Grad bold
 *   small: a more diminutive heading using Open Sans, to more closely match the rest of
 *     the Rules Integration UI
 * Deprecated properties:
 * @property {Boolean} allowScroll - See Dialog.Content
 * @property {Array} actions - Deprecated. Use Dialog.Footer and Dialog.Action.
 */
export default class Dialog extends Component {
  constructor(props) {
    super(props);
    this.uniqueIntForId = uniqueId();
    this.state = {
      // setFooterContent is passed down via context so that Dialog.Footer can set footer content to
      // be portal-ed into DialogFooterRenderer. Note that if we stored the state of the footer content
      // here in Dialog that would cause an infinite render loop.
      // Dialog.Footer rendering would set footer content which would cause Dialog to rerender
      // which would cause Dialog.Footer to rerender etc. Instead the state of the footerContent
      // is stored by DialogFooterRenderer which then tells Dialog how to set its own footer content
      // via initializeSetFooterContent (L59). As a sibling component to the children of Dialog,
      // DialogFooterRenderer re-rendering itself will not cause Dialog.Footer to also rerender thus avoiding
      // the infinite re-render loop problem.
      setFooterContent: () => null
    };
  }

  initializeSetFooterContent = (setter) => {
    this.setState((state) => {
      return { ...state, setFooterContent: setter };
    });
  }

  handleShowChange = e => {
    if (this.props.shouldClose) {
      e && e.preventDefault();
      this.props.shouldClose();
    }
  };

  handleAction = action => {
    if (!action.noClose) {
      this.handleShowChange();
    }
  };

  handleCloseButtonKeyDown = evt => {
    if (evt.key === " " || evt.key === "Enter") {
      this.handleShowChange(evt);
    }
  };

  /**
   * Renders the header section if simpleHeader is true or a title is given.
   *
   * @returns {React.element}
   */
  renderHeader = () => {
    if (this.props.simpleHeader) {
      return (
        <div className="ac-dialog__simple-header">
          {this.renderCloseButton()}
        </div>
      );
    }

    // This simpleHeader usage is redundant but clearer than relying on an else if for control flow
    if (this.props.title && !this.props.simpleHeader) {
      const titleClassName = makeClassName(
        'ac-dialog__title',
        this.props.titleTheme === 'small' && 'ac-dialog__title--small',
        this.props.titleTheme === 'default' && 'ac-dialog__title--default'
      );
      return (
        <div className={titleClassName}>
          <div
            className="ac-dialog__title-text"
            id={`ac-dialog__title-text-${this.uniqueIntForId}`}
          >
            {this.props.title}
          </div>
          {this.renderCloseButton()}
        </div>
      );
    }
  };

  renderCloseButton = () => {
    if (!this.props.hideCloseButton) {
      return (
        <div
          className="ac-dialog__title-close"
          aria-label="Close Dialog"
          role="button"
          tabIndex={0}
          onClick={this.handleShowChange}
          onKeyDown={this.handleCloseButtonKeyDown}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      );
    }
  }

  /**
   * Render the dialog content.
   *
   * @returns {React.element}
   */
  renderContent() {
    if (this.props.actions) {
      // props.actions is supported for backwards compatibility.
      // Actions can also be passed in as children, using Dialog.Footer and Dialog.Action.
      return (
        <Fragment>
          <Dialog.Content noScroll={!this.props.allowScroll}>
            {this.props.children}
          </Dialog.Content>

          <Dialog.Footer>
            {this.props.actions.map((action, idx) =>
              <Dialog.Action {...action} key={action.label + idx}>
                {action.label}
              </Dialog.Action>
            )}
          </Dialog.Footer>
        </Fragment>
      );
    }

    return this.props.children;
  }

  /**
   * Render the component.
   *
   * @returns {React.element}
   */
  render() {
    if (this.props.show) {
      const sizeClassName = {
        narrow: 'ac-dialog__size-narrow',
        half: 'ac-dialog__size-half',
        auto: 'ac-dialog__size-auto',
        full: 'ac-dialog__size-full'
      }[this.props.size];

      const borderClassName = {
        default: 'ac-dialog__border-default',
        rounded: 'ac-dialog__border-rounded'
      }[this.props.borderTheme];

      return (
        <Modal
          backdropTheme={this.props.backdropTheme}
          disableBackdropClose={this.props.disableBackdropClose}
          onClose={this.handleShowChange}
        >
          {({ openFocusRecipientRef }) => (
            <div
              tabIndex={0}
              className={makeClassName(
                'ac-dialog',
                sizeClassName,
                borderClassName,
                this.props.className
              )}
              ref={openFocusRecipientRef}
              role="dialog"
              aria-modal="true"
              aria-labelledby={`ac-dialog__title-text-${this.uniqueIntForId}`}
            >
              {this.renderHeader()}

              <DialogContext.Provider
                value={{
                  onClick: this.handleAction,
                  initializeSetFooterContent: this.initializeSetFooterContent,
                  setFooterContent: this.state.setFooterContent
                }}
              >
                {this.renderContent()}
                <DialogFooterRenderer />
              </DialogContext.Provider>
            </div>
          )}
        </Modal>
      );
    }
    return "";
  }
}

const DialogFooterRenderer = (_props) => {
  const { initializeSetFooterContent } = useContext(DialogContext);
  const [footerContent, setFooterContent] = useState(null);
  useEffect(() => {
    initializeSetFooterContent(setFooterContent);
    return () => {
      initializeSetFooterContent(() => null);
    };
  }, []);
  return footerContent;
};

/**
 * The dialog footer, meant to wrap <DialogAction/>.
 *
 * @param {Object} props
 *   @param {JSX.Element} props.children - the footer content (typically one or more Dialog.Action
 *     elements)
 *   @param {JSX.Element} props.confirmation - when we use a confirmation modal, if the "confirmation" prop is true,
 *     the separating border top is removed
 * @returns {React.element}
 * @constructor
 */
const DialogFooter = (props) => {
  const { setFooterContent } = useContext(DialogContext);
  const render = () => (
    <div className={props.confirmation ? "ac-dialog__actions ac-dialog__footer-confirmation" : "ac-dialog__actions"}>
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
    </div>
  );
  useEffect(() => {
    setFooterContent(render());
    return () => {
      setFooterContent(null);
    };
  });
  return null;
};

DialogFooter.propTypes = {
  children: PropTypes.node.isRequired,
  confirmation: PropTypes.bool
};

Dialog.Footer = DialogFooter;


/**
 * The content of the dialog.
 *
 * @param {Object} props
 * @param {string} [props.className] - optional extra class name to include on the content container
 * @param {Boolean} props.noScroll - If the content is too tall, stretch the dialog, rather than
 *   allowing the content to scroll. Use this when the dialog contains absolutely positioned
 *   elements (like <Form.Select>), because those elements will be cut off by the scrollable area.
 * @returns {React.element}
 * @constructor
 */
Dialog.Content = props => {
  const contentPadClass = {
    none: 'ac-dialog__content-pad-none',
    "no-title": 'ac-dialog__content-pad-no-title',
    default: 'ac-dialog__content-pad-default',
    withoutMarginBottom: 'ac-dialog__content-pad-without-margin-bottom'
  }[props.contentPadTheme];

  const contentClassName =
    makeClassName(
      'ac-dialog__content',
      !props.noScroll && 'ac-dialog__allow-scroll',
      contentPadClass,
      props.isConfirmation && 'ac-dialog__confirmation',
      props.className
    );

  return (
    <div
      className={contentClassName}
    >
      {props.children}
    </div>
  );
};

/**
 * Create a button in the dialog.
 *
 * @property {Function} action - The function to exec when the user clicks the button.
 * @property {Boolean} disabled - Show the action button as disabled
 * @property {Boolean} noClose - Don't close the dialog after this action.
 * @property {Boolean} loading - Show a loading spinner and disable the button.
 * @property {string} [type=primary] - Which type of button style to use
 */
Dialog.Action = class DialogAction extends Component {
  handleAction(e, context) {
    if (this.props.action) {
      this.props.action();
    }

    context.onClick(this.props);
  }

  renderAction = context => {
    if (!context || !context.onClick) {
      throw new Error('Dialog.Action can only be created inside a Dialog.');
    }

    const className = makeClassName([
      this.props.type === 'tertiary' && 'ac-dialog__action--tertiary'
    ]);

    return (
      <Button
        disabled={this.props.disabled}
        onClick={e => this.handleAction(e, context)}
        loading={this.props.loading}
        type={this.props.type}
        className={className}
      >
        {this.props.children}
      </Button>
    );
  };

  render() {
    return <DialogContext.Consumer>{this.renderAction}</DialogContext.Consumer>;
  }
};

Dialog.Action.propTypes = {
  action: PropTypes.func,
  disabled: PropTypes.bool,
  noClose: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node
};

Dialog.Action.defaultProps = {
  type: 'primary'
};

Dialog.Content.propTypes = {
  className: PropTypes.string,
  contentPadTheme: PropTypes.oneOf(['none', 'default', 'no-title', 'withoutMarginBottom']),
  noScroll: PropTypes.bool,
  isConfirmation: PropTypes.bool
};

Dialog.Content.defaultProps = {
  contentPadTheme: 'default'
};

Dialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * If true, the dialog will render a simple header with only a close button.
   * This overrides the title prop.
   */
  simpleHeader: PropTypes.bool,
  /**
   * Action types:
   *  Label - string - required - the label of the action
   *  Action - function - a function that handles the action
   *  loading - boolean - show a loading spinner on the button
   */
  actions: PropTypes.arrayOf(PropTypes.shape(Dialog.Action.propTypes)),
  backdropTheme: Modal.propTypes.backdropTheme,
  /**
   * Called when the dialog should be closed without action
   */
  shouldClose: PropTypes.func,
  /**
   * True to show the dialog, false to hide it
   */
  show: PropTypes.bool.isRequired,
  /**
   * Set to false to disable clicking away to close
   */
  disableBackdropClose: PropTypes.bool,
  /**
   * Set to false to disable the close button in the corner
   */
  hideCloseButton: PropTypes.bool,
  /**
   * Enable scrolling
   */
  allowScroll: PropTypes.bool,
  /*
   * narrow: Use a fixed width on desktop, and slightly less than 100% width on mobile.
   * half: The dialog width is roughly 50% that of the content area on desktop.
   * auto: Size to the container.
   * full: Full-window dialog.
   */
  size: PropTypes.oneOf(['narrow', 'half', 'auto', 'full', 'custom']),
  borderTheme: PropTypes.oneOf(['default', 'rounded']),
  titleTheme: PropTypes.oneOf(['default', 'small']),
  className: PropTypes.string
};

Dialog.defaultProps = {
  disableBackdropClose: false,
  hideCloseButton: false,
  size: 'narrow',
  borderTheme: 'default',
  titleTheme: 'default'
};

export const testing = { DialogFooterRenderer };
