import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SkillList } from 'pages/workforce/components/skill_list';
import { popularSkillsActions } from 'pages/workforce/components/popular_skills_actions';

import './popular_skills.sass';

export const PopularSkills = props => {
  const [popularSkillsState] = popularSkillsActions.useOnMount('fetchPopularSkills', props.organizationId);
  const popularSkills = popularSkillsState.resources;
  const popularSkillsStatus = popularSkillsState.status;

  if (popularSkillsStatus.failed) {
    return (
      <div>
        <FormattedMessage
          id="workforce.popular-skills.load_failure"
          defaultMessage="Could not load popular skills"
        />
      </div>
    );
  }

  const skills = popularSkills?.skills;

  if (skills?.length === 0) {
    return null;
  }

  return (
    <div className="workforce__employer-popular-skills">
      {
        skills && (
          <SkillList
            skills={skills}
            header={(
              <FormattedMessage
                id="workforce.popular-skills.header"
                defaultMessage="Popular skills in your organization"
              />
            )}
            loading={popularSkillsStatus.pending}
          />
        )
      }
    </div>
  );
};

PopularSkills.propTypes = {
  organizationId: PropTypes.string.isRequired
};
