import React from 'react';
import { useDebounced } from 'utils/react_utils';
import { useFormProps } from 'form';
import {
  getUploadEmployeeOccupations,
  uploadEmployeeOccupations
} from 'pages/workforce/employees/employees_actions';

/**
 * React hook to encapsulate the complex interactions between upload and state polling.
 *
 * @param {String} orgId - The organization ID.
 * @return {UploadEmployeeOccupationsFormData}
 */
export const useUploadEmployeeOccupationsForm = (orgId) => {
  const [formProps, uploadState] =
    useFormProps(
      uploadEmployeeOccupations,
      { initialValues: { organization_id: orgId } }
    );
  const [pollState, poll] = getUploadEmployeeOccupations.useAction();
  const [importedFile, setImportedFile] = React.useState();
  const [processingFile, setProcessingFile] = React.useState();
  const [failedFile, setFailedFile] = React.useState();
  const delayPoll = useDebounced(() => poll({ organization_id: orgId }));

  // Get files from the most recent completed request.
  const updateFiles = actionState => {
    const resources = actionState.resources.slice().reverse();
    const imported = resources.find(r => r.state === 'successful');
    const failed = resources.find(r => r.state.indexOf('failed') >= 0);
    const processing = resources.find(r => r.progress < 100);

    // If there's a file to be processed, poll its state.
    if (processing) {
      delayPoll();
    }

    setImportedFile(imported);
    setProcessingFile(processing);
    setFailedFile(failed);
  };

  // Request data on mount, to look for existing importers.
  React.useEffect(() => poll({ organization_id: orgId }), [orgId]);

  // After uploading or getting current state, update files. Don't combine these, or changing
  // poll state to pending will result in an unnecessary update.
  React.useEffect(() => {
    uploadState.status.succeeded && updateFiles(uploadState);
  }, [uploadState.status.succeeded]);
  React.useEffect(() => {
    pollState.status.succeeded && updateFiles(pollState);
  }, [pollState.status.succeeded]);

  /**
   * @typedef {Object} UploadEmployeeOccupationsFormData
   * @property {Object} formProps - Properties to pass to the upload form.
   * @property {EmployeeOccupationImportData} importedFile - The most recently successful import.
   * @property {EmployeeOccupationImportData} processingFile - The most recent in progress import.
   * @property {EmployeeOccupationImportData} failedFile - The most recent failed import.
   * @property {Boolean} loading - Are we loading initial data?
   * @property {Boolean} uploading - Is an upload in progress?
   * @property
   */
  return {
    formProps,
    importedFile,
    processingFile,
    failedFile,
    loading:
      (pollState.status.idle || pollState.status.pending) &&
      pollState.resources.length === 0 && uploadState.resources.length === 0,
    uploading: uploadState.status.pending
  };
};
