import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { combineLoaders } from 'utils/combine_loaders';
import { employerStatsActions, employerStatsHistoryActions } from 'pages/workforce/employer_stats_actions';
import {
  useFaethmOccupationMappingEnabled
} from 'pages/workforce/faethm_occupation_mapping_hooks';
import { Chiclet, ChicletGroup, ChicletLoading } from './chiclet';

import './employer_statistics.sass';

const EmployerStatisticsPanel = props => {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: `workforce.statistics.${props.statisticsField}.label`,
    defaultMessage: props.statisticsFieldDisplay
  });
  let chicletProps;
  const displayDifference = {
    total: props.employerStats[`total_${props.statisticsField}`],
    previousTotal: props.prevEmployerStats?.[`total_${props.statisticsField}`]
  };
  if (typeof displayDifference.total !== 'number' || typeof displayDifference.previousTotal !== 'number') {
    chicletProps = { displayStatic: displayDifference.total };
  } else {
    chicletProps = { displayDifference };
  }

  return (
    <Chiclet
      {...chicletProps}
      label={label}
      tooltip={props.tooltip}
    />
  );
};

EmployerStatisticsPanel.propTypes = {
  statisticsField: PropTypes.string.isRequired,
  statisticsFieldDisplay: PropTypes.string.isRequired,
  employerStats: PropTypes.object.isRequired,
  prevEmployerStats: PropTypes.object.isRequired,
  tooltip: PropTypes.node
};

const combineStatuses = (currentStatsState, prevStatsState) => {
  const currentStatsStatus = currentStatsState.status;
  const prevStatsRequestDetails = prevStatsState.requestDetails;
  const stdCombinedLoaders = combineLoaders(currentStatsStatus, prevStatsState.status);

  if (
    stdCombinedLoaders.failed &&
    !currentStatsStatus.failed &&
    prevStatsRequestDetails?.statusCode === 403
  ) {
    // the combined status is failed specifically due to a 403 (Forbidden) error, which could mean
    // the user is someone who does not have access to pre-aggregated stats, so just use the status
    // of the current stats request, instead
    return currentStatsStatus;
  }
  return stdCombinedLoaders;
};

export const EmployerStatistics = props => {
  const [statsState] = employerStatsActions.useOnMount('current', props.organizationId);
  const [prevStatsState] = employerStatsHistoryActions.useOnMount('last_month', props.organizationId);
  const employerStats = statsState.resources;
  const prevEmployerStats = prevStatsState.resources;
  const combinedStatus = combineStatuses(statsState, prevStatsState);
  const occupationMappingEnabled = useFaethmOccupationMappingEnabled();

  if (combinedStatus.pending || (combinedStatus.idle && !employerStats)) {
    return (
      <ChicletGroup layout="quad">
        <ChicletLoading/>
        <ChicletLoading/>
        <ChicletLoading/>
        <ChicletLoading/>
      </ChicletGroup>
    );
  } else if (combinedStatus.failed) {
    return (
      <div>
        <FormattedMessage
          id="workforce.statistics.employer_stats.load_failure"
          defaultMessage="Could not load statistics"
        />
      </div>
    );
  } else {
    return (
      <EmployerStatisticsInner
        employerStats={employerStats}
        prevEmployerStats={prevEmployerStats}
        occupationMappingEnabled={occupationMappingEnabled}
      />
    );
  }
};

EmployerStatistics.propTypes = {
  organizationId: PropTypes.string.isRequired
};

const EmployerStatisticsInner = (
  { employerStats, prevEmployerStats, occupationMappingEnabled }
) => {
  return (
    <div className="workforce__employer-statistics">
      <ChicletGroup layout="quad">
        <EmployerStatisticsPanel
          statisticsField="employees"
          statisticsFieldDisplay="Employees"
          employerStats={employerStats}
          prevEmployerStats={prevEmployerStats}
          tooltip={(
            <FormattedMessage
              id="workforce.statistics.employees.chiclet_explanation"
              defaultMessage="Total number of added employees."
            />
          )}
        />
        <EmployerStatisticsPanel
          statisticsField="accepted"
          statisticsFieldDisplay="Accepted"
          employerStats={employerStats}
          prevEmployerStats={prevEmployerStats}
          tooltip={(
            <FormattedMessage
              id="workforce.statistics.accepted.chiclet_explanation"
              defaultMessage="Total number of employees who have accepted your invitation."
            />
          )}
        />
        <EmployerStatisticsPanel
          statisticsField="badges"
          statisticsFieldDisplay="Badges"
          employerStats={employerStats}
          prevEmployerStats={prevEmployerStats}
          tooltip={(
            <FormattedMessage
              id="workforce.statistics.badges.chiclet_explanation"
              defaultMessage={(
                'Total number of badges your employees have earned to date. This includes all ' +
                'badges that you know of. Your employees may have more - to learn more, send ' +
                'them an invitation, or remind them to accept their invitation.'
              )}
            />
          )}
        />
        <EmployerStatisticsPanel
          statisticsField="skills_by_earner"
          statisticsFieldDisplay="Skills"
          employerStats={employerStats}
          prevEmployerStats={prevEmployerStats}
          tooltip={(
            occupationMappingEnabled
              ? (
                <FormattedMessage
                  id="workforce.statistics.skills.chiclet_explanation_l3"
                  defaultMessage={(
                    'Total number of skills held by your employees, ' +
                    'both Verified and Occupation Skills.'
                  )}
                />
              ) : (
                <FormattedMessage
                  id="workforce.statistics.skills.chiclet_explanation_l2"
                  defaultMessage="Total number of skills held by your employees."
                />
              )
          )}
        />
      </ChicletGroup>
    </div>
  );
};

EmployerStatisticsInner.propTypes = {
  employerStats: PropTypes.object.isRequired,
  prevEmployerStats: PropTypes.object,
  occupationMappingEnabled: PropTypes.bool.isRequired
};

export const testing = { EmployerStatisticsPanel };
