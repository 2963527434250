import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Filters } from 'controls/filters';
import { useBrowserQueryString } from 'controls/with_query_string';
import { FILTER_NAMES } from 'pages/public/organization/earner_directory/constants';
import { useDirectoryQueryStringFilter } from './use_directory_query_string_filter';
import { useDirectoryTracking } from './use_directory_tracking';
import { DirectorySearch } from '../directory_search';

import './directory_search_with_filters.sass';

/**
 * Component that renders the DirectorySearch and Filters and handles
 * pushing history as well as tracking based on user interactions
 * with the aforementioned components.
 * @param {Object} props
 *   @param {Object} props.baseParams - Query params to pass through in all API requests.
 *   @param {Object} props.trackingData - Base stat object data to pass to tracking functions.
 *   @param {Object} props.excludeSearchTypes - Set of search types to hide, for example
 *   EarnerDirectory hides searching by issuer e.g. { issuer: true }
 *   @param {Node} [props.filterTag] - Component containing the button for opening the filters modal
 *   @param {String} [props.searchBarPlaceholderText] - Text to be displayed in the SearchBar component
 *   @param {boolean} [props.suppressSearchWithoutTerm=false] - if true, don't show suggested terms
 *     in the search bar unless the user has entered a query
 */
export const DirectorySearchWithFilters = (props) => {
  const history = useHistory();
  const queryString = useBrowserQueryString();
  const filters = useDirectoryQueryStringFilter();
  const dirTracking = useDirectoryTracking(props.trackingData);

  /**
   * Called on filter change.
   *
   * @param {QueryString} filters
   */
  const saveFilters = React.useCallback(filters => {
    const q = queryString.clone().removeKey('page');
    const updateFilters = filters.clone();
    const filterHash = updateFilters.m_data;

    if (props.orFilters && Object.prototype.hasOwnProperty.call(filterHash, 'or')) {
      if (Array.isArray(filterHash.or)) {
        updateFilters.m_data.or = filterHash.or.filter((orItem) => !!filterHash[orItem]);
      } else {
        if (!filterHash[filterHash.or]) {
          delete filterHash.or;
        }
      }
    }

    history.push({ search: q.set('filter', updateFilters).toString() });
  }, [queryString]);

  /**
   * A search result has been selected.
   *
   * @param {{key: string, value: string}} result - Filter for the search result.
   */
  const onSelectSearchResult = React.useCallback(result => {
    const q = queryString.clone().removeKey('page');
    q.dig('filter').pushUnique(result.key, result.value);
    if (props.orFilters) {
      q.dig('filter').pushUnique('or', result.key);
    }
    dirTracking.trackSelectSearchResult(result);
    history.push({ search: q.toString() });
  }, [dirTracking.trackSelectSearchResult, queryString]);

  const DirectorySearchTag = props.directorySearchTag;

  return (
    <div className="c-directory-search-with-filters">
      <DirectorySearchTag
        baseParams={{ ...props.baseParams, ...(filters.isEmpty() ? {} : { filter: filters }) }}
        excludeSearchTypes={props.excludeSearchTypes}
        onTermChanged={dirTracking.trackSearchTermChanged}
        onSelect={onSelectSearchResult}
        suppressSearchWithoutTerm={props.suppressSearchWithoutTerm}
        searchBarPlaceholderText={props.searchBarPlaceholderText}
        filterTag={props.filterTag}
        tabs={props.tabs}
        dataToFilter={props.dataToFilter}
        tabNamesToFilterKeyTransforms={props.tabNamesToFilterKeyTransforms}
      />
      <Filters
        className="c-directory-search-with-filters__filters"
        filterNames={props.filterNames || FILTER_NAMES}
        filters={filters}
        onChange={saveFilters}
      />
    </div>
  );
};

DirectorySearchWithFilters.propTypes = {
  baseParams: PropTypes.object.isRequired,
  excludeSearchTypes: PropTypes.object,
  suppressSearchWithoutTerm: PropTypes.bool,
  searchBarPlaceholderText: PropTypes.string,
  trackingData: PropTypes.object.isRequired,
  directorySearchTag: PropTypes.object,
  filterTag: PropTypes.node,
  filterNames: PropTypes.object,
  orFilters: PropTypes.bool
};

DirectorySearchWithFilters.defaultProps = {
  directorySearchTag: DirectorySearch,
  filterTag: null
};
