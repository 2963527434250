import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EmployeesEmptySearch } from '../../employees_empty_search';
import { useFaethmOccupationMappingEnabled } from '../../../faethm_occupation_mapping_hooks';

/**
 * Used to render empty search result messaging depending on organization tier.
 * Organization tier is determined from useFaethmOccupationMappingEnabled()
 * to see if they are currently integrated with Faethm or not.
 *
 * L1 & L3 tiers: "No data found", rendered from EmployeesEmptySearch.
 * L2 tier: "Contact sales to map your workforce against our proprietary
 * occupation ontology to gain access to market insights, suggested skills
 * and recommended badges."
 */

export const L2EmptyOccupationsSearch = () => {
  const faethmMapped = useFaethmOccupationMappingEnabled();
  const [emptySearch, setEmptySearch] = useState(<EmployeesEmptySearch/>);

  const l2Message = (
    '<link>Contact sales</link> to map your workforce against our proprietary ' +
    'occupation ontology to gain access to market insights, ' +
    'suggested skills and recommended badges.'
  );
  let message;

  useEffect(() => {
    if (!faethmMapped) {
      message = (
        <FormattedMessage
          id="user.l2.occupations.empty.course_of_action"
          defaultMessage={l2Message}
          values={{
            link: (linkText) => (
              <a
                href="https://info.credly.com/schedule-a-demo"
                rel="noopener noreferrer"
                target="_blank"
              >
                {linkText}
              </a>
            )
          }}
        />
      );
      setEmptySearch(<EmployeesEmptySearch message={message}/>);
    }
  }, [faethmMapped]);

  return (
    <>
      {emptySearch}
    </>
  );
};
