import 'base.sass';
import 'utils/force_rollup';
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { Provider } from 'react-redux';
import { actionTypes } from 'redux-resource';
import debounce from 'lodash.debounce';
import { ABTest } from 'ab_test';
import * as objUtils from 'utils/object';
import { WithLazyLoadFallbackContent } from 'app_utils/lazy_load';
import { PortaledHeader, SiteHeaderContextProvider } from 'header';
import store, { initPrerenderData } from 'store';
import { AlertsActions, AlertStack } from 'alert';
import { ScrollToTop } from 'controls/scroll_to_top';
import { LoadingSpinner } from 'controls/loading_spinner';
import { ErrorBoundary } from 'pages/error_views';
import { PermissionsManager } from './permissions_requests';
import { EarnerTosAgreement } from "./earner/earner_tos_agreement";

/**
 * Prerender code needed for all applications.
 */
export const prerender = () => {
  // Create the "profile" redux store, which is delivered from Rails (as opposed to fetched via
  // ajax like everything else).
  const globals = window.initialAppValues;
  if (globals.currentUser) {
    const id = globals.currentUser.id;
    store.dispatch({
      type: actionTypes.UPDATE_RESOURCES,
      resources: {
        profile: {
          [id]: globals.currentUser
        }
      },
      lists: {
        profile: {
          self: [id]
        }
      }
    });

    store.dispatch({
      type: actionTypes.UPDATE_RESOURCES,
      resources: {
        talentMatcher: {
          self: globals.talentMatcher
        }
      }
    });
  }

  if (globals.features) {
    store.dispatch({
      type: actionTypes.UPDATE_RESOURCES,
      resources: {
        enabledFeatures: globals.features
      },
      lists: {
        enabledFeatures: {
          all: globals.features
        }
      }
    });
  }

  if (globals.permissionsRequests) {
    store.dispatch({
      type: actionTypes.UPDATE_RESOURCES,
      resources: {
        permissionsRequests: globals.permissionsRequests
      },
      lists: {
        permissionsRequests: {
          all: globals.permissionsRequests.map(p => p.id)
        }
      }
    });
  }

  ABTest.initialize();

  // Load data into the redux store that might have been injected into the HTML by Rails. This is
  // to improve performance and SEO on public pages.
  if (window.prerenderData) {
    initPrerenderData(window.prerenderData);
  }
};

/**
 * Content for all applications, rendered by ApplicationStructure. This must be a separate component
 * from ApplicationStructure, because react-router hooks cannot be called in the same component
 * that creates the top-level route.
 *
 * @param {Object} props - See ApplicationStructure.
 */
const ApplicationTopRoute = props => {
  // Debounce so we don't get garbage data due to <Redirect> or rapid clicks by the user.
  const gaTrackPageView = React.useCallback(debounce(location => {
    // eslint-disable-next-line no-undef
    window.ga && ga('send', 'pageview', location.pathname + location.search);
  }, 300), []);

  // This is a bit of a misuse of hooks. We should be using useLocation instead of history.listen.
  // however, due to a bug (?) in react-router, useLocation does not update when changing between
  // routes in different <Switch> blocks - for example, going from settings to the profile.
  const history = useHistory();
  React.useEffect(() => history.listen(gaTrackPageView), []);

  // <Suspense> must wrap all lazy-loaded components, so anything outside it has to be an immediate
  // load (using "import X" instead of import(X) or lazy(X)).
  return (
    <Route
      path={props.path}
      render={routeProps =>
        <ErrorBoundary pathName={routeProps.location.pathname} homePath={props.homePath}>
          <Suspense fallback={<LoadingSpinner position="window-center" delay={500} />}>
            {props.children(routeProps)}
          </Suspense>
        </ErrorBoundary>
      }
    />
  );
};
ApplicationTopRoute.propTypes = {
  path: PropTypes.string,
  homePath: PropTypes.string,
  children: PropTypes.func.isRequired
};

/**
 * Structure for all applications.
 *
 * @param {Object} props
 *   @param {Boolean} props.hasSiteHeader - Render the site header.
 *   @param {String} props.path - The base path for the primary route.
 *   @param {String} props.homePath - The homepage path.
 *   @param {React.element} props.additionalContent - Render this after the main content, on every
 *     page.
 *   @param {function(object):React.element} props.children - The main content renderer. The
 *     parameter is the routeProps object from <Route>.
 */
export const ApplicationStructure = props => {
  React.useEffect(() => {
    // Add any alerts stashed in the page by the server
    if (window.initialAppValues.alerts) {
      window.initialAppValues.alerts.forEach(AlertsActions.bind(store.dispatch).add);
    }
  }, []);


  let content =
    <ApplicationTopRoute path={props.path} homePath={props.homePath}>
      {props.children}
    </ApplicationTopRoute>;

  if (props.hasSiteHeader) {
    content =
      <SiteHeaderContextProvider>
        <PermissionsManager />
        <Suspense fallback="">
          <PortaledHeader />
        </Suspense>
        {content}
      </SiteHeaderContextProvider>;
  }

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Router>
          <ScrollToTop>
            {content}
            <Suspense fallback="">
              <EarnerTosAgreement/>
              <WithLazyLoadFallbackContent
                fallbackContent={(
                  <LoadingSpinner position="below" delay={500} />
                )}
              >
                {props.additionalContent}
              </WithLazyLoadFallbackContent>
            </Suspense>
          </ScrollToTop>
        </Router>
        <AlertStack/>
      </ErrorBoundary>
    </Provider>
  );
};
ApplicationStructure.propTypes = {
  additionalContent: PropTypes.node,
  hasSiteHeader: PropTypes.bool,
  path: PropTypes.string,
  homePath: PropTypes.string,
  children: PropTypes.func.isRequired
};
