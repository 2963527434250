import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import './progress_bar.sass';

/**
 * A progress bar.
 *
 * @param {Object} props
 *   @param {String} props.className - Add this class name to the top-level element.
 *   @param {Number} props.progress - Fill this percentage of the progress bar.
 *   @param {Number} props.nextProgress - If specified, the progress bar will automatically
 *     progress from `progress` to `nextProgress`, reaching 80% of the difference after
 *     about 10 seconds.
 *   @param {String} props.label - A text label that appears under the progress bar.
 *   @param {Boolean} props.segment - If true, the progress bar will be segmented into sections
 *   @param {Number} props.segmentCount - The number of segments to divide the progress bar into.
 *   @param {Number} props.segmentCurrent - The current segment. as in 3 of 5 total segments.
 * @returns {JSX.Element}
 */
export const ProgressBar = props => {
  const progress = props.progress || 0;
  const nextProgress = props.nextProgress || progress;
  const { segment, segmentCount, segmentCurrent, label, className } = props;

  const renderSegment = () => {
    return (
      <div className={makeClassName("progress-bar-segmented")}>
        {Array.from(Array(segmentCount).keys()).map((_, index) => {
          const fillType = index < segmentCurrent
            ? "progress-bar-segment__completed"
            : "progress-bar-segment__incomplete";
          return (
            <div
              key={index}
              className={makeClassName("progress-bar-segment__fill", fillType)}
            />
          );
        })
        }
      </div>
    );
  };

  const renderContinuous = () => {
    return (
      <div className={makeClassName('progress-bar', className)}>
        <div className={makeClassName("progress-bar__fill", `${className}_fill`)} style={{ width: progress + '%' }} />
        {
        // Append a section that's (maxProgress - progress)% wide, and use a CSS animation to slowly
        // fill it in. This gives the illusion that progress is updated more frequently.
          progress < nextProgress &&
            <div
              // Change key to restart the css animation.
              key={progress}
              className="progress-bar__extension"
              style={{ left: progress + '%', right: (100 - nextProgress) + '%' }}
            >
              <div className="progress-bar__fill"/>
            </div>
        }
        {label && <div className="progress-bar__label">{label}</div>}
      </div>
    );
  };

  return segment
    ? renderSegment()
    : renderContinuous();
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number,
  nextProgress: PropTypes.number,
  label: PropTypes.string,
  segment: PropTypes.bool,
  segmentCount: PropTypes.number,
  segmentCurrent: PropTypes.number
};
