import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'controls/dialog';
import { recentlyViewedOrganizationsActions } from './action_creators';
import { FewOrganizationsModalSelector } from './few_organizations_modal_selector';
import { ManyOrganizationsModalSelector } from './many_organizations_modal_selector';

/**
 * A higher order component renders a modal view dependent on the number of organizations
 * that a user has access to.
 *
 * @property {string} current_organization_id - Id of the current organization that the user is
 * viewing.
 * @property {object} currentUser - Api resource object representing the current user
 * @property {string} onClose - Callback function to handle closing the modal view
 * @property {boolean} show - Whether or not to display the modal view
 */
export const OrganizationModalSelector = (props) => {
  const [_, updateRecentlyViewedOrganizationsAction] =
    recentlyViewedOrganizationsActions.useAction(
      'updateRecentlyViewedOrganizations',
      props.current_organization_id
    );
  useEffect(() => {
    updateRecentlyViewedOrganizationsAction({ id: props.current_organization_id });
  }, [props.current_organization_id]);

  const shouldShowMany = () => (props.currentUser.memberships.length > 6);


  const dialogContent = () => {
    if (shouldShowMany()) {
      return (
        <ManyOrganizationsModalSelector
          current_organization_id={props.current_organization_id}
          currentUser={props.currentUser}
          onClose={props.onClose}
        />
      );
    } else {
      return (
        <FewOrganizationsModalSelector
          currentUser={props.currentUser}
          current_organization_id={props.current_organization_id}
          onClose={props.onClose}
        />
      );
    }
  };
  if (props.show) {
    return (
      <Dialog
        backdropTheme="light"
        show
        shouldClose={props.onClose}
        borderTheme="rounded"
        size={"auto"}
      >
        <Dialog.Content contentPadTheme="none">
          {dialogContent()}
        </Dialog.Content>
      </Dialog>
    );
  } else {
    return null;
  }
};

OrganizationModalSelector.propTypes = {
  current_organization_id: PropTypes.string,
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  show: PropTypes.bool
};
