import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltUp } from '@fortawesome/pro-solid-svg-icons/faArrowAltUp';
import { faArrowAltDown } from '@fortawesome/pro-solid-svg-icons/faArrowAltDown';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons/faChartLine';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import TrendChartLoadingSVG from 'svg/org-mgmt-analytics_trend_chart_loading.svg';
import { Alert } from 'alert';
import { standardAggregators, Timeseries } from 'charts/timeseries';
import { Heading } from 'controls/heading';
import { makeClassName } from 'utils';
import { wrapArray } from 'utils/array';
import dateUtils from 'utils/date';

import './trend_chart.sass';

/**
 * The number of empty-state placeholder images. Must match the number of images in ./images/ and
 * the number of background-defining CSS classes in trend_chart.sass.
 *
 * @type {number}
 */
const PLACEHOLDER_COUNT = 5;

/**
 * Uses the {@link Timeseries} chart component to render a trend chart for analytics, including a
 * title and summation of y values.
 *
 * @param {object} props
 *   @param {Array<object>} props.data - the dataset from which to select points to
 *     display on the chart; elements must have at least an interval key which is an ISO 8601 date,
 *     as well as some numeric value corresponding to yKey
 *   @param {function(number):(string|React.ReactElement)} props.itemLabel - function that will
 *     receive the number of items for the summary line in the upper-right corner of the chart and
 *     is expected to return a string formatted for display (e.g. 5432 => "5,432 Widgets")
 *   @param {String|React.ReactElement} props.title - the title to use to label the chart
 *   @param {String|String[]} props.yKey - the key of an attribute or attributes in data that will
 *     be used for the y values in the chart; if multiple keys are provided, their values are
 *     summed
 *   @param {{
 *     header: String|React.ReactElement, detail: String|React.ReactElement
 *   }} [props.emptyMessage] - optional object describing the
 *     text to render if the chart is empty, that is, if no values in data have non-zero y values,
 *     and maxX and minX were not provided
 *   @param {(function:function(number[]):number)|function(number[]):number} [props.dataAggregator] -
 *     function to use to combine data points when necessary to fit into the chart area; defaults
 *     to summing y values
 *   @param {boolean} [props.loading=false] - whether the chart data is loading
 *   @param {Array<Date>} [props.highlightedDomain] - if provided, indicates a portion of the x
 *     domain of the chart that will be highlighted; used in analytics trend charts to indicate the
 *     region corresponding to the applied date filter
 *   @param {Date} [props.maxX] - the maximum value that x (interval) could conceivably have, used
 *     to establish the right-most boundary of the chart, if absent boundary is determined by
 *     maximum x value in data
 *   @param {Date} [props.minX] - the minimum value that x (interval) could conceivably have, used
 *     to establish the left-most boundary of the chart, if absent boundary is determined by
 *     minimum x value in data
 *   @param {String} [props.note] - a supplemental note to provide additional context or caveats
 *     about the data presented
 *   @param {Date[]} [props.previousPeriodDateRange] - if previousPeriodTotal is given, this will
 *     indicate what time period that value spans
 *   @param {number} [props.previousPeriodTotal] - the total of the previous period, used to
 *     display a comparison of the current period total against the previous period to indicate
 *     performance over time
 *   @param {(function:function(number[]):number)|function(number[]):number} [props.totalAggregator] - function to
 *     compute the total to display in the chart header (default is to sum y values)
 * @returns {JSX.Element}
 * @constructor
 */
export const TrendChart = (props) => {
  const transformedData = useMemo(() => {
    return selectData(props.data, props.yKey, props.minX, props.maxX);
  }, [props.data, props.yKey, props.minX, props.maxX]);

  const totalAggregator = props.totalAggregator || standardAggregators.sum;
  const headerTotal = useMemo(() => {
    const yValues = transformedData.map(datum => datum.y);
    if (totalAggregator.length === 0) {
      return totalAggregator()(yValues);
    } else {
      return totalAggregator(yValues);
    }
  }, [transformedData, totalAggregator]);

  const heading = (
    <Heading appearance="custom" className="cr-org-mgmt-analytics-trend-chart__title">
      {props.title}
    </Heading>
  );

  // Randomly assign a placeholder image in case the chart is empty or loading; but only do so once
  // per mount, so that unrelated re-renders don't cause the placeholder to change unexpectedly and
  // create visual noise
  const placeholderImageIndex = useRef(Math.floor(Math.random() * PLACEHOLDER_COUNT));

  if (props.loading) {
    return <TrendChartLoading/>;
  }

  let content;
  if (transformedData.length === 0) {
    let header = (
      <FormattedMessage
        id="org_mgmt.analytics.trend_chart_empty_header"
        defaultMessage="No Data for Selected Filters"
      />
    );
    if (props.emptyMessage && props.emptyMessage.header) {
      header = props.emptyMessage.header;
    }
    content = (
      <>
        <div className="cr-org-mgmt-analytics-trend-chart__header">
          {heading}
        </div>
        <div
          className={makeClassName(
            'cr-org-mgmt-analytics-trend-chart__empty-container',
            `cr-org-mgmt-analytics-trend-chart__empty-container--${placeholderImageIndex.current}`
          )}
        >
          <div className="cr-org-mgmt-analytics-trend-chart__empty-message">
            <div className="cr-org-mgmt-analytics-trend-chart__empty-message-icon">
              <FontAwesomeIcon
                icon={faChartLine}
                className="cr-org-mgmt-analytics-trend-chart__empty-message-icon-chart"
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="cr-org-mgmt-analytics-trend-chart__empty-message-icon-search"
              />
            </div>
            <div className="cr-org-mgmt-analytics-trend-chart__empty-message-header">
              {header}
            </div>
            {
              props.emptyMessage && props.emptyMessage.detail && (
                <div className="cr-org-mgmt-analytics-trend-chart__empty-message-detail">
                  {props.emptyMessage.detail}
                </div>
              )
            }
          </div>
        </div>
      </>
    );
  } else {
    content = (
      <>
        <div className="cr-org-mgmt-analytics-trend-chart__header">
          {heading}
          <div className="cr-org-mgmt-analytics-trend-chart__summary">
            <div className="cr-org-mgmt-analytics-trend-chart__total">
              {props.itemLabel(headerTotal)}
            </div>
            <TrendChartPreviousPeriodChange
              current={headerTotal}
              previous={props.previousPeriodTotal}
              previousPeriodDateRange={props.previousPeriodDateRange}
            />
          </div>
        </div>
        <Timeseries
          data={transformedData}
          minX={props.minX}
          maxX={props.maxX}
          highlightedDomain={props.highlightedDomain}
          aggregator={props.dataAggregator}
          processData
        />
      </>
    );
  }

  return (
    <div
      className={makeClassName(
        'cr-org-mgmt-analytics-trend-chart',
        transformedData.length === 0 && 'cr-org-mgmt-analytics-trend-chart--empty'
      )}
    >
      {content}
      {
        props.note && (
          <Alert
            className="cr-org-mgmt-analytics-trend-chart__note"
            text={props.note}
            type="info"
          />
        )
      }
    </div>
  );
};

TrendChart.defaultProps = {
  loading: false
};

TrendChart.propTypes = {
  data: PropTypes.arrayOf((dataArray, itemIndex) => {
    const interval = dataArray[itemIndex].interval;
    if (!interval) {
      return new Error(`Invalid prop data supplied to TrendChart: elements must have interval key`);
    }
    if (itemIndex > 0 && interval < dataArray[itemIndex - 1].interval) {
      return new Error(
        `Invalid prop data supplied to TrendChart: elements must be sorted by value of interval; ` +
        `found item with interval ${interval} at index ${itemIndex} greater than previous item ` +
        `${dataArray[itemIndex - 1].interval}`
      );
    }
  }).isRequired,
  emptyMessage: PropTypes.shape({
    header: PropTypes.node.isRequired, detail: PropTypes.node
  }),
  itemLabel: PropTypes.func.isRequired,
  title: PropTypes.node,
  yKey: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,

  // optional props
  dataAggregator: PropTypes.func,
  highlightedDomain: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  loading: PropTypes.bool,
  maxX: PropTypes.instanceOf(Date),
  minX: PropTypes.instanceOf(Date),
  note: PropTypes.string,
  previousPeriodDateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  previousPeriodTotal: PropTypes.number,
  totalAggregator: PropTypes.func
};

/**
 *
 * @param {object[]} data - the chart data
 * @param {string|string[]} yKey - the name or names of attributes to use for the y value of data
 *   points in the chart
 * @param {Date} minX - minimum value that x can have, to establish left boundary of chart, e.g. if
 *   user has selected a specific date range
 * @param {Date} maxX - maximum value that x can have, to establish right boundary of chart, e.g. if
 *   user has selected a specific date range
 * @returns {Array<{ x: Date, y: number}>}
 */
const selectData = (data, yKey, minX, maxX) => {
  yKey = wrapArray(yKey);
  const result = [];
  data = data || [];

  data.forEach((item) => {
    const xValue = dateUtils.dateFromString(item.interval, { formats: ['yyyy-M-d HH:mm:ss'] });
    let yValue = 0;
    yKey.forEach((key) => {
      yValue += item[key] || 0;
    });
    // skip items at the beginning of the range that are 0-valued if we aren't looking for a
    // specific start date
    if ((minX && minX <= xValue) || result.length > 0 || yValue > 0) {
      result.push({ x: xValue, y: yValue });
    }
  });

  // drop 0 values from the end, unless we're looking for a specific end date
  for (let i = result.length - 1; i > 0; --i) {
    if (result[i].y === 0 && (!maxX || maxX < result[i].x)) {
      result.splice(i, 1);
    } else {
      break;
    }
  }

  return result;
};

/**
 * Shows relative change between current and previous values provided. Renders empty if previous
 * value is 0 or not provided at all.
 *
 * @param {object} props
 *   @param {number} props.current - the current value for which to calculate percent change
 *     relative to previous
 *   @param {number} props.previous - the previous value for the metric, against which change is
 *     calculated
 *   @param {Date[]} props.previousPeriodDateRange - the date range covered by the previous period,
 *     for the purposes of displaying a tooltip indicating that range
 * @returns {JSX.Element|null}
 * @constructor
 */
const TrendChartPreviousPeriodChange = (props) => {
  const intl = useIntl();

  if (!props.previous) {
    return null;
  }

  const tooltip = intl.formatMessage(
    {
      id: 'org_mgmt.analytics.trend_chart_tooltip',
      defaultMessage: '{previous, number} during {startDate, date, medium} - {endDate, date, medium}'
    },
    {
      previous: props.previous,
      startDate: props.previousPeriodDateRange[0],
      endDate: props.previousPeriodDateRange[1]
    }
  );

  let percentChange = (props.current - props.previous) / props.previous;

  let icon;
  if (Math.abs(percentChange) < 0.0005) {
    icon = null;
    percentChange = 0;
  } else if (percentChange > 0) {
    icon = (
      <FontAwesomeIcon
        icon={faArrowAltUp}
        className="cr-org-mgmt-analytics-trend-chart__comparison-icon"
        title={intl.formatMessage({
          id: 'org_mgmt.analytics.trend_chart_change_up',
          defaultMessage: 'Up'
        })}
      />
    );
  } else {
    icon = (
      <FontAwesomeIcon
        icon={faArrowAltDown}
        className="cr-org-mgmt-analytics-trend-chart__comparison-icon"
        title={intl.formatMessage({
          id: 'org_mgmt.analytics.trend_chart_change_down',
          defaultMessage: 'Down'
        })}
      />
    );
    percentChange = Math.abs(percentChange);
  }

  return (
    <div className="cr-org-mgmt-analytics-trend-chart__comparison" title={tooltip}>
      {icon}
      {intl.formatMessage(
        {
          id: 'org_mgmt.analytics.trend_chart_change',
          defaultMessage: '{percentChange, plural, =0 {No Change} ' +
            'other {{percentChange, number, :: % .0}}} vs previous period'
        },
        { percentChange }
      )}
    </div>
  );
};

TrendChartPreviousPeriodChange.propTypes = {
  current: PropTypes.number,
  previous: PropTypes.number,
  previousPeriodDateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date))
};

/**
 * Internal component used by TrendChart when it is loading. Displays greyed-out placeholder.
 *
 * @param {object} _props - ignored
 * @returns {JSX.Element}
 * @constructor
 */
const TrendChartLoading = (_props) => {
  return (
    <div
      className="cr-org-mgmt-analytics-trend-chart cr-org-mgmt-analytics-trend-chart--placeholder"
    >
      <div className="cr-org-mgmt-analytics-trend-chart__header">
        <div
          className="
            cr-org-mgmt-analytics-trend-chart__title
            cr-org-mgmt-analytics-trend-chart__title--placeholder
          "
        >
          <div className="cr-org-mgmt-analytics-trend-chart__placeholder-content"/>
        </div>
        <div className="cr-org-mgmt-analytics-trend-chart__summary">
          <div
            className="
              cr-org-mgmt-analytics-trend-chart__total
              cr-org-mgmt-analytics-trend-chart__total--placeholder
            "
          >
            <div className="cr-org-mgmt-analytics-trend-chart__placeholder-content"/>
          </div>
          <div
            className="
              cr-org-mgmt-analytics-trend-chart__comparison
              cr-org-mgmt-analytics-trend-chart__comparison--placeholder
            "
          >
            <div className="cr-org-mgmt-analytics-trend-chart__placeholder-content"/>
          </div>
        </div>
      </div>
      <TrendChartLoadingSVG viewBox="0 0 800 195" preserveAspectRatio="none"/>
    </div>
  );
};

export const testing = { TrendChartPreviousPeriodChange, TrendChartLoading };
