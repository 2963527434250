import React from 'react';
import { PaginationNavControls } from './nav_controls';

export {
  PaginationNavControls,
  PaginationNavControlBack,
  PaginationNavControlForward
} from './nav_controls';
export { PaginationProgressIndicator } from './progress';
export { PaginationPages } from './page_numbers';

export default function Pagination(props) {
  return <PaginationNavControls centered pageNumbers {...props} />;
}
