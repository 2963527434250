import { ActionManager } from 'utils/action_manager';

export const recentlyViewedOrganizationsActions = (new ActionManager('managementRecentlyViewedOrganizations'))
  .add({
    url: '/api/v1/recently_viewed_organizations',
    requestKey: 'getRecentlyViewedOrganizations',
    effect: 'read',
    method: 'GET',
    list: 'all'
  }).add({
    url: '/api/v1/recently_viewed_organizations/[id]',
    requestKey: 'updateRecentlyViewedOrganizations',
    effect: 'update',
    method: 'PUT',
    list: 'all'
  }, true).add({
    url: '/api/v1/recently_viewed_organizations/[id]',
    requestKey: 'deleteRecentlyViewedOrganization',
    effect: 'delete',
    method: 'DELETE'
  }, true);

