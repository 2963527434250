import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'controls/heading';
import { CarouselManager, carouselCardTypes, carouselLayoutTypes } from 'controls/carousel/carousel_manager';
import { similarEmployeesAction } from './similar_employees_action';
import { useIntl } from 'react-intl';

import './similar_employees_carousel.sass';

/**
 * Carousel component of similar employees
 *
 * @param {Object} props
 *   @param {object} props.employee - Resource object representing employee profile api response
 */
export const SimilarEmployeesCarousel = props => {
  const similarParams = { organization_id: props.organizationId, id: props.employeeId };
  const [similarEmployeesState] =
    similarEmployeesAction.useOnMount(similarParams, [props.organizationId, props.employeeId]);
  const intl = useIntl();
  if (!props.faethmOccupation ||
    (!similarEmployeesState.status.pending &&
      !similarEmployeesState.resources?.length)) {
    return <></>;
  }

  return (
    <div className="earner-dashboard__similar-employees-container">
      <Heading
        className="container earner-dashboard__similar-employees-heading"
        appearance="serif-primary"
      >
        <span className="earner-dashboard__similar-employees-heading-text">
          {intl.formatMessage({
            id: 'similar_employees.heading',
            defaultMessage: 'Other {faethmOccupationName}'
          }, { faethmOccupationName: props.faethmOccupationName })}
        </span>
      </Heading>
      <div className="similar-employees-carousel-container">
        <CarouselManager
          layout={carouselLayoutTypes.WORKFORCE}
          cardType={carouselCardTypes.EMPLOYEE}
          action={similarEmployeesAction}
          actionParams={similarParams}
          settings={{
            label: intl.formatMessage({
              id: 'similar_employees.carousel.label',
              defaultMessage: 'Similar Employees Carousel'
            }),
            description: intl.formatMessage({
              id: 'similar_employees.carousel.description',
              defaultMessage: 'Explore other Employees with a similar occupation'
            }),
            itemLabel: (employee) => intl.formatMessage({
              id: 'similar_employees.carousel.item_label',
              defaultMessage: '{employeeName}'
            }, { employeeName: employee.full_name || employee.email }),
            itemsPerSlideBreakpoints: { lg: 2, md: 2, xs: 1, sm: 1, default: 3 }
          }}
        />
      </div>
    </div>
  );
};

SimilarEmployeesCarousel.propTypes = {
  organizationId: PropTypes.string.isRequired,
  employeeId: PropTypes.string.isRequired,
  faethmOccupation: PropTypes.string,
  faethmOccupationName: PropTypes.string
};
