import lodashGet from "lodash.get";

/**
 * Like [].map, but for objects.
 *
 * @param {Object} obj - The object
 * @param {function(key:*, value:*):Object} callback - The callback
 */
export const map = (obj, callback) => {
  if (!obj) {
    return {};
  }

  const rval = {};
  Object.keys(obj).forEach(k => {
    rval[k] = callback(k, obj[k]);
  });
  return rval;
};

/**
 * Like [].map, but takes an object, and returns an array.
 *
 * @param {Object} obj - The object
 * @param {function(key:*, value:*):*} callback - The callback
 * @returns {Array}
 */
export const mapToArray = (obj, callback) =>
  Object.keys(obj || {}).map(k => callback(k, obj[k]));

/**
 * Like [].map, but takes an array, and returns an object. This is just a shortcut to make
 * Array.reduce() a little less confusing for this common use-case.
 *
 * @param {Array} a - The array
 * @param {function(obj:*, item:*):*} callback - The callback. The first parameter is the object
 *   we're adding to. The second is the array element.
 * @returns {Array}
 */
export const mapFromArray = (a, callback) => a.reduce((obj, item) => {
  callback(obj, item);
  return obj;
}, {});

/**
 * Removes keys from an object whose values are falsy
 *
 * @param {Object} obj - The object
 * @returns {Object}
 */
export const compact = (obj = {}) => {
  return filter(obj, (_key, value) => !!value);
};

/**
 * Safely extracts a property at the provided path from an object.  Returns defaultValue if the
 * path resolves to undefined.
 *
 * @param {Object} obj - the object from which to extract a property
 * @param {Array|string} propPath - the path to the property that is to be exracted
 * @param {*} [defaultValue] - a value to return if the property is undefined
 * @returns {*}
 */
export const dig = (obj, propPath, defaultValue) => lodashGet(obj, propPath, defaultValue);

/**
 * Like [].every, but for objects
 *
 * @param {Object} obj - the object
 * @param {function(key:*, value:*)} callback - the callback
 * @returns {boolean}
 */
export const every = (obj, callback) => {
  if (!obj) {
    return false;
  }
  for (const k in obj) {
    if (!callback(k, obj[k])) {
      return false;
    }
  }
  return true;
};

/**
 * Like [].filter, but for objects.
 *
 * @param {Object} obj - The object
 * @param {function(key:*, value:*):boolean} callback - The callback
 */
export const filter = (obj, callback) => {
  if (!obj) {
    return {};
  }

  const rval = {};
  Object.keys(obj).forEach(k => {
    if (callback(k, obj[k])) {
      rval[k] = obj[k];
    }
  });
  return rval;
};

/**
 * Like [].forEach, but for objects.
 *
 * @param {Object} obj - The object
 * @param {function(key:*, value:*)} callback - The callback
 */
export const forEach = (obj, callback) => {
  if (obj) {
    Object.keys(obj).forEach(k => callback(k, obj[k]));
  }
};

/**
 * Checks whether the object is empty, i.e. whether it's non-null and has any of its own
 * properties.
 *
 * @param {object} obj - the object to check for emptiness
 * @returns {boolean}
 */
export const isEmpty = (obj) => {
  // This implementation intentionally avoids Object.keys(obj).length so that for large objects we
  // don't generate a long array of keys only to determine whether there's at least one.  But the
  // difference when applied to actual arguments to this function is almost certainly negligible.
  // https://github.com/YourAcclaim/acclaim-server/pull/2555#discussion_r378396942
  if (obj === null) {
    return true;
  }
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
};

export const isHashObject = (obj) => {
  return obj && !Array.isArray(obj) && ((typeof obj) === "object");
};

/**
 * Determines if a property in an object has changed.
 * Only does simple equality comparisons so numbers, strings, or arrays of numbers/strings are expected.
 * Useful for in our React components in methods like componentDidUpdate()
 * Will iterate through arrays and perform simple === comparisons
 *
 * @param {Object} obj1 - The first object to compare properties against
 * @param {Object} obj2 - The second object to compare properties against
 * @param {String} path - The path to the prop to compare against the two objects. Any path string
 * compatible with lodash's "get" is a valid string.
 * @example
 * obj1 = { foo: { biz: [4,2,3] } }
 * obj2 = { foo: { biz: [1,2,3] } }
 * propChanged(obj1, obj2, "foo.biz[0]"} => true
 * @returns {boolean}
 */
export const propChanged = (obj1, obj2, path) => {
  // simple helper that lets it iterate through arrays
  const isEqual = (c1, c2) => {
    if (typeof c1 !== typeof c2) {
      return false;
    }
    if (Array.isArray(c1)) {
      return c1.every((ele, index) => isEqual(ele, c2[index]));
    } else {
      return c1 === c2;
    }
  };
  const prop1 = lodashGet(obj1, path, null);
  const prop2 = lodashGet(obj2, path, null);
  return !isEqual(prop1, prop2);
};

/**
 * Detect whether the provided path has changed between prevProps and nextProps, and return true
 * if the value in nextProps is equal to expected.
 *
 * @param {object} prevProps - the old props object
 * @param {object} nextProps - the next props object
 * @param {Array<String>} path - path of the property to check
 * @param {*} expected - the expected value that nextProps will have for the property at path
 * @returns {boolean}
 */
export const propChangedTo = (prevProps, nextProps, path, expected) => {
  const nextPropValue = lodashGet(nextProps, path, null);
  if (nextPropValue !== expected) {
    return false;
  }
  return propChanged(prevProps, nextProps, path);
};

/**
 * Determines if any of the properties specified in the two objects has changed.
 *
 * @param {Object} obj1 - The first object to compare properties against
 * @param {Object} obj2 - The second object to compare properties against
 * @param {String} paths - The path(s) to the prop to compare against the two objects
 * @example
 * obj1 = { foo: { biz: [4,2,3] } }
 * obj2 = { foo: { biz: [1,2,3] } }
 * anyPropChanged(obj1, obj2, "foo.biz[1]", "foo.biz[0]") => true
 * anyPropChanged(obj1, obj2, "foo.biz[1]") => false
 * @returns {boolean}
 */
export const anyPropChanged = (obj1, obj2, ...paths) => {
  return paths.some(path => propChanged(obj1, obj2, path));
};

/**
 * Determines whether or not the prop changed from not present to present
 * Useful for determining whether or not there was an initial load of data.
 *
 * @param {object} obj1
 * @param {object} obj2
 * @param {Array<String>|String} path
 * @returns {boolean}
 */
export const propLoaded = (obj1, obj2, path) => {
  return (
    typeof lodashGet(obj1, path) === 'undefined' && typeof lodashGet(obj2, path) !== 'undefined'
  );
};

/**
 * Returns a shallow copy of the object with the specified keys removed. Similar to Rails'
 * Hash#except
 *
 * @param {Object} obj - the object
 * @param {Array<*>} keys - the keys to exclude
 * @returns {Object} - a copy of obj without the specified keys
 */
export const except = (obj, keys) => {
  if (obj) {
    const result = { ...obj };
    for (const key of keys) {
      delete result[key];
    }
    return result;
  }
};

/**
 * Does a shallow comparison of two objects and returns true if they have the same keys and
 * values.
 *
 * @param {Object} left - the object
 * @param {Object} right - the object to which left is being compared
 * @returns {boolean} - whether the objects have the same key-value pairs (shallowly)
 */
export const shallowEquals = (left, right) => {
  if (left === right) {
    return true;
  }
  if (!right || !left || typeof left !== typeof right) {
    return false;
  }
  const leftKeys = Object.keys(left);
  if (leftKeys.length !== Object.keys(right).length) {
    return false;
  }
  let result = true;
  for (const key of leftKeys) {
    result = result && left[key] === right[key];
    if (!result) {
      break;
    }
  }
  return result;
};

/**
 * Does a deep comparison of two objects and returns true if they have the same keys and
 * values.
 *
 * @param {Object} left - the object
 * @param {Object} right - the object to which left is being compared
 * @returns {boolean} - whether the objects have the same key-value pairs (shallowly)
 */
export const deepEquals = (left, right) => {
  // There are more efficient ways to do this, especially if we can make assumptions about the
  // values (for example, if they're guaranteed to be objects, guaranteed to be non-classes etc).
  return JSON.stringify(left) === JSON.stringify(right);
};

/**
 * Turn an array of objects into a set, or a scalar array into a set.
 * Items in the array are mapped as returnVal[item[key]] = true
 *
 * @param {Array} a - An array of objects or scalars.
 * @param {*} [key] - The key to use for mapping. Omit to use the scalar value in the array.
 * @returns {Object}
 */
export const arrayToSet = (a, key = null) => {
  const obj = {};
  if (key === null) {
    a.forEach(s => obj[s] = true);
  } else {
    a.forEach(o => obj[o[key]] = true);
  }
  return obj;
};

/**
 * Compares two arrays of objects, based on a single key. For example, comparing by 'id',
 * [{id: 1}, {id: 2}] == [{id: 2}, {id: 1, something: 'else'}]
 *
 * @param {array<object>} left - An array of objects.
 * @param {array<object>} right - An array of objects.
 * @param {String} key - The key to compare.
 * @returns {boolean} - Are the arrays the same?
 */
export const arrayEqualsByKey = (left, right, key) => {
  if (left === right) {
    return true;
  }
  if (!Array.isArray(right) || !Array.isArray(left) || right.length !== left.length) {
    return false;
  }

  // Using arrayToSet() might be faster than sorting, but it won't work correctly if there are
  // duplicates.
  const sort = (a, b) => a[key] - b[key];
  left = [...left].sort(sort);
  right = [...right].sort(sort);

  for (let x = 0; x < left.length; x++) {
    if (left[x][key] !== right[x][key]) {
      return false;
    }
  }
  return true;
};

/**
 * Return a deep copy of an object. This will copy simple objects, arrays and scalars by value.
 * Unsupported data (classes, regular expressions etc) may not retain their original types, and
 * private members could be copied by reference, so it should not be used with objects containing
 * those data types.
 *
 * @param {Object} data - The original object.
 * @returns {Object} The clone.
 */
export const clone = (data) => {
  if (!data) {
    return data;
  } else if (Array.isArray(data)) {
    return data.map(value => clone(value));
  } else if (typeof data === 'object') {
    return map(data, (key, value) => clone(value));
  } else {
    return data;
  }
};
