import React, { useContext } from "react";

/**
 * React.Context that allows components in the component tree to access information about
 * whether an organization is integrated with Faethm for occupation mapping
 *
 * @type {React.Context}
 */

const defaultValue = {};
const FaethmOccupationMappingContext = React.createContext(defaultValue);
export const useFaethmOccupationMappingContext = () => useContext(FaethmOccupationMappingContext);
export default FaethmOccupationMappingContext;
