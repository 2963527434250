import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router';
import { CredlySwitch } from 'controls/credly_switch';
import CredlyRoute from 'controls/credly_route';
import { AccountsLayout } from './accounts';
import { AnalyticsLayout } from './analytics';
import { LearningLayout } from './learning';
import { WorkforceAdminDashboard } from './workforce_admin_dashboard';
import { WorkforceRouteHelpers } from './route_helpers';
import { Routes as EmployeesRoutes } from './employees/routes';
import { ChartDemos } from "./chart_demos";

/**
 * Handles top-level routes for Workforce section.
 *
 * @param {object} props
 *   @param {string} props.baseUrl - base URL of Workforce section (e.g. /workforce/organizations/:organization_id)
 * @returns {JSX.Element}
 * @constructor
 */
export const Routes = (props) => {
  const RouteHelpers = WorkforceRouteHelpers.forUrl(props.baseUrl);
  const intl = useIntl();

  return (
    <CredlySwitch>
      <Redirect from={RouteHelpers.root.route()} exact to={RouteHelpers.dashboard.route()}/>
      <CredlyRoute
        title={
          intl.formatMessage({
            id: 'workforce.page_title.accounts',
            defaultMessage: 'Accounts'
          })
        }
        path={RouteHelpers.accounts.route()}
        render={(routeProps) => (
          <AccountsLayout baseUrl={routeProps.match.path} organization={props.organization} />
        )}
      />
      <CredlyRoute
        title={
          intl.formatMessage({
            id: 'workforce.page_title.analytics',
            defaultMessage: 'Analytics - Workforce'
          })
        }
        path={RouteHelpers.analytics.route()}
        render={(_routeProps) => (
          <AnalyticsLayout organizationId={props.organization.id}/>
        )}
      />
      <CredlyRoute
        title={
          intl.formatMessage({
            id: 'workforce.page_title.workforce',
            defaultMessage: 'Workforce'
          })
        }
        path={RouteHelpers.dashboard.route()}
        exact
        render={(_routeProps) => (
          <WorkforceAdminDashboard organization={props.organization}/>
        )}
      />
      <CredlyRoute
        title={
          intl.formatMessage({
            id: 'workforce.page_title.employees',
            defaultMessage: 'Employees'
          })
        }
        path={RouteHelpers.employees.route()}
        render={(routeProps) => (
          <EmployeesRoutes baseUrl={routeProps.match.path} organization={props.organization} />
        )}
      />
      <CredlyRoute
        title="Demo Charts"
        path={RouteHelpers.chartDemo.route()}
        render={(routeProps) => (
          <ChartDemos baseUrl={routeProps.match.path} organization={props.organization} />
        )}
      />
      <CredlyRoute
        title={
          intl.formatMessage({
            id: 'workforce.page_title.learning',
            defaultMessage: 'Learning'
          })
        }
        path={RouteHelpers.learning.route()}
        render={(routeProps) => (
          <LearningLayout routeProps={routeProps} organization={props.organization} />
        )}
      />
    </CredlySwitch>
  );
};

Routes.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired
};
