import React, { useContext } from 'react';
import { OrgCard, BadgeCard } from 'badges';
import { EmployeeCard } from 'pages/workforce/employees/employees/employee_card';
import { useWidthBreakpoints, BREAKPOINTS } from 'utils/use_width_breakpoints';
import { CarouselManagerContext } from './carousel_manager_context';
import Carousel from '../carousel';
import { carouselCardTypes } from '.';

const MULTILINE_CHARACTER_COUNT = 32;

const CarouselCard = (props) => {
  const windowWidth = useWidthBreakpoints();
  const { cardType, size, sourceTrack } = useContext(CarouselManagerContext);
  const cardSize = size || 'large';

  const display = windowWidth === BREAKPOINTS.XS ? 'largeColumns2' : cardSize;

  switch (cardType) {
    case carouselCardTypes.BADGE:
      return <BadgeCard {...props} display={display} sourceTrack={sourceTrack} />;

    case carouselCardTypes.EMPLOYEE:
      return <EmployeeCard {...props} />;

    default:
      return <OrgCard {...props} display={display} />;
  }
};


export const LoadingCard = () => {
  const { action } = useContext(CarouselManagerContext);
  const [actionState] = action.useAction();

  const lastCard = React.useMemo(() => (
    actionState.resources.length &&
    actionState.resources[actionState.resources.length - 1]
  ), [actionState.resources]);

  let placeholderLineCount;
  // if the last card in the list text is on only one line, then give
  // the loading placeholder one line too
  if (lastCard && lastCard?.name?.length < MULTILINE_CHARACTER_COUNT) {
    placeholderLineCount = 1;
  }

  return (
    <CarouselCard
      loading
      loadingSettings={{
        title: { placeholderLineCount }
      }}
    />
  );
};

export const CarouselManagerItem = (
  <Carousel.Item>
    {(itemParams) => {
      const { cardType } = useContext(CarouselManagerContext);
      const additionalProps = {};

      if (cardType === carouselCardTypes.ORGANIZATION) {
        additionalProps.org = itemParams.data;
      } else if (cardType === carouselCardTypes.BADGE || cardType === carouselCardTypes.EMPLOYEE) {
        additionalProps.data = itemParams.data;
      }

      return (
        <CarouselCard
          type="BadgeTemplate"
          {...additionalProps}
          tabIndex={itemParams.visible ? 0 : -1}
        />
      );
    }}
  </Carousel.Item>
);
